import { useCallback, useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import {
  Button,
  Col,
  Grid,
  Group,
  Textarea,
  Text
} from '@mantine/core'
import { type Comment, createComment, updateComment } from '@venturi-io/api/src/config/task'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'

interface Props {
  taskId: number
  comment?: Comment
  onCommentSaved?: () => void
  loadAllComments: () => void
}

export function FormComment ({ taskId, comment, onCommentSaved, loadAllComments }: Props) {
  const { token } = useUser()
  const addComment = useApi(createComment)
  const editComment = useApi(updateComment)
  const [charCount, setCharCount] = useState(0)
  const maxCharCount = 512

  const form = useForm<Omit<Comment, 'id' | 'orgUser' | 'edited' | 'createdAt' | 'updatedAt'>>({
    initialValues: {
      content: ''
    },
    validate: {
      content: (value: string | any[]) => value.length < 1 ? 'Please input comment' : null
    }
  })

  useEffect(() => {
    if (comment) {
      form.setValues({ content: comment.content })
      setCharCount(comment.content.length)
    }
  }, [comment])

  type FormValues = typeof form.values

  const handleCreate = (values: FormValues) => {
    void addComment
      .fetch({
        taskId,
        ...values
      }, token, 'Successfully created new comment')
      .finally(() => {
        form.reset()
        void loadAllComments()
      })
  }

  const handleUpdate = async (values: typeof form.values) => {
    if (!comment) return
    await editComment
      .fetch({
        taskId,
        commentId: comment.id,
        edited: true,
        ...values
      }, token, 'Successfully updated comment')
    if (onCommentSaved) {
      onCommentSaved()
    }
  }

  const handleSubmit = useCallback(
    async (values: typeof form.values) => {
      if (comment) {
        await handleUpdate(values)
      } else {
        handleCreate(values)
      }
      form.reset()
      setCharCount(0)
    },
    [comment, form.values]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(event.currentTarget.value.length)
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Col>
          <Textarea
            maxLength={maxCharCount}
            withAsterisk
            minRows={2}
            label="Comments"
            placeholder="Input your comment here"
            {...form.getInputProps('content')}
            onChange={(event) => {
              form.getInputProps('content').onChange(event)
              handleInputChange(event)
            }}
          />
          <Text
            size="xs"
            color={charCount >= maxCharCount
              ? 'red'
              : 'dimmed'}
          >
            {charCount}
            /
            {maxCharCount}
            {' '}
            characters
          </Text>
        </Col>
        <Col>
          <Group position="right">
            <Button
              size="xs"
              type="submit"
              color="green"
              leftIcon={(
                <FontAwesomeIcon
                  icon={['fas', 'floppy-disk']}
                  color="white"
                />
              )}
              disabled={addComment.loading || editComment.loading}
            >
              {comment
                ? 'Update'
                : 'Submit'}
            </Button>
          </Group>
        </Col>
      </Grid>
    </form>
  )
}
