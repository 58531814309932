import { type ListChildComponentProps } from 'react-window'
import { type InmarsatMessage } from '@venturi-io/api/src/config/inmarsat'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { type ReactNode } from 'react'
import { Text } from '@mantine/core'
import { parseEmpty } from 'src/utils/parseEmpty'
import { columnStyle } from '../shared'

interface Props extends ListChildComponentProps<InmarsatMessage[]> {
  isItemLoaded: (index: number) => boolean
}

interface TdProps {
  children: ReactNode
}

const Td = ({ children }: TdProps) => (
  <td style={columnStyle}>
    <Text lineClamp={2}>{children}</Text>
  </td>
)

const Row = ({ index, data, style, isItemLoaded }: Props) => {
  const item = data[index]

  return (
    !isItemLoaded(index)
      ? null
      : (
        <tr key={`${item.id}-${index}`} style={style}>
          <Td>{dayjs(item.callTime).format(uiDateFormat)}</Td>
          <Td>{item.mailboxId}</Td>
          <Td>{parseEmpty(item.errorId)}</Td>
          <Td>{parseEmpty(item.messageId)}</Td>
          <Td>{parseEmpty(dayjs(item.messageUtc).format(uiDateFormat))}</Td>
          <Td>{parseEmpty(dayjs(item.receiveUtc).format(uiDateFormat))}</Td>
          <Td>{parseEmpty(item.rawPayload)}</Td>
          <Td>{parseEmpty(item.decodedRawPayload)}</Td>
          <Td>{parseEmpty(item.agentId)}</Td>
          <Td>{parseEmpty(item.mobileId)}</Td>
          <Td>{parseEmpty(item.sin)}</Td>
        </tr>
        )
  )
}

export default Row
