import {
  Container,
  Group,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import RadialGauge from 'src/Charts/RadialGauge'
import { convertStrToFloat, findSensorByType, useSharedStyles } from '../shared'
import GroupBoxValue from '../shared/GroupBoxValue'

const batteryLevelOuterData = [
  {
    id: 1,
    value: 33,
    color: '#5FB359'
  },
  {
    id: 2,
    value: 33,
    color: '#E5AF59'
  },
  {
    id: 3,
    value: 33,
    color: '#A62219'
  }
]

interface Props {
  agentName: string
  sensors: Sensor[]
}

interface TextSizes {
  valueSize: React.CSSProperties['fontSize']
  labelSize: React.CSSProperties['fontSize']
}

export default function UpsDetails ({
  agentName,
  sensors
}: Props) {
  const { classes: sharedClasses } = useSharedStyles()
  const { ref, height, width } = useElementSize()
  const {
    ref: contentWrapperRef,
    height: contentWrapperHeight,
    width: contentWrapperWidth
  } = useElementSize()
  const gridSizeSum = width + height
  const textSizes: TextSizes = {
    valueSize: `${gridSizeSum / 60}px`,
    labelSize: `${gridSizeSum / 70}px`
  }

  const upsStatus = findSensorByType('UPS_STATUS', sensors)
  const upsRuntime = findSensorByType('UPS_RUNTIME', sensors)
  const batteryLevel = findSensorByType('BATTERY', sensors)
  const main = findSensorByType('MAINS_VOLTAGE', sensors)
  const load = findSensorByType('LOAD_KVA', sensors)

  const batteryLevelInnerData = {
    id: 1,
    value: convertStrToFloat(batteryLevel?.currentValue),
    color: '#E5AF59'
  }

  return (
    <Container
      ref={ref}
      style={{
        width: '100%',
        height: '87%'
      }}
      fluid
    >
      <Group className={sharedClasses.contentTitle} position="apart" noWrap>
        <Text size="sm" weight={500}>{agentName.toUpperCase()}</Text>
      </Group>
      <SimpleGrid className={sharedClasses.dock} cols={2} spacing="lg">
        <Stack align="center" justify="flex-start" spacing="lg">
          <GroupBoxValue
            label="Status"
            value={upsStatus?.currentValue ?? ''}
            color={upsStatus?.currentValue === 'DISCHARGING'
              ? 'red'
              : 'green'}
            height="142px"
            {...textSizes}
          />
          <SimpleGrid
            ref={contentWrapperRef}
            cols={1}
            style={{
              width: '100%',
              height: height - 150
            }}
          >
            <Text size="sm">Battery Level</Text>
            <Group position="center" align="center">
              <RadialGauge
                title={batteryLevel?.unit ?? ''}
                value={convertStrToFloat(batteryLevel?.currentValue)}
                innerData={batteryLevelInnerData}
                outerData={batteryLevelOuterData}
                height={contentWrapperHeight}
                width={contentWrapperWidth - 180}
              />
            </Group>
          </SimpleGrid>
        </Stack>
        <Stack justify="space-around" spacing="lg" >
          <GroupBoxValue
            label="Runtime"
            value={upsRuntime?.currentValue ?? '0'}
            color="green"
            {...textSizes}
          />
          <GroupBoxValue
            label="MAINS(V)"
            value={main?.currentValue ?? '0'}
            color="red"
            {...textSizes}
          />
          <GroupBoxValue
            label="Load (KVA)"
            value={load?.currentValue ?? '0'}
            color="green"
            {...textSizes}
          />
        </Stack>

      </SimpleGrid>
    </Container>
  )
}
