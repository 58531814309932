import { useState, useCallback } from 'react'
import { Button, Group, Stack, Title } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type OrgLogoType, deleteOrgLogo } from '@venturi-io/api/src/userManager/org'
import { useApi } from 'src/utils/useApi'
import { useNotifications } from 'src/utils/notifications'
import CustomImageDropzone from 'src/Input/ImageDropzone'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useUser } from 'src/UserContext'

interface Props {
  title: string
  type: OrgLogoType
  src?: string
  apiPath?: string
}

export default function Logo ({ title, type, src, apiPath }: Props) {
  const { token, orgId } = useUser()
  const remove = useApi(deleteOrgLogo)
  const [imageUrl, setImageUrl] = useState(src)
  const [showDelete, setShowDelete] = useState(false)
  const [uploading, setUploading] = useState(false)

  const { showError, showSuccess } = useNotifications()

  const inUploadMode = imageUrl?.includes('blob:')

  const handleUpload = useCallback(async () => {
    if (imageUrl) {
      setUploading(true)

      const imageResponse = await fetch(imageUrl)
      const logo = await imageResponse.blob()
      // used raw fetch termporarily to trigger upload org logo API
      // since it's expecting a form-data payload which is not yet supported on our createAPI
      const form = new FormData()
      form.append('type', type)
      form.append('logo', logo)

      const request = {
        method: 'POST',
        headers: {
          'X-Auth-Token': token
        },
        body: form
      }

      const result = await fetch(`${apiPath}/${orgId}`, request)
      setUploading(false)

      if (result.ok) {
        setImageUrl(src)
        showSuccess(`Uploaded new ${title}`)
      } else {
        showError(new Error('Failed to upload logo'))
      }
    }
  }, [imageUrl])

  const handleDelete = useCallback(() => {
    if (imageUrl && !inUploadMode) {
      void remove
        .fetch({ orgId, type }, token, 'Successfully deleted logo')
        .finally(() => {
          setImageUrl(undefined)
          setShowDelete(false)
        })
    }
  }, [imageUrl, inUploadMode])

  return (
    <>
      <Stack align="center">
        <Title order={3} align="left">{title}</Title>
        <CustomImageDropzone
          imageUrl={imageUrl}
          onChange={({ imageUrl }) => setImageUrl(imageUrl)}
          loading={uploading}
        />
        {imageUrl && (
          <Group>
            {inUploadMode && (
              <Button
                color="gray"
                variant="subtle"
                size="sm"
                leftIcon={<FontAwesomeIcon icon={['fas', 'upload']} />}
                compact
                onClick={handleUpload}
                disabled={uploading}
              >
                Upload
              </Button>
            )}
            {!inUploadMode && (
              <>
                <a href={imageUrl} download>
                  <Button
                    color="gray"
                    variant="subtle"
                    size="sm"
                    leftIcon={<FontAwesomeIcon icon={['fas', 'download']} />}
                    compact
                  >
                    Download
                  </Button>
                </a>
                <Button
                  color="gray"
                  variant="subtle"
                  size="sm"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} />}
                  compact
                  onClick={() => setShowDelete(true)}
                  disabled={remove.loading}
                >
                  Delete
                </Button>
              </>
            )}
          </Group>
        )}
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${title}"`}
        loading={remove.loading}
        question="Are you sure you want to delete this logo? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
