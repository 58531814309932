import { type Layouts } from 'react-grid-layout'
import { MODULE_CONTENT } from './constants'

export const defaultLayouts: Layouts = {
  xl: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 7, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 7, y: 0, w: 5, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 7, y: 1, w: 5, h: 1 }
  ],
  lg: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 7, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 7, y: 0, w: 5, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 7, y: 1, w: 5, h: 1 }
  ],
  md: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 7, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 7, y: 0, w: 5, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 7, y: 1, w: 5, h: 1 }
  ],
  sm: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 6, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 0, y: 2, w: 6, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 0, y: 3, w: 6, h: 1 }
  ],
  xs: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 6, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 0, y: 2, w: 6, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 0, y: 3, w: 6, h: 1 }
  ],
  xxs: [
    { i: MODULE_CONTENT.ATS_STATUS, x: 0, y: 0, w: 6, h: 2, static: true, isDraggable: true },
    { i: MODULE_CONTENT.S1_VOLTAGE, x: 0, y: 2, w: 6, h: 1 },
    { i: MODULE_CONTENT.S2_VOLTAGE, x: 0, y: 3, w: 6, h: 1 }
  ]
}
