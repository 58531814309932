/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Group, Text, createStyles, useMantineTheme } from '@mantine/core'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { mq } from 'src/utils/style'
import { useMediaQuery } from '@mantine/hooks'
import AlarmIndicator from '../AlarmIndicator'

interface Props {
  fuelLevelLiterAlarms: Alarm[]
  fuelCapacityLiter: number
  fuelLevelPercent: number
  withAlarm?: boolean
  isCriticalAlarm?: boolean
}

const useStyles = createStyles(() => ({
  alarmsLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    div: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 20
    },
    span: {
      paddingTop: 5
    }
  },
  textStyles: {
    color: '#212121',
    lineHeight: '14px'
  }
}))

export default function MarkersAnnotationLeft ({
  fuelLevelLiterAlarms,
  fuelCapacityLiter,
  fuelLevelPercent,
  withAlarm,
  isCriticalAlarm
}: Props) {
  const { classes } = useStyles()
  const { breakpoints: { md } } = useMantineTheme()
  const isMediumScreen = useMediaQuery(mq(md, false))

  const closestAlarm = (fuelLevelLiterAlarms: Alarm[], goal: number) => {
    const getAllSetpoint = fuelLevelLiterAlarms.map(alarm => Math.floor((alarm.setPoint / fuelCapacityLiter) * 100))
    return getAllSetpoint.reduce((prev: number, curr: number) =>
      Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
  }

  return (
    <>
      <div className={classes.alarmsLabel}>
        {fuelLevelLiterAlarms
          ?.map(alarm => {
            const alarmPos = Math.floor((alarm.setPoint / fuelCapacityLiter) * 100)
            return (
              <Text
                size={12}
                weight={400}
                key={alarm.setPoint}
                className={classes.textStyles}
              >
                <span>
                  {alarm.name}
                </span>
                {' '}
                <span>
                  {'>'}
                  {alarmPos}
                  {' '}
                  % •
                  {' '}
                  {alarm.setPoint}
                  {' '}
                  L
                </span>
              </Text>
            )
          })
          .sort((a, b) => Number(b.key) - Number(a.key))}
      </div>
      <Text
        size={12}
        weight={400}
        key={fuelCapacityLiter * 0.95}
        classNames={classes.textStyles}
        style={{
          position: 'absolute',
          bottom: (95 - 2) + '%'
        }}
      >
        Safe Fill
      </Text>
      {fuelLevelLiterAlarms?.map(alarm => {
        const alarmPos = Math.floor((alarm.setPoint / fuelCapacityLiter) * 100)
        return (
          <Text
            size={12}
            weight={400}
            key={alarm.setPoint}
            className={classes.textStyles}
            style={{
              position: 'absolute',
              bottom: (alarmPos - ((withAlarm && !isMediumScreen) ? 3 : 2)) + '%',
              right: withAlarm && !isMediumScreen ? '-1rem' : 0
            }}
          >
            {!isMediumScreen
              ? (withAlarm && ((fuelLevelPercent <= alarmPos) &&
                (alarmPos === closestAlarm(fuelLevelLiterAlarms, fuelLevelPercent))
                )) && (
                  <Group
                    position="center"
                    align="flex-start"
                    spacing={5}
                  >
                    <span
                      style={{
                        background: isCriticalAlarm
                          ? '#E03131'
                          : '#FFE066'
                      }}
                    />
                    <AlarmIndicator
                      size={24}
                      alarmColor={isCriticalAlarm
                        ? 'red'
                        : 'yellow'}
                    />
                  </Group>
                )
              : ((fuelLevelPercent <= alarmPos) &&
                (alarmPos === closestAlarm(fuelLevelLiterAlarms, fuelLevelPercent))
                ) && alarm.name}
          </Text>
        )
      })}
    </>
  )
}
