import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Sensor } from '@venturi-io/api/src/config/agent'

export interface Props {
  battery: Sensor
}

export default function Battery ({ battery: { currentValue } }: Props) {
  const value = parseFloat(currentValue ?? '0')

  switch (true) {
    case value > 75:
      return <FontAwesomeIcon icon={['fas', 'battery-full']} color="green" size="3x" />
    case value > 50:
      return <FontAwesomeIcon icon={['fas', 'battery-three-quarters']} color="green" size="3x" />
    case value > 30:
      return <FontAwesomeIcon icon={['fas', 'battery-half']} color="green" size="3x" />
    case value > 10:
      return <FontAwesomeIcon icon={['fas', 'battery-quarter']} color="yellow" size="3x" />
    default:
      return <FontAwesomeIcon icon={['fas', 'battery-empty']} color="red" size="3x" />
  }
}
