import { useEffect } from 'react'
import { Box } from '@mantine/core'
import {
  getAlarmIncidents,
  getAlarmIncidentsForSite
} from '@venturi-io/api/src/analytics/alarmIncident'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import Nothing from 'src/Nothing'
import Table from './Table'

interface Props {
  siteId?: number
}

export default function Incidents ({ siteId }: Props) {
  const alarms = usePaginatedApi(getAlarmIncidents)
  const alarmsForSite = usePaginatedApi(getAlarmIncidentsForSite)
  const { orgId, token } = useUser()
  const api = siteId ? alarmsForSite : alarms

  const loadAlarmsForOrg = (page?: number, size?: number) => {
    const req = {
      orgId,
      page: page ?? alarms.page,
      size: size ?? alarms.size
    }

    void alarms
      .fetch(req, token)
      .finally(() => {
        alarms.stopPolling()
        alarms.startPolling(req, token, 60)
      })

    return () => {
      alarms.stopPolling()
      alarms.abort()
      alarmsForSite.stopPolling()
      alarmsForSite.abort()
    }
  }

  const loadAlarmsForSite = (page?: number, size?: number) => {
    const req = {
      siteId: siteId ?? -1,
      page: page ?? alarmsForSite.page,
      size: size ?? alarmsForSite.size
    }

    void alarmsForSite
      .fetch(req, token)
      .finally(() => {
        alarmsForSite.stopPolling()
        alarmsForSite.startPolling(req, token, 60)
      })

    return () => {
      alarmsForSite.stopPolling()
      alarmsForSite.abort()
    }
  }

  useEffect(() => {
    if (siteId) {
      loadAlarmsForSite(1, 20)

      return
    }

    loadAlarmsForOrg(1, 20)
  }, [])

  return (
    <div>
      {api.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={api.loading}
            nothing={api.data.isNothing()}
            placeholder="No incidents retrieved for this organisation"
          />
        ),
        Just: data => (
          <>
            <Table incidents={data.items} />
            <Box sx={theme => ({ marginTop: theme.spacing.sm })}>
              <Pagination
                color="primary"
                value={api.page}
                onChange={page => {
                  siteId
                    ? loadAlarmsForSite(page, api.size)
                    : loadAlarmsForOrg(page, api.size)
                }}
                total={data.totalPages}
              />
            </Box>
          </>
        )
      })}
    </div>
  )
}
