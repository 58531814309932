import React, { type ReactNode } from 'react'
import { Group, Stack } from '@mantine/core'
import { type DateTimePickerProps } from '@mantine/dates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateTimePicker from './DateTimePicker'

interface Props {
  fromProps: DateTimePickerProps
  toProps: DateTimePickerProps
  error?: ReactNode | JSX.Element | null
}

export default function DateTimeRangePicker ({ fromProps, toProps, error }: Props) {
  return (
    <Stack spacing={0}>
      <Group>
        <DateTimePicker {...fromProps} />
        <FontAwesomeIcon icon={['far', 'dash']} />
        <DateTimePicker {...toProps} />
      </Group>
      {error}
    </Stack>
  )
}
