import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type AssetType, type Sensor, agent } from './agent'
import { type PaginatedRequest, paginated } from '../shared'

const agentTemplate = agent
  .pick({
    agentId: true,
    agentName: true,
    assetType: true
  })
  .extend({
    template: z.boolean()
  })

export type AgentTemplate = z.infer<typeof agentTemplate>

const allAgentTemplates = paginated.extend({
  items: z.array(agentTemplate)
})

const createdAgentTemplate = agent.pick({
  agentId: true,
  agentName: true,
  gpsSetting: true,
  transmitInterval: true,
  measurementInterval: true,
  simulator: true,
  sensors: true,
  metadata: true,
  hasImmobiliser: true
})

interface CreateAgentTemplateRequest extends Record<string, unknown> {
  assetType: AssetType
  sensors: Array<{ id: Sensor['id'] }>
}

export const createAgentTemplate = createStatefulApi<CreateAgentTemplateRequest, typeof createdAgentTemplate>(
  'config',
  '/agents/templates',
  { method: 'POST' },
  createdAgentTemplate
)

export const getAgentTemplates = createStatelessApi<PaginatedRequest, typeof allAgentTemplates>(
  'config',
  '/agents/templates',
  { method: 'GET', type: 'path' },
  allAgentTemplates
)
