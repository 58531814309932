/* eslint-disable max-len */
import { PDFViewer } from '@react-pdf/renderer'
import { Modal } from '@mantine/core'
import { type Range } from '@venturi-io/api'
import { type AgentTripItem } from '../'
import AgentTripHistory from './AgentTripHistory'

export interface Props {
  agentName: string
  data: AgentTripItem[]
  dateRange: Range | null
  totalTrips: string
  totalDuration: string
  totalDistance: string
  showPdfViewer: boolean
  toggleShowPdfViewer: () => void
}

export default function ExportToPdf ({
  agentName,
  data,
  dateRange,
  totalTrips,
  totalDuration,
  totalDistance,
  showPdfViewer,
  toggleShowPdfViewer
}: Props) {
  return (
    <Modal
      title="Exporting to pdf..."
      size="auto"
      opened={showPdfViewer}
      onClose={toggleShowPdfViewer}
    >
      <PDFViewer width="1000" height="650">
        <AgentTripHistory
          agentName={agentName}
          data={data}
          dateRange={dateRange}
          totalTrips={totalTrips}
          totalDuration={totalDuration}
          totalDistance={totalDistance}
        />
      </PDFViewer>
    </Modal>
  )
}
