import { memo, useCallback, useMemo } from 'react'
import {
  Accordion,
  Box,
  Button,
  Group,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import ColorInput from 'src/Input/ColorInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultStyles as defaultPercentGraphStyles } from 'src/Charts/PercentGraph'
import SelectAgent from 'src/Input/Select/SelectAgent'
import SelectAgentSensor from 'src/Input/Select/SelectAgentSensor'
import ItemPreview from '../shared/ItemPreview'
import SensorPercentage, { type Props as SensorPercentageProps } from '.'

interface Props {
  initialValues: SensorPercentageProps | null
  onSubmit: (properties: SensorPercentageProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const { colors } = useMantineTheme()
  const focusTrapRef = useFocusTrap()

  const formValues: SensorPercentageProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        agentId: -1,
        sensorInstanceId: -1
      },
      indicators: {
        warning: {
          high: undefined,
          low: undefined
        },
        critical: {
          high: undefined,
          low: undefined
        }
      },
      styles: {
        ...defaultPercentGraphStyles,
        theme: {
          textColor: colors.dark[6],
          barColor: colors.blue[5],
          barWarningColor: colors.yellow[5],
          barCriticalColor: colors.red[5],
          backgroundColor: colors.white[0]
        }
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const orientations = useMemo(() => (
    [
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              rotation={270}
              icon={['fas', 'battery-three-quarters']}
              color="primary"
            />
            <Text size="sm">Vertical</Text>
          </Group>
        ),
        value: 'vertical'
      },
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'battery-three-quarters']}
              color="primary"
            />
            <Text size="sm">Horizontal</Text>
          </Group>
        ),
        value: 'horizontal'
      }
    ]
  ), [])

  const hasSelectedAgent = useMemo(() => form.values.data.agentId > 0, [form.values.data.agentId])

  const hasSelectedSensorInstance = useMemo(() => (
    form.values.data.sensorInstanceId > 0
  ), [form.values.data.sensorInstanceId])

  const previewItem = useMemo(() => (
    hasSelectedAgent && hasSelectedSensorInstance
  ), [hasSelectedAgent, hasSelectedSensorInstance])

  const setFormDataValue = useCallback((
    key: keyof SensorPercentageProps['data'],
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormStylesValue = useCallback((
    key: keyof SensorPercentageProps['styles'],
    value: unknown
  ) => {
    form.setValues({
      ...form.values,
      styles: {
        ...form.values.styles,
        [key]: value
      }
    })
  }, [form.values.styles])

  const setFormIndicatorsValue = useCallback((
    key: keyof SensorPercentageProps['indicators'],
    value: unknown
  ) => {
    form.setFieldValue('indicators', {
      ...form.values.indicators,
      [key]: value
    })
  }, [form.values.indicators])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="signal-bars" previewItem={previewItem}>
        <SensorPercentage {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectAgent
                    label="Agent"
                    value={form.values.data.agentId.toString()}
                    onChange={agentId => setFormDataValue('agentId', Number(agentId))}
                    required
                    searchable
                    error={form.errors.agentId}
                  />
                  {hasSelectedAgent && (
                    <SelectAgentSensor
                      agentId={form.values.data.agentId}
                      label="Sensor"
                      value={(
                        hasSelectedSensorInstance
                          ? form.values.data.sensorInstanceId.toString()
                          : null
                      )}
                      onChange={sensorInstanceId => setFormDataValue('sensorInstanceId', Number(sensorInstanceId))}
                      required
                      searchable
                      error={form.errors.sensorInstanceId}
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="indicators">
              <Accordion.Control>Indicators</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <Box>
                    <Text size="xs">Warning Level</Text>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        placeholder="Minimum"
                        value={(
                            typeof form.values.indicators.warning === 'object'
                              ? form.values.indicators.warning.low
                              : undefined
                        )}
                        onChange={value => {
                          setFormIndicatorsValue('warning', (
                            typeof form.values.indicators.warning === 'object'
                              ? {
                                  ...form.values.indicators.warning,
                                  low: value
                                }
                              : undefined
                          ))
                        }}
                      />
                      <NumberInput
                        placeholder="Maximum"
                        value={(
                            typeof form.values.indicators.warning === 'object'
                              ? form.values.indicators.warning.high
                              : undefined
                        )}
                        onChange={value => {
                          setFormIndicatorsValue('warning', (
                            typeof form.values.indicators.warning === 'object'
                              ? {
                                  ...form.values.indicators.warning,
                                  high: value
                                }
                              : undefined
                          ))
                        }}
                      />
                    </SimpleGrid>
                  </Box>
                  <Box>
                    <Text size="xs">Critical Level</Text>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        placeholder="Minimum"
                        value={(
                            typeof form.values.indicators.critical === 'object'
                              ? form.values.indicators.critical.low
                              : undefined
                        )}
                        onChange={value => {
                          setFormIndicatorsValue('critical', (
                            typeof form.values.indicators.critical === 'object'
                              ? {
                                  ...form.values.indicators.critical,
                                  low: value
                                }
                              : undefined
                          ))
                        }}
                      />
                      <NumberInput
                        placeholder="Maximum"
                        value={(
                            typeof form.values.indicators.critical === 'object'
                              ? form.values.indicators.critical.high
                              : undefined
                        )}
                        onChange={value => {
                          setFormIndicatorsValue('critical', (
                            typeof form.values.indicators.critical === 'object'
                              ? {
                                  ...form.values.indicators.critical,
                                  high: value
                                }
                              : undefined
                          ))
                        }}
                      />
                    </SimpleGrid>
                  </Box>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="styles">
              <Accordion.Control>Styles</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <Stack spacing="xs">
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Top"
                        placeholder="Padding Top"
                        value={form.values.styles.padding.top}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            top: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Bottom"
                        placeholder="Padding Bottom"
                        value={form.values.styles.padding.bottom}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            bottom: value
                          })
                        }}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Left"
                        placeholder="Padding Left"
                        value={form.values.styles.padding.left}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            left: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Right"
                        placeholder="Padding Right"
                        value={form.values.styles.padding.right}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            right: value
                          })
                        }}
                      />
                    </SimpleGrid>
                  </Stack>

                  <Box>
                    <Text size="xs">Orientation</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.orientation}
                      data={orientations}
                      onChange={value => setFormStylesValue('orientation', value)}
                    />
                  </Box>

                  <Stack spacing="xs">
                    <ColorInput
                      name="textColor"
                      label="Text Color"
                      placeholder="Pick a color"
                      value={form.values.styles.theme?.textColor}
                      onChange={value => {
                        setFormStylesValue('theme', {
                          ...form.values.styles.theme,
                          textColor: value
                        })
                      }}
                    />
                    <Box>
                      <Text size="xs">Bar Colors</Text>
                      <SimpleGrid cols={3} spacing="md">
                        <ColorInput
                          name="barColor"
                          placeholder="Default"
                          value={form.values.styles.theme?.barColor}
                          onChange={value => {
                            setFormStylesValue('theme', {
                              ...form.values.styles.theme,
                              barColor: value
                            })
                          }}
                        />
                        <ColorInput
                          name="barWarningColor"
                          placeholder="Warning"
                          value={form.values.styles.theme?.barWarningColor}
                          onChange={value => {
                            setFormStylesValue('theme', {
                              ...form.values.styles.theme,
                              barWarningColor: value
                            })
                          }}
                        />
                        <ColorInput
                          name="barCriticalColor"
                          placeholder="Critical"
                          value={form.values.styles.theme?.barCriticalColor}
                          onChange={value => {
                            setFormStylesValue('theme', {
                              ...form.values.styles.theme,
                              barCriticalColor: value
                            })
                          }}
                        />
                      </SimpleGrid>
                    </Box>
                  </Stack>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
