import { type ReactElement } from 'react'
import {
  type MantineColor,
  Group,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconAlertOctagon, IconPlugConnected, IconWifiOff } from '@tabler/icons-react'
import colors from 'src/AssetTemplate/colors'
import dayjs from 'dayjs'

export type Status = 'CONNECTED' | 'DISCONNECTED' | 'ERROR'

const statusIcon: Record<Status, ReactElement> = {
  CONNECTED: <IconPlugConnected size={12} stroke={2} />,
  DISCONNECTED: <IconWifiOff size={12} stroke={2} />,
  ERROR: <IconAlertOctagon size={12} stroke={2} />
}

const statusColor: Record<Status, MantineColor> = {
  CONNECTED: colors.green,
  DISCONNECTED: colors.gray,
  ERROR: colors.red
}

interface Props {
  status: Status
  duration?: string
  lastDataTransfer?: string
}

export default function ConnectivityStatus ({ status, duration, lastDataTransfer }: Props) {
  return (
    <Stack
      spacing={8}
      sx={{
        width: '100%'
      }}
    >
      <Group
        position="apart"
        align="center"
        sx={{
          width: '100%'
        }}
      >
        <Group spacing={4} position="center" align="center">
          <ThemeIcon
            radius="xl"
            size="xs"
            color={statusColor[status]}
            sx={{
              stroke: 'blue',
              fill: 'red',
              strokeWidth: '10px'
            }}
          >
            {statusIcon[status]}
          </ThemeIcon>
          <Text
            size={12}
            weight={600}
            color={statusColor[status]}
          >
            {status.toUpperCase()}
          </Text>
        </Group>
        <Text size={12} weight={500}>
          {duration}
        </Text>
      </Group>
      {lastDataTransfer && (
        <Group
          pl={20}
          position="apart"
          align="center"
          sx={{
            width: '100%'
          }}
        >
          <Text size={12} weight={400} color="dark.3">
            Last data transfer
          </Text>
          <Text size={12} weight={500}>
            {dayjs(lastDataTransfer).fromNow()}
          </Text>
        </Group>
      )}
    </Stack>
  )
}
