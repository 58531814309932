import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getGeoZoneActionTypes, getGeoZoneActions } from '@venturi-io/api/src/config/geoZoneAction'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateGeoZoneAction from './Create'

export default function GeoZoneActions () {
  const { orgId, token } = useUser()
  const geoZoneActionTypes = useApi(getGeoZoneActionTypes)
  const geoZoneActions = usePaginatedApi(getGeoZoneActions)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)
  const actionTypes = geoZoneActionTypes.data.mapOrDefault(data => data, {})

  const loadGeoZoneActions = async (page?: number, size?: number): Promise<void> => {
    void geoZoneActions
      .fetch({
        orgId,
        page: page ?? geoZoneActions.page,
        size: size ?? geoZoneActions.size,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadGeoZoneActions(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadGeoZoneActions()
  }, [geoZoneActions.page])

  useEffect(() => {
    void geoZoneActionTypes.fetch({}, token)
  }, [])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create New Action"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new Action
        </Button>
      </Group>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search geozone action here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={geoZoneActions.loading}
        data={geoZoneActions.data.mapOrDefault(({ items }) => (
          items.map(({ action, ...data }) => ({
            ...data,
            action: actionTypes[action] as string
          }))
        ), [])}
        getId={({ geoZoneActionId }) => geoZoneActionId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={geoZoneActions.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={geoZoneActions.page}
        onPageChange={geoZoneActions.setPage}
      />
      <CreateGeoZoneAction
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadGeoZoneActions}
      />
    </>
  )
}
