import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import { getAlarmWebhook, type AlarmWebhook } from '@venturi-io/api/src/analytics/alarmWebhook'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from './Form'

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  alarmWebhookId: string
}

export default function Edit () {
  const { alarmWebhookId: id } = useParams<RouteParams>()
  const { token } = useUser()
  const { classes } = useStyles()
  const [formInitialValues, setFormInitialValues] = useState<AlarmWebhook>()
  const alarmWebhook = useApi(getAlarmWebhook)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const loadAlarmWebhook = () => {
    void alarmWebhook.fetch({ webhookId: parseInt(id) }, token)
  }

  useEffect(() => {
    loadAlarmWebhook()
  }, [])

  useEffect(() => {
    alarmWebhook.data.ifJust(setFormInitialValues)
  }, [alarmWebhook.data])

  return (
    <Paper className={classes.container} relative>
      {alarmWebhook.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={alarmWebhook.loading}
            nothing={alarmWebhook.data.isNothing()}
          />
        ),
        Just: () => <Form mode="EDIT" initialValues={formInitialValues} />
      })}
    </Paper>
  )
}
