import { type Sensor } from '@venturi-io/api/src/config/agent'
import { Box, Stack } from '@mantine/core'
import { findSensor } from 'src/AssetTemplate/shared'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'
import SensorHistoryBarChart from 'src/AssetTemplate/Components/Charts/SensorHistoryBarChart'

interface Props {
  sensors: Sensor[]
}

export default function Fuel ({ sensors }: Props) {
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)

  return (
    <Box>
      <Stack spacing={28}>
        {fuelLevel && (
          <SensorHistoryLineChart
            title="Fuel Level"
            sensorInstanceId={fuelLevel.sensorInstanceId}
            unit={fuelLevel?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
        {fuelConsumption && (
          <SensorHistoryBarChart
            title="Fuel Consumption"
            sensorInstanceId={fuelConsumption.sensorInstanceId}
            unit={fuelConsumption?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
      </Stack>
    </Box>
  )
}
