import { memo, useEffect, useMemo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import dayjs, { type ManipulateType } from 'dayjs'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import Area, { type Props as AreaProps } from 'src/Charts/Area'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data: {
    agentId: Agent['agentId']
    sensorInstanceId: Sensor['sensorInstanceId']
    bucket: ChosenBucket<ItemKeys>
  }
  styles: AreaProps['styles']
}

const SensorHistory = ({
  id,
  data: {
    agentId,
    sensorInstanceId,
    bucket
  },
  styles
}: Props) => {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const allSensorsData = useApi(getSensorData)

  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensor = useMemo(() => (
    agent?.sensors.find(s => s.sensorInstanceId === sensorInstanceId) ?? null
  ), [agent, sensorInstanceId])

  const sensorData = useMemo(() => (
    allSensorsData.data.mapOrDefault(({ result }) => (
      result[0].data.map(({ x, y }) => ({
        x,
        y: typeof y !== 'undefined' ? y : 0
      }))
    ), [])
  ), [allSensorsData.data])

  const isLoading = useMemo(() =>
    agentDetails.loading || allSensorsData.loading,
  [agentDetails.loading, allSensorsData.loading])

  useEffect(() => {
    if (sensor === null) return

    const time = bucket.item.split(' ')
    const req = {
      sensorInstanceIds: [sensor.sensorInstanceId],
      startTime: dayjs().subtract(Number.parseInt(time[0]), time[1] as ManipulateType).format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: bucket.data.noOfRecords
    }

    void allSensorsData.fetch(req, token)
  }, [sensor, bucket])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      {isLoading && <Loader variant="bars" />}
      {(!isLoading && agent && sensor) && (
        <Area
          id={id}
          title={sensor.name}
          group={agent.agentName}
          data={sensorData}
          styles={{
            ...styles,
            width,
            height
          }}
        />
      )}
    </Container>
  )
}

export default memo(SensorHistory)
