import { createStyles } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
export interface Sensors {
  sensors: Sensor[]
}

export const sensorTypes = {
  ATS_STATUS: 167,
  BATTERY: 7,
  BATTERY_VOLTAGE: 42,
  CHARGER_STATUS: 45,
  CHARGE_ALTERNATOR_VOLTAGE: 57,
  CONTROL_MODE: 147,
  COOLANT_TEMPERATURE: 54,
  ENGINE_BATTERY_VOLTAGE: 58,
  ENGINE_IGNITION_STATUS: 538,
  ENGINE_OPERATE_MODE: 76,
  ENGINE_RUN_TIME: 81,
  ENGINE_SPEED: 59,
  EXT_POWER_SOURCE_VOLTAGE: 537,
  FUEL_CAPACITY: 154,
  FUEL_CONSUMPTION: 71,
  FUEL_LEVEL: 56,
  FUEL_LEVEL_L: 177,
  GENERATOR_L1N_VOLTAGE: 61,
  GENERATOR_L2N_VOLTAGE: 62,
  GENERATOR_L3N_VOLTAGE: 63,
  GENERATOR_L1L2_VOLTAGE: 64,
  GENERATOR_L1L3_VOLTAGE: 65,
  GENERATOR_L3L1_VOLTAGE: 66,
  GENERATOR_L1_CURRENT: 67,
  GENERATOR_L2_CURRENT: 68,
  GENERATOR_L3_CURRENT: 69,
  PERCENTAGE_LOAD_AT_SPEED: 73,
  HUMIDITY: 3,
  TIME_TO_NEXT_MAINTENANCE: 80,
  LOAD: 86,
  LOAD_KVA: 176,
  MAINS_VOLTAGE: 43,
  OIL_PRESSURE: 79,
  POSITION_ALTITUDE: 532,
  POSITION_DIRECTION: 533,
  POSITION_HDOP: 534,
  POSITION_SATELLITES: 535,
  POSITION_SPEED: 536,
  S1_AVAILABLE: 165,
  S1_SWITCH: 163,
  S2_AVAILABLE: 166,
  S2_SWITCH: 164,
  S1_VOLTAGE_L1N: 168,
  S1_VOLTAGE_L2N: 169,
  S1_VOLTAGE_L3N: 170,
  S2_VOLTAGE_L1N: 171,
  S2_VOLTAGE_L2N: 172,
  S2_VOLTAGE_L3N: 173,
  UPS_STATUS: 174,
  UPS_RUNTIME: 175,
  POWER_STATUS: 153,
  ACTIVE_ENERGY_LINE_1: 190,
  ACTIVE_ENERGY_LINE_2: 193,
  ACTIVE_ENERGY_LINE_3: 194,
  ACTIVE_THREE_PHASE: 199,
  POSITIVE_ACTIVE_THREE_PHASE: 200,
  NEGATIVE_ACTIVE_THREE_PHASE: 201,
  RMS_LINE_CURRENT_L1: 186,
  RMS_LINE_CURRENT_L2: 221,
  RMS_LINE_CURRENT_L3: 222,
  RMS_VOLTAGE_L1N: 185,
  RMS_VOLTAGE_L2N: 218,
  RMS_VOLTAGE_L3N: 219,
  RMS_ENERGY_L1: 202,
  RMS_ENERGY_L2: 203,
  RMS_ENERGY_L3: 204,
  RMS_AVG_VALUE_VOLTAGE: 220,
  ACTIVE_ENERGY_LINE_2_NEGATIVE: 193,
  ACTIVE_ENERGY_LINE_1_POSITIVE: 190,
  POWER_FACTOR_THREE_PHASE: 226,
  REACTIVE_ENERGY_THREE_PHASE: 205,
  RMS_LINE_CURRENT_LN: 223,
  IMMOBILISATION_STATUS: 551,
  DIGITAL_INPUT_2_DURESS: 543
} as const

export type SensorType = keyof typeof sensorTypes

export const findSensorByType = (sensorType: SensorType, sensors: Sensor[]): Sensor | null => {
  const sensor = sensors.find(sensor => sensor.id === sensorTypes[sensorType])

  if (!sensor) {
    return null
  }

  return sensor
}

export const useSharedStyles = createStyles(theme => ({
  center: { margin: '0 auto' },
  dock: {
    width: '100%',
    height: '100%',
    padding: '0 .5px'
  },
  contentTitle: {
    margin: '0px 0px 15px 0px'
  },
  select: {
    input: {
      fontSize: '.7rem'
    },
    width: '100px'
  },
  statusBarContainer: {
    marginTop: '-10px'
  },
  statusGaugeContainer: {
    height: '50%',
    width: '20%',
    marginTop: '0'
  },
  gaugeSubLabel: {
    position: 'absolute',
    bottom: 10,
    left: 70
  },
  checkbox: {
    input: {
      backgroundColor: 'white',
      '&:checked': {
        backgroundColor: theme.colors.primary[5],
        border: `1px solid ${theme.colors.primary[5]}`
      }
    }
  }
}))

export const numberWithCommas = (x: number) => {
  return x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertStrToFloat = (value: string | undefined): number => value ? parseFloat(value) : 0

export const computeRelativePercentage = (currentValue: number, min: number, max: number): number => {
  const range = max - min
  const correctedStartValue = currentValue - min

  return (correctedStartValue * 100) / range
}

export const computeSensorPercentage = (sensor: Sensor) => {
  const currentVal = sensor?.currentValue ? parseFloat(sensor.currentValue) : 0
  const maxVal = sensor?.maxValue ? sensor.maxValue : 100

  return (currentVal / maxVal) * 100
}

export const barColor = (input: string | undefined) => {
  if (input === 'ALARM') return 'red'
  if (input === 'WARNING') return 'yellow'
  return 'green'
}

export const gridColumns = {
  xl: 12,
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2
}

export const secondsToDhms = (seconds: number) => {
  const y = Math.floor(seconds / (3600 * 24 * 365))
  const d = Math.floor(seconds % (3600 * 24 * 365) / (3600 * 24))
  const h = Math.floor(seconds % (3600 * 24) / 3600)
  const m = Math.floor(seconds % 3600 / 60)
  const s = Math.floor(seconds % 60)

  const yDisplay = y > 0 ? `${y}y ` : ''
  const dDisplay = d > 0 ? `${d}d ` : ''
  const hDisplay = h > 0 ? `${h}h ` : ''
  const mDisplay = m > 0 ? `${m}m ` : ''
  const sDisplay = s > 0 ? `${s}s ` : ''
  const total = yDisplay + dDisplay + hDisplay + mDisplay + sDisplay
  return total.trim() !== ''
    ? total
    : '0s'
}

export const powerStatuses = [
  'OFF',
  'ON',
  'ALARM'
] as const

export type PowerStatus = typeof powerStatuses[number]

export const powerStatusColor: Record<PowerStatus, string> = {
  OFF: 'gray',
  ON: 'green',
  ALARM: 'red'
}

export const radialOuterData = [
  {
    id: 1,
    value: 33,
    color: '#5FB359'
  },
  {
    id: 2,
    value: 33,
    color: '#E5AF59'
  },
  {
    id: 3,
    value: 33,
    color: '#A62219'
  }
]

// Checked if sensors are null then return their sensor name as array of strings
export const findMissingSensors = (requiredSensors: SensorType[], sensors: Sensor[]) => {
  let missingSensors: string[] = []

  if (requiredSensors.length > 0) {
    requiredSensors.map(sensor => {
      const checkSensor = findSensorByType(sensor, sensors)
      if (checkSensor === null) {
        missingSensors = [...missingSensors, sensor]
      }

      return missingSensors
    })
  }

  const renameSensors = missingSensors
    .map(sensors => sensors.replaceAll('_', ' '))
  const filteredMissingSensors = renameSensors
    .filter((val, ind) => renameSensors.indexOf(val) === ind)

  return filteredMissingSensors
}

export const rowHeight = 300
export const maxRowHeight = 760

export const getHeight = (height: number) => {
  let newHeight = height / rowHeight
  if (height > maxRowHeight) {
    newHeight = 2
  }
  return newHeight
}

export const forceToTenths = (value: string | number) => (
  Number(value) < 10
    ? '0'
    : ''
) + `${value}`
