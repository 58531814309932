import { type NodeTypes } from 'react-flow-renderer'
import DefaultDual from './DefaultDual'
import DefaultTriple from './DefaultTriple'

const nodeTypes: NodeTypes = {
  ifThenElse: DefaultDual,
  customScript: DefaultTriple
}

export default nodeTypes
