import { type ThemeIconProps, ThemeIcon } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import colors from 'src/AssetTemplate/colors'

interface Props {
  size: ThemeIconProps['size']
  alarmColor: string
}

type Colors =
  'green' |
  'yellow' |
  'red' |
  'gray'

export default function AlarmIndicator ({ size, alarmColor }: Props) {
  return (
    <ThemeIcon
      size={size}
      p={4}
      color={colors[alarmColor as Colors]}
      radius={2}
    >
      <IconAlertTriangle
        size={20}
        color={alarmColor === 'red'
          ? 'white'
          : 'black'}
      />
    </ThemeIcon>
  )
}
