import { forwardRef, type HTMLProps, type ReactNode } from 'react'
import { Table as MantineTable } from '@mantine/core'
import { createStyles } from '@mantine/styles'
import { columnStyle } from '../shared'

const useStyles = createStyles(() => ({
  table: {
    thead: {
      position: 'relative'
    },
    tbody: {
      position: 'relative',
      display: 'block'
    },
    tr: {
      display: 'flex',
      'th:nth-child(1),td:nth-child(1)': {
        width: '20%'
      },
      'th:nth-child(2),td:nth-child(2)': {
        width: '65%'
      },
      'th:nth-child(3),td:nth-child(3)': {
        width: '15%'
      }
    }
  }
}))

interface ThProps {
  children: ReactNode
}

const Th = ({ children }: ThProps) => (
  <th style={columnStyle}>{children}</th>
)

const Table = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function Inner ({ children, ...rest }, ref) {
    const { classes } = useStyles()

    return (
      <div {...rest} ref={ref}>
        <MantineTable highlightOnHover horizontalSpacing="xl" className={classes.table}>
          <thead>
            <tr>
              <Th>Timestamp</Th>
              <Th>Log</Th>
              <Th>Severity</Th>
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </MantineTable>
      </div>
    )
  }
)

export default Table
