import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  createStyles,
  Button,
  Col,
  Grid,
  Group
} from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  deleteAsset,
  getAsset,
  getAssetTypes,
  updateAsset,
  type Asset
} from '@venturi-io/api/src/config/asset'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import NotFound from 'src/Router/NotFound'
import SelectAssetType from './SelectAssetType'

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const asset = useApi(getAsset)
  const editAsset = useApi(updateAsset)
  const removeAsset = useApi(deleteAsset)
  const assetTypes = useApi(getAssetTypes)
  const [showDelete, setShowDelete] = useState(false)
  const navigate = useNavigate()
  const { token } = useUser()
  const { assetId: id } = useParams<RouteParams>()
  const { classes } = useStyles()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const assetId = parseInt(id)

  const form = useForm<Asset>({
    initialValues: {
      id: assetId,
      name: '',
      assetTypeId: -1
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null,
      assetTypeId: value => value === -1 ? 'Please choose a Asset Type' : null
    }
  })

  const redirectToListPage = () => navigate('/settings/assets')

  const handleSubmit = ({ id, ...values }: typeof form.values) => {
    void editAsset
      .fetch({
        assetId: id,
        ...values
      }, token, 'Successfully updated asset')
      .finally(redirectToListPage)
  }

  const handleDelete = () => {
    void removeAsset
      .fetch({ assetId }, token, 'Succesfully deleted asset')
      .finally(redirectToListPage)
  }
  const selectAssetTypes = assetTypes.data.mapOrDefault(types => types, [])

  const loadAsset = () => {
    void asset.fetch({ assetId: parseInt(id) }, token)
  }

  useEffect(() => {
    void assetTypes.fetch({}, token)
    loadAsset()
  }, [])

  useEffect(() => {
    asset.data.ifJust(data => {
      form.setValues({
        id: data.id,
        name: data.name,
        assetTypeId: data.assetTypeId
      })
    })
  }, [asset.data])

  return (
    <Paper className={classes.container} relative>
      <LoadingOverlay visible={asset.loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12} sm={6}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col span={12} sm={6}>
            <SelectAssetType
              required
              disabled={assetTypes.loading}
              data={selectAssetTypes}
              {...form.getInputProps('assetTypeId')}
            />
          </Col>
          <Col span={6}>
            <Group position="left" mt="sm">
              <Button
                color="red"
                leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                onClick={() => setShowDelete(true)}
              >
                Delete
              </Button>
            </Group>
          </Col>
          <Col span={6}>
            <Group position="right" mt="sm">
              <Button
                component={Link}
                to="/settings/assets"
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={editAsset.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${form.values.name}"`}
        loading={removeAsset.loading}
        question="Are you sure you want to delete this asset? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </Paper>
  )
}
