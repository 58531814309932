import { type ReactNode } from 'react'
import { createStyles, Box, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'
import { type DiagnosticMetricItem } from '../Components/DiagnosticMetric'
import { type SensorMetricItem } from '../Components/SensorMetric'
import DiagnosticMetrics from './DiagnosticMetrics'
import SensorMetrics from './SensorMetrics'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 1
    }
  }
}))

interface Props {
  children?: ReactNode
  diagnosticMetrics?: DiagnosticMetricItem[]
  sensorMetrics?: SensorMetricItem[]
}

export default function Content ({ children, diagnosticMetrics, sensorMetrics }: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        {diagnosticMetrics && (
          <DiagnosticMetrics items={diagnosticMetrics} />
        )}
        {sensorMetrics && (
          <SensorMetrics items={sensorMetrics} />
        )}
        {children}
      </Stack>
    </Box>
  )
}
