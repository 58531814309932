import { memo, useCallback, useMemo } from 'react'
import {
  useMantineTheme,
  Accordion,
  Box,
  Button,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ColorInput from 'src/Input/ColorInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectAgent from 'src/Input/Select/SelectAgent'
import SelectAgentSensor from 'src/Input/Select/SelectAgentSensor'
import ItemPreview from '../shared/ItemPreview'
import SensorGauge, { type Props as SensorGaugeProps } from '.'

interface Props {
  initialValues: SensorGaugeProps | null
  onSubmit: (properties: SensorGaugeProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const { colors } = useMantineTheme()
  const focusTrapRef = useFocusTrap()

  const formValues: SensorGaugeProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        agentId: -1,
        sensorInstanceId: -1
      },
      styles: {
        innerData: {
          id: 1,
          value: 0,
          color: colors.orange[5]
        },
        outerData: [
          { id: 1, value: 33, color: colors.green[5] },
          { id: 2, value: 33, color: colors.orange[5] },
          { id: 3, value: 33, color: colors.red[5] }
        ]
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const hasSelectedAgent = useMemo(() => form.values.data.agentId > 0, [form.values.data.agentId])

  const hasSelectedSensorInstance = useMemo(() => (
    form.values.data.sensorInstanceId > 0
  ), [form.values.data.sensorInstanceId])

  const previewItem = useMemo(() => (
    hasSelectedAgent && hasSelectedSensorInstance
  ), [hasSelectedAgent, hasSelectedSensorInstance])

  const outerData = form.values.styles.outerData ?? []

  const setFormDataValue = useCallback((
    key: keyof SensorGaugeProps['data'],
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormStylesValue = useCallback((
    key: keyof SensorGaugeProps['styles'],
    value: unknown
  ) => {
    form.setValues({
      ...form.values,
      styles: {
        ...form.values.styles,
        [key]: value
      }
    })
  }, [form.values.styles])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="gauge-circle-bolt" previewItem={previewItem}>
        <SensorGauge {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectAgent
                    label="Agent"
                    value={form.values.data.agentId.toString()}
                    onChange={agentId => setFormDataValue('agentId', Number(agentId))}
                    required
                    searchable
                    error={form.errors.agentId}
                  />
                  {hasSelectedAgent && (
                    <SelectAgentSensor
                      agentId={form.values.data.agentId}
                      label="Sensor"
                      value={(
                        hasSelectedSensorInstance
                          ? form.values.data.sensorInstanceId.toString()
                          : null
                      )}
                      onChange={sensorInstanceId => setFormDataValue('sensorInstanceId', Number(sensorInstanceId))}
                      required
                      searchable
                      error={form.errors.sensorInstanceId}
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="styles">
              <Accordion.Control>Styles</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <ColorInput
                    name="primaryColor"
                    label="Primary Color"
                    placeholder="Pick a color"
                    value={form.values.styles.innerData?.color}
                    onChange={color => {
                      setFormStylesValue('innerData', {
                        ...form.values.styles.innerData,
                        color
                      })
                    }}
                  />
                  <Box>
                    <Text size="xs">Outer Colors</Text>
                    <Stack spacing="xs">
                      <SimpleGrid cols={2} spacing="md">
                        <ColorInput
                          name="outerColor1"
                          placeholder="Outer Color 1"
                          value={outerData.find(({ id }) => id === 1)?.color}
                          onChange={color => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 1) {
                                  return {
                                    ...item,
                                    color
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                        <TextInput
                          placeholder="Outer Value 1"
                          value={outerData.find(({ id }) => id === 1)?.value}
                          onChange={event => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 1) {
                                  return {
                                    ...item,
                                    value: event.currentTarget.value
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                      </SimpleGrid>
                      <SimpleGrid cols={2} spacing="md">
                        <ColorInput
                          name="outerColor2"
                          placeholder="Outer Color 2"
                          value={outerData.find(({ id }) => id === 2)?.color}
                          onChange={color => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 2) {
                                  return {
                                    ...item,
                                    color
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                        <TextInput
                          placeholder="Outer Value 2"
                          value={outerData.find(({ id }) => id === 2)?.value}
                          onChange={event => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 2) {
                                  return {
                                    ...item,
                                    value: event.currentTarget.value
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                      </SimpleGrid>
                      <SimpleGrid cols={2} spacing="md">
                        <ColorInput
                          name="outerColor3"
                          placeholder="Outer Color 3"
                          value={outerData.find(({ id }) => id === 3)?.color}
                          onChange={color => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 3) {
                                  return {
                                    ...item,
                                    color
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                        <TextInput
                          placeholder="Outer Value 3"
                          value={outerData.find(({ id }) => id === 3)?.value}
                          onChange={event => {
                            setFormStylesValue('outerData', (
                              outerData.map(item => {
                                if (item.id === 3) {
                                  return {
                                    ...item,
                                    value: event.currentTarget.value
                                  }
                                }

                                return item
                              })
                            ))
                          }}
                        />
                      </SimpleGrid>
                    </Stack>
                  </Box>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
