import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack } from '@mantine/core'
import { type Organisation } from '@venturi-io/api/src/userManager/org'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import RestartOnboardingButton from 'src/Buttons/RestartOnboardingButton'
import NeedsRole from 'src/NeedsRole'
import { useUser } from 'src/UserContext'

export default function Card ({ orgId, name }: Organisation & ListRow) {
  const { orgId: currentOrgId } = useUser()
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={orgId.toString()} isKey />
          <Group spacing={8}>
            <ActionButton
              label="Edit"
              icon="pencil"
              iconType="fas"
              onClick={() => navigate(`/my-admin/organisations/edit/${orgId}`)}
              actionColor="green"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
            <NeedsRole role="ROLE_SUPERADMIN">
              {currentOrgId === orgId && (
                <RestartOnboardingButton
                  id={orgId}
                  name={name}
                  type="ORG"
                />
              )}
            </NeedsRole>
          </Group>
        </Group>
        <TextGroup label="Name" value={name} maxLength={25} />
      </Stack>
    </Paper>
  )
}
