import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import dayjs from 'dayjs'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const alarms = sensors.filter(({ alarmStatus }) => alarmStatus === 'ALARM').length

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col} >
        <Link to={`/assets/generic/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={30}
          />
        </Link>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        {sensors.length}
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <Text size="sm">
          {alarms}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(lastSeenTime).fromNow()}
        </Text>
      </div>
    </div>
  )
}
