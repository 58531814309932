import { type ReactElement } from 'react'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Text,
  ThemeIcon
} from '@mantine/core'

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 150px',
    background: 'white',
    height: 128,
    minWidth: 0,
    padding: '8px 0px',
    overflow: 'clip'
  },
  icon: {
    height: 32,
    color: theme.colors.dark[0],
    background: 'transparent'
  },
  name: {
    color: theme.colors.dark[3],
    lineHeight: 'normal'
  },
  valueContainer: {
    height: 'auto',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export interface SensorMetricItem {
  sensorInstanceId: number
  name: string
  icon: ReactElement
  value: number
  displayValue: string
  displayValueUnit: string
  label?: string
}

export default function SensorMetric ({
  name,
  icon,
  displayValue,
  displayValueUnit
}: SensorMetricItem) {
  const { classes } = useStyles()

  return (
    <Paper className={classes.container} radius={8} shadow="xs">
      <Group pl={12} pr={4} position="apart" align="center">
        <Text
          className={classes.name}
          size={12}
          weight={500}
        >
          {name}
        </Text>
        <ThemeIcon className={classes.icon}>
          {icon}
        </ThemeIcon>
      </Group>
      <Box className={classes.valueContainer}>
        <Group spacing={2} align="baseline">
          <Text
            size={32}
            weight={700}
            align="center"
          >
            {displayValue}
          </Text>
          <Text size={16} weight={600}>
            {displayValueUnit}
          </Text>
        </Group>
      </Box>
    </Paper>
  )
}
