import { Box, Center, Text, createStyles } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List, { type ListRowProps } from 'react-virtualized/dist/commonjs/List'
import Row from './Row'
import { rowHeight } from './shared'

interface Props {
  agents: Array<string | Agent>
  onClick?: (agentId: Agent['agentId']) => void
  onRefresh?: () => void
}

const useStyle = createStyles(() => ({
  list: {
    scrollbarWidth: 'thin'
  }
}))

export default function VirtualTable ({
  agents,
  onClick,
  onRefresh
}: Props) {
  const { classes } = useStyle()
  const agentCount = agents.length

  return (
    <Box sx={{ height: '100%', scrollbarGutter: 'stable' }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            className={classes.list}
            height={height}
            width={width + 15}
            rowCount={agentCount}
            rowHeight={rowHeight}
            itemData={agents}
            rowRenderer={({ index, key, style }: ListRowProps) => {
              const row = agents[index]
              if (typeof row === 'string') {
                return (
                  <Center key={key} style={{ ...style, position: 'sticky', zIndex: 2 }}>
                    <Text size="xs" weight="bold">{row}</Text>
                  </Center>
                )
              } else {
                return (
                  <Row
                    key={key}
                    data={row}
                    style={{
                      ...style,
                      paddingTop: 7,
                      width: width - 7
                    }}
                    onClick={onClick}
                    onRefresh={onRefresh}
                  />
                )
              }
            }}
          />
        )}
      </AutoSizer>
    </Box>
  )
}
