import {
  Box,
  Button,
  createStyles,
  Paper,
  Tabs
} from '@mantine/core'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import area from '@turf/area'
import { type MouseEvent } from 'react'
import { getCurrentBreakpoint } from 'src/utils/style'
import { type Feature, type MapMode } from '../shared'
import { MODES } from '../constants'
import GeoZoneProperties from './GeoZoneProperties'
import AreaDetails from './AreaDetails'
import GeoZoneRules from './GeoZoneRules'

interface StyleParams {
  screenCoordX: number
  screenCoordY: number
}

const useStyles = createStyles((_, { screenCoordX, screenCoordY }: StyleParams) => ({
  container: {
    position: 'absolute',
    left: screenCoordX,
    top: screenCoordY,
    margin: '8px',
    padding: '4px',
    maxWidth: '300px',
    fontSize: '10px',
    zIndex: 10
  },
  tabPanel: {
    height: '270px',
    overflowY: 'auto'
  }
}))

const defaultScreenCoords = {
  screenCoordX: 3,
  screenCoordY: 2
}

export type GeoZoneData = Omit<GeoZone, 'boundary'>

interface Props {
  mode: MapMode
  orgId: number
  activeFeature: Feature
  selectedGeoZone: GeoZoneData | null
  switchMode: (value: string) => void
  onCreate: (geoZone: Omit<GeoZone, 'geoZoneId'>) => void
  onUpdate: (geoZone: GeoZone, newAgentGroupIds: number[], removedAgentGroupIds: number[]) => void
  onDelete: () => void
  onCancel: () => void
  setShowInformationBox: (value: boolean) => void
}

export default function InformationBox ({
  mode,
  orgId,
  activeFeature,
  selectedGeoZone,
  switchMode,
  onCreate,
  onUpdate,
  onDelete,
  onCancel,
  setShowInformationBox
}: Props) {
  const featureArea = activeFeature && area(activeFeature)
  // Let's give it a fix position on the top right, so even if the map is moved, information box stays at the top right
  const { classes } = useStyles(defaultScreenCoords)
  const initialBreakpoint = getCurrentBreakpoint()

  return (
    <Paper
      className={classes.container}
      p="sm"
      radius="sm"
      shadow="lg"
      withBorder
    >
      <Tabs color="primary" defaultValue="properties">
        <Tabs.List>
          <Tabs.Tab value="properties" icon={<FontAwesomeIcon icon={['fas', 'clipboard']} size="sm" />}>
            Properties
          </Tabs.Tab>
          <Tabs.Tab value="area" icon={<FontAwesomeIcon icon={['fas', 'draw-polygon']} size="sm" />}>
            Area
          </Tabs.Tab>
          <Tabs.Tab
            value="rules"
            icon={<FontAwesomeIcon icon={['fas', 'scale-balanced']} size="sm" />}
            disabled={!selectedGeoZone}
          >
            Rules
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel className={classes.tabPanel} value="properties" pt="md">
          <GeoZoneProperties
            mode={mode}
            orgId={orgId}
            activeFeature={activeFeature}
            selectedGeoZone={selectedGeoZone}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={onCancel}
            setShowInformationBox={setShowInformationBox}
          />
          {(initialBreakpoint === 'xxs' &&
            mode?.id !== MODES.EDITING) &&
            (
              <Box ml={8} mr={8}>
                <Button
                  w="100%"
                  mt="sm"
                  color="green"
                  leftIcon={(
                    <FontAwesomeIcon
                      icon={['fas', 'pencil']}
                      color="white"
                    />
                  )}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(mode?.id === MODES.EDITING
                      ? MODES.VIEWING
                      : MODES.EDITING
                    )
                  }}
                >
                  Edit
                </Button>
              </Box>
            )}
        </Tabs.Panel>
        <Tabs.Panel className={classes.tabPanel} value="area" pt="md">
          <AreaDetails featureArea={featureArea} />
        </Tabs.Panel>
        <Tabs.Panel className={classes.tabPanel} value="rules" pt="md">
          {selectedGeoZone !== null && <GeoZoneRules mode={mode} selectedGeoZone={selectedGeoZone} />}
        </Tabs.Panel>
      </Tabs>
    </Paper>
  )
}
