import { Badge } from '@mantine/core'

const EditModeBadge = () => (
  <Badge
    variant="gradient"
    gradient={{
      from: 'silver',
      to: 'gray'
    }}
  >
    Edit Mode
  </Badge>
)

export default EditModeBadge
