import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Modal,
  Stack,
  Table,
  Text,
  Tooltip
} from '@mantine/core'
import React, { useEffect, useState } from 'react'
import {
  clearAlarmIncidentForAgentId,
  clearAlarmIncidentForSensorInstance
} from '@venturi-io/api/src/analytics/alarmIncident'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { type ApiData, useApi } from './utils/useApi'
import { useUser } from './UserContext'
import ConfirmModal from './Layout/ConfirmModal'
import { useNotifications } from './utils/notifications'

interface ClearAgent {
  agentId?: number
  sensorInstanceIds?: number[]
  agentName?: string
  onRefresh?: () => void
  confirmLabel?: string
  children?: (onClick: () => void) => React.ReactNode
}

function ClearAlarmButton ({
  agentId,
  sensorInstanceIds,
  onRefresh,
  confirmLabel,
  agentName,
  children
}: ClearAgent) {
  const { token } = useUser()
  const { showError, showSuccess } = useNotifications()
  const [alarms, setAlarms] = useState<ApiData<typeof getAgentDetails>['sensors']>()
  const clearAlarmViaAgentId = useApi(clearAlarmIncidentForAgentId)
  const clearAlarmViaSensorInstanceIds = useApi(clearAlarmIncidentForSensorInstance)
  const [isClearing, setIsClearing] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const [showClear, setShowClear] = useState(false)

  const [instanceIds, setInstanceIds] = useState<number[]>([])

  const agentDetail = useApi(getAgentDetails)

  const loadAgentDetails = () => {
    if (agentId) {
      void agentDetail.fetch({ agentId }, token)
    }
  }

  const handleClearAlarm = () => {
    if (sensorInstanceIds) {
      setInstanceIds(sensorInstanceIds)
      setShowClear(true)
    } else {
      loadAgentDetails()
    }
  }

  const confirmClearAlarm = async () => {
    setIsClearing(true)
    try {
      if (agentId) {
        await clearAlarmViaAgentId.fetch({ agentId }, token, 'Successfully cleared alarm')
      }
      if (sensorInstanceIds) {
        await clearAlarmViaSensorInstanceIds.fetch({ sensorInstanceIds }, token)
        showSuccess('Successfully cleared alarm')
      }
      setShowClear(false)
      // should refresh
      if (onRefresh) {
        onRefresh()
      }
    } catch (err) {
      showError(err as Error)
    } finally {
      setIsClearing(false)
    }
  }

  useEffect(() => {
    agentDetail.data.ifJust(({ sensors }) => {
      // check for sensor instance alarm
      const sensorsWithAlarm = sensors.filter(({ alarmStatus }) => alarmStatus === 'ALARM')
      if (sensorsWithAlarm.length > 1 && sensorsWithAlarm.length !== 0) {
        // has one or more alarms with it
        setShowPicker(true)
      } else {
        // has only one
        setShowClear(true)
      }
      setAlarms(sensorsWithAlarm)
    })
  }, [agentDetail.data])

  return (
    <>
      {children
        ? children(handleClearAlarm)
        : (
          <Tooltip label="Clear alarm">
            <ActionIcon
              variant="subtle"
              color="primary"
              loading={isClearing}
              onClick={(event) => {
                event.stopPropagation()
                void handleClearAlarm()
              }}
            >
              <FontAwesomeIcon icon={['far', 'bell-exclamation']} />
            </ActionIcon>
          </Tooltip>
          )}
      {showPicker && alarms && (
        <Modal
          title={`Clearing "${agentName}" alarm`}
          opened={showPicker}
          onClose={() => setShowPicker(false)}
        >
          <Stack>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      color="primary"
                      onChange={(event) => {
                        const { target: { checked } } = event
                        const values = checked
                          ? alarms.map(({ sensorInstanceId }) => sensorInstanceId)
                          : []
                        setInstanceIds(values)
                      }}
                    />
                  </th>
                  <th>Sensor</th>
                  <th>Current Value</th>
                  <th><Text align="right">Action</Text></th>
                </tr>
              </thead>
              <tbody>
                {alarms.map(({
                  sensorInstanceId,
                  name,
                  currentValue
                }) => (
                  <tr key={sensorInstanceId}>
                    <td>
                      <Checkbox
                        checked={instanceIds.includes(sensorInstanceId)}
                        onChange={(event) => {
                          const { target: { checked } } = event
                          const value = checked
                            ? [...instanceIds, sensorInstanceId]
                            : instanceIds.filter(id => id !== sensorInstanceId)
                          setInstanceIds(value)
                        }}
                        color="primary"
                      />
                    </td>
                    <td>{name}</td>
                    <td>{currentValue}</td>
                    <td align="right">
                      <ClearAlarmButton
                        sensorInstanceIds={[sensorInstanceId]}
                        agentName={agentName}
                        onRefresh={onRefresh}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Group position="apart">
              <Button
                color="gray"
                onClick={() => setShowPicker(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={instanceIds.length === 0}
                color="red"
                onClick={() => setShowClear(true)}
              >
                Clear selected
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
      {showClear && (
        <ConfirmModal
          type="delete"
          opened={showClear}
          title={agentName
            ? `Clearing "${agentName}" alarm`
            : 'Clear alarms'}
          loading={isClearing}
          confirmLabel={confirmLabel ?? 'Clear'}
          question={confirmLabel
            ? 'Are you sure you want to clear all selected alarms? This cannot be undone.'
            : 'Are you sure you want to clear the alarm for this agent? This cannot be undone.'}
          onClose={() => setShowClear(false)}
          onCancel={() => setShowClear(false)}
          onConfirm={() => {
            void confirmClearAlarm()
          }}
        />
      )}
    </>
  )
}

export default ClearAlarmButton
