import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Group, Paper } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { getCurrentBreakpoint, getMantineBreakpointsInPx } from 'src/utils/style'
import { getTask } from '@venturi-io/api/src/config/task'
import NotFound from 'src/Router/NotFound'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { gridColumns } from 'src/Assets/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import { useNotifications } from 'src/utils/notifications'
import { useMeta } from 'src/MetaContext'
import { defaultLayouts } from './data'
import ModuleContainer from './ModuleContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface RouteParams extends Record<string, string | undefined> {
  taskId: string
}

const rowHeight = 360

export default function Details () {
  const { token } = useUser()
  const { taskId: id } = useParams<RouteParams>()
  const taskDetails = useApi(getTask)
  const [apiPath, setApiPath] = useState('')
  const { showError, showSuccess } = useNotifications()
  const { meta, setMeta } = useMeta()
  const [markAsDone, setMarkAsDone] = useState(false)

  useEffect(() => {
    void configure(getHost()).then(config => {
      setApiPath(`${config.config}/api/config/tasks`)
    })
  }, [])

  const initialBreakpoint = getCurrentBreakpoint()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const breakpoints = getMantineBreakpointsInPx()
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint)
  const onBreakpointChange = (breakpoint: string) => setCurrentBreakpoint(breakpoint)

  const loadTaskDetails = () => {
    void taskDetails.fetch({ taskId: parseInt(id) }, token)
  }

  const handleMarkAsDone = async () => {
    const formData = new FormData()
    formData.append('status', 'DONE')

    try {
      const request = {
        method: 'PATCH',
        headers: {
          'X-Auth-Token': token
        },
        body: formData
      }

      const result = await fetch(`${apiPath}/${id}`, request)
      if (result.ok) {
        showSuccess('Successfully mark as done')
        setMarkAsDone(true)
      } else {
        showError(new Error('Failed to mark as done'))
      }
    } catch (error: any) {
      showError(error)
    }
  }

  useEffect(() => {
    loadTaskDetails()
  }, [])

  useEffect(() => {
    taskDetails
      .data
      .ifJust(({ agent, summary }) => {
        const summaryTitle = summary
          ? `| ${summary}`
          : ''
        setMeta({ ...meta, pageTitle: `Task Details | ${agent.agentName} ${summaryTitle}` })
      })
  }, [taskDetails.data])

  const tasks = taskDetails.data.mapOrDefault(data => data, null)

  useEffect(() => {
    if (tasks?.status === 'DONE') {
      setMarkAsDone(true)
    }
  }, [tasks])

  return (
    <Box mt="lg" style={{ width: '100%' }}>
      {tasks?.status !== 'DONE' &&
      !markAsDone && (
        <Group
          position="right"
          spacing="xs"
        >
          <Button
            title="Mark as Done"
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
            onClick={handleMarkAsDone}
          >
            Mark as Done
          </Button>
        </Group>
      )}
      {taskDetails.loading && <Loader />}
      {tasks && (
        <ResponsiveGridLayout
          className="layout"
          layouts={defaultLayouts}
          rowHeight={rowHeight}
          cols={gridColumns}
          breakpoints={breakpoints}
          onBreakpointChange={onBreakpointChange}
        >
          {currentBreakpoint && (
            defaultLayouts[currentBreakpoint]?.map(item => (
              <Paper
                key={item.i}
                p="md"
                shadow="sm"
                style={{
                  overflow: 'none',
                  position: 'relative'
                }}
              >
                <ModuleContainer
                  tasks={tasks}
                  contentKey={item.i}
                />
              </Paper>
            ))
          )}
        </ResponsiveGridLayout>
      )}
    </Box>
  )
}
