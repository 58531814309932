import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  type ColProps,
  Grid,
  Group,
  Stack
} from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  createModbusDevice,
  deleteModbusDevice,
  getModbusDevice,
  type ModbusDevice,
  updateModbusDevice
} from '@venturi-io/api/src/config/modbus'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { useApi } from 'src/utils/useApi'

interface Props {
  mode: FormMode
  deviceId?: number
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({ deviceId, mode, onCreate, onClose }: Props) {
  const { token } = useUser()
  const navigate = useNavigate()
  const device = useApi(getModbusDevice)
  const newDevice = useApi(createModbusDevice)
  const editDevice = useApi(updateModbusDevice)
  const removeDevice = useApi(deleteModbusDevice)
  const [showDelete, setShowDelete] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm <Omit<ModbusDevice, 'valueMap' | 'id'>>({
    initialValues: {
      name: '',
      delay: 0,
      swapBytes: false,
      swapWords: false,
      byteOrder: ''
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null,
      delay: value => value < 0 ? 'Please specify valid Delay' : null,
      byteOrder: value => value.length < 1 ? 'Please specify Byte Order' : null
    }
  })

  const redirectToListPage = () => navigate('/my-admin/modbus-devices')

  const handleCreate = (values: typeof form.values) => {
    void newDevice
      .fetch(values, token)
      .finally(() => {
        form.reset()
        if (onClose) void onClose()
        if (onCreate) void onCreate()
      })
  }

  const handleUpdate = useCallback((values: typeof form.values) => {
    if (!deviceId) return
    void editDevice
      .fetch({
        ...values,
        deviceId
      }, token, 'Successfully updated modbus device')
      .finally(redirectToListPage)
  }, [deviceId])

  const handleDelete = useCallback(() => {
    if (!deviceId) return
    void removeDevice
      .fetch({ deviceId }, token, 'Succesfully deleted modbus device')
      .finally(redirectToListPage)
  }, [deviceId])

  const handleSubmit = useCallback((values: typeof form.values) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode])

  const loadDevice = () => {
    if (!deviceId) return
    void device.fetch({ deviceId }, token)
  }

  useEffect(() => {
    if (inEditMode) {
      loadDevice()
    }
  }, [inEditMode])

  useEffect(() => {
    device
      .data
      .ifJust(
        (values: ModbusDevice) => form.setValues(values)
      )
  }, [device.data])

  return (
    <>
      <LoadingOverlay visible={device.loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput type="number" withAsterisk label="Delay" {...form.getInputProps('delay')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Byte Order" {...form.getInputProps('byteOrder')} />
          </Col>
          <Col {...inputColProps}>
            <Stack>
              <Checkbox
                checked={form.values.swapBytes}
                label="Swap bytes"
                onChange={event => form.setFieldValue('swapBytes', event.currentTarget.checked)}
              />
              <Checkbox
                checked={form.values.swapWords}
                label="Swap words"
                onChange={event => form.setFieldValue('swapWords', event.currentTarget.checked)}
              />
            </Stack>
          </Col>
          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="lg" mb="sm">
                <Button
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                disabled={newDevice.loading || editDevice.loading}
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={() => {
                  form.reset()
                  if (onClose) onClose()
                  if (inEditMode) redirectToListPage()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'circle-plus']} color="white" />}
                loading={newDevice.loading || editDevice.loading}
              >
                {inCreateMode ? 'Create' : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${form.values.name}"`}
        loading={removeDevice.loading}
        question="Are you sure you want to delete this device? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
