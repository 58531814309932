import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type OrgIdRequest, type SiteIdRequest, type PaginatedRequest, paginated } from '../shared'

export const site = z.object({
  siteId: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  orgId: z.optional(z.number())
})

const sites = paginated.extend({
  items: z.array(
    site.extend({
      agentCount: z.number()
    })
  )
})

export type Site = z.infer<typeof site>

interface CreateSiteRequest extends OrgIdRequest {
  name: string
  description?: string
}

export const createSite = createStatefulApi<CreateSiteRequest, typeof site>(
  'user',
  '/site',
  { method: 'POST' },
  site
)

interface AllSitesRequest extends OrgIdRequest, PaginatedRequest {
  search?: string
}

export const getSites = createStatelessApi<AllSitesRequest, typeof sites>(
  'user',
  '/org/:orgId/sites',
  { method: 'GET', type: 'path' },
  sites
)

export const getSite = createStatelessApi<SiteIdRequest, typeof site>(
  'user',
  '/site/:siteId',
  { method: 'GET', type: 'path' },
  site
)

interface UpdateSiteRequest extends SiteIdRequest {
  name?: string
  description?: string
}

export const updateSite = createStatefulApi<UpdateSiteRequest, typeof site>(
  'user',
  '/site/:siteId',
  { method: 'PUT' },
  site
)

export const deleteSite = createStatefulApi<SiteIdRequest, typeof site>(
  'user',
  '/site/delete/:siteId',
  { method: 'DELETE', type: 'path' },
  site
)
