import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type Organisation } from '@venturi-io/api/src/userManager/org'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'
import RestartOnboardingButton from 'src/Buttons/RestartOnboardingButton'
import NeedsRole from 'src/NeedsRole'
import { useUser } from 'src/UserContext'

export default function Row ({ orgId, name }: Organisation & ListRow) {
  const { orgId: currentOrgId } = useUser()
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{orgId}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={parseEmpty(name)}
          maxLength={30}
          size="sm"
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/organisations/edit/${orgId}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
        <NeedsRole role="ROLE_SUPERADMIN">
          {currentOrgId === orgId && (
            <RestartOnboardingButton
              id={orgId}
              name={name}
              type="ORG"
            />
          )}
        </NeedsRole>
      </div>
    </div>
  )
}
