import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  createStyles,
  Group,
  Menu,
  Text,
  Tooltip
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { useUser } from 'src/UserContext'
import { useZendesk } from 'src/ZendeskContext'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import Alarms from '../Alarms'

const useStyles = createStyles(theme => ({
  menu: {
    zIndex: zIndex.menu,
    float: 'right',
    display: 'none !important', // TODO: Fix this !important
    [mq(theme.breakpoints.sm)]: {
      display: 'initial !important', // TODO: Fix this !important
      marginRight: '25px'
    }
  },
  menuIconWrapper: {
    width: '125px'
  },
  menuIcon: {
    color: 'white'
  },
  chatbotIcon: {
    color: 'white',
    padding: '8px',
    borderRadius: '50%'
  },
  menuIconText: {
    fontSize: 16,
    color: 'white',
    marginLeft: '10px'
  },
  menuItemIcon: {
    marginRight: '10px'
  }
}))

interface Props {
  signout: () => void
}

export default function ProfileMenu ({ signout }: Props) {
  const { classes } = useStyles()
  const { firstName, lastName } = useUser()
  const { open, show } = useZendesk()

  return (
    <Group mr="md" position="right">
      <Alarms showDrawer />
      <Tooltip label="Help">
        <ActionIcon
          variant="transparent"
          onClick={() => {
            show()
            open()
          }}
          sx={{
            marginRight: '-8px'
          }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={['far', 'circle-question']}
            className={classes.chatbotIcon}
          />
        </ActionIcon>
      </Tooltip>
      <Menu
        withArrow
        zIndex={zIndex.menu}
        position="bottom-end"
        transitionProps={{
          transition: 'pop-bottom-left'
        }}
        width={200}
        shadow="lg"
      >
        <Menu.Target>
          <ActionIcon className={classes.menuIconWrapper} variant="transparent">
            <FontAwesomeIcon size="lg" icon={['fas', 'user-circle']} className={classes.menuIcon} />
            <Text className={classes.menuIconText} lineClamp={1}>
              {`${firstName} ${lastName}`}
            </Text>
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={Link} to="/user-profile">
            <FontAwesomeIcon className={classes.menuItemIcon} icon={['fas', 'user-circle']} />
            User Profile
          </Menu.Item>
          <Menu.Item component={Link} to="/" onClick={signout}>
            <FontAwesomeIcon className={classes.menuItemIcon} icon={['fas', 'sign-out-alt']} />
            Sign Out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}
