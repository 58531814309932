import { useNavigate } from 'react-router-dom'
import { Code, Text } from '@mantine/core'
import { type SensorDataTransformer } from '@venturi-io/api/src/config/sensorDataTransformer'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'

export default function Row ({ id, name, function: fn }: SensorDataTransformer & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={parseEmpty(name).toString()}
          maxLength={30}
          size="sm"
        />
      </div>
      <div className={classes.col}>
        <Code>{parseEmpty(fn).toString()}</Code>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/scale-factor/edit/${id}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
