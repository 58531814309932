import {
  type ReactNode,
  type CSSProperties,
  useState,
  useCallback,
  useMemo
} from 'react'
import {
  type PopoverProps,
  ActionIcon,
  Button,
  Center,
  Grid,
  Group,
  Popover,
  ScrollArea,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { type IconName, type IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks'
import Pagination from 'src/Layout/Pagination'
import { fontawesomeIcons } from 'src/utils/icons'
import { mq } from 'src/utils/style'
import parseIconStyle from 'src/utils/parseIconStyle'

// for loop proves faster than filter. we use it instead
const filter = (fn: (arg0: string) => boolean, a: string[]) => {
  const f = []
  for (let i = 0; i < a.length; i++) {
    if (fn(a[i])) {
      f.push(a[i])
    }
  }
  return f
}

type IconType = 'solid' | 'regular'

const iconTypePrefix: Record<IconType, IconPrefix> = {
  solid: 'fas',
  regular: 'far'
}

const uniqIcons = [...new Set(fontawesomeIcons)]

interface SegmentControl {
  value: IconType
  label: ReactNode
}

const segmentControls: SegmentControl[] = [
  {
    value: 'solid',
    label: (
      <Center>
        <FontAwesomeIcon icon={['fas', 'circle']} />
        <Text size="sm" weight={300} ml={10}>Solid</Text>
      </Center>
    )
  },
  {
    value: 'regular',
    label: (
      <Center>
        <FontAwesomeIcon icon={['far', 'circle-half-stroke']} />
        <Text size="sm" weight={300} ml={10}>Regular</Text>
      </Center>
    )
  }
]

interface Props {
  label?: string
  position?: PopoverProps['position']
  width?: CSSProperties['width']
  value: string | undefined
  onChange?: (value: string) => void
}

export default function IconSelector ({
  label,
  position = 'bottom-start',
  width = '100%',
  value,
  onChange
}: Props) {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))
  const [opened, setOpened] = useState(false)
  const [search, setSearch] = useState('')
  const [type, setType] = useState<IconType>('regular')
  const [pagination, setPagination] = useState({
    page: 1,
    size: 50,
    total: 0
  })
  const [debouncedSearch] = useDebouncedValue(search, 500)

  const results = useMemo(() => {
    const filtered = filter(value => value.includes(debouncedSearch), uniqIcons)
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 1,
      total: Math.floor(filtered.length / prevPagination.size)
    }))
    return filtered
  }, [debouncedSearch])

  const resultsToShow = useMemo(() => {
    const { page, size } = pagination
    return [...results].splice((page - 1) * size, size)
  }, [pagination, results])

  const iconPrefix = iconTypePrefix[type] ?? iconTypePrefix.regular

  const handleChange = useCallback((name: string) => () => {
    if (onChange) {
      onChange(`["${iconPrefix}", "${name}"]`)
    }
  }, [iconPrefix])

  return (
    <Stack spacing={6}>
      {label && <Text size="xs">{label}</Text>}
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position={position}
        transitionProps={{
          transition: isMobile ? 'pop-top-left' : 'pop'
        }}
        radius="xs"
        shadow="md"
        offset={8}
        withArrow
        width={width}
      >
        <Popover.Target>
          <Button
            sx={{
              width: '100%'
            }}
            color="primary"
            variant="outline"
            leftIcon={value && <FontAwesomeIcon icon={parseIconStyle(value)} />}
            rightIcon={value && (
              <ActionIcon
                title="Remove Icon"
                sx={{
                  position: 'absolute',
                  right: 8
                }}
                onClick={() => {
                  if (onChange) {
                    onChange('')
                  }
                }}
              >
                <FontAwesomeIcon icon={['far', 'circle-x']} color="silver" />
              </ActionIcon>
            )}
            onClick={() => setOpened(prev => !prev)}
          >
            {value ? JSON.parse(value)[1] : 'Select an icon' }
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <>
            <TextInput
              value={search}
              rightSection={(
                <Tooltip label="clear">
                  <ActionIcon onClick={() => setSearch('')}>
                    <FontAwesomeIcon icon={['fas', 'circle-xmark']} color={theme.colors.gray[5]} size="sm" />
                  </ActionIcon>
                </Tooltip>
              )}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder="Search an icon"
              icon={<FontAwesomeIcon icon={['fas', 'search']} size="sm" />}
              data-autofocus
              mb="xs"
            />
            {/* Segmented control for icon type */}
            <SegmentedControl
              radius="lg"
              mb="sm"
              size="xs"
              color="primary"
              fullWidth
              value={type}
              onChange={(val: IconType) => setType(val)}
              data={segmentControls}
            />
            {/* Grid like icons */}
            <ScrollArea
              sx={{
                height: 260
              }}
              type="hover"
              scrollbarSize={5}
            >
              <Grid columns={10} gutter={0}>
                {resultsToShow.length === 0
                  ? <Text size="sm" color="gray">Try searching a different keyword.</Text>
                  : resultsToShow.map(name => (
                    <Grid.Col key={name} span={1}>
                      <Tooltip label={name} withArrow>
                        <ActionIcon my="sm" onClick={handleChange(name)}>
                          <FontAwesomeIcon
                            color={theme.colors.primary[4]}
                            icon={[iconPrefix, name as IconName]}
                            size="lg"
                          />
                        </ActionIcon>
                      </Tooltip>
                    </Grid.Col>
                  ))}
              </Grid>
            </ScrollArea>
            <Group position="apart">
              <Pagination
                size="sm"
                total={pagination.total}
                value={pagination.page}
                onChange={page => setPagination(prevPagination => ({ ...prevPagination, page }))}
              />
              {isMobile && (
                <Button
                  size="xs"
                  color="primary"
                  onClick={() => setOpened(false)}
                  leftIcon={<FontAwesomeIcon icon={['fas', 'xmark']} size="sm" />}
                >
                  Close
                </Button>
              )}
            </Group>
          </>
        </Popover.Dropdown>
      </Popover>
    </Stack>
  )
}
