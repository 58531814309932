import { useEffect, useMemo } from 'react'
import { Container, ScrollArea } from '@mantine/core'
import { getAgents } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import Loader from 'src/Layout/Loader'
import { useSharedStyles } from '../shared'
import Table from './Table'

export interface Props {
  id: string
  name: string
}

export default function DuressAlarms ({ name }: Props) {
  const { orgId, token } = useUser()
  const getDuressDevices = usePaginatedApi(getAgents)

  const { classes } = useSharedStyles()

  const devices = useMemo(() => (
    getDuressDevices.data.mapOrDefault(({ items }) => items, [])
  ), [getDuressDevices.data])

  const loadDevicesWithAlarm = (page?: number, size?: number) => {
    const req = {
      orgId,
      assetType: 'DURESS',
      alarmStatus: 'ALARM',
      page: page ?? getDuressDevices.page,
      size: size ?? getDuressDevices.size
    }

    void getDuressDevices.fetch(req, token)
  }

  useEffect(() => {
    loadDevicesWithAlarm()
  }, [getDuressDevices.page])

  return (
    <Container className={classes.itemContainer} fluid>
      {getDuressDevices.loading && <Loader variant="bars" />}
      {!getDuressDevices.loading && (
        <ScrollArea
          className={classes.scrollArea}
          type="hover"
          scrollbarSize={5}
        >
          <Table
            title={name}
            devices={devices}
            onRefresh={loadDevicesWithAlarm}
          />
          <Pagination
            mt="sm"
            value={getDuressDevices.page}
            total={getDuressDevices.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            onChange={getDuressDevices.setPage}
          />
        </ScrollArea>
      )}
    </Container>
  )
}
