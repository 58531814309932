import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type PaginatedRequest, paginated } from '../shared'

// Get sensor data for graphs
const sensorData = z.object({
  sensorInstanceId: z.number(),
  data: z.array(z.object({
    x: z.string(),
    y: z.optional(z.number())
  }))
})

export type SensorData = z.infer<typeof sensorData>

const sensorDataResponse = z.object({
  result: z.array(sensorData)
})

interface SensorDataRequest extends Record<string, unknown> {
  startTime: string
  endTime: string
  timeBucket: string
  noOfRecords: number
  timeZone: string
  sensorInstanceIds: number[]
}

export const getSensorData = createStatefulApi<SensorDataRequest, typeof sensorDataResponse>(
  'collector',
  '/data/query',
  { method: 'POST' },
  sensorDataResponse
)

// Sensor Logs
const sensorDataLog = z.object({
  id: z.number(),
  agentId: z.number(),
  agentName: z.string(),
  eventLog: z.string(),
  sensorId: z.number(),
  sensorName: z.string(),
  sensorInstanceId: z.number(),
  sensorInstanceName: z.string(),
  timestamp: z.string()
})

const allSensorDataLogs = paginated.extend({
  items: z.array(sensorDataLog)
})

export type SensorDataLog = z.infer<typeof sensorDataLog>

interface AllSensorDataLogs extends PaginatedRequest {
  startTime: string
  endTime: string
  agentId?: number
  sensorInstanceId?: number
}

export const getAllSensorDataLogs = createStatelessApi<AllSensorDataLogs, typeof allSensorDataLogs>(
  'collector',
  '/sensor-data/logs',
  { method: 'GET', type: 'path' },
  allSensorDataLogs
)
