import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  Switch,
  Stack
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import Select from 'src/Input/Select'
import SelectUserGroup from 'src/Input/Select/SelectUserGroup'
import MultiSelectSensorInstance from 'src/Input/MultiSelect/MultiSelectAgentSensor'
import {
  actionTypes,
  createAlarmAction,
  deleteAlarmAction,
  updateAlarmAction,
  assignSensorInstancesToAlarmAction,
  removeSensorInstancesFromAlarmAction,
  type AlarmAction
} from '@venturi-io/api/src/analytics/alarmAction'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'

interface Props {
  mode: FormMode
  initialValues?: AlarmAction
  onCreate?: () => Promise<void>
  onClose?: () => void
}

interface FormProps extends Omit<AlarmAction, 'id' | 'sensorInstances' | 'userGroup'> {
  sensorInstanceIds?: string[]
  userGroupId: string
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { token } = useUser()
  const navigate = useNavigate()
  const newAlarmAction = useApi(createAlarmAction)
  const editAlarmAction = useApi(updateAlarmAction)
  const removeAlarmAction = useApi(deleteAlarmAction)
  const assignSensorInstances = useApi(assignSensorInstancesToAlarmAction)
  const removeSensorInstances = useApi(removeSensorInstancesFromAlarmAction)
  const [showDelete, setShowDelete] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const initialSensorInstanceIds = initialValues?.sensorInstances.map(({ id }) => id.toString()) ?? []

  const form = useForm<FormProps>({
    initialValues: {
      name: '',
      action: '',
      onTrigger: true,
      onClear: true,
      userGroupId: ''
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null,
      action: value => value.length < 1
        ? 'Please specify Action'
        : null,
      userGroupId: value => value.length < 1
        ? 'Please select User Group'
        : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => {
    navigate('/settings/alarm-actions')
  }

  const handleCreate = useCallback(({ name, action, onTrigger, onClear, userGroupId }: FormValues) => {
    void newAlarmAction.fetch(
      {
        name,
        action,
        onTrigger,
        onClear,
        userGroup: {
          id: Number(userGroupId)
        }
      },
      token,
      'Successfully created new alarm action'
    )
  }, [form.values])

  const updateSensorInstances = useCallback((alarmActionId: number, updatedSensorInstanceIds: string[]) => {
    const newSensorInstanceIds = updatedSensorInstanceIds.filter(id => !initialSensorInstanceIds.includes(id))
    const removedSensorInstanceIds = initialSensorInstanceIds.filter(id => !updatedSensorInstanceIds.includes(id))

    if (newSensorInstanceIds.length) {
      void assignSensorInstances.fetch({
        alarmActionId,
        sensorInstanceIds: newSensorInstanceIds.map(id => Number(id))
      }, token)
    }

    if (removedSensorInstanceIds.length) {
      void removeSensorInstances.fetch({
        alarmActionId,
        sensorInstanceIds: removedSensorInstanceIds.map(id => Number(id))
      }, token)
    }
  }, [initialSensorInstanceIds])

  const handleUpdate = useCallback(({
    name,
    action,
    onTrigger,
    onClear,
    userGroupId,
    sensorInstanceIds
  }: FormValues) => {
    const alarmActionId = initialValues?.id

    if (!alarmActionId) {
      throw Error('Unable to perform the action, no alarm action provided')
    }

    updateSensorInstances(alarmActionId, sensorInstanceIds ?? [])

    void editAlarmAction
      .fetch({
        alarmActionId,
        name,
        action,
        onTrigger,
        onClear,
        userGroup: {
          id: Number(userGroupId)
        }
      }, token, 'Successfully updated alarm action')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const alarmActionId = initialValues?.id

    if (!alarmActionId) {
      throw Error('Unable to perform the action, no alarm action provided')
    }

    void removeAlarmAction
      .fetch({ alarmActionId }, token, 'Succesfully deleted alarm action')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) {
      handleCreate(values)
    }

    if (inEditMode) {
      handleUpdate(values)
    }
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    newAlarmAction.data.ifJust(({ id: alarmActionId }) => {
      // Assignment of sensor instances to alarm action upon creation
      if (form.values.sensorInstanceIds?.length) {
        void assignSensorInstances.fetch({
          alarmActionId,
          sensorInstanceIds: form.values.sensorInstanceIds.map(id => Number(id))
        }, token)
      }

      form.reset()

      if (onClose) {
        onClose()
      }

      if (onCreate) {
        void onCreate()
      }
    })
  }, [newAlarmAction.data])

  useEffect(() => {
    if (initialValues) {
      const {
        name,
        action,
        onTrigger,
        onClear,
        userGroup
      } = initialValues
      form.setValues({
        name,
        action,
        onTrigger,
        onClear,
        sensorInstanceIds: initialSensorInstanceIds,
        userGroupId: userGroup.id.toString()
      })
    }
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <Select
              label="Action"
              placeholder="Action"
              data={actionTypes}
              required
              {...form.getInputProps('action')}
            />
          </Col>
          <Col {...inputColProps}>
            <SelectUserGroup
              label="User Group"
              placeholder="Choose a user group"
              required
              searchable
              {...form.getInputProps('userGroupId')}
            />
          </Col>
          <Col {...inputColProps}>
            <MultiSelectSensorInstance
              label="Sensors"
              value={form.values.sensorInstanceIds}
              onChange={ids => (
                form.setFieldValue('sensorInstanceIds', ids)
              )}
              withAlarmConfig
              searchable
            />
          </Col>
          <Col span={12}>
            <Stack spacing="xs">
              <Switch
                label="On Trigger"
                color="primary"
                size="sm"
                checked={form.values.onTrigger}
                onChange={event => {
                  form.setFieldValue('onTrigger', event.currentTarget.checked)
                }}
              />
              <Switch
                label="On Clear"
                color="primary"
                size="sm"
                checked={form.values.onClear}
                onChange={event => {
                  form.setFieldValue('onClear', event.currentTarget.checked)
                }}
              />
              <Switch
                label="Notification Popup"
                color="primary"
                size="sm"
                checked={form.values.onClear}
                onChange={event => {
                  form.setFieldValue('onClear', event.currentTarget.checked)
                }}
              />
            </Stack>
          </Col>
          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="sm">
                <Button
                  mt="sm"
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode
                  ? handleClose
                  : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={[
                      'fas',
                      inCreateMode
                        ? 'circle-plus'
                        : 'floppy-disk'
                    ]}
                    color="white"
                  />
                )}
                disabled={inCreateMode
                  ? newAlarmAction.loading
                  : editAlarmAction.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeAlarmAction.loading}
          question="Are you sure you want to delete this alarm action? This cannot be undone."
          onClose={() => {
            setShowDelete(false)
          }}
          onCancel={() => {
            setShowDelete(false)
          }}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
