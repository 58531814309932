import { useCallback, useEffect, useState } from 'react'
import {
  useMantineTheme,
  Box,
  Button,
  Col,
  Grid,
  Group,
  TransferList,
  type TransferListData,
  Stack,
  Text,
  Tooltip,
  Center
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { useNavigate, useParams } from 'react-router'
import {
  getAgentDetails,
  updateAgent,
  setAgentName,
  gpsSettings,
  type GpsSetting,
  assignAgentToGroup,
  unassignAgentFromGroup,
  updateAgentSensorInstances,
  type SensorInstance
} from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import Paper from 'src/Layout/Paper'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import { useApi } from 'src/utils/useApi'
import { mq } from 'src/utils/style'
import CoreMap from 'src/Maps/CoreMap'
import IconSelector from 'src/Input/IconSelector'
import SelectAssetType from 'src/Input/Select/SelectAssetType'
import SelectSite from 'src/Input/Select/SelectSite'
import SelectUser from 'src/Input/Select/SelectUser'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import { defaultLocation, transmitIntervalData, useStyles } from './constants'

interface RouteParams extends Record<string, string | undefined> {
  agentId: string
}

interface AgentFormProps {
  agentId: number
  siteId: number
  deviceId?: string
  assetType?: string
  rfidTag?: string
  groupIds: string[]
  transmitInterval?: number
  ids: TransferListData
  orgUserId?: number
  orgOwnerId?: number
  gpsSetting: GpsSetting
  iconStyle?: string
  latitude: string
  longitude: string
}

interface FormProps extends Omit<AgentFormProps, 'transmitInterval' | 'gpsSetting'> {
  transmitInterval: string | null
  gpsSetting: GpsSetting | string
}

export default function Configure () {
  const { token } = useUser()
  const { classes } = useStyles()
  const { showError, showSuccess } = useNotifications()
  const theme = useMantineTheme()
  const params = useParams<RouteParams>()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.md, false))
  const agentDetails = useApi(getAgentDetails)
  const assignToGroup = useApi(assignAgentToGroup)
  const updateAgentInfo = useApi(updateAgent)
  const unassignFromGroup = useApi(unassignAgentFromGroup)
  const updateAgentSensor = useApi(updateAgentSensorInstances)
  const [updateInFlight, setUpdateInFlight] = useState(false)
  const [nameInFlight, setNameInFlight] = useState(false)
  const [locationMarker, setLocationMarker] = useState(defaultLocation)

  const redirectToListPage = useCallback(() => {
    navigate('/settings/agents')
  }, [])

  if (typeof params.agentId === 'undefined' || isNaN(parseInt(params.agentId))) {
    return <NotFound />
  }

  const agentId = parseInt(params.agentId)

  const nameForm = useForm({
    initialValues: {
      agentId,
      name: ''
    },
    validate: {
      agentId: value => value < 0
        ? 'Please select specific Agent'
        : null,
      name: value => value.length < 1
        ? 'Please input an Agent Name'
        : null
    }
  })

  const handleSubmitName = (values: typeof nameForm.values) => {
    setNameInFlight(true)
    void setAgentName(values, token)
      .caseOf({
        Left: err => showError(err),
        Right: () => {
          setNameInFlight(false)
          showSuccess('Updated agent name')
          void loadAgentDetails()
        }
      })
      .finally(() => setNameInFlight(false))
  }

  const agentForm = useForm<FormProps>({
    initialValues: {
      agentId,
      rfidTag: '',
      siteId: -1,
      groupIds: [],
      transmitInterval: null as string | null,
      ids: [[], []] as TransferListData,
      gpsSetting: '' as GpsSetting | '',
      latitude: '0',
      longitude: '0',
      iconStyle: ''
    },
    validate: {
      agentId: value => value < 0
        ? 'Please select specific Agent'
        : null,
      siteId: value => value < 0
        ? 'Please select specific Site'
        : null,
      latitude: val => {
        const lat = parseFloat(val)

        return (isNaN(lat) || (lat < -90 || lat > 90))
          ? 'Please input valid latitude (-90 to 90)'
          : null
      },
      longitude: val => {
        const lng = parseFloat(val)

        return (isNaN(lng) || (lng < -180 || lng > 180))
          ? 'Please input valid longitude (-180 to 180)'
          : null
      }
    }
  })

  const isManualLocation = agentForm.values.gpsSetting === 'MANUAL'

  const currentGroupIds: string[] = (
    agentDetails.data.mapOrDefault(({ agentGroups }) => (
      agentGroups.map(({ agentGroupId }) => agentGroupId.toString())
    ), [])
  )

  const updateAgentGroups = useCallback((currentGroupIds: string[], updatedGroupIds: string[]) => {
    const newGroupIds = updatedGroupIds.filter(id => !currentGroupIds.includes(id))
    const removedGroupIds = currentGroupIds.filter(id => !updatedGroupIds.includes(id))

    if (newGroupIds.length) {
      void assignToGroup.fetch({
        agentId,
        groupIds: newGroupIds.map(id => Number(id))
      }, token).finally(() => {
        loadAgentDetails()
      })
    }

    if (removedGroupIds.length) {
      void unassignFromGroup.fetch({
        agentId,
        groupIds: removedGroupIds.map(id => Number(id))
      }, token).finally(() => {
        loadAgentDetails()
      })
    }
  }, [agentId])

  // Update function for sensors in agent
  const updateSensors = useCallback((ids: TransferListData) => {
    const [primary, system] = ids
    const sensorInstances: SensorInstance[] = agentDetails.data.mapOrDefault(({ sensors }) => (
      sensors.map(({ sensorInstanceId }) => ({
        sensorInstanceId,
        primaryFunction: !!primary.find(sensor => sensor.value === sensorInstanceId.toString()),
        systemOnly: !!system.find(sensor => sensor.value === sensorInstanceId.toString())
      }))
    ), [])

    void updateAgentSensor.fetch({
      agentId,
      sensorInstances
    }, token)
  }, [agentDetails.data, agentId])

  const handleSubmit = useCallback(({
    siteId,
    transmitInterval: rawTransmitInterval,
    ids,
    agentId,
    orgUserId,
    orgOwnerId,
    latitude,
    longitude,
    gpsSetting,
    assetType,
    rfidTag,
    iconStyle,
    groupIds
  }: typeof agentForm.values) => {
    if (isNaN(Number(rawTransmitInterval)) || !siteId) {
      return
    }

    const transmitInterval = rawTransmitInterval
      ? parseInt(rawTransmitInterval)
      : undefined
    const isManual = gpsSetting === 'MANUAL'
    const geoLocation = {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    }
    const user = orgUserId
      ? { orgUserId }
      : undefined
    const owner = orgOwnerId
      ? { orgUserId: orgOwnerId }
      : undefined

    setUpdateInFlight(true)

    updateSensors(ids)

    updateAgentGroups(currentGroupIds, groupIds)

    void updateAgentInfo
      .fetch({
        agentId,
        siteId,
        transmitInterval,
        user,
        owner,
        gpsSetting: gpsSetting
          ? gpsSetting as GpsSetting
          : undefined,
        geoLocation: isManual
          ? geoLocation
          : undefined,
        assetType,
        rfidTag,
        iconStyle
      }, token, 'Saved successfully!')
      .finally(() => {
        setUpdateInFlight(false)
      })
  }, [agentDetails.data])

  const loadAgentDetails = () => {
    void agentDetails.fetch({ agentId }, token)
  }

  useEffect(() => {
    loadAgentDetails()
  }, [agentId])

  useEffect(() => {
    let sensors: TransferListData = [[], []]
    agentDetails.data.ifJust(({
      transmitInterval,
      user,
      owner,
      gpsSetting,
      geoLocation,
      iconStyle,
      sensors: agentSensors,
      agentGroups,
      siteId,
      rfidTag,
      agentName,
      assetType
    }) => {
      const newLocation = {
        latitude: `${defaultLocation.latitude} (DEFAULT VALUE, PLEASE CHANGE)`,
        longitude: `${defaultLocation.longitude} (DEFAULT VALUE, PLEASE CHANGE)`
      }

      if (geoLocation) {
        const { latitude, longitude } = geoLocation

        newLocation.latitude = latitude.toString()
        newLocation.longitude = longitude.toString()

        setLocationMarker({
          longitude,
          latitude
        })
      } else {
        setLocationMarker(defaultLocation)
      }

      sensors = agentSensors.reduce<TransferListData>((
        acc,
        {
          sensorInstanceId,
          name,
          primaryFunction
        }
      ) => {
        const value = {
          value: sensorInstanceId.toString(),
          label: name
        }
        return primaryFunction
          ? [
              [
                ...acc[0],
                value
              ],
              acc[1]
            ]
          : [
              acc[0],
              [
                ...acc[1],
                value
              ]
            ]
      }, [[], []])

      nameForm.setValues({
        agentId,
        name: agentName
      })

      return agentForm.setValues({
        ids: sensors,
        transmitInterval: transmitInterval?.toString() ?? null,
        orgUserId: user?.orgUserId,
        orgOwnerId: owner?.orgUserId,
        gpsSetting: gpsSetting as unknown as GpsSetting,
        iconStyle,
        latitude: newLocation.latitude,
        longitude: newLocation.longitude,
        groupIds: agentGroups.map(({ agentGroupId }) => agentGroupId.toString()) ?? [],
        siteId,
        rfidTag,
        assetType
      })
    }
    )
  }, [agentDetails.data])

  const showErrorUpdateGroup = useCallback((error: Error) => {
    showError(error)
  }, [])

  const isLoading =
    updateAgentInfo.loading ||
    assignToGroup.loading ||
    unassignFromGroup.loading ||
    updateAgentSensor.loading

  useEffect(() => {
    setUpdateInFlight(isLoading)
  }, [isLoading])

  useEffect(() => {
    assignToGroup.error.ifJust(showErrorUpdateGroup)
  }, [assignToGroup.error])

  useEffect(() => {
    unassignFromGroup.error.ifJust(showErrorUpdateGroup)
  }, [unassignFromGroup.error])

  return (
    agentDetails.data.caseOf({
      Nothing: () => (
        <Nothing
          isLoading={agentDetails.loading}
          nothing={agentDetails.data.isNothing()}
        />
      ),
      Just: () => {
        return (
          <Paper>
            <form onSubmit={nameForm.onSubmit(handleSubmitName)}>
              <Grid gutter="xs" align="end">
                <Col
                  span={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'end'
                  }}
                >
                  <TextInput
                    label="Agent Name"
                    {...nameForm.getInputProps('name')}
                    sx={{
                      width: '95%',
                      display: 'inline-block'
                    }}
                  />
                  <Tooltip
                    label="Update agent name"
                    position="top"
                    transitionProps={{
                      transition: 'pop',
                      duration: 200
                    }}
                    withArrow
                  >
                    <Button
                      sx={{
                        height: '37px',
                        padding: '0',
                        width: '45px',
                        marginLeft: '1%'
                      }}
                      type="submit"
                      size="md"
                      color="green"
                      disabled={nameInFlight}
                    >
                      <Center>
                        <FontAwesomeIcon
                          icon={['fas', 'floppy-disk']}
                          color="white"
                        />
                      </Center>
                    </Button>
                  </Tooltip>
                </Col>
              </Grid>
            </form>
            <form onSubmit={agentForm.onSubmit(handleSubmit)}>
              <Grid gutter="xs" align="end">
                <Col span={12}>
                  <SelectAssetType
                    label="Asset Type"
                    {...agentForm.getInputProps('assetType')}
                    value={agentForm.values.assetType?.toString()}
                  />
                </Col>
                <Col span={12}>
                  <TextInput
                    label="Board ID"
                    value={agentDetails.data.mapOrDefault(({ deviceId }) => (
                      deviceId?.toString()
                    ), '')}
                    disabled
                  />
                </Col>
                <Col span={12}>
                  <TextInput label="RFID Tag" {...agentForm.getInputProps('rfidTag')} />
                </Col>
                <Col span={12}>
                  <MultiSelectAgentGroup
                    label="Groups"
                    value={agentForm.values.groupIds}
                    onChange={ids => {
                      agentForm.setFieldValue('groupIds', ids)
                    }}
                    searchable
                  />
                </Col>
                <Col span={12}>
                  <Select
                    label="Transmit Interval"
                    data={transmitIntervalData}
                    {...agentForm.getInputProps('transmitInterval')}
                  />
                </Col>
                <Col span={12}>
                  <SelectSite
                    label="Site"
                    value={agentForm.values.siteId.toString()}
                    onChange={val => {
                      agentForm.setFieldValue('siteId', Number(val))
                    }}
                    searchable
                  />
                </Col>
                <Col span={12}>
                  <Stack spacing="xs">
                    <Text>Sensors</Text>
                    <TransferList
                      value={agentForm.values.ids}
                      onChange={value => {
                        agentForm.setFieldValue('ids', value)
                      }}
                      searchPlaceholder="Search..."
                      nothingFound="Nothing here"
                      titles={['Primary', 'System']}
                      breakpoint="sm"
                      classNames={{
                        transferListTitle: classes.transferListTitle
                      }}
                    />
                  </Stack>
                </Col>
                <Col span={12}>
                  <SelectUser
                    label="User"
                    placeholder="Choose a user"
                    {...agentForm.getInputProps('orgUserId')}
                    value={agentForm.values.orgUserId?.toString()}
                    searchable
                  />
                </Col>
                <Col span={12}>
                  <SelectUser
                    label="Owner"
                    placeholder="Choose an owner"
                    {...agentForm.getInputProps('orgOwnerId')}
                    value={agentForm.values.orgOwnerId?.toString()}
                    searchable
                  />
                </Col>
                <Col span={12} sm={3}>
                  <IconSelector
                    label="Agent Icon"
                    width={isDesktop
                      ? 450
                      : '85%'}
                    value={agentForm.values.iconStyle}
                    onChange={(value) => {
                      agentForm.setFieldValue('iconStyle', value)
                    }}
                  />
                </Col>
                <Col span={12} sm={3}>
                  <Select
                    label="Location Type"
                    data={[...gpsSettings]}
                    {...agentForm.getInputProps('gpsSetting')}
                  />
                </Col>
                <Col span={12} sm={3}>
                  <TextInput
                    label="Latitude"
                    disabled={!isManualLocation}
                    onBlur={ev => {
                      setLocationMarker({
                        ...locationMarker,
                        latitude: parseFloat(ev.currentTarget.value)
                      })
                    }}
                    {...agentForm.getInputProps('latitude')}
                  />
                </Col>
                <Col span={12} sm={3}>
                  <TextInput
                    label="Longitude"
                    disabled={!isManualLocation}
                    onBlur={ev => {
                      setLocationMarker({
                        ...locationMarker,
                        longitude: parseFloat(ev.currentTarget.value)
                      })
                    }}
                    {...agentForm.getInputProps('longitude')}
                  />
                </Col>
              </Grid>
              <Box mt="xs">
                <CoreMap
                  height="500px"
                  width="100%"
                  longitude={locationMarker.longitude}
                  latitude={locationMarker.latitude}
                  showLocationMarker
                  showLocationMarkerInformation
                  zoom={5}
                  canPan={isManualLocation}
                  canZoom={isManualLocation}
                  canRotate={isManualLocation}
                  onMarkerChange={({ latitude, longitude }) => {
                    agentForm.setFieldValue('longitude', longitude.toString())
                    agentForm.setFieldValue('latitude', latitude.toString())
                    setLocationMarker({
                      longitude,
                      latitude
                    })
                  }}
                />
              </Box>
              <Grid gutter="xs" align="end">
                <Col span={12}>
                  <Group position="right" mt="sm">
                    <Button
                      color="gray"
                      size="md"
                      leftIcon={(
                        <FontAwesomeIcon icon={['fas', 'ban']} color="white" />
                      )}
                      onClick={redirectToListPage}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="green"
                      disabled={updateInFlight}
                      size="md"
                      leftIcon={(
                        <FontAwesomeIcon
                          icon={['fas', 'floppy-disk']}
                          color="white"
                        />
                      )}
                    >
                      Save
                    </Button>
                  </Group>
                </Col>
              </Grid>
            </form>
          </Paper>
        )
      }
    })
  )
}
