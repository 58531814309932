import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import {
  ActionIcon,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { getAgentStatistics } from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useEffect } from 'react'
import { roundToKm } from 'src/utils/math'
import { findSensorByType } from '../shared'
import { MODULE_CONTENT } from './constants'
interface Props {
  agent: AgentDetails
}

interface TableRowProps {
  property: string
  value?: string | number | null
}

const TableRow = ({ property, value }: TableRowProps) => (
  <tr>
    <td>
      <Text
        transform="uppercase"
        weight="bold"
        size="xs"
        sx={{ minWidth: 80 }}
      >
        {property}
      </Text>
    </td>
    <td>
      <Text size="xs">{value ?? '-'}</Text>
    </td>
  </tr>
)

function Details ({
  agent
}: Props) {
  const {
    agentId,
    agentName,
    deviceId,
    lastSeenTime,
    sensors,
    make,
    model,
    assetType
  } = agent

  const agentStatistic = useApi(getAgentStatistics)
  const { token } = useUser()
  const voltage = findSensorByType('EXT_POWER_SOURCE_VOLTAGE', sensors)
  const speed = findSensorByType('POSITION_SPEED', sensors)
  const statistic = useApi(getAgentStatistics)

  useEffect(() => {
    if (assetType === 'VEHICLE') {
      void statistic.fetch({ agentId }, token)
    }
  }, [assetType])

  useEffect(() => {
    void agentStatistic.fetch({ agentId }, token)
  }, [])

  const odometerInKm = agentStatistic.data.mapOrDefault(({ odometerInMeters }) => (
    roundToKm(odometerInMeters)), 0)

  return (
    <Stack>
      <Group position="apart">
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.VEHICLE_DETAILS}
        </Text>
        <Tooltip withinPortal label="Agent config">
          <Link to={`/settings/agents/${agentId}`}>
            <ActionIcon>
              <FontAwesomeIcon icon={['far', 'cog']} />
            </ActionIcon>
          </Link>
        </Tooltip>
      </Group>
      <ScrollArea
        type="auto"
        h={250}
        scrollbarSize={5}
      >
        <Stack spacing="xs">
          <Table>
            <tbody>
              <TableRow
                property="Asset ID"
                value={agentId}
              />
              {make && (
                <TableRow
                  property="Asset make"
                  value={make}
                />
              )}
              <TableRow
                property="Asset name"
                value={agentName}
              />
              {model && (
                <TableRow
                  property="Asset model"
                  value={model}
                />
              )}
              <TableRow
                property="Asset Serial"
                value={deviceId}
              />
              <TableRow
                property="Current speed"
                value={`${speed?.currentValue ?? '-'} ${speed?.unit ?? '-'}`}
              />
              <TableRow
                property="Last seen"
                value={lastSeenTime}
              />
              <TableRow
                property="External voltage"
                value={`${voltage?.currentValue ?? '-'} ${voltage?.unit ?? '-'}`}
              />
              {assetType === 'VEHICLE' && (
                <TableRow
                  property="Odometer"
                  value={odometerInKm + ' ' + 'km'}
                />
              )}
            </tbody>
          </Table>
        </Stack>
      </ScrollArea>
    </Stack>
  )
}

export default Details
