import { type EditingMode } from 'react-map-gl-draw'
import { type ViewMode } from '@nebula.gl/edit-modes'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'

export type Mode = {
  modeId: string | undefined
  modeHandler: ViewMode | EditingMode | undefined
} | undefined

export type MapMode = {
  id: string | undefined
  desc: string | undefined | null
} | undefined

export interface Feature {
  type: 'Feature'
  properties: {
    data: {
      geoZoneId: number
      orgId?: number
      name: string
      description?: string
      hiddenOnMap: boolean
    }
    shape?: string
    guideType?: string
  }
  geometry: {
    type: 'Polygon'
    coordinates: Array<Array<[number, number]>>
  }
}

export interface FeatureWithId extends Feature {
  id?: string
}

export interface FeatureOptions {
  mapCoords: Array<[number, number]>
  screenCoords: number[]
  selectedEditHandleIndex: number | null
  selectedEditHandleIndexes: number[]
  selectedFeature: Feature
  selectedFeatureIndex: number
}

export interface ActiveFeature {
  feature: Feature
}

export interface LocationMark {
  longitude: number
  latitude: number
}

export const geoZoneToFeature = ({ boundary, ...geoZone }: GeoZone) => ({
  type: 'Feature' as const,
  properties: {
    data: { ...geoZone }
  },
  geometry: boundary
})

export const featureToGeoZone = ({ properties: { data }, geometry }: Feature) => ({
  ...data,
  boundary: { ...geometry }
})
