import {
  Box,
  Transition,
  Stack,
  Text,
  useMantineTheme,
  Group
} from '@mantine/core'
import React, { type CSSProperties, useContext } from 'react'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { type TripDetails } from '@venturi-io/api/src/collector/trip'
import { getTripContext } from '../AnimationContext'
import { convertMillisecondsToHumanReadableTime } from './shared'

interface Props {
  hovered: boolean
}

const top = -75

interface TooltipProps {
  label: string
  value: number
  trip: TripDetails
  reversed: TripDetails['rawPath']
  endTime: dayjs.Dayjs
  styles: CSSProperties
}

const Tooltip = ({
  label,
  value,
  trip,
  reversed,
  endTime,
  styles
}: TooltipProps) => {
  const point = trip.rawPath[value]
  const actual = dayjs(point.time, dateFormat)
  const reversedActual = dayjs(reversed[value].time, dateFormat)
  const interval = endTime.diff(reversedActual)

  return (
    <Box
      p="xs"
      style={styles}
    >
      <Stack spacing={0} sx={{ bottom: 20 }}>
        <Group position="apart">
          <Text color="white" component="span" weight="bold">{label}</Text>
          <Text color="white" size="xs">
            {convertMillisecondsToHumanReadableTime(interval)}
          </Text>
        </Group>
        <Text color="white" size="xs">
          {actual.format('DD/MM/YY HH:mm:ss')}
        </Text>
      </Stack>
    </Box>
  )
}

export default function RangeTooltip ({ hovered }: Props) {
  const { trip, frameRange } = useContext(getTripContext())
  if (!trip) {
    return null
  }

  const { colors } = useMantineTheme()
  const [start, stop] = frameRange
  const endTime = dayjs(trip.rawPath[trip.rawPath.length - 1].time, dateFormat)
  const reversed = [...trip.rawPath].reverse()

  const sharedStyles: CSSProperties = {
    borderRadius: 8,
    position: 'absolute',
    top,
    backgroundColor: colors.dark[6]
  }

  const sharedProps = {
    trip,
    endTime,
    reversed
  }

  return (
    <Transition mounted={hovered} transition="pop" duration={250} timingFunction="ease">
      {styles => (
        <>
          <Tooltip
            label="Start"
            value={start}
            {...sharedProps}
            styles={{
              ...styles,
              ...sharedStyles,
              left: 0
            }}
          />
          <Tooltip
            label="End"
            value={stop}
            {...sharedProps}
            styles={{
              ...styles,
              ...sharedStyles,
              right: 0
            }}
          />
        </>
      )}
    </Transition>
  )
}
