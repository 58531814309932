import { useCallback, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Group,
  Menu,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type Dashboard,
  setDefaultDashboard,
  deleteDashboard
} from '@venturi-io/api/src/userManager/dashboard'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { type ItemType } from '../../Items'
import ActionButton from '../../../ActionButton'
import { menuDashboardItems } from './menu'

const useStyles = createStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [mq(theme.breakpoints.xs)]: {
      justifyContent: 'flex-end'
    }
  },
  menuDropdown: {
    height: 450,
    overflow: 'auto',
    scrollbarWidth: 'thin'
  }
}))

interface Props {
  selectedDashboard: Dashboard | null
  editMode: boolean
  setShowCreateDashboard: (status: boolean) => void
  setEditMode: (status: boolean) => void
  onSaveLayout: () => void
  onResetLayout: () => void
  onDeleteDashboard: (dashboardId: Dashboard['dashboardId']) => void
  onAddItem: (type: ItemType) => void
}

const Controls = ({
  selectedDashboard,
  editMode,
  setShowCreateDashboard,
  setEditMode,
  onSaveLayout,
  onResetLayout,
  onDeleteDashboard,
  onAddItem
}: Props) => {
  const { classes } = useStyles()
  const { token } = useUser()
  const editDefaultDashboard = useApi(setDefaultDashboard)
  const removeDashboard = useApi(deleteDashboard)

  const [isOpenItemsMenu, setIsOpenItemsMenu] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenResetModal, setIsOpenResetModal] = useState(false)

  const handleSetHomeDashboard = useCallback(() => {
    if (selectedDashboard) {
      const { dashboardId } = selectedDashboard
      const req = {
        dashboardId,
        remove: false
      }

      void editDefaultDashboard
        .fetch(req, token)
        .finally(() => {
          window.location.reload()
        })
    }
  }, [selectedDashboard])

  const handleDeleteDashboard = useCallback(() => {
    if (selectedDashboard) {
      const { dashboardId } = selectedDashboard

      void removeDashboard
        .fetch({ dashboardId }, token, 'Dashboard successfully deleted!')
        .finally(() => {
          onDeleteDashboard(dashboardId)
          setIsOpenDeleteModal(false)
          window.location.reload()
        })
    }
  }, [selectedDashboard, onDeleteDashboard])

  return (
    <>
      <Group
        id="tour-dashboard__widgets"
        className={classes.container}
        spacing={8}
        mr="xs"
      >
        {selectedDashboard && !selectedDashboard.isDefault && !editMode && (
          <ActionButton
            label="Set as home"
            icon="house-heart"
            onClick={handleSetHomeDashboard}
          />
        )}
        {!editMode && (
          <ActionButton
            label="Create new dashboard"
            icon="layer-plus"
            onClick={() => setShowCreateDashboard(true)}
          />
        )}
        {selectedDashboard && (
          <>
            {editMode
              ? (
                <>
                  <ActionButton
                    label="Save layout"
                    icon="floppy-disk-pen"
                    onClick={() => onSaveLayout()}
                  />
                  <ActionButton
                    label="Reset layout"
                    icon="rotate-left"
                    onClick={() => setIsOpenResetModal(true)}
                  />
                </>
                )
              : (
                <>
                  <ActionButton
                    label="Delete dashboard"
                    icon="layer-minus"
                    onClick={() => setIsOpenDeleteModal(true)}
                  />
                  <Menu
                    opened={isOpenItemsMenu}
                    width="xl"
                    shadow="md"
                    position="bottom-end"
                    transitionProps={{
                      transition: 'pop-top-right'
                    }}
                    closeOnClickOutside
                    onClose={() => setIsOpenItemsMenu(prevStatus => !prevStatus)}
                    withArrow
                  >
                    <Menu.Target>
                      <Tooltip
                        label="Add widget"
                        position="top"
                        transitionProps={{
                          transition: 'pop',
                          duration: 200
                        }}
                        withArrow
                      >
                        <ActionIcon onClick={() => setIsOpenItemsMenu(prevStatus => !prevStatus)}>
                          <FontAwesomeIcon icon={['fas', 'grid-2-plus']} color="dark" size="1x" />
                        </ActionIcon>
                      </Tooltip>
                    </Menu.Target>
                    <Menu.Dropdown className={classes.menuDropdown}>
                      {menuDashboardItems.map(({ name, icon }) => (
                        <Menu.Item
                          key={name}
                          icon={<FontAwesomeIcon icon={['fas', icon]} size="sm" />}
                          onClick={() => {
                            setIsOpenItemsMenu(false)
                            onAddItem(name)
                          }}
                        >
                          {name}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>

                </>
                )}
            <ActionButton
              label={editMode ? 'Stop editing' : 'Edit'}
              icon={editMode ? 'cancel' : 'pen-to-square'}
              onClick={() => setEditMode(!editMode)}
            />
          </>
        )}
      </Group>
      {selectedDashboard && (
        <>
          <ConfirmModal
            type="delete"
            opened={isOpenDeleteModal}
            title={`Deleting "${selectedDashboard.name}" dashboard`}
            question="Are you sure you want to proceed? This action cannot be undone."
            onClose={() => setIsOpenDeleteModal(false)}
            onCancel={() => setIsOpenDeleteModal(false)}
            onConfirm={handleDeleteDashboard}
          />
          <ConfirmModal
            type="delete"
            opened={isOpenResetModal}
            title={`Resetting "${selectedDashboard.name}" layout`}
            question="Are you sure you want to proceed? This action cannot be undone."
            confirmLabel="Reset"
            onClose={() => setIsOpenResetModal(false)}
            onCancel={() => setIsOpenResetModal(false)}
            onConfirm={() => {
              setIsOpenResetModal(false)
              onResetLayout()
            }}
          />
        </>
      )}
    </>
  )
}

export default Controls
