import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type AgentIdRequest, geoLocation } from '../shared'

const agentLocation = z.object({
  timestamp: z.string(),
  geoLocation
})

const agentLocationHistory = z.object({
  items: z.array(agentLocation),
  totalItems: z.number()
})

const agentData = z.object({
  sensorName: z.string(),
  value: z.string()
})

const agentDurationAggregate = z.object({
  totalIdlingDuration: z.number(),
  totalParkedDuration: z.number(),
  totalEngineIgnitionDuration: z.number()
})

const agentActualData = z.array(agentData)

export type AgentData = z.infer <typeof agentActualData>
export type AgentLocation = z.infer<typeof agentLocation>
export type AgentLocationHistory = z.infer<typeof agentLocationHistory>
export type AgentDurationAggregate = z.infer<typeof agentDurationAggregate>

export interface AgentDataRequest extends AgentIdRequest {
  time: string
}

export interface AgentLocationHistoryRequest extends AgentIdRequest {
  startTime: string
  endTime: string
  noOfRecords: number
}

export const getAgentLocationHistory = createStatefulApi<AgentLocationHistoryRequest, typeof agentLocationHistory>(
  'collector',
  '/data/agent/:agentId/location',
  { method: 'POST' },
  agentLocationHistory
)

export const getAgentActualData = createStatelessApi<AgentDataRequest, typeof agentActualData>(
  'collector',
  '/data/agent/:agentId/actual-data',
  { method: 'GET', type: 'path' },
  agentActualData
)

export const getAgentDurationAggregate = createStatelessApi<AgentIdRequest, typeof agentDurationAggregate>(
  'collector',
  '/agents/:agentId/location-aggregate/durations',
  { method: 'GET', type: 'path' },
  agentDurationAggregate
)
