import { memo, useEffect, useMemo } from 'react'
import { type Agent, getAgents } from '@venturi-io/api/src/config/agent'
import { useMantineTheme, Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import AutoGrid, { type Props as AutoGridProps } from 'src/Charts/AutoGrid'
import { type ColorGradient } from 'src/Charts/shared'
import { Link } from 'react-router-dom'
import { truncateWithEllipsis } from 'src/utils/strings'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data: {
    agentIds: Array<Agent['agentId']>
  }
  styles: AutoGridProps['styles']
}

const AgentStatus = ({
  data: {
    agentIds
  },
  styles
}: Props) => {
  const { orgId, token } = useUser()
  const allAgents = useApi(getAgents)
  const { colors } = useMantineTheme()
  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()
  const agents = useMemo(() => (
    allAgents.data.mapOrDefault(({ items }) => (
      items.filter(({ agentId }) => agentIds.includes(agentId))
    ), [])
  ), [allAgents.data, agentIds])

  const gridHeight = useMemo(() => (height / agents.length), [height, agents])

  const statusColors: Record<string, ColorGradient> = useMemo(() => ({
    OK: {
      primary: colors.green[4],
      secondary: colors.green[7]
    },
    WARNING: {
      primary: colors.yellow[4],
      secondary: colors.yellow[7]
    },
    ALARM: {
      primary: colors.red[4],
      secondary: colors.red[7]
    }
  }), [])

  useEffect(() => {
    void allAgents.fetch({
      orgId,
      page: 1,
      size: 99999
    }, token)
  }, [])

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      {allAgents.loading && <Loader variant="bars" />}
      {!allAgents.loading && (
        agents.map(({ agentId, agentName, alarmStatus }) => (
          <Link
            className={classes.linkRedirect}
            to={`/agent/${agentId}`}
            key={`${agentId} - ${agentName}`}
          >
            <AutoGrid
              name={truncateWithEllipsis(agentName, 25)}
              value={truncateWithEllipsis(agentName, 25)}
              styles={{
                ...styles,
                width,
                height: gridHeight,
                theme: {
                  textColor: '#ffffff',
                  backgroundColor: statusColors[alarmStatus]
                },
                withoutTitle: true
              }}
            />
          </Link>
        ))
      )}
    </Container>
  )
}

export default memo(AgentStatus)
