import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const kwHour = findSensorByType('ACTIVE_THREE_PHASE', sensors)
  const Hz = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)
  const V = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)
  const A = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/power-meter/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
          />
        </Link>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(kwHour?.currentValue)}
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text size="sm">
          {parseEmpty(Hz?.currentValue)}
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text size="sm">
          {parseEmpty(V?.currentValue)}
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text size="sm">
          {parseEmpty(A?.currentValue)}
        </Text>
      </div>
    </div>
  )
}
