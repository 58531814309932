import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type PaginatedRequest, type AgentGroupIdRequest, paginated } from '../shared'

export const agentGroup = z.object({
  agentGroupId: z.number(),
  name: z.string(),
  description: z.string()
})

const allAgentGroups = paginated.extend({
  items: z.array(agentGroup)
})

export type AgentGroup = z.infer<typeof agentGroup>

interface CreateAgentGroupRequest extends Record<string, unknown> {
  name: string
  description: string
}

export const createAgentGroup = createStatefulApi<CreateAgentGroupRequest, typeof agentGroup>(
  'config',
  '/agent-group',
  { method: 'POST' },
  agentGroup
)

interface AgentsAssignmentRequest extends AgentGroupIdRequest {
  agentIds: number[]
}

export const assignAgentsToGroup = createStatefulApi<AgentsAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent-group/:agentGroupId/agents',
  { method: 'POST' },
  z.boolean()
)

export const removeAgentsFromGroup = createStatefulApi<AgentsAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent-group/:agentGroupId/agents',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

export const getAgentGroups = createStatelessApi<PaginatedRequest, typeof allAgentGroups>(
  'config',
  '/agent-groups',
  { method: 'GET', type: 'path' },
  allAgentGroups
)

export const getAgentGroup = createStatelessApi<AgentGroupIdRequest, typeof agentGroup>(
  'config',
  '/agent-group/:agentGroupId',
  { method: 'GET', type: 'path' },
  agentGroup
)

interface UpdateAgentGroupRequest extends AgentGroupIdRequest {
  name: string
  description?: string
}
export const updateAgentGroup = createStatefulApi<UpdateAgentGroupRequest, typeof agentGroup>(
  'config',
  '/agent-group/:agentGroupId',
  { method: 'PUT' },
  agentGroup
)

export const deleteAgentGroup = createStatefulApi<AgentGroupIdRequest, z.ZodBoolean>(
  'config',
  '/agent-group/:agentGroupId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

interface RolesAssignmentRequest extends AgentGroupIdRequest {
  roleIds: number[]
}

export const assignRolesToGroup = createStatefulApi<RolesAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent-group/:agentGroupId/roles',
  { method: 'POST' },
  z.boolean()
)

export const removeRolesFromGroup = createStatefulApi<RolesAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent-group/:agentGroupId/roles',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
