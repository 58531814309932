import { createStyles } from '@mantine/core'

export const useSelectStyles = createStyles(theme => ({
  input: {
    border: `0.808511px solid ${theme.colors.primary[6]}`,
    boxSizing: 'border-box',
    '&:active': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    },
    '&:focus': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    },
    '&[disabled]': {
      border: `0.808511px solid ${theme.colors.gray[4]}`
    }
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px'
  },
  values: {
    maxWidth: '350px'
  },
  item: {
    '&[data-selected]': {
      '&, &:hover': {
        background: theme.colors.primary[5]
      },
      '& .mantine-Text-root, & svg': {
        color: 'white'
      }
    }
  },
  day: {
    '&[data-selected]': {
      '&, &:hover': {
        background: theme.colors.primary[5]
      },
      '& .mantine-Text-root, & svg': {
        color: 'white'
      }
    }
  }
}))
