import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Box,
  Menu,
  Tooltip,
  createStyles
} from '@mantine/core'
import { useCallback, useContext } from 'react'
import { getTripContext } from '../GeoZoneMap/Trips/AnimationContext'

export enum MapType {
  satellite = 'mapbox://styles/venturidev/clntzsuec00xk01pn7uxg7844',
  simplified = 'mapbox://styles/venturidev/clhoq5ktd01u701p6gc841esq'
}

interface Props {
  type: MapType
  onChangeStyle: (mode: MapType) => void
}

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    width: 'auto',
    zIndex: 2
  }
}))

export default function StylePicker ({ type, onChangeStyle }: Props) {
  const { classes } = useStyles()
  const { onMapReloadStyle } = useContext(getTripContext())

  const handleChangeStyle = useCallback((mapStyle: MapType) => () => {
    onChangeStyle(mapStyle)
    onMapReloadStyle()
  }, [])

  return (
    <Box className={classes.container} p="sm">
      <Menu withArrow>
        <Menu.Target>
          <Tooltip label="Map mode" position="left" withArrow>
            <ActionIcon color="primary" variant="filled">
              <FontAwesomeIcon icon={['far', type === MapType.satellite ? 'earth-oceania' : 'globe']} />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Tooltip label="Satellite" position="left" withArrow>
            <ActionIcon
              color={type === MapType.satellite ? 'primary' : 'gray.5'}
              onClick={handleChangeStyle(MapType.satellite)}
            >
              <FontAwesomeIcon icon={['fas', 'earth-oceania']} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Simplified" position="left" withArrow>
            <ActionIcon
              color={type === MapType.simplified ? 'primary' : 'gray.5'}
              onClick={handleChangeStyle(MapType.simplified)}
            >
              <FontAwesomeIcon icon={['fas', 'globe']} />
            </ActionIcon>
          </Tooltip>
        </Menu.Dropdown>
      </Menu>
    </Box>
  )
}
