import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type OrgIdRequest, type PaginatedRequest, paginated } from '../shared'

const metaLocation = z.object({
  name: z.optional(z.string()),
  id: z.optional(z.string()),
  type: z.optional(z.string())
})

const geoLocation = z.object({
  latitude: z.optional(z.number()),
  longitude: z.optional(z.number()),
  metadata: z.optional(metaLocation)
})

export type DefaultGeoLocation = z.infer<typeof geoLocation>

const organisation = z.object({
  orgId: z.number(),
  name: z.string(),
  description: z.string().optional(),
  urls: z.array(z.string()),
  defaultGeoLocation: z.optional(geoLocation),
  defaultTimezone: z.optional(z.string()),
  speedLimit: z.optional(z.number())
})

const organisationWithParent = organisation.extend({
  parentOrgId: z.number().optional(),
  parentOrgName: z.string().optional()
})

export type Organisation = z.infer<typeof organisation>

interface OrgRequest extends Record<string, unknown> {
  name: string
  description?: string
  urls: string[]
  parentOrgId?: number
  defaultGeoLocation?: DefaultGeoLocation
}
export const createOrg = createStatefulApi<OrgRequest, typeof organisationWithParent>(
  'user',
  '/org',
  { method: 'POST' },
  organisationWithParent
)

const allOrgs = paginated.extend({
  items: z.array(organisation)
})

export const getOrgs = createStatelessApi<PaginatedRequest, typeof allOrgs>(
  'user',
  '/orgs',
  { method: 'GET', type: 'path' },
  allOrgs
)

export const getOrg = createStatelessApi<OrgIdRequest, typeof organisationWithParent>(
  'user',
  '/org/:orgId',
  { method: 'GET', type: 'path' },
  organisationWithParent
)

interface UpdateOrgRequest extends OrgIdRequest, OrgRequest {}

export const updateOrg = createStatefulApi<UpdateOrgRequest, typeof organisation>(
  'user',
  '/org/:orgId',
  { method: 'PUT' },
  organisation
)

export const deleteOrg = createStatefulApi<OrgIdRequest, typeof organisation>(
  'user',
  '/org/:orgId',
  { method: 'DELETE', type: 'path' },
  organisation
)

// Organisation theme
const color = z.tuple([
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
  z.string()
])

const orgTheme = z.object({
  primary: color,
  secondary: color,
  brand: z.tuple([z.string(), z.string(), z.string(), z.string()])
})

export const getOrgTheme = createStatelessApi<OrgIdRequest, typeof orgTheme>(
  'user',
  '/org/:orgId/theme',
  { method: 'GET', type: 'path' },
  orgTheme
)

export interface CreateOrgThemeRequest extends OrgIdRequest {
  primary: [string, string, string, string, string, string, string, string, string, string]
  secondary: [string, string, string, string, string, string, string, string, string, string]
  brand: [string, string, string, string]
}

export const createOrgTheme = createStatefulApi<CreateOrgThemeRequest, typeof orgTheme>(
  'user',
  '/org/:orgId/theme',
  { method: 'PUT' },
  orgTheme
)

const orgLogoTypes = [
  'topLogo',
  'topMobileLogo',
  'bottomLogo'
] as const

export type OrgLogoType = typeof orgLogoTypes[number]

const orgLogos = z.record(z.enum(orgLogoTypes), z.string())

export const getOrgLogos = createStatelessApi<OrgIdRequest, typeof orgLogos>(
  'user',
  '/image/org/:orgId/all',
  { method: 'GET', type: 'path' },
  orgLogos
)

interface OrgIdWithLogoTypeRequest extends OrgIdRequest {
  type: OrgLogoType
}

interface UploadOrgLogoReq extends OrgIdRequest {
  type: string
  logo: string
}

export const uploadOrgLogo = createStatefulApi<UploadOrgLogoReq, typeof orgLogos>(
  'user',
  '/image/org/:orgId',
  { method: 'POST' },
  orgLogos
)

export const downloadOrgLogo = createStatelessApi<OrgIdWithLogoTypeRequest, z.ZodUnknown>(
  'user',
  '/image/org/:orgId/logo.png',
  { method: 'GET', type: 'path' },
  z.unknown()
)

export const deleteOrgLogo = createStatefulApi<OrgIdWithLogoTypeRequest, z.ZodBoolean>(
  'user',
  '/image/org/:orgId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
