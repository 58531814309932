import { memo, useCallback, useEffect } from 'react'
import { Drawer } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import {
  type Dashboard,
  type DashboardItem,
  type DashboardItemRequest,
  createDashboardItem,
  updateDashboardItem,
  deleteDashboardItem
} from '@venturi-io/api/src/userManager/dashboard'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import TextTooltip from 'src/Layout/TextTooltip'
import { type FormMode } from 'src/utils'
import { type Item, type ItemType } from '../Items'
import Form from './Form'

interface Props {
  mode: FormMode | null
  opened: boolean
  initialValues: Item | null
  itemType: ItemType | null
  selectedDashboard: Dashboard | null
  selectedDashboardItem: DashboardItem | null
  setSelectedDashboard: (dashboard: Dashboard | null) => void
  onSubmit?: (item: Item) => void
  onDelete?: (itemId: DashboardItem['dashboardItemId']) => void
  onClose: () => void
}

const ItemForm = ({
  mode,
  opened,
  initialValues,
  itemType,
  selectedDashboard,
  selectedDashboardItem,
  setSelectedDashboard,
  onSubmit,
  onClose
}: Props) => {
  const { token } = useUser()
  const newDashboardItem = useApi(createDashboardItem)
  const editDashboardItem = useApi(updateDashboardItem)
  const removeDashboardItem = useApi(deleteDashboardItem)

  const handleCreateItem = useCallback((item: Item) => {
    if (selectedDashboard && itemType) {
      const { dashboardId } = selectedDashboard
      const req: DashboardItemRequest = {
        dashboardId,
        name: item.name,
        type: itemType,
        properties: {
          ...item,
          id: randomId()
        }
      }

      void newDashboardItem.fetch(req, token)
    }
  }, [selectedDashboard, itemType])

  useEffect(() => {
    if (selectedDashboard) {
      newDashboardItem.data.ifJust(item => {
        setSelectedDashboard({
          ...selectedDashboard,
          dashboardItems: [
            ...selectedDashboard?.dashboardItems ?? [],
            item
          ]
        })
      })
    }
  }, [newDashboardItem.data])

  const handleEditItem = useCallback((item: Item) => {
    if (selectedDashboardItem && itemType) {
      const { dashboardItemId, properties } = selectedDashboardItem
      const req: Omit<DashboardItemRequest, 'dashboardId'> = {
        name: item.name,
        type: itemType,
        properties: {
          ...properties,
          ...item
        }
      }

      void editDashboardItem.fetch({ dashboardItemId, ...req }, token)
    }
  }, [selectedDashboardItem, itemType])

  useEffect(() => {
    if (selectedDashboard) {
      editDashboardItem.data.ifJust(updatedItem => {
        setSelectedDashboard({
          ...selectedDashboard,
          dashboardItems: selectedDashboard?.dashboardItems?.map(item => {
            if (item.dashboardItemId === updatedItem.dashboardItemId) {
              return updatedItem
            }

            return item
          })
        })
      })
    }
  }, [editDashboardItem.data])

  const handleSubmit = useCallback((item: Item) => {
    if (mode === 'CREATE') {
      handleCreateItem(item)
    } else {
      handleEditItem(item)
    }

    if (onSubmit) onSubmit(item)
  }, [mode, handleCreateItem, handleEditItem])

  useEffect(() => {
    if (selectedDashboard && selectedDashboardItem) {
      removeDashboardItem.error.ifJust(() => {
        setSelectedDashboard({
          ...selectedDashboard,
          dashboardItems: [
            ...selectedDashboard?.dashboardItems ?? [],
            selectedDashboardItem
          ]
        })
      })
    }
  }, [removeDashboardItem.error])

  return (
    <Drawer
      title={(
        <TextTooltip
          size="xl"
          weight="bold"
          value={`${mode === 'CREATE' ? 'New' : 'Edit'} ${itemType}`}
          maxLength={35}
        />)}
      opened={opened}
      onClose={onClose}
      position="right"
      padding="lg"
      size={500}
    >
      {selectedDashboard && mode && itemType && (
        <Form
          initialValues={initialValues}
          type={itemType}
          onSubmit={handleSubmit}
        />
      )}
    </Drawer>
  )
}

export default memo(ItemForm)
