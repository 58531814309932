import { memo, useCallback } from 'react'
import {
  Button,
  Drawer,
  Stack,
  Switch,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDashboard } from '@venturi-io/api/src/userManager/dashboard'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'

interface Props {
  opened: boolean
  onCreate?: () => void
  onClose?: () => void
}

const Create = ({ opened, onCreate, onClose }: Props) => {
  const { token } = useUser()
  const newDashboard = useApi(createDashboard)

  const form = useForm({
    initialValues: {
      name: '',
      isPrivate: true,
      layouts: {}
    },
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    void newDashboard
      .fetch(values, token, 'Dashboard successfully created!')
      .finally(() => {
        if (onClose) onClose()

        if (onCreate) onCreate()

        window.location.reload()
      })
  }, [form.values])

  return (
    <Drawer
      title={<Title>Add New Dashboard</Title>}
      opened={opened}
      onClose={() => {
        if (onClose) onClose()

        form.reset()
      }}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          <Switch
            label="Private"
            color="primary"
            size="sm"
            checked={form.values.isPrivate}
            onChange={event => {
              form.setFieldValue('isPrivate', event.currentTarget.checked)
            }}
          />
          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
            disabled={newDashboard.loading}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Drawer>
  )
}

export default memo(Create)
