import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { jsonSchema, paginated, type PaginatedRequest } from '../shared'

const modbusDevice = z.object({
  id: z.number(),
  name: z.string(),
  delay: z.number(),
  swapWords: z.boolean(),
  swapBytes: z.boolean(),
  byteOrder: z.string()
})

export type ModbusDevice = z.infer<typeof modbusDevice>

const modbusDevices = z.array(modbusDevice)

const allDevices = paginated.extend({
  items: modbusDevices
})

export interface CreateModbusDeviceRequest extends Record<string, unknown> {
  name: string
  delay: number
  swapWords: boolean
  swapBytes: boolean
  byteOrder: string
}

export const createModbusDevice = createStatefulApi<CreateModbusDeviceRequest, typeof modbusDevice>(
  'config',
  '/modbus/modbus-device',
  { method: 'POST' },
  modbusDevice
)

const register = z.object({
  modbusRegisterId: z.number(),
  name: z.string(),
  description: z.optional(z.string())
})

const modbusWithRegisters = modbusDevice.extend({
  registers: z.optional(z.array(register))
})

export interface GetModbusDeviceRequest extends Record<string, unknown> {
  deviceId: number
}

export const getModbusDevice = createStatelessApi<GetModbusDeviceRequest, typeof modbusWithRegisters>(
  'config',
  '/modbus/modbus-device/:deviceId',
  { method: 'GET', type: 'path' },
  modbusWithRegisters
)

export interface UpdateModbusDeviceRequest extends Record<string, unknown> {
  deviceId: number
  name: string
  delay: number
  swapWords: boolean
  swapBytes: boolean
  byteOrder: string
}

export const updateModbusDevice = createStatefulApi<UpdateModbusDeviceRequest, typeof modbusDevice>(
  'config',
  '/modbus/modbus-device/:deviceId',
  { method: 'PUT' },
  modbusDevice
)

export interface DeleteModbusDeviceRequest extends Record<string, unknown> {
  deviceId: number
}

export const deleteModbusDevice = createStatefulApi<DeleteModbusDeviceRequest, z.ZodBoolean>(
  'config',
  '/modbus/modbus-device/:deviceId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

interface AllModbusDevicesRequest extends PaginatedRequest {
  search?: string
}
export const getModbusDevices = createStatelessApi<AllModbusDevicesRequest, typeof allDevices>(
  'config',
  '/modbus/modbus-devices',
  { method: 'GET', type: 'path' },
  allDevices
)

export interface CreateModbusSlaveRequest extends Record<string, unknown> {
  agentId: number
  modbusDeviceId: number
  slaveId: number
}

export const createModbusSlave = createStatefulApi<CreateModbusSlaveRequest, typeof jsonSchema>(
  'config',
  '/agent/:agentId/config/create_modbus_slave_from_register',
  { method: 'POST' },
  jsonSchema
)

const registerDetail = z.object({
  modbusRegisterId: z.number().optional(),
  modbusDeviceId: z.number().optional(),
  name: z.string(),
  description: z.string().optional(),
  sensorId: z.number(),
  sensorName: z.string().optional(),
  dataType: z.string(),
  address: z.number(),
  function: z.number(),
  registerCount: z.number(),
  scalingFactor: z.number()
})

export type ModbusRegister = z.infer<typeof registerDetail>

const allRegisters = paginated.extend({
  items: z.array(registerDetail)
})

interface AllRegistersRequest extends PaginatedRequest {
  modbusDeviceId: number
}

export const getAllModbusRegisters = createStatelessApi<AllRegistersRequest, typeof allRegisters>(
  'config',
  '/modbus/modbus-device/:modbusDeviceId/modbus-registers',
  { method: 'GET', type: 'path' },
  allRegisters
)

export interface CreateModbusRegisterRequest extends Record<string, unknown> {
  modbusDeviceId: number
  name: string
  description: string
  sensorId: number
  dataType: string
  address: number
  function: number
  registerCount: number
  scalingFactor: number
}

export const createModbusRegister = createStatefulApi<CreateModbusRegisterRequest, typeof registerDetail>(
  'config',
  '/modbus/modbus-register',
  { method: 'POST' },
  registerDetail
)

export interface UpdateModbusRegisterRequest extends Record<string, unknown> {
  name: string
  description: string
  sensorId: number
  dataType: string
  address: number
  function: number
  registerCount: number
  scalingFactor: number
}

export const updateModbusRegister = createStatefulApi<UpdateModbusRegisterRequest, typeof registerDetail>(
  'config',
  '/modbus/modbus-register/:registerId',
  { method: 'PUT' },
  registerDetail
)

export interface DeleteModbusRegisterRequest extends Record<string, unknown> {
  registerId: number
}

export const deleteModbusRegister = createStatefulApi<DeleteModbusRegisterRequest, z.ZodBoolean>(
  'config',
  '/modbus/modbus-register/:registerId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
