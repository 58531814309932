import { z } from 'zod'
import { createStatelessApi } from '../configure'
import { type PaginatedRequest, paginated } from '../shared'

// Get all sites with alarm info
const siteWithAlarms = z.object({
  siteId: z.number(),
  name: z.string(),
  alarmsCount: z.number(),
  alarmsOkCount: z.number()
})

export type SiteWithAlarms = z.infer<typeof siteWithAlarms>

const sitesWithAlarms = paginated.extend({
  items: z.array(siteWithAlarms)
})

interface GetAllSitesWithAlarmsRequest extends PaginatedRequest {
  orgId: number
}

export const getSitesWithAlarms = createStatelessApi<GetAllSitesWithAlarmsRequest, typeof sitesWithAlarms>(
  'config',
  '/org/:orgId/sites/alarms/summary',
  { method: 'GET', type: 'path' },
  sitesWithAlarms
)
