import { type Agent } from '@venturi-io/api/src/config/agent'
import { Indicator } from '@mantine/core'
import { getAgentStatusColor } from 'src/utils/status'

export default function AgentAlarmStatus ({
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime
}: Pick<Agent, 'alarmStatus' | 'agentStatus' | 'connectionStatus' | 'lastSeenTime'>) {
  const color = getAgentStatusColor(
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  )

  return (
    <Indicator
      ml={6}
      size={15}
      color={color}
      position="middle-center"
      processing={color === 'red'}
    >
      <span />
    </Indicator>
  )
}
