import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Checkbox,
  Flex,
  Group,
  Indicator,
  Paper,
  Stack,
  Text,
  createStyles
} from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import React, { useCallback, useMemo, type CSSProperties } from 'react'
import ClearAlarmButton from 'src/ClearAlarmButton'
import TextTooltip from 'src/Layout/TextTooltip'
import parseIconStyle from 'src/utils/parseIconStyle'
import { checkIfAlarm, getAgentStatusColor } from 'src/utils/status'
import { useViewTypes } from 'src/Maps/GeoZoneMap/AgentAttendanceContext'
import { type AgentUser } from '@venturi-io/api/src/config/agent'

const useStyles = createStyles((theme) => ({
  itemWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    gap: 10,
    '&:hover': {
      background: theme.colors.gray[0]
    },
    overflowX: 'auto'
  }
}))

interface PartialAgent {
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  iconStyle: Agent['iconStyle']
  alarmStatus: Agent['alarmStatus']
}

interface Props {
  data: Agent
  style: CSSProperties
  onClick?: (agentId: Agent['agentId']) => void
  onRefresh?: () => void
}

export default function Row ({
  data,
  onClick,
  onRefresh,
  style
}: Props) {
  const { classes } = useStyles()
  const {
    tab,
    setCheckedAgentIds,
    setCheckedPeopleIds,
    agentCheckReference
  } = useViewTypes()
  const {
    agentId,
    agentName,
    iconStyle,
    lastSeenTime,
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastLocation,
    user
  } = data

  const icon = parseIconStyle(iconStyle ?? '')
  const isAlarm = alarmStatus.toUpperCase() === 'ALARM'

  const isProcessing = useMemo(() => checkIfAlarm(alarmStatus), [alarmStatus])

  const agentStatusColor = useMemo(() => (
    getAgentStatusColor(
      alarmStatus,
      agentStatus,
      connectionStatus,
      lastSeenTime
    )
  ), [alarmStatus, agentStatus, connectionStatus, lastSeenTime])

  const handleToggleAgentVisibility = useCallback((agentId: Agent['agentId'], orgUserId?: AgentUser['orgUserId']) =>
    ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      if (!checked && tab === 'drivers') {
        // we want to uncheck the group if tab === 'drivers' and assuming not every agent in the group is selected
        setCheckedPeopleIds(lastIds => lastIds.filter(id => id !== orgUserId))
      }
      setCheckedAgentIds(lastIds => (
        checked
          ? [...lastIds, agentId]
          : lastIds.filter((id) => id !== agentId)
      ))
    }, [setCheckedAgentIds, setCheckedPeopleIds, tab, user])

  const handleClick = useCallback((agentId: PartialAgent['agentId']) => () => {
    if (onClick) {
      onClick(agentId)
    }
  }, [onClick])

  return (
    <Box style={style}>
      <Indicator
        size={14}
        color={agentStatusColor}
        processing={isProcessing}
        withBorder
      >
        <Paper
          className={classes.itemWrapper}
          shadow="xs"
          p="sm"
          onClick={handleClick(agentId)}
        >
          <Checkbox
            color="primary"
            size="xs"
            checked={agentCheckReference[agentId]}
            onChange={handleToggleAgentVisibility(agentId, user?.orgUserId)}
            onClick={event => {
              event.stopPropagation()
            }}
          />
          <Flex direction="row" sx={{ flex: 1 }}>
            <Group spacing={8} sx={{ flexGrow: 1 }}>
              <FontAwesomeIcon
                icon={icon}
                color={agentStatusColor}
                size="lg"
                fixedWidth
              />
              <Stack spacing={0}>
                <TextTooltip
                  size={11}
                  value={agentName}
                  maxLength={20}
                />
                <Text size={10} color="gray" lh={1}>
                  {`${lastLocation.place ?? ''}${lastLocation.place ? ',' : ''}
                              ${lastLocation.region ?? ''}`}
                </Text>
              </Stack>
            </Group>
            {isAlarm && (
              <ClearAlarmButton
                agentId={agentId}
                agentName={agentName}
                onRefresh={onRefresh}
              />
            )}
          </Flex>
        </Paper>
      </Indicator>
    </Box>
  )
}
