const shortenNumber = (value: number) => {
  if (value < 1e3) {
    return value
  } else if (value >= 1e3 && value < 1e6) {
    return +(value / 1e3).toFixed(1) + 'K'
  } else if (value >= 1e6 && value < 1e9) {
    return +(value / 1e6).toFixed(1) + 'M'
  } else if (value >= 1e9 && value < 1e12) {
    return +(value / 1e9).toFixed(1) + 'B'
  } else if (value >= 1e12) {
    return +(value / 1e12).toFixed(1) + 'T'
  }
}

export const formatNumber = (value: number) => {
  let format

  if (value < 0) {
    format = '-' + shortenNumber(-1 * value)
  } else {
    format = shortenNumber(value)
  }

  return format
}
