import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { Stack, Title } from '@mantine/core'
import Paper from 'src/Layout/Paper'
import CoreMap from 'src/Maps/CoreMap'

interface Props {
  agentLocation: AgentDetails['geoLocation']
}

const Location = ({ agentLocation }: Props) => {
  return (
    <Paper relative>
      <Stack spacing="sm">
        <Title order={4}>Location</Title>
        <CoreMap
          {...agentLocation}
          height="500px"
          width="100%"
          zoom={16}
          showLocationMarker
          canZoom
          canPan
        />
      </Stack>
    </Paper>
  )
}

export default Location
