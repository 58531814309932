/* eslint-disable max-len */
import { PDFViewer } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import { isEmpty } from 'src/utils/objects'
import { useDisclosure } from '@mantine/hooks'
import { Modal } from '@mantine/core'
import { type Range } from '@venturi-io/api'
import { useCallback, type ReactNode } from 'react'
import { type ParsedTripItem } from '../TripReportView'
import { type ParsedSpeedEvent } from '../SpeedingReportView'
import { type ParsedDuressEvent } from '../DuressReportView'
import { type ParsedIdleEvent } from '../IdleReportView'
import { type ParsedGeozoneEvent } from '../GeozoneReportView'
import TripHistory from './TripHistory'
import ConditionSummary from './ConditionSummary'
import SpeedingEvents from './SpeedingEvents'
import DuressEvents from './DuressEvents'
import IdleTimeEvents from './IdleTimeEvents'
import GeozoneEvents from './GeozoneEvents'

type Parsed = ParsedTripItem[] | ParsedSpeedEvent[] | ParsedDuressEvent[] | ParsedIdleEvent[]
export interface Props {
  report: Report
  rawData: Record<string, Array<Record<string, string | null>>>
  data?: Parsed
  range: Range | null
  queryId: number
  button: (toggle: () => void, disabled: boolean) => ReactNode
}

export default function ExportToPdf (props: Props) {
  const {
    button,
    report,
    rawData,
    data,
    range,
    queryId
  } = props
  const [showPdf, { toggle }] = useDisclosure(false)
  const rows = data ?? []
  const records = rawData[`${queryId}`]

  const renderPDFChild = useCallback(() => {
    const { reportType: { name } } = report
    switch (name) {
      case 'Trip History':
        return (
          <TripHistory
            range={range}
            report={report}
            data={rows as ParsedTripItem[]}
          />
        )
      case 'Speed Event Report':
        return (
          <SpeedingEvents
            range={range}
            report={report}
            data={rows as ParsedSpeedEvent[]}
          />
        )
      case 'Duress Event Report':
        return (
          <DuressEvents
            range={range}
            report={report}
            data={rows as ParsedDuressEvent[]}
          />
        )
      case 'Idle Time Report':
        return (
          <IdleTimeEvents
            range={range}
            report={report}
            data={rows as ParsedIdleEvent[]}
          />
        )
      case 'Geozone Event Report':
        return (
          <GeozoneEvents
            range={range}
            report={report}
            data={rows as ParsedGeozoneEvent[]}
          />
        )
      default:
        return (
          <ConditionSummary
            report={report}
            data={records}
          />
        )
    }
  }, [report])

  return (
    <>
      <Modal
        title="Exporting to pdf..."
        size="auto"
        opened={showPdf}
        onClose={toggle}
      >
        <PDFViewer width="1000" height="650">
          {renderPDFChild()}
        </PDFViewer>
      </Modal>
      {button(toggle, isEmpty(rawData))}
    </>
  )
}
