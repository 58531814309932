import { useCallback, useEffect, useState } from 'react'
import {
  type AutocompleteItem,
  Grid,
  Col,
  Paper,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import SelectAgent from 'src/Input/Select/SelectAgent'
import Select from 'src/Input/Select'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import DateTimeRangePicker from 'src/Input/DateTimeRangePicker'
import { type DateTimePickerProps, type DatesRangeValue } from '@mantine/dates'
import { type Range } from '@venturi-io/api'

const ClearIcon = () => <FontAwesomeIcon icon={['fas', 'close']} size="sm" />

interface Props {
  agentId: string | null
  sensorId: string | null
  setSelectedAgentId: (value: string | null) => void
  setSelectedSensorInstanceId: (value: string | null) => void
  setDateRange: (value: Range | null) => void
  dateRange: Range | null
}

const inputWrapperOrder: Array<'label' | 'error' | 'input' | 'description'> = ['label', 'error', 'input', 'description']
const sharedProps: Partial<DateTimePickerProps> = {
  inputWrapperOrder,
  descriptionProps: {
    style: {
      fontStyle: 'italic'
    }
  },
  maxDate: dayjs().toDate()
}

const format = `${dateFormat}`

export default function SearchBar ({
  agentId,
  sensorId,
  setSelectedAgentId,
  setSelectedSensorInstanceId,
  setDateRange,
  dateRange
}: Props) {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const [searchAgent, setSearchAgent] = useState<string | null>(null)
  const [searchSensorInstance, setSearchSensorInstance] = useState<string | null>(null)

  const { from, to } = dateRange
    ? {
        from: dayjs(dateRange.from, dateFormat),
        to: dayjs(dateRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const range: DatesRangeValue = [
    from?.toDate() ?? null,
    to?.toDate() ?? null
  ]

  const isValidRange = from && to
    ? from.isBefore(to)
    : true

  const isMoreThanAWeek = to?.isBefore(from.add(7, 'days').add(1, 'minute'))

  const errorMes = () => {
    if (!isValidRange) {
      return (
        <Text
          italic
          size="xs"
          color="red"
        >
          Please provide a valid date range
        </Text>
      )
    } else if (!isMoreThanAWeek) {
      return (
        <Text
          italic
          size="xs"
          color="red"
        >
          Date range must be maximum of 1 week only
        </Text>
      )
    }
  }

  const selectSensorInstances: AutocompleteItem[] = agentDetails.data.mapOrDefault(
    ({ sensors }) => sensors.map(({ sensorInstanceId, name }) => ({
      value: sensorInstanceId.toString(),
      label: name
    }))
    , [])

  const onSelectAgent = useCallback((agentId: string | null) => {
    setSearchAgent(agentId)
    setSelectedAgentId(agentId)
    setSearchSensorInstance(null)
    setSelectedSensorInstanceId(null)
  }, [])

  const onSelectSensorInstance = useCallback((sensorId: string | null) => {
    setSearchSensorInstance(sensorId)
    setSelectedSensorInstanceId(sensorId)
  }, [])

  const onClearAgent = useCallback(() => {
    setSearchAgent('')
    setSelectedAgentId(null)
    setSearchSensorInstance('')
    setSelectedSensorInstanceId(null)
    setDateRange({
      from: dayjs()
        .subtract(1, 'days')
        .format(`${dateFormat}Z`),
      to: dayjs().format(`${dateFormat}Z`)
    })
  }, [])

  const onClearSensorInstance = useCallback(() => {
    setSearchSensorInstance('')
    setSelectedSensorInstanceId(null)
  }, [])

  useEffect(() => {
    if (!searchAgent) return

    void agentDetails.fetch({ agentId: Number(searchAgent) }, token)
  }, [searchAgent])

  useEffect(() => {
    if (agentId) {
      setSearchAgent(agentId)
    }
  }, [agentId])

  useEffect(() => {
    if (sensorId) {
      setSearchSensorInstance(sensorId)
    }
  }, [sensorId])

  return (
    <Paper p="md" shadow="xs" mb="md">
      <Grid align="flex-end">
        <Col span={12} sm={4}>
          <SelectAgent
            placeholder="Choose an agent"
            value={searchAgent}
            onChange={onSelectAgent}
            rightSection={<ClearIcon />}
            rightSectionProps={{
              onClick: onClearAgent
            }}
            searchable
          />
        </Col>
        <Col span={12} sm={4}>
          <Select
            searchable
            placeholder="Choose a sensor"
            value={searchSensorInstance}
            onChange={onSelectSensorInstance}
            data={selectSensorInstances}
            rightSection={<ClearIcon />}
            rightSectionProps={{ onClick: onClearSensorInstance }}
            disabled={!searchAgent || agentDetails.loading}
          />
        </Col>
        <Col span={12} sm={4}>
          <DateTimeRangePicker
            fromProps={{
              ...sharedProps,
              label: 'From date/time',
              error: !isValidRange || !isMoreThanAWeek,
              defaultValue: range[0],
              onChange: date => {
                setDateRange({
                  from: dayjs(date)
                    .set('seconds', 0)
                    .format(format),
                  to: dayjs(range[1])
                    .set('seconds', 0)
                    .format(format)
                })
              }
            }}
            toProps={{
              ...sharedProps,
              label: 'To date/time',
              error: !isValidRange || !isMoreThanAWeek,
              defaultValue: range[1],
              onChange: date => {
                setDateRange({
                  from: dayjs(range[0])
                    .set('seconds', 0)
                    .format(format),
                  to: dayjs(date)
                    .set('seconds', 0)
                    .format(format)
                })
              }
            }}
            error={errorMes()}
          />
        </Col>
      </Grid>
    </Paper>
  )
}
