import { useNavigate } from 'react-router-dom'
import {
  Code,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/sensor'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { parseEmpty } from 'src/utils/parseEmpty'
import { isEmpty } from 'src/utils/objects'

export default function Card ({
  id,
  name,
  type,
  sensorTypeName,
  unit,
  valueMap
}: Sensor & ListRow) {
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const ValueMapList = () => (
    <Stack spacing="xs" align="flex-start" justify="center">
      {Object.entries(valueMap).map(([input, output]) => (
        <Code key={input}>
          {`${input.toString()} = ${output.toString()}`}
        </Code>
      ))}
    </Stack>
  )

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/my-admin/sensors/edit/${id}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Name"
            value={name}
            maxLength={textMaxLength}
          />
          <TextGroup
            label="Type"
            value={parseEmpty(type)}
          />
          <TextGroup
            label="Sensor Type"
            value={parseEmpty(sensorTypeName ?? '-')}
          />
          <TextGroup
            label="Unit"
            value={parseEmpty(unit ?? '-')}
          />
          <Stack spacing={4}>
            <TextGroup
              label="Value Map"
              value=""
            />
            {isEmpty(valueMap)
              ? <Text size="sm">-</Text>
              : <ValueMapList />}
          </Stack>
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
