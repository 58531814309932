import {
  Container,
  Text,
  SimpleGrid,
  useMantineTheme,
  ScrollArea
} from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import RadialGauge from 'src/Charts/RadialGauge'
import { mq } from 'src/utils/style'
import {
  convertStrToFloat,
  useSharedStyles,
  findSensorByType,
  radialOuterData,
  type Sensors
} from '../shared'
import { MODULE_CONTENT } from './constants'
interface Props extends Sensors {
  agentName: string
}

const wattageValue = (value?: string) => {
  return value
    ? parseFloat((convertStrToFloat(value) * 0.0001).toFixed(2))
    : 0
}

export default function PowerCurrentOutputs ({
  sensors
}: Props) {
  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const load = findSensorByType('POSITIVE_ACTIVE_THREE_PHASE', sensors)
  const currentNegative = findSensorByType('NEGATIVE_ACTIVE_THREE_PHASE', sensors)
  const currentPositive = findSensorByType('POSITIVE_ACTIVE_THREE_PHASE', sensors)
  const powerFactor = findSensorByType('POWER_FACTOR_THREE_PHASE', sensors)

  const { breakpoints: { sm } } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(sm, false))

  const loadInnerData = {
    id: 1,
    value: load
      ? convertStrToFloat(load.currentValue)
      : 0,
    color: '#E5AF59'
  }

  const negativeInnerData = {
    id: 1,
    value: currentNegative
      ? Math.abs(wattageValue(currentNegative.currentValue))
      : 0,
    color: '#E5AF59'
  }

  const positiveInnerData = {
    id: 2,
    value: currentPositive
      ? wattageValue(currentPositive.currentValue)
      : 0,
    color: '#E5AF59'
  }

  const powerInnerData = {
    id: 3,
    value: powerFactor
      ? powerFactor.currentValue
        ? parseFloat(powerFactor.currentValue)
        : 0
      : 0,
    color: '#E5AF59'
  }

  const sizes = {
    width: isDesktop ? width / 4 : width
  }

  return (
    <Container ref={ref} className={classes.dock} fluid>
      <Text size="sm" weight={500}>
        {MODULE_CONTENT.POWER_CURRENT.toUpperCase()}
      </Text>
      <ScrollArea type="scroll" sx={{ height: height - 40 }} viewportProps={{ style: { overflowX: 'hidden' } }}>
        <SimpleGrid cols={isDesktop ? 4 : 1}>
          <RadialGauge
            title="Kilo Watts"
            subtitle="Negative"
            value={negativeInnerData.value}
            innerData={negativeInnerData}
            outerData={radialOuterData}
            {...sizes}
          />
          <RadialGauge
            title="Kilo Watts"
            subtitle="Positive"
            value={positiveInnerData.value}
            innerData={positiveInnerData}
            outerData={radialOuterData}
            {...sizes}
          />
          <RadialGauge
            title={'Hertz'}
            value={loadInnerData.value}
            innerData={loadInnerData}
            outerData={radialOuterData}
            {...sizes}
          />
          <RadialGauge
            title={'Power factor'}
            value={powerInnerData.value}
            maxValue={1}
            innerData={powerInnerData}
            outerData={radialOuterData}
            {...sizes}
          />
        </SimpleGrid>
      </ScrollArea>
    </Container>
  )
}
