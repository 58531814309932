import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import TextTooltip from 'src/Layout/TextTooltip'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import Link from 'src/Layout/Link'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const upsStatus = findSensorByType('UPS_STATUS', sensors)
  const runtime = findSensorByType('UPS_RUNTIME', sensors)
  const batteryLevel = findSensorByType('BATTERY', sensors)
  const main = findSensorByType('MAINS_VOLTAGE', sensors)
  const load = findSensorByType('LOAD_KVA', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/ups/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(upsStatus?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(runtime?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {batteryLevel?.currentValue
            ? `${batteryLevel.currentValue} ${batteryLevel.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {main?.currentValue
            ? `${main.currentValue} ${main.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {load?.currentValue
            ? `${load?.currentValue} ${load.unit}`
            : '-'}
        </Text>
      </div>
    </div>
  )
}
