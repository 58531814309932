import { Loader, Table, Text, Group } from '@mantine/core'
import { dateFormat, uiDateOnly } from 'src/utils/dates'
import dayjs from 'dayjs'
import { type RefObject } from 'react'
import { type TripProps } from '../..'
import { type FocusManagerRef } from '../../FocusManager'
import TripsCell from './TripsCell'

interface Props {
  focusRef: RefObject<FocusManagerRef>
  loading: boolean
  trips: TripProps[]
}

const compareDates = (first: string, second: string) => {
  return dayjs(first, dateFormat).format(uiDateOnly) === dayjs(second, dateFormat).format(uiDateOnly)
}

export default function TripsTable ({
  focusRef,
  loading,
  trips
}: Props) {
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan={2}>
            <Group spacing={4} align="center">
              Trips
              {loading && <Loader color="primary" size={18} />}
            </Group>
          </th>
        </tr>
      </thead>
      <tbody>
        {trips.length > 0
          ? trips.map((trip, position) => {
            const lastTrip = position > 0
              ? trips[position - 1]
              : trip
            const isSameDay = position > 0
              ? compareDates(lastTrip.startTime, trip.startTime)
              : false
            return (
              <tr key={`trip-${position}`}>
                <TripsCell
                  focusRef={focusRef}
                  trip={trip}
                  position={position}
                  showDate={!isSameDay}
                />
              </tr>
            )
          })
          : (
            <tr>
              <td colSpan={2}>
                <Text>No trips found</Text>
              </td>
            </tr>
            )}
      </tbody>
    </Table>
  )
}
