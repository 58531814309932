import { Paper, SimpleGrid, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type TankTransaction } from '@venturi-io/api/src/config/fms'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'

export default function Card ({ ...transactions }: TankTransaction & ListRow) {
  const {
    imported,
    rfidTag,
    person,
    volume,
    type,
    transactionId,
    agent
  } = transactions

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Date/Time"
            value={dayjs(imported).format(uiDateFormat).toString()}
            maxLength={25}
          />
          <TextGroup
            label="RFID Tag"
            value={parseEmpty(rfidTag)}
            maxLength={25}
          />
          <TextGroup
            label="Agent ID"
            value={parseEmpty(agent?.agentId)}
            maxLength={25}
          />
          <TextGroup
            label="Agent Name"
            value={parseEmpty(agent?.agentName)}
            maxLength={25}
          />
          <TextGroup
            label="Asset Type"
            value={parseEmpty(agent?.assetType)}
            maxLength={25}
          />
          <TextGroup
            label="User"
            value={parseEmpty(person)}
            maxLength={25}
          />
          <TextGroup
            label="Volume(L)"
            value={parseEmpty(volume)}
            maxLength={25}
          />
          <TextGroup
            label="In/Out"
            value={parseEmpty(type)}
            maxLength={25}
          />
          <TextGroup
            label="Trans ID"
            value={parseEmpty(transactionId)}
            maxLength={25}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
