import {
  type CSSProperties,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Container, Title } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { getGeoZonesAndAgents } from '@venturi-io/api/src/config/geoZone'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import Donut, { type Props as DonutProps } from 'src/Charts/Donut'
import { round } from 'src/utils/math'
import { toProperCase } from 'src/utils/strings'
import { useSharedStyles } from '../shared'

interface ConnectionSummary {
  online: number
  offline: number
  disconnected: number
}

type ConnectionType = keyof ConnectionSummary

export interface Props {
  id: string
  name: string
}

const colors: Record<ConnectionType, CSSProperties['color']> = {
  online: '#4DB252',
  offline: '#E3E4E9',
  disconnected: '#FF9494'
}

const ConnectionState = ({ name }: Props) => {
  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()
  const { token, orgId } = useUser()
  const agentsAndGeoZones = useApi(getGeoZonesAndAgents)
  const [connectionSummary, setConnectionSummary] = useState<ConnectionSummary>({
    online: 0,
    offline: 0,
    disconnected: 0
  })
  const [totalCount, setTotalCount] = useState(0)

  const data: DonutProps['data'] = useMemo(() => (Object
    .keys(connectionSummary)
    .map((key, index) => {
      const value = connectionSummary[key as ConnectionType]

      return {
        id: index,
        label: toProperCase(key),
        value,
        valuePercent: value !== 0
          ? round((value / totalCount) * 100)
          : value,
        color: colors[key as ConnectionType]
      }
    })
  ), [connectionSummary, colors])

  useEffect(() => {
    agentsAndGeoZones.data.ifJust(({ agents }) => {
      const online = agents.filter(({ connectionStatus }) => connectionStatus === 'ONLINE')
      const offline = agents.filter(({ connectionStatus }) => connectionStatus === 'OFFLINE')
      const disconnected = agents.filter(({ connectionStatus }) => typeof connectionStatus === 'undefined')
      const summary: ConnectionSummary = {
        online: online.length,
        offline: offline.length,
        disconnected: disconnected.length
      }
      const total = Object.keys(summary).reduce((total, key) => (
        total + summary[key as keyof ConnectionSummary]
      ), 0)

      setConnectionSummary(summary)
      setTotalCount(total)
    })
  }, [agentsAndGeoZones.data])

  useEffect(() => {
    void agentsAndGeoZones.fetch({ orgId }, token)
  }, [orgId])

  return (
    <Container
      ref={ref}
      className={classes.itemContainer}
      sx={{
        background: 'white',
        overflow: 'clip'
      }}
      fluid
    >
      {!agentsAndGeoZones.loading && (
        <Title
          ml="md"
          mt="sm"
          order={3}
          sx={{
            alignSelf: 'start',
            marginTop: '2.5em !important'
          }}
        >
          {name}
        </Title>
      )}
      {agentsAndGeoZones.loading && <Loader variant="bars" />}
      {!agentsAndGeoZones.loading && (
        <Donut
          width={width}
          height={height}
          value={totalCount}
          data={data}
          withLegend
        />
      )}
    </Container>
  )
}

export default memo(ConnectionState)
