import {
  Box,
  Container,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import StackedAreas, { defaultStyles } from 'src/Charts/StackedAreas'
import { useContext, useMemo } from 'react'
import { type SensorData } from '@venturi-io/api/src/collector/sensor'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import TimePeriodSelector from 'src/Input/TimePeriodSelector'
import { useSharedStyles } from '../shared'
import { TimeBucketContext } from './data'
interface Props {
  label: string
  sensors: Array<Sensor | null>
}

type Data = Record<string, string>
interface SensorDataWithKey extends SensorData {
  key: string
}

export default function PowerStackedAreaCharts ({ label, sensors }: Props) {
  const { sensorData, loading, bucket, updateBucket } = useContext(TimeBucketContext)
  const { classes } = useSharedStyles()
  const { ref, width } = useElementSize()

  const sensorsData: SensorDataWithKey[] = useMemo(() => (
    sensorData
      .reduce((
        sensorsData_: SensorDataWithKey[],
        { sensorInstanceId, data }
      ) => {
        const sensor = sensors.find(s => s ? s.sensorInstanceId === sensorInstanceId : false)
        if (sensor) {
          sensorsData_ = [
            ...sensorsData_,
            {
              sensorInstanceId,
              key: sensor.name,
              data: data.map(({ x, y }) => (
                {
                  x,
                  y: y ?? 0
                }
              ))
            }
          ]
        }

        return sensorsData_
      }, [])
      .filter(sensor => sensor.data.length > 0)
  ), [sensorData])

  const series = sensorsData.reduce((series: Record<string, Data>, sensor) => {
    sensor.data.forEach(({ x, y }) => {
      const key = x.toString()
      const val = typeof y !== 'undefined' ? y.toString() : '0'

      if (series[key]) {
        series[key] = {
          ...series[key],
          [sensor.key]: val
        }
      } else {
        series[key] = {
          [sensor.key]: val
        }
      }
    })

    return series
  }, {})

  const sensorsHistory: Data[] = Object.keys(series).map(date => ({ date, ...series[date] }))

  return (
    <Container ref={ref} className={classes.dock} fluid>
      <Box>
        <Stack spacing={0}>
          <Group position="apart">
            <Text size="sm" weight={500}>{label}</Text>
            <TimePeriodSelector chosenBucket={bucket} updateBucket={updateBucket} />
          </Group>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <StackedAreas
              id="test"
              title={label}
              data={sensorsHistory}
              styles={{
                ...defaultStyles,
                withoutTitle: true,
                hideRecentStackedValue: true,
                margin: {
                  top: 20,
                  bottom: 20,
                  left: 65,
                  right: 45
                },
                width,
                height: 300
              }}
            />
          )}
        </Stack>
      </Box>
    </Container>
  )
}
