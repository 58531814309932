import { useCallback } from 'react'
import { type ReactourStep } from 'reactour'
import Tour, { type Props } from '../Tour'
import InformationBox from '../InformationBox'

const steps: ReactourStep[] = [
  {
    selector: '#tour-map__menu',
    content: () => (
      <InformationBox
        title="Use the Map to view all of your assets in one place"
        message="The Map allows users to view all assets live,
          providing their status, location and many other useful data points."
      />
    )
  },
  {
    selector: '#tour-map__asset-list',
    content: () => (
      <InformationBox
        title="Asset list display"
        message="The asset list allow users to scroll through all of their assets,
          with useful filtering tools to make finding the right asset, person or group, easier."
      />
    )
  },
  {
    selector: '#tour-map__search-bar',
    content: () => (
      <InformationBox
        title="Multi-functional Search Bar"
        message="Users can search within the map view for Assets, Geozones,
          Places and Users, to help pinpoint key assets in a heartbeat."
      />
    )
  },
  {
    selector: '#tour-map__focused-asset',
    content: () => (
      <InformationBox
        title="Click an Asset for more details"
        message="Users can select specific asset from the Map to view necessary information about it."
      />
    )
  }
]

export default function MapTour ({ isOpen, setIsOpen, setIsDone }: Omit<Props, 'steps' | 'onClickLastStepNextButton'>) {
  const handleClickNextTour = useCallback(() => {
    if (setIsDone) {
      setIsDone(true)
    }
  }, [])

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClickLastStepNextButton={handleClickNextTour}
    />
  )
}
