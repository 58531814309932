import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  type Dashboard,
  type DashboardItem,
  getDashboard,
  getUserDashboards,
  updateDashboard
} from '@venturi-io/api/src/userManager/dashboard'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type Layouts, type Layout } from 'react-grid-layout'
import { Box } from '@mantine/core'
import { getCurrentBreakpoint } from 'src/utils/style'
import { type FormMode as ItemFormMode } from 'src/utils'
import Header from './Header'
import Grid from './Grid'
import CreateDashboard from './Create'
import ItemForm from './ItemForm'
import AddWidgetInfo from './AddWidgetInfo'
import { type Item, type ItemType } from './Items'

export default () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const { token } = useUser()
  const findDashboard = useApi(getDashboard)
  const findUserDashboards = useApi(getUserDashboards)
  const editDashboardLayout = useApi(updateDashboard)
  const [currentLayout, setCurrentLayout] = useState<Layout[]>([])
  const [currentBreakpoint, setCurrentBreakpoint] = useState('xxs')
  const [selectedDashboardId, setSelectedDashboardId] = useState<Dashboard['dashboardId'] | null>(null)
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null)
  const [itemFormMode, setItemFormMode] = useState<ItemFormMode | null>(null)
  const [selectedDashboardItem, setSelectedDashboardItem] = useState<DashboardItem | null>(null)
  const [selectedDashboardItemType, setItemType] = useState<ItemType | null>(null)
  const [showCreateDashboard, setShowCreateDashboard] = useState(false)
  const [showItemForm, setShowItemForm] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [cachedLayouts, setCachedLayouts] = useState<Layouts | null>(null)
  const initialBreakpoint = getCurrentBreakpoint()

  const handleResetLayout = useCallback(() => {
    if (selectedDashboard) {
      const { dashboardId, name, description, isPrivate, layouts } = selectedDashboard
      const updatedLayouts = {
        ...layouts,
        [currentBreakpoint]: []
      }

      setCachedLayouts(layouts as Layouts)

      setSelectedDashboard({
        ...selectedDashboard,
        layouts: updatedLayouts
      })

      void editDashboardLayout.fetch({
        dashboardId,
        name,
        description,
        isPrivate,
        layouts: updatedLayouts
      }, token, 'Dashboard layout successfully reset!')
    }
  }, [selectedDashboard, currentBreakpoint])

  const handleSaveLayout = useCallback(() => {
    if (selectedDashboard) {
      const { dashboardId, name, description, isPrivate, layouts } = selectedDashboard
      const updatedLayouts = {
        ...layouts,
        [currentBreakpoint]: currentLayout
      }

      setCachedLayouts(layouts as Layouts)

      setSelectedDashboard({
        ...selectedDashboard,
        layouts: updatedLayouts
      })

      void editDashboardLayout
        .fetch({
          dashboardId,
          name,
          description,
          isPrivate,
          layouts: updatedLayouts
        }, token, 'Dashboard layout successfully saved!')
        .finally(() => {
          setEditMode(false)
        })
    }
  }, [selectedDashboard, currentBreakpoint, currentLayout])

  useEffect(() => {
    if (selectedDashboard && cachedLayouts) {
      editDashboardLayout.data.ifJust(() => {
        setCachedLayouts(null)
      })
    }
  }, [editDashboardLayout.data])

  useEffect(() => {
    if (selectedDashboard && cachedLayouts) {
      editDashboardLayout.error.ifJust(() => {
        setSelectedDashboard({
          ...selectedDashboard,
          layouts: cachedLayouts
        })

        setCachedLayouts(null)
      })
    }
  }, [editDashboardLayout.error])

  useEffect(() => {
    setCurrentBreakpoint(initialBreakpoint)
  }, [initialBreakpoint])

  const resetItemForm = useCallback(() => {
    setItemFormMode(null)
    setItemType(null)
    setSelectedDashboardItem(null)
    setShowItemForm(false)
  }, [])

  useEffect(() => {
    findDashboard.data.ifJust(dashboard => {
      setSelectedDashboard(dashboard)
    })
  }, [findDashboard.data])

  useEffect(() => {
    if (selectedDashboardId) {
      if (!editMode) {
        findDashboard.startPolling({ dashboardId: selectedDashboardId }, token, 30)
      } else {
        findDashboard.stopPolling()
      }
    }

    return () => {
      findDashboard.stopPolling()
      findDashboard.abort()
    }
  }, [selectedDashboardId, editMode])

  useEffect(() => {
    if (selectedDashboardId) {
      void findDashboard.fetch({ dashboardId: selectedDashboardId }, token)
    }
  }, [selectedDashboardId])

  useEffect(() => {
    const params = new URLSearchParams(search)
    const dashboardId = params.get('dashboardId')

    if (dashboardId === null) {
      findUserDashboards.data.ifJust(userDashboards => {
        const defaultDashboard = userDashboards.find(({ isDefault }) => isDefault)

        if (defaultDashboard) {
          setSelectedDashboardId(defaultDashboard.dashboardId)
        }
      })
    }
  }, [findUserDashboards.data])

  useEffect(() => {
    const params = new URLSearchParams()

    if (selectedDashboardId !== null) {
      params.append('dashboardId', selectedDashboardId.toString())
    }

    navigate(`?${params.toString()}`, { replace: true })
  }, [selectedDashboardId])

  useEffect(() => {
    if (search.length > 0) {
      const params = new URLSearchParams(search)
      const dashboardId = params.get('dashboardId')

      if (dashboardId !== null) {
        setSelectedDashboardId(Number(dashboardId))
      }
    }
  }, [search])

  useEffect(() => {
    void findUserDashboards.fetch({}, token)
  }, [])

  return (
    <>
      <Box mt={0}>
        <Header
          selectedDashboard={selectedDashboard}
          editMode={editMode}
          onAddItem={type => {
            setItemFormMode('CREATE')
            setSelectedDashboardItem(null)
            setItemType(type)
            setShowItemForm(true)
          }}
          onSaveLayout={handleSaveLayout}
          onResetLayout={handleResetLayout}
          onDeleteDashboard={resetItemForm}
          setShowCreateDashboard={setShowCreateDashboard}
          setSelectedDashboardId={setSelectedDashboardId}
          setSelectedDashboard={setSelectedDashboard}
          setEditMode={setEditMode}
        />
        {selectedDashboard?.dashboardItems && selectedDashboard.dashboardItems?.length === 0 && (
          <AddWidgetInfo />
        )}
        <Grid
          selectedDashboard={selectedDashboard}
          currentBreakpoint={currentBreakpoint}
          setCurrentLayout={setCurrentLayout}
          setCurrentBreakpoint={setCurrentBreakpoint}
          setSelectedDashboard={setSelectedDashboard}
          setSelectedDashboardItem={item => {
            setItemFormMode('EDIT')
            setSelectedDashboardItem(item)
            setItemType(item.type as ItemType)
            setShowItemForm(true)
          }}
          isEditable={editMode}
          onDelete={resetItemForm}
        />
      </Box>
      <CreateDashboard opened={showCreateDashboard} onClose={() => setShowCreateDashboard(false)} />
      <ItemForm
        mode={itemFormMode}
        opened={showItemForm}
        initialValues={selectedDashboardItem?.properties as unknown as Item ?? null}
        itemType={selectedDashboardItemType}
        selectedDashboard={selectedDashboard}
        selectedDashboardItem={selectedDashboardItem}
        setSelectedDashboard={setSelectedDashboard}
        onSubmit={resetItemForm}
        onDelete={resetItemForm}
        onClose={resetItemForm}
      />
    </>
  )
}
