import { useNavigate } from 'react-router-dom'
import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type ModbusDevice } from '@venturi-io/api/src/config/modbus'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'

export default function Card ({
  id,
  name,
  delay,
  swapWords,
  swapBytes,
  byteOrder
}: ModbusDevice & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <Group spacing="sm">
            <ActionButton
              label="Edit"
              icon="pencil"
              iconType="fas"
              onClick={() => navigate(`/my-admin/modbus-devices/edit/${id}`)}
              actionColor="green"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
            <ActionButton
              label="Edit Registers"
              icon="memo-pad"
              iconType="fas"
              onClick={() => navigate(`/my-admin/modbus-devices/edit-registers/${id}`)}
              actionColor="primary"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
          </Group>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup label="Name" value={name} maxLength={25} />
          <TextGroup label="Delay" value={delay.toString()} />
          <TextGroup label="Swap Words" value={swapWords ? 'Yes' : 'No'} />
          <TextGroup label="Swap Bytes" value={swapBytes ? 'Yes' : 'No'} />
          <TextGroup label="Byte Order" value={byteOrder} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
