import { Stack, Text } from '@mantine/core'

interface Props {
  title: string
  item?: number | string
  unit?: string
}

export default function SummaryCol ({ title, item, unit }: Props) {
  const item_ = item
    ? unit
      ? `${item} ${unit}`
      : item
    : 'No Data'

  return (
    <Stack p="xs" spacing={5}>
      <Text size="xl" weight={600}>
        {title}
      </Text>
      <Text size="lg">
        {item_}
      </Text>
    </Stack>
  )
}
