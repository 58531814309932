import {
  createStyles,
  Box,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistorySparkLineChart from 'src/AssetTemplate/Components/Charts/SensorHistorySparkLineChart'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { formatNumber } from 'src/utils/numbers'
import { mq } from 'src/utils/style'

const useStyles = createStyles((theme) => ({
  container: {
    margin: '0 auto',
    color: '#212121',
    padding: '24px 12px',
    [mq(theme.breakpoints.md)]: {
      padding: '32px 8px'
    }
  },
  itemContainer: {
    position: 'relative'
  },
  chartContainer: {
    minWidth: 0,
    width: 64
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: 8
  }
}))

interface Props {
  fuelLevel: Sensor | null
  fuelLevelLiter: number
  fuelConsumption: Sensor | null
  fuelConsumptionLiter: number
}

export default function FuelDetails ({
  fuelLevel,
  fuelLevelLiter,
  fuelConsumption,
  fuelConsumptionLiter
}: Props) {
  const { classes } = useStyles()
  const fuelLevelHasAlarm = fuelLevel?.alarmStatus === 'ALARM'
  const fuelConsumptionHasAlarm = fuelConsumption?.alarmStatus === 'ALARM'
  const currentLoad = Math.floor(fuelConsumptionLiter > 1
    ? fuelLevelLiter / fuelConsumptionLiter
    : 0)

  return (
    <Box className={classes.container}>
      <Stack align="center" justify="stretch" spacing={16}>
        <Group
          position="apart"
          align="stretch"
          sx={{ width: '80%' }}
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Fuel Remaining
            </Text>
            <Group>
              <Text size={24} weight={600}>
                {`${formatNumber(fuelLevelLiter)} L`}
              </Text>
              <Box className={classes.chartContainer}>
                {fuelLevel?.sensorInstanceId && fuelLevel?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={fuelLevel.sensorInstanceId}
                    height={50}
                  />
                )}
              </Box>
            </Group>
            {fuelLevelHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              at Current Load
            </Text>
            <Text size={24} weight={600}>
              {`${currentLoad} kWh`}
            </Text>
          </Stack>
        </Group>
        <Group
          position="apart"
          align="stretch"
          sx={{ width: '80%' }}
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Fuel Consumption
            </Text>
            <Group>
              <Text size={24} weight={600}>
                {`${formatNumber(fuelConsumptionLiter)} L/h`}
              </Text>
              <Box className={classes.chartContainer}>
                {fuelConsumption?.sensorInstanceId && fuelConsumption?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={fuelConsumption.sensorInstanceId}
                    height={50}
                  />
                )}
              </Box>
            </Group>
            {fuelConsumptionHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text
              color="#A6A7AB"
              size={12}
              weight={500}
            >
              at Max Load
            </Text>
            {/* TODO: Map proper value for max load once data is available */}
            <Text
              color="#A6A7AB"
              size={24}
              weight={600}
            >
              n/a
            </Text>
          </Stack>
        </Group>
      </Stack>
    </Box>
  )
}
