import { type IconName } from '@fortawesome/fontawesome-svg-core'
import { type ItemType } from '../../Items'

interface MenuDashboardItem {
  name: ItemType
  icon: IconName
}

export const menuDashboardItems: MenuDashboardItem[] = [
  {
    name: 'Agent Quick View',
    icon: 'binoculars'
  },
  {
    name: 'Agent Status',
    icon: 'temperature-quarter'
  },
  {
    name: 'Alarm List',
    icon: 'siren-on'
  },
  {
    name: 'Assets in Geozones',
    icon: 'vector-polygon'
  },
  {
    name: 'Connection State',
    icon: 'signal-stream'
  },
  {
    name: 'Duress Alarms',
    icon: 'warning'
  },
  {
    name: 'Engine Hours',
    icon: 'engine'
  },
  {
    name: 'IFrame',
    icon: 'films'
  },
  {
    name: 'Last Seen',
    icon: 'list'
  },
  {
    name: 'Map',
    icon: 'map-location-dot'
  },
  {
    name: 'Motion State',
    icon: 'car-on'
  },
  {
    name: 'Multi-Agent Sensor History',
    icon: 'chart-network'
  },
  {
    name: 'Multi-Agent Sensor History (Table)',
    icon: 'table'
  },
  {
    name: 'Multi-Sensor History',
    icon: 'chart-area'
  },
  {
    name: 'Odometer',
    icon: 'dashboard'
  },
  {
    name: 'Sensor Gauge',
    icon: 'gauge-circle-bolt'
  },
  {
    name: 'Sensor History',
    icon: 'chart-mixed'
  },
  {
    name: 'Sensor Percentage',
    icon: 'signal-bars'
  },
  {
    name: 'Sensor Status',
    icon: 'sensor-on'
  },
  {
    name: 'Speed Alerts',
    icon: 'gauge-simple-max'
  },
  {
    name: 'Stacked Sensor - All Agents',
    icon: 'chart-line'
  }
]
