import { Table, Text } from '@mantine/core'
import { type getAgentDetails } from '@venturi-io/api/src/config/agent'
import { type ReactNode } from 'react'
import { type ApiData } from 'src/utils/useApi'
import valueMap from 'src/utils/valueMap'

interface TableRowProps {
  property: string
  value: string
}

const TableRow = ({ property, value }: TableRowProps) => (
  <tr>
    <td>
      <Text
        sx={{ minWidth: 80 }}
        weight="bold"
        size="xs"
      >
        {property}
      </Text>
    </td>
    <td>
      <Text size="xs">{value}</Text>
    </td>
  </tr>
)

function SystemSensors ({ sensors }: ApiData<typeof getAgentDetails>): ReactNode {
  return sensors.length > 0
    ? (
      <Table>
        <tbody>
          {sensors.map(({ id, name, currentValue, unit, valueMap: vm }) => (
            <TableRow
              key={id}
              property={`${name}:`}
              value={`ID: ${id} - ${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`}
            />
          ))}
        </tbody>
      </Table>
      )
    : null
}

export default SystemSensors
