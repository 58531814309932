import { Stack as MantineStack, Center, Text } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import Loader from 'src/Layout/Loader'
import { useElementSize } from '@mantine/hooks'
import { useMemo } from 'react'
import VirtualTable from './VirtualTable'
import { rowHeight } from './VirtualTable/shared'

interface Props {
  asChild?: boolean
  title?: string
  agents: Array<string | Agent>
  loading?: boolean
  isAgentGroup?: boolean
  onClick?: (agentId: Agent['agentId']) => void
  onRefresh?: () => void
  isCheckbox?: boolean
}

export default function AgentStack ({
  asChild,
  agents,
  loading,
  isAgentGroup,
  onClick,
  onRefresh
}: Props) {
  const { ref: containerRef, height } = useElementSize()

  // run once
  const initialHeight = useMemo(() => {
    return height === 0
      ? asChild
        ? agents.length * rowHeight
        : '100%'
      : '100%'
  }, [])

  return (
    <MantineStack
      ref={containerRef}
      mt="xs"
      spacing={10}
      sx={{
        height: initialHeight
      }}
    >
      {isAgentGroup && agents.length <= 0 && (
        <Center>
          <Text ml={-15} size="xs">No assigned agent(s)</Text>
        </Center>
      )}
      {loading && <Loader />}
      <VirtualTable
        agents={agents}
        onClick={onClick}
        onRefresh={onRefresh}
      />
    </MantineStack>
  )
}
