import { forwardRef } from 'react'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { createStyles, Box, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'
import { type Status as ConnectivityStatusType } from '../Components/ConnectivityStatus'
import PictogramAndStatus from './PictogramAndStatus'
import AssetDetails from './AssetDetails'
import Location from './Location'
import Tasks from './Tasks'
import { type Fuel } from './PictogramAndStatus/FuelType'

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 240
    }
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  agentName: AgentDetails['agentName']
  assetType: AgentDetails['assetType']
  metadata: AgentDetails['metadata']
  geoLocation: AgentDetails['geoLocation']
  connectivityStatus: ConnectivityStatusType
  connectivityDuration?: string
  lastDataTransfer?: string
  totalAssetRuntime: string
  fuelType?: Fuel
}

const Details = forwardRef<HTMLDivElement, Props>(({
  agentId,
  assetType,
  metadata,
  geoLocation,
  connectivityStatus,
  connectivityDuration,
  lastDataTransfer,
  totalAssetRuntime,
  fuelType
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <PictogramAndStatus
          assetType={assetType}
          connectivityStatus={connectivityStatus}
          connectivityDuration={connectivityDuration}
          lastDataTransfer={lastDataTransfer}
          totalAssetRuntime={totalAssetRuntime}
          fuelType={fuelType}
        />
        <AssetDetails agentId={agentId} metadata={metadata} />
        <Location agentId={agentId} geoLocation={geoLocation} />
        <Tasks agentId={agentId} />
      </Stack>
    </Box>
  )
})

export default Details
