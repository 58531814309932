import { createStyles, Box } from '@mantine/core'

const useStyles = createStyles({
  container: {
    position: 'sticky',
    top: 0,
    height: 120, // TODO: Define exact height,
    background: '#858585'
  }
})

export default function Header () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
    </Box>
  )
}
