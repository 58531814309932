const zIndex = {
  splash: 1000,
  tourCheckList: 201,
  pageFullContent: 110,
  burger: 101,
  menu: 100,
  header: 99,
  assetTemplateHeader: 99,
  dashEditControls: 10,
  pageFullViewIcon: 1,
  content: 1
}

export default zIndex
