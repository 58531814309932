import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Paper
} from '@mantine/core'
import { useParams } from 'react-router'
import {
  createModbusRegister,
  deleteModbusRegister,
  getAllModbusRegisters,
  type ModbusRegister,
  updateModbusRegister
} from '@venturi-io/api/src/config/modbus'
import {
  ReactGrid,
  type Column,
  type Id,
  type OptionType,
  type CellChange,
  type DefaultCellTypes
} from '@silevis/reactgrid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { getSensors } from '@venturi-io/api/src/config/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNotifications } from 'src/utils/notifications'
import CreateSensor from 'src/Admin/Sensors/Create'
import NotFound from 'src/Router/NotFound'
import { DeleteRowCell } from './DeleteRowCell'
import ControlTooltip from './ControlTooltip'
import { type SelectCell, SelectRowCell } from './SelectRowCell'
import {
  applyChangesToRegisters,
  dataTypeOpts,
  getColumns,
  headerRow,
  type LocalRegister
} from './shared'
import CSVModal from './CSVModal'

interface RouteParams extends Record<string, string | undefined> {
  deviceId: string
}

const useStyles = createStyles(() => ({
  container: {
    flex: 1,
    overflowX: 'auto',
    minHeight: 450
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

export default function Registers () {
  const { classes } = useStyles()
  const { showSuccess, showError } = useNotifications()
  const { deviceId: id } = useParams<RouteParams>()
  const { token } = useUser()
  const getAllSensors = useApi(getSensors)
  const getAllRegisters = usePaginatedApi(getAllModbusRegisters)
  const [registers, setRegisters] = useState<LocalRegister[]>([])
  const [sensors, setSensors] = useState<OptionType[]>([])
  const [toDelete, setToDelete] = useState<number[]>([])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [openCSV, setOpenCSV] = useState<boolean>(false)
  const [columns, setColumns] = useState<Column[]>(getColumns())

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const deviceId = parseInt(id)

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci)
      const resizedColumn = prevColumns[columnIndex]
      const updatedColumn = { ...resizedColumn, width }
      prevColumns[columnIndex] = updatedColumn
      return [...prevColumns]
    })
  }

  const handleDelete = useCallback((idx: number) => () => {
    // save modbusRegisterIds to delete
    const register = registers[idx]
    if (register.modbusRegisterId && register.modbusRegisterId !== -1) {
      setToDelete([...toDelete, register.modbusRegisterId])
    }
    setRegisters(prevRegisters => {
      const _registers = [...prevRegisters]
      _registers.splice(idx, 1)
      return _registers
    })
  }, [toDelete, registers])

  const handleAddSensor = useCallback(() => {
    setShowCreate(true)
  }, [])

  const handleImportCSV = useCallback((newRegisters: ModbusRegister[]) => {
    const _registers: LocalRegister[] = newRegisters.map(register => (
      {
        ...register,
        modbusRegisterId: -1,
        isDataTypeOpen: false,
        isSensorOpen: false,
        status: 'new',
        edited: true
      }
    ))
    // remove empty row
    const oldRegisters = [...registers]
    oldRegisters.pop()
    setRegisters([
      ...oldRegisters,
      ..._registers
    ])
    setOpenCSV(false)
  }, [registers])

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    const toCreate = registers
      .filter(({
        modbusRegisterId,
        edited,
        status
      }: LocalRegister) => modbusRegisterId === -1 && edited && status === 'new')
      .map(({
        modbusDeviceId,
        name,
        description,
        sensorId,
        scalingFactor,
        dataType,
        address,
        registerCount,
        function: funcValue
      }) => {
        return {
          modbusDeviceId: modbusDeviceId ?? deviceId,
          description: description ?? '',
          name,
          address,
          sensorId,
          scalingFactor,
          dataType,
          registerCount,
          function: funcValue
        }
      })

    const toUpdate = registers
      .filter(({
        modbusRegisterId,
        edited,
        status
      }: LocalRegister) => modbusRegisterId !== -1 && edited && status === 'existing')
      .map(({
        modbusRegisterId,
        modbusDeviceId,
        name,
        description,
        sensorId,
        scalingFactor,
        dataType,
        address,
        registerCount,
        function: funcValue
      }) => {
        return {
          registerId: modbusRegisterId ?? -1,
          modbusDeviceId: modbusDeviceId ?? deviceId,
          description: description ?? '',
          name,
          address,
          sensorId,
          scalingFactor,
          dataType,
          registerCount,
          function: funcValue
        }
      })

    const toDeletePromises = toDelete.map(async registerId => {
      const promise = new Promise<string>((resolve, reject) => {
        void deleteModbusRegister({ registerId }, token)
          .caseOf({
            Left: err => {
              reject(err)
            },
            Right: () => resolve('Deleted')
          })
      })
      return await promise
    })

    const toCreateRequests = toCreate.map(async register => {
      const promise = new Promise<string>((resolve, reject) => {
        void createModbusRegister(register, token)
          .caseOf({
            Left: err => {
              reject(err)
            },
            Right: () => resolve('Created')
          })
      })
      return await promise
    })

    const toUpdateRequests = toUpdate.map(async register => {
      const promise = new Promise<string>((resolve, reject) => {
        void updateModbusRegister(register, token)
          .caseOf({
            Left: err => {
              reject(err)
            },
            Right: () => resolve('Updated')
          })
      })
      return await promise
    })

    void Promise.all([...toCreateRequests, ...toUpdateRequests, ...toDeletePromises])
      .then(() => {
        showSuccess('Successfully saved modbus registers')
        setToDelete([])
        load()
      })
      .catch(() => {
        showError(new Error('Please make sure that all required fields are valid'))
      })
      .finally(() => {
        setIsSaving(false)
      })
  }, [toDelete, registers, deviceId])

  const emptyRegister: LocalRegister = {
    modbusRegisterId: -1,
    modbusDeviceId: deviceId,
    name: '',
    description: '',
    sensorId: -1,
    sensorName: '',
    dataType: '',
    address: NaN,
    function: 0,
    registerCount: NaN,
    scalingFactor: NaN,
    isDataTypeOpen: false,
    isSensorOpen: false,
    status: 'new',
    edited: false
  }

  const rows = useMemo(() => {
    const _rows = registers.map((register, idx) => {
      const {
        name,
        description,
        sensorId,
        dataType,
        address,
        registerCount,
        scalingFactor,
        edited,
        status
      } = register
      return {
        rowId: idx,
        cells: [
          { type: 'delete', onClick: handleDelete(idx), disabled: (status === 'new' && !edited) ?? false },
          { type: 'number', value: address, hideZero: true },
          { type: 'number', value: registerCount, hideZero: true },
          {
            type: 'select',
            label: 'data type',
            selectedValue: dataType,
            options: dataTypeOpts
          },
          { type: 'number', value: scalingFactor, hideZero: true },
          { type: 'text', text: name },
          { type: 'text', text: description ?? '' },
          {
            type: 'select',
            label: 'sensor',
            selectedValue: `${sensorId}`,
            options: sensors,
            hasAdd: true,
            onClick: handleAddSensor
          }
        ]
      }
    })
    return [
      headerRow,
      ..._rows
    ]
  }, [registers, sensors])

  const load = useCallback(() => {
    void getAllRegisters.fetch({ modbusDeviceId: deviceId, page: 1, size: 999 }, token)
  }, [deviceId])

  const loadSensors = useCallback(async () => {
    void getAllSensors.fetch({}, token)
  }, [])

  const handleChanges = useCallback((changes: Array<CellChange<DefaultCellTypes | SelectCell>>) => {
    setRegisters((prevRegisters) => applyChangesToRegisters(changes, prevRegisters))
  }, [])

  const loading = getAllRegisters.loading ?? getAllSensors.loading

  useEffect(() => {
    load()
    void loadSensors()
  }, [])

  useEffect(() => {
    getAllRegisters.data.ifJust(({ items }) => {
      const _registers = items.map(register => (
        {
          ...register,
          isDataTypeOpen: false,
          isSensorOpen: false,
          status: 'existing',
          edited: false
        }
      ))
      setRegisters([
        ..._registers,
        { ...emptyRegister }
      ])
    })
  }, [getAllRegisters.data])

  useEffect(() => {
    getAllSensors.data.ifJust((sensors) => {
      setSensors(sensors.map(({ id, name }) => ({ label: name, value: `${id}` })))
    })
  }, [getAllSensors.data])

  // Check for Addition / Deletion
  useEffect(() => {
    // check equality
    if (JSON.stringify(registers[registers.length - 1]) !== JSON.stringify(emptyRegister)) {
      // Add a new register
      setRegisters([
        ...registers,
        { ...emptyRegister }
      ])
    }
    // cannot be empty so we add a template
    if (registers.length === 0) {
      setRegisters([
        { ...emptyRegister }
      ])
    }
  }, [registers, emptyRegister])

  return loading
    ? <Loader />
    : (
      <>
        <Box className={classes.root}>
          <Paper my="xs" p="sm" shadow="sm">
            <CSVModal
              opened={openCSV}
              onClose={() => setOpenCSV(false)}
              onCancel={() => setOpenCSV(false)}
              onAccept={handleImportCSV}
            />
            <Group spacing={8} position="right">
              <ControlTooltip label={openCSV ? 'Cancel import' : 'Import CSV'}>
                <ActionIcon disabled={isSaving ?? loading} loading={isSaving} onClick={() => setOpenCSV(true)}>
                  <FontAwesomeIcon icon={['fas', openCSV ? 'xmark' : 'file-csv']} color="dark" size="1x" />
                </ActionIcon>
              </ControlTooltip>
              <ControlTooltip label="Save">
                <ActionIcon disabled={isSaving ?? loading} loading={isSaving} onClick={handleSave}>
                  <FontAwesomeIcon icon={['fas', 'floppy-disk']} color="dark" size="1x" />
                </ActionIcon>
              </ControlTooltip>
            </Group>
          </Paper>
          <Box className={classes.container}>
            <ReactGrid
              enableRowSelection
              enableRangeSelection
              enableFullWidthHeader
              rows={rows}
              columns={columns}
              onColumnResized={handleColumnResize}
              onCellsChanged={handleChanges}
              customCellTemplates={{ delete: new DeleteRowCell(), select: new SelectRowCell() }}
            />
          </Box>
        </Box>
        <CreateSensor
          show={showCreate}
          close={() => setShowCreate(false)}
          onCreate={loadSensors}
        />
      </>
      )
}
