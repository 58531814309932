import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack, Text } from '@mantine/core'
import { type AgentTemplate } from '@venturi-io/api/src/config/agentTemplate'
import { deleteAgent } from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextTooltip from 'src/Layout/TextTooltip'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { useNotifications } from 'src/utils/notifications'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { type Actions } from '.'

export default function Card ({
  agentId,
  agentName,
  assetType,
  actions
}: AgentTemplate & ListRow<Actions>) {
  const navigate = useNavigate()
  const { token } = useUser()
  const { showError, showSuccess } = useNotifications()
  const deleteTemplate = useApi(deleteAgent)
  const [showDelete, setShowDelete] = useState(false)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const handleDelete = useCallback(() => {
    void deleteTemplate.fetch({ agentId }, token)
  }, [token, agentId, actions])

  useEffect(() => {
    deleteTemplate.data.ifJust(res => {
      if (res) {
        showSuccess('Successfully deleted agent template')
        void actions.loadAgentTemplates()
      } else {
        showError(Error('Failed to delete agent template'))
      }
    })
  }, [deleteTemplate.data])

  useEffect(() => {
    deleteTemplate.error.ifJust(() => {
      showError(Error('Something went wrong while deleting agent template'))
    })
  }, [deleteTemplate.error])

  return (
    <>
      <Paper p="lg" my={10} shadow="md">
        <Stack>
          <Group position="apart" align="start">
            <TextGroup label="ID" value={agentId.toString()} />
            <Group spacing="sm">
              <ActionButton
                label="Edit"
                icon="pencil"
                iconType="fas"
                onClick={() => navigate(`/settings/agents/${agentId}/sensors?isTemplate=true`)}
                actionColor="green"
                actionVariant="filled"
                iconColor="white"
                iconSize="sm"
              />
              <ActionButton
                label="Delete"
                icon="trash"
                iconType="fas"
                actionColor="red"
                actionVariant="filled"
                iconColor="white"
                iconSize="sm"
                onClick={() => {
                  setShowDelete(true)
                }}
              />
            </Group>
          </Group>
          <Stack spacing={1}>
            <Text weight={600} size="sm">Name</Text>
            <TextTooltip
              size="sm"
              value={agentName}
              maxLength={textMaxLength}
            />
          </Stack>
          <TextGroup
            label="Asset Type"
            value={assetType ?? '-'}
          />
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${agentName}"`}
        confirmLabel="Delete"
        loading={deleteTemplate.loading}
        question="Are you sure you want to delete this agent template? This cannot be undone."
        onClose={() => {
          setShowDelete(false)
        }}
        onCancel={() => {
          setShowDelete(false)
        }}
        onConfirm={handleDelete}
      />
    </>
  )
}
