import { useEffect, useState } from 'react'
import { getAllIncidents, getIncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { type ChildProps } from '..'
import { Header, Row, Card } from './List'

function IncidentSummary ({ sensorInstanceId, dateRange }: ChildProps) {
  const incidentsSummary = usePaginatedApi(getIncidentsSummary)
  const allIncidents = usePaginatedApi(getAllIncidents)
  const { token, orgId } = useUser()
  const [loadData, setLoadData] = useState(true)

  const loadAllIncidents = (page?: number, size?: number) => {
    void allIncidents
      .fetch({
        orgId,
        startTime: dayjs(dateRange[0]).format(`${dateFormat}`),
        endTime: dayjs(dateRange[1]).format(`${dateFormat}`),
        page: page ?? allIncidents.page,
        size: size ?? allIncidents.size
      }, token)
  }

  useEffect(() => {
    loadAllIncidents()
  }, [allIncidents.page])

  const loadIncidents = (page?: number, size?: number) => {
    void incidentsSummary
      .fetch({
        sensorInstanceId: sensorInstanceId ?? '',
        startTime: dayjs(dateRange[0]).format(`${dateFormat}`),
        endTime: dayjs(dateRange[1]).format(`${dateFormat}`),
        page: page ?? incidentsSummary.page,
        size: size ?? incidentsSummary.size
      }, token)
      .finally(() => setLoadData(false))
  }

  useEffect(() => {
    if (!sensorInstanceId) {
      return incidentsSummary.clearAll()
    }
    if (incidentsSummary.loading) {
      incidentsSummary.abort()
      incidentsSummary.clearAll()
    }

    loadIncidents()
  }, [incidentsSummary.page, dateRange[0], dateRange[1], sensorInstanceId])

  useEffect(() => {
    if (sensorInstanceId) {
      setLoadData(false)
    }

    return () => {
      setLoadData(true)
    }
  }, [sensorInstanceId])

  const incidents = loadData
    ? allIncidents.data.mapOrDefault(({ items }) => items, [])
    : incidentsSummary.data.mapOrDefault(({ items }) => items, [])

  const incidentsLoading = loadData
    ? allIncidents.loading
    : incidentsSummary.loading

  return (
    <ActionList
      isLoading={incidentsLoading}
      data={incidents}
      getId={({ incidentId }) => incidentId}
      header={() => <Header />}
      row={Row}
      card={Card}
      totalPages={incidentsSummary.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
      page={incidentsSummary.page}
      onPageChange={incidentsSummary.setPage}
    />
  )
}

export default IncidentSummary
