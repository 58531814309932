import { z } from 'zod'
import dayjs from 'dayjs'
import { MIME_TYPES } from '@mantine/dropzone'
import { createStatefulApi, createStatelessApi } from '../configure'
import {
  paginated,
  type PaginatedRequest,
  type TaskIdRequest,
  type Req,
  type CommentIdRequest
} from '../shared'
import { user } from '../userManager/user'

export const taskTypes = [
  'MECHANICAL',
  'AUTO_ELECTRICAL',
  'REGISTRATION',
  'SAFETY_STICKER',
  'FAULTY_HARDWARE',
  'CALIBRATIONS',
  'BREAKDOWN',
  'SERVICE'
] as const

export const priority = [
  'LOW',
  'MEDIUM',
  'HIGH'
] as const

export const statuses = [
  'NEW',
  'IN_PROGRESS',
  'DONE'
] as const

export type TaskType = typeof taskTypes[number]
export type Priority = typeof priority[number]
export type Status = typeof statuses[number]

export const attachment = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
  createdAt: z.optional(z.string())
})

export type Attachment = z.infer<typeof attachment>

export const acceptedTypes = [
  MIME_TYPES.jpeg,
  MIME_TYPES.gif,
  MIME_TYPES.png,
  MIME_TYPES.svg,
  MIME_TYPES.pdf,
  MIME_TYPES.xls,
  MIME_TYPES.xlsx
]

const taskUser = user
  .pick({
    firstName: true,
    lastName: true,
    jobTitle: true
  })
  .extend({
    id: z.number()
  })

const agent = z.object({
  agentId: z.number(),
  agentName: z.string(),
  assetType: z.string()
})

const comment = z.object({
  id: z.number(),
  content: z.string(),
  orgUser: taskUser,
  edited: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string()
})

export const task = z.object({
  id: z.number(),
  agent,
  taskType: z.enum(taskTypes),
  priority: z.enum(priority),
  assignedTo: taskUser,
  dueDate: z
    .string()
    .transform(input => dayjs(input).toDate()),
  status: z.enum(statuses),
  notes: z.string(),
  summary: z.optional(z.string()),
  attachments: z.array(attachment),
  createdBy: taskUser,
  updatedBy: taskUser,
  createdAt: z
    .string()
    .transform(input => dayjs(input).toDate()),
  updatedAt: z
    .string()
    .transform(input => dayjs(input).toDate())
})

const allTasks = paginated.extend({
  items: z.array(task)
})

const comments = z.object({
  items: z.array(comment)
})

export type Task = z.infer<typeof task>
export type Comment = z.infer<typeof comment>
export interface TaskRequest extends Req {
  taskId: number
  agentId?: number
  taskType?: TaskType
  priority?: Priority
  assignedTo?: number
  dueDate?: string
  status?: Status
  notes?: string
}

export interface CommentRequest extends Req {
  content?: string
}

export const createTask = createStatefulApi<Omit<TaskRequest, 'taskId'>, typeof task>(
  'config',
  '/tasks',
  { method: 'POST' },
  task
)

interface GetAllTasksRequest extends PaginatedRequest {
  assetName?: string
  assetType?: string
  taskType?: string
  status?: string
  taskGroup?: string
  agentId?: string
}

export interface UpdateDeleteCommentRequest extends Req, TaskIdRequest, CommentIdRequest {
  content?: string
}

export const getAllTasks = createStatelessApi<GetAllTasksRequest, typeof allTasks>(
  'config',
  '/tasks',
  { method: 'GET', type: 'path' },
  allTasks
)

export const getTask = createStatelessApi<TaskIdRequest, typeof task>(
  'config',
  '/tasks/:taskId',
  { method: 'GET', type: 'path' },
  task
)

export const updateTask = createStatefulApi<TaskRequest, typeof task>(
  'config',
  '/tasks/:taskId',
  { method: 'PUT' },
  task
)

export const deleteTask = createStatefulApi<TaskIdRequest, z.ZodBoolean>(
  'config',
  '/tasks/:taskId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

type DeleteTaskAttachmentsRequest = TaskIdRequest & { attachmentIds: number[] }
export const deleteTaskAttachments = createStatefulApi<DeleteTaskAttachmentsRequest, z.ZodBoolean>(
  'config',
  '/tasks/:taskId/attachments',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

export const createComment = createStatefulApi<CommentRequest, typeof comment>(
  'config',
  '/tasks/:taskId/comments',
  { method: 'POST' },
  comment
)

export const getComments = createStatelessApi<TaskIdRequest, typeof comments>(
  'config',
  '/tasks/:taskId/comments',
  { method: 'GET', type: 'path' },
  comments
)

export const updateComment = createStatefulApi<UpdateDeleteCommentRequest, typeof comment>(
  'config',
  '/tasks/:taskId/comments/:commentId',
  { method: 'PUT' },
  comment
)

export const deleteComment = createStatefulApi<UpdateDeleteCommentRequest, z.ZodBoolean>(
  'config',
  '/tasks/:taskId/comments/:commentId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
