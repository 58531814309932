import { useState, useEffect } from 'react'
import { Box, Paper } from '@mantine/core'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getCurrentBreakpoint, getMantineBreakpointsInPx } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { gridColumns } from '../shared'
import MissingSensorMessage from '../MissingSensorMessage'
import ModuleContainer from './ModuleContainer'
import { defaultLayouts } from './data'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

export default function UpsDashboard () {
  const agentDetails = useApi(getAgentDetails)
  const { token } = useUser()
  const [missingSensors, setMissingSensors] = useState<string[]>([])
  const initialBreakpoint = getCurrentBreakpoint()

  const { assetId } = useParams<RouteParams>()
  if (!assetId) {
    throw new Error('No asset ID given, routing is broken')
  }

  const breakpoints = getMantineBreakpointsInPx()
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint)
  const onBreakpointChange = (breakpoint: string) => setCurrentBreakpoint(breakpoint)

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  useEffect(() => {
    const agentId = parseInt(assetId)

    void agentDetails
      .fetch({ agentId }, token)
      .finally(() => {
        agentDetails.startPolling({ agentId }, token, 60)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [])

  return (
    <Box mt="lg">
      {agentDetails.loading && <Loader />}
      {!agentDetails.loading && missingSensors.length > 0 && (
        <MissingSensorMessage assetName={agent?.agentName} missingSensors={missingSensors} />
      )}
      {agent && (
        <ResponsiveGridLayout
          className="layout"
          layouts={defaultLayouts}
          rowHeight={280}
          cols={gridColumns}
          breakpoints={breakpoints}
          onBreakpointChange={onBreakpointChange}
        >
          {currentBreakpoint && (
            defaultLayouts[currentBreakpoint]?.map(item => (
              <Paper
                key={item.i}
                p="md"
                shadow="sm"
                style={{
                  overflow: 'none'
                }}
              >
                <ModuleContainer
                  agent={agent}
                  contentKey={item.i}
                  setMissingSensors={setMissingSensors}
                />
              </Paper>
            ))
          )}
        </ResponsiveGridLayout>
      )}
    </Box>
  )
}
