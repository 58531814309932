import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { useEffect } from 'react'
import { findMissingSensors } from '../shared'
import { MODULE_CONTENT, findSensors } from './constants'
import PowerStatus from './PowerStatus'
import PowerStackedAreaCharts from './PowerStackedAreaCharts'
import PowerCurrentOutputs from './PowerCurrentOutputs'
import PowerTotals from './PowerTotals'

interface Props {
  agent: AgentDetails
  contentKey: string
  setMissingSensors: (value: string[]) => void
}

export default function ModuleContainer ({
  agent,
  contentKey,
  setMissingSensors
}: Props) {
  const {
    agentName,
    sensors
  } = agent
  const {
    posActiveThreePhase,
    negActiveThreePhase,
    powerFactor,
    activeThreePhase,
    activeEnergyLN1,
    activeEnergyLN2,
    activeEnergyLN3,
    rmsLineCurrentL1,
    rmsLineCurrentL2,
    rmsLineCurrentL3,
    reactiveEnergyThreePhase,
    rmsLineCurrentLN
  } = findSensors(sensors)

  useEffect(() => {
    const missingSensors = findMissingSensors([
      'POSITIVE_ACTIVE_THREE_PHASE',
      'NEGATIVE_ACTIVE_THREE_PHASE',
      'POWER_FACTOR_THREE_PHASE',
      'ACTIVE_THREE_PHASE',
      'RMS_LINE_CURRENT_LN',
      'REACTIVE_ENERGY_THREE_PHASE',
      'RMS_LINE_CURRENT_L1',
      'RMS_LINE_CURRENT_L2',
      'RMS_LINE_CURRENT_L3',
      'ACTIVE_ENERGY_LINE_1',
      'ACTIVE_ENERGY_LINE_2',
      'ACTIVE_ENERGY_LINE_3'
    ], sensors)

    setMissingSensors(missingSensors)
  }, [sensors])

  switch (contentKey) {
    case MODULE_CONTENT.POWER_CURRENT:
      return (
        <PowerCurrentOutputs
          sensors={sensors}
          agentName={agentName}
        />
      )
    case MODULE_CONTENT.POWER_STATUS:
      return (
        <PowerStatus
          sensors={sensors}
          agentName={agentName}
        />
      )
    case MODULE_CONTENT.POWER_TOTALS: {
      return (
        <PowerTotals
          sensors={{
            activeThreePhase,
            posActiveThreePhase,
            negActiveThreePhase,
            powerFactor
          }}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_STACKED_KILOWATTS: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            activeEnergyLN1,
            activeEnergyLN2,
            activeEnergyLN3
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_STACKED_CURRENT: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            rmsLineCurrentL1,
            rmsLineCurrentL2,
            rmsLineCurrentL3
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_SINGLE_POWER_FACTOR: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            powerFactor
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_SINGLE_LL: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            reactiveEnergyThreePhase
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_SINGLE_LN: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            rmsLineCurrentLN
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_SINGLE_POSITIVE_ACTIVE: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            posActiveThreePhase
          ]}
        />
      )
    }
    case MODULE_CONTENT.POWER_GRAPH_SINGLE_NEGATIVE_ACTIVE: {
      return (
        <PowerStackedAreaCharts
          label={contentKey}
          sensors={[
            negActiveThreePhase
          ]}
        />
      )
    }
    default:
      return null
  }
}
