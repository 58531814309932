import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <NeedsRole role={adminRoles}>
        <div
          className={cx(classes.col, classes.idCol)}
          style={{ minWidth: '40px' }}
        >
          <Text weight={600} size="sm">
            ID
          </Text>
        </div>
      </NeedsRole>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Asset
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Type
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Task Type
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Priority
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Assignee
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Due Date
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Status
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Action
        </Text>
      </div>
    </div>
  )
}

export default Header
