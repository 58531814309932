import { memo, useEffect, useMemo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Agent, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import AgentCell from 'src/Home/Agents/AgentCell'
import Loader from 'src/Layout/Loader'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data: {
    agentId: Agent['agentId']
  }
}

const AgentQuickView = ({
  data: {
    agentId
  }
}: Props) => {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)

  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const agent: Agent | null = useMemo(() => (
    agentDetails.data.mapOrDefault(({
      agentId,
      agentName,
      description,
      iconStyle,
      sensors,
      alarmStatus,
      lastSeenTime,
      simulator,
      site,
      hasImmobiliser
    }) => ({
      agentId,
      agentName,
      description: description ?? '',
      iconStyle,
      sensors,
      alarmStatus,
      lastSeenTime,
      simulator,
      site,
      hasImmobiliser
    }), null)
  ), [agentDetails.data])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      {agentDetails.loading && <Loader variant="bars" />}
      {(!agentDetails.loading && agent) && (
        <AgentCell
          agent={agent}
          isSmall={false}
          width={width}
          height={height}
        />
      )}
    </Container>
  )
}

export default memo(AgentQuickView)
