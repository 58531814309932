import { type ReactElement } from 'react'
import Map from 'src/Maps/GeoZoneMap'
import { useParams } from 'react-router-dom'

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

interface Props {
  expandBtn?: ReactElement
}

export default function Location ({ expandBtn }: Props) {
  const { assetId } = useParams<RouteParams>()

  return (
    <Map
      selectedAgentId={Number(assetId)}
      expandBtn={expandBtn}
      options={{
        embedded: true
      }}
    />
  )
}
