import {
  ActionIcon,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { type Task } from '@venturi-io/api/src/config/task'
import dayjs from 'dayjs'
import { MODULE_CONTENT } from './constants'

interface Props {
  tasks: Task
}

interface TableRowProps {
  property: string
  value?: string | number | null
}

const TableRow = ({ property, value }: TableRowProps) => (
  <tr>
    <td>
      <Text
        transform="uppercase"
        weight="bold"
        size="xs"
        sx={{ minWidth: 80 }}
      >
        {property}
      </Text>
    </td>
    <td>
      <Text size="xs">{value ?? '-'}</Text>
    </td>
  </tr>
)

function Asset ({
  tasks
}: Props) {
  const {
    agent,
    assignedTo,
    createdBy,
    dueDate,
    priority
  } = tasks

  const assignee = `${assignedTo.firstName} ${assignedTo.lastName}`
  const reporter = `${createdBy.firstName} ${createdBy.lastName}`
  const duedate = dayjs(dueDate).toString() || null

  return (
    <Stack>
      <Group position="apart">
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.TASK_DETAILS}
        </Text>
        <Tooltip withinPortal label="Agent config">
          <Link to={`/settings/agents/${agent.agentId}`}>
            <ActionIcon>
              <FontAwesomeIcon icon={['far', 'cog']} />
            </ActionIcon>
          </Link>
        </Tooltip>
      </Group>
      <ScrollArea
        type="auto"
        h={200}
        scrollbarSize={5}
        style={{ flex: 1 }}
      >
        <Stack spacing="xs" h={370}>
          <Table>
            <tbody>
              <TableRow
                property="Asset ID"
                value={agent.agentId}
              />
              <TableRow
                property="Asset name"
                value={agent.agentName}
              />
              <TableRow
                property="Asset Type"
                value={agent.assetType}
              />
              <TableRow
                property="Due Date"
                value={duedate}
              />
              <TableRow
                property="Priority"
                value={priority}
              />
              <TableRow
                property="Assignee"
                value={assignee}
              />
              <TableRow
                property="Reporter"
                value={reporter}
              />
            </tbody>
          </Table>
        </Stack>
      </ScrollArea>
    </Stack>
  )
}

export default Asset
