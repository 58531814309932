import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import {
  type AlarmStatus,
  type AssetType,
  alarmStatuses,
  assetTypes,
  getAgents
} from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import NeedsRole from 'src/NeedsRole'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import ActionList from 'src/Layout/ActionList'
import { Header, Row, Card } from './List'
import CreateAgent from './Create'

type AssetTypeFilter = AssetType | string | undefined
type AlarmStatusFilter = AlarmStatus | string | undefined

const selectAssetTypes = [
  'ALL',
  ...assetTypes,
  'NONE'
]

const selectAlarmStatuses = [
  'ALL',
  ...alarmStatuses
]

export default function Agents () {
  const { token, orgId } = useUser()
  const agents = usePaginatedApi(getAgents)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)
  const [assetType, setAssetType] = useState<AssetTypeFilter>()
  const [alarmStatus, setAlarmStatus] = useState<AlarmStatusFilter>()

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agents
      .fetch({
        page: page ?? agents.page,
        size: size ?? agents.size,
        orgId,
        search: debouncedSearch,
        assetType,
        alarmStatus
      }, token)
  }

  useEffect(() => {
    void loadAgents(1)
  }, [debouncedSearch, assetType, alarmStatus])

  useEffect(() => {
    void loadAgents()
  }, [agents.page])

  return (
    <>
      <NeedsRole role="ROLE_SUPERADMIN">
        <Group position="right" spacing="xs">
          <Button
            disabled={showCreate}
            title="Create Agent"
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
            onClick={() => setShowCreate(true)}
          >
            Create Agent
          </Button>
        </Group>
      </NeedsRole>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} md={8}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search agent here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by type"
              data={selectAssetTypes}
              onChange={value => {
                const type = value === 'ALL' || value === null
                  ? undefined
                  : value
                setAssetType(type)
              }}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by status"
              data={selectAlarmStatuses}
              onChange={value => {
                const status = value === 'ALL' || value === null
                  ? undefined
                  : value
                setAlarmStatus(status)
              }}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={agents.loading}
        data={agents.data.mapOrDefault(({ items }) => items, [])}
        getId={({ agentId }) => agentId}
        actions={{ loadAgents }}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={agents.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={agents.page}
        onPageChange={agents.setPage}
      />
      <CreateAgent
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadAgents}
      />
    </>
  )
}
