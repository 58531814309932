import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Group, Paper, Stack } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { removeAgentsFromGroup } from '@venturi-io/api/src/config/agentGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { truncateWithEllipsis } from 'src/utils/strings'
import { type Actions, type ExtraData } from '.'

export default function Card ({
  actions,
  extra,
  ...agent
}: Agent & ListRow<Actions, ExtraData>) {
  const { token } = useUser()
  const navigate = useNavigate()
  const { agentId, agentName } = agent
  const { agentGroupId } = extra
  const { loadAgents } = actions
  const removeFromGroup = useApi(removeAgentsFromGroup)
  const [showRemove, setShowRemove] = useState(false)

  const handleRemoveFromGroup = useCallback(() => {
    void removeFromGroup
      .fetch({
        agentGroupId,
        agentIds: [agentId]
      }, token)
      .finally(() => { void loadAgents() })
  }, [token, agentId, agentGroupId])

  return (
    <>
      <Paper p="lg" my={10} shadow="md">
        <Stack>
          <Group position="apart" align="start">
            <TextGroup label="ID" value={agentId.toString()} isKey />
            <Group spacing="sm">
              <ActionIcon
                title="Edit"
                color="green"
                variant="filled"
                onClick={() => navigate(`/settings/agents/${agentId}`)}
              >
                <FontAwesomeIcon icon={['fas', 'pencil']} color="white" />
              </ActionIcon>
              <ActionIcon
                title="Remove"
                color="red"
                variant="filled"
                onClick={() => setShowRemove(true)}
                disabled={removeFromGroup.loading}
              >
                <FontAwesomeIcon size="lg" icon={['fas', 'square-minus']} color="white" />
              </ActionIcon>
            </Group>
          </Group>
          <TextGroup label="Name" value={agentName} maxLength={25} />
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showRemove}
        title={`Removing "${truncateWithEllipsis(agentName, 23)}" from this group`}
        confirmLabel="Remove"
        loading={removeFromGroup.loading}
        question="Are you sure you want to remove this agent from this group? This cannot be undone."
        onClose={() => setShowRemove(false)}
        onCancel={() => setShowRemove(false)}
        onConfirm={handleRemoveFromGroup}
      />
    </>
  )
}
