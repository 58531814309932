import { type Sensor } from '@venturi-io/api/src/config/agent'
import { formatNumber } from 'src/utils/numbers'

export const sensorTypes = {
  ACTIVE_ENERGY_LINE_1: 190,
  ACTIVE_ENERGY_LINE_2: 193,
  ACTIVE_ENERGY_LINE_3: 194,
  ACTIVE_THREE_PHASE: 199,
  ATS_STATUS: 167,
  BATTERY: 7,
  BATTERY_VOLTAGE: 42,
  CHARGE_ALTERNATOR_VOLTAGE: 57,
  CONTROL_MODE: 147,
  COOLANT_TEMPERATURE: 54,
  DIGITAL_INPUT_2_DURESS: 543,
  ENGINE_BATTERY_VOLTAGE: 58,
  ENGINE_IGNITION_STATUS: 538,
  ENGINE_OPERATE_MODE: 76,
  ENGINE_RUN_TIME: 81,
  ENGINE_SPEED: 59,
  EXT_POWER_SOURCE_VOLTAGE: 537,
  FUEL_CAPACITY: 154,
  FUEL_CONSUMPTION: 71,
  FUEL_LEVEL: 56,
  FUEL_LEVEL_L: 177,
  GENERATOR_L1N_VOLTAGE: 61,
  GENERATOR_L2N_VOLTAGE: 62,
  GENERATOR_L3N_VOLTAGE: 63,
  GENERATOR_L1L2_VOLTAGE: 64,
  GENERATOR_L1L3_VOLTAGE: 65,
  GENERATOR_L3L1_VOLTAGE: 66,
  GENERATOR_L1_CURRENT: 67,
  GENERATOR_L2_CURRENT: 68,
  GENERATOR_L3_CURRENT: 69,
  HUMIDITY: 3,
  IMMOBILISATION_STATUS: 551,
  MAINS_VOLTAGE: 43,
  NEGATIVE_ACTIVE_THREE_PHASE: 201,
  OIL_PRESSURE: 79,
  POSITION_ALTITUDE: 532,
  POSITION_DIRECTION: 533,
  POSITION_HDOP: 534,
  POSITION_SATELLITES: 535,
  POSITION_SPEED: 536,
  POSITIVE_ACTIVE_THREE_PHASE: 200,
  POWER_CAPACITY: 575,
  POWER_FACTOR_THREE_PHASE: 226,
  POWER_FREQUENCY: 60,
  POWER_LEVEL_KVA: 176,
  POWER_LEVEL_KWH: 86,
  POWER_STATUS: 153,
  REACTIVE_ENERGY_THREE_PHASE: 205,
  RMS_AVG_VALUE_VOLTAGE: 220,
  RMS_ENERGY_L1: 202,
  RMS_ENERGY_L2: 203,
  RMS_ENERGY_L3: 204,
  RMS_LINE_CURRENT_L1: 186,
  RMS_LINE_CURRENT_L2: 221,
  RMS_LINE_CURRENT_L3: 222,
  RMS_LINE_CURRENT_LN: 223,
  RMS_VOLTAGE_L1N: 185,
  RMS_VOLTAGE_L2N: 218,
  RMS_VOLTAGE_L3N: 219,
  S1_AVAILABLE: 165,
  S1_SWITCH: 163,
  S1_VOLTAGE_L1N: 168,
  S1_VOLTAGE_L2N: 169,
  S1_VOLTAGE_L3N: 170,
  S2_AVAILABLE: 166,
  S2_SWITCH: 164,
  S2_VOLTAGE_L1N: 171,
  S2_VOLTAGE_L2N: 172,
  S2_VOLTAGE_L3N: 173,
  UPS_STATUS: 174,
  UPS_RUNTIME: 175
} as const

export type SensorType = keyof typeof sensorTypes

export const findSensor = (sensorType: SensorType, sensors: Sensor[]): Sensor | null => {
  const sensor = sensors.find(sensor => sensor.id === sensorTypes[sensorType])

  if (!sensor) {
    return null
  }

  return sensor
}

export const getSensorValue = (sensor: Sensor | null, formatted = false, withUnit = false) => {
  let value = '0'

  if (typeof sensor?.currentValue !== 'undefined') {
    const { currentValue } = sensor
    const parsedValue = Number(currentValue)

    value = currentValue

    if (formatted && !isNaN(parsedValue)) {
      value = `${formatNumber(parsedValue) ?? parsedValue}`
    }
  }

  if (withUnit && typeof sensor?.unit !== 'undefined') {
    value = `${value} ${sensor.unit}`
  }

  return value
}

export const computeRelativePercentage = (
  value: number,
  minValue?: number,
  maxValue?: number
): number => {
  const min = typeof minValue !== 'undefined'
    ? minValue
    : 0
  const max = typeof maxValue !== 'undefined'
    ? maxValue
    : 0
  const range = max - min
  const correctedStartValue = value - min

  return (correctedStartValue * 100) / range
}

export const convertStrToFloat = (value: string | undefined): number => value ? parseFloat(value) : 0

export const hasAlarm = (sensors: Array<Sensor | null>) => (
  sensors.some(sensor => (sensor !== null && sensor.alarmStatus === 'ALARM'))
)
