import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from '@mantine/form'
import {
  type SelectItem,
  Box,
  Col,
  Grid,
  Group,
  Stack,
  Text
} from '@mantine/core'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import Select from 'src/Input/Select'
import ActionButton from 'src/ActionButton'

interface ValInput {
  input: string
}

interface Props {
  value?: ValueMapped
  onChange: (map: ValueMapped) => void
  setErrorMessage: (param: string | null) => void
}

type ValMapString = ValInput & {
  outputType: 'String'
  output: string
}

type ValMapNumber = ValInput & {
  outputType: 'Number'
  output: number
}

type ValMapBoolean = ValInput & {
  outputType: 'Boolean'
  output: boolean
}
type ValMap =
  | ValMapString
  | ValMapNumber
  | ValMapBoolean

export type ValueMapped = Record<string, ValMap['output']>

export const defaultValMap: ValMap = {
  input: '',
  outputType: 'String',
  output: ''
}

const selectInput: SelectItem[] = [
  {
    value: 'String',
    label: 'String'
  },
  {
    value: 'Number',
    label: 'Number'
  },
  {
    value: 'Boolean',
    label: 'Boolean'
  }
]

const selectBoolean: SelectItem[] = [
  {
    value: 'true',
    label: 'True'
  },
  {
    value: 'false',
    label: 'False'
  }
]

export default function ValueMap ({ value, onChange, setErrorMessage }: Props) {
  const form = useForm<{ formValue: ValMap[] }>({
    initialValues: {
      formValue: []
    }
  })

  const hasDuplicatedInputs = useMemo(() => {
    const inputKeys = form.values.formValue
      .filter(({ input }) => input.length > 0)
      .map(({ input }) => input)
    const uniqueInputKeys = new Set(inputKeys)

    return inputKeys.length !== uniqueInputKeys.size
  }, [form.values.formValue])

  const clickHandler = () => {
    form.insertListItem('formValue', { ...defaultValMap })
  }

  const handleSelectTypeChange = (index: number) => (outputType: ValMap['outputType']) => {
    const current = form.values.formValue[index]
    const val = {
      input: current.input,
      outputType
    }

    form.removeListItem('formValue', index)

    switch (outputType) {
      case 'String':
        form.insertListItem(
          'formValue',
          {
            ...val,
            output: ''
          },
          index
        )
        break

      case 'Number':
        form.insertListItem(
          'formValue',
          {
            ...val,
            output: 0
          },
          index
        )
        break

      case 'Boolean':
        form.insertListItem(
          'formValue',
          {
            ...val,
            output: ''
          },
          index
        )
        break

      default:
        form.insertListItem(
          'formValue',
          {
            ...val,
            output: ''
          },
          index
        )
    }
  }

  const handleFormValueChange = useCallback(() => {
    const result = form.validate()
    if (!result.hasErrors) {
      if (hasDuplicatedInputs) {
        setErrorMessage('** Duplicated inputs not allowed **')
      } else {
        setErrorMessage(null)
        const mapped = form.values.formValue.reduce((obj: ValueMapped, {
          input,
          outputType,
          output
        }) => {
          let parsedOutput: ValMap['output']
          switch (outputType) {
            case 'String':
              parsedOutput = output.toString()
              break

            case 'Number':
              parsedOutput = isNaN(output)
                ? 0
                : parseFloat(output.toString())
              break

            case 'Boolean':
              parsedOutput = output.toString() === 'true'
              break
          }
          obj[input] = parsedOutput

          return obj
        }, {})

        onChange(mapped)
      }
    }
  }, [form.values.formValue, onChange])

  useEffect(() => {
    handleFormValueChange()
  }, [form.values.formValue])

  useEffect(() => {
    if (value) {
      const editMap: ValMap[] = Object.entries(value).map(([input, output]) => {
        let parsedValue: ValMap

        switch (typeof output) {
          case 'string':
            parsedValue = {
              input,
              outputType: 'String',
              output
            }
            break

          case 'number':
            parsedValue = {
              input,
              outputType: 'Number',
              output
            }
            break

          case 'boolean':
            parsedValue = {
              input,
              outputType: 'Boolean',
              output
            }
            break
        }
        return parsedValue
      })
      form.setFieldValue('formValue', editMap)
    }
  }, [value])

  const addListItem = form.values.formValue.map((val, index) => (
    <Grid key={index}>
      <Col span={12} sm={4}>
        <Group align="flex-end" spacing={4}>
          <TextInput
            required
            sx={{ flex: 1 }}
            label="Input"
            onKeyUp={() => {
              handleFormValueChange()
            }}
            {...form.getInputProps(`formValue.${index}.input`)}
          />
          <Box mb={1}>
            <ActionButton
              label="Remove Item"
              icon="remove"
              iconType="fas"
              onClick={() => {
                form.removeListItem('formValue', index)
                handleFormValueChange()
              }}
              actionVariant="default"
              actionSize="lg"
              iconColor="gray"
              iconSize="sm"
            />
          </Box>
        </Group>
      </Col>
      <Col span={12} sm={4}>
        <Select
          required
          label="Type"
          data={selectInput}
          {...form.getInputProps(`formValue.${index}.outputType`)}
          onSelect={() => {
            handleSelectTypeChange(index)
          }}
        />
      </Col>
      <Col span={12} sm={4}>
        {val.outputType === 'String' && (
          <TextInput
            required
            label="Output"
            onKeyUp={() => {
              handleFormValueChange()
            }}
            {...form.getInputProps(`formValue.${index}.output`)}
          />
        )}
        {val.outputType === 'Number' && (
          <NumberInput
            required
            label="Output"
            hideControls
            onKeyUp={() => {
              handleFormValueChange()
            }}
            value={typeof form.values.formValue[index].output !== 'number'
              ? 0
              : form.values.formValue[index].output as number}
            onChange={value => {
              form.setFieldValue(`formValue.${index}.output`, value)
            }}
          />
        )}
        {val.outputType === 'Boolean' && (
          <Select
            required
            label="Output"
            data={selectBoolean}
            onSelect={() => {
              handleFormValueChange()
            }}
            value={form.values.formValue[index].output === true || form.values.formValue[index].output === 'true'
              ? 'true'
              : 'false'}
            onChange={value => {
              form.setFieldValue(
                `formValue.${index}.output`,
                value)
            }}
          />
        )}
      </Col>
    </Grid>
  ))

  return (
    <div>
      <Grid grow>
        <Col span={12}>
          <Group spacing={8}>
            <Text size="xs">Value Map</Text>
            <ActionButton
              label="Add Item"
              icon="plus"
              iconType="fas"
              onClick={clickHandler}
              actionColor="blue"
              actionVariant="filled"
              actionSize="xs"
              iconColor="white"
              iconSize="xs"
            />
          </Group>
          <Col span={12}>
            <Stack spacing={16}>
              { addListItem }
            </Stack>
          </Col>
        </Col>
      </Grid>
    </div>
  )
}
