import { Paper, Stack, Text, Radio } from '@mantine/core'
import zIndex from 'src/utils/zIndex'

interface Props {
  isDoneMapTour?: boolean
  isDoneDashboardTour?: boolean
  isDoneAssetTour?: boolean
  isDoneReportTour?: boolean
  isDoneAssetTaskTour?: boolean
}

export default function CheckList ({
  isDoneMapTour = false,
  isDoneDashboardTour = false,
  isDoneAssetTour = false,
  isDoneReportTour = false,
  isDoneAssetTaskTour = false
}: Props) {
  return (
    <Paper
      shadow="lg"
      px="xl"
      py="lg"
      radius={20}
      sx={{
        zIndex: zIndex.tourCheckList,
        position: 'absolute',
        right: 15,
        bottom: 15,
        pointerEvents: 'none'
      }}
    >
      <Text weight="bold" ta="center">GET STARTED</Text>
      <Stack mt="sm">
        <Radio
          checked={isDoneMapTour}
          color="primary"
          label="Explore the Map"
        />
        <Radio
          checked={isDoneDashboardTour}
          color="primary"
          label="Custom Dashboards"
        />
        <Radio
          checked={isDoneAssetTour}
          color="primary"
          label="Asset Information"
        />
        <Radio
          checked={isDoneReportTour}
          color="primary"
          label="Pulling Reports"
        />
        <Radio
          checked={isDoneAssetTaskTour}
          color="primary"
          label="Asset Tasks"
        />
      </Stack>
    </Paper>
  )
}
