import { ActionIcon, SimpleGrid, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type HeaderGroup } from 'react-table'

interface Props {
  headerGroups: HeaderGroup[]
  hasSorting?: boolean
  hasFilters?: boolean
}

export default function Header ({
  headerGroups,
  hasSorting = false,
  hasFilters = false
}: Props) {
  return (
    <>
      {headerGroups.map(({ id, getHeaderGroupProps, headers }, i) => (
        <tr {...getHeaderGroupProps()} key={`${id}-${i}`}>
          {headers.map(({
            id,
            canFilter,
            isSorted,
            isSortedDesc,
            getHeaderProps,
            getSortByToggleProps,
            render,
            Filter
          }) => (
            <th
              {...getHeaderProps(getSortByToggleProps())}
              style={{
                minWidth: 200
              }}
              key={id}
            >
              <SimpleGrid cols={1} spacing="xs">
                <SimpleGrid cols={2} spacing="xs">
                  <Text size="xs">{render('Header')}</Text>
                  {hasSorting && (
                    isSorted
                      ? (
                        <ActionIcon size="xs">
                          <FontAwesomeIcon
                            icon={['fas', 'arrow-up']}
                            color="silver"
                            size="sm"
                            style={{
                              transform: isSortedDesc
                                ? 'rotate(180deg)'
                                : 'none'
                            }}
                          />
                        </ActionIcon>
                        )
                      : (
                        <ActionIcon size="xs">
                          <FontAwesomeIcon
                            icon={['fas', 'sort-alt']}
                            color="silver"
                            size="sm"
                          />
                        </ActionIcon>
                        )
                  )}
                </SimpleGrid>
                {hasFilters && (
                  <div onClick={e => e.stopPropagation()}>
                    {canFilter && Filter
                      ? render('Filter')
                      : null}
                  </div>
                )}
              </SimpleGrid>
            </th>
          ))}
        </tr>
      ))}
    </>
  )
}
