/* eslint-disable @typescript-eslint/no-unused-vars */
import type mapboxgl from 'mapbox-gl'

// Variables for the pulsating dot
const radius = 90
const size = radius * 2

interface SpeedCtxProps {
  width: number
  height: number
  data: Uint8ClampedArray
  context: CanvasRenderingContext2D | null
  onAdd: () => void
  render: () => void
}
// Function to draw the pulsating dot
export const generateSpeedingDot = (map: mapboxgl.Map): SpeedCtxProps => ({
  width: size,
  height: size,
  data: new Uint8ClampedArray(size * size * 4),
  context: null,
  onAdd: function () {
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height
    this.context = canvas.getContext('2d')
  },
  // Call once before every frame where the icon will be used.
  render: function () {
    const duration = 1000
    const t = (performance.now() % duration) / duration

    const radius = (size / 2) * 0.3
    const outerRadius = (size / 2) * 0.7 * t + radius
    const context = this.context
    if (context) {
      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height)
      context.beginPath()
      context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
      )
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`
      context.fill()

      // Draw the inner circle.
      context.beginPath()
      context.arc(
        this.width / 2,
        this.height / 2,
        radius,
        0,
        Math.PI * 2
      )
      context.fillStyle = 'rgba(255, 100, 100, 1)'
      context.strokeStyle = 'white'
      context.lineWidth = 2 + 4 * (1 - t)
      context.fill()
      context.stroke()

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(
        0,
        0,
        this.width,
        this.height
      ).data

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      map.triggerRepaint()
    }
    // Return `true` to let the map know that the image was updated.
    return true
  }
})
