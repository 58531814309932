import { memo, useEffect } from 'react'
import { Container, ScrollArea } from '@mantine/core'
import {
  getSpeedAlerts,
  type SpeedAlertRequest,
  type SpeedAlert
} from '@venturi-io/api/src/analytics/speedAlerts'
import { usePaginatedApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { useUser } from 'src/UserContext'
import { useSharedStyles } from '../shared'
import Table from './Table'

export interface SpeedAlertHistory {
  data: SpeedAlert[]
}

export interface Props {
  id: string
  name: string
  data: {
    geozoneIds: string[]
    bucket: ChosenBucket<ItemKeys>
  }
}

const MultiAgentSensorHistoryTable = ({
  name,
  data: {
    geozoneIds
  }
}: Props) => {
  const { token, orgId } = useUser()
  const allSpeedAlerts = usePaginatedApi(getSpeedAlerts)

  const { classes } = useSharedStyles()

  const speedAlerts: SpeedAlert[] | null = allSpeedAlerts.data.mapOrDefault(data => data.items, null)

  const isLoading = allSpeedAlerts.loading

  useEffect(() => {
    if (!geozoneIds || geozoneIds.length < 1) return

    const request: SpeedAlertRequest = {
      orgId,
      geoZoneIds: geozoneIds.join(','),
      page: 1,
      size: 999
    }

    void allSpeedAlerts.fetch(request, token)
  }, [geozoneIds])

  return (
    <Container className={classes.itemContainer} fluid>
      {isLoading && <Loader variant="bars" />}
      {!isLoading && (
        <ScrollArea
          style={{
            height: '100%',
            width: '100%',
            background: 'white'
          }}
          type="hover"
          scrollbarSize={5}
        >
          <Table title={name} data={speedAlerts ?? []} />
        </ScrollArea>
      )}
    </Container>
  )
}

export default memo(MultiAgentSensorHistoryTable)
