/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedDuressEvent } from '../DuressReportView'

interface Props {
  report: Report
  data: ParsedDuressEvent[]
  range: Range | null
}

const formatToReadableDate = (date?: string | null) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  report: Report
  range: Range | null
}
const Details = ({ report, range }: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Report type"
      value={report.reportType.name}
    />
    <LabelValue
      label="Period Range"
      value={range
        ? `${formatToReadableDate(range.from)} - ${formatToReadableDate(range.to)}`
        : '--'}
    />
  </View>
)

interface Value {
  value: string | null
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Asset Name" />
    <THCell value="Date" />
    <THCell value="Count" />
    <THCell value="Driver" />
    <THCell value="Group" />
  </View>
)

const TableRow = ({
  asset,
  driver,
  group,
  date,
  count
}: ParsedDuressEvent) => (
  <View style={reportStyles.group}>
    <TDCell value={asset} />
    <TDCell value={date} />
    <TDCell value={count} />
    <TDCell value={driver} />
    <TDCell value={group} />
  </View>
)

interface TableBodyProps {
  data: ParsedDuressEvent[]
}
const TableBody = ({ data }: TableBodyProps) => data.map(item => (
  <TableRow key={item.sensor_instance_id} {...item} />
))

const Space = () => <View style={reportStyles.space} />

function DuressEvents ({ report, data, range }: Props) {
  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details report={report} range={range} />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default DuressEvents
