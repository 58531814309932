import { useEffect, useState, type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Group,
  ScrollArea,
  Table,
  Tooltip
} from '@mantine/core'
import { useApi, type ApiData } from 'src/utils/useApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import Paper from 'src/Layout/Paper'
import NoData from 'src/Layout/TableRowNoData'
import { getAssets } from '@venturi-io/api/src/config/asset'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import CreateAsset from './Create'

function AssetsRow (assets: ApiData<typeof getAssets>): ReactNode {
  const navigate = useNavigate()

  return assets.length > 0
    ? assets.map(asset => {
      const { id, name, assetTypeId } = asset

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{name}</td>
          <td>{assetTypeId}</td>
          <td>
            <Group position="center" spacing="xs">
              <Tooltip
                label="Edit"
                transitionProps={{
                  transition: 'fade',
                  duration: 200
                }}
                withArrow
              >
                <Button
                  onClick={() => {
                    navigate(`/settings/assets/edit/${assetTypeId}`)
                  }}
                  size="sm"
                  color="green"
                  title="Edit"
                  compact
                >
                  <FontAwesomeIcon icon={['fas', 'pencil']} color="white" />
                </Button>
              </Tooltip>
            </Group>
          </td>
        </tr>
      )
    })
    : <NoData />
}

export default function Assets () {
  const assets = useApi(getAssets)
  const [showCreate, setShowCreate] = useState(false)
  const { token } = useUser()

  const loadAssets = async () => {
    void assets.fetch({}, token)
  }

  useEffect(() => {
    void loadAssets()
  }, [])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create Asset"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new asset
        </Button>
      </Group>
      <Paper relative>
        <LoadingOverlay visible={assets.loading} />
        <ScrollArea type="hover" scrollbarSize={5}>
          <Table striped highlightOnHover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {assets.data.mapOrDefault(AssetsRow, <NoData message="" />)}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>
      <CreateAsset
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadAssets}
      />
    </>
  )
}
