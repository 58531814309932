import { Text } from '@mantine/core'
import dayjs from 'dayjs'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { msToDhms, uiDateFormat } from 'src/utils/dates'
import { type Incident } from '../index'

export default function Row ({ ...incident }: Incident & ListRow) {
  const { classes, cx } = useStyles()
  const {
    incidentId,
    name,
    cleared,
    detected,
    sensorInstanceName
  } = incident

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{incidentId}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(detected).format(uiDateFormat)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {cleared ? dayjs(cleared).format(uiDateFormat) : ''}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{sensorInstanceName}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{name}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {cleared ? msToDhms(dayjs(cleared).diff(dayjs(detected))) : 'Active'}
        </Text>
      </div>
    </div>
  )
}
