import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { msToDhms, uiDateFormat } from 'src/utils/dates'
import { type Incident } from '../index'

export default function Card ({ ...incident }: Incident & ListRow) {
  const {
    incidentId,
    name,
    cleared,
    detected,
    sensorInstanceName
  } = incident

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={incidentId.toString()} isKey />
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup label="Start" value={dayjs(detected).format(uiDateFormat)} maxLength={25} />
          <TextGroup label="End" value={cleared ? dayjs(cleared).format(uiDateFormat) : ''} />
          <TextGroup label="Sensor" value={sensorInstanceName} />
          <TextGroup label="Alarm" value={name} />
          <TextGroup label="Duration" value={cleared ? msToDhms(dayjs(cleared).diff(dayjs(detected))) : 'Active'} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
