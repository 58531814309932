import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import Tasks from 'src/Tasks'
import { useEffect } from 'react'
import { ScrollArea } from '@mantine/core'
import Location from '../shared/Location'
import { findMissingSensors, findSensorByType } from '../shared'
import Transactions from './Transactions'
import { MODULE_CONTENT } from './constants'
import Details from './Details'
import Status from './Status'
import Trips from './Trips'
import TankHistory from './TankHistory'
import AuditLog from './AuditLog'

interface Props {
  agent: AgentDetails
  contentKey: string
  setMissingSensors: (status: string[]) => void
  didChangeHeight: (height: number, key: string) => void
}

export default function ModuleContainer ({
  agent,
  contentKey,
  setMissingSensors,
  didChangeHeight
}: Props) {
  const {
    agentId,
    agentName,
    sensors
  } = agent

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fuelLevelLiter = findSensorByType('FUEL_LEVEL_L', sensors)
  const positionSpeed = findSensorByType('POSITION_SPEED', sensors)

  useEffect(() => {
    const missingSensors = findMissingSensors([
      'BATTERY_VOLTAGE',
      'POSITION_SPEED',
      'ENGINE_IGNITION_STATUS',
      'IMMOBILISATION_STATUS'
    ], sensors)

    setMissingSensors(missingSensors)
  }, [sensors])

  switch (contentKey) {
    case MODULE_CONTENT.VEHICLE_DETAILS:
      return <Details agent={agent} />
    case MODULE_CONTENT.VEHICLE_STATUS:
      return <Status agent={agent} />
    case MODULE_CONTENT.VEHICLE_LOCATION: {
      const { geoLocation } = agent
      return (
        <Location
          title={MODULE_CONTENT.VEHICLE_LOCATION}
          agentLocation={geoLocation}
        />
      )
    }
    case MODULE_CONTENT.VEHICLE_TRIP: {
      return (
        <ScrollArea
          offsetScrollbars
          scrollbarSize={5}
          style={{ height: '100%' }}
        >
          <Trips
            agent={agent}
            didChangeHeight={didChangeHeight}
          />
        </ScrollArea>
      )
    }
    case MODULE_CONTENT.VEHICLE_TRANSACTIONS: {
      const { agentId } = agent
      return (
        <Transactions
          agentId={agentId}
          didChangeHeight={didChangeHeight}
        />
      )
    }
    case MODULE_CONTENT.VEHICLE_TANK_HISTORY:
      return fuelLevelLiter && positionSpeed && (
        <TankHistory
          agentId={agentId}
          sensorInstanceIds={[
            fuelLevelLiter.sensorInstanceId,
            positionSpeed.sensorInstanceId
          ]}
          didChangeHeight={didChangeHeight}
        />
      )
    case MODULE_CONTENT.VEHICLE_TASKS: {
      return (
        <ScrollArea
          offsetScrollbars
          scrollbarSize={5}
          style={{
            height: '100%'
          }}
        >
          <Tasks
            hideShadow
            agentId={agentId}
            agentName={agentName}
            didChangeHeight={didChangeHeight}
            title={MODULE_CONTENT.VEHICLE_TASKS}
            hideToolbar
          />
        </ScrollArea>
      )
    }
    case MODULE_CONTENT.VEHICLE_AUDIT_LOG: {
      return (
        <AuditLog
          type="ODOMETER"
          agentId={agentId}
          didChangeHeight={didChangeHeight}
        />
      )
    }
    default:
      return null
  }
}
