import { Text } from '@mantine/core'
import { type Role } from '@venturi-io/api/src/userManager/role'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'

export default function Row ({ ...role }: Role & ListRow) {
  const { classes, cx } = useStyles()
  const { roleId, name } = role

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{roleId}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{name}</Text>
      </div>
    </div>
  )
}
