import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import {
  type PaginatedRequest,
  type DashboardIdRequest,
  type DashboardItemIdRequest,
  paginated
} from '../shared'

const dashboardItem = z.object({
  dashboardItemId: z.number(),
  dashboardId: z.number(),
  name: z.string(),
  type: z.string(),
  properties: z.record(z.string(), z.unknown())
})

const dashboardItems = z.array(dashboardItem)

export type DashboardItem = z.infer<typeof dashboardItem>

const dashboard = z.object({
  dashboardId: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  ownerOrgUserId: z.number(),
  isPrivate: z.boolean(),
  isDefault: z.optional(z.boolean()),
  isFavourite: z.optional(z.boolean()),
  active: z.boolean(),
  layouts: z.record(z.string(), z.unknown()),
  dashboardItems: z.optional(dashboardItems)
})

const paginatedDashboards = paginated.extend({
  items: z.array(dashboard)
})

const userDashboard = dashboard.pick({
  dashboardId: true,
  name: true,
  description: true,
  isPrivate: true,
  isDefault: true,
  isFavourite: true
})

const userDashboardDetails = dashboard.pick({
  dashboardId: true,
  name: true,
  description: true
})

const userDashboards = z.array(userDashboard)

export type Dashboard = z.infer<typeof dashboard>

// Dashboard
export interface DashboardRequest extends Record<string, unknown> {
  name: string
  description?: string
  isPrivate: boolean
  layouts: Record<string, unknown>
}

export const createDashboard = createStatefulApi<DashboardRequest, typeof dashboard>(
  'user',
  '/dashboard',
  { method: 'POST' },
  dashboard
)

interface GetDashboardsRequest extends PaginatedRequest {
  privateOnly: boolean
}
export const getDashboards = createStatelessApi<GetDashboardsRequest, typeof paginatedDashboards>(
  'user',
  '/dashboard/all',
  { method: 'GET', type: 'path' },
  paginatedDashboards
)

export const getDashboard = createStatelessApi<DashboardIdRequest, typeof dashboard>(
  'user',
  '/dashboard/:dashboardId',
  { method: 'GET', type: 'path' },
  dashboard
)

export const updateDashboard = createStatefulApi<DashboardIdRequest & DashboardRequest, typeof dashboard>(
  'user',
  '/dashboard/:dashboardId',
  { method: 'PUT' },
  dashboard
)

export const deleteDashboard = createStatefulApi<DashboardIdRequest, z.ZodBoolean>(
  'user',
  '/dashboard/:dashboardId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

export const getUserDashboards = createStatelessApi<Record<string, unknown>, typeof userDashboards>(
  'user',
  '/dashboard/my-dashboards',
  { method: 'GET', type: 'path' },
  userDashboards
)

interface SetDefaultDashboardRequest extends DashboardIdRequest {
  remove: boolean
}

export const setDefaultDashboard = createStatefulApi<SetDefaultDashboardRequest, typeof userDashboardDetails>(
  'user',
  '/dashboard/default',
  { method: 'POST' },
  userDashboardDetails
)

interface SetFavouriteDashboardRequest extends DashboardIdRequest {
  isFavourite: boolean
}

export const setFavouriteDashboard = createStatefulApi<SetFavouriteDashboardRequest, typeof userDashboardDetails>(
  'user',
  '/dashboard/favourite',
  { method: 'POST' },
  userDashboardDetails
)

// Dashboard Item
export interface DashboardItemRequest extends DashboardIdRequest {
  name: string
  type: string
  properties: Record<string, unknown>
}

export const createDashboardItem = createStatefulApi<DashboardItemRequest, typeof dashboardItem>(
  'user',
  '/dashboard/:dashboardId/item',
  { method: 'POST' },
  dashboardItem
)

export const getDashboardItems = createStatelessApi<DashboardIdRequest, typeof dashboardItems>(
  'user',
  '/dashboard/:dashboardId/items',
  { method: 'GET', type: 'path' },
  dashboardItems
)

export const getDashboardItem = createStatelessApi<DashboardItemIdRequest, typeof dashboardItem>(
  'user',
  '/dashboard/item/:dashboardItemId',
  { method: 'GET', type: 'path' },
  dashboardItem
)

export const updateDashboardItem = createStatefulApi<Omit<DashboardItemRequest, 'dashboardId'> & DashboardItemIdRequest, typeof dashboardItem>(
  'user',
  '/dashboard/item/:dashboardItemId',
  { method: 'PUT' },
  dashboardItem
)

export const deleteDashboardItem = createStatefulApi<DashboardItemIdRequest, z.ZodBoolean>(
  'user',
  '/dashboard/item/:dashboardItemId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
