import { DatePickerInput, type DatePickerInputProps } from '@mantine/dates'
import { useSelectStyles } from './styles'

export default function DateRangePicker (props: DatePickerInputProps<'range'>) {
  const { classes } = useSelectStyles()

  return (
    <DatePickerInput
      color="primary"
      type="range"
      classNames={classes}
      {...props}
    />
  )
}
