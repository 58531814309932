import { type ReactElement } from 'react'
import { IconEngine, IconClockStop } from '@tabler/icons-react'
import StatusBadge from 'src/AssetTemplate/Components/StatusBadge'

export type Mode = 'Auto' | 'Stopped' | 'Other'

interface Props {
  mode: Mode
  expanded?: boolean
  showTitle?: boolean
}

const modeIcon: Record<Mode, ReactElement> = {
  Auto: <IconEngine size={20} />,
  Stopped: <IconClockStop size={20} />,
  Other: <IconClockStop size={20} />
}

export default function ControlMode ({ mode, expanded, showTitle }: Props) {
  return (
    <StatusBadge
      title="Control Mode"
      icon={modeIcon[mode]}
      color="gray.4"
      textColor="gray"
      value={mode}
      showTitle={showTitle}
      expanded={expanded}
    />
  )
}
