import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import NotFound from 'src/Router/NotFound'
import Form from './Form'

interface RouteParams extends Record<string, string | undefined> {
  deviceId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { deviceId: id } = useParams<RouteParams>()
  const { classes } = useStyles()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }
  return (
    <Paper className={classes.container} relative>
      <Form
        mode="EDIT"
        deviceId={parseInt(id)}
      />
    </Paper>
  )
}
