import {
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { type AssetType, type Sensor } from '@venturi-io/api/src/config/agent'
import { createAgentTemplate } from '@venturi-io/api/src/config/agentTemplate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import SelectAssetType from 'src/Input/Select/SelectAssetType'
import MultiSelectSensor from 'src/Input/MultiSelect/MultiSelectSensor'

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

interface FormProps {
  sensorIds: Array<Sensor['id']>
  assetType?: AssetType
}

export default function Create ({ show, close, onCreate }: Props) {
  const create = useApi(createAgentTemplate)
  const { token } = useUser()

  const form = useForm<FormProps>({
    initialValues: {
      sensorIds: []
    },
    validate: {
      sensorIds: ids => ids.length === 0
        ? 'Please choose sensor(s)'
        : null,
      assetType: value => typeof value === 'undefined' || value.length === 0
        ? 'Please choose asset type'
        : null
    }
  })

  const handleSubmit = (values: typeof form.values) => {
    if (values.assetType) {
      const { assetType, sensorIds } = values
      void create
        .fetch({
          assetType,
          sensors: sensorIds.map(id => ({ id }))
        }, token, 'Successfully created new agent template')
        .finally(() => {
          form.reset()
          void onCreate()
          close()
        })
    }
  }

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Agent Template</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Col span={12}>
            <SelectAssetType
              label="Asset Type"
              {...form.getInputProps('assetType')}
              value={form.values.assetType?.toString()}
              required
            />
          </Col>
          <Col span={12}>
            <MultiSelectSensor
              label="Sensors"
              value={form.values.sensorIds.map(id => id.toString())}
              onChange={sensorIds => {
                form.setFieldValue(
                  'sensorIds',
                  sensorIds.map(id => Number(id))
                )
              }}
              required
              searchable
              error={form.errors.sensorIds}
            />
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={() => {
                  form.reset()
                  close()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={create.loading}
              >
                Create
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
