import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type Role } from '@venturi-io/api/src/userManager/role'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'

export default function Row ({ roleId, name }: Role & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{roleId}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={parseEmpty(name).toString()}
          maxLength={30}
          size="sm"
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/roles/edit/${roleId}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
