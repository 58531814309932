import { createStyles, Box, Stack, Text } from '@mantine/core'
import GaugeComponent from 'react-gauge-component'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { getSensorValue, convertStrToFloat } from 'src/AssetTemplate/shared'

const useStyles = createStyles(theme => ({
  container: {
    position: 'relative',
    margin: '0 auto',
    width: 200,
    minWidth: 0,
    height: 200,
    [mq(theme.breakpoints.md)]: {
      width: 180,
      height: 180
    }
  },
  label: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 12,
    width: 60,
    background: 'white',
    [mq(theme.breakpoints.md)]: {
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 4
    }
  }
}))

interface Props {
  sensor: Sensor | null
}

export default function EngineSpeed ({ sensor }: Props) {
  const { classes } = useStyles()
  const engineSpeedValue = Math.floor(convertStrToFloat(sensor?.currentValue))

  return (
    <Box className={classes.container}>
      <Stack className={classes.label} spacing={0}>
        <Text
          color="#2C2E33"
          size={24}
          weight={600}
          align="center"
        >
          {sensor
            ? getSensorValue(sensor, true, false)
            : 0}
        </Text>
        <Text
          color="#2C2E33"
          size={12}
          weight={500}
          align="center"
        >
          RPM
        </Text>
      </Stack>
      <GaugeComponent
        value={engineSpeedValue}
        type="radial"
        minValue={0}
        maxValue={3000}
        arc={{
          cornerRadius: 0,
          width: 0.35,
          padding: 0.01,
          subArcs: [
            {
              limit: 1350,
              color: '#F03E3E',
              showTick: false
            },
            {
              limit: 1425,
              color: '#FFE066',
              showTick: false
            },
            {
              limit: 1575,
              color: '#62E5BD',
              showTick: false
            },
            {
              limit: 1650,
              color: '#FFE066',
              showTick: false
            },
            {
              color: '#F03E3E',
              showTick: false
            }
          ]
        }}
        labels={{
          tickLabels: {
            type: 'outer'
          }
        }}
        pointer={{
          type: 'needle',
          elastic: true,
          animationDelay: 0
        }}
      />
    </Box>
  )
}
