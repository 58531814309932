import { Alert } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HomeDashboardMessage = () => (
  <Alert title="Home Dashboard" icon={<FontAwesomeIcon icon={['far', 'info-circle']} />}>
    {'You haven\'t set your Home Dashboard yet. Please choose one by clicking the "Set as home" button.'}
  </Alert>
)

export default HomeDashboardMessage
