import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import dayjs from 'dayjs'
import { deleteTask, type Task } from '@venturi-io/api/src/config/task'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useApi } from 'src/utils/useApi'
import TextTooltip from 'src/Layout/TextTooltip'
import { parseEmpty } from 'src/utils/parseEmpty'
import { useUser } from 'src/UserContext'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import ActionButton from 'src/ActionButton'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import { getCurrentBreakpoint } from 'src/utils/style'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import { type Actions } from '.'

export default function Row ({
  id,
  agent,
  taskType,
  priority,
  assignedTo,
  dueDate,
  status,
  actions
}: Task & ListRow<Actions>) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const removeTask = useApi(deleteTask)
  const [showDelete, setShowDelete] = useState(false)
  const { token } = useUser()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiPath, setApiPath] = useState('')
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  const handleDelete = useCallback(() => {
    void removeTask
      .fetch({ taskId: id }, token, 'Succesfully deleted task')
      .finally(() => {
        void actions.loadTasks()
      })
  }, [id])

  useEffect(() => {
    void configure(getHost())
      .then(config => {
        setApiPath(config.user)
      })
  }, [])

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <NeedsRole role={adminRoles}>
        <div
          className={cx(classes.col, classes.idCol)}
          style={{ minWidth: '40px' }}
        >
          <Text size="sm">{id}</Text>
        </div>
      </NeedsRole>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent.agentName)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(agent.assetType)}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(taskType)}
          maxLength={10}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(priority)}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(`${assignedTo.firstName} ${assignedTo.lastName}`)}
          maxLength={10}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(dueDate, dateFormat).format(uiDateFormatRegular)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(status)}</Text>
      </div>
      <div className={classes.col}>
        <NeedsRole role={adminRoles}>
          <ActionButton
            label="Details"
            icon="file-text"
            iconType="fas"
            onClick={() => navigate(`/tasks/details/${id}`)}
            actionColor="blue"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/tasks/edit/${id}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
          <ActionButton
            label="Delete"
            icon="trash-can"
            iconType="fas"
            onClick={() => setShowDelete(true)}
            actionColor="red"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
            actionDisabled={false}
          />
        </NeedsRole>
      </div>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting task for "${agent.agentName}"`}
        loading={removeTask.loading}
        question="Are you sure you want to delete this task? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </div>
  )
}
