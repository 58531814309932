import { type LineSvgProps, ResponsiveLine } from '@nivo/line'

interface Props extends LineSvgProps {
  height: number
}

export default function Line ({ height, ...props }: Props) {
  return (
    <div style={{ height }}>
      <ResponsiveLine {...props} />
    </div>
  )
}
