import { type Layouts } from 'react-grid-layout'
import { createContext } from 'react'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { type SensorData } from '@venturi-io/api/src/collector/sensor'
import { MODULE_CONTENT } from './constants'

const sizes = {
  w: 12,
  h: 1.4,
  isDraggable: false,
  isResizable: false,
  static: false
}

const initialLayout = [
  { i: MODULE_CONTENT.POWER_CURRENT, ...sizes, x: 0, y: 0 },
  { i: MODULE_CONTENT.POWER_STATUS, ...sizes, x: 0, y: 1.4, h: 0.9 },
  { i: MODULE_CONTENT.POWER_TOTALS, ...sizes, x: 0, y: 2.3, h: 0.6 },
  { i: MODULE_CONTENT.POWER_GRAPH_STACKED_KILOWATTS, ...sizes, x: 0, y: 3.2 },
  { i: MODULE_CONTENT.POWER_GRAPH_STACKED_CURRENT, ...sizes, x: 0, y: 4.6 },
  { i: MODULE_CONTENT.POWER_GRAPH_SINGLE_POWER_FACTOR, ...sizes, x: 0, y: 6 },
  { i: MODULE_CONTENT.POWER_GRAPH_SINGLE_LL, ...sizes, x: 0, y: 7.4 },
  { i: MODULE_CONTENT.POWER_GRAPH_SINGLE_LN, ...sizes, x: 0, y: 8.8 },
  { i: MODULE_CONTENT.POWER_GRAPH_SINGLE_POSITIVE_ACTIVE, ...sizes, x: 0, y: 10.2 },
  { i: MODULE_CONTENT.POWER_GRAPH_SINGLE_NEGATIVE_ACTIVE, ...sizes, x: 0, y: 11.6 }
]

export const defaultLayouts: Layouts = {
  xl: initialLayout,
  lg: initialLayout,
  md: initialLayout,
  sm: initialLayout,
  xs: initialLayout,
  xxs: initialLayout
}

export interface TimeBucketProps {
  bucket: ChosenBucket<ItemKeys>
  updateBucket: (input: ChosenBucket<ItemKeys>) => void
  sensorData: SensorData[]
  loading: boolean
}

export const TimeBucketContext = createContext<TimeBucketProps>({
  bucket: {
    item: '30 days',
    data: {
      timeBucket: '12 hours',
      noOfRecords: 60
    }
  },
  updateBucket: () => {},
  sensorData: [],
  loading: false
})
