import { memo, useCallback, useMemo } from 'react'
import { useMantineTheme, Accordion, Button, Stack } from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimePeriodSelector from 'src/Input/TimePeriodSelector'
import MultiSelectAgentSensor from 'src/Input/MultiSelect/MultiSelectAgentSensor'
import ItemPreview from '../shared/ItemPreview'
import MuiltiAgentSensorHistory, { type Props as MuiltiAgentSensorHistoryProps } from '.'

interface Props {
  initialValues: MuiltiAgentSensorHistoryProps | null
  onSubmit: (properties: MuiltiAgentSensorHistoryProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const { colors } = useMantineTheme()
  const focusTrapRef = useFocusTrap()

  const formValues: MuiltiAgentSensorHistoryProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        sensorInstanceIds: [],
        bucket: {
          item: '7 days',
          data: {
            timeBucket: '1 hour',
            noOfRecords: 24
          }
        }
      }
    }
  ), [initialValues, colors])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const parsedSensorInstanceIds = useMemo(() => (
    form.values.data.sensorInstanceIds.map(id => id.toString())
  ), [form.values.data.sensorInstanceIds])

  const hasSelectedSensor = useMemo(() => (
    form.values.data.sensorInstanceIds.length > 0
  ), [form.values.data.sensorInstanceIds])

  const previewItem = useMemo(() => hasSelectedSensor, [hasSelectedSensor])

  const setFormDataValue = useCallback((
    key: keyof MuiltiAgentSensorHistoryProps['data'],
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="table" previewItem={previewItem}>
        <MuiltiAgentSensorHistory {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <MultiSelectAgentSensor
                    label="Sensors"
                    value={parsedSensorInstanceIds}
                    onChange={sensorInstanceIds => {
                      setFormDataValue(
                        'sensorInstanceIds',
                        sensorInstanceIds.map(id => Number(id))
                      )
                    }}
                    required
                    searchable
                    error={form.errors.sensorInstanceIds}
                  />
                  {hasSelectedSensor && (
                    <TimePeriodSelector
                      required
                      label="Time Period"
                      chosenBucket={form.values.data.bucket}
                      updateBucket={bucket => setFormDataValue('bucket', bucket)}
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
