import { unparse } from 'papaparse'

const download = (blob: Blob, fileName: string) => {
  try {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(a.href)
  } catch (e: any) {
    console.error('Failed to download file')
  }
}

export const downloadCSV = (array: Array<Record<string, unknown>>, fileName: string) => {
  const csv = unparse(array)
  download(
    new Blob([csv], { type: 'text/csv' }),
    fileName
  )
}
