import {
  Button,
  Drawer,
  Group,
  Stack,
  Title
} from '@mantine/core'
import {
  createModbusSlave,
  getModbusDevices,
  type ModbusDevice
} from '@venturi-io/api/src/config/modbus'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import Select from 'src/Input/Select'
import TextInput from 'src/Input/TextInput'
import { useUser } from 'src/UserContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'

interface Props {
  show: boolean
  close: () => void
  onSuccess: () => void
  agentId: string | null
}

const modbusToSelect = ({ id, name }: ModbusDevice) => ({
  value: id.toString(),
  label: name
})

export default function CreateModbus ({ show, close, agentId, onSuccess }: Props) {
  const modbus = usePaginatedApi(getModbusDevices)
  const slave = useApi(createModbusSlave)
  const { token } = useUser()
  const [modbusId, setModbusId] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      slaveId: ''
    }
  })

  const selectModbus = modbus.data.mapOrDefault(({ items }) => (
    items.map(modbusToSelect)
  ), [])

  const loadAgents = () => {
    void modbus.fetch({ page: 1, size: 999 }, token)
  }

  const handleCreateSlave = useCallback(async () => {
    if (!agentId || !modbusId || !form.values.slaveId) return
    await slave.fetch({
      agentId: parseInt(agentId),
      slaveId: parseInt(form.values.slaveId),
      modbusDeviceId: parseInt(modbusId)
    }, token, 'Successfully created modbus slave')
    form.reset()
    setModbusId(null)
    onSuccess()
    close()
  }, [form.values.slaveId, agentId, modbusId])

  useEffect(() => {
    void loadAgents()
  }, [])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create Modbus slave</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <Stack>
        <Select
          required
          searchable
          disabled={modbus.loading}
          label="Choose a modbus device"
          value={modbusId}
          data={selectModbus}
          onChange={item => {
            setModbusId(item)
          }}
        />
        <TextInput
          required
          label="Slave Id"
          value={form.values.slaveId}
          onChange={ev => form.setFieldValue('slaveId', ev.currentTarget.value)}
        />
        <Group position="right">
          <Button
            fullWidth
            disabled={!modbusId || !form.values.slaveId}
            loading={slave.loading}
            onClick={handleCreateSlave}
          >
            Create slave
          </Button>
        </Group>
      </Stack>
    </Drawer>
  )
}
