import { memo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import GeoZoneMap, { type Props as GeoZoneMapProps } from 'src/Maps/GeoZoneMap'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data?: GeoZoneMapProps['filters']
  options?: GeoZoneMapProps['options']
}

const Map = ({ data, options }: Props) => {
  const { ref } = useElementSize()
  const { classes } = useSharedStyles()

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      <GeoZoneMap
        fullView
        filters={data}
        options={options}
      />
    </Container>
  )
}

export default memo(Map)
