import { ActionIcon, Group, Text, Tooltip } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findSensorByType } from 'src/Assets/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import TextTooltip from 'src/Layout/TextTooltip'
import { getCurrentBreakpoint } from 'src/utils/style'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import Link from 'src/Layout/Link'
import ImmobiliseButton from 'src/Buttons/ImmobiliseButton'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime,
  hasImmobiliser
}: Agent & ListRow) {
  const { classes } = useStyles()
  const batteryVoltage = findSensorByType('BATTERY_VOLTAGE', sensors)
  const extPowerSourceVoltage = findSensorByType('EXT_POWER_SOURCE_VOLTAGE', sensors)
  const positionDirection = findSensorByType('POSITION_DIRECTION', sensors)
  const positionSpeed = findSensorByType('POSITION_SPEED', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/vehicle/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div className={classes.col}>
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {batteryVoltage?.currentValue
            ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {extPowerSourceVoltage?.currentValue
            ? `${extPowerSourceVoltage.currentValue} ${extPowerSourceVoltage.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(positionDirection?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(positionSpeed?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Group spacing={7}>
          <Link to={`/assets/vehicle/${agentId}/transactions`}>
            <Tooltip
              label="View Transactions"
              position="top"
              transitionProps={{
                transition: 'pop',
                duration: 200
              }}
              withArrow
            >
              <ActionIcon color="primary" variant="transparent">
                <FontAwesomeIcon icon={['far', 'receipt']} size="lg" />
              </ActionIcon>
            </Tooltip>
          </Link>
          {hasImmobiliser && (
            <ImmobiliseButton
              agentId={agentId}
              onSuccess={() => {
                window.location.reload()
              }}
            />
          )}
        </Group>
      </div>
    </div>
  )
}
