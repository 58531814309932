import { IconBell } from '@tabler/icons-react'
import { Accordion } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'

interface Props {
  sensors: Sensor[]
}

export default function Analytics ({ sensors }: Props) {
  return sensors.length > 0 && (
    <Accordion
      multiple
      defaultValue={[sensors[0].sensorInstanceId.toString()]}
      variant="contained"
      radius="xs"
      sx={{
        background: 'white'
      }}
    >
      {sensors.map(({
        name,
        sensorInstanceId,
        unit
      }) => (
        <Accordion.Item key={`${name}-${sensorInstanceId}`} value={sensorInstanceId.toString()}>
          <Accordion.Control icon={<IconBell size={16} color="#228BE6" />}>
            {name}
          </Accordion.Control>
          <Accordion.Panel>
            <SensorHistoryLineChart
              title="History"
              sensorInstanceId={sensorInstanceId}
              unit={unit}
              height={300}
              margin={{
                top: 30,
                right: 20,
                bottom: 50,
                left: 40
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
