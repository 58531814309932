import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Badge,
  Group,
  Indicator,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Col,
  Grid
} from '@mantine/core'
import { findUserById, updateUser as updateUserReq } from '@venturi-io/api/src/userManager/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useNavigate } from 'react-router-dom'
import { getOrg } from '@venturi-io/api/src/userManager/org'
import SelectTimezone from './Input/Select/SelectTimezone'
import { useApi } from './utils/useApi'

interface UserTz {
  timezone?: string
}

const UserProfile = () => {
  const updateUser = useApi(updateUserReq)
  const {
    firstName,
    lastName,
    email,
    status,
    orgName,
    roles,
    token,
    orgUserId,
    orgId,
    signout
  } = useUser()
  const org = useApi(getOrg)
  const findUser = useApi(findUserById)
  const orgData = org.data.mapOrDefault((data) => data, null)
  const user = findUser.data.mapOrDefault(data => data, null)
  const [userTz, setUserTz] = useState<string | undefined>()
  const navigate = useNavigate()

  const redirectAccountVerificationPage = useCallback(() => {
    signout()
    navigate('/accountVerification')
  }, [])

  const handleSubmit = ({
    timezone
  }: UserTz) => {
    if (updateUser.loading) return // Short-circuit
    void updateUser
      .fetch({
        orgUserId,
        firstName,
        lastName,
        email,
        status,
        orgName,
        roles,
        jobTitle: user?.jobTitle ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        timezone
      }, token, 'Saved successfully!')
  }

  useEffect(() => {
    void org.fetch({ orgId }, token)
  }, [orgId])

  useEffect(() => {
    void findUser.fetch({ orgUserId }, token)
  }, [orgUserId])

  useEffect(() => {
    if (user?.defaultTimezone) {
      setUserTz(user?.defaultTimezone)
    } else {
      setUserTz(orgData?.defaultTimezone)
    }
  }, [findUser.data, org.data])

  return (
    <Paper p="xl" shadow="md">
      <Stack align="flex-start" justify="flex-start">
        <SimpleGrid
          cols={1}
          sx={{
            width: '100%'
          }}
          breakpoints={[
            { cols: 2, verticalSpacing: 'lg' },
            { cols: 1, spacing: 'lg' }
          ]}
        >
          <Stack align="flex-start" justify="flex-start" spacing={35}>
            <Box>
              <Text weight="bold">Name</Text>
              <Text>{`${firstName} ${lastName}`}</Text>
            </Box>
            <Box>
              <Text weight="bold">Email Address</Text>
              <Text>{email}</Text>
            </Box>
            <Box>
              <Text weight="bold">Status</Text>
              <Group ml="xs" spacing="xs">
                <Indicator position="middle-start" color={status === 'ACTIVE' ? 'green' : 'gray'}>
                  <span />
                </Indicator>
                <Text>{status}</Text>
              </Group>
            </Box>
          </Stack>
          <Stack align="flex-start" justify="flex-start" spacing={35}>
            <Box>
              <Text weight="bold">Organisation</Text>
              <Text>{orgName}</Text>
            </Box>
            <Box>
              <Text weight="bold">Roles</Text>
              <Group spacing={8} align="flex-start" position="left">
                {roles.map(role => (
                  <Badge key={role} color="primary" radius="sm">
                    {role}
                  </Badge>
                ))}
              </Group>
            </Box>
            <Box>
              <Grid grow>
                <Col span={12}>
                  <Text weight="bold" mb={2}>Time zone</Text>
                  <SelectTimezone
                    searchable
                    isOrgTimezone={orgData?.defaultTimezone === user?.defaultTimezone}
                    value={userTz}
                    onChange={(timezone) => {
                      setUserTz(timezone?.toString() ?? '')
                      handleSubmit({ timezone: timezone?.toString() })
                    }}
                  />
                </Col>
              </Grid>
            </Box>
          </Stack>
        </SimpleGrid>
        <Button
          type="button"
          color="red"
          leftIcon={<FontAwesomeIcon
            icon={['fas', 'rotate-left']}
            color="white"
                    />}
          onClick={redirectAccountVerificationPage}
        >
          Reset Password
        </Button>
      </Stack>
    </Paper>
  )
}

export default UserProfile
