import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type OnboardingStatus, onboardingStatuses } from './auth'
import {
  type PaginatedRequest,
  type OrgUserIdRequest,
  type OrgIdRequest,
  type UserIdRequest,
  userStatus,
  paginated
} from '../shared'

export const user = z.object({
  userId: z.number(),
  orgUserId: z.number(),
  email: z.optional(z.string().email()),
  status: userStatus,
  firstName: z.optional(z.string()),
  lastName: z.optional(z.string()),
  roles: z.array(z.string()),
  jobTitle: z.optional(z.string()),
  phoneNumber: z.optional(z.string()),
  signUpDate: z.optional(z.string()),
  invitedDate: z.optional(z.string()),
  activatedDate: z.optional(z.string()),
  lastLoginDate: z.optional(z.string()),
  emergencyContact1Name: z.optional(z.string()),
  emergencyContact1PhoneNumber: z.optional(z.string()),
  emergencyContact2Name: z.optional(z.string()),
  emergencyContact2PhoneNumber: z.optional(z.string()),
  defaultTimezone: z.optional(z.string())
})

export type User = z.infer<typeof user>

const allUsers = z.array(user)

const inactiveUser = z.object({
  userId: z.number(),
  orgId: z.number(),
  orgUserId: z.number(),
  orgName: z.string(),
  email: z.string(),
  status: z.string(),
  roles: z.array(z.string()),
  firstName: z.string(),
  lastName: z.string(),
  jobTitle: z.optional(z.string()),
  phoneNumber: z.optional(z.string())
})

const activatedUser = inactiveUser.extend({
  firstName: z.string(),
  lastName: z.string(),
  onboardingStatus: z.enum(onboardingStatuses),
  'X-Auth-Token': z.string()
})

export type AllUsers = z.infer<typeof allUsers>

const paginatedUsers = paginated.extend({
  items: allUsers
})

interface AllUsersRequest extends PaginatedRequest {
  orgId: number
  search?: string
}

export const getUsers = createStatelessApi<AllUsersRequest, typeof paginatedUsers>(
  'user',
  '/org/:orgId/users',
  { method: 'GET', type: 'path' },
  paginatedUsers
)

interface UsersForUserGroupRequest extends PaginatedRequest {
  userGroupId: number
}

export const getUsersForUserGroup = createStatelessApi<UsersForUserGroupRequest, typeof paginatedUsers>(
  'user',
  '/user-groups/:userGroupId/users',
  { method: 'GET', type: 'path' },
  paginatedUsers
)

export const roleSummary = z.object({
  roleId: z.number(),
  name: z.string(),
  orgId: z.number()
})

// Get all roles with privileges
export const privilege = z.object({
  privilegeId: z.number(),
  privilegeName: z.string(),
  roles: z.optional(z.array(roleSummary))
})

const role = z.object({
  roleId: z.number(),
  name: z.string(),
  privileges: z.array(privilege)
})

const allRolesAndPrivileges = paginated.extend({
  items: z.array(role)
})

export const getRolesAndPrivileges = createStatelessApi<PaginatedRequest, typeof allRolesAndPrivileges>(
  'user',
  '/roles/privileges',
  { method: 'GET', type: 'path' },
  allRolesAndPrivileges
)

// Invite user
const inviteUsersResponse = z.object({
  users: z.array(z.object({
    userId: z.number(),
    orgUserId: z.optional(z.number()),
    email: z.string().email(),
    status: userStatus,
    roles: z.optional(z.array(z.string())),
    firstName: z.string(),
    lastName: z.string(),
    orgId: z.number(),
    orgName: z.string(),
    signUpDate: z.string(),
    invitedDate: z.string(),
    jobTitle: z.optional(z.string()),
    phoneNumber: z.optional(z.string()),
    emergencyContact1Name: z.optional(z.string()),
    emergencyContact1PhoneNumber: z.optional(z.string()),
    emergencyContact2Name: z.optional(z.string()),
    emergencyContact2PhoneNumber: z.optional(z.string())
  })),
  error: z.optional(
    z.object({
      code: z.number(),
      messages: z.array(z.string())
    })
  )
})

interface InviteUsersRequest extends Record<string, unknown> {
  orgId: number
  users: Array<{
    email: string
    firstName: string
    lastName: string
    jobTitle?: string
    phoneNumber?: string
    emergencyContact1Name?: string
    emergencyContact1PhoneNumber?: string
    emergencyContact2Name?: string
    emergencyContact2PhoneNumber?: string
  }>
  roles?: string[]
  emailBody?: string
  backOfficeRole?: string
}

export const inviteUsers = createStatefulApi<InviteUsersRequest, typeof inviteUsersResponse>(
  'user',
  '/users/invite',
  { method: 'POST' },
  inviteUsersResponse
)

interface InviteUserRequest extends Record<string, unknown> {
  email: string
}

export const inviteUser = createStatefulApi<InviteUserRequest, typeof user>(
  'user',
  '/users/invite/:orgUserId',
  { method: 'POST' },
  user
)

// Create a basic user (without contact info and role)
const basicUserResponse = z.object({
  orgUserId: z.number(),
  orgId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  jobTitle: z.optional(z.string()),
  phoneNumber: z.optional(z.string()),
  emergencyContact1Name: z.optional(z.string()),
  emergencyContact1PhoneNumber: z.optional(z.string()),
  emergencyContact2Name: z.optional(z.string()),
  emergencyContact2PhoneNumber: z.optional(z.string())
})
interface CreateBasicUserRequest extends Record<string, unknown> {
  orgId: number
  firstName: string
  lastName: string
  jobTitle?: string
  phoneNumber?: string
  emergencyContact1Name?: string
  emergencyContact1PhoneNumber?: string
  emergencyContact2Name?: string
  emergencyContact2PhoneNumber?: string
}

export const createBasicUser = createStatefulApi<CreateBasicUserRequest, typeof basicUserResponse>(
  'user',
  '/users',
  { method: 'POST' },
  basicUserResponse
)

export const findUserById = createStatelessApi<OrgUserIdRequest, typeof user>(
  'user',
  '/orgUser/:orgUserId',
  { method: 'GET', type: 'path' },
  user
)

interface FindUserByActivationIdRequst extends Record<string, unknown> {
  activationId: string
}

export const findUserByActivationId = createStatelessApi<FindUserByActivationIdRequst, typeof inactiveUser>(
  'user',
  '/user/activationId/:activationId',
  { method: 'GET', type: 'path' },
  inactiveUser
)

interface ActivateUserRequest extends Record<string, unknown> {
  activationId: string
  email: string
  firstName: string
  lastName: string
  jobTitle: string
  phoneNumber: string
  password: string
  matchingPassword: string
  timezone: string
  orgUrl: string
}

export const activateUser = createStatefulApi<ActivateUserRequest, typeof activatedUser>(
  'user',
  '/users/activate',
  { method: 'POST' },
  activatedUser
)

// Update user
const updateUserResponse = z.object({
  orgUserId: z.number(),
  email: z.optional(z.string().email()),
  status: userStatus,
  roles: z.array(z.string()),
  firstName: z.optional(z.string()),
  lastName: z.optional(z.string()),
  phoneNumber: z.optional(z.string()),
  emergencyContact1Name: z.optional(z.string()),
  emergencyContact1PhoneNumber: z.optional(z.string()),
  emergencyContact2Name: z.optional(z.string()),
  emergencyContact2PhoneNumber: z.optional(z.string()),
  jobTitle: z.optional(z.string())
})

interface UpdateUserRequest extends OrgUserIdRequest {
  roles?: string[]
  email?: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  phoneNumber?: string
  emergencyContact1Name?: string
  emergencyContact1PhoneNumber?: string
  emergencyContact2Name?: string
  emergencyContact2PhoneNumber?: string
}

export const updateUser = createStatefulApi<UpdateUserRequest, typeof updateUserResponse>(
  'user',
  '/orgUser/:orgUserId',
  { method: 'PUT' },
  updateUserResponse
)

const deleteUserResponse = z.literal(true)

export const deleteUser = createStatefulApi<OrgUserIdRequest, typeof deleteUserResponse>(
  'user',
  '/orgUser/:orgUserId',
  { method: 'DELETE', type: 'path' },
  deleteUserResponse
)

// Onboarding status
interface UpdateOnboardingStatus extends Record<string, unknown> {
  status: OnboardingStatus
}

const updatedOnboardingStatus = z.object({
  status: z.enum(onboardingStatuses)
})

export const updateOnboardingStatusPerUser = createStatefulApi<UpdateOnboardingStatus & UserIdRequest, typeof updatedOnboardingStatus>(
  'user',
  '/users/:userId/property/onboarding-status',
  { method: 'PUT' },
  updatedOnboardingStatus
)

export const updateOnboardingStatusPerOrg = createStatefulApi<UpdateOnboardingStatus & OrgIdRequest, typeof updatedOnboardingStatus>(
  'user',
  '/users/org/:orgId/property/onboarding-status',
  { method: 'PUT' },
  updatedOnboardingStatus
)
