import { type Layouts } from 'react-grid-layout'
import { MODULE_CONTENT } from './constants'

const initialLayout = [
  { i: MODULE_CONTENT.PDD_DETAILS, x: 0, y: 0, w: 12, h: 2, static: true, isDraggable: false }
]

export const defaultLayouts: Layouts = {
  xl: initialLayout,
  lg: initialLayout,
  md: initialLayout,
  sm: initialLayout,
  xs: initialLayout,
  xxs: initialLayout
}
