import { type DrawCustomMode } from '@mapbox/mapbox-gl-draw'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'

const DrawSquare: DrawCustomMode = {
  onSetup: function () {
    const square = this.newFeature({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [[]]
      }
    })
    this.addFeature(square)
    this.clearSelectedFeatures()
    this.updateUIClasses({ mouse: 'add' })
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false
    })
    return {
      square
    }
  },
  onTap: function (state, e) {
    const mouseEvent = e as never as MapboxDraw.MapMouseEvent

    if (state.startPoint && this.onMouseMove) {
      this.onMouseMove(state, mouseEvent)
    }
    if (this.onClick) {
      this.onClick(state, mouseEvent)
    }
  },
  onClick: function (state, e) {
    if (
      state.startPoint &&
          state.startPoint[0] !== e.lngLat.lng &&
          state.startPoint[1] !== e.lngLat.lat
    ) {
      this.updateUIClasses({ mouse: 'pointer' })
      this.changeMode('direct_select', { featureId: state.square.id })
    }
    state.startPoint = [e.lngLat.lng, e.lngLat.lat]
  },
  onMouseMove: function (state, e) {
    if (state.startPoint) {
      const xLength = e.lngLat.lng - state.startPoint[0]
      const yLength = e.lngLat.lat - state.startPoint[1]
      const sideLength = Math.max(Math.abs(xLength), Math.abs(yLength))
      const lng = state.startPoint[0] + sideLength * (xLength > 0 ? 1 : -1)
      const lat = state.startPoint[1] + sideLength * (yLength > 0 ? 1 : -1)

      state.square.updateCoordinate(
        '0.0',
        state.startPoint[0],
        state.startPoint[1]
      )
      state.square.updateCoordinate(
        '0.1',
        lng,
        state.startPoint[1]
      )
      state.square.updateCoordinate(
        '0.2',
        lng,
        lat
      )
      state.square.updateCoordinate(
        '0.3',
        state.startPoint[0],
        lat
      )
    }
  },
  onKeyUp: function (state, e) {
    if (e.keyCode === 27) {
      return this.changeMode('direct_select')
    }
  },
  onStop: function (state) {
    this.updateUIClasses({ mouse: 'none' })
    this.activateUIButton()

    if (this.getFeature(state.square.id) === undefined) {
      return
    }

    state.square.removeCoordinate('0.4')
    if (state.square.isValid()) {
      this.map.fire('draw.create', {
        features: [state.square.toGeoJSON()]
      })
    } else {
      const id: string = state.square.id
      this.deleteFeature(id, { silent: true })
      this.changeMode('simple_select', {}, { silent: true })
    }
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (geojson.type === 'Feature' && geojson.properties) {
      const isActivePolygon = geojson.properties.id === state.square.id
      geojson.properties.active = isActivePolygon
        ? 'true'
        : 'false'
      if (!isActivePolygon) {
        return display(geojson)
      }
    }

    if (!state.startPoint) return
    return display(geojson)
  },
  onTrash: function (state) {
    const id: string = state.square.id
    this.deleteFeature(id, { silent: true })
    this.changeMode('simple_select')
  }
}

export default DrawSquare
