import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { createSite, deleteSite, updateSite, type Site } from '@venturi-io/api/src/userManager/site'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'

interface Props {
  mode: FormMode
  initialValues?: Site
  hasAgent?: boolean
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({
  mode,
  initialValues,
  hasAgent = false,
  onCreate,
  onClose
}: Props) {
  const { token, orgId } = useUser()
  const navigate = useNavigate()
  const newSite = useApi(createSite)
  const editSite = useApi(updateSite)
  const removeSite = useApi(deleteSite)
  const [showDelete, setShowDelete] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<Omit<Site, 'siteId' | 'orgId'>>({
    initialValues: {
      name: ''
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = useCallback(() => navigate('/settings/sites'), [])

  const handleCreate = useCallback((values: FormValues) => {
    void newSite
      .fetch({
        orgId,
        ...values
      }, token, 'Successfully created new site')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }, [form.values])

  const handleUpdate = useCallback((values: FormValues) => {
    const siteId = initialValues?.siteId

    if (!siteId) throw new Error('Unable to perform the action, no site provided')

    void editSite
      .fetch({
        siteId,
        ...values
      }, token, 'Successfully updated site')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const siteId = initialValues?.siteId

    if (!siteId) throw new Error('Unable to perform the action, no site provided')

    void removeSite
      .fetch({ siteId }, token, 'Succesfully deleted site')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    if (initialValues) form.setValues(initialValues)
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          {inEditMode && (
            <Col span={6}>
              <Group mt="xl" position="left">
                <Tooltip
                  position="right"
                  color="red"
                  label="You cannot delete a site that has agents"
                  opened={isHover && hasAgent}
                  withArrow
                >
                  <Box
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <Button
                      color="red"
                      leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                      onClick={() => setShowDelete(true)}
                      disabled={hasAgent}
                    >
                      Delete
                    </Button>
                  </Box>
                </Tooltip>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group mt="sm" position="right">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode ? newSite.loading : editSite.loading}
              >
                {inCreateMode ? 'Create' : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeSite.loading}
          question="Are you sure you want to delete this site? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
