import { useCallback, useState } from 'react'
import { Group, Text } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { deleteAgent } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import TextTooltip from 'src/Layout/TextTooltip'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useStyles, type Row as ListRow } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { truncateWithEllipsis, getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import Icon from './Icon'
import Settings from './Settings'
import { type Actions } from '.'

export default function Row ({
  agentId,
  agentName,
  iconStyle,
  deviceId,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  site,
  actions
}: Agent & ListRow<Actions>) {
  const { classes, cx } = useStyles()
  const deleteApi = useApi(deleteAgent)
  const [showDelete, setShowDelete] = useState(false)
  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)
  const { token } = useUser()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const handleDelete = useCallback(() => {
    void deleteApi
      .fetch({ agentId }, token, 'Succesfully deleted agent')
      .finally(() => { void actions.loadAgents() })
  }, [agentId])

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{agentId}</Text>
      </div>
      <div className={classes.col}>
        <Group spacing={8}>
          <Icon
            iconStyle={iconStyle}
            alarmStatus={alarmStatus}
            agentStatus={agentStatus}
            connectionStatus={connectionStatus}
            lastSeenTime={lastSeenTime}
          />
          <TextTooltip
            size="sm"
            value={agentName}
            maxLength={textMaxLength}
          />
        </Group>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(deviceId)}
          maxLength={10}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(site?.name)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <Settings
          agentId={agentId}
          isOpen={isOpenSettingsMenu}
          setIsOpen={setIsOpenSettingsMenu}
          setShowDelete={setShowDelete}
        />
      </div>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${truncateWithEllipsis(agentName, 20)}"`}
        loading={deleteApi.loading}
        question="Are you sure you want to delete this agent? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </div>
  )
}
