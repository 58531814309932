import { type NodeTypes } from 'react-flow-renderer'
import { createStyles } from '@mantine/core'

type NodeType = keyof NodeTypes

export const useSharedStyles = createStyles(() => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 55px',
    border: '1px solid black',
    borderRadius: '5px !important',
    textAlign: 'center',
    fontSize: '.75rem',
    fontWeight: 400,
    overflow: 'hidden'
  },
  edge: {
    background: '#19192B'
  }
}))

export const nodeStyles: Record<NodeType, React.CSSProperties> = {
  input: {
    color: 'white',
    background: '#8699B0'
  },
  default: {
    color: 'white',
    background: '#3E5C82'
  },
  defaultDual: {
    color: 'white',
    background: '#3E5C82'
  },
  defaultTriple: {
    color: 'white',
    background: '#3E5C82'
  },
  output: {
    color: 'white',
    background: '#264773'
  }
}
