import { Drawer, Title } from '@mantine/core'
import Form, { type ReqProps } from './Form'

interface Props {
  agentId: number
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function CreateForSingleAgent ({ show, close, onCreate, agentId }: Props) {
  const initialValues: ReqProps = {
    agentId,
    taskType: '',
    priority: '',
    assignedTo: -1,
    dueDate: null,
    status: '',
    notes: ''
  }
  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Task</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <Form
        forceValues={initialValues}
        mode="CREATE"
        onCreate={onCreate}
        onClose={close}
      />
    </Drawer>
  )
}
