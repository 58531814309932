import { useCallback, useState } from 'react'
import {
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { deleteAgent } from '@venturi-io/api/src/config/agent'
import TextTooltip from 'src/Layout/TextTooltip'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { truncateWithEllipsis, getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getCurrentBreakpoint } from 'src/utils/style'
import Icon from './Icon'
import Settings from './Settings'
import { type Actions } from '.'

export default function Card ({
  agentId,
  agentName,
  iconStyle,
  deviceId,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  site,
  actions
}: Agent & ListRow<Actions>) {
  const deleteApi = useApi(deleteAgent)
  const [showDelete, setShowDelete] = useState(false)
  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)
  const { token } = useUser()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const handleDelete = useCallback(() => {
    void deleteApi
      .fetch({ agentId }, token, 'Succesfully deleted agent')
      .finally(() => { void actions.loadAgents() })
  }, [agentId])

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={agentId.toString()} isKey />
          <Group spacing="sm">
            <Settings
              agentId={agentId}
              isOpen={isOpenSettingsMenu}
              setIsOpen={setIsOpenSettingsMenu}
              setShowDelete={setShowDelete}
            />
          </Group>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <Stack spacing={1}>
            <Text weight={600} size="sm">Name</Text>
            <Group spacing={8}>
              <Icon
                iconStyle={iconStyle}
                alarmStatus={alarmStatus}
                agentStatus={agentStatus}
                connectionStatus={connectionStatus}
                lastSeenTime={lastSeenTime}
              />
              <TextTooltip
                size="sm"
                value={agentName}
                maxLength={textMaxLength}
              />
            </Group>
          </Stack>
          <TextGroup
            label="Serial Number"
            value={parseEmpty(deviceId)}
            maxLength={textMaxLength}
          />
          <TextGroup
            label="Site"
            value={parseEmpty(site?.name)}
            maxLength={textMaxLength}
          />
        </SimpleGrid>
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting "${truncateWithEllipsis(agentName, 20)}"`}
        loading={deleteApi.loading}
        question="Are you sure you want to delete this agent? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </Paper>
  )
}
