import { useEffect, useState } from 'react'
import { useMantineTheme, ColorSwatch, Popover } from '@mantine/core'
import { type ColorIndex, type ColorName, validateHex } from 'src/utils/style'
import ColorPicker from 'src/Input/ColorPicker'

interface Props {
  name: ColorName
  index: ColorIndex
  override?: string
  onChange: (hex: string, name: ColorName, index: ColorIndex) => void
}

export default function ThemeColorPicker (props: Props) {
  const { colors } = useMantineTheme()
  const set = colors[props.name]
  const themeColor = set[props.index]

  const [opened, setOpened] = useState(false)
  const [internal, setInternal] = useState(
    props.override && validateHex(props.override)
      ? props.override
      : themeColor
  )

  const dirty = internal !== themeColor

  useEffect(() => {
    if (props.override) {
      setInternal(props.override)
    }
  }, [props.override])

  useEffect(() => {
    if (dirty) {
      props.onChange(internal, props.name, props.index)
    }
  }, [dirty, internal])

  const swatch = (
    <ColorSwatch
      color={internal}
      size={50}
      radius={0}
      onClick={() => setOpened(true)}
    />
  )

  return (
    <Popover
      opened={opened}
      position="right"
    >
      <Popover.Target>{swatch}</Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          color={internal}
          onAccept={setInternal}
          close={() => setOpened(false)}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
