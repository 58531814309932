import {
  Autocomplete as MantineAutocomplete,
  type AutocompleteProps,
  createStyles,
  type MantineColor,
  type SelectItemProps,
  Text
} from '@mantine/core'
import { forwardRef } from 'react'

const useStyles = createStyles(theme => ({
  input: {
    border: `0.808511px solid ${theme.colors.primary[6]}`,
    boxSizing: 'border-box',
    borderRadius: '2.42553px',
    '&:active': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    },
    '&:focus': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    }
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px'
  },
  item: {
    '&:hover': {
      background: theme.colors.primary[5],
      color: 'white'
    }
  }
}))

interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => {
    const { classes } = useStyles()
    return (
      <div className={classes.item} ref={ref} {...others}>
        <Text className={classes.label}>{label}</Text>
      </div>
    )
  }
)

export default function Autocomplete (props: AutocompleteProps) {
  const { classes } = useStyles()

  return (
    <MantineAutocomplete
      itemComponent={AutoCompleteItem}
      classNames={{ input: classes.input, label: classes.label }}
      {...props}
    />
  )
}
