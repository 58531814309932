import { Center, Indicator, Table as MantineTable, Title } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoData from 'src/Layout/TableRowNoData'
import { type PowerStatus as Status, findSensorByType, powerStatusColor } from 'src/Assets/shared'
import { checkIfAlarm } from 'src/utils/status'
import Link from 'src/Layout/Link'
import ClearAlarmButton from 'src/ClearAlarmButton'

interface PowerStatusProps {
  status: Status
}

const PowerStatus = ({ status }: PowerStatusProps) => {
  const color = powerStatusColor[status] ?? 'gray'

  return (
    <Indicator
      size={15}
      color={color}
      processing={status === 'ALARM'}
      position="middle-center"
    >
      <span />
    </Indicator>
  )
}

interface AlertProps {
  status: Agent['alarmStatus']
}

function Alert ({ status }: AlertProps) {
  const isAlert = checkIfAlarm(status)
  const color = isAlert ? 'red' : 'gray'

  return (
    <Center>
      <FontAwesomeIcon
        color={color}
        icon={['fas', 'warning']}
        size="lg"
      />
    </Center>
  )
}

export interface DevicesRowProps {
  devices: Agent[]
  onRefresh: () => void
}

function DevicesRow ({ devices, onRefresh }: DevicesRowProps) {
  return devices.length > 0
    ? (
      <>
        {devices.map(({
          agentId,
          agentName,
          assignedTo,
          alarmStatus,
          sensors
        }) => {
          const powerStatus = findSensorByType('POWER_STATUS', sensors)
          const duress = findSensorByType('DIGITAL_INPUT_2_DURESS', sensors)
          return (
            <tr key={agentId}>
              <td>{agentId}</td>
              <td>{assignedTo}</td>
              <td>
                <PowerStatus status={powerStatus?.currentValue as Status | undefined ?? 'OFF'} />
              </td>
              <td>
                <Alert status={alarmStatus} />
              </td>
              <td>
                <Link to={`/assets/duress/${agentId}/map`}>
                  View
                </Link>
              </td>
              {duress && (
                <td>
                  <ClearAlarmButton
                    sensorInstanceIds={[duress.sensorInstanceId]}
                    agentName={agentName}
                    onRefresh={onRefresh}
                  />
                </td>
              )}
            </tr>
          )
        })}
      </>
      )
    : <NoData message="No data available" />
}

export interface Props {
  title: string
  devices: Agent[]
  onRefresh: () => void
}

export default function Table ({ title, devices, onRefresh }: Props) {
  return (
    <div>
      <Title ml="md" mt="sm" order={3}>{title}</Title>
      <MantineTable striped highlightOnHover>
        <thead>
          <tr>
            <th>Asset #</th>
            <th>Assignee</th>
            <th>Status</th>
            <th>Alert</th>
            <th>Location</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <DevicesRow devices={devices} onRefresh={onRefresh} />
        </tbody>
      </MantineTable>
    </div>
  )
}
