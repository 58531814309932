import { Pagination as MantinePagination, type PaginationProps } from '@mantine/core'

interface Props extends PaginationProps {}

export default function Pagination ({
  color = 'primary',
  total = 0,
  ...props
}: Props) {
  return (
    <MantinePagination
      color={color}
      total={total}
      withControls={total > 0}
      {...props}
    />
  )
}
