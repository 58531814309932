import { type DefaultRawDatum, type PieSvgProps, ResponsivePie } from '@nivo/pie'

interface Props extends PieSvgProps<DefaultRawDatum> {
  height: number
}

export default function Pie ({ height, ...props }: Props) {
  return (
    <div style={{ height }}>
      <ResponsivePie {...props} />
    </div>
  )
}
