import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createStyles,
  getStylesRef,
  Burger,
  Text,
  ActionIcon,
  Tooltip
} from '@mantine/core'
import { useZendesk } from 'src/ZendeskContext'
import { mq } from 'src/utils/style'

export interface CloseMenuProps {
  show: boolean
  showLabel?: boolean
  setShow: (input: boolean) => void
}

const closeItemStyles = createStyles(theme => {
  const menuItemLabel = getStylesRef('menuItemLabel')

  return {
    closeMenu: {
      [mq(theme.breakpoints.sm)]: {
        backgroundColor: theme.colors.primary[8],
        cursor: 'pointer',
        display: 'flex',
        color: 'black',
        height: '48px',
        width: '100%',
        padding: '5px 5px 5px 12px',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: theme.colors.primary[8]
        }
      }
    },
    closeMenuLabel: {
      color: theme.white,
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '40px'
      }
    },
    menuButton: {
      position: 'fixed',
      top: '18px',
      right: '30px',
      width: '20px',
      zIndex: 199,
      transform: 'translate3d(0, 0, 0)',
      [mq(theme.breakpoints.sm)]: {
        position: 'initial',
        zIndex: 'initial'
      }
    },
    menuItemLabel: {
      ref: menuItemLabel,
      color: 'black',
      fontSize: '0.9rem',
      marginLeft: '20px',
      textAlign: 'left',
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '36px'
      }
    }
  }
})

const useStyles = createStyles((theme) => ({
  chatbotIcon: {
    position: 'fixed',
    right: '75px',
    zIndex: 199,
    transform: 'translate3d(0, 0, 0)',
    [mq(theme.breakpoints.sm)]: {
      position: 'initial',
      zIndex: 'initial',
      display: 'none'
    }
  },
  icon: {
    color: 'white',
    padding: '8px',
    borderRadius: '50%'
  }
}))

export function CloseMenu ({ show, setShow, showLabel }: CloseMenuProps) {
  const { classes, cx } = closeItemStyles()
  const { classes: chatbotClass } = useStyles()
  const { show: showChatbot, open } = useZendesk()

  return (
    <>
      <Tooltip label="Help">
        <ActionIcon
          variant="transparent"
          onClick={() => {
            showChatbot()
            open()
          }}
          sx={{
            marginRight: '-8px'
          }}
          className={chatbotClass.chatbotIcon}
        >
          <FontAwesomeIcon
            size="lg"
            icon={['far', 'circle-question']}
            className={chatbotClass.icon}
          />
        </ActionIcon>
      </Tooltip>
      <div className={classes.closeMenu} onClick={() => setShow(!show)}>
        <Burger
          color="white"
          className={classes.menuButton}
          opened={show}
        />
        {showLabel && (
          <Text className={cx(classes.menuItemLabel, classes.closeMenuLabel)}>
            Close
          </Text>
        )}
      </div>
    </>
  )
}
