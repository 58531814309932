import { createStyles, Table as MantineTable, ScrollArea } from '@mantine/core'
import { type VehicleTransaction } from '@venturi-io/api/src/config/fms'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import NoData from 'src/Layout/TableRowNoData'
import { useElementSize } from '@mantine/hooks'
import { useEffect } from 'react'

const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    background: 'white'
  },
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff'
  }
}))

export interface Props {
  transactions: VehicleTransaction[]
  didChangeHeight?: (height: number, key: string) => void
}

function Rows ({ transactions }: Props) {
  return transactions.length > 0
    ? (
      <>
        {transactions.map(({
          imported,
          rfidTag,
          person,
          volume,
          registrationNumber,
          odometerKilometres,
          kmTravelled,
          transactionId,
          agent
        }) => (
          <tr key={transactionId}>
            <td>{dayjs(imported).format(uiDateFormat)}</td>
            <td>{rfidTag}</td>
            <td>{agent?.agentId ?? '-'}</td>
            <td>{agent?.agentName ?? '-'}</td>
            <td>{agent?.assetType ?? '-'}</td>
            <td>{person ?? '-'}</td>
            <td>{registrationNumber}</td>
            <td>{volume}</td>
            <td>{odometerKilometres}</td>
            <td>{kmTravelled}</td>
            <td>{transactionId}</td>
          </tr>
        ))}
      </>
      )
    : <NoData message="No transactions available" />
}

const barHeight = 68

function Table ({ transactions, didChangeHeight }: Props) {
  const { classes } = useStyles()
  const { ref, height } = useElementSize()

  useEffect(() => {
    if (didChangeHeight) {
      didChangeHeight(height + barHeight, 'transactions')
    }
  }, [height])

  return (
    <ScrollArea
      className={classes.container}
      type="hover"
      scrollbarSize={5}
    >
      <MantineTable ref={ref} striped highlightOnHover>
        <thead className={classes.head}>
          <tr>
            <th>Date/Time</th>
            <th>RFID Tag</th>
            <th>Agent ID</th>
            <th>Agent Name</th>
            <th>Asset Type</th>
            <th>User</th>
            <th>LPR</th>
            <th>Volume(L)</th>
            <th>Odometer(KM)</th>
            <th>KM travelled</th>
            <th>Trans ID</th>
          </tr>
        </thead>
        <tbody>
          <Rows transactions={transactions} />
        </tbody>
      </MantineTable>
    </ScrollArea>
  )
}

export default Table
