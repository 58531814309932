import { useState, useEffect, type ReactNode } from 'react'
import { type Report } from '@venturi-io/api/src/config/report'
import Select from 'src/Input/Select'
import { toProperCase } from 'src/utils/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  value?: number
  error?: ReactNode
  data: Report[]
  onChange: (value?: Report) => void
}

export default function SelectReport ({
  required = false,
  searchable = false,
  disabled = false,
  error,
  data,
  ...props
}: Props) {
  const [value, setValue] = useState(props.value?.toString() ?? '')

  useEffect(() => {
    const report = data.find(({ id }) => id.toString() === value)
    if (report) {
      props.onChange(report)
    } else {
      props.onChange()
    }
  }, [value])

  return (
    <Select
      sx={{
        width: 240
      }}
      icon={<FontAwesomeIcon icon={['far', 'clipboard-list']} />}
      required={required}
      searchable={searchable}
      disabled={disabled}
      placeholder="Choose a report"
      value={value}
      data={data.map(({ id, name }) => ({
        label: toProperCase(name),
        value: id.toString()
      }))}
      onChange={val => setValue(val ?? '')}
      error={error}
    />
  )
}
