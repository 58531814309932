import {
  createStyles,
  Box,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { hasAlarm } from 'src/AssetTemplate/shared'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import PowerState from './PowerState'
import PowerDetails from './PowerDetails/'

const useStyles = createStyles(theme => ({
  content: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      alignItems: 'flex-start',
      flexDirection: 'row'
    }
  },
  titleContainer: {
    position: 'relative'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    top: -8,
    left: -16
  },
  alarmIndicatorLine: {
    height: 32,
    width: 4,
    background: '#FFE066'
  },
  leftSection: {
    flex: 1,
    alignContent: 'center'
  },
  rightSection: {
    flex: 3,
    alignContent: 'center'
  }
}))

export interface VoltageSensors {
  a: Sensor | null
  b: Sensor | null
  c: Sensor | null
}

interface Props {
  powerLevel: Sensor | null
  outputPower: Sensor | null
  powerFactor: Sensor | null
  powerFrequency: Sensor | null
  voltageLN: VoltageSensors
  voltageLL: VoltageSensors
  voltageCurrent: VoltageSensors
}

export default function PowerStatus ({
  powerLevel,
  outputPower,
  powerFactor,
  powerFrequency,
  voltageLN,
  voltageLL,
  voltageCurrent
}: Props) {
  const { classes } = useStyles()
  const withAlarm = hasAlarm([
    powerLevel,
    outputPower,
    powerFactor,
    powerFrequency,
    voltageLN.a,
    voltageLN.b,
    voltageLN.c,
    voltageLL.a,
    voltageLL.b,
    voltageLL.c,
    voltageCurrent.a,
    voltageCurrent.b,
    voltageCurrent.c
  ])

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
          color="#212121"
        >
          Power Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicatorContainer}
            position="center"
            align="center"
            spacing={100}
          >
            <span className={classes.alarmIndicatorLine} />
            <AlarmIndicator size="md" />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.leftSection}>
          <PowerState powerLevel={powerLevel} />
        </Box>
        <Box className={classes.rightSection}>
          <PowerDetails
            outputPower={outputPower}
            powerFactor={powerFactor}
            powerFrequency={powerFrequency}
            voltageLN={voltageLN}
            voltageLL={voltageLL}
            voltageCurrent={voltageCurrent}
          />
        </Box>
      </Box>
    </Paper>
  )
}
