import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Group, Paper, Stack } from '@mantine/core'
import { removeRolesFromGroup } from '@venturi-io/api/src/config/agentGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMockApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Role } from '@venturi-io/api/src/config/agent'
import { type Actions, type ExtraData } from '.'

export default function Card ({
  actions,
  extra,
  ...role
}: Role & ListRow<Actions, ExtraData>) {
  const { token } = useUser()
  const { roleId, name } = role
  const { loadRoles } = actions
  const { agentGroupId } = extra
  const navigate = useNavigate()
  const removeFromGroup = useMockApi(removeRolesFromGroup, true)
  const [showRemove, setShowRemove] = useState(false)

  const handleRemoveFromGroup = useCallback(() => {
    // TODO: Add remove API here.
    // TODO: Change useMockApi to useApi
    void removeFromGroup
      .fetch({
        agentGroupId,
        roleIds: [roleId]
      }, token)
      .finally(() => { void loadRoles() })
  }, [token, roleId, agentGroupId])

  return (
    <>
      <Paper p="lg" my={10} shadow="md">
        <Stack>
          <Group position="apart" align="start">
            <TextGroup label="ID" value={roleId.toString()} isKey />
            <Group spacing="sm">
              <ActionIcon
                title="Edit"
                color="green"
                variant="filled"
                onClick={() => navigate(`/settings/role/${roleId}`)}
              >
                <FontAwesomeIcon icon={['fas', 'pencil']} color="white" />
              </ActionIcon>
              <ActionIcon
                title="Remove"
                color="red"
                variant="filled"
                onClick={() => setShowRemove(true)}
                disabled={removeFromGroup.loading}
              >
                <FontAwesomeIcon size="lg" icon={['fas', 'square-minus']} color="white" />
              </ActionIcon>
            </Group>
          </Group>
          <TextGroup label="Name" value={name} maxLength={25} />
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showRemove}
        title={'Removing role from this group'}
        confirmLabel="Remove"
        loading={removeFromGroup.loading}
        question="Are you sure you want to remove this role from this group? This cannot be undone."
        onClose={() => setShowRemove(false)}
        onCancel={() => setShowRemove(false)}
        onConfirm={handleRemoveFromGroup}
      />
    </>
  )
}
