import { useEffect, useState } from 'react'
import {
  Center,
  Col,
  Grid,
  Stack,
  Title
} from '@mantine/core'
import { configure } from '@venturi-io/api/src/configure'
import { getOrgLogos } from '@venturi-io/api/src/userManager/org'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { getHost } from 'src/utils/host'
import Logo from './Logo'

export default function OrgLogos () {
  const { token, orgId } = useUser()
  const orgLogos = useApi(getOrgLogos)
  const [apiPath, setApiPath] = useState('')
  const timeParam = `time=${Date.now()}`
  const logos = orgLogos.data.mapOrDefault(logos_ => logos_, null)

  useEffect(() => {
    void configure(getHost())
      .then(config => {
        setApiPath(`${config.user}/api/usermanager/image/org`)
      })

    void orgLogos.fetch({ orgId }, token)
  }, [])

  return (
    <Stack>
      <Title order={2} mb={10}>Organisation Logos</Title>
      {orgLogos.loading && <Center><Loader variant="dots" /></Center>}
      {logos && (
        <Grid my="sm" gutter="xl" grow>
          <Col span={12} sm={12} md={6} lg={4}>
            <Logo
              title="Top Logo"
              type="topLogo"
              src={logos?.topLogo
                ? `${apiPath}/${logos.topLogo}&${timeParam}`
                : undefined}
              apiPath={apiPath}
            />
          </Col>
          <Col span={12} sm={12} md={6} lg={4}>
            <Logo
              title="Top Logo (Mobile)"
              type="topMobileLogo"
              src={logos?.topMobileLogo
                ? `${apiPath}/${logos.topMobileLogo}&${timeParam}`
                : undefined}
              apiPath={apiPath}
            />
          </Col>
          <Col span={12} sm={12} md={6} lg={4}>
            <Logo
              title="Bottom Logo"
              type="bottomLogo"
              src={logos?.bottomLogo
                ? `${apiPath}/${logos.bottomLogo}&${timeParam}`
                : undefined}
              apiPath={apiPath}
            />
          </Col>
        </Grid>
      )}
    </Stack>
  )
}
