import {
  useMantineTheme,
  type Tuple,
  type MantineSize,
  type MantineSizes,
  px
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { tint, shade } from 'polished'

export const mq = (input: string, isCss = true) => isCss
  ? `@media screen and (min-width: ${input})`
  : `(min-width: ${input})`

export type Color = Tuple<string, 10>
export type ColorMutable = [string, string, string, string, string, string, string, string, string, string]
export type ColorIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export const color = (hex: string): Color => [
  tint(0.7, hex),
  tint(0.6, hex),
  tint(0.5, hex),
  tint(0.4, hex),
  tint(0.3, hex),
  tint(0.2, hex),
  tint(0.1, hex),
  hex,
  shade(0.2, hex),
  shade(0.4, hex)
]

export const adjustColor = (color: Color, hex: string, index: ColorIndex): Color => {
  const result = [...color]

  result[index] = hex

  return result as unknown as Color
}

export const simpleColor = (input: string[]) => {
  const arr: ColorMutable = ['#fff', '#fff', '#ffffff', '#ffffff', '#fff', '#fff', '#fff', '#fff', '#ccc', '#999']

  input.forEach((v, k) => {
    arr[k] = v
  })

  return arr as Color
}

export const validateHex = (input: string) => /^#[0-9A-F]{6}$/i.test(input)

export type ColorName = 'primary' | 'secondary' | 'brand'

interface MantineSizesWithXss extends Record<string, string>, MantineSizes {
  xxs: string
}

interface MantineBreakpointsInPx extends Record<MantineSize, number> {
  xxs: number
}

const defaultBreakpoints: MantineBreakpointsInPx = {
  xxs: 0,
  xs: 500,
  sm: 800,
  md: 1000,
  lg: 1100,
  xl: 1400
}

export const getMantineBreakpointsInPx: () => MantineBreakpointsInPx = () => {
  // default mantine's breakpoints are now in em unit
  const { breakpoints } = useMantineTheme()
  const breakpointsWithXxs: MantineSizesWithXss = {
    ...breakpoints,
    xxs: '0em'
  }
  // convert mantine's default breakpoints into px unit
  const breakpointsInPx: MantineBreakpointsInPx = Object.keys(breakpointsWithXxs).reduce((breakpoints, size) => {
    breakpoints[size] = px(breakpointsWithXxs[size].replace('em', 'rem'))
    return breakpoints
  }, defaultBreakpoints)

  return breakpointsInPx
}

export const getCurrentBreakpoint = () => {
  const breakpoints: Record<string, string> = useMantineTheme().breakpoints
  let currentBreakpoint = 'xxs'

  Object.keys(breakpoints).forEach((size) => {
    if (useMediaQuery(mq(breakpoints[size], false))) {
      currentBreakpoint = size as MantineSize
    }
  })

  return currentBreakpoint
}

export const generateAreaColors = (keys: string[]) => {
  const { colors } = useMantineTheme()

  const swatches = [
    colors.blue,
    colors.red,
    colors.green,
    colors.yellow,
    colors.grape,
    colors.cyan,
    colors.lime
  ]
  let round = 1

  return (
    keys.reduce((areaColors_, key, i) => {
      const swatchIndex = i % swatches.length
      const randomColor = swatches[swatchIndex]

      const color = {
        primary: randomColor[round],
        secondary: randomColor[round + 1]
      }

      if (swatchIndex === 0) {
        round += 1
      }

      return {
        ...areaColors_,
        [key]: color
      }
    }, {})
  )
}

export const getBatteryLevelColor = (level: number) => {
  if (level >= 0 && level <= 32) {
    return 'red'
  } else if (level >= 33 && level <= 65) {
    return 'yellow'
  } else if (level >= 66 && level <= 100) {
    return 'green'
  } else {
    return 'gray'
  }
}
