import {
  ActionIcon,
  Container,
  Group,
  Popover,
  ScrollArea,
  Text,
  Title,
  Tooltip
} from '@mantine/core'
import { memo, useEffect, useState } from 'react'
import { usePaginatedApi } from 'src/utils/useApi'
import { getAgents } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import Pagination from 'src/Layout/Pagination'
import Select from 'src/Input/Select'
import DateTimeRangePicker from 'src/Input/DateTimeRangePicker'
import { type DatesRangeValue, type DateTimePickerProps } from '@mantine/dates'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Range } from '@venturi-io/api'
import { useSharedStyles } from '../shared'
import NoData from '../shared/NoData'
import Table from './Table'

export interface Props {
  id: string
  name: string
}

const inputWrapperOrder: Array<
'label' |
'error' |
'input' |
'description'
> = [
  'label',
  'error',
  'input',
  'description'
]
const sharedProps: Partial<DateTimePickerProps> = {
  inputWrapperOrder,
  descriptionProps: {
    style: {
      fontStyle: 'italic'
    }
  },
  maxDate: dayjs().toDate()
}

const LastSeen = ({ name }: Props) => {
  const { classes } = useSharedStyles()
  const agentsForOrg = usePaginatedApi(getAgents)
  const { token, orgId } = useUser()
  const [status, setStatus] = useState('ALL')
  const [open, setOpen] = useState(false)
  const [dateRange, setDateRange] = useState<Range | null>({
    from: dayjs()
      .subtract(3, 'month')
      .format(`${dateFormat}Z`),
    to: dayjs().format(`${dateFormat}Z`)
  })

  const agents = agentsForOrg.data.mapOrDefault(({ items }) => items, [])

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agentsForOrg
      .fetch({
        page: page ?? agentsForOrg.page,
        size: size ?? agentsForOrg.size,
        sort: 'lastSeenTime',
        order: 'asc',
        connectionStatus: status !== 'ALL'
          ? status
          : undefined,
        startLastSeenTime: dateRange?.from
          ? dateRange.from
          : undefined,
        endLastSeenTime: dateRange?.to
          ? dateRange.to
          : undefined,
        orgId
      }, token)
  }

  const { from, to } = dateRange
    ? {
        from: dayjs(dateRange.from, dateFormat),
        to: dayjs(dateRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const range: DatesRangeValue = [
    from?.toDate() ?? null,
    to?.toDate() ?? null
  ]

  const isValidRange = from && to
    ? from.isBefore(to)
    : true

  const handleChangeRange = (range: Range | null) => {
    if (range && setDateRange) {
      setDateRange(range)
    }
  }

  useEffect(() => {
    void loadAgents(1, 200)
  }, [agentsForOrg.page, status, dateRange])

  return (
    <Container className={classes.itemContainer} fluid>
      {agentsForOrg.loading && <Loader variant="bars" />}
      {!agentsForOrg.loading && (
        agents.length
          ? (
            <ScrollArea
              style={{
                height: '100%',
                width: '100%',
                background: 'white'
              }}
              type="hover"
              scrollbarSize={5}
            >
              <Group spacing="3px">
                <Title ml="md" order={3}>{name}</Title>
                <Popover
                  width={340}
                  position="right"
                  withArrow
                  shadow="md"
                  opened={open}
                  onClose={() => setOpen(false)}
                  withinPortal
                >
                  <Tooltip label="Filters">
                    <Popover.Target>
                      <ActionIcon
                        variant="subtle"
                        color="primary"
                        onClick={() => setOpen(true)}
                        mt={3}
                      >
                        <FontAwesomeIcon icon={['far', 'filter-list']} />
                      </ActionIcon>
                    </Popover.Target>
                  </Tooltip>
                  <Popover.Dropdown>
                    <Group>
                      <Select
                        w="100%"
                        value={status}
                        label="Connection Status"
                        data={['ALL', 'ONLINE', 'OFFLINE']}
                        onChange={(value: string) => setStatus(value)}
                      />
                      <DateTimeRangePicker
                        fromProps={{
                          ...sharedProps,
                          size: 'sm',
                          error: !isValidRange,
                          label: 'From date/time',
                          defaultValue: range[0],
                          onChange: date => {
                            if (!date) {
                              return handleChangeRange(null)
                            }
                            handleChangeRange({
                              from: dayjs(date)
                                .set('seconds', 0)
                                .format(`${dateFormat}Z`),
                              to: dayjs(range[1])
                                .set('seconds', 0)
                                .format(`${dateFormat}Z`)
                            })
                          }
                        }}
                        toProps={{
                          ...sharedProps,
                          size: 'sm',
                          error: !isValidRange,
                          label: 'To date/time',
                          defaultValue: range[1],
                          onChange: date => {
                            if (!date) {
                              return handleChangeRange(null)
                            }
                            handleChangeRange({
                              from: dayjs(range[0])
                                .set('seconds', 0)
                                .format(`${dateFormat}Z`),
                              to: dayjs(date)
                                .set('seconds', 0)
                                .format(`${dateFormat}Z`)
                            })
                          }
                        }}
                        error={!isValidRange && (
                          <Text
                            italic
                            size="xs"
                            color="red"
                          >
                            Please provide a valid date range
                          </Text>
                        )}
                      />
                    </Group>
                  </Popover.Dropdown>
                </Popover>
              </Group>
              <Table
                agents={agents}
              />
              <Pagination
                mt="sm"
                value={agentsForOrg.page}
                total={agentsForOrg.data.mapOrDefault(v => v.totalPages, 0)}
                onChange={agentsForOrg.setPage}
              />
            </ScrollArea>
            )
          : <NoData />
      )}
    </Container>
  )
}

export default memo(LastSeen)
