import { useCallback, useEffect, useState } from 'react'
import { useMantineTheme, ActionIcon, Group } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Color, type ColorName, type ColorIndex } from 'src/utils/style'
import ThemeColorPicker from './ThemeColorPicker'

interface Props {
  name: ColorName
  override?: Color
  onChange: (result: Color, name: ColorName) => void
}

export default function ThemeGroupPicker (props: Props) {
  const { colors } = useMantineTheme()
  const set = colors[props.name]
  const [internal, setInternal] = useState<Color>([...set])

  useEffect(() => {
    if (props.override) {
      setInternal(props.override)
    }
  }, [props.override])

  const isDirty = useCallback(() => {
    let dirty = false
    internal.forEach((hex, idx) => {
      if (hex !== set[idx]) {
        dirty = true
      }
    })

    return dirty
  }, [internal])

  useEffect(() => {
    props.onChange(internal, props.name)
  }, [...internal])

  const updateIndex = useCallback((hex: string, index: ColorIndex) => {
    const copy: Color = [...internal]
    copy[index] = hex
    setInternal(copy)
  }, [internal])

  const resetAll = useCallback(() => {
    setInternal([...set])
  }, [internal])

  return (
    <Group>
      {internal.map((hex, index) => {
        return (
          <ThemeColorPicker
            key={index}
            name={props.name}
            index={index as ColorIndex}
            override={hex}
            onChange={(hex, _, index) => updateIndex(hex, index)}
          />
        )
      })}
      {isDirty() && (
        <ActionIcon title="Discard changes" size="lg" onClick={resetAll}>
          <FontAwesomeIcon icon={['far', 'trash-undo']} size="lg" />
        </ActionIcon>
      )}
    </Group>
  )
}
