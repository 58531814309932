import { useCallback } from 'react'
import { Marker as MapboxMarker } from 'react-map-gl'

import { createStyles } from '@mantine/core'

const useStyles = createStyles(() => ({
  pin: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    border: '5px solid red',
    borderRadius: '100%',
    background: 'white',
    width: 40,
    height: 40,
    color: 'red',
    fontWeight: 500,
    cursor: 'pointer',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 27,
      left: -1,
      width: 0,
      height: 0,
      borderRight: '16px solid transparent',
      borderLeft: '16px solid transparent',
      borderTop: '32px solid red',
      zIndex: -1
    }
  }
}))

export interface AgentCluster {
  clusterId?: number | string
  latitude: number
  longitude: number
}

interface Props extends AgentCluster {
  pointCount: number
  totalPoints?: number
  onClick?: (cluster: AgentCluster) => void
}

export default function ClusterMarker ({
  clusterId,
  latitude,
  longitude,
  pointCount,
  onClick
}: Props) {
  const { classes } = useStyles()

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick({ clusterId, latitude, longitude })
    }
  }, [clusterId, latitude, longitude])

  return (
    <MapboxMarker
      latitude={latitude}
      longitude={longitude}
      offset={[-0, -40]}
      onClick={handleClick}
    >
      <div className={classes.pin}>
        {pointCount}
      </div>
    </MapboxMarker>
  )
}
