/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMantineTheme } from '@mantine/core'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import React, {
  type RefObject,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from 'react'
import {
  Layer,
  type LayerProps,
  Source,
  type SourceProps,
  type MapRef,
  type SymbolLayer
} from 'react-map-gl'
import { getAgentStatusColor } from 'src/utils/status'
import { useDebouncedValue } from '@mantine/hooks'
import { getToolContext } from '../Trips/Layer/Devtools'
import { useViewTypes } from '../AgentAttendanceContext'
export interface AgentCluster {
  clusterId?: number | string
  latitude: number
  longitude: number
}
interface Props {
  selectedAgent?: Agent
  mapRef: RefObject<MapRef>
  handleAgentClick: (id: Agent['agentId']) => void
}

const fontAwesomeCharacterCode = (name?: string): string => {
  if (!name) return ''
  const [prefix, iconName] = JSON.parse(name)
  const icon = findIconDefinition({
    prefix,
    iconName
  })
  const charCode = String.fromCharCode(parseInt(icon.icon[3], 16))
  return charCode
}

const getSource = (agent: Agent, selectedAgent?: Agent) => {
  const {
    agentId,
    lastLocation: {
      geoLocation
    },
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  } = agent

  const { longitude, latitude } = geoLocation
  const agentStatusColor = getAgentStatusColor(
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  )
  const statusColor = `is-${agentStatusColor}`
  const isSelected = selectedAgent?.agentId === agentId
  return ({
    id: agentId,
    type: 'Feature',
    properties: {
      ...agent,
      opacity: isSelected ? 1 : 0.85,
      [statusColor]: true
    },
    geometry: {
      coordinates: [
        longitude,
        latitude
      ],
      type: 'Point'
    }
  })
}

const getSources = (agents: Agent[], selectedAgent?: Agent): SourceProps => {
  const features = agents.map((agent) => {
    return getSource(agent, selectedAgent)
  })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    },
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 60
  }
}

const baseIconLayout: Pick<SymbolLayer, 'layout' | 'paint'> = {
  layout: {
    'icon-size': 1,
    'icon-allow-overlap': true,
    'icon-offset': [0, -14]
  }
}

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

interface ClusterMarkerProps extends AgentCluster {
  pointCount: number
  totalPoints?: number
  onClick?: (cluster: AgentCluster) => void
  mapRef: RefObject<MapRef>
}

function ClusterMarker ({
  clusterId,
  latitude,
  longitude,
  pointCount,
  totalPoints,
  mapRef,
  onClick
}: ClusterMarkerProps) {
  const map = mapRef.current
    ? mapRef.current.getMap()
    : null
  const { colors } = useMantineTheme()

  const clusterLayerId = `cluster-${clusterId}`
  const clusterCountLayerId = `cluster-count-${clusterId}`
  const geoJson = {
    type: 'FeatureCollection',
    features: [
      {
        id: clusterId,
        type: 'Feature',
        properties: {
          pointCount,
          totalPoints
        },
        geometry: {
          type: 'Point',
          coordinates: [
            longitude,
            latitude]
        }
      }
    ]
  }

  const clusterLayer: LayerProps = {
    id: clusterLayerId,
    type: 'circle',
    filter: ['has', 'pointCount'],
    paint: {
    // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-stroke-color': colors.primary[0],
      'circle-stroke-width': 2,
      'circle-color': [
        'step',
        ['get', 'pointCount'],
        colors.primary[3],
        10,
        colors.primary[6],
        30,
        colors.primary[9]
      ],
      'circle-radius': [
        'step',
        ['get', 'pointCount'],
        15,
        10,
        20,
        30,
        25
      ]
    }
  }

  const clusterCountLayer: LayerProps = {
    id: clusterCountLayerId,
    type: 'symbol',
    filter: ['has', 'pointCount'],
    layout: {
      'text-field': ['get', 'pointCount'],
      'text-font': [
        'Open Sans Bold',
        'Arial Unicode MS Bold'
      ],
      'text-size': 12,
      'text-allow-overlap': true
    },
    paint: {
      'text-color': '#fff'
    }
  }

  const handleHoverEvent = useCallback((pointer: string) => () => {
    if (map) {
      map.getCanvas().style.cursor = pointer
    }
  }, [map])

  const handleClusterClick = useCallback(() => {
    if (onClick) {
      onClick({ clusterId, latitude, longitude })
    }
  }, [clusterId, latitude, longitude])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', [clusterLayerId, clusterCountLayerId], handleHoverEvent(''))
      map.on('mouseenter', [clusterLayerId, clusterCountLayerId], handleHoverEvent('pointer'))
      map.on('click', [clusterLayerId, clusterCountLayerId], handleClusterClick)
    }
    return () => {
      if (map) {
        map.off('mouseleave', [clusterLayerId, clusterCountLayerId], handleHoverEvent(''))
        map.off('mouseenter', [clusterLayerId, clusterCountLayerId], handleHoverEvent('pointer'))
        map.off('click', [clusterLayerId, clusterCountLayerId], handleClusterClick)
      }
    }
  }, [map])

  return (
    <Source type="geojson" data={geoJson}>
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
    </Source>
  )
}

interface MarkerProps {
  agent: Agent
  selectedAgent?: Agent
  mapRef: RefObject<MapRef>
  onClick: (agentId: number) => void
}

function Marker ({
  agent,
  selectedAgent,
  mapRef
}: MarkerProps) {
  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  const { agentId } = agent
  const textId = `textId-${agentId}`
  const defIconId = `defId-${agentId}`
  const idleIconId = `idleId-${agentId}`
  const okIconId = `okId-${agentId}`
  const immoIconId = `immoId-${agentId}`
  const alarmIconId = `alarmId-${agentId}`
  const layerIds = [textId, defIconId, idleIconId, okIconId, alarmIconId, immoIconId]

  const source: SourceProps = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        getSource(agent, selectedAgent)
      ]
    },
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 60
  }

  const defIconLayer: LayerProps = {
    id: defIconId,
    type: 'symbol',
    filter: ['has', 'is-gray'],
    layout: {
      ...baseIconLayout.layout,
      'icon-image': 'location-pin-gray'
    },
    paint: {
      'icon-opacity': ['get', 'opacity']
    }
  }

  const okIconLayer: LayerProps = {
    id: okIconId,
    type: 'symbol',
    filter: ['has', 'is-green'],
    layout: {
      ...baseIconLayout.layout,
      'icon-image': 'location-pin-green'
    },
    paint: {
      'icon-opacity': ['get', 'opacity']
    }
  }

  const alarmIconLayer: LayerProps = {
    id: alarmIconId,
    type: 'symbol',
    filter: ['has', 'is-red'],
    layout: {
      ...baseIconLayout.layout,
      'icon-image': 'location-pin-red'
    },
    paint: {
      'icon-opacity': 1
    }
  }

  const immobiliseIconLayer: LayerProps = {
    id: immoIconId,
    type: 'symbol',
    filter: ['has', 'is-orange'],
    layout: {
      ...baseIconLayout.layout,
      'icon-image': 'location-pin-orange'
    },
    paint: {
      'icon-opacity': ['get', 'opacity']
    }
  }

  const idleIconLayer: LayerProps = {
    id: idleIconId,
    type: 'symbol',
    filter: ['has', 'is-yellow'],
    layout: {
      ...baseIconLayout.layout,
      'icon-image': 'location-pin-yellow'
    },
    paint: {
      'icon-opacity': ['get', 'opacity']
    }
  }

  const textLayer: LayerProps = {
    id: textId,
    type: 'symbol',
    layout: {
      'text-line-height': 1,
      'text-max-width': Infinity,
      'text-field': ['get', 'agentName'],
      'text-font': [
        'Open Sans Bold',
        'Arial Unicode MS Bold'
      ],
      'text-size': 16,
      'text-anchor': 'top',
      'text-allow-overlap': true,
      'text-offset': [0, 0.5]
    },
    paint: {
      'text-color': '#fff',
      'text-halo-color': '#000',
      'text-halo-width': 1,
      'text-opacity': ['get', 'opacity']
    }
  }

  const moveToTop = () => {
    if (map) {
      map.moveLayer(textId)
      map.moveLayer(defIconId)
      map.moveLayer(immoIconId)
      map.moveLayer(idleIconId)
      map.moveLayer(okIconId)
      map.moveLayer(alarmIconId)
      map.moveLayer('pointer')
    }
  }

  const handleHoverEvent = useCallback((pointer: string) => (e: MouseEvent) => {
    if (map) {
      map.getCanvas().style.cursor = pointer
      moveToTop()
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', layerIds, handleHoverEvent(''))
      map.on('mouseenter', layerIds, handleHoverEvent('pointer'))
    }
    return () => {
      if (map) {
        map.off('mouseleave', layerIds, handleHoverEvent(''))
        map.off('mouseenter', layerIds, handleHoverEvent('pointer'))
      }
    }
  }, [map])

  return (
    <Source {...source}>
      <Layer {...textLayer} />
      <Layer {...defIconLayer} />
      <Layer {...okIconLayer} />
      <Layer {...alarmIconLayer} />
      <Layer {...immobiliseIconLayer} />
      <Layer {...idleIconLayer} />
    </Source>
  )
}

export default function MarkerLayer ({
  selectedAgent,
  mapRef,
  handleAgentClick
}: Props) {
  const { agentPoints, agentClusters, handleClusterClick } = useViewTypes()
  const { showMarkerLayer } = useContext(getToolContext())
  const [debouncedAgent] = useDebouncedValue(selectedAgent, 350)
  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  const moveToTop = (agentId: number) => {
    if (map && agentId && showMarkerLayer) {
      map.moveLayer(`textId-${agentId}`)
      map.moveLayer(`immoId-${agentId}`)
      map.moveLayer(`okId-${agentId}`)
      map.moveLayer(`alarmId-${agentId}`)
      map.moveLayer(`idleId-${agentId}`)
      map.moveLayer(`defId-${agentId}`)
      map.moveLayer('pointer')
    }
  }

  const handlePointClick = useCallback((e: MouseEvent) => {
    e.originalEvent.preventDefault()
    e.originalEvent.stopPropagation()
    if (map) {
      const features = map.queryRenderedFeatures(e.point)
      if (features[0]) {
        const { agentId } = features[0].properties as Agent
        if (agentId) {
          handleAgentClick(agentId)
        }
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('click', handlePointClick)
    }
    return () => {
      if (map) {
        map.off('click', handlePointClick)
      }
    }
  }, [map])

  useEffect(() => {
    if (debouncedAgent) {
      moveToTop(debouncedAgent.agentId)
    }
  }, [debouncedAgent])

  if (!showMarkerLayer) {
    return null
  }

  return agentClusters.map((cluster, i) => {
    const [longitude, latitude] = cluster.geometry.coordinates
    const {
      cluster: isCluster,
      point_count: pointCount
    } = cluster.properties

    return isCluster
      ? (
        <ClusterMarker
          key={`${cluster.id}-${i}`}
          clusterId={cluster.id}
          latitude={latitude}
          longitude={longitude}
          pointCount={pointCount ?? 0}
          totalPoints={agentPoints.length}
          onClick={handleClusterClick}
          mapRef={mapRef}
        />
        )
      : (
        <Marker
          mapRef={mapRef}
          key={cluster.properties.agent.agentId}
          agent={cluster.properties.agent}
          selectedAgent={selectedAgent}
          onClick={handleAgentClick}
        />
        )
  })
}
