import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type SensorDataLog } from '@venturi-io/api/src/collector/sensor'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'

export default function Row ({
  agentName,
  eventLog,
  sensorId,
  sensorName,
  sensorInstanceId,
  sensorInstanceName,
  timestamp
}: SensorDataLog & ListRow) {
  const { classes } = useStyles()

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(dayjs(timestamp).format(uiDateFormat))}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(agentName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(sensorId)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(sensorName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(sensorInstanceId)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(sensorInstanceName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(eventLog)}</Text>
      </div>
    </div>
  )
}
