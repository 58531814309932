import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createAlarmWebhook,
  deleteAlarmWebhook,
  updateAlarmWebhook,
  type AlarmWebhook
} from '@venturi-io/api/src/analytics/alarmWebhook'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'

interface Props {
  mode: FormMode
  initialValues?: AlarmWebhook
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { token } = useUser()
  const navigate = useNavigate()
  const newAlarmWebhook = useApi(createAlarmWebhook)
  const editAlarmWebhook = useApi(updateAlarmWebhook)
  const removeAlarmWebhook = useApi(deleteAlarmWebhook)
  const [showDelete, setShowDelete] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const hasValue = (inputName: string, value: string) => value.length < 1 ? `Please specify ${inputName}` : null

  const form = useForm<Omit<AlarmWebhook, 'id'>>({
    initialValues: initialValues ?? {
      name: '',
      url: '',
      alarmKey: '',
      clearKey: ''
    },
    validate: {
      name: value => hasValue('Name', value),
      url: value => hasValue('URL', value),
      alarmKey: value => hasValue('Alarm Key', value),
      clearKey: value => hasValue('Clear Key', value)
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = useCallback(() => navigate('/my-admin/alarm-webhooks'), [])

  const handleCreate = useCallback((values: FormValues) => {
    void newAlarmWebhook
      .fetch(values, token, 'Successfully created new alarm webhook')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }, [form.values])

  const handleUpdate = useCallback((values: FormValues) => {
    const webhookId = initialValues?.id

    if (!webhookId) throw new Error('Unable to perform the action, no alarm webhook provided')

    void editAlarmWebhook
      .fetch({
        webhookId,
        ...values
      }, token, 'Successfully updated alarm webhook')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const webhookId = initialValues?.id

    if (!webhookId) throw new Error('Unable to perform the action, no alarm webhook provided')

    void removeAlarmWebhook
      .fetch({ webhookId }, token, 'Succesfully deleted alarm webhook')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    if (initialValues) form.setValues(initialValues)
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Url" {...form.getInputProps('url')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Alarm Key" {...form.getInputProps('alarmKey')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Clear Key" {...form.getInputProps('clearKey')} />
          </Col>
          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="sm">
                <Button
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode ? newAlarmWebhook.loading : editAlarmWebhook.loading}
              >
                {inCreateMode ? 'Create' : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeAlarmWebhook.loading}
          question="Are you sure you want to delete this alarm webhook? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
