import { Center, Paper, SimpleGrid, Text } from '@mantine/core'

const statuses = [
  'Off',
  'Manual',
  'Auto',
  'Test'
] as const

export type AtsStatus = typeof statuses[number]

export default function Status ({ value }: { value: AtsStatus }) {
  return (
    <SimpleGrid cols={4} px={15} spacing="xl">
      {statuses.map(status => (
        <Paper
          key={status}
          radius="md"
          p="md"
          sx={theme => ({
            color: status === value
              ? 'white'
              : 'black',
            background: status === value
              ? theme.colors.green[4]
              : theme.colors.gray[2]
          })}
        >
          <Center>
            <Text>{status}</Text>
          </Center>
        </Paper>
      ))}
    </SimpleGrid>
  )
}
