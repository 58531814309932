import { StyleSheet } from '@react-pdf/renderer'

export const reportStyles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 20,
    lineHeight: 1.1,
    flexDirection: 'column'
  },
  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#3E3E3E'
  },
  stack: {
    flexDirection: 'column',
    gap: 2
  },
  group: {
    flexDirection: 'row'
  },
  stretch: {
    flex: 1
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    fontStyle: 'bold'
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 10,
    color: 'black'
  },
  subtitleValue: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontStyle: 'bold',
    fontWeight: 700,
    color: 'black'
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 48,
    paddingTop: 12,
    paddingBottom: 24
  },
  space: {
    marginTop: 10
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  image: {
    // 1280 x 720 converted to 750 x 422 to maintain aspect ration of 16:9
    width: 800,
    height: 450
  }
})

export const tableStyles = StyleSheet.create({
  tHead: {
    backgroundColor: '#E7E7E7',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 'bold'
  },
  td: {
    fontFamily: 'Inter',
    fontSize: 8
  },
  cell: {
    fontFamily: 'Inter',
    flex: 1,
    alignItems: 'flex-start',
    padding: 4,
    border: 0.5,
    color: '#2F2F2F',
    borderColor: '#BDBDBD'
  }
})
