import { Table as MantineTable } from '@mantine/core'
import { type Props } from '..'
import Rows from './Rows'
import Head from './Head'

export default function DesktopTable ({ transactions }: Props) {
  return (
    <MantineTable fontSize={14} withColumnBorders={false} withBorder={false}>
      <Head />
      <tbody>
        <Rows transactions={transactions} />
      </tbody>
    </MantineTable>
  )
}
