import {
  Alert,
  Collapse,
  type MantineSize,
  Text,
  createStyles,
  Divider,
  SimpleGrid,
  Button,
  Container
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

interface Props {
  assetName?: string
  missingSensors?: string[]
}

const useStyles = createStyles(theme => ({
  title: {
    color: theme.colors.primary[6]
  }
}))

export default function MissingSensorMessage ({ assetName, missingSensors }: Props) {
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const { classes } = useStyles()

  const titleProps = {
    className: classes.title,
    size: 'sm' as MantineSize,
    fs: 'italic'
  }

  return (
    <Alert
      title="Missing Sensor"
      icon={<FontAwesomeIcon icon={['far', 'info-circle']} />}
      color="yellow"
      pb="lg"
      mb="sm"
    >
      {`There's a missing sensor for ${assetName ?? 'some of the following assets'}.
        Please ensure that all required/primary sensors were configured completely.`}
      {missingSensors && missingSensors?.length > 0 && (
        <Container sx={{ padding: '15px 0 0 0' }} size="100%">
          <Button
            variant="outline"
            color="primary"
            onClick={() => setIsOpenDetails(!isOpenDetails)}
            size="sm"
            leftIcon={<FontAwesomeIcon icon={['fas', 'bell']} color="primary" />}
          >
            {isOpenDetails ? 'Hide' : 'Show'}
            {' '}
            Missing Sensors
          </Button>
          <Collapse in={isOpenDetails}>
            <Divider my="lg" variant="dotted" />
            <SimpleGrid cols={2}>
              {missingSensors?.map((sensor, index) => (
                <Text {...titleProps} key={index}>
                  {sensor}
                </Text>
              ))}
            </SimpleGrid>
          </Collapse>
        </Container>
      )}

    </Alert>
  )
}
