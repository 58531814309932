import {
  type ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type GeoZone, findGeoZones } from '@venturi-io/api/src/config/geoZone'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  excludeRoleIds?: number[]
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const roleToSelect = ({ name, geoZoneId }: GeoZone): SelectItem => ({
  value: `${geoZoneId}`,
  label: name
})

export default function MultiSelectGeozone ({
  excludeRoleIds,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const geozones = usePaginatedApi(findGeoZones)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectGeozones = useMemo(() => {
    const allGeoZones = geozones.data.mapOrDefault(data => data.items, [])
    const filteredGeozones = allGeoZones.filter(
      ({ geoZoneId }) => !excludeRoleIds?.some(id => id === geoZoneId)
    )
    return filteredGeozones.map(roleToSelect)
  }, [geozones.data])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void geozones.fetch({ orgId, page: 1, size: 999 }, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose geozone(s)'}
      value={value}
      data={selectGeozones}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || geozones.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
