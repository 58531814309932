import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { site } from '../userManager/site'
import {
  type GeoZoneIdRequest,
  type OrgIdRequest,
  type PaginatedRequest,
  paginated,
  geoLocation,
  lastLocation
} from '../shared'
import { agentGroup } from './agentGroup'
import {
  agentUser,
  agentOwner,
  agentStatuses,
  alarmStatuses,
  connectionStatuses
} from './agent'
import { geoZoneRule } from './geoZoneRule'

const geoZoneBoundaryType = z.enum(['Polygon'])

const geoZone = z.object({
  geoZoneId: z.number(),
  boundary: z.object({
    type: geoZoneBoundaryType,
    coordinates: z.array(z.array(z.tuple([z.number(), z.number()])))
  }),
  name: z.string(),
  description: z.optional(z.string()),
  orgId: z.optional(z.number()),
  agentGroups: z.optional(z.array(agentGroup.pick({
    agentGroupId: true
  }))),
  hiddenOnMap: z.boolean()
})

const geoZoneRuleWithActions = geoZoneRule.extend({
  geoZoneActions: z.array(z.object({
    geoZoneActionId: z.number(),
    name: z.string(),
    description: z.string(),
    geoZoneRuleActionId: z.number(),
    geoZoneRuleActionDescription: z.string()
  }))
})

export type GeoZoneRuleWithActions = z.infer<typeof geoZoneRuleWithActions>

const geoZoneRulesAndActions = z.object({
  geoZoneId: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  geoZoneRules: z.optional(z.array(geoZoneRuleWithActions))
})

// All agents and geozones
const agents = z.array(z.object({
  agentId: z.number(),
  agentName: z.string(),
  assetType: z.optional(z.string()),
  geoLocation: z.optional(geoLocation),
  lastLocation,
  iconStyle: z.optional(z.string()),
  metadata: z.optional(z.record(z.string())),
  user: z.optional(agentUser),
  owner: z.optional(agentOwner),
  lastSeenTime: z.optional(z.string()),
  site: z.optional(
    site.pick({
      siteId: true,
      name: true
    })
  ),
  alarmStatus: z.enum(alarmStatuses),
  agentStatus: z.optional(z.enum(agentStatuses)),
  connectionStatus: z.optional(z.enum(connectionStatuses)),
  hasImmobiliser: z.boolean()
}))

const geoZones = z.array(geoZone)

export type Agent = z.infer<typeof agents>[0]

export type GeoZone = z.infer<typeof geoZones>[0]

export const geoZonesAndAgents = z.object({ geoZones, agents })

interface GeoZonesAndAgentsInput extends Record<string, unknown> {
  orgId: number
}

export const getGeoZonesAndAgents = createStatelessApi<GeoZonesAndAgentsInput, typeof geoZonesAndAgents>(
  'config',
  '/agents/zones',
  { method: 'GET', type: 'param' },
  geoZonesAndAgents
)

// Geo Zones
const zones = paginated.extend({
  items: geoZones
})

export interface GeoZoneRequest extends GeoZone {}

interface FindGeoZonesRequest extends PaginatedRequest {
  orgId: number
}

export const findGeoZones = createStatelessApi<FindGeoZonesRequest, typeof zones>(
  'config',
  '/org/:orgId/geo/zones',
  { method: 'GET', type: 'path' },
  zones
)

export const getGeoZone = createStatelessApi<GeoZoneIdRequest, typeof geoZone>(
  'config',
  '/geo/zone/:geoZoneId',
  { method: 'GET', type: 'path' },
  geoZone
)

export const getGeoZoneRulesAndActions = createStatelessApi<GeoZoneIdRequest, typeof geoZoneRulesAndActions>(
  'config',
  '/geo/zone/:geoZoneId/rules/actions',
  { method: 'GET', type: 'path' },
  geoZoneRulesAndActions
)

export const createGeoZone = createStatefulApi<Omit<GeoZoneRequest, 'geoZoneId'>, typeof geoZone>(
  'config',
  '/geo/zone',
  { method: 'POST' },
  geoZone
)

export const updateGeoZone = createStatefulApi<GeoZoneRequest, typeof geoZone>(
  'config',
  '/geo/zone/:geoZoneId',
  { method: 'PUT' },
  geoZone
)

export const deleteGeoZone = createStatefulApi<GeoZoneIdRequest, z.ZodBoolean>(
  'config',
  '/geo/zone/:geoZoneId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

interface AgentGroupAssignmentRequest extends GeoZoneIdRequest {
  agentGroupIds: number[]
}

export const assignAgentGroupsToGeoZone = createStatefulApi<AgentGroupAssignmentRequest, z.ZodBoolean>(
  'config',
  '/geo/zone/:geoZoneId/agent-groups',
  { method: 'POST' },
  z.boolean()
)

export const removeAgentGroupsFromGeoZone = createStatefulApi<AgentGroupAssignmentRequest, z.ZodBoolean>(
  'config',
  '/geo/zone/:geoZoneId/agent-groups',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

const geoZonesWithAgentCount = z.object({
  items: z.array(
    geoZone
      .pick({
        geoZoneId: true,
        name: true,
        description: true
      })
      .extend({
        agentCount: z.number()
      })
  )
})

export const getAgentsInGeoZones = createStatelessApi<OrgIdRequest, typeof geoZonesWithAgentCount>(
  'config',
  '/org/:orgId/geo/agent/count',
  { method: 'GET', type: 'path' },
  geoZonesWithAgentCount
)
