import { useEffect } from 'react'
import {
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Radio,
  Stack,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSensor } from '@venturi-io/api/src/config/sensor'
import { createSensorInstance } from '@venturi-io/api/src/config/sensorInstance'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'
import IconSelector from 'src/Input/IconSelector'
import SelectSensor from 'src/Input/Select/SelectSensor'
import SelectScaleFactor from 'src/Input/Select/SelectScaleFactor'

interface Props {
  agentId: number
  show: boolean
  close: () => void
  onCreate: () => void
}

export default function Create ({ agentId, show, close, onCreate }: Props) {
  const sensor = useApi(getSensor)
  const create = useApi(createSensorInstance)
  const { token } = useUser()

  const form = useForm({
    initialValues: {
      agentId,
      sensorId: -1,
      name: '',
      primaryOrSystem: 'primary',
      sensorDataTransformerId: undefined
    },
    validate: {
      sensorId: value => value === -1 ? 'Please choose a Sensor' : null,
      name: value => value.length < 1 ? 'Please specify Name' : null
    }
  })

  const handleSubmit = (values: typeof form.values) => {
    const body = {
      ...values,
      sensorDataTransformerId: values.sensorDataTransformerId
        ? Number(values.sensorDataTransformerId)
        : undefined,
      primaryFunction: values.primaryOrSystem === 'primary',
      systemOnly: values.primaryOrSystem === 'system'
    }
    void create
      .fetch(body, token)
      .finally(() => {
        form.reset()
        onCreate()
        close()
      })
  }

  useEffect(() => {
    sensor.data.ifJust(({ iconStyle }) => {
      form.setFieldValue('iconStyle', iconStyle)
    })
  }, [sensor.data])

  useEffect(() => {
    if (form.values.sensorId !== -1) {
      void sensor.fetch({
        sensorId: form.values.sensorId
      }, token)
    }
  }, [form.values.sensorId])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Sensor Instance</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Col span={12}>
            <SelectSensor
              label="Sensor"
              withAsterisk
              searchable
              {...form.getInputProps('sensorId')}
            />
          </Col>
          <Col span={12}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col span={12}>
            <IconSelector
              label="Icon"
              width="88%"
              {...form.getInputProps('iconStyle')}
            />
          </Col>
          <Col span={12}>
            <SelectScaleFactor
              searchable
              clearable
              label="Scale Factor"
              {...form.getInputProps('sensorDataTransformerId')}
            />
          </Col>
          <Col span={12}>
            <Radio.Group
              withAsterisk
              value={form.values.primaryOrSystem}
              onChange={(value) => form.setFieldValue('primaryOrSystem', value)}
            >
              <Stack pt="md">
                <Radio
                  color="primary"
                  value="primary"
                  label="Primary function"
                />
                <Radio
                  color="primary"
                  value="system"
                  label="System only"
                />
              </Stack>
            </Radio.Group>
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={() => {
                  form.reset()
                  close()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={create.loading}
              >
                Create
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
