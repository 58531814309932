import { useMantineTheme } from '@mantine/core'
import { type ColorGradient, type Size, type Padding } from './shared'

interface Theme {
  backgroundColor?: ColorGradient
  textColor: React.CSSProperties['color']
}

interface Styles {
  width: number
  height: number
  padding: Padding
  align?: React.CSSProperties['alignItems']
  textSize?: Size
  theme?: Theme
  withoutTitle?: boolean
}

export const defaultStyles: Styles = {
  width: 500,
  height: 300,
  padding: {
    top: 0,
    right: 15,
    bottom: 0,
    left: 15
  },
  align: 'center',
  textSize: 'md',
  withoutTitle: false
}

const textSizeDivisor: Record<Size, number> = {
  xs: 40,
  sm: 35,
  md: 30,
  lg: 25,
  xl: 20
}

export interface Props {
  name: string
  value: string
  styles: Styles
}

export default function AutoGrid ({
  name,
  value,
  styles = defaultStyles
}: Props) {
  const { colors } = useMantineTheme()
  const {
    width,
    height,
    padding,
    theme,
    align,
    textSize,
    withoutTitle
  } = styles
  const { top, right, bottom, left } = padding

  const containerSize = width + height

  // set default theme
  const textColor = theme?.textColor ?? colors.gray[1]
  const backgroundColor: ColorGradient = theme?.backgroundColor ?? {
    primary: colors.primary[4],
    secondary: colors.primary[6]
  }

  return (
    <div
      style={{
        position: 'relative',
        backgroundImage: `linear-gradient(to bottom right, ${backgroundColor?.primary}, ${backgroundColor?.secondary})`,
        width,
        height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: align,
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        gap: '2px',
        overflow: 'hidden',
        textAlign: 'center'
      }}
    >
      {!withoutTitle && (
        <span
          style={{
            fontSize: `${containerSize / 28}px`,
            color: textColor
          }}
        >
          {name}
        </span>
      )}
      <span
        style={{
          fontSize: `${containerSize / textSizeDivisor[textSize ?? 'md']}px`,
          fontWeight: 600,
          color: textColor
        }}
      >
        {value}
      </span>
    </div>
  )
}
