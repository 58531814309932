import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type SelectItem, Select, ActionIcon } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import {
  type CellTemplate,
  type Compatible,
  getCellProperty,
  type Uncertain,
  type Cell,
  type UncertainCompatible,
  isAlphaNumericKey,
  keyCodes
} from '@silevis/reactgrid'
import { useRef } from 'react'
import ControlTooltip from './ControlTooltip'
import { type DataTypes } from './shared'

export interface SelectCell extends Cell {
  type: 'select'
  label: string
  options: SelectItem[]
  selectedValue?: string | null
  hasAdd: boolean
  onClick?: () => void
}

interface CellProps {
  uncertainCell: Uncertain<SelectCell>
  name: keyof SelectCell
  type: DataTypes
}

function parseCellProperty ({ uncertainCell, name, type }: CellProps) {
  let value: unknown
  try {
    value = getCellProperty(uncertainCell, name, type)
  } catch (err) {
    value = undefined
  }
  return value
}

export class SelectRowCell implements CellTemplate<SelectCell> {
  getCompatibleCell (uncertainCell: Uncertain<SelectCell>): Compatible<SelectCell> {
    const selectedValue = parseCellProperty({ uncertainCell, name: 'selectedValue', type: 'string' }) as string
    const label = parseCellProperty({ uncertainCell, name: 'label', type: 'string' }) as string
    const options = parseCellProperty({ uncertainCell, name: 'options', type: 'object' }) as SelectItem[]
    const hasAdd = parseCellProperty({ uncertainCell, name: 'hasAdd', type: 'boolean' }) as boolean
    const onClick = parseCellProperty({ uncertainCell, name: 'onClick', type: 'function' }) as () => void
    const value = selectedValue ? parseFloat(selectedValue) : NaN
    const text = selectedValue ?? ''
    return { ...uncertainCell, selectedValue, text, value, options, label, onClick, hasAdd }
  }

  update (cell: Compatible<SelectCell>, cellToMerge: UncertainCompatible<SelectCell>): Compatible<SelectCell> {
    return this.getCompatibleCell({ ...cell, selectedValue: cellToMerge.selectedValue })
  }

  handleKeyDown (
    cell: Compatible<SelectCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean): {
      cell: Compatible<SelectCell>
      enableEditMode: boolean
    } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return { cell: this.getCompatibleCell({ ...cell }), enableEditMode: false }
    }
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) {
      return {
        cell: this.getCompatibleCell({ ...cell }),
        enableEditMode: false
      }
    }
    return { cell, enableEditMode: false }
  }

  render (
    cell: Compatible<SelectCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SelectCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <SelectDropdown
        onCellChanged={(cell) => onCellChanged(this.getCompatibleCell(cell), true)}
        cell={cell}
      />
    )
  }
}

interface SDProps {
  onCellChanged: (cell: Compatible<SelectCell>, commit: boolean) => void
  cell: Compatible<SelectCell>
}

const SelectDropdown = ({ onCellChanged, cell }: SDProps) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <div onPointerDown={(e) => e.stopPropagation()}>
      <Select
        withinPortal
        ref={ref}
        key={`${cell.selectedValue}-${randomId()}`}
        searchable
        placeholder={cell.label}
        value={cell.selectedValue}
        data={cell.options}
        onChange={val => onCellChanged({ ...cell, selectedValue: val }, true)}
        rightSection={cell.hasAdd
          ? (
            <ControlTooltip label={`Add ${cell.label}`} position="right">
              <ActionIcon onClick={cell.onClick}>
                <FontAwesomeIcon icon={['fas', 'plus']} color="dark" />
              </ActionIcon>
            </ControlTooltip>
            )
          : null}
      />
    </div>
  )
}
