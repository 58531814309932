import { useCallback, useEffect } from 'react'
import {
  Button,
  Col,
  Drawer,
  Group,
  Grid,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { assignAgentsToGroup } from '@venturi-io/api/src/config/agentGroup'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelectAgent from 'src/Input/MultiSelect/MultiSelectAgent'
import { getAgentsForAgentGroup } from '@venturi-io/api/src/config/agent'

interface Props {
  agentGroupId: number
  show: boolean
  onClose: () => void
  onSave?: () => Promise<void>
}

const AgentAssignment = ({ agentGroupId, show, onClose, onSave }: Props) => {
  const { token } = useUser()
  const addAgentsToGroup = useApi(assignAgentsToGroup)
  const agents = usePaginatedApi(getAgentsForAgentGroup)

  const form = useForm<{ agentIds: string[] }>({
    initialValues: {
      agentIds: []
    },
    validate: {
      agentIds: value => value.length < 1 ? 'Please select at least one agent' : null
    }
  })

  const loadExistingAgents = async (): Promise<void> => {
    void agents
      .fetch({
        agentGroupId,
        page: 1,
        size: 99999
      }, token)
  }

  const handleSubmit = useCallback(({ agentIds }: typeof form.values) => {
    if (agentIds.length) {
      void addAgentsToGroup
        .fetch({
          agentGroupId,
          agentIds: agentIds.map(id => Number(id))
        }, token, 'Successfully added agent to this group')
        .finally(() => {
          form.reset()

          onClose()

          if (onSave) void onSave()
        })
    }
  }, [form.values])

  const handleClose = useCallback(() => {
    form.reset()

    onClose()
  }, [])

  useEffect(() => {
    addAgentsToGroup.data.ifJust(() => {
      void loadExistingAgents()
    })
  }, [addAgentsToGroup.data])

  useEffect(() => {
    void loadExistingAgents()
  }, [agents.page])

  return (
    <Drawer
      opened={show}
      onClose={onClose}
      title={<Title order={3}>Add Agent</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>

          <Col span={12}>
            <MultiSelectAgent
              {...form.getInputProps('agentIds')}
              excludeAgentIds={agents.data.mapOrDefault(({ items }) =>
                items.map(item => item.agentId), []
              )}
              label="Agents"
              value={form.values.agentIds}
              onChange={ids => (
                form.setFieldValue('agentIds', ids)
              )}
              searchable
            />
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', 'circle-plus']}
                    color="white"
                  />
                )}
                disabled={addAgentsToGroup.loading}
              >
                Add
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}

export default AgentAssignment
