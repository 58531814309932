import { z } from 'zod'
import { createStatelessApi } from '../configure'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { paginated, type PaginatedRequest } from '../shared'

export const eventTypes = [
  'LPR',
  'RFID/NFC',
  'Pol',
  'Frame'
] as const

export type EventType = typeof eventTypes[number]

export const cameraEvent = z.object({
  eventId: z.number(),
  cameraId: z.number(),
  dateTime: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  length: z.string(),
  eventType: z.optional(z.enum(eventTypes)),
  tagId: z.optional(z.string()),
  poi: z.optional(z.string()),
  lpr: z.optional(z.string()),
  viewEvent: z.optional(z.string())
})

const cameraEvents = paginated.extend({
  items: z.array(cameraEvent)
})

export type CameraEvent = z.infer<typeof cameraEvent>

export const getAllCameraEvents = createStatelessApi<PaginatedRequest, typeof cameraEvents>(
  'config',
  '/camera/events',
  { method: 'GET', type: 'path' },
  cameraEvents
)

interface EventsForCameraRequest extends PaginatedRequest {
  cameraId: number
}

export const getEventsForCamera = createStatelessApi<EventsForCameraRequest, typeof cameraEvents>(
  'config',
  '/camera/events/:cameraId',
  { method: 'GET', type: 'path' },
  cameraEvents
)
