import { type ReactNode, useCallback } from 'react'
import { type SelectProps, Text, type SelectItem } from '@mantine/core'
import { userMachineTz } from 'src/utils/dates'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  isOrgTimezone?: boolean
  showInfo?: boolean
  onChange?: (value: Value) => void
  setExternalUserId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const timezoneToSelect = (timezone: string): SelectItem => ({
  value: timezone,
  label: timezone
})

export default function SelectTimezone ({
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  showInfo = true,
  error,
  isOrgTimezone,
  ...props
}: Props) {
  const selectTimezone = Intl.supportedValuesOf('timeZone').map(timezone => timezoneToSelect(timezone), [])

  const handleChange = useCallback((val: Value) => {
    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  return (
    <>
      <Select
        label={label}
        placeholder={placeholder ?? 'Choose time zone'}
        data={selectTimezone}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        required={required}
        searchable={searchable}
        error={error}
        {...props}
      />
      {showInfo && (
        <Text italic size="xs">
          {isOrgTimezone
            ? `Default org timezone(${userMachineTz})`
            : `Default user timezone(${userMachineTz})`}
        </Text>
      )}
    </>
  )
}
