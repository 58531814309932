import { useEffect, useState } from 'react'
import {
  useMantineTheme,
  Button,
  Col,
  Grid,
  Group,
  Paper,
  Stack,
  Title,
  Menu
} from '@mantine/core'
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getUsers } from '@venturi-io/api/src/userManager/user'
import { getRoles } from '@venturi-io/api/src/userManager/role'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import { mq } from 'src/utils/style'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header as UserListHeader, Row as UserListRow, Card as UserListCard } from './UserList'
import { Header as RoleListHeader, Row as RoleListRow, Card as RoleListCard } from './RoleList'
import AddUser from './Add'
import InviteUser from './Invite'

export default function Users () {
  const theme = useMantineTheme()
  const { orgId, token } = useUser()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const [showAddForm, setShowAddForm] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [sentInvite, setSentInvite] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)
  const users = usePaginatedApi(getUsers)
  const roles = usePaginatedApi(getRoles)

  const loadUsers = async (page?: number, size?: number): Promise<void> => {
    void users
      .fetch({
        orgId,
        page: page ?? users.page,
        size: size ?? users.size,
        search: debouncedSearch === ''
          ? undefined
          : debouncedSearch
      }, token)
  }

  const loadRoles = async (page?: number, size?: number): Promise<void> => {
    void roles
      .fetch({
        orgId,
        page: page ?? roles.page,
        size: size ?? roles.size
      }, token)
  }

  useEffect(() => {
    void loadUsers(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadUsers()

    if (sentInvite) {
      setSentInvite(false)
    }
  }, [users.page, sentInvite])

  useEffect(() => {
    void loadRoles()
  }, [roles.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Menu
          withArrow
          position="bottom-end"
          transitionProps={{
            transition: 'pop-bottom-left'
          }}
          width={150}
          shadow="lg"
        >
          <Menu.Target>
            <Button
              title="Add User"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'user-plus']} color="white" />}
            >
              Add new
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setShowAddForm(true)}>
              Contact
            </Menu.Item>
            <Menu.Item onClick={() => setShowInviteForm(true)}>
              Platform User
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Paper mt="md" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} md={9}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search user here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={users.loading}
        data={users.data.mapOrDefault(({ items }) => items, [])}
        getId={({ orgUserId }) => orgUserId}
        header={() => isDesktop ? <UserListHeader /> : <div></div>}
        row={props => (
          <UserListRow
            setSentInvite={setSentInvite}
            {...props}
          />
        )}
        card={props => (
          <UserListCard
            setSentInvite={setSentInvite}
            {...props}
          />
        )}
        totalPages={users.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={users.page}
        onPageChange={users.setPage}
      />
      <Stack spacing={0} mt="xl">
        <Title order={2}>Roles</Title>
        <ActionList
          isLoading={roles.loading}
          data={roles.data.mapOrDefault(({ items }) => items, [])}
          getId={({ roleId }) => roleId}
          header={() => isDesktop ? <RoleListHeader /> : <div></div>}
          row={RoleListRow}
          card={RoleListCard}
          totalPages={roles.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
          page={roles.page}
          onPageChange={roles.setPage}
        />
      </Stack>
      <AddUser
        show={showAddForm}
        close={() => setShowAddForm(false)}
        onCreate={loadUsers}
      />
      <InviteUser
        show={showInviteForm}
        close={() => setShowInviteForm(false)}
        onCreate={loadUsers}
      />
    </>
  )
}
