/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedTripItem } from '../TripReportView'

interface Props {
  report: Report
  data: ParsedTripItem[]
  range: Range | null
}

const formatToReadableDate = (date?: string) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  report: Report
  range: Range | null
}
const Details = ({ report, range }: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Report type"
      value={report.reportType.name}
    />
    <LabelValue
      label="Period Range"
      value={range
        ? `${formatToReadableDate(range.from)} - ${formatToReadableDate(range.to)}`
        : '--'}
    />
  </View>
)

interface Value {
  value: string
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Agent Name" />
    <THCell value="Alarm Status" />
    <THCell value="# Trips" />
    <THCell value="Total Duration" />
    <THCell value="Total Distance KMs" />
  </View>
)

const TableRow = ({
  agent_name,
  alarm_status,
  total_trips,
  total_duration,
  total_distance_in_kilometres
}: ParsedTripItem) => (
  <View style={reportStyles.group}>
    <TDCell value={agent_name} />
    <TDCell value={alarm_status} />
    <TDCell value={total_trips} />
    <TDCell value={total_duration} />
    <TDCell value={total_distance_in_kilometres} />
  </View>
)

interface TableBodyProps {
  data: ParsedTripItem[]
}
const TableBody = ({ data }: TableBodyProps) => data.map(item => (
  <TableRow key={item.agent_id} {...item} />
))

const Space = () => <View style={reportStyles.space} />

function TripHistory ({ report, data, range }: Props) {
  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details report={report} range={range} />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default TripHistory
