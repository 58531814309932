import { memo, useCallback, useMemo } from 'react'
import { Accordion, Button, Stack } from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectAgent from 'src/Input/Select/SelectAgent'
import ItemPreview from '../shared/ItemPreview'
import AgentQuickView, { type Props as AgentQuickViewProps } from '.'

interface Props {
  initialValues: AgentQuickViewProps | null
  onSubmit: (properties: AgentQuickViewProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: AgentQuickViewProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        agentId: -1
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const hasSelectedAgent = useMemo(() => form.values.data.agentId > 0, [form.values.data.agentId])

  const setFormDataValue = useCallback((
    key: string,
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview
        iconName="binoculars"
        previewItem={hasSelectedAgent}
        disabled
      >
        <AgentQuickView {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectAgent
                    label="Agent"
                    value={form.values.data.agentId.toString()}
                    onChange={agentId => setFormDataValue('agentId', Number(agentId))}
                    required
                    searchable
                    error={form.errors.agentId}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
