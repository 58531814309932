import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Group,
  Paper,
  SimpleGrid,
  Stack
} from '@mantine/core'
import { type Incident } from '@venturi-io/api/src/config/alarmHistory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { parseEmpty } from 'src/utils/parseEmpty'

export default function Card ({ ...incident }: Incident) {
  const navigate = useNavigate()
  const {
    incidentId,
    name,
    setPoint,
    detected,
    cleared,
    operator,
    agentName
  } = incident

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={incidentId.toString()} isKey />
          <ActionIcon
            title="View"
            variant="filled"
            color="primary"
            onClick={() => navigate(`/alarms/incident/${incidentId}`, { state: incident })}
          >
            <FontAwesomeIcon size="sm" icon={['fas', 'eye']} color="white" />
          </ActionIcon>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup label="Agent" value={parseEmpty(agentName)} />
          <TextGroup label="Detected" value={dayjs(detected).format(uiDateFormat)} />
          <TextGroup label="Resolved" value={cleared ? dayjs(cleared).format(uiDateFormat) : 'Active'} />
          <TextGroup label="Alarm" value={`${operator}${setPoint} (${name})`} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
