import { Container, Group, Text } from '@mantine/core'
import { findSensorByType, type Sensors as AgentSensors, useSharedStyles } from '../../shared'
import { MODULE_CONTENT } from '../constants'
import Status, { type AtsStatus as AtsStatusType } from './Status'
import Sensors from './Sensors'

export default function AtsStatus ({
  sensors
}: AgentSensors) {
  const { classes } = useSharedStyles()
  const atsStatus = findSensorByType('ATS_STATUS', sensors)
  const status = atsStatus
    ? atsStatus.currentValue as AtsStatusType
    : 'Off'

  return (
    <Container className={classes.dock} fluid>
      <Group px={15} className={classes.contentTitle} position="apart" noWrap>
        <Text size="sm" weight={500}>
          {MODULE_CONTENT.ATS_STATUS.toUpperCase()}
        </Text>
      </Group>
      <Status value={status} />
      <Sensors instances={sensors} />
    </Container>
  )
}
