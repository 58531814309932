import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createAgentGroup,
  deleteAgentGroup,
  updateAgentGroup,
  assignAgentsToGroup,
  type AgentGroup
} from '@venturi-io/api/src/config/agentGroup'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import MultiSelectAgent from 'src/Input/MultiSelect/MultiSelectAgent'

interface Props {
  mode: FormMode
  initialValues?: AgentGroup
  hasAgent?: boolean
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({
  mode,
  initialValues,
  hasAgent = false,
  onCreate,
  onClose
}: Props) {
  const { token } = useUser()
  const navigate = useNavigate()
  const newAgentGroup = useApi(createAgentGroup)
  const editAgentGroup = useApi(updateAgentGroup)
  const removeAgentGroup = useApi(deleteAgentGroup)
  const addAgentsToGroup = useApi(assignAgentsToGroup)
  const [showDelete, setShowDelete] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<Omit<AgentGroup, 'agentGroupId'> & { agentIds?: string[] }>({
    initialValues: {
      name: '',
      description: '',
      agentIds: []
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null,
      description: value => value.length < 1 ? 'Please specify Description' : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/settings/agent-groups')

  const handleCreate = useCallback(({ name, description }: FormValues) => {
    void newAgentGroup.fetch({
      name,
      description
    }, token, 'Successfully created new agent group')
  }, [form.values])

  const handleUpdate = useCallback(({ name, description }: FormValues) => {
    const agentGroupId = initialValues?.agentGroupId

    if (!agentGroupId) throw new Error('Unable to perform the action, no agent group provided')

    void editAgentGroup
      .fetch({
        agentGroupId,
        name,
        description
      }, token, 'Successfully updated agent group')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const agentGroupId = initialValues?.agentGroupId

    if (!agentGroupId) throw new Error('Unable to perform the action, no agent group provided')

    void removeAgentGroup
      .fetch({ agentGroupId }, token, 'Succesfully deleted agent group')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    newAgentGroup.data.ifJust(({ agentGroupId }) => {
      if (form.values.agentIds?.length) {
        void addAgentsToGroup.fetch({
          agentGroupId,
          agentIds: form.values.agentIds.map(id => Number(id))
        }, token)
      }

      form.reset()
      if (onClose) onClose()
      if (onCreate) void onCreate()
    })
  }, [newAgentGroup.data])

  useEffect(() => {
    if (initialValues) form.setValues(initialValues)
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Description" {...form.getInputProps('description')} />
          </Col>
          {inCreateMode && (
            <Col {...inputColProps}>
              <MultiSelectAgent
                label="Agents"
                value={form.values.agentIds}
                onChange={ids => (
                  form.setFieldValue('agentIds', ids)
                )}
                searchable
              />
            </Col>
          )}
          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="sm">
                <Tooltip
                  label="You cannot delete a group that has agents"
                  position="right"
                  color="red"
                  opened={isHover && hasAgent}
                  withArrow
                >
                  <Box onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                    <Button
                      color="red"
                      leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                      onClick={() => setShowDelete(true)}
                      disabled={hasAgent}
                    >
                      Delete
                    </Button>
                  </Box>
                </Tooltip>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode ? newAgentGroup.loading : editAgentGroup.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeAgentGroup.loading}
          question="Are you sure you want to delete this agent group? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
