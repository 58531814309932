import { type ReactElement } from 'react'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Text,
  ThemeIcon
} from '@mantine/core'
import SensorHistorySparkLineChart from './Charts/SensorHistorySparkLineChart'

const useStyles = createStyles((theme) => ({
  container: {
    flex: '1 1 150px',
    background: 'white',
    height: 200,
    minWidth: 0,
    padding: '8px 0px',
    overflow: 'clip'
  },
  icon: {
    height: 32,
    color: theme.colors.dark[0],
    background: 'transparent'
  },
  name: {
    color: theme.colors.dark[3],
    lineHeight: 'normal'
  },
  valueContainer: {
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export interface DiagnosticMetricItem {
  sensorInstanceId: number
  name: string
  icon: ReactElement
  value: number
  displayValue: string
  displayValueUnit: string
  label?: string
  lowerLabel?: string
  disableChart?: boolean
}

export default function DiagnosticMetric ({
  sensorInstanceId,
  name,
  icon,
  displayValue,
  displayValueUnit,
  label,
  lowerLabel,
  disableChart = false
}: DiagnosticMetricItem) {
  const { classes } = useStyles()

  return (
    <Paper className={classes.container}
      radius={8}
      shadow="xs"
      style={lowerLabel
        ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }
        : {}}
    >
      <Group pl={12} pr={4} position="apart" align="center">
        <Text
          className={classes.name}
          size={12}
          weight={500}
        >
          {name}
        </Text>
        <ThemeIcon className={classes.icon}>
          {icon}
        </ThemeIcon>
      </Group>
      <Box className={classes.valueContainer}>
        <Group spacing={2} align="baseline">
          <Text
            size={32}
            weight={700}
            align="center"
          >
            {displayValue}
          </Text>
          <Text size={16} weight={600}>
            {displayValueUnit}
          </Text>
        </Group>
        {label && (
          <Text
            size={14}
            weight={500}
            color="dark.3"
            align="center"
          >
            {label}
          </Text>
        )}
      </Box>
      {!disableChart && sensorInstanceId !== -1 && (
        <SensorHistorySparkLineChart
          sensorInstanceId={sensorInstanceId}
          height={50}
        />
      )}
      {lowerLabel && (
        <Text
          size={12}
          weight={500}
          color="dark.2"
          align="center"
        >
          {lowerLabel}
        </Text>
      )}
    </Paper>
  )
}
