import { createContext, useContext, type Context } from 'react'
import { type User as RawUser } from '@venturi-io/api/src/userManager/auth'

export interface AuthLoginWithSignout extends RawUser {
  signout: () => void
}

export interface User extends AuthLoginWithSignout {
  token: string
}

let userCtx: Context<AuthLoginWithSignout> | null = null
export function getUserContext (user?: RawUser, signout?: () => void) {
  if (userCtx !== null) return userCtx

  if (!user || !signout) throw new Error('No user context instantiated...')

  userCtx = createContext({
    ...user,
    signout
  })

  return userCtx
}

export const useUser = (): User => {
  const state = useContext(getUserContext())

  return {
    ...state,
    token: state['X-Auth-Token']
  }
}
