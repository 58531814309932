import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text weight={600} size="sm">
          ID
        </Text>
      </div>
      <div className={cx(classes.col)}>
        <Text weight={600} size="sm">
          Date/Time
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Start Time
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          End Time
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Length (h:m:s)
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Event Type
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Tag ID
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          POI
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          LPR
        </Text>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <Text weight={600} size="sm">
          View Event
        </Text>
      </div>
    </div>
  )
}

export default Header
