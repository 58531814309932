// TODO: Remove this import
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { Paper } from '@mantine/core'
import { IconBatteryCharging2, IconBolt, IconDroplet, IconGauge } from '@tabler/icons-react'
import AssetTemplate from 'src/AssetTemplate'
import { type DiagnosticMetricItem } from 'src/AssetTemplate/Components/DiagnosticMetric'
import { findSensor, getSensorValue } from 'src/AssetTemplate/shared'
import Statuses from './Statuses'
import mockAgentGenset from './mockAgentGenset.json'

const Card = () => (
  <Paper
    p="lg"
    radius={0}
    shadow="xs"
    sx={{
      height: 270
    }}
  >
    {'{card}'}
  </Paper>
)

export default function Genset () {
  const {
    agentId,
    agentName,
    assetType,
    metadata,
    geoLocation,
    sensors: sampleSensors,
    lastSeenTime
  } = mockAgentGenset

  // TODO: Map correct sensors
  const sensors = sampleSensors as Sensor[]
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)
  const fuelCapacity = findSensor('FUEL_CAPACITY', sensors)

  const diagnosticMetrics: DiagnosticMetricItem[] = [
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: fuelLevel?.name ?? '-',
      icon: <IconDroplet size={16} />,
      value: Number(fuelLevel?.currentValue ?? 0),
      displayValue: getSensorValue(fuelLevel),
      displayValueUnit: fuelLevel?.unit ?? '-',
      label: `${getSensorValue(fuelConsumption, true)} / ${getSensorValue(fuelCapacity, true)}`
    },
    {
      sensorInstanceId: -1,
      name: 'Power Capacity',
      icon: <IconBolt size={16} />,
      value: 71,
      displayValue: '71',
      displayValueUnit: '%',
      label: '132 / 180 kVA'
    },
    {
      sensorInstanceId: -1,
      name: 'Engine Speed',
      icon: <IconGauge size={16} />,
      value: 1501,
      displayValue: '1501',
      displayValueUnit: 'RPM'
    },
    {
      sensorInstanceId: -1,
      name: 'Battery Voltage',
      icon: <IconBatteryCharging2 size={16} />,
      value: 26.6,
      displayValue: '26.6',
      displayValueUnit: 'V'
    }
  ]

  return (
    <AssetTemplate
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      metadata={metadata}
      // TODO: Update this to geoLocation from AgentDetails type
      geoLocation={geoLocation}
      diagnosticMetrics={diagnosticMetrics}
      statuses={(
        <Statuses
          alarms={sensors.filter(sensor => sensor.alarmStatus === 'ALARM').length}
          engineState="Running" // TODO: Set proper sensor's value
          mode="Auto" // TODO: Set proper sensor's value
        />
      )}
      // TODO: Map correct connectivity status and duration, and total asset runtime
      connectivityStatus="CONNECTED"
      connectivityDuration="21d +5:12"
      lastDataTransfer={lastSeenTime}
      totalAssetRuntime="183 hr"
    >
      <Card />
      <Card />
      <Card />
      <Card />
    </AssetTemplate>
  )
}
