import { useEffect, useState } from 'react'
import { Col, Grid, Paper } from '@mantine/core'
import { getAgents } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'src/Input/Select'
import ActionList from 'src/Layout/ActionList'
import { useDebouncedValue } from '@mantine/hooks'
import { alarmType, type AlarmType } from '@venturi-io/api/src/analytics/alarm'
import { Card, Header, Row } from './List'

export default function VehicleOverview () {
  const agents = usePaginatedApi(getAgents)
  const { token, orgId } = useUser()
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)
  const [alarmTypes, setAlarmTypes] = useState<AlarmType | 'ALL'>('ALL')

  const loadAgents = (page?: number, size?: number) => {
    void agents
      .fetch({
        orgId,
        assetType: 'VEHICLE',
        page: page ?? agents.page,
        size: size ?? agents.size,
        alarmStatus: alarmTypes !== 'ALL'
          ? alarmTypes
          : undefined,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadAgents(1)
  }, [debouncedSearch, alarmTypes])

  useEffect(() => {
    void loadAgents()
  }, [agents.page])

  return (
    <>
      <LoadingOverlay visible={agents.loading} color="primary" />
      <Paper mt="lg" p="sm" shadow="sm" my={0}>
        <Grid grow>
          <Col span={12} md={2}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search asset name here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by status"
              data={['ALL', ...alarmType]}
              onChange={(value: AlarmType) => setAlarmTypes(value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={agents.loading}
        data={agents.data.mapOrDefault(({ items }) => items, [])}
        getId={({ agentId }) => agentId}
        header={() => <Header />}
        row={props => <Row {...props} />}
        card={props => <Card {...props} />}
        totalPages={agents.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={agents.page}
        onPageChange={agents.setPage}
      />
    </>

  )
}
