import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showNotification } from '@mantine/notifications'

export function useNotifications () {
  const showError = (err: Error) => {
    showNotification({
      color: 'red',
      title: 'Error',
      message: err.message
    })
  }

  const showSuccess = (message: string) => {
    showNotification({
      icon: <FontAwesomeIcon icon={['fas', 'check']} />,
      title: 'Success',
      message,
      color: 'teal'
    })
  }

  return {
    showError,
    showSuccess
  }
}
