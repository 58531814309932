import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles, Stack, Text } from '@mantine/core'
import { getAgentsForSite } from '@venturi-io/api/src/config/agent'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import { getSite, type Site } from '@venturi-io/api/src/userManager/site'
import ActionList from 'src/Layout/ActionList'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from '../Form'
import { Header, Row, Card } from './List'

interface RouteParams extends Record<string, string | undefined> {
  siteId: string
}

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  },
  paper: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { token } = useUser()
  const { classes } = useStyles()
  const { siteId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<Site>()
  const site = useApi(getSite)
  const agents = usePaginatedApi(getAgentsForSite)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const siteId = parseInt(id)

  const loadSite = () => {
    void site.fetch({ siteId }, token)
  }

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agents
      .fetch({
        siteId,
        page: page ?? agents.page,
        size: size ?? agents.size
      }, token)
  }

  useEffect(() => {
    site.data.ifJust(setFormInitialValues)
  }, [site.data])

  useEffect(() => {
    loadSite()
    void loadAgents()
  }, [agents.page])

  const hasAgent = agents.data.mapOrDefault(({ items }) => items.length > 0, false)

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} mb={10} relative>
        {site.data.caseOf({
          Nothing: () => (
            <Nothing
              isLoading={site.loading}
              nothing={site.data.isNothing()}
            />
          ),
          Just: () => (
            <Form
              mode="EDIT"
              initialValues={formInitialValues}
              hasAgent={hasAgent}
            />
          )
        })}
      </Paper>
      <Stack mt="lg" spacing={0}>
        <Text size="lg" weight="bold">Agents</Text>
        <ActionList
          isLoading={agents.loading}
          data={agents.data.mapOrDefault(({ items }) => items, [])}
          getId={({ agentId }) => agentId}
          header={() => <Header />}
          row={Row}
          card={Card}
          totalPages={agents.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
          page={agents.page}
          onPageChange={agents.setPage}
        />
      </Stack>
    </div>
  )
}
