import { Stack, Text } from '@mantine/core'

interface Props {
  label: string
  value: string
}

export default function Attribute ({ label, value }: Props) {
  return (
    <Stack spacing={2}>
      <Text size={12} weight={500}>{label}</Text>
      <Text size={12} weight={400}>{value}</Text>
    </Stack>
  )
}
