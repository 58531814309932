/* eslint-disable @typescript-eslint/no-var-requires */
import {
  type Context,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
  createContext,
  useRef,
  type RefObject,
  useCallback
} from 'react'
import { zip } from 'd3-array'
import { interpolateBasis, quantize } from 'd3-interpolate'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { type TripDetails } from '@venturi-io/api/src/collector/trip'
import { type MapRef } from 'react-map-gl'
import { useMediaQuery } from '@mantine/hooks'
import { useMantineTheme } from '@mantine/core'
import { mq } from 'src/utils/style'
import { generateSpeedingDot } from './Layer/speedingDot'

export enum AnimationMode {
  Play = 'play',
  Pause = 'pause',
  Reset = 'reset',
  Stop = 'stop',
  Seek = 'seek'
}
interface TripContext {
  trip: TripDetails | null
  mode: AnimationMode
  currentFrame: number
  cameraPosition: number[]
  pointerAngle: number
  pointerCoordinates: number[]
  frameRange: [number, number]
  isChangingStyle: boolean
  setIsChangingStyle: Dispatch<SetStateAction<boolean>>
  onMapReloadStyle: () => void
  setMode: Dispatch<SetStateAction<AnimationMode>>
  initialSetup: (path: TripDetails) => void
  startAnimation: () => void
  resetAnimation: () => void
  pauseAnimation: () => void
  exitAnimation: () => void
  updatePoints: (point: [number, number]) => void
  resumePoints: (point: [number, number]) => void
  updateAnimation: (toFrame: number) => void
  resumeAnimation: (toFrame: number) => void
}

let tripsCtx: Context<TripContext> | null = null
export function getTripContext (trips?: TripContext) {
  if (tripsCtx !== null) return tripsCtx

  if (!trips) throw new Error('No trips context instantiated...')

  tripsCtx = createContext(trips)

  return tripsCtx
}

let frameStartTime: number
const FPS = 144
const fpsInterval = 45 % FPS

interface AnimationProps {
  activeAgent?: Agent
  children: React.ReactElement | JSX.Element
  mapRef: RefObject<MapRef>
}

export default function AnimationContext ({ activeAgent, children, mapRef }: AnimationProps) {
  // animation
  const { breakpoints: { sm } } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(sm, false))

  const animation = useRef<number>(0)
  const lineCoords = useRef<number[][]>([])
  const cameraPosition = useRef<number[]>([])
  const [trip, setTrip] = useState<TripDetails | null>(null)
  const [interpolated, setInterpolated] = useState<number[][]>([])
  const [directions, setDirections] = useState<number[]>([])
  const [pointerAngle, setPointerAngle] = useState<number>(0)
  const [pointerCoordinates, setPointerCoordinates] = useState<number[]>([])
  const [mode, setMode] = useState<AnimationMode>(AnimationMode.Stop)
  const [frameRange, setFrameRange] = useState<[number, number]>([0, 0])
  const [currentFrame, setCurrentFrame] = useState<number>(-1)
  const [lastAgent, setLastAgent] = useState<Agent | undefined>(activeAgent)
  const [isMapReady, setIsMapReady] = useState(true)
  const [isChangingStyle, setIsChangingStyle] = useState(false)

  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  useEffect(() => {
    if (activeAgent?.agentId !== lastAgent?.agentId) {
      exitAnimation()
      setLastAgent(activeAgent)
    }
  }, [activeAgent])

  useEffect(() => {
    if (mode === AnimationMode.Pause ||
      mode === AnimationMode.Seek ||
      mode === AnimationMode.Stop
    ) return cancelAnimationFrame(animation.current)
    if (mode === AnimationMode.Play) {
      const animateLine = (timestamp: number) => {
        if (!frameStartTime) {
          frameStartTime = timestamp
        }

        const elapsed = timestamp - frameStartTime
        if (elapsed > fpsInterval) {
          frameStartTime = timestamp - (elapsed % fpsInterval)
          setCurrentFrame((currentFrame) => {
            const newFrame = currentFrame + 1
            const equivalentFrame = frameRange[1] * 3
            if (newFrame > interpolated.length - 1 || newFrame > equivalentFrame) {
              cancelAnimationFrame(animation.current)
              setMode(AnimationMode.Reset)
              return newFrame
            }
            lineCoords.current = [...lineCoords.current, interpolated[newFrame]]
            cameraPosition.current = interpolated[newFrame]
            return newFrame
          })
        }

        animation.current = requestAnimationFrame(animateLine)
      }

      if (interpolated.length > 0) {
        animation.current = requestAnimationFrame(animateLine)
      }
    }
    return () => cancelAnimationFrame(animation.current)
  }, [mode])

  const initialSetup = (trip: TripDetails) => {
    resetAnimation(true)
    if (interpolated.length > 0) {
      // reset interpolated
      setInterpolated([])
      setFrameRange([0, 0])
    }
    setTrip(trip)
  }

  const startAnimation = useCallback(() => {
    if (trip) {
      resetAnimation()
      const travel = trip.rawPath
      setFrameRange([0, travel.length - 1])
      let totalInterpolated: number[][] = []
      let totalDirections: number[] = []

      if (map) {
        const coordinates: LngLatLike[] = trip.rawPath.map(({ longitude, latitude }) => ({
          lng: longitude,
          lat: latitude
        }))
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        )

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord)
        }

        const horizontalBounds = isDesktop ? window.innerWidth / 4 : 60
        map.fitBounds(bounds, {
          padding: {
            top: 20,
            bottom: 20,
            left: horizontalBounds,
            right: horizontalBounds
          }
        })
      }

      for (let i = 0; i < travel.length - 1; i++) {
        const pairLocations = travel.slice(i, i + 2)
        const n =
            Math.floor(
              Math.max(
                Math.abs(pairLocations[1].latitude - pairLocations[0].latitude),
                Math.abs(pairLocations[1].longitude - pairLocations[0].longitude)
              )
            ) + 3

        const lat = interpolateBasis(pairLocations.map((item) => item.latitude))
        const lng = interpolateBasis(pairLocations.map((item) => item.longitude))
        const angle = interpolateBasis(pairLocations.map((item) => item.direction ?? 0))

        const _interpolated = zip(quantize(lng, n), quantize(lat, n))
        const _directions = quantize(angle, n)
        totalInterpolated = [...totalInterpolated, ..._interpolated]
        totalDirections = [...totalDirections, ..._directions]
      }
      setInterpolated([...totalInterpolated])
      setDirections(totalDirections)
      lineCoords.current = [totalInterpolated[0]]
      cameraPosition.current = totalInterpolated[0]

      // change setMode
      setMode(AnimationMode.Play)
    }
  }, [trip])

  const resetAnimation = (toZero?: boolean) => {
    lineCoords.current = [interpolated[0]]
    if (toZero) {
      setCurrentFrame(0)
    }
    // change setMode
    setMode(AnimationMode.Stop)
    cancelAnimationFrame(animation.current)
  }

  const updatePoints = useCallback((points: [number, number]) => {
    if (mode !== AnimationMode.Seek) {
      setMode(AnimationMode.Seek)
    }
    const equivalentFrame = points[0] * 3
    setFrameRange(points)
    setCurrentFrame(equivalentFrame)
  }, [mode])

  const resumePoints = useCallback((points: [number, number]) => {
    setFrameRange(points)
    let equivalentFrame = points[0] * 3
    if (equivalentFrame > interpolated.length - 1 || (equivalentFrame >= frameRange[1] * 3)) {
      resetAnimation()
      equivalentFrame = points[0] * 3
    }
    setCurrentFrame(equivalentFrame)
    setMode(AnimationMode.Play)
  }, [interpolated, frameRange])

  const updateAnimation = useCallback((toFrame: number) => {
    if (mode !== AnimationMode.Seek) {
      setMode(AnimationMode.Seek)
    }
    const [start, stop] = frameRange
    const equivalentStart = start * 3
    const equivalentStop = stop * 3
    const equivalentFrame = toFrame * 3
    if (equivalentFrame < equivalentStart) {
      setCurrentFrame(equivalentStart)
    }
    if (equivalentFrame > equivalentStop) {
      setCurrentFrame(equivalentStop)
    }
    if (equivalentFrame >= equivalentStart && equivalentFrame <= equivalentStop) {
      // allow seek
      setCurrentFrame(equivalentFrame)
    }
  }, [interpolated, mode, frameRange])

  const pauseAnimation = () => {
    setMode(AnimationMode.Pause)
  }

  const resumeAnimation = (toFrame: number) => {
    let equivalentFrame = toFrame * 3
    const [start, stop] = frameRange
    if (equivalentFrame > interpolated.length - 1 || (equivalentFrame >= stop * 3)) {
      // resets the animation
      resetAnimation()
      equivalentFrame = start * 3
    }

    const equivalentStart = start * 3
    const equivalentStop = stop * 3
    if (equivalentFrame < equivalentStart) {
      setCurrentFrame(equivalentStart)
    }
    if (equivalentFrame > equivalentStop) {
      setCurrentFrame(equivalentStop)
    }
    if (equivalentFrame >= equivalentStart && equivalentFrame <= equivalentStop) {
      // allow seek
      setCurrentFrame(equivalentFrame)
    }
    setMode(AnimationMode.Play)
  }

  const exitAnimation = () => {
    if (map) {
      setPointerCoordinates([])
      setTrip(null)
    }
    resetAnimation(true)
  }

  useEffect(() => {
    if (interpolated.length > 0) {
      lineCoords.current = interpolated.slice(0, currentFrame)
    }
  }, [currentFrame])

  useEffect(() => {
    if (!isMapReady) return
    // start the animation
    const last = interpolated[currentFrame]
    const angle = directions[currentFrame]
    if (last) {
      setPointerAngle(angle)
      setPointerCoordinates([last[0], last[1]])
    }
  }, [lineCoords.current])

  useEffect(() => {
    if (trip) {
      startAnimation()
    }
  }, [trip])

  const onMapReloadStyle = () => {
    setIsMapReady(false)
    // introduce delay to avoid style error and allow map to load it's styles
    setTimeout(() => {
      setIsMapReady(true)
    }, 800)
  }

  const loadImages = useCallback(() => {
    if (map) {
      map.loadImage(require('../../assets/arrow-indicator.png'), (error, image) => {
        if (error) {
          console.error('ERR', error)
        }
        if (image && !map.hasImage('arrow-indicator')) {
          map.addImage('arrow-indicator', image)
        }
      })
      map.loadImage(require('../../assets/idle-indicator.png'), (error, image) => {
        if (error) {
          console.error('ERR', error)
        }
        if (image && !map.hasImage('idle-indicator')) {
          map.addImage('idle-indicator', image)
        }
      })
      map.loadImage(require('../../assets/arrow-plain.png'), (error, image) => {
        if (error) {
          console.error('ERR', error)
        }
        if (image && !map.hasImage('arrow-pointer')) {
          map.addImage('arrow-pointer', image)
        }
      })

      if (!map.hasImage('speeding-dot')) {
        const speedingDot = generateSpeedingDot(map)
        map.addImage('speeding-dot', speedingDot, { pixelRatio: 2 })
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      setIsMapReady(false)
      map.on('load', () => {
        loadImages()
        setIsMapReady(true)
      })
      map.on('style.load', () => {
        if (isMapReady) {
          loadImages()
        }
      })
    }
  }, [map])

  const values: TripContext = {
    trip,
    mode,
    currentFrame: Math.floor(currentFrame / 3),
    cameraPosition: cameraPosition.current,
    pointerAngle,
    pointerCoordinates,
    isChangingStyle,
    frameRange,
    setMode,
    initialSetup,
    exitAnimation,
    startAnimation,
    resumeAnimation,
    resetAnimation,
    pauseAnimation,
    resumePoints,
    updatePoints,
    updateAnimation,
    onMapReloadStyle,
    setIsChangingStyle
  }
  const TripContext = getTripContext(values)

  return (
    <TripContext.Provider value={values}>
      {children}
    </TripContext.Provider>
  )
}
