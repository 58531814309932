import Paper from 'src/Layout/Paper'
import CoreMap from 'src/Maps/CoreMap'

export default function GeoZones () {
  return (
    <Paper relative my={0}>
      <CoreMap
        height="75vh"
        width="100%"
        longitude={133.8807}
        latitude={23.6980}
        zoom={1}
        canDraw
        canPan
        canZoom
      />
    </Paper>
  )
}
