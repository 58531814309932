import {
  ActionIcon,
  Group,
  Image,
  Stack,
  Text
} from '@mantine/core'
import { IconDroplet } from '@tabler/icons-react'

export type Fuel =
  'Diesel' |
  'Water' |
  'Unleaded' |
  'Palm Oil' |
  'Petroleum' |
  'Grease'

const fuelTypePhotos: Record<Fuel, string> = {
  Diesel: '/assets/fuelTypes/Hazardous.svg',
  Water: '/',
  Unleaded: '/assets/fuelTypes/Hazardous.svg',
  'Palm Oil': '/',
  Petroleum: '/assets/fuelTypes/Hazardous.svg',
  Grease: '/assets/fuelTypes/Hazardous.svg'
}

interface Props {
  fuelType: Fuel
}

export default function FuelType ({ fuelType }: Props) {
  return (
    <Stack
      spacing={4}
      align="flex-start"
      sx={{
        width: '100%',
        zIndex: 1
      }}
    >
      <Group
        spacing={4}
        position="apart"
        w="100%"
      >
        <Group spacing={4} position="center">
          <ActionIcon
            variant="transparent"
            size="xs"
            color="dark.2"
            sx={{
              pointerEvents: 'none'
            }}
          >
            <IconDroplet size={16} />
          </ActionIcon>
          <Text size={12} weight={500} color="#373A40">
            {fuelType}
          </Text>
        </Group>
        {(fuelType !== 'Water' && fuelType !== 'Palm Oil') && (
          <Image
            src={fuelTypePhotos[fuelType]}
            alt="Fuel Type Logo"
            width={24}
            height={21}
            fit="contain"
          />
        )}
      </Group>
    </Stack>
  )
}
