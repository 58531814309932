import { z } from 'zod'
import { createStatefulApi } from '../configure'
import { type AgentIdRequest } from '../shared'

interface ImmobiliseAgentRequest extends AgentIdRequest {
  dout2: boolean
}

export const immobiliseAgent = createStatefulApi<ImmobiliseAgentRequest, z.ZodBoolean>(
  'config',
  '/command/agents/:agentId/immobilise',
  { method: 'POST' },
  z.boolean()
)
