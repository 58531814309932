import { type EitherAsync } from 'purify-ts'
import { z } from 'zod'

export type Req = Record<string, unknown>

export interface UserIdRequest extends Req {
  userId: number
}

export interface OrgUserIdRequest extends Req {
  orgUserId: number
}

export interface AgentIdRequest extends Req {
  agentId: number
}
export interface TripIdRequest extends Req {
  tripId: number
}

export interface AgentGroupIdRequest extends Req {
  agentGroupId: number
}

export interface GeoZoneIdRequest extends Req {
  geoZoneId: number
}

export interface GeoZoneRuleIdRequest extends Req {
  geoZoneRuleId: number
}

export interface GeoZoneActionIdRequest extends Req {
  geoZoneActionId: number
}
export interface GeoZoneRuleActionIdRequest extends Req {
  geoZoneRuleActionId: number
}

export interface OrgIdRequest extends Req {
  orgId: number
}

export interface SiteIdRequest extends Req {
  siteId: number
}

export interface AlarmIdRequest extends Req {
  alarmId: number
}

export interface AlarmActionIdRequest extends Req {
  alarmActionId: number
}

export interface AlarmWebhookIdRequest extends Req {
  webhookId: number
}

export interface RoleIdRequest extends Req {
  roleId: number
}

export interface SensorInstanceIdRequest extends Req {
  sensorInstanceId: number
}

export interface SensorIdRequest extends Req {
  sensorId: number
}

export interface AssetIdRequest extends Req {
  assetId: number
}

export interface FullIncidentReportRequest extends Req {
  incidentId: string
}

export interface ReportIdRequest extends Req {
  reportId: number
}

export interface DashboardIdRequest extends Req {
  dashboardId: number
}

export interface DashboardItemIdRequest extends Req {
  dashboardItemId: number
}

export interface TaskIdRequest extends Req {
  taskId: number
}

export interface CommentIdRequest extends Req {
  commentId: number
}

export interface CameraIdRequest extends Req {
  cameraId: string
}

export type order = 'asc' | 'desc'

export interface PaginatedRequest extends Req {
  page: number
  size: number
  sort?: string
  order?: order
}

const paginatedResponse = {
  totalItems: z.number(),
  totalPages: z.number(),
  currentPage: z.number()
}

export const paginated = z.object(paginatedResponse)

export type PaginatedResponse = z.infer<typeof paginated>

export const userStatus = z.enum(['INVITED', 'ACTIVE', 'DEACTIVATED', 'BLOCKED', 'INACTIVE', 'OFFLINE'])

export const valueMap = z.optional(z.record(z.union([z.string(), z.number(), z.boolean()]))).default({})

export const geoLocation = z.object({
  latitude: z.number(),
  longitude: z.number()
})

export type GeoLocation = z.infer<typeof geoLocation>

export const lastLocation = z.object({
  geoLocation,
  updatedAt: z.optional(z.string()),
  fullAddress: z.optional(z.string()),
  addressLine: z.optional(z.string()),
  postcode: z.optional(z.string()),
  locality: z.optional(z.string()),
  place: z.optional(z.string()),
  region: z.optional(z.string()),
  country: z.optional(z.string())
})

export type LastLocation = z.infer<typeof lastLocation>

export const mockLoginData = {
  orgUrl: process.env.REACT_APP_LOCAL_HOSTNAME ?? 'localhost:3000',
  email: process.env.REACT_APP_TEST_USER_EMAIL ?? '',
  password: process.env.REACT_APP_TEST_USER_PASSWORD ?? '',
  timezone: '+10:00'
}

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])

type Literal = z.infer<typeof literalSchema>

export type Json = Literal | { [key: string]: Json } | Json[]

export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

export interface ApiConfig {
  environment: string
  user: string
  collector: string
  config: string
  globalConfig: string
  analytics: string
  orgId: number
  orgName: string
}

type Config =
  | { config: ApiConfig, hasConfigured: true }
  | { config: null, hasConfigured: false }

export const apiConfig: Config = {
  config: null,
  hasConfigured: false
}
interface BaseOptions {
  isRetryable: boolean
  retryLimit: number
  retryDelay: number
}

export interface StatefulOptions extends BaseOptions {
  isStateful: true
}

export interface StatelessOptions extends BaseOptions {
  isStateful: false
}

type ApiErrorData = z.infer<typeof apiErrorData>
export class ApiResError extends Error {
  data: string | ApiErrorData
  options?: Options

  constructor (data: string | ApiErrorData, options?: Options) {
    super(typeof data === 'string'
      ? data
      : data.messages[0] ?? 'Unknown Error')
    this.data = data
    this.options = options
  }
}

export type Options = StatefulOptions | StatelessOptions

export type ApiCallable<Req extends Record<string, unknown>, Res> = (
  request: Req,
  token?: string,
  bearerToken?: string,
  signal?: AbortSignal
) => EitherAsync<ApiResError, Res>

export type Api =
  | 'user'
  | 'collector'
  | 'config'
  | 'globalConfig'
  | 'analytics'

type RequestType = 'param' | 'path'
export interface GET {
  method: 'GET'
  type: RequestType
}

export interface POST {
  method: 'POST'
}

export interface PUT {
  method: 'PUT'
}

export interface PATCH {
  method: 'PATCH'
}

export interface DELETE {
  method: 'DELETE'
  type: RequestType
}

export type Method = GET | POST | PUT | PATCH | DELETE

export type OnUnauthorized = () => void

export const apiErrorData = z.object({
  code: z.number(),
  messages: z.array(z.string())
})

export const topLevelError = z.object({
  timestamp: z.string(),
  status: z.number(),
  error: z.string(),
  path: z.string()
})

export interface Range {
  from: string
  to: string
}
