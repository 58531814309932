import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { userStatus } from '../shared'

const rawRole = z.enum([
  'ROLE_USER',
  'ROLE_ADMIN',
  'ROLE_SUPERADMIN'
])

export type Roles = Array<z.infer<typeof rawRole>>

export const onboardingStatuses = [
  'UNSUPPORTED',
  'NOT_STARTED',
  'FINISHED'
] as const

export type OnboardingStatus = typeof onboardingStatuses[number]

export const baseRoleDecoder =
  z
    .array(z.string())
    .transform(args => args
      .filter(input => rawRole.safeParse(input).success) as Roles
    )
    .refine(roles => roles.length > 0, 'There must be at least one valid role')

export const orgRole = z.object({
  orgId: z.number(),
  orgName: z.string(),
  roles: baseRoleDecoder
})

export const user = z.object({
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  status: userStatus,
  orgId: z.number(),
  orgName: z.string(),
  roles: z.array(z.string()),
  backOfficeRole: z.optional(rawRole),
  orgUserId: z.number(),
  onboardingStatus: z.enum(onboardingStatuses),
  'X-Auth-Token': z.string()
})

export interface AuthLoginRequest extends Record<string, unknown> {
  email: string
  password: string
  orgId: number
  orgUrl: string
  timezone: string
}

export type User = z.infer<typeof user>

export const loginUser = createStatefulApi<AuthLoginRequest, typeof user>(
  'user',
  '/auth/login',
  { method: 'POST' },
  user
)

export const loginMobileBypassUser = createStatefulApi<AuthLoginRequest, typeof user>(
  'user',
  '/auth/login',
  { method: 'POST' },
  user
)

export const sendStillAlive = createStatelessApi<Record<string, never>, z.ZodBoolean>(
  'user',
  '/session/alive',
  { method: 'GET', type: 'path' },
  z.boolean()
)

const mobileOrgRole = z.object({
  orgId: z.number(),
  orgName: z.string(),
  roles: baseRoleDecoder,
  orgUrls: z.array(z.string())
})

export const mobileUser = z.object({
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  backOfficeRole: z.optional(rawRole),
  orgRoles: z.array(mobileOrgRole),
  onboardingStatus: z.enum(onboardingStatuses),
  'X-Auth-Token': z.string()
})

interface MobileAuthLoginRequest extends Record<string, unknown> {
  email: string
  password: string
  timezone: string
}

export type MobileUser = z.infer<typeof mobileUser>
export type MobileOrgRole = z.infer<typeof mobileOrgRole>

export const loginMobileUser = createStatefulApi<MobileAuthLoginRequest, typeof mobileUser>(
  'user',
  '/auth/app-login',
  { method: 'POST' },
  mobileUser
)

// Password reset
interface SendPasswordResetEmailRequest extends Record<string, unknown> {
  email: string
  orgUrl: string
  orgId: number
}

export const sendPasswordResetEmail = createStatelessApi<SendPasswordResetEmailRequest, z.ZodBoolean>(
  'user',
  '/auth/sendPasswordResetEmail',
  { method: 'GET', type: 'path' },
  z.boolean()
)

interface GetUserByResetIdRequest extends Record<string, unknown> {
  resetId: string
}

export const passwordResetUser = z.object({
  userId: z.number(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string()
})

export const getUserByResetId = createStatelessApi<GetUserByResetIdRequest, typeof passwordResetUser>(
  'user',
  '/users/findByPasswordResetId/:resetId',
  { method: 'GET', type: 'path' },
  passwordResetUser
)

interface ResetPassword extends Record<string, unknown> {
  userId: number
  password: string
  matchingPassword: string
  orgId: number
  orgUrl: string
  timezone: string
}

export const updatedUser = z.object({
  userId: z.number(),
  email: z.string().email(),
  status: userStatus,
  orgId: z.number(),
  orgName: z.string(),
  roles: z.array(z.string()),
  orgUserId: z.number(),
  onboardingStatus: z.enum(onboardingStatuses),
  'X-Auth-Token': z.string()
})

export const resetPassword = createStatefulApi<ResetPassword, typeof updatedUser>(
  'user',
  '/auth/resetPassword',
  { method: 'PATCH' },
  updatedUser
)
