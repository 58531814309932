import { type PaginatedRequest, type ReportIdRequest, type Req, paginated } from '@venturi-io/api'
import { createStatefulApi, createStatelessApi } from '../configure'
import { z } from 'zod'

const cannotContain = [
  'drop',
  'delete',
  'grant',
  'update',
  'insert',
  'sleep',
  'alter'
]

const sql = z
  .string()
  .refine(input => {
    let ret = true
    cannotContain.forEach(kw => {
      if (!ret) return // short-circuit

      if (input.toLowerCase().includes(kw)) {
        ret = false
      }
    })

    return ret
  }, {
    message: `Your SQL cannot connect any dangerous keywords: ${cannotContain.join(', ')}`
  })

const reportType = z.object({
  id: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  reportQueryIds: z.array(z.number()).max(3)
})

const reportTypes = paginated.extend({
  items: z.array(reportType)
})

export type ReportType = z.infer<typeof reportType>

export const reportView = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  createQuery: sql,
  isTable: z.boolean().optional().default(false)
})

const reportViews = paginated.extend({
  items: z.array(reportView)
})

export const reportQuery = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  query: sql,
  params: z.record(z.string())
})

const reportQueries = paginated.extend({
  items: z.array(
    reportQuery.extend({
      reportView
    })
  )
})

export type ReportQuery = z.infer<typeof reportQuery>

const report = z.object({
  id: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  reportType
})

const reports = paginated.extend({
  items: z.array(report)
})

export type Report = z.infer<typeof report>

// Report Types
export const getReportTypes = createStatelessApi<PaginatedRequest, typeof reportTypes>(
  'config',
  '/back-office/reports/report-types',
  { method: 'GET', type: 'param' },
  reportTypes
)

// Report Queries
export const getReportQueries = createStatelessApi<PaginatedRequest, typeof reportQueries>(
  'config',
  '/back-office/reports/report-queries',
  { method: 'GET', type: 'param' },
  reportQueries
)

// Report Views
export const getReportViews = createStatelessApi<PaginatedRequest, typeof reportViews>(
  'config',
  '/back-office/reports/report-views',
  { method: 'GET', type: 'param' },
  reportViews
)

// Reports
export const getReports = createStatelessApi<PaginatedRequest, typeof reports>(
  'config',
  '/back-office/reports/reports',
  { method: 'GET', type: 'param' },
  reports
)

interface ReportRequest extends ReportIdRequest {
  name: string
  description?: string
  reportTypeId: number
}

export const createReport = createStatefulApi<Omit<ReportRequest, 'reportId'>, typeof report>(
  'config',
  '/back-office/reports/report',
  { method: 'POST' },
  report
)

export const updateReport = createStatefulApi<ReportRequest, typeof report>(
  'config',
  '/back-office/reports/report/:reportId',
  { method: 'PUT' },
  report
)

export const deleteReport = createStatefulApi<ReportIdRequest, z.ZodBoolean>(
  'config',
  '/back-office/reports/report/:reportId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

// Report Execution
export type QueryParam = Record<string | number, string | number>

export interface QueryParams extends Record<string | number, QueryParam> {}

const reportData = z.record(z.string(), z.array(z.record(z.nullable(z.string()))))

export type ReportData = z.infer<typeof reportData>

interface ExecuteReport extends Req {
  reportId: number
  queryParams: QueryParams
}

export const executeReport = createStatefulApi<ExecuteReport, typeof reportData>(
  'config',
  '/reports/run/report/:reportId',
  { method: 'POST' },
  reportData
)
