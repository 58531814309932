import { useCallback, useState } from 'react'
import { Group } from '@mantine/core'
import { type order } from '@venturi-io/api/src/shared'
import ActionButton from 'src/ActionButton'
import Select from './Select'

type sort = string | undefined

interface Props {
  data: string[]
  defaultSort?: string
  defaultOrder?: order
  onChange?: (sort: sort, order: order) => void
  title?: string
}

const Sorter = ({ data, defaultSort, defaultOrder, onChange, title }: Props) => {
  const [sort, setSort] = useState<sort>(defaultSort)
  const [order, setOrder] = useState<order>(defaultOrder ?? 'asc')
  const isOrderAsc = order === 'asc'

  const onSelectSort = useCallback((newSort: string | null) => {
    const sort_ = newSort ?? undefined
    setSort(sort_)

    if (onChange) {
      onChange(sort_, order)
    }
  }, [order])

  const toggleOrder = useCallback(() => {
    const newOrder = isOrderAsc ? 'desc' : 'asc'

    setOrder(newOrder)

    if (onChange) {
      onChange(sort, newOrder)
    }
  }, [sort, order])

  return (
    <Group position="left" spacing={8} noWrap>
      <Select placeholder="Sort by" defaultValue={defaultSort} data={data} onChange={onSelectSort} />
      <ActionButton
        label={title ?? ''}
        icon={isOrderAsc ? 'arrow-down-a-z' : 'arrow-up-a-z'}
        iconType="far"
        onClick={toggleOrder}
        iconColor="silver"
        iconCursor="pointer"
      />
    </Group>
  )
}

export default Sorter
