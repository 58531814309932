import { createStyles, Box, Button, Stack } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useMemo } from 'react'
import SensorMetric from '../Components/SensorMetric'
import { type SensorMetricItem } from '../Components/SensorMetric'

const useStyles = createStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: 16
  }
}))

interface Props {
  items: SensorMetricItem[]
}

export default function SensorMetrics ({ items }: Props) {
  const { classes } = useStyles()
  const [viewedMore, { toggle }] = useDisclosure(false)

  const hasMoreThan6Sensors = items.length > 6

  const sensors = useMemo(() =>
    hasMoreThan6Sensors
      ? viewedMore
        ? items
        : items.slice(0, 6) // we want to preserve the original items
      : items
  , [items, viewedMore, hasMoreThan6Sensors])
  return (
    <Stack>
      <Box className={classes.container}>
        {sensors.map((props, index) => (
          <SensorMetric key={index} {...props} />
        ))}
      </Box>
      {hasMoreThan6Sensors && (
        <Button
          variant="outline"
          color="gray.9"
          leftIcon={<IconPlus size={16} />}
          sx={{ alignSelf: 'center' }}
          onClick={toggle}
        >
          Display
          {' '}
          {viewedMore
            ? 'Less'
            : 'More'}
        </Button>
      )}
    </Stack>
  )
}
