import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parseIconStyle from 'src/utils/parseIconStyle'
import { getAgentStatusColor } from 'src/utils/status'

export default function Icon ({
  iconStyle,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime
}: Pick<Agent, 'alarmStatus' | 'agentStatus' | 'connectionStatus' | 'lastSeenTime' | 'iconStyle'>) {
  const icon = parseIconStyle(iconStyle ?? '')
  const statusColor = getAgentStatusColor(
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  )

  return (
    <FontAwesomeIcon
      icon={icon}
      color={statusColor}
      size="1x"
      fixedWidth
    />
  )
}
