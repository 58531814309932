import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type CSSProperties
} from 'react'
import { createStyles, Box } from '@mantine/core'
import {
  getGeoZone,
  getGeoZonesAndAgents as getGeoZones,
  createGeoZone,
  updateGeoZone,
  deleteGeoZone,
  assignAgentGroupsToGeoZone,
  removeAgentGroupsFromGeoZone,
  type GeoZone
} from '@venturi-io/api/src/config/geoZone'
import ReactMapGL, { type MapRef, NavigationControl } from 'react-map-gl'
import { type Editor } from 'react-map-gl-draw'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import {
  type DrawCreateEvent,
  type DrawModeChangeEvent,
  type DrawUpdateEvent
} from '@mapbox/mapbox-gl-draw'
import { bbox } from '@turf/turf'
import { useNotifications } from 'src/utils/notifications'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { MapType } from '../StylePicker'
import LocationMarker from './LocationMarker'
import InformationBox from './InformationBox'
import {
  type LocationMark,
  geoZoneToFeature,
  type ActiveFeature,
  type MapMode,
  type FeatureWithId,
  type Feature
} from './shared'
import { MODES } from './constants'
import DrawControl from './DrawControl'
import Toolbar from './Toolbar'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import 'mapbox-gl/dist/mapbox-gl.css'

interface StyleParams {
  width: CSSProperties['width']
  height: CSSProperties['height']
}

const useStyles = createStyles((theme, { width, height }: StyleParams) => ({
  container: {
    position: 'relative',
    width,
    height
  }
}))

export interface Props {
  height?: string
  width?: string
  longitude?: number
  latitude?: number
  zoom?: number
  showLocationMarker?: boolean
  showLocationMarkerInformation?: boolean
  onMarkerChange?: (value: LocationMark) => void
  canPan?: boolean
  canZoom?: boolean
  canRotate?: boolean
  canDraw?: boolean
}

const modes = [
  { id: MODES.READ_ONLY, desc: null },
  { id: MODES.VIEWING, desc: 'simple_select' },
  { id: MODES.EDITING, desc: 'direct_select' },
  { id: MODES.DRAW_POLYGON, desc: 'draw_polygon' },
  { id: MODES.DRAW_RECTANGLE, desc: 'draw_rectangle' },
  { id: MODES.DRAW_SQUARE, desc: 'draw_square' },
  { id: MODES.DRAW_CIRCLE, desc: 'draw_circle' }
]

export default function CoreMap ({
  height = '100%',
  width = '100%',
  longitude = 133.8807,
  latitude = 23.6980,
  zoom = 5,
  showLocationMarker = false,
  showLocationMarkerInformation = false,
  onMarkerChange,
  canPan = false,
  canZoom = false,
  canRotate = false,
  canDraw = false
}: Props) {
  const editorRef = useRef<Editor>(null)
  const { classes } = useStyles({ width, height })
  const mapRef = useRef<MapRef>(null)
  const drawRef = useRef<MapboxDraw>()
  const { token, orgId } = useUser()
  const findGeoZone = useApi(getGeoZone)
  const allGeoZones = useApi(getGeoZones)
  const create = useApi(createGeoZone)
  const update = useApi(updateGeoZone)
  const remove = useApi(deleteGeoZone)
  const assignAgentGroups = useApi(assignAgentGroupsToGeoZone)
  const removeAgentGroups = useApi(removeAgentGroupsFromGeoZone)
  const [toAssignAgentGroupIds, setToAssignAgentGroupIds] = useState<number[]>([])
  const { showError } = useNotifications()

  const [mode, setMode] = useState<MapMode>(undefined)
  const [activeFeature, setActiveFeature] = useState<ActiveFeature | null>(null)
  const [existingFeatures, setExistingFeatures] = useState<Record<string, FeatureWithId>>({})
  const [uncommittedFeatures, setUncommittedFeatures] = useState<FeatureWithId[]>([])
  const [existingGeoZone, setExistingGeoZone] = useState<Omit<GeoZone, 'boundary'> | null>(null)
  const [showInformationBox, setShowInformationBox] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [viewport, setViewport] = useState({
    longitude,
    latitude,
    zoom
  })
  const [marker, setMarker] = useState<LocationMark>({
    longitude,
    latitude
  })
  const settings = {
    dragPan: canPan,
    scrollZoom: canZoom,
    touchZoom: canZoom,
    doubleClickZoom: canZoom,
    dragRotate: canRotate,
    touchRotate: canRotate
  }
  const geoZones = allGeoZones.data.mapOrDefault(({ geoZones }) => geoZones, [])

  const switchMode = (e: string) => {
    // TODO make it cleaner
    const mode_ = modes.find(m => m.id === e) ?? modes[0]
    setMode(mode_)

    // Manually invokes drawRef to change mode
    if (mode_?.desc) {
      if (mode_.id === 'Editing') {
        if (activeFeature?.feature) {
          const { id } = activeFeature?.feature as FeatureWithId
          drawRef.current?.changeMode(mode_.desc, { featureId: id })
        }
      } else {
        drawRef.current?.changeMode(mode_.desc)
        setActiveFeature(null)
      }
    }
  }

  const fromDrawSwitchMode = (e: DrawModeChangeEvent) => {
    const mode_ = modes.find(m => m.desc === e.mode) ?? modes[0]
    setMode(mode_)
  }

  const removeExistingFeature = (feature: FeatureWithId) => {
    const features_ = Object.keys(existingFeatures)
    setExistingFeatures(
      features_.reduce((acc, key) => (
        key !== feature.id
          ? { [key]: existingFeatures[key], ...acc }
          : acc
      ), {}))
  }

  const onCreate = useCallback(({ features }: DrawCreateEvent) => {
    const createdFeature = features[0] as FeatureWithId
    setActiveFeature({ feature: createdFeature })
    setUncommittedFeatures(features => ([...features, createdFeature]))
    // delay??
    setTimeout(() => {
      switchMode(MODES.EDITING)
    }, 250)
  }, [])

  const onUpdate = useCallback(({ features }: DrawUpdateEvent) => {
    const updatedFeature = features[0] as FeatureWithId
    let features_ = uncommittedFeatures
    features_ = features_.filter(item => item.id !== updatedFeature.id)
    setUncommittedFeatures([...features_, updatedFeature])
    setActiveFeature({ feature: updatedFeature })
  }, [uncommittedFeatures])

  const onSelect = useCallback(({ features }: { features: object[] }) => {
    const feature = features[0] as Feature
    // avoid double focus
    if (feature && feature !== activeFeature?.feature) {
      // set's the active feature
      setActiveFeature({ feature })
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature)

      if (mapRef.current) {
        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          { padding: 120, duration: 1000 }
        )
      }
      setShowInformationBox(true)
    } else {
      switchMode(MODES.VIEWING)
      setActiveFeature(null)
      setShowInformationBox(false)
    }
  }, [mode, activeFeature?.feature])

  const handleCreate = useCallback((geoZone: Omit<GeoZone, 'geoZoneId'>) => {
    setToAssignAgentGroupIds(geoZone?.agentGroups?.map(({ agentGroupId }) => agentGroupId) ?? [])

    void create
      .fetch(geoZone, token, 'Successfully created geo zone')
      .finally(() => {
        // remove feature then replace it with the new featuref from backend on useEffect
        if (activeFeature?.feature) {
          const { id } = activeFeature.feature as FeatureWithId
          if (id) {
            drawRef?.current?.delete(id)
            setUncommittedFeatures(features => features.filter(item => item.id !== id))
          }
        }
        setActiveFeature(null)
        switchMode(MODES.VIEWING)
      })
  }, [editorRef, activeFeature])

  useEffect(() => {
    create.data.ifJust((data) => {
      const { geoZoneId } = data
      if (toAssignAgentGroupIds.length) {
        void assignAgentGroups.fetch({
          geoZoneId,
          agentGroupIds: toAssignAgentGroupIds
        }, token)
      }
      if (activeFeature?.feature) {
        const { id, properties } = activeFeature.feature as FeatureWithId
        if (id) {
          const { data } = properties
          setExistingFeatures(existing => ({
            ...existing,
            [id]: {
              ...activeFeature.feature,
              properties: {
                ...properties,
                data: {
                  ...data,
                  // replace geozoneId with the value created by backend
                  geoZoneId
                }
              }
            }
          }))
        }
      }
    })
  }, [create.data])

  const handleUpdate = useCallback((geoZone: GeoZone, newAgentGroupIds: number[], removedAgentGroupIds: number[]) => {
    const { geoZoneId } = geoZone

    if (newAgentGroupIds.length) {
      void assignAgentGroups.fetch({
        geoZoneId,
        agentGroupIds: newAgentGroupIds
      }, token)
    }

    if (removedAgentGroupIds.length) {
      void removeAgentGroups.fetch({
        geoZoneId,
        agentGroupIds: removedAgentGroupIds
      }, token)
    }

    void update
      .fetch(geoZone, token, 'Successfully updated geo zone')
      .finally(() => {
        if (activeFeature?.feature) {
          const { id } = activeFeature.feature as FeatureWithId
          if (id) {
            const featuresToUpdate = {
              ...existingFeatures,
              [id]: activeFeature.feature
            }
            setExistingFeatures(featuresToUpdate)
            setUncommittedFeatures(features => features.filter(item => item.id !== id))
          }
        }
        setExistingGeoZone(null)
        switchMode(MODES.VIEWING)
      })
  }, [activeFeature])

  const showErrorUpdateAgentGroup = useCallback((error: Error) => {
    showError(error)
  }, [])

  const handleDeleteFeature = useCallback(() => {
    if (activeFeature?.feature) {
      const { id } = activeFeature.feature as FeatureWithId
      if (id) drawRef?.current?.delete(id)

      setUncommittedFeatures(features => features.filter(item => item.id !== id))
      setShowInformationBox(false)
      setActiveFeature(null)
    }
  }, [drawRef, activeFeature])

  const handleDelete = useCallback((geoZoneId: number) => {
    if (activeFeature?.feature && geoZoneId) {
      void remove.fetch({ geoZoneId }, token, 'Successfully deleted geo zone')
      setShowDelete(false)
      setExistingGeoZone(null)
      removeExistingFeature(activeFeature.feature)
    }
    setShowInformationBox(false)
  }, [editorRef, activeFeature])

  useEffect(() => {
    remove.data.ifJust(() => {
      if (activeFeature?.feature) {
        const { id } = activeFeature.feature as FeatureWithId
        if (id) drawRef?.current?.delete(id)
      }
    })
  }, [remove.data])

  const onDiscard = useCallback(() => {
    //  for created features that was not saved
    const featureIds = uncommittedFeatures.reduce((acc: string[], item) => {
      return item.id
        ? [
            ...acc,
            item.id
          ]
        : acc
    }, [])
    drawRef?.current?.delete(featureIds)

    // redraw existing features
    drawRef?.current?.deleteAll()
    for (const f in existingFeatures) {
      drawRef?.current?.add(existingFeatures[f])
    }

    setUncommittedFeatures([])
    setShowInformationBox(false)
    setExistingGeoZone(null)
    setActiveFeature(null)
    switchMode(MODES.VIEWING)
  }, [existingFeatures])

  useEffect(() => {
    create.data.map(geoZone => {
      const updatedFeature = geoZoneToFeature(geoZone)

      drawRef.current?.add(updatedFeature)

      return geoZone
    })
  }, [create.data])

  useEffect(() => {
    setMarker({
      longitude,
      latitude
    })
  }, [longitude, latitude])

  useEffect(() => {
    setViewport({
      ...viewport,
      ...marker
    })
  }, [marker])

  useEffect(() => {
    assignAgentGroups.error.ifJust(showErrorUpdateAgentGroup)
  }, [assignAgentGroups.error])

  useEffect(() => {
    removeAgentGroups.error.ifJust(showErrorUpdateAgentGroup)
  }, [removeAgentGroups.error])

  useEffect(() => {
    findGeoZone.data.ifJust((data) => setExistingGeoZone(data))
  }, [findGeoZone.data])

  useEffect(() => {
    if (activeFeature?.feature?.properties?.data) {
      void findGeoZone.fetch({
        geoZoneId: activeFeature?.feature?.properties?.data.geoZoneId
      }, token)
    } else {
      setExistingGeoZone(null)
    }
  }, [activeFeature])

  const onKeyDown = useCallback((e: { key: string }) => {
    if (e.key === 'Escape') {
      if (mapRef.current) {
        mapRef.current.flyTo({
          zoom: viewport.zoom - 1
        })
      }

      setShowInformationBox(false)
      setExistingGeoZone(null)
      setActiveFeature(null)
      switchMode(MODES.VIEWING)
    }
  }, [mapRef.current, viewport.zoom])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [viewport.zoom])

  useEffect(() => {
    const request = {
      orgId,
      showHiddenGeoZones: true
    }
    void allGeoZones.fetch(request, token)
    switchMode(MODES.VIEWING)
  }, [])

  useEffect(() => {
    if (mapRef.current &&
        drawRef.current &&
        geoZones.length > 0 &&
        mapRef.current.getMap()
    ) {
      // draw Geozones here
      const features: FeatureWithId[] = geoZones.map((geoZone) => geoZoneToFeature(geoZone))
      for (const f of features) {
        drawRef.current?.add(f)
      }
      const allFeatures = drawRef.current?.getAll()
      if (allFeatures?.features) {
        const featuresObj = allFeatures.features.reduce((acc, item) => (item?.id
          ? {
              ...acc,
              [item.id]: item
            }
          : acc), {})
        setExistingFeatures(featuresObj)
      }
    }
  }, [geoZones, mapRef, drawRef])

  return (
    <Box className={classes.container}>
      <ReactMapGL
        {...viewport}
        {...settings}
        ref={mapRef}
        style={{ width, height }}
        onMove={evt => setViewport(evt.viewState)}
        mapStyle={MapType.satellite}
        mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
        attributionControl={false}
        transformRequest={(url) => ({
          url,
          referrerPolicy: 'strict-origin-when-cross-origin'
        })}
      >
        {showLocationMarker && (
          <LocationMarker
            marker={marker}
            canPan={canPan}
            setMarker={setMarker}
            onMarkerChange={onMarkerChange}
            showInformation={showLocationMarkerInformation}
          />
        )}

        {canZoom && <NavigationControl position="bottom-right" style={{ right: 10, bottom: 10 }} />}

        {canDraw && (
          <>
            <DrawControl
              ref={drawRef}
              boxSelect={false}
              keybindings={false}
              position="top-right"
              displayControlsDefault={false}
              defaultMode="simple_select"
              onCreate={onCreate}
              onUpdate={onUpdate}
              onSelect={onSelect}
              onModeChange={fromDrawSwitchMode}
            />
            <Toolbar
              mode={mode}
              switchMode={switchMode}
              activeFeature={activeFeature?.feature}
              showDiscard={!!uncommittedFeatures.length}
              onDiscard={onDiscard}
            />
          </>
        )}
      </ReactMapGL>
      {showInformationBox && activeFeature && (
        <InformationBox
          mode={mode}
          orgId={orgId}
          activeFeature={activeFeature.feature}
          selectedGeoZone={existingGeoZone}
          switchMode={switchMode}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onCancel={() => switchMode(MODES.VIEWING)}
          onDelete={() => {
            if (!existingGeoZone) {
              handleDeleteFeature()
            } else {
              setShowDelete(true)
            }
          }}
          setShowInformationBox={setShowInformationBox}
        />
      )}
      {existingGeoZone && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${existingGeoZone.name}"`}
          question="Are you sure you want to delete this geo zone? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={() => handleDelete(existingGeoZone.geoZoneId)}
          centered
        />
      )}
    </Box>
  )
}
