import {
  Center,
  Container,
  Group,
  ScrollArea,
  Stack,
  Text
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { Bar } from 'src/stories/Bar'
import {
  findSensorByType,
  type SensorType,
  barColor,
  useSharedStyles,
  computeRelativePercentage,
  convertStrToFloat,
  type Sensors
} from '../shared'
import { MODULE_CONTENT } from './constants'

const sensorTypeCurrent: Record<string, SensorType> = {
  A: 'RMS_LINE_CURRENT_L1',
  B: 'RMS_LINE_CURRENT_L2',
  C: 'RMS_LINE_CURRENT_L3'
}

const sensorTypeLN: Record<string, SensorType> = {
  A: 'RMS_VOLTAGE_L1N',
  B: 'RMS_VOLTAGE_L2N',
  C: 'RMS_VOLTAGE_L3N'
}

const sensorTypeLL: Record<string, SensorType> = {
  A: 'RMS_ENERGY_L1',
  B: 'RMS_ENERGY_L2',
  C: 'RMS_ENERGY_L3'
}

interface Props extends Sensors {
  agentName: string
}

export default function PowerStatus ({ sensors }: Props) {
  const { classes } = useSharedStyles()
  const { ref, height, width } = useElementSize()

  const barsContainerStyles = {
    width: width <= 300 || height >= 600
      ? '100%'
      : '25%'
  }

  const populateSensorBars = (sensorTypes: Record<string, SensorType>) => Object.keys(sensorTypes).map((k) => {
    const type = sensorTypes[k]
    const sensor = findSensorByType(type, sensors)
    if (!sensor) return null
    const { currentValue, unit, minValue, maxValue, alarmStatus } = sensor
    const value = convertStrToFloat(currentValue)
    const percentValue = computeRelativePercentage(
      value,
      typeof minValue !== 'undefined' ? minValue : 0,
      typeof maxValue !== 'undefined' ? maxValue : 100
    )

    return (
      <Bar
        key={type}
        primaryLabel={k}
        secondaryLabel={`${value} ${unit}`}
        value={percentValue}
        color={barColor(alarmStatus)}
      />
    )
  })

  return (
    <Container ref={ref} className={classes.dock} fluid>
      <Text size="sm" weight={500}>
        {MODULE_CONTENT.POWER_STATUS.toUpperCase()}
      </Text>
      <ScrollArea sx={{ height: height - 40 }}>
        <Group
          sx={{
            padding: 2,
            width: '100%'
          }}
          position="apart"
          spacing="xl"
        >
          <Stack spacing="xl" sx={barsContainerStyles}>
            <Center><Text size="xs" weight="bold">Voltage (L-N)</Text></Center>
            <Stack spacing="xl">
              {populateSensorBars(sensorTypeLN)}
            </Stack>
          </Stack>
          <Stack spacing="xl" sx={barsContainerStyles}>
            <Center><Text size="xs" weight="bold">Voltage (L-L)</Text></Center>
            <Stack spacing="xl">
              {populateSensorBars(sensorTypeLL)}
            </Stack>
          </Stack>
          <Stack spacing="xl" sx={barsContainerStyles}>
            <Center><Text size="xs" weight="bold">Current</Text></Center>
            <Stack spacing="xl">
              {populateSensorBars(sensorTypeCurrent)}
            </Stack>
          </Stack>
        </Group>
      </ScrollArea>
    </Container>
  )
}
