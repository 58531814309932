import {
  createStyles,
  Box,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { hasAlarm } from 'src/AssetTemplate/shared'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import EngineSpeed from './EngineSpeed'
import Sensors from './Sensors'

const useStyles = createStyles(theme => ({
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  },
  titleContainer: {
    position: 'relative'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    top: -8,
    left: -16
  },
  alarmIndicatorLine: {
    height: 32,
    width: 4,
    background: '#FFE066'
  },
  leftSection: {
    flex: 1,
    alignContent: 'center'
  },
  rightSection: {
    minWidth: 0,
    flex: 2,
    alignContent: 'center'
  }
}))

interface Props {
  engineSpeed: Sensor | null
  engineBatteryVoltage: Sensor | null
  coolantTemperature: Sensor | null
  oilPressure: Sensor | null
}

export default function EngineStatus ({
  engineSpeed,
  engineBatteryVoltage,
  coolantTemperature,
  oilPressure
}: Props) {
  const { classes } = useStyles()
  const withAlarm = hasAlarm([
    engineSpeed,
    engineBatteryVoltage,
    coolantTemperature,
    oilPressure
  ])

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
          color="#212121"
        >
          Engine Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicatorContainer}
            position="center"
            align="center"
            spacing={104}
          >
            <span className={classes.alarmIndicatorLine} />
            <AlarmIndicator size="md" />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.leftSection}>
          <EngineSpeed sensor={engineSpeed} />
        </Box>
        <Box className={classes.rightSection}>
          <Sensors
            engineBatteryVoltage={engineBatteryVoltage}
            coolantTemperature={coolantTemperature}
            oilPressure={oilPressure}
          />
        </Box>
      </Box>
    </Paper>
  )
}
