import { useEffect } from 'react'
import {
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Title
} from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'
import { createAsset, getAssetTypes, type Asset } from '@venturi-io/api/src/config/asset'
import SelectAssetType from './SelectAssetType'

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Create ({ show, close, onCreate }: Props) {
  const assetTypes = useApi(getAssetTypes)
  const create = useApi(createAsset)

  const { token } = useUser()

  const form = useForm<Omit<Asset, 'id'>>({
    initialValues: {
      name: '',
      assetTypeId: -1
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null,
      assetTypeId: value => value === -1 ? 'Please choose a Asset Type' : null
    }
  })

  const handleSubmit = (values: typeof form.values) => {
    void create
      .fetch(values, token, 'Successfully created new asset')
      .finally(() => {
        form.reset()
        close()
        void onCreate()
      })
  }

  const selectAssetTypes = assetTypes.data.mapOrDefault(types => types, [])

  useEffect(() => {
    void assetTypes.fetch({}, token)
  }, [])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Asset</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col span={12}>
            <SelectAssetType
              required
              disabled={assetTypes.loading}
              data={selectAssetTypes}
              {...form.getInputProps('assetTypeId')}
            />
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={() => {
                  form.reset()
                  close()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'circle-plus']} color="white" />}
                disabled={create.loading}
              >
                Create
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
