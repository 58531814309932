import { memo } from 'react'
import { Button, Table as MantineTable, Title, Group } from '@mantine/core'
import { type AlarmIncident } from '@venturi-io/api/src/analytics/alarmIncident'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoData from 'src/Layout/TableRowNoData'
import Link from 'src/Layout/Link'
import ClearAlarmButton from 'src/ClearAlarmButton'

export interface IncidentsRowProps {
  incidents: AlarmIncident[]
  onRefresh: () => void
}

function IncidentsRow ({ incidents, onRefresh }: IncidentsRowProps) {
  return incidents.length > 0
    ? (
      <>
        {incidents.map(({
          incidentId,
          siteName,
          agentName,
          sensorInstanceName,
          sensorInstanceId,
          unit,
          stringValue
        }) => (
          <tr key={incidentId}>
            <td>{siteName ?? ''}</td>
            <td>{`${agentName} - ${sensorInstanceName}`}</td>
            <td>{`${stringValue} ${unit ?? ''}`}</td>
            <td>
              <FontAwesomeIcon
                color="red"
                icon={['fas', 'warning']}
                size="lg"
                width="50%"
                fade
              />
            </td>
            <td>
              <Link to={`/alarms/incident/${incidentId}`}>
                View
              </Link>
            </td>
            <td>
              <ClearAlarmButton
                sensorInstanceIds={[sensorInstanceId]}
                agentName={agentName}
                onRefresh={onRefresh}
              />
            </td>
          </tr>
        ))}
      </>
      )
    : <NoData message="No data available" />
}

export interface Props {
  title: string
  incidents: AlarmIncident[]
  onRefresh: () => void
}

function Table ({ title, incidents, onRefresh }: Props) {
  return (
    <div>
      <Group mt="sm" mr="sm" align="center" position="apart">
        <Title ml="md" order={3}>{title}</Title>
        <ClearAlarmButton
          confirmLabel="Clear all alarms"
          sensorInstanceIds={incidents.map(({ sensorInstanceId }) => sensorInstanceId)}
          onRefresh={onRefresh}
        >
          {(onClick) => (
            <Button
              color="primary"
              leftIcon={(
                <FontAwesomeIcon icon={['far', 'bell-exclamation']} />
              )}
              variant="outline"
              onClick={onClick}
            >
              Clear all alarms

            </Button>
          )}
        </ClearAlarmButton>
      </Group>
      <MantineTable striped highlightOnHover>
        <thead>
          <tr>
            <th>Site</th>
            <th>Device</th>
            <th>Current value</th>
            <th>Alarm status</th>
            <th>View</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <IncidentsRow incidents={incidents} onRefresh={onRefresh} />
        </tbody>
      </MantineTable>
    </div>
  )
}

export default memo(Table)
