import { memo, forwardRef, type ComponentPropsWithoutRef, useMemo } from 'react'
import { Group, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultBadge from '../HomeBadge'
import Favourite from '../Favorite'

export interface Props extends ComponentPropsWithoutRef<'div'> {
  label: string
  value: string
  isPrivate: boolean
  isDefault: boolean
  isFavourite: boolean
}

const Item = forwardRef<HTMLDivElement, Props>(
  ({ label, isPrivate, isDefault, isFavourite, ...others }: Props, ref) => {
    const isSelected = useMemo(() => (
      others.className?.includes('mantine-Select-selected')
    ), [others.className])

    const selectedColor = useMemo(() => isSelected ? 'white' : 'gray', [isSelected])

    return (
      <div ref={ref} {...others}>
        <Group position="apart" noWrap>
          <Group position="apart" spacing={7} noWrap>
            <FontAwesomeIcon
              icon={['far', isPrivate ? 'lock' : 'user-group']}
              color={selectedColor}
              size="sm"
            />
            <Text size="xs" color={selectedColor}>{label}</Text>
          </Group>
          {isDefault
            ? <DefaultBadge size="xs" />
            : isFavourite && <Favourite isFavourite disabled />}
        </Group>
      </div>
    )
  }
)

export default memo(Item)
