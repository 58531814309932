import { memo, useCallback, useMemo } from 'react'
import {
  Accordion,
  ActionIcon,
  Button,
  Group,
  Stack,
  Tooltip
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectAgent from 'src/Input/Select/SelectAgent'
import { hoursToSeconds, secondsToHours } from 'src/utils/uom'
import { useApi } from 'src/utils/useApi'
import { assetTypes, updateAgentStatistics } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import ItemPreview from '../shared/ItemPreview'
import EngineHours, { type Props as EngineHourProps } from '.'

interface Props {
  initialValues: EngineHourProps | null
  onSubmit: (properties: EngineHourProps) => void
}

const excludeAssetTypes = ['ATS', 'CAMERA', 'PDD']

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()
  const { token } = useUser()
  const updateStats = useApi(updateAgentStatistics)

  const formValues: EngineHourProps = useMemo(() => (
    initialValues ?? {
      id: '',
      agentId: '',
      agentName: '',
      name: '',
      engineTimeInSeconds: 0
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      agentId: value => (
        value.trim().length === 0
          ? 'Please select an agent'
          : null
      ),
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const handleSubmit = useCallback(async (values: typeof form.values) => {
    if (form.isDirty('engineTimeInSeconds')) {
      const { agentId, engineTimeInSeconds } = values
      await updateStats.fetch({
        agentId: Number(agentId),
        engineTimeInSeconds
      },
      token)
    }

    onSubmit(values)

    form.reset()
  }, [form.values])

  const parsedEngineHour = secondsToHours(form.values.engineTimeInSeconds)

  return (
    <>
      <ItemPreview iconName="engine" previewItem>
        <EngineHours
          {...form.values}
          passToParent={(timeInSeconds: number) => {
            form.setFieldValue('engineTimeInSeconds', timeInSeconds)
            form.resetDirty()
          }}
        />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectAgent
                    withAsterisk
                    searchable
                    label="Agent"
                    placeholder="Select Agent"
                    assetTypes={assetTypes.filter(type => !excludeAssetTypes.includes(type))}
                    value={form.values.agentId}
                    onChange={(value: string | null, text?: string) => {
                      if (value) {
                        form.setFieldValue('agentId', value)
                      }
                      if (text) {
                        form.setFieldValue('agentName', text)
                      }
                    }}
                  />
                  <Group align="flex-end">
                    <NumberInput
                      label="Engine Hours"
                      placeholder="Enter engine hours"
                      value={parsedEngineHour}
                      onChange={value => {
                        form.setFieldValue('engineTimeInSeconds',
                          typeof value === 'number'
                            ? hoursToSeconds(value)
                            : 0)
                      }}
                      sx={{
                        flex: 1
                      }}
                    />
                    <Tooltip
                      label="Reset Engine Hours"
                      position="top-end"
                      withArrow
                      withinPortal
                    >
                      <ActionIcon
                        color="red"
                        variant="filled"
                        size="lg"
                        onClick={() => {
                          form.setFieldValue('engineTimeInSeconds', 0)
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'close']} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            loading={updateStats.loading}
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
