import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { useEffect } from 'react'
import { findMissingSensors } from '../shared'
import { MODULE_CONTENT } from './constants'
import UpsDetails from './UpsDetails'

interface Props {
  agent: AgentDetails
  contentKey: string
  setMissingSensors: (value: string[]) => void
}

export default function ModuleContainer ({
  agent,
  contentKey,
  setMissingSensors
}: Props) {
  const {
    agentName,
    sensors
  } = agent

  useEffect(() => {
    const missingSensors = findMissingSensors([
      'UPS_STATUS',
      'UPS_RUNTIME',
      'BATTERY',
      'MAINS_VOLTAGE',
      'LOAD_KVA'
    ], sensors)

    setMissingSensors(missingSensors)
  }, [sensors])

  switch (contentKey) {
    case MODULE_CONTENT.UPS_DETAILS:
      return (
        <UpsDetails
          agentName={agentName}
          sensors={sensors}
        />
      )

    default:
      return null
  }
}
