import { forwardRef, type HTMLProps } from 'react'
import { Table as MantineTable } from '@mantine/core'
import { createStyles } from '@mantine/styles'

const useStyles = createStyles(() => ({
  table: {
    thead: {
      position: 'relative'
    },
    tbody: {
      position: 'relative',
      display: 'block'
    },
    tr: {
      display: 'flex',
      'th:nth-child(1),td:nth-child(1)': {
        width: '10%',
        minWidth: '200px'
      },
      'th:nth-child(2),td:nth-child(2)': {
        width: '8.33%',
        minWidth: '200px'
      },
      'th:nth-child(3),td:nth-child(3)': {
        width: '8.33%',
        minWidth: '200px'
      },
      'th:nth-child(4),td:nth-child(4)': {
        width: '8.33%',
        minWidth: '200px'
      },
      'th:nth-child(5),td:nth-child(5)': {
        width: '10%',
        minWidth: '200px'
      },
      'th:nth-child(6),td:nth-child(6)': {
        width: '10%',
        minWidth: '200px'
      },
      'th:nth-child(7),td:nth-child(7)': {
        width: '10%',
        minWidth: '200px'
      },
      'th:nth-child(8),td:nth-child(8)': {
        width: '10%',
        minWidth: '200px'
      },
      'th:nth-child(9),td:nth-child(9)': {
        width: '8.33%',
        minWidth: '200px'
      },
      'th:nth-child(10),td:nth-child(10)': {
        width: '8.33%',
        minWidth: '200px'
      },
      'th:nth-child(11),td:nth-child(11)': {
        width: '8.33%',
        minWidth: '200px'
      }
    }
  }
}))

const Table = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function Inner ({ children, ...rest }, ref) {
    const { classes } = useStyles()
    return (
      <div {...rest} ref={ref}>
        <MantineTable highlightOnHover horizontalSpacing="xl" className={classes.table}>
          <thead>
            <tr>
              <th>Call Time</th>
              <th>Mailbox ID</th>
              <th>Error ID</th>
              <th>Message ID</th>
              <th>Message UTC</th>
              <th>Receive UTC</th>
              <th>Raw Payload</th>
              <th>Decoded Raw Payload</th>
              <th>Agent ID</th>
              <th>Mobile ID</th>
              <th>SIN</th>
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </MantineTable>
      </div>
    )
  }
)

export default Table
