import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { type Props } from '../../../Transactions'
import { textGeneralStyles } from './styles'

export default function DateTime ({ transactions }: Props) {
  return transactions.length > 0 &&
    (
      <>
        {transactions.map(({
          imported,
          transactionId
        }) => {
          return (
            <tr key={transactionId}>
              <td style={textGeneralStyles}>
                {dayjs(imported).format(uiDateFormat)}
              </td>
            </tr>
          )
        })}
      </>
    )
}
