import { curveBasis, curveLinear, curveNatural } from '@visx/curve'

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface Spacing {
  top: number
  right: number
  bottom: number
  left: number
}

export interface Margin extends Spacing {}

export interface Padding extends Spacing {}

export const curveTypes = [
  'natural',
  'basis',
  'linear'
] as const

export type CurveType = typeof curveTypes[number]

export type Curve = Record<CurveType, typeof curveNatural | typeof curveBasis | typeof curveLinear>

export const curves: Curve = {
  natural: curveNatural,
  basis: curveBasis,
  linear: curveLinear
}

export interface ColorGradient {
  primary: React.CSSProperties['color']
  secondary: React.CSSProperties['color']
}

export interface DataPoint {
  x: number | string
  y?: number
}
