import { Badge, Box, Group, Text } from '@mantine/core'
import { IconUrgent } from '@tabler/icons-react'
import { type Task } from '@venturi-io/api/src/config/task'

export default function Item ({
  taskType,
  priority,
  status
}: Pick<Task, 'id' | 'taskType' | 'priority' | 'status'>) {
  return (
    <Group position="apart" align="center">
      <Group position="center" align="center" spacing={8}>
        <Box
          p={0}
          m={0}
          sx={{ width: 16 }}
        >
          {status === 'NEW' && (
            <IconUrgent
              color="indigo"
              size={16}
              stroke={2}
            />
          )}
        </Box>
        <Text size={12} weight={400}>{taskType}</Text>
      </Group>
      <Badge color="gray">
        {priority}
      </Badge>
    </Group>
  )
}
