import { useCallback, useEffect, useState } from 'react'
import { type Agent, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { immobiliseAgent } from '@venturi-io/api/src/config/command'
import { ActionIcon, Tooltip } from '@mantine/core'
import ImmobiliseIcon from 'src/Icons/ImmobiliseIcon'
import MobiliseIcon from 'src/Icons/MobiliseIcon'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useNotifications } from 'src/utils/notifications'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { adminRoles, checkIfHasRole } from 'src/utils/role'
import { findSensorByType } from 'src/Assets/shared'

interface Props {
  agentId: Agent['agentId']
  onSuccess?: () => void
}

export default function ImmobiliseButton ({ agentId, onSuccess }: Props) {
  const { token } = useUser()
  const { showSuccess, showError } = useNotifications()
  const immobilise = useApi(immobiliseAgent)
  const agentDetails = useApi(getAgentDetails)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const agentName = agentDetails.data.mapOrDefault(({ agentName }) => agentName, null)
  const sensors = agentDetails.data.mapOrDefault(({ sensors }) => sensors, [])
  const immobilisationStatus = findSensorByType('IMMOBILISATION_STATUS', sensors)
  const isImmobilised = immobilisationStatus?.currentValue === '0.00'
  const isAllowed = checkIfHasRole(adminRoles)
  const disabled = agentName === null || !isAllowed || immobilise.loading
  const action = isImmobilised
    ? 'Mobilise'
    : 'Immobilise'

  const loadAgentDetails = useCallback(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  const handleImmobilise = useCallback(() => {
    void immobilise.fetch({
      agentId,
      dout2: isImmobilised
    }, token)

    setIsOpenModal(false)
  }, [agentId, isImmobilised])

  const handleClose = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  useEffect(() => {
    immobilise.data.ifJust(success => {
      if (success) {
        showSuccess(`"${agentName}" has been successfully ${action.toLowerCase()}d!`)

        if (onSuccess) {
          onSuccess()
        }
      } else {
        showError(new Error(`Failed to ${action.toLowerCase()} "${agentName}".`))
      }
    })
  }, [immobilise.data])

  useEffect(() => {
    loadAgentDetails()
  }, [agentId])

  return (
    <>
      <Tooltip
        label={action}
        transitionProps={{
          transition: 'pop',
          duration: 200
        }}
        withArrow
      >
        <ActionIcon
          variant="transparent"
          onClick={() => {
            setIsOpenModal(true)
          }}
          loading={immobilise.loading}
          disabled={disabled}
        >
          {isImmobilised
            ? <MobiliseIcon color={isAllowed ? 'green' : 'silver'} />
            : <ImmobiliseIcon color={isAllowed ? 'red' : 'silver'} />}
        </ActionIcon>
      </Tooltip>
      <ConfirmModal
        type={isImmobilised
          ? 'confirm'
          : 'delete'}
        opened={isOpenModal}
        title={`${action} "${agentName}" Vehicle`}
        question={`Are you sure you want to ${action.toLowerCase()} this asset?`}
        confirmLabel={isImmobilised
          ? 'Mobilise'
          : 'Immobilise'}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleImmobilise}
      />
    </>
  )
}
