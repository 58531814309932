import { type MouseEvent, useCallback, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Stack,
  Paper,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { getCurrentBreakpoint } from 'src/utils/style'
import { MODES } from './constants'
import { type Feature, type MapMode } from './shared'

interface Props {
  mode: MapMode
  activeFeature?: Feature
  switchMode: (value: string) => void
  showDiscard?: boolean
  onDiscard: () => void
}

const useStyles = createStyles(() => ({
  container: {
    width: '32px',
    position: 'absolute',
    right: 10,
    top: 10
  }
}))

export default function Toolbar ({
  mode,
  switchMode,
  activeFeature,
  showDiscard = false,
  onDiscard
}: Props) {
  const { classes } = useStyles()
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false)
  const initialBreakpoint = getCurrentBreakpoint()

  const handleDelete = useCallback(() => {
    onDiscard()
    setShowDiscardConfirmation(false)
  }, [onDiscard])

  const isActiveMode = (toMode: string) => mode?.id === toMode
  const activeColor = (flag: boolean) => flag ? 'primary' : 'gray'
  const activeIconStyle = (flag: boolean) => flag ? 'fas' : 'far'
  return (
    <>
      <Paper className={classes.container}>
        <Stack spacing={0} align="center" my={5}>
          <Tooltip
            label="Draw polygon"
            color="primary"
            position="left"
            withArrow
          >
            <ActionIcon
              disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_POLYGON)}
              color={activeColor(isActiveMode(MODES.DRAW_POLYGON))}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                switchMode(isActiveMode(MODES.DRAW_POLYGON)
                  ? MODES.VIEWING
                  : MODES.DRAW_POLYGON
                )
              }}
            >
              <FontAwesomeIcon
                icon={[activeIconStyle(isActiveMode(MODES.DRAW_POLYGON)), 'draw-polygon']}
                size="1x"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Draw rectangle"
            color="primary"
            position="left"
            withArrow
          >
            <ActionIcon
              disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_RECTANGLE)}
              color={activeColor(isActiveMode(MODES.DRAW_RECTANGLE))}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                switchMode(isActiveMode(MODES.DRAW_RECTANGLE)
                  ? MODES.VIEWING
                  : MODES.DRAW_RECTANGLE
                )
              }}
            >
              <FontAwesomeIcon
                icon={[activeIconStyle(isActiveMode(MODES.DRAW_RECTANGLE)), 'rectangle']}
                size="1x"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Draw square"
            color="primary"
            position="left"
            withArrow
          >
            <ActionIcon
              disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_SQUARE)}
              color={activeColor(isActiveMode(MODES.DRAW_SQUARE))}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                switchMode(isActiveMode(MODES.DRAW_SQUARE)
                  ? MODES.VIEWING
                  : MODES.DRAW_SQUARE
                )
              }}
            >
              <FontAwesomeIcon
                icon={[activeIconStyle(isActiveMode(MODES.DRAW_SQUARE)), 'square']}
                size="1x"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Draw circle"
            color="primary"
            position="left"
            withArrow
          >
            <ActionIcon
              disabled={!isActiveMode(MODES.VIEWING) && !isActiveMode(MODES.DRAW_CIRCLE)}
              color={activeColor(isActiveMode(MODES.DRAW_CIRCLE))}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                switchMode(isActiveMode(MODES.DRAW_CIRCLE)
                  ? MODES.VIEWING
                  : MODES.DRAW_CIRCLE
                )
              }}
            >
              <FontAwesomeIcon
                icon={[activeIconStyle(isActiveMode(MODES.DRAW_CIRCLE)), 'circle']}
                size="1x"
              />
            </ActionIcon>
          </Tooltip>
          {(initialBreakpoint !== 'xxs' &&
            activeFeature) &&
            (
              <Tooltip
                label={mode?.id === MODES.EDITING ? 'Cancel Editing' : 'Edit'}
                color="primary"
                position="left"
                withArrow
              >
                <ActionIcon
                  color={activeColor(isActiveMode(MODES.EDITING))}
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    switchMode(mode?.id === MODES.EDITING
                      ? MODES.VIEWING
                      : MODES.EDITING
                    )
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fas', mode?.id === MODES.EDITING ? 'cancel' : 'pen-to-square']}
                    color="dark"
                    size="1x"
                  />
                </ActionIcon>
              </Tooltip>
            )}
          {showDiscard && (
            <Tooltip
              label="Discard unsaved"
              color="primary"
              position="left"
              withArrow
            >
              <ActionIcon onClick={() => setShowDiscardConfirmation(true)}>
                <FontAwesomeIcon icon={['fas', 'eraser']} color="dark" size="1x" />
              </ActionIcon>
            </Tooltip>
          )}
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showDiscardConfirmation}
        title="Discarding all unsaved geo zones"
        confirmLabel="Discard"
        question="Are you sure you want to perform this action? This action can't be undone."
        onClose={() => setShowDiscardConfirmation(false)}
        onCancel={() => setShowDiscardConfirmation(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
