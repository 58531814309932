import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import { getRole, type Role } from '@venturi-io/api/src/userManager/role'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from './Form'

interface RouteParams extends Record<string, string | undefined> {
  roleId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

const baseRoles = ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER']

export default function Edit () {
  const { token } = useUser()
  const role = useApi(getRole)
  const { classes } = useStyles()
  const { roleId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<Role>()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const loadRole = () => {
    void role.fetch({ roleId: parseInt(id) }, token)
  }

  useEffect(() => {
    loadRole()
  }, [])

  useEffect(() => {
    role.data.ifJust(setFormInitialValues)
  }, [role.data])

  const isBaseRole = baseRoles.includes(formInitialValues?.name ?? '')

  return (
    <Paper className={classes.container} relative>
      {role.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={role.loading}
            nothing={role.data.isNothing()}
          />
        ),
        Just: () => (
          <Form
            mode="EDIT"
            initialValues={formInitialValues}
            isBaseRole={isBaseRole}
          />
        )
      })}
    </Paper>
  )
}
