import { type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

const parseIconStyle = (style: string): FontAwesomeIconProps['icon'] => {
  try {
    const icon = JSON.parse(style.replace(/'/g, '"'))
    // bypass icon using fal to use far instead
    if (icon[0] === 'fal') {
      icon[0] = 'far'
    }
    return icon as unknown as FontAwesomeIconProps['icon']
  } catch (e) {
    return ['far', 'info']
  }
}

export default parseIconStyle
