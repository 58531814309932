import { type Row } from 'react-table'

interface Props {
  items: Row[]
  prepareRow: (row: Row<object>) => void
}

export default function Rows ({ items, prepareRow }: Props) {
  return (
    <>
      {items.map((row) => {
        prepareRow(row)
        return (
          <tr {...row.getRowProps()} key={row.id}>
            {row
              .cells
              .map((cell, i) => (
                <td {...cell.getCellProps()} key={i}>{cell.render('Cell')}</td>
              ))}
          </tr>
        )
      })}
    </>
  )
}
