import { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  createStyles,
  Anchor,
  Container,
  Group,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentsInGeoZones } from '@venturi-io/api/src/config/geoZone'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { toProperCase, truncateWithEllipsis } from 'src/utils/strings'
import { useSharedStyles } from '../shared'

const useStyles = createStyles((theme) => ({
  itemContainer: {
    background: 'white',
    overflow: 'hidden'
  },
  container: {
    padding: '10px 15px',
    overflow: 'auto',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '3.5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.gray[5],
      borderRadius: '2px'
    }
  }
}))

export interface Props {
  id: string
  name: string
}

const AssetsInGeozones = ({ name }: Props) => {
  const { ref, width, height } = useElementSize()
  const { classes: sharedClasses } = useSharedStyles()
  const { classes, cx } = useStyles()
  const { token, orgId } = useUser()
  const agentsInGeoZones = useApi(getAgentsInGeoZones)

  useEffect(() => {
    void agentsInGeoZones.fetch({ orgId }, token)
  }, [])

  return (
    <Container
      ref={ref}
      className={cx(sharedClasses.itemContainer, classes.itemContainer)}
      fluid
    >
      {!agentsInGeoZones.loading && (
        <Title
          ml="md"
          mt="sm"
          mb={5}
          order={3}
          sx={{
            position: 'absolute',
            top: 0,
            left: 2
          }}
        >
          {name}
        </Title>
      )}
      {agentsInGeoZones.loading && <Loader variant="bars" />}
      {!agentsInGeoZones.loading && (
        agentsInGeoZones.data.mapOrDefault(({ items }) => (items.length > 0
          ? (
            <Stack
              mt={50}
              spacing="xs"
              className={classes.container}
              sx={{
                width,
                height
              }}
            >
              {items.map(({ geoZoneId, name, agentCount }) => (
                <Group key={geoZoneId} position="apart">
                  <Group spacing="sm" position="center">
                    <FontAwesomeIcon
                      icon={['fas', 'vector-polygon']}
                      color="#2998F0"
                    />
                    <Anchor
                      size="sm"
                      component={Link}
                      to={`/?geoZoneId=${geoZoneId}`}
                    >
                      {toProperCase(truncateWithEllipsis(name, 20))}
                    </Anchor>
                  </Group>
                  <Text size="xs">{agentCount}</Text>
                </Group>
              ))}
            </Stack>
            )
          : (
            <Text size="xs" align="center">
              No data available
            </Text>
            )
        ), null)
      )}
    </Container>
  )
}

export default memo(AssetsInGeozones)
