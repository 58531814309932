import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { userGroup } from '../userManager/userGroup'
import {
  type AlarmActionIdRequest,
  type OrgIdRequest,
  type PaginatedRequest,
  paginated
} from '../shared'

export const actionTypes = [
  'SEND_SMS',
  'SEND_EMAIL'
] as const

const sensorInstance = z.object({
  id: z.number(),
  name: z.string(),
  agentId: z.number()
})

const alarmAction = z.object({
  id: z.number(),
  name: z.string(),
  action: z.string(),
  onTrigger: z.boolean(),
  onClear: z.boolean(),
  sensorInstances: z.array(sensorInstance),
  userGroup: userGroup.pick({
    id: true,
    name: true
  })
})

export type AlarmAction = z.infer<typeof alarmAction>

const alarmActionWithoutDependencies = alarmAction.omit({
  sensorInstances: true
})

export type AlarmActionWithoutDependencies = z.infer<typeof alarmActionWithoutDependencies>

const alarmActions = z.array(alarmActionWithoutDependencies)

const allAlarmActions = paginated.extend({
  items: alarmActions
})

interface AlarmActionRequest extends Record<string, unknown> {
  name: string
  action: string
  onTrigger: boolean
  onClear: boolean
  userGroup: {
    id: number
    name?: string
  }
}
export const createAlarmAction = createStatefulApi<AlarmActionRequest, typeof alarmActionWithoutDependencies>(
  'analytics',
  '/alarm-actions',
  { method: 'POST' },
  alarmActionWithoutDependencies
)

interface AllAlarmActionsRequest extends PaginatedRequest, OrgIdRequest {
  sensorInstanceId?: number
  search?: string
  action?: string
}
export const getAlarmActions = createStatelessApi<AllAlarmActionsRequest, typeof allAlarmActions>(
  'analytics',
  '/alarm-actions',
  { method: 'GET', type: 'path' },
  allAlarmActions
)

export const getAlarmActionsForUI = createStatelessApi<OrgIdRequest, typeof alarmActions>(
  'analytics',
  '/alarm-actions/ui',
  { method: 'GET', type: 'path' },
  alarmActions
)

export const getAlarmAction = createStatelessApi<AlarmActionIdRequest, typeof alarmAction>(
  'analytics',
  '/alarm-actions/:alarmActionId',
  { method: 'GET', type: 'path' },
  alarmAction
)

export const updateAlarmAction = createStatefulApi<AlarmActionRequest & AlarmActionIdRequest, typeof alarmActionWithoutDependencies>(
  'analytics',
  '/alarm-actions/:alarmActionId',
  { method: 'PUT' },
  alarmActionWithoutDependencies
)

export const deleteAlarmAction = createStatefulApi<AlarmActionIdRequest, z.ZodBoolean>(
  'analytics',
  '/alarm-actions/:alarmActionId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

// Sensor Instances and Alarm Actions
interface SensorInstancesAssignmentRequest extends AlarmActionIdRequest {
  sensorInstanceIds: number[]
}
export const assignSensorInstancesToAlarmAction = createStatefulApi<SensorInstancesAssignmentRequest, z.ZodBoolean>(
  'analytics',
  '/alarm-actions/:alarmActionId/sensor-instances',
  { method: 'POST' },
  z.boolean()
)

export const removeSensorInstancesFromAlarmAction = createStatefulApi<SensorInstancesAssignmentRequest, z.ZodBoolean>(
  'analytics',
  '/alarm-actions/:alarmActionId/sensor-instances',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
