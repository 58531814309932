import { useNavigate } from 'react-router-dom'
import { ActionIcon, Text } from '@mantine/core'
import { type Incident } from '@venturi-io/api/src/config/alarmHistory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from 'src/Layout/ActionList'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import TextTooltip from 'src/Layout/TextTooltip'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({ ...incident }: Incident) {
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)
  const { classes, cx } = useStyles()
  const {
    incidentId,
    name,
    setPoint,
    detected,
    cleared,
    operator,
    agentName
  } = incident

  return (
    <div className={classes.row}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{incidentId}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agentName)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">{dayjs(detected).format(uiDateFormat)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{cleared ? dayjs(cleared).format(uiDateFormat) : 'Active'}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{`${operator}${setPoint} (${name})`}</Text>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionIcon
          title="View"
          variant="filled"
          color="primary"
          onClick={() => navigate(`/alarms/incident/${incidentId}`, { state: incident })}
        >
          <FontAwesomeIcon size="sm" icon={['fas', 'eye']} color="white" />
        </ActionIcon>
      </div>
    </div>
  )
}
