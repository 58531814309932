import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type AlarmActionWithoutDependencies } from '@venturi-io/api/src/analytics/alarmAction'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import ActionButton from 'src/ActionButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  id,
  name,
  action,
  onTrigger,
  onClear
}: AlarmActionWithoutDependencies & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={name}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">{action}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={onTrigger
            ? 'Yes'
            : 'No'}
          size="sm"
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={onClear
            ? 'Yes'
            : 'No'}
          size="sm"
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/settings/alarm-actions/edit/${id}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
