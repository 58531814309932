import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  type SelectItem
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  getGeoZoneActionTypes,
  createGeoZoneAction,
  deleteGeoZoneAction,
  updateGeoZoneAction,
  type GeoZoneAction
} from '@venturi-io/api/src/config/geoZoneAction'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import SelectUserGroup from 'src/Input/Select/SelectUserGroup'

interface Props {
  mode: FormMode
  initialValues?: GeoZoneAction
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { token } = useUser()
  const navigate = useNavigate()
  const geoZoneActionTypes = useApi(getGeoZoneActionTypes)
  const newGeoZoneAction = useApi(createGeoZoneAction)
  const editGeoZoneAction = useApi(updateGeoZoneAction)
  const removeGeoZoneAction = useApi(deleteGeoZoneAction)
  const [showDelete, setShowDelete] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const actionTypes: SelectItem[] = geoZoneActionTypes.data.mapOrDefault((types) => (
    Object.entries(types).map(([key, val]) => ({
      label: val as string,
      value: key
    }))
  ), [])

  const form = useForm<Omit<GeoZoneAction, 'geoZoneActionId'>>({
    initialValues: {
      name: '',
      action: '',
      description: '',
      userGroup: {
        userGroupId: -1,
        name: '',
        description: ''
      }
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify Name' : null,
      action: value => value.length < 1 ? 'Please specify Action' : null,
      userGroup: {
        userGroupId: value => value === -1 ? 'Please specify a User Group' : null
      }
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/settings/geozone-actions')

  const handleCreate = useCallback((values: FormValues) => {
    void newGeoZoneAction
      .fetch(values, token, 'Successfully created new geozone action')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }, [form.values])

  const handleUpdate = useCallback((values: FormValues) => {
    const geoZoneActionId = initialValues?.geoZoneActionId

    if (!geoZoneActionId) throw new Error('Unable to perform the action, no action provided')

    void editGeoZoneAction
      .fetch({
        geoZoneActionId,
        ...values
      }, token, 'Successfully updated action')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const geoZoneActionId = initialValues?.geoZoneActionId

    if (!geoZoneActionId) throw new Error('Unable to perform the action, no action provided')

    void removeGeoZoneAction
      .fetch({ geoZoneActionId }, token, 'Succesfully deleted action')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    if (initialValues) form.setValues(initialValues)
  }, [initialValues])

  useEffect(() => {
    void geoZoneActionTypes.fetch({}, token)
  }, [])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <Select
              label="Action"
              data={actionTypes}
              value={form.values.action}
              onChange={action => (
                form.setFieldValue('action', action ?? '')
              )}
              withAsterisk
              error={form.errors.action}
            />
          </Col>
          <Col {...inputColProps}>
            <TextInput label="Description" {...form.getInputProps('description')} />
          </Col>
          <Col {...inputColProps}>
            <SelectUserGroup
              required
              label="User group"
              placeholder="Select user group"
              {...form.getInputProps('userGroup.userGroupId')}
              value={form.values.userGroup.userGroupId.toString()}
            />
          </Col>
          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="sm">
                <Button
                  mt="sm"
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode ? newGeoZoneAction.loading : editGeoZoneAction.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeGeoZoneAction.loading}
          question="Are you sure you want to delete this action? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
