/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  createStyles,
  type AutocompleteItem,
  Group,
  Text,
  Paper,
  Stack,
  type SelectItemProps,
  useMantineTheme
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Agent, type GeoZone } from '@venturi-io/api/src/config/geoZone'
import Autocomplete from 'src/Input/Autocomplete'
import { mq } from 'src/utils/style'
import { truncateWithEllipsis } from 'src/utils/strings'
import { useDebouncedValue } from '@mantine/hooks'
import { getAgentStatusColor } from 'src/utils/status'
import parseIconStyle from 'src/utils/parseIconStyle'
import { type SuggestedPlace, type Place, findPlaces, retrievePlace } from './mapbox'

const useStyles = createStyles((theme, moveSearchbar: boolean = false) => ({
  container: {
    position: 'absolute',
    top: 15,
    right: moveSearchbar ? 365 : 15,
    width: '230px',
    background: '#fafafa',
    zIndex: 2,
    [mq(theme.breakpoints.md)]: {
      width: '300px'
    }
  }
}))

interface Props {
  agents: Agent[]
  geoZones: GeoZone[]
  onSearchAgent?: (agent: Agent) => void
  onSearchGeoZone?: (geoZone: GeoZone) => void
  onSearchPlace?: (place: Place) => void
  moveSearchbar?: boolean
  applyStyle?: boolean
  metaName?: string
  error?: string | null | ReactNode
  label?: string
  resetMeta?: () => void
  resetSearch?: boolean
}

interface ItemProps extends SelectItemProps {
  place_formatted?: string
  agentName?: Agent['agentName']
  alarmStatus?: Agent['alarmStatus']
  agentStatus?: Agent['agentStatus']
  connectionStatus?: Agent['connectionStatus']
  lastSeenTime?: Agent['lastSeenTime']
  iconStyle?: Agent['iconStyle']
  type: string
}

const Item = forwardRef<HTMLDivElement, ItemProps>(({
  value,
  place_formatted,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  iconStyle,
  type,
  ...others
}: ItemProps, ref) => {
  const theme = useMantineTheme()
  const agentStatusColor = alarmStatus
    ? getAgentStatusColor(
      alarmStatus,
      agentStatus,
      connectionStatus,
      lastSeenTime
    )
    : theme.colors.primary[5]
  const icon = parseIconStyle(iconStyle ?? '')
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        {type === 'place' && (
          <FontAwesomeIcon icon={['fas', 'location-dot']} size="lg" color="red" />
        )}
        {type === 'agent' && (
          <FontAwesomeIcon icon={icon} size="lg" color={agentStatusColor} />
        )}
        {type === 'geozone' && (
          <FontAwesomeIcon icon={['fas', 'draw-polygon']} size="lg" color={theme.colors.primary[5]} />
        )}
        {type === 'user' && (
          <FontAwesomeIcon icon={['fas', 'user']} size="lg" color={theme.colors.primary[5]} />
        )}
        <div>
          <Text>{truncateWithEllipsis(value ?? '', 50)}</Text>
          {place_formatted && (
            <Text size="xs" color="dimmed">
              {place_formatted}
            </Text>
          )}
          {type === 'user' && (
            <Group spacing={5}>
              <FontAwesomeIcon
                icon={icon}
                size="xs"
                color={agentStatusColor}
              />
              <Text size="xs" color="dimmed">
                {truncateWithEllipsis(agentName ?? '', 20)}
              </Text>
            </Group>)}
        </div>
      </Group>
    </div>
  )
})

const ClearIcon = () => <FontAwesomeIcon icon={['fas', 'close']} size="sm" color="silver" />

export default function SearchBar ({
  agents,
  geoZones,
  onSearchAgent,
  onSearchGeoZone,
  onSearchPlace,
  moveSearchbar = false,
  applyStyle = true,
  metaName = '',
  error = '',
  label = '',
  resetMeta = () => {},
  resetSearch = false
}: Props) {
  const { classes } = useStyles(moveSearchbar)

  const [search, setSearch] = useState('')
  const [suggestedPlaces, setSuggestedPlaces] = useState<SuggestedPlace[]>([])
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const [isFocused, setIsFocused] = useState(false)
  const paperStyle = {
    transition: 'opacity 500ms ease, right 400ms ease-in-out',
    opacity: isFocused
      ? 1
      : 0.8
  }
  const agentItems = agents.map(({
    agentId,
    agentName,
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime,
    iconStyle
  }) => ({
    key: `agent-${agentId}`,
    id: agentId,
    label: agentName,
    value: agentName,
    type: 'agent',
    iconStyle,
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime,
    agentName
  }))
  const userItems = agents
    .filter(({ user }) => typeof user !== 'undefined')
    .map(({
      agentId,
      agentName,
      alarmStatus,
      agentStatus,
      connectionStatus,
      lastSeenTime,
      iconStyle,
      user
    }) => ({
      key: `user-${agentId}`,
      id: agentId,
      label: `${user?.firstName} ${user?.lastName}`,
      value: `${user?.firstName} ${user?.lastName}`,
      type: 'user',
      iconStyle,
      alarmStatus,
      agentStatus,
      connectionStatus,
      lastSeenTime,
      agentName
    }))
  const geoZoneItems = geoZones.map(({ geoZoneId, name }) => ({
    key: `geozone-${geoZoneId}`,
    id: geoZoneId,
    label: name,
    value: name,
    type: 'geozone'
  }))
  const placeItems = suggestedPlaces.map(({ mapbox_id, name, place_formatted }) => ({
    key: `place-${mapbox_id}-${place_formatted}`,
    id: mapbox_id,
    label: name,
    value: name,
    place_formatted,
    type: 'place'
  }))

  const selectItems = [
    ...agentItems,
    ...userItems,
    ...geoZoneItems,
    ...placeItems
  ]

  const handleSelectItem = useCallback(
    async (item: AutocompleteItem) => {
      setSearch(item.value)
      switch (item.type) {
        case 'user':
        case 'agent': {
          const foundAgent = agents.find(({ agentId }) => agentId === item.id)
          if (foundAgent && onSearchAgent) {
            onSearchAgent(foundAgent)
          }
          break
        }
        case 'geozone': {
          const foundGeoZone = geoZones.find(({ geoZoneId }) => geoZoneId === item.id)
          if (foundGeoZone && onSearchGeoZone) {
            onSearchGeoZone(foundGeoZone)
          }
          break
        }
        case 'place': {
          const foundPlace = await retrievePlace(item.id)
          if (foundPlace && onSearchPlace) {
            onSearchPlace(foundPlace)
          }
          break
        }
        default:
          clearSearch()
          break
      }
    },
    [agents, geoZones, onSearchAgent, onSearchGeoZone, onSearchPlace]
  )

  const clearSearch = useCallback(() => {
    setSearch('')
    resetMeta()
  }, [resetMeta])

  const handleChange = useCallback((value: string) => {
    setSearch(value)
    resetMeta()
  }, [resetMeta])

  useEffect(() => {
    const loadPlaceSuggestions = async () => {
      const suggestions = await findPlaces(debouncedSearch ?? '')
      setSuggestedPlaces(suggestions)
    }

    void loadPlaceSuggestions()
  }, [debouncedSearch])

  useEffect(() => {
    setSearch(metaName ?? '')
  }, [metaName])

  useEffect(() => {
    if (resetSearch) {
      clearSearch()
    }
  })

  return (
    <Paper
      id="tour-map__search-bar"
      style={paperStyle}
      className={applyStyle
        ? classes.container
        : ''}
      shadow={applyStyle
        ? 'lg'
        : ''}
    >
      <Stack spacing={8}>
        <Autocomplete
          value={search}
          size="sm"
          icon={<FontAwesomeIcon icon={['fas', 'magnifying-glass']} color="#d1" />}
          data={selectItems}
          itemComponent={Item}
          limit={10}
          dropdownPosition="bottom"
          rightSection={search
            ? <ClearIcon />
            : null}
          rightSectionProps={{
            onClick: clearSearch
          }}
          onChange={val => handleChange(val)}
          onItemSubmit={handleSelectItem}
          placeholder="Search by People, Asset, Geozone or Place"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          error={error}
          label={label}
          withAsterisk
          withinPortal
        />
      </Stack>
    </Paper>
  )
}
