import {
  Button,
  Center,
  Stack as MantineStack,
  Text,
  createStyles,
  useMantineTheme
} from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import Loader from 'src/Layout/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { useViewTypes } from '../../AgentAttendanceContext'
import Group from './Group'

const useStyles = createStyles((theme, isMobile: boolean) => ({
  ctaButton: {
    position: 'fixed',
    bottom: 20,
    left: isMobile
      ? 130
      : 70,
    width: 'fit-content'
  }
}))

interface Props {
  loading?: boolean
  onClick?: (agentId: Agent['agentId']) => void
}

const NoData = () => (
  <Center>
    <Text size="sm" color="dimmed" mt="md" italic>No data retrieved</Text>
  </Center>
)

export default function AgentGroups ({
  loading,
  onClick
}: Props) {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { classes } = useStyles(isMobile)
  const { allGroups } = useViewTypes()

  return (
    <>
      <MantineStack mt="xs" spacing={10}>
        {loading && <Loader />}
        {allGroups.length === 0 && !loading
          ? <NoData />
          : allGroups.map(agentGroup => {
            return (
              <Group
                key={agentGroup.agentGroupId}
                agentGroup={agentGroup}
                onClick={onClick}
              />
            )
          })}
      </MantineStack>
      <Button
        title="New Agent Group"
        color="primary"
        leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
        onClick={() => navigate('/settings/agent-groups')}
        className={classes.ctaButton}
      >
        New Agent Group
      </Button>
    </>
  )
}
