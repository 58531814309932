import {
  Container,
  Text,
  Title,
  Group,
  Stack,
  SimpleGrid,
  useMantineTheme,
  ScrollArea
} from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import { useContext, useMemo } from 'react'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import TimePeriodSelector from 'src/Input/TimePeriodSelector'
import { mq } from 'src/utils/style'
import { formatNumber } from 'src/utils/numbers'
import { useSharedStyles } from '../shared'
import { MODULE_CONTENT } from './constants'
import { TimeBucketContext } from './data'

interface TotalItemProps {
  value: number
  label: string
  subtitle?: string
}

function TotalItem ({ value, label, subtitle }: TotalItemProps) {
  const { classes } = useSharedStyles()
  const { breakpoints: { sm } } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(sm, false))
  return (
    <Group position="apart" className={classes.center} align="flex-start">
      <Stack spacing={0} align="center" >
        <Title size={isDesktop ? '2.5rem' : '2rem'}>{formatNumber(value)}</Title>
        <Text color="gray.8" size="xs">{label}</Text>
        {subtitle && <Text color="dimmed" size="xs">{`(${subtitle})`}</Text>}
      </Stack>
    </Group>
  )
}
interface TotalProps {
  activeThreePhase?: number
  posActiveThreePhase?: number
  negActiveThreePhase?: number
  powerFactor?: number
}

interface Props {
  sensors: Record<string, Sensor | null>
}

export default function PowerTotals ({ sensors }: Props) {
  const { sensorData, loading, bucket, updateBucket } = useContext(TimeBucketContext)
  const { ref, height: containerHeight } = useElementSize()
  const { classes } = useSharedStyles()
  const { breakpoints: { sm } } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(sm, false))

  const {
    activeThreePhase = 0,
    posActiveThreePhase = 0,
    negActiveThreePhase = 0,
    powerFactor = 0
  }: TotalProps = useMemo(() => {
    const keys = Object.keys(sensors)

    return keys.reduce<TotalProps>((acc, key) => {
      const dt = sensors[key]
      if (!dt) return acc
      const singleSensorData = sensorData.find((sensor) => sensor.sensorInstanceId === dt.sensorInstanceId)
      if (singleSensorData) {
        if (key === 'powerFactor') {
          const total = singleSensorData.data.reduce((total, data) => {
            total += data.y ?? 0
            return total
          }, 0)
          return {
            ...acc,
            [key]: total / singleSensorData.data.length
          }
        } else {
          const lastTotal = singleSensorData.data[0].y
          const initialTotal = lastTotal ?? 0
          return {
            ...acc,
            [key]: singleSensorData.data.reduce((total, data) => {
              total += data.y ?? 0
              return total
            }, initialTotal)
          }
        }
      }
      return acc
    }, {})
  }, [sensorData])

  return (
    <Container ref={ref} className={classes.dock} fluid>
      <Group position="apart">
        <Text size="sm" weight={500}>
          {MODULE_CONTENT.POWER_TOTALS.toUpperCase()}
        </Text>
        <TimePeriodSelector chosenBucket={bucket} updateBucket={updateBucket} />
      </Group>
      <LoadingOverlay visible={loading} />
      <ScrollArea sx={{ height: containerHeight - 30 }}>
        <SimpleGrid cols={isDesktop ? 4 : 1}>
          <TotalItem
            value={activeThreePhase}
            label="Kilo watts hour"
            subtitle="Active energy"
          />
          <TotalItem
            value={posActiveThreePhase}
            label="Kilo watts hour"
            subtitle="Positive energy"
          />
          <TotalItem
            value={negActiveThreePhase}
            label="Kilo watts hour"
            subtitle="Negative energy"
          />
          <TotalItem
            value={powerFactor}
            label="Average power factor"
          />
        </SimpleGrid>
      </ScrollArea>
    </Container>
  )
}
