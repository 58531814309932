import { useLocalStorage } from '@mantine/hooks'
import { menuList } from 'src/menuData'
import { wrapWithNeedsRole } from 'src/NeedsRole'
import DropdownMenu from './DropdownMenu'
import MenuItem from './MenuItem'

export interface MenuListProps {
  isDesktop: boolean
  show: boolean
  setShow: (input: boolean) => void
}

export default function MenuList ({ show, setShow, isDesktop }: MenuListProps) {
  const [environment] = useLocalStorage({
    key: 'environment',
    defaultValue: ''
  })
  const items = environment === 'DEV-SYD'
    ? menuList
    : menuList.filter(({ isHidden }) => !isHidden)

  return (
    <>
      {items.map(item => {
        const menuProps = {
          key: item.title,
          item,
          isDesktop,
          isSidebarOpen: show,
          closeMenu: () => setShow(false)
        }
        return item.children.length > 0
          ? wrapWithNeedsRole(item.role, (
            <DropdownMenu
              {...menuProps}
              setSidebarOpen={setShow}
            />
          ))
          : wrapWithNeedsRole(item.role, (
            <MenuItem {...menuProps} />
          ))
      })}
    </>
  )
}
