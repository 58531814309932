import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import { getGeoZoneRule, type GeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from './Form'

interface RouteParams extends Record<string, string | undefined> {
  geoZoneRuleId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { token, orgId } = useUser()
  const geoZoneRule = useApi(getGeoZoneRule)
  const { classes } = useStyles()
  const { geoZoneRuleId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<GeoZoneRule>()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const loadGeoZoneRule = () => {
    void geoZoneRule.fetch({
      orgId,
      geoZoneRuleId: parseInt(id)
    }, token)
  }

  useEffect(() => {
    loadGeoZoneRule()
  }, [])

  useEffect(() => {
    geoZoneRule.data.ifJust(setFormInitialValues)
  }, [geoZoneRule.data])

  return (
    <Paper className={classes.container} relative>
      {geoZoneRule.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={geoZoneRule.loading}
            nothing={geoZoneRule.data.isNothing()}
          />
        ),
        Just: () => <Form mode="EDIT" initialValues={formInitialValues} />
      })}
    </Paper>
  )
}
