import { createStyles, Group, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import { useMeta } from 'src/MetaContext'
import TextTooltip from 'src/Layout/TextTooltip'
import Logo from './Menu/Logo'
import { type StyleParams, styleParams } from './shared'
import ProfileMenu from './ProfileMenu'
import { CloseMenu } from './Menu/CloseMenu'

const useStyles = createStyles((theme, params: StyleParams) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: theme.colors.primary[7],
    height: params.headerHeight,
    top: 0,
    left: 0,
    right: 0,
    zIndex: zIndex.header
  },
  titleContainer: {
    flex: 1,
    [mq(theme.breakpoints.sm)]: {
      paddingLeft: '82px'
    }
  },
  title: {
    color: theme.white,
    fontSize: '16pt',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [mq(theme.breakpoints.sm)]: {
      textAlign: 'left'
    }
  }
}))

export interface Props {
  orgId: number
  show: boolean
  signout: () => void
  setShow: (input: boolean) => void
}

export default function Header (props: Props) {
  const { classes } = useStyles({ ...styleParams })
  const { meta } = useMeta()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const isTablet = useMediaQuery(mq(theme.breakpoints.md, false))

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <Group position={isDesktop ? 'apart' : 'left'} spacing="sm" grow={isDesktop}>
          {!isDesktop && <Logo orgId={props.orgId} minimised isDesktop={isDesktop} />}
          {!isTablet
            ? <TextTooltip className={classes.title} value={meta.pageTitle} maxLength={20} />
            : <TextTooltip className={classes.title} value={meta.pageTitle} maxLength={35} />}
          {isDesktop
            ? <ProfileMenu signout={props.signout} />
            : <CloseMenu {...props} showLabel={isDesktop} />}
        </Group>
      </div>
    </header>
  )
}
