/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import { type ComplianceSummary } from '@venturi-io/api/src/config/alarmHistory'
import dayjs from 'dayjs'
import { dateFormat, uiDateOnly } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type DateRange } from 'src/UIState'
import { type AlarmItem } from '..'

interface Props {
  agentName: string
  sensorName: string
  complianceSummary: ComplianceSummary
  data: AlarmItem[]
  dateRange: DateRange
}

const formatToReadableDate = (date: Date | null) => dayjs(date, dateFormat).format(uiDateOnly)

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  complianceSummary: ComplianceSummary
  dateRange: DateRange
}
const Details = ({
  complianceSummary: {
    average,
    minimum,
    maximum,
    samplesCollected,
    alarms,
    alarmIncidents
  },
  dateRange: [
    startDate,
    endDate
  ]
}: DetailsProps) => (
  <>
    <View style={reportStyles.details}>
      <LabelValue
        label="Period Range"
        value={`${formatToReadableDate(startDate)} - ${formatToReadableDate(endDate)}`}
      />
    </View>
    <Space />
    <View style={reportStyles.details}>
      <LabelValue
        label="Average During Period"
        value={average?.toString() ?? '0'}
      />
      <LabelValue
        label="Samples Collected"
        value={samplesCollected.toString()}
      />
    </View>
    <Space />
    <View style={reportStyles.details}>
      <LabelValue
        label="Minimum During Period"
        value={minimum?.toString() ?? '0'}
      />
      <LabelValue
        label="Configured Alarms"
        value={alarms.toString()}
      />
    </View>
    <Space />
    <View style={reportStyles.details}>
      <LabelValue
        label="Maximum During Period"
        value={maximum?.toString() ?? '0'}
      />
      <LabelValue
        label="Alarm Incidents"
        value={alarmIncidents.toString()}
      />
    </View>
  </>
)

interface Value {
  value: string
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Incident" />
    <THCell value="Agent" />
    <THCell value="Detected" />
    <THCell value="Resolved" />
    <THCell value="Alarm" />
  </View>
)

const TableRow = ({
  incident_id,
  detected,
  resolved,
  alarm,
  agent_name
}: AlarmItem) => (
  <View style={reportStyles.group}>
    <TDCell value={incident_id.toString()} />
    <TDCell value={agent_name} />
    <TDCell value={detected} />
    <TDCell value={resolved} />
    <TDCell value={alarm} />
  </View>
)

interface TableBodyProps {
  data: AlarmItem[]
}
const TableBody = ({ data }: TableBodyProps) => data.map(item => (
  <TableRow key={item.incident_id} {...item} />
))

const Space = () => <View style={reportStyles.space} />

function AlarmHistoryReport ({
  agentName,
  sensorName,
  complianceSummary,
  data,
  dateRange
}: Props) {
  const title = `${agentName} (${sensorName}) - Alarm History Report`
  return (
    <ReportTemplate
      title={title}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details
        complianceSummary={complianceSummary}
        dateRange={dateRange}
      />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default AlarmHistoryReport
