import { useCallback } from 'react'
import {
  Button,
  Col,
  Drawer,
  Group,
  Grid,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMockApi } from 'src/utils/useApi'
import { assignRolesToGroup } from '@venturi-io/api/src/config/agentGroup'
import { useUser } from 'src/UserContext'
import MultiSelectRole from 'src/Input/MultiSelect/MultiSelectRole'
interface Props {
  excludeRoleIds: number[]
  agentGroupId: number
  show: boolean
  onClose: () => void
  onSave?: () => Promise<void>
}
const hasValue = (inputName: string, value: string | string[]) => value.length < 1
  ? `Please specify ${inputName}`
  : null

const RoleAssignment = ({ agentGroupId, excludeRoleIds, show, onClose, onSave }: Props) => {
  const { token } = useUser()
  // TODO: Add roles API here.
  // TODO: Change useMockApi to useApi
  const addRolesToGroup = useMockApi(assignRolesToGroup, true)

  const form = useForm<{ roleIds: string[] }>({
    initialValues: {
      roleIds: []
    },
    validate: {
      roleIds: value => hasValue('Role(s)', value)
    }
  })

  const handleSubmit = useCallback(({ roleIds }: typeof form.values) => {
    if (roleIds.length) {
      void addRolesToGroup
        .fetch({
          agentGroupId,
          roleIds: roleIds.map(id => Number(id))
        }, token)
        .finally(() => {
          form.reset()

          onClose()

          if (onSave) void onSave()
        })
    }
  }, [form.values])

  const handleClose = useCallback(() => {
    form.reset()

    onClose()
  }, [])

  return (
    <Drawer
      opened={show}
      onClose={onClose}
      title={<Title order={3}>Add Role</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <MultiSelectRole
              {...form.getInputProps('roleIds')}
              excludeRoleIds={excludeRoleIds}
              label="Roles"
              value={form.values.roleIds}
              onChange={ids => (
                form.setFieldValue('roleIds', ids)
              )}
              searchable
            />
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', 'circle-plus']}
                    color="white"
                  />
                )}
                disabled={addRolesToGroup.loading}
              >
                Add
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}

export default RoleAssignment
