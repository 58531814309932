import { useMantineTheme, Group } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import Alarms from './Alarms'
import EngineState, { type Status as EngineStatus } from './EngineState'
import ControlMode, { type Mode } from './ControlMode'

interface Props {
  alarms: number
  engineState: EngineStatus
  mode: Mode
}

export default function Statuses ({ alarms, engineState, mode }: Props) {
  const { breakpoints: { xs } } = useMantineTheme()
  const isSmallerScreen = useMediaQuery(mq(xs, false))

  return (
    <Group
      position="center"
      align="center"
      spacing={isSmallerScreen
        ? 16
        : 8}
    >
      {alarms > 0 && (
        <Alarms count={alarms} showTitle={isSmallerScreen} />
      )}
      <EngineState
        status={engineState}
        showTitle={isSmallerScreen}
        expanded={isSmallerScreen}
      />
      <ControlMode
        mode={mode}
        showTitle={isSmallerScreen}
        expanded={isSmallerScreen}
      />
    </Group>
  )
}
