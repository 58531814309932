import { type ReactNode } from 'react'
import { type ListChildComponentProps } from 'react-window'
import { type AgentLog } from '@venturi-io/api/src/config/agent'
import { Text } from '@mantine/core'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { parseEmpty } from 'src/utils/parseEmpty'
import { columnStyle } from '../shared'

interface Props extends ListChildComponentProps<AgentLog[]> {
  isItemLoaded: (index: number) => boolean
}

interface TdProps {
  children: ReactNode
}

const Td = ({ children }: TdProps) => (
  <td style={columnStyle}>
    <Text lineClamp={3}>{children}</Text>
  </td>
)

const Row = ({ index, data, style, isItemLoaded }: Props) => {
  const item = data[index]

  return (
    !isItemLoaded(index)
      ? null
      : (
        <tr key={`${item.id}-${item.log}-${index}`} style={style}>
          <Td>{parseEmpty(dayjs(item.timestamp).format(uiDateFormat))}</Td>
          <Td>{parseEmpty(item.log)}</Td>
          <Td>{parseEmpty(item.severity)}</Td>
        </tr>
        )
  )
}

export default Row
