import { Container, ScrollArea, Stack } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { ReactGrid, type Column, type Row } from '@silevis/reactgrid'
import { type ReportData } from '@venturi-io/api/src/config/report'
import Paper from 'src/Layout/Paper'
import { toProperCase } from 'src/utils/strings'

interface ReportViewProps {
  data: ReportData
}

export default function ReportView ({ data }: ReportViewProps) {
  const { ref, width } = useElementSize()

  function getRowsAndColumns (items: ReportData[0]) {
    const raw = items[0]
    const columns: Column[] = Object
      .keys(raw)
      .map(columnId => ({ columnId, width: 250 }))
    const headerRow: Row = {
      rowId: 'header',
      cells: Object
        .keys(raw)
        .map(columnId => ({
          type: 'header',
          text: toProperCase(columnId)
        }))
    }
    const rows: Row[] = [
      headerRow,
      ...items.map<Row>((item, idx) => ({
        rowId: idx,
        cells: Object
          .keys(item)
          .map(key => ({
            type: 'text',
            text: item[key] ?? ''
          }))
      }))
    ]

    return {
      columns,
      rows
    }
  }

  return (
    <Paper p="lg">
      <Container ref={ref} fluid px={0}>
        <ScrollArea
          type="always"
          scrollbarSize={4}
          style={{ width }}
        >
          <Stack justify="space-between" spacing="md">
            {Object
              .keys(data)
              .map(queryId => (
                <ReactGrid
                  key={queryId}
                  {...getRowsAndColumns(data[queryId])}
                  enableFullWidthHeader
                  stickyTopRows={1}
                />
              ))}
          </Stack>
        </ScrollArea>
      </Container>
    </Paper>
  )
}
