import { memo, useCallback, useMemo } from 'react'
import {
  Accordion,
  Box,
  Button,
  Group,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import { defaultStyles as defaultAutoGridStyles } from 'src/Charts/AutoGrid'
import MultiSelectAgent from 'src/Input/MultiSelect/MultiSelectAgent'
import ItemPreview from '../shared/ItemPreview'
import AgentStatus, { type Props as AgentStatusProps } from '.'

interface Props {
  initialValues: AgentStatusProps | null
  onSubmit: (properties: AgentStatusProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: AgentStatusProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        agentIds: []
      },
      styles: {
        ...defaultAutoGridStyles
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const alignments = useMemo(() => (
    [
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-left']}
              color="primary"
            />
            <Text size="sm">Left</Text>
          </Group>
        ),
        value: 'flex-start'
      },
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-center']}
              color="primary"
            />
            <Text size="sm">Center</Text>
          </Group>
        ),
        value: 'center'
      },
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-right']}
              color="primary"
            />
            <Text size="sm">Right</Text>
          </Group>
        ),
        value: 'flex-end'
      }
    ]
  ), [])

  const textSizes = useMemo(() => (
    [
      {
        label: 'XS',
        value: 'xs'
      },
      {
        label: 'S',
        value: 'sm'
      },
      {
        label: 'M',
        value: 'md'
      },
      {
        label: 'L',
        value: 'lg'
      },
      {
        label: 'XL',
        value: 'xl'
      }
    ]
  ), [])

  const hasSelectedAgents = useMemo(() => form.values.data.agentIds.length > 0, [form.values.data.agentIds])

  const parsedAgentIds = useMemo(() => (
    form.values.data.agentIds.map(id => id.toString())
  ), [form.values.data.agentIds])

  const previewItem = useMemo(() => hasSelectedAgents, [hasSelectedAgents])

  const setFormDataValue = useCallback((
    key: string,
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormStylesValue = useCallback((
    key: keyof AgentStatusProps['styles'],
    value: unknown
  ) => {
    form.setValues({
      ...form.values,
      styles: {
        ...form.values.styles,
        [key]: value
      }
    })
  }, [form.values.styles])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview
        iconName="temperature-quarter"
        previewItem={previewItem}
        disabled
      >
        <AgentStatus {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <MultiSelectAgent
                    label="Agents"
                    value={parsedAgentIds}
                    onChange={agentIds => {
                      setFormDataValue(
                        'agentIds',
                        agentIds.map(id => Number(id))
                      )
                    }}
                    required
                    searchable
                    error={form.errors.agentIds}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="styles">
              <Accordion.Control>Styles</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <Stack spacing="xs">
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Top"
                        placeholder="Padding Top"
                        value={form.values.styles.padding.top}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            top: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Bottom"
                        placeholder="Padding Bottom"
                        value={form.values.styles.padding.bottom}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            bottom: value
                          })
                        }}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Left"
                        placeholder="Padding Left"
                        value={form.values.styles.padding.left}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            left: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Right"
                        placeholder="Padding Right"
                        value={form.values.styles.padding.right}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            right: value
                          })
                        }}
                      />
                    </SimpleGrid>
                  </Stack>

                  <Box>
                    <Text size="xs">Align Text</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.align}
                      data={alignments}
                      onChange={value => setFormStylesValue('align', value)}
                    />
                  </Box>

                  <Box>
                    <Text size="xs">Text Size</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.textSize}
                      data={textSizes}
                      onChange={value => setFormStylesValue('textSize', value)}
                    />
                  </Box>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
