import { useEffect, useState } from 'react'
import { type ApiCallable, type Options as ApiOptions } from '@venturi-io/api/src/shared'
import { Just, type Maybe, Nothing } from 'purify-ts'
import { type z } from 'zod'

export type UseNotifications = () => {
  showSuccess: (message: string) => void
  showError: (err: Error) => void
}

export const configureUseApi = (useNotifications: UseNotifications) => {
  return <Req extends Record<string, unknown>, Res> (
    api: ApiCallable<Req, Res>,
    needsToken = true,
    needsNotifications = true,
    needsBearerToken = false
  ) => {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<ApiOptions>()
    const [data, setData] = useState<Maybe<Res>>(Nothing)
    const [error, setError] = useState<Maybe<z.ZodError<{}> | Error>>(Nothing)
    const [pollInterval, setPollInterval] = useState<NodeJS.Timeout | null>(null)
    const [retryInterval, setRetryInterval] = useState<NodeJS.Timeout | null>(null)
    const [retryCount, setRetryCount] = useState(0)
    const [cachedRequest, setCachedRequest] = useState<Req | null>(null)
    const [cachedSuccess, setCachedSuccess] = useState<string>()
    const [cachedToken, setCachedToken] = useState<string | null>(null)
    const [cachedBearerToken, setCachedBearerToken] = useState<string>()
    const notifications = needsNotifications ? useNotifications() : undefined
    const retryLimitReached = options && retryCount > options.retryLimit
    const controller = new AbortController()

    const fetch = async (request: Req, token: string, success?: string, bearerToken?: string) => {
      if (needsToken && token === '') throw new Error('No token provided')
      if (needsBearerToken && (!bearerToken || bearerToken === '')) throw new Error('No bearer token provided')

      setLoading(true)
      setCachedRequest(request)
      setCachedSuccess(success)
      setCachedToken(token)
      setCachedBearerToken(bearerToken)

      await api(request, token, bearerToken, controller.signal)
        .caseOf({
          Left: error => {
            if (error.options) {
              setOptions(error.options)
            }

            if (error.name === 'AbortError') return
            setError(Just(error))
            if (notifications) {
              notifications.showError(error)
            } else {
              console.error(error)
              setError(Nothing)
            }
          },
          Right: result => {
            if (success && notifications) notifications.showSuccess(success)
            setData(Just(result))
          }
        })
        .finally(() => setLoading(false))
    }

    const clearState = () => setData(Nothing)

    const clearAll = () => {
      setData(Nothing)
      setError(Nothing)
      setCachedRequest(null)
      setCachedSuccess(undefined)
      setCachedToken(null)
      setCachedBearerToken(undefined)
      setLoading(false)
    }

    const startPolling = (request: Req, token: string, seconds: number) => {
      if (pollInterval) {
        stopPolling()
      }

      setPollInterval(
        setInterval(() => {
          void fetch(request, token)
        }, seconds * 1000)
      )
    }

    const stopPolling = () => {
      if (pollInterval) {
        clearInterval(pollInterval)
        setPollInterval(null)
      }
    }

    const retry = () => {
      if (cachedRequest !== null) {
        void fetch(cachedRequest, cachedToken ?? '', cachedSuccess, cachedBearerToken)
      }
    }

    const cancelRetry = () => {
      if (retryInterval) {
        clearInterval(retryInterval)
        setRetryInterval(null)
        setRetryCount(0)
      }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const __set = (res: Maybe<Res>, err: Maybe<Error>, loading: boolean) => {
      setData(res)
      setError(err)
      setLoading(loading)
    }

    useEffect(() => {
      if (options && !options.isStateful && options.isRetryable && retryInterval) {
        if (retryLimitReached) {
          cancelRetry()
        } else {
          retry()
        }
      }

      return cancelRetry
    }, [retryCount])

    useEffect(() => {
      error.ifJust(() => {
        if (
          options &&
          options.retryDelay > 0 &&
          (!options.isStateful && options.isRetryable) &&
          (!retryInterval && options.retryLimit > 0) &&
          !retryLimitReached
        ) {
          // This function will trigger retry() if there is an error based on the given interval and retryLimit
          setRetryInterval(
            setInterval(() => {
              setRetryCount(prevCount => prevCount + 1)
            }, options.retryDelay * 1000)
          )
        }

        stopPolling()
      })
    }, [error])

    useEffect(() => {
      return () => {
        stopPolling()
        controller.abort()
      }
    }, [])

    return {
      data,
      error,
      loading,
      abort: () => controller.abort(),
      fetch,
      retry,
      startPolling,
      stopPolling,
      clearState,
      clearAll,
      __set
    }
  }
}
