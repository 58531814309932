import { memo, useEffect, useMemo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import RadialGauge, { type Props as RadialGaugeProps } from 'src/Charts/RadialGauge'
import { round, computeSensorPercentage } from 'src/utils/math'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data: {
    agentId: Agent['agentId']
    sensorInstanceId: Sensor['sensorInstanceId']
  }
  styles: {
    innerData: RadialGaugeProps['innerData']
    outerData: RadialGaugeProps['outerData']
  }
}

const SensorGauge = ({
  data: {
    agentId,
    sensorInstanceId
  },
  styles
}: Props) => {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)

  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensor = useMemo(() => (
    agent?.sensors.find(s => s.sensorInstanceId === sensorInstanceId) ?? null
  ), [agent, sensorInstanceId])

  const sensorValue = useMemo(() => (
    sensor?.currentValue
      ? round(parseFloat(sensor.currentValue))
      : 0
  ), [sensor])

  const sensorPercentage = useMemo(() => (
    sensor !== null
      ? computeSensorPercentage(sensor)
      : 0
  ), [sensor])

  const innerData: RadialGaugeProps['innerData'] = useMemo(() => ({
    id: 1,
    value: sensorValue,
    color: styles.innerData.color
  }), [sensor, styles.innerData])

  const outerData: RadialGaugeProps['outerData'] = useMemo(() => styles.outerData, [styles.outerData])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Container
      ref={ref}
      className={classes.itemContainer}
      sx={{
        background: 'white'
      }}
      fluid
    >
      {agentDetails.loading && <Loader variant="bars" />}
      {(!agentDetails.loading && agent && sensor) && (
        <RadialGauge
          width={width}
          height={height}
          title={`${agent.agentName} - ${sensor.name}`}
          value={sensorPercentage}
          innerData={innerData}
          outerData={outerData}
        />
      )}
    </Container>
  )
}

export default memo(SensorGauge)
