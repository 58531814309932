import {
  Container,
  Group,
  ScrollArea,
  SimpleGrid,
  Text,
  createStyles
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import valueMap from 'src/utils/valueMap'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import ImmobiliseButton from 'src/Buttons/ImmobiliseButton'
import { colors } from 'src/utils/status'
import GroupBoxValue from '../shared/GroupBoxValue'
import { findSensorByType, useSharedStyles } from '../shared'
import { MODULE_CONTENT } from './constants'

const useStyles = createStyles(() => ({
  contentContainer: {
    width: '100%',
    height: '87%'
  }
}))

interface Props {
  agent: AgentDetails
}

const defaultEngineMap = {
  '0.00': 'OFF',
  '1.00': 'ON'
}

const defaultImmobilisationMap = {
  '0.00': 'IMMOBILISED',
  '1.00': 'MOBILISED'
}

export default function Status ({
  agent
}: Props) {
  const {
    agentId,
    assetType,
    alarmStatus,
    agentStatus,
    sensors,
    hasImmobiliser
  } = agent
  const { classes } = useStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const { ref, width, height } = useElementSize()
  const responsiveFontSize = `${(width + height) / 55}px`

  // TODO: Map correct engine state
  const engineState = findSensorByType('ENGINE_IGNITION_STATUS', sensors)
  const immobilisationState = findSensorByType('IMMOBILISATION_STATUS', sensors)

  const engineMap = engineState
    ? Object.values(engineState.valueMap).length
      ? engineState.valueMap
      : defaultEngineMap
    : defaultEngineMap

  let engineStateValue = valueMap(
    engineMap,
    engineState
      ? engineState.currentValue
      : 'OFF')
    .toUpperCase()

  if (agentStatus === 'IDLE') {
    engineStateValue = 'IDLE'
  }

  const engineStateColor = agentStatus === 'MOVING'
    ? colors.green
    : agentStatus === 'IDLE'
      ? colors.yellow
      : colors.gray

  const immobilisationMap = immobilisationState
    ? Object.values(immobilisationState.valueMap).length
      ? immobilisationState.valueMap
      : defaultImmobilisationMap
    : defaultImmobilisationMap

  const immobilisationStateValue = hasImmobiliser
    ? valueMap(
      immobilisationMap,
      immobilisationState
        ? immobilisationState.currentValue ?? '-'
        : 'INACTIVE')
      .toUpperCase()
    : 'NOT INSTALLED'

  const immobilisationStateColor = agentStatus === 'IMMOBILISED' || immobilisationStateValue === 'IMMOBILISED'
    ? colors.orange
    : colors.gray

  const alarmStatusColor = alarmStatus === 'OK'
    ? colors.green
    : colors.red

  return (
    <Container ref={ref} className={sharedClasses.dock} fluid>
      <Group className={sharedClasses.contentTitle} position="apart" noWrap>
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.VEHICLE_STATUS}
        </Text>
        {assetType?.toLowerCase() === 'vehicle' && hasImmobiliser && (
          <ImmobiliseButton
            agentId={agentId}
            onSuccess={() => {
              window.location.reload()
            }}
          />
        )}
      </Group>
      <ScrollArea
        style={{
          height: `calc(${height}px - 30px)`
        }}
        type="auto"
        scrollbarSize={5}
        offsetScrollbars
      >
        <SimpleGrid className={classes.contentContainer} cols={1} spacing="xs">
          <GroupBoxValue
            label="ENGINE STATE"
            value={engineStateValue}
            labelSize={responsiveFontSize}
            valueSize={responsiveFontSize}
            color={engineStateColor}
          />
          <GroupBoxValue
            label="ALARM STATUS"
            value={alarmStatus}
            labelSize={responsiveFontSize}
            valueSize={responsiveFontSize}
            color={alarmStatusColor}
          />
          <GroupBoxValue
            label="IMMOBILISATION STATE"
            value={immobilisationStateValue}
            labelSize={responsiveFontSize}
            valueSize={responsiveFontSize}
            color={immobilisationStateColor}
          />
        </SimpleGrid>
      </ScrollArea>
    </Container>
  )
}
