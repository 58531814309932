import {
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { createAgent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import TextInput from 'src/Input/TextInput'
import SelectAssetType from 'src/Input/Select/SelectAssetType'
import SelectSite from 'src/Input/Select/SelectSite'

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

interface FormProps {
  agentName: string
  siteId: number
  assetType?: string
}

export default function Create ({ show, close, onCreate }: Props) {
  const create = useApi(createAgent)
  const { token, orgId } = useUser()

  const form = useForm<FormProps>({
    initialValues: {
      agentName: '',
      siteId: -1
    },
    validate: {
      agentName: name => name.length < 1 ? 'Please input an Agent Name' : null,
      siteId: siteId => siteId < 0 ? 'Please choose a Site' : null
    }
  })

  const handleSubmit = (values: typeof form.values) => {
    void create
      .fetch({
        orgId,
        ...values,
        assetType: values.assetType === ''
          ? undefined
          : values.assetType
      }, token, 'Successfully created new agent')
      .finally(() => {
        form.reset()
        void onCreate()
        close()
      })
  }

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Agent</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Col span={12}>
            <TextInput required label="Agent Name" {...form.getInputProps('agentName')} />
          </Col>
          <Col span={12}>
            <SelectSite
              label="Site"
              {...form.getInputProps('siteId')}
              value={form.values.siteId.toString()}
              searchable
              required
            />
          </Col>
          <Col span={12}>
            <SelectAssetType
              label="Asset Type"
              {...form.getInputProps('assetType')}
              value={form.values.assetType?.toString()}
            />
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={() => {
                  form.reset()
                  close()
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={create.loading}
              >
                Create
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
