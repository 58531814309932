import {
  Button,
  Group,
  Modal,
  type ModalProps,
  Stack,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ConfirmModalType =
  | 'confirm'
  | 'delete'

type PromiseOrVoid = Promise<void> | void

interface Props extends ModalProps {
  type: ConfirmModalType
  title?: string
  question?: string
  confirmLabel?: string
  loading?: boolean
  onCancel: () => PromiseOrVoid
  onConfirm: () => PromiseOrVoid
}

export default function ConfirmModal ({
  loading,
  type,
  title,
  question,
  confirmLabel,
  onCancel,
  onConfirm,
  ...rest
}: Props) {
  const isDelete = type === 'delete'

  const deleteButtons = (
    <>
      <Button color="red" onClick={onConfirm} disabled={loading}>
        {confirmLabel ?? 'Delete'}
      </Button>
      <Button color="gray" onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
    </>
  )

  const confirmButtons = (
    <>
      <Button color="green" onClick={onConfirm} disabled={loading}>
        {confirmLabel ?? 'Confirm'}
      </Button>
      <Button color="gray" onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
    </>
  )

  return (
    <Modal
      size="xs"
      withCloseButton={false}
      {...rest}
    >
      <Stack align="center">
        <FontAwesomeIcon
          icon={['fas', 'message-exclamation']}
          color={isDelete ? 'red' : 'green'}
          size="4x"
        />
        <Text size="xl" align="center" weight={700}>
          {title ?? 'Are you sure?'}
        </Text>
        {question && (
          <Text size="sm" align="center">
            {question}
          </Text>
        )}
        <Group position="center">
          {isDelete
            ? deleteButtons
            : confirmButtons}
        </Group>
      </Stack>
    </Modal>
  )
}
