import { z } from 'zod'
import { paginated, type Req, type PaginatedRequest } from '../shared'
import { createStatefulApi, createStatelessApi } from '../configure'

const sensorDataTransformer = z.object({
  name: z.string(),
  function: z.string()
})

const sensorDataTransformerWithId = sensorDataTransformer.extend({
  id: z.number()
})

const sensorDataTransformers = paginated.extend({
  items: z.array(sensorDataTransformerWithId)
})

export type SensorDataTransformer = z.infer<typeof sensorDataTransformerWithId>

export interface CreateSensorDataTransformer extends Req {
  name: string
  function: string
}

export const createSensorDataTransformer = createStatefulApi<CreateSensorDataTransformer, typeof sensorDataTransformerWithId>(
  'config',
  '/sensor-data-transformers',
  { method: 'POST' },
  sensorDataTransformerWithId
)

interface GetSensorDataTransformer extends PaginatedRequest {
  search?: string
}

export const getSensorDataTransformers = createStatelessApi<GetSensorDataTransformer, typeof sensorDataTransformers>(
  'config',
  '/sensor-data-transformers',
  { method: 'GET', type: 'path' },
  sensorDataTransformers
)

interface SensorDataTransformerId extends Req {
  sensorDataTransformerId: number
}

export const getSensorDataTransformer = createStatelessApi<SensorDataTransformerId, typeof sensorDataTransformerWithId>(
  'config',
  '/sensor-data-transformers/:sensorDataTransformerId',
  { method: 'GET', type: 'path' },
  sensorDataTransformerWithId
)

export interface UpdateSensorDataTransformer extends SensorDataTransformerId {
  name: string
  function: string
}

export const updateSensorDataTransformer = createStatefulApi<UpdateSensorDataTransformer, typeof sensorDataTransformer>(
  'config',
  '/sensor-data-transformers/:sensorDataTransformerId',
  { method: 'PUT' },
  sensorDataTransformer
)

export const deleteSensorDataTransformer = createStatefulApi<SensorDataTransformerId, z.ZodBoolean>(
  'config',
  '/sensor-data-transformers/:sensorDataTransformerId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
