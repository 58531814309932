import {
  Button,
  Center,
  Image,
  Modal,
  Stack,
  Text
} from '@mantine/core'

export interface Props {
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  onConfirm: () => void
}

export default function Intro ({
  isOpen,
  setIsOpen,
  onConfirm
}: Props) {
  return (
    <Modal
      centered
      opened={isOpen}
      radius={15}
      onClose={() => setIsOpen(false)}
      withCloseButton={false}
      transitionProps={{
        transition: 'pop',
        duration: 200,
        timingFunction: 'linear'
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      <Stack
        align="stretch"
        justify="center"
        spacing="md"
        m="sm"
      >
        <Center>
          <Image
            width={55}
            height={55}
            src="/logo.svg"
            alt="Brand Logo"
            fit="contain"
          />
        </Center>
        <Text
          weight="bold"
          size="sm"
          ta="center"
        >
          Welcome to the Venturi Asset Management Platform
        </Text>
        <Text
          weight="lighter"
          size="xs"
          ta="center"
        >
          Let&apos;s find out how we can support your company, in a couple of minutes
        </Text>
      </Stack>
      <Center>
        <Button
          mt="sm"
          type="button"
          color="primary"
          onClick={onConfirm}
        >
          Get started
        </Button>
      </Center>
    </Modal>
  )
}
