import { type RefObject, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type LngLatLike, type MapRef } from 'react-map-gl'
import { getGeoZone } from '@venturi-io/api/src/config/geoZone'
import mapboxgl from 'mapbox-gl'

interface Props {
  mapRef: RefObject<MapRef>
}

let lastLoadedGeoId = 0
function URLGeozoneLoader ({ mapRef }: Props) {
  const { token } = useUser()
  const [params] = useSearchParams()
  const [geoId, setGeoId] = useState(Number(params.get('geoZoneId')))
  const geoDetails = useApi(getGeoZone)

  const loadGeoZone = () => {
    if (
      mapRef.current &&
      geoId !== lastLoadedGeoId &&
      geoId !== 0
    ) {
      lastLoadedGeoId = geoId
      void geoDetails.fetch({ geoZoneId: geoId }, token)
    }
  }

  useEffect(() => {
    const paramGeo = params.get('geoZoneId')
    const newGeoId = Number(paramGeo)
    if (newGeoId !== geoId) {
      setGeoId(newGeoId)
    }
    if (paramGeo === null) {
      lastLoadedGeoId = 0
    }
  }, [params])

  useEffect(() => {
    loadGeoZone()
  }, [geoId, mapRef.current])

  useEffect(() => {
    geoDetails.data.ifJust((data) => {
      const { boundary: { coordinates } } = data

      const geoPoints: LngLatLike[] = coordinates.map(coordinate => {
        return coordinate.map(([lng, lat]) => ({ lng, lat }))
      }).flat()

      if (coordinates.length === 0 || !mapRef.current) return

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        geoPoints[0],
        geoPoints[0]
      )

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of geoPoints) {
        bounds.extend(coord)
      }

      mapRef.current.fitBounds(bounds, {
        padding: 40,
        maxZoom: 16
      })
    })
  }, [geoDetails.data])

  return null
}

export default URLGeozoneLoader
