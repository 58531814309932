/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Container,
  Table as MantineTable,
  Text,
  createStyles,
  ActionIcon
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import NoData from 'src/Dashboard/Items/shared/NoData'
import Nothing from 'src/Nothing'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { type SpeedAlert, getSpeedAlerts } from '@venturi-io/api/src/analytics/speedAlerts'

const useStyles = createStyles((theme, { stickyTop }: { stickyTop: number }) => ({
  container: {
    background: 'white'
  },
  head: {
    backgroundColor: theme.colors.gray[0],
    position: 'sticky',
    top: stickyTop,
    zIndex: 3,
    borderTop: 0
  },
  table: {
    backgroundColor: theme.colors.gray[0]
  },
  row: {
    zIndex: 2
  },
  center: { margin: '0 auto' },
  dock: {
    width: '100%',
    height: '100%',
    padding: '0 .5px',
    backgroundColor: theme.colors.gray[0]
  },
  contentTitle: {
    margin: '0px 0px 15px 0px'
  },
  select: {
    input: {
      fontSize: '.7rem'
    },
    width: '100px'
  },
  statusBarContainer: {
    marginTop: '-10px'
  },
  statusGaugeContainer: {
    height: '50%',
    width: '20%',
    marginTop: '0'
  },
  gaugeSubLabel: {
    position: 'absolute',
    bottom: 10,
    left: 70
  },
  checkbox: {
    input: {
      backgroundColor: 'white',
      '&:checked': {
        backgroundColor: theme.colors.primary[5],
        border: `1px solid ${theme.colors.primary[5]}`
      }
    }
  }
}))

interface Props {
  tripId: number
  showTitle?: boolean
  style?: React.CSSProperties
  stickyTop?: number
  didChangeHeight?: (height: number) => void
}

export interface TableProps extends Pick<Props, 'didChangeHeight' | 'stickyTop'> {
  tripId: number
  data: SpeedAlert[]
}

interface LocationCellProps {
  longitude?: number
  latitude?: number
}

function LocationCell ({ longitude, latitude }: LocationCellProps) {
  const [address, setAddress] = useState('...')
  const loadAddress = async () => {
    if (longitude && latitude) {
      const result = await reverseGeocode([longitude, latitude])
      setAddress(result)
    }
  }

  useEffect(() => {
    if (longitude && latitude) {
      void loadAddress()
    }
  }, [longitude, latitude])

  return (
    <td>{address}</td>
  )
}

function Rows ({ data, tripId }: TableProps) {
  return data.length > 0
    ? (
      <>
        {data.map(({
          id,
          agentId,
          speed,
          speedLimit,
          speedDateTime,
          geoZoneName,
          agentLocationPoint
        }, idx) => {
          return (
            <tr key={`${agentId}-${id}`}>
              <td>{idx + 1}</td>
              <td>{dayjs(speedDateTime).format(uiDateFormatUniversal)}</td>
              <LocationCell longitude={agentLocationPoint?.longitude} latitude={agentLocationPoint?.latitude} />
              <td>{geoZoneName ?? 'N/A'}</td>
              <td>
                {speedLimit}
                {' '}
                km/h
              </td>
              <td>
                {speed}
                {' '}
                km/h
              </td>
            </tr>
          )
        })}
      </>
      )
    : (
      <tr>
        <td colSpan={15}><NoData message="No speed events available" /></td>
      </tr>
      )
}

const barHeight = 12
const Table = ({
  data,
  tripId,
  stickyTop = 0,
  didChangeHeight
}: TableProps) => {
  const { classes } = useStyles({ stickyTop: stickyTop ?? 0 })
  const { ref, height } = useElementSize()

  useEffect(() => {
    if (didChangeHeight) {
      didChangeHeight(height + barHeight)
    }
  }, [height])

  return (
    <MantineTable
      className={classes.table}
      ref={ref}
      fontSize="xs"
    >
      <thead className={classes.head}>
        <tr>
          <th>Event #</th>
          <th>Event Date/Time</th>
          <th>Location</th>
          <th>Geozone</th>
          <th>Speed Limit</th>
          <th>Speed Violation</th>
        </tr>
      </thead>
      <tbody>
        <Rows data={data} tripId={tripId} />
      </tbody>
    </MantineTable>
  )
}

export default function SpeedAlerts ({
  tripId,
  showTitle = true,
  style,
  stickyTop = 0,
  didChangeHeight
}: Props) {
  const { token, orgId } = useUser()
  const speedAlerts = usePaginatedApi(getSpeedAlerts)

  const { classes: sharedClasses } = useStyles({ stickyTop })
  const { ref } = useElementSize()

  const loadTrips = () => {
    void speedAlerts.fetch({
      orgId,
      tripId,
      parentLog: true,
      page: 1,
      size: 999
    }, token)
  }

  useEffect(() => {
    void loadTrips()
  }, [tripId])

  return (
    <Container ref={ref} className={sharedClasses.dock} fluid style={style}>
      {showTitle && (
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          Speed alert events
        </Text>
      )}

      {speedAlerts.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={speedAlerts.loading}
            nothing={speedAlerts.data.isNothing()}
          />
        ),
        Just: ({ items }) => (
          <Table
            didChangeHeight={didChangeHeight}
            tripId={tripId}
            data={items}
            stickyTop={stickyTop}
          />
        )
      })}
    </Container>
  )
}
