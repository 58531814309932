import { Anchor, Breadcrumbs as MantineBreadcrumbs, Text } from '@mantine/core'
import { Link } from 'react-router-dom'

export interface BreadcrumbsItem {
  title: string
  href?: string
}

interface Props {
  items: BreadcrumbsItem[]
}

export default function Breadcrumbs ({ items }: Props) {
  return (
    <MantineBreadcrumbs separator="/">
      {items.map(({ title, href }, index) => (
        href
          ? (
            <Anchor
              component={Link}
              to={href}
              key={index}
              size="xs"
            >
              {title}
            </Anchor>
            )
          : <Text key={index} size="xs" color="gray.5">{title}</Text>
      ))}
    </MantineBreadcrumbs>
  )
}
