import { createStyles, Stack } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import SensorBar from './SensorBar'

const useStyles = createStyles(theme => ({
  container: {
    minWidth: 0,
    padding: '16px 28px',
    [mq(theme.breakpoints.md)]: {
      padding: '30px 60px'
    }
  }
}))

interface Props {
  engineBatteryVoltage: Sensor | null
  coolantTemperature: Sensor | null
  oilPressure: Sensor | null
}

export default function Sensors ({
  engineBatteryVoltage,
  coolantTemperature,
  oilPressure
}: Props) {
  const { classes } = useStyles()

  return (
    <Stack className={classes.container} spacing={16}>
      <SensorBar label="Battery Voltage" sensor={engineBatteryVoltage} />
      <SensorBar label="Coolant Temperature" sensor={coolantTemperature} />
      <SensorBar label="Oil Pressure" sensor={oilPressure} />
    </Stack>
  )
}
