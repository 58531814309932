import { type BarDatum, type ResponsiveBarSvgProps, ResponsiveBar } from '@nivo/bar'

interface Props extends ResponsiveBarSvgProps<BarDatum> {
  height: number
}

export default function Bar ({ height, ...props }: Props) {
  return (
    <div style={{ height }}>
      <ResponsiveBar {...props} />
    </div>
  )
}
