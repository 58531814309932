import React, { useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Popover,
  Text,
  Tooltip,
  Switch,
  useMantineTheme,
  Stack,
  Divider,
  Group
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { getTripContext } from '../AnimationContext'
import { getToolContext } from './Devtools'

function Filters () {
  const { colors } = useMantineTheme()
  const {
    showRaw,
    showSpeeding,
    showIdling,
    showBe,
    showMarkerElements,
    setShowRaw,
    setShowSpeeding,
    setShowIdling,
    setShowBe,
    setShowMarkerElements
  } = useContext(getToolContext())

  const { trip } = useContext(getTripContext())
  const [show, { toggle, close }] = useDisclosure(false)

  useEffect(() => {
    if (trip) {
      // trip was played
      setShowRaw(false)
      setShowSpeeding(true)
      setShowIdling(true)
      setShowBe(true)
    }
  }, [trip])

  return (
    <Popover
      opened={show}
      position="left-end"
      offset={{
        mainAxis: 20
      }}
    >
      <Popover.Target>
        <Tooltip
          hidden={show}
          withArrow
          position="left"
          label="Filters"
        >
          <ActionIcon
            size="sm"
            onClick={toggle}
          >
            <FontAwesomeIcon
              size="lg"
              color={colors.gray[7]}
              icon={['far', 'sliders']}
            />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Stack w={144} spacing="xs">
          <Stack pt={12} px={16}>
            <Group position="apart">
              <Text size="xs" weight="bold">
                Map Filters
              </Text>
              <ActionIcon onClick={close} size="xs">
                <FontAwesomeIcon icon={['far', 'xmark']} />
              </ActionIcon>
            </Group>
            <Switch
              color="primary"
              checked={showMarkerElements}
              onClick={() => setShowMarkerElements(!showMarkerElements)}
              size="xs"
              label={(
                <Text size="xs">
                  Agents
                </Text>
              )}
            />
          </Stack>
          <Divider />
          <Stack pb={16} px={16} spacing={8}>
            <Text size="xs" color="dimmed" weight="bold">
              Trips
            </Text>
            <Switch
              color="primary"
              checked={showRaw}
              onClick={() => setShowRaw(!showRaw)}
              size="xs"
              label={(
                <Text size="xs">
                  Points
                </Text>
              )}
            />
            <Switch
              color="primary"
              checked={showSpeeding}
              onClick={() => setShowSpeeding(!showSpeeding)}
              size="xs"
              label={(
                <Text size="xs">
                  Speeding
                </Text>
              )}
            />
            <Switch
              color="primary"
              checked={showIdling}
              onClick={() => setShowIdling(!showIdling)}
              size="xs"
              label={(
                <Text size="xs">
                  Idling
                </Text>
              )}
            />
            <Switch
              color="primary"
              checked={showBe}
              onClick={() => setShowBe(!showBe)}
              size="xs"
              label={(
                <Text size="xs">
                  Trip line
                </Text>
              )}
            />
          </Stack>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default Filters
