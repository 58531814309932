import { createStyles } from '@mantine/core'

export const defaultSensors: Record<string, number[]> = {
  GENSET: [
    153,
    56
  ],
  ATS: [
    167
  ],
  UPS: [
    174,
    7,
    175
  ],
  TANK: [
    56,
    154,
    51
  ],
  VEHICLE: [
    538,
    536
  ]
}

type Time = 'seconds' | 'minutes' | 'hours'
export const giveTransmitData = (input: number, type: Time) => {
  const result = {
    value: -1,
    label: `${input} ${type}`
  }

  switch (type) {
    case 'hours':
      result.value = input * 60 * 60
      break
    case 'minutes':
      result.value = input * 60
      break
    case 'seconds':
    default:
      result.value = input
      break
  }

  if (input === 1) {
    result.label = `${input} ${type.substring(0, type.length - 1)}`
  }

  return {
    ...result,
    value: result.value.toString()
  }
}

export const useStyles = createStyles(theme => ({
  transferListTitle: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 'normal'
  }
}))

export const defaultLocation = {
  latitude: 6.63560377632845,
  longitude: 109.54597315781179
}

export const transmitIntervalData = [
  giveTransmitData(30, 'seconds'),
  giveTransmitData(1, 'minutes'),
  giveTransmitData(5, 'minutes'),
  giveTransmitData(10, 'minutes'),
  giveTransmitData(30, 'minutes'),
  giveTransmitData(1, 'hours'),
  giveTransmitData(4, 'hours'),
  giveTransmitData(8, 'hours'),
  giveTransmitData(12, 'hours'),
  giveTransmitData(24, 'hours')
]
