import Breaches from './Breaches'
import Report from './Report'

export default function Incident () {
  return (
    <>
      <Report />
      <Breaches />
    </>
  )
}
