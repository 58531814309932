import { type ReactNode } from 'react'
import { Box } from '@mantine/core'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import { useSharedStyles } from '..'
import ItemPlaceholder from './ItemPlaceholder'

interface Props {
  iconName: IconName
  children: ReactNode
  previewItem?: boolean
  disabled?: boolean
}

export default function ItemPreview ({
  iconName,
  children,
  previewItem = false,
  disabled = false
}: Props) {
  const { classes } = useSharedStyles()

  return (
    <Box
      className={classes.itemBox}
      sx={{
        pointerEvents: disabled
          ? 'none'
          : 'initial'
      }}
      mb="md"
    >
      {previewItem
        ? children
        : <ItemPlaceholder iconName={iconName} />}
    </Box>
  )
}
