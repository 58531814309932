import { Center, Text } from '@mantine/core'
import LoadingOverlay from './Layout/LoadingOverlay'

interface Props {
  isLoading: boolean
  nothing: boolean
  placeholder?: string
}

export default function Nothing ({
  isLoading,
  nothing,
  placeholder = 'No data retrieved'
}: Props) {
  return isLoading || nothing
    ? <LoadingOverlay visible={isLoading} />
    : <Center><Text>{placeholder}</Text></Center>
}
