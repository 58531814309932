import { Box, Stack, Title } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import Pie from '../Charts/Pie'

const data = [
  {
    id: 'stationary',
    label: 'stationary',
    value: 65,
    color: 'hsl(3.3, 88.35%, 59.61%)'
  },
  {
    id: 'immobilised',
    label: 'immobilised',
    value: 501,
    color: 'hsl(36, 100%, 50%)'
  },
  {
    id: 'moving',
    label: 'moving',
    value: 238,
    color: 'hsl(122.97, 39.61%, 50%) '
  },
  {
    id: 'off',
    label: 'off',
    value: 214,
    color: 'hsl(230, 12%, 90.2%)'
  },
  {
    id: 'no actual state',
    label: 'no actual state',
    value: 15,
    color: 'hsl(0, 100%, 79.02%)'
  },
  {
    id: 'no coordinates',
    label: 'no coordinates',
    value: 25,
    color: 'hsl(53.88, 100%, 61.57%)'
  }
]

interface Props {
  lightModeOn: boolean
}

export default function Pies ({ lightModeOn = false }: Props) {
  const {
    ref: pieARef,
    width: pieAWidth,
    height: pieAHeight
  } = useElementSize()

  const {
    ref: pieBRef,
    width: pieBWidth,
    height: pieBHeight
  } = useElementSize()

  return (
    <Box>
      <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
        Pies
      </Title>
      <Stack spacing={2}>
        <Box
          ref={pieARef}
          sx={{
            height: 400,
            width: '100%'
          }}
        >
          <Pie
            height={pieAHeight}
            width={pieAWidth}
            data={data}
            margin={{
              top: 40,
              right: 80,
              bottom: 80,
              left: 80
            }}
            startAngle={-84}
            innerRadius={0.35}
            activeOuterRadiusOffset={10}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.2
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={7}
            arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
            arcLinkLabelsStraightLength={19}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={false}
            arcLabelsRadiusOffset={0.4}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.9
                ]
              ]
            }}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 26,
              clamp: false,
              precision: 0.01,
              velocity: 0
            }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: 300,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Box
          ref={pieBRef}
          sx={{
            height: 300,
            width: '100%'
          }}
        >
          <Pie
            height={pieBHeight}
            width={pieBWidth}
            data={data}
            margin={{
              top: 40,
              right: 80,
              bottom: 80,
              left: 80
            }}
            startAngle={-90}
            endAngle={90}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.2
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={7}
            arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
            arcLinkLabelsStraightLength={19}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={false}
            arcLabelsRadiusOffset={0.4}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.9
                ]
              ]
            }}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 26,
              clamp: false,
              precision: 0.01,
              velocity: 0
            }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />
        </Box>
      </Stack>
    </Box>
  )
}
