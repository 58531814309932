import {
  type ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { type Agent, getAgents } from '@venturi-io/api/src/config/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  label?: string
  excludeAgentIds?: number[]
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const agentToSelect = ({ agentId, agentName }: Agent): SelectItem => ({
  value: agentId.toString(),
  label: agentName
})

export default function MultiSelectAgent ({
  label,
  excludeAgentIds,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const agents = useApi(getAgents)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectAgents = useMemo(() => {
    const allAgents = agents.data.mapOrDefault(agent => agent, [])
    const filteredAgents = allAgents.filter(({ agentId }) => !excludeAgentIds?.some(id => id === agentId))
    return filteredAgents.map(agentToSelect)
  }, [agents.data])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void agents.fetch({ orgId }, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose agent(s)'}
      value={value}
      data={selectAgents}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || agents.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
