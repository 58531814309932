import { useParams } from 'react-router'
import { getTankTransactions } from '@venturi-io/api/src/config/fms'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useEffect } from 'react'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import ActionList from 'src/Layout/ActionList'
import { Card, Header, Row } from './List'

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

const Transactions = () => {
  const { assetId } = useParams<RouteParams>()
  const { token } = useUser()
  const transactions = usePaginatedApi(getTankTransactions)

  if (!assetId) throw new Error('No asset ID given, routing is broken')

  const agentId = Number(assetId)

  const loadTransactions = async (page?: number, size?: number): Promise<void> => {
    void transactions.fetch({
      agentId,
      page: page ?? transactions.page,
      size: size ?? transactions.size,
      sort: 'imported',
      order: 'desc'
    }, token)
  }

  useEffect(() => {
    void loadTransactions()
  }, [transactions.page])

  return (
    <>
      <LoadingOverlay visible={transactions.loading} color="primary" />
      <ActionList
        isLoading={transactions.loading}
        data={transactions.data.mapOrDefault(({ items }) => items, [])}
        getId={({ transactionId }) => transactionId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={transactions.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={transactions.page}
        onPageChange={transactions.setPage}
      />
    </>
  )
}

export default Transactions
