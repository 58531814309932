import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import {
  type SensorType,
  getSensors,
  getAllSensorTypes,
  sensorTypes
} from '@venturi-io/api/src/config/sensor'
import { type order } from '@venturi-io/api/src/shared'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import Sorter from 'src/Input/Sorter'
import ActionList from 'src/Layout/ActionList'
import { Header, Row, Card } from './List'
import CreateSensor from './Create'

export const defaultValueFormat = 'FLOAT'

const sortProperties = ['id', 'name', 'type', 'unit']

export default function Sensors () {
  const [showCreate, setShowCreate] = useState(false)
  const [sensorName, setSensorName] = useState<string | undefined>()
  const [debouncedSensorName] = useDebouncedValue(sensorName, 700)
  const [sensorType, setSensorType] = useState<SensorType | 'ALL'>('ALL')
  const [sort, setSort] = useState<string | undefined>('name')
  const [order, setOrder] = useState<order>('asc')

  const sensors = usePaginatedApi(getSensors)
  const allSensorTypes = useApi(getAllSensorTypes)
  const { token } = useUser()

  const loadSensors = async (page?: number, size?: number): Promise<void> => {
    void sensors
      .fetch({
        page: page ?? sensors.page,
        size: size ?? sensors.size,
        name: debouncedSensorName,
        type: sensorType !== 'ALL' ? sensorType : undefined,
        sort,
        order
      }, token)
  }

  const loadAllSensorType = async (): Promise<void> => {
    void allSensorTypes.fetch({}, token)
  }

  const selectSensorType = allSensorTypes
    .data
    .mapOrDefault(sensors => (
      sensors.map(({ name }) => ({
        label: name,
        value: name
      }))
    ), [])

  useEffect(() => {
    void loadSensors(1)
  }, [debouncedSensorName, sensorType, sort, order])

  useEffect(() => {
    void loadSensors()
  }, [sensors.page])

  useEffect(() => {
    void loadAllSensorType()
  }, [])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create Sensor"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new sensor
        </Button>
      </Group>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} md={5}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search sensor here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSensorName(e.target.value)}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by type"
              data={['ALL', ...sensorTypes]}
              onChange={(value: SensorType) => setSensorType(value)}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by sensor type"
              data={['ALL', ...selectSensorType]}
              onChange={(value: SensorType) => setSensorType(value)}
            />
          </Col>
          <Col span={12} md={2}>
            <Sorter
              data={sortProperties}
              defaultSort={sort}
              defaultOrder={order}
              onChange={(sort_, order_) => {
                setSort(sort_)
                setOrder(order_)
              }}
              title="Sort Order"
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={sensors.loading}
        data={sensors.data.mapOrDefault(({ items }) => items, [])}
        getId={({ id }) => id}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={sensors.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={sensors.page}
        onPageChange={sensors.setPage}
      />
      <CreateSensor
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadSensors}
      />
    </>
  )
}
