import { Group, ScrollArea, Table, Text } from '@mantine/core'
import ActionButton from 'src/ActionButton'
import dayjs from 'dayjs'
import { uiDateOnly } from 'src/utils/dates'
import TextTooltip from 'src/Layout/TextTooltip'

interface ListAttachmentProps {
  attachments: Array<{ id: number, name: string, url: string, createdAt?: string }>
  onFileClick: (fileUrl: string) => void
  onFileDownload: (fileUrl: string, name: string) => void
  isMobile?: boolean
}

// if mobile, assigned height=100
export const ListAttachment = ({ attachments, onFileClick, onFileDownload, isMobile }: ListAttachmentProps) => (
  <ScrollArea
    type="auto"
    h={isMobile ? 400 : 100}
    scrollbarSize={5}
    style={{ flex: 1 }}
  >
    {attachments.length > 0
      ? (
        <Table>
          <tbody>
            {attachments.map((item) => (
              <tr key={item.id}>
                <td>
                  <Text
                    transform="uppercase"
                    weight="bold"
                    size="xs"
                    sx={{ minWidth: 80 }}
                  >
                    {item.id}
                  </Text>
                </td>
                <td style={{ width: '250px' }}>
                  <TextTooltip
                    fw={500}
                    value={item.name}
                    maxLength={12}
                  />
                </td>
                <td style={{ width: '80px' }}>
                  <Text
                    transform="uppercase"
                    weight="bold"
                    size="xs"
                    sx={{ minWidth: 80 }}
                  >
                    {dayjs(item.createdAt).format(uiDateOnly)}
                  </Text>
                </td>
                <td style={{ width: '200px' }}>
                  <Group
                    position="right"
                    spacing="xs"
                  >
                    <ActionButton
                      label="Download"
                      icon="arrow-alt-circle-down"
                      iconType="fas"
                      onClick={() => onFileDownload(item.url, item.name)}
                      actionColor="blue"
                      actionVariant="filled"
                      iconColor="white"
                      iconSize="sm"
                    />
                    <ActionButton
                      label="Preview"
                      icon="eye"
                      iconType="fas"
                      onClick={() => onFileClick(item.url)}
                      actionColor="green"
                      actionVariant="filled"
                      iconColor="white"
                      iconSize="sm"
                    />
                  </Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        )
      : (
        <Text align="center" fw={500} size="lg">No attachment available</Text>
        )}
  </ScrollArea>
)
