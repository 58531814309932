import { Group } from '@mantine/core'
import DiagnosticMetricMini from '../Components/DiagnosticMetricMini'
import { type DiagnosticMetricItem } from '../Components/DiagnosticMetric'

interface Props {
  items: DiagnosticMetricItem[]
}

export default function DiagnosticMetrics ({ items }: Props) {
  return (
    <Group spacing={20}>
      {/* Limit display upto 4 items only */}
      {items.slice(0, 4).map((props, index) => (
        <DiagnosticMetricMini key={index} {...props} />
      ))}
    </Group>
  )
}
