import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import NotFound from 'src/Router/NotFound'
import { getOrg, type Organisation } from '@venturi-io/api/src/userManager/org'
import Nothing from 'src/Nothing'
import Form from './Form'

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  orgId: string
}

export default function Edit () {
  const { token } = useUser()
  const { classes } = useStyles()
  const { orgId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<Organisation>()
  const org = useApi(getOrg)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const loadOrganisation = () => {
    void org.fetch({ orgId: parseInt(id) }, token)
  }

  useEffect(() => {
    loadOrganisation()
  }, [])

  useEffect(() => {
    org
      .data
      .ifJust(values => setFormInitialValues(values))
  }, [org.data])

  return (
    <Paper className={classes.container} relative>
      {org.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={org.loading}
            nothing={org.data.isNothing()}
          />
        ),
        Just: () => <Form mode="EDIT" initialValues={formInitialValues} />
      })}
    </Paper>
  )
}
