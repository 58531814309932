import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { type Sensor, getSensors } from '@venturi-io/api/src/config/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const sensorToSelect = ({ id, name }: Sensor): SelectItem => ({
  value: id.toString(),
  label: name
})

export default function MultiSelectSensor ({
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const sensors = useApi(getSensors)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectSensors = sensors.data.mapOrDefault(data => data.map(sensorToSelect), [])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void sensors.fetch({}, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose sensor(s)'}
      value={value}
      data={selectSensors}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || sensors.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
