import { type CSSProperties } from 'react'

interface Props {
  width?: CSSProperties['height']
  height?: CSSProperties['height']
  color?: CSSProperties['color']
}

export default function Bar ({
  width = '100%',
  height = '100%',
  color = 'blue'
}: Props) {
  return (
    <div
      style={{
        width,
        height,
        backgroundColor: color,
        borderRadius: '4px'
      }}
    >
    </div>
  )
}
