import { type Agent, type Sensor } from '@venturi-io/api/src/config/agent'
import { Box, Stack } from '@mantine/core'
import { findSensor } from 'src/AssetTemplate/shared'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'
import SensorHistoryStackedLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryStackedLineChart'
import SensorHistoryStackedBarChart from 'src/AssetTemplate/Components/Charts/SensorHistoryStackedBarChart'

interface Props {
  agentId: Agent['agentId']
  sensors: Sensor[]
}

export default function Power ({ agentId, sensors }: Props) {
  const powerLevel = findSensor('POWER_LEVEL_KVA', sensors)
  const powerCapacity = findSensor('POWER_CAPACITY', sensors)
  const powerFactor = findSensor('POWER_FACTOR_THREE_PHASE', sensors)
  const powerFrequency = findSensor('POWER_FREQUENCY', sensors)
  const voltageLNA = findSensor('GENERATOR_L1N_VOLTAGE', sensors)
  const voltageLNB = findSensor('GENERATOR_L2N_VOLTAGE', sensors)
  const voltageLNC = findSensor('GENERATOR_L3N_VOLTAGE', sensors)
  const voltageLLA = findSensor('GENERATOR_L1L2_VOLTAGE', sensors)
  const voltageLLB = findSensor('GENERATOR_L1L3_VOLTAGE', sensors)
  const voltageLLC = findSensor('GENERATOR_L3L1_VOLTAGE', sensors)
  const voltageCurrentA = findSensor('GENERATOR_L1_CURRENT', sensors)
  const voltageCurrentB = findSensor('GENERATOR_L2_CURRENT', sensors)
  const voltageCurrentC = findSensor('GENERATOR_L3_CURRENT', sensors)

  return (
    <Box>
      <Stack spacing={28}>
        {powerLevel && (
          <SensorHistoryLineChart
            title="Power Level"
            sensorInstanceId={powerLevel.sensorInstanceId}
            unit={powerLevel?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
        {powerCapacity && (
          <SensorHistoryLineChart
            title="Power Capacity"
            sensorInstanceId={powerCapacity.sensorInstanceId}
            unit={powerCapacity?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
        {powerFactor && (
          <SensorHistoryLineChart
            title="Power Factor"
            sensorInstanceId={powerFactor.sensorInstanceId}
            unit={powerFactor?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
        {powerFrequency && (
          <SensorHistoryLineChart
            title="Power Frequency"
            sensorInstanceId={powerFrequency.sensorInstanceId}
            unit={powerFrequency?.unit}
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
        {voltageLNA && voltageLNB && voltageLNC && (
          <SensorHistoryStackedLineChart
            title="Voltage (L-N)"
            agentId={agentId}
            sensorInstanceIds={[
              voltageLNA.sensorInstanceId,
              voltageLNB.sensorInstanceId,
              voltageLNC.sensorInstanceId
            ]}
            unit="V"
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
            unstacked
          />
        )}
        {voltageLLA && voltageLLB && voltageLLC && (
          <SensorHistoryStackedLineChart
            title="Voltage (L-L)"
            agentId={agentId}
            sensorInstanceIds={[
              voltageLLA.sensorInstanceId,
              voltageLLB.sensorInstanceId,
              voltageLLC.sensorInstanceId
            ]}
            unit="V"
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
            unstacked
          />
        )}
        {voltageCurrentA && voltageCurrentB && voltageCurrentC && (
          <SensorHistoryStackedBarChart
            title="Voltage (Current)"
            agentId={agentId}
            sensorInstanceIds={[
              voltageCurrentA.sensorInstanceId,
              voltageCurrentB.sensorInstanceId,
              voltageCurrentC.sensorInstanceId
            ]}
            unit="A"
            height={300}
            margin={{
              top: 30,
              right: 20,
              bottom: 50,
              left: 40
            }}
          />
        )}
      </Stack>
    </Box>
  )
}
