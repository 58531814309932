import { ColorInput as MantineColorInput, type ColorInputProps, createStyles } from '@mantine/core'

const useStyles = createStyles(theme => ({
  input: {
    border: `0.808511px solid ${theme.colors.primary[6]}`,
    boxSizing: 'border-box',
    borderRadius: '2.42553px',
    '&:active': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    },
    '&:focus': {
      border: `0.808511px solid ${theme.colors.primary[2]}`
    },
    '&:disabled': {
      border: `0.808511px solid ${theme.colors.gray[5]}`
    },
    '&[aria-invalid=true]': {
      border: `0.808511px solid ${theme.colors.red[6]}`
    }
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px'
  }
}))

export default function ColorInput (props: ColorInputProps) {
  const { classes } = useStyles()

  return <MantineColorInput classNames={classes} {...props} />
}
