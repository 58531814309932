import {
  Button,
  Group,
  Paper,
  Grid,
  Col
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useForm } from '@mantine/form'
import { useCallback, useEffect } from 'react'
import { getOrg, updateOrg } from '@venturi-io/api/src/userManager/org'
import { useApi } from 'src/utils/useApi'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import SelectTimezone from 'src/Input/Select/SelectTimezone'
import NumberInput from 'src/Input/NumberInput'

interface InitialValues {
  defaultTimezone?: string
  speedLimit?: number
}

const checkSpeedLimitVal = (val?: string) => val && /^[0-9]*$/.test(val)

const Organisation = () => {
  const { orgId, token } = useUser()
  const editOrg = useApi(updateOrg)
  const org = useApi(getOrg)
  const orgData = org.data.mapOrDefault(data => data, null)

  useEffect(() => {
    void org.fetch({ orgId }, token)
  }, [])

  const form = useForm<InitialValues>({
    validate: {
      speedLimit: value => checkSpeedLimitVal(value?.toString())
        ? null
        : 'Please input number only'
    }
  })

  type FormValues = typeof form.values

  const handleUpdate = useCallback(({
    defaultTimezone,
    speedLimit,
    ...values
  }: FormValues) => {
    if (!orgId) {
      throw new Error('Unable to perform the action, no organisation provided')
    }

    if (orgData) {
      void editOrg.fetch({
        name: orgData.name,
        urls: orgData.urls,
        defaultTimezone: defaultTimezone?.toString(),
        speedLimit: Number(speedLimit),
        ...values,
        orgId
      }, token, 'Successfully updated organisation')
    }
  }, [form.values])

  useEffect(() => {
    form.setValues({
      defaultTimezone: orgData?.defaultTimezone,
      speedLimit: orgData?.speedLimit
    })
  }, [orgData])

  return (
    <Paper px={20} py={10}>
      <LoadingOverlay visible={org.loading} />
      <form onSubmit={form.onSubmit(handleUpdate)}>
        <Grid grow align="center" justify="center">
          <Col span={12} sm={6}>
            <SelectTimezone
              searchable
              label="Time zone"
              {...form.getInputProps('defaultTimezone')}
              isOrgTimezone={true}
              showInfo={false}
            />
          </Col>
          <Col span={12} sm={6}>
            <NumberInput
              label="Speed Limit"
              {...form.getInputProps('speedLimit')}
            />
          </Col>
          <Col span={6}>
            <Group position="right" mt="sm">
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={org.loading}
              >
                Update
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Paper>
  )
}

export default Organisation
