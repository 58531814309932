import { type Sensor } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from '../shared'

export const MODULE_CONTENT = {
  TIME_SELECTOR: 'Time Selector',
  POWER_CURRENT: 'Current Output',
  POWER_STATUS: 'Power Status',
  POWER_TOTALS: 'Totals',
  POWER_GRAPH_STACKED_KILOWATTS: 'Kilo Watts',
  POWER_GRAPH_STACKED_CURRENT: 'Current',
  POWER_GRAPH_SINGLE_POWER_FACTOR: 'Power Factor',
  POWER_GRAPH_SINGLE_LL: 'LL',
  POWER_GRAPH_SINGLE_LN: 'LN',
  POWER_GRAPH_SINGLE_POSITIVE_ACTIVE: 'Positive Active',
  POWER_GRAPH_SINGLE_NEGATIVE_ACTIVE: 'Negative Active'
} as const

export const findSensors = (sensors: Sensor[]) => {
  const posActiveThreePhase = findSensorByType('POSITIVE_ACTIVE_THREE_PHASE', sensors)
  const negActiveThreePhase = findSensorByType('NEGATIVE_ACTIVE_THREE_PHASE', sensors)
  const powerFactor = findSensorByType('POWER_FACTOR_THREE_PHASE', sensors)
  const activeThreePhase = findSensorByType('ACTIVE_THREE_PHASE', sensors)
  const activeEnergyLN1 = findSensorByType('ACTIVE_ENERGY_LINE_1', sensors)
  const activeEnergyLN2 = findSensorByType('ACTIVE_ENERGY_LINE_2', sensors)
  const activeEnergyLN3 = findSensorByType('ACTIVE_ENERGY_LINE_3', sensors)
  const rmsLineCurrentL1 = findSensorByType('RMS_LINE_CURRENT_L1', sensors)
  const rmsLineCurrentL2 = findSensorByType('RMS_LINE_CURRENT_L2', sensors)
  const rmsLineCurrentL3 = findSensorByType('RMS_LINE_CURRENT_L3', sensors)
  const reactiveEnergyThreePhase = findSensorByType('REACTIVE_ENERGY_THREE_PHASE', sensors)
  const rmsLineCurrentLN = findSensorByType('RMS_LINE_CURRENT_LN', sensors)

  return {
    posActiveThreePhase,
    negActiveThreePhase,
    powerFactor,
    activeThreePhase,
    activeEnergyLN1,
    activeEnergyLN2,
    activeEnergyLN3,
    rmsLineCurrentL1,
    rmsLineCurrentL2,
    rmsLineCurrentL3,
    reactiveEnergyThreePhase,
    rmsLineCurrentLN
  }
}
