import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack
} from '@mantine/core'
import { mq } from 'src/utils/style'

const useStyles = createStyles((theme) => ({
  container: {
    flex: 1
  },
  card: {
    background: 'white',
    height: 210
  },
  miniCard: {
    background: 'white',
    height: 200,
    minWidth: '100%',
    [mq(theme.breakpoints.xs)]: {
      minWidth: 'initial'
    }
  }
}))

export default function Cards () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <Group position="apart" align="stretch" grow>
          <Paper
            className={classes.miniCard}
            radius="sm"
          >
          </Paper>
          <Paper
            className={classes.miniCard}
            radius="sm"
          >
          </Paper>
          <Paper
            className={classes.miniCard}
            radius="sm"
          >
          </Paper>
          <Paper
            className={classes.miniCard}
            radius="sm"
          >
          </Paper>
        </Group>
        <Paper
          className={classes.card}
          radius="sm"
        >
        </Paper>
        <Paper
          className={classes.card}
          radius="sm"
        >
        </Paper>
        <Paper
          className={classes.card}
          radius="sm"
        >
        </Paper>
        <Paper
          className={classes.card}
          radius="sm"
        >
        </Paper>
      </Stack>
    </Box>
  )
}
