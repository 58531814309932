/* eslint-disable max-len */
import {
  Accordion,
  Avatar,
  Box,
  Center,
  createStyles,
  getStylesRef,
  Group,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import React, { useEffect } from 'react'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import { IconNotes, IconRoad } from '@tabler/icons-react'
import { mq } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import {
  getAgentStatisticsLogs,
  type StatisticType,
  type StatisticLog
} from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import Nothing from 'src/Nothing'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { roundToKm } from 'src/utils/math'
import { MODULE_CONTENT } from './constants'

const useStyle = createStyles(theme => ({
  label: {
    fontWeight: 'bold'
  },
  control: {
    paddingLeft: 16
  },
  avatar: {
    backgroundColor: '#fff'
  },
  item: {
    '&[data-active]': {
      [`& .${getStylesRef('icon')}`]: {
        color: theme.colors.dark[5]
      }
    }
  },
  icon: {
    ref: getStylesRef('icon'),
    color: theme.colors.gray[5]
  }
}))

interface Props {
  agentId?: number
  didChangeHeight?: (height: number, key: string) => void
  type?: StatisticType
}

const AuditItem = ({ id, previousValue, newValue, type, notes, timestamp, orgUser: { firstName, lastName } }: StatisticLog) => {
  const { classes } = useStyle()
  const { colors, breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  return (
    <Accordion.Item value={`${type}-${id}`}>
      <Accordion.Control icon={<IconRoad />}>
        <Group>
          {roundToKm(Number(previousValue))}
          {' '}
          km
          {' '}
          -
          {' '}
          {roundToKm(Number(newValue))}
          {' '}
          km
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack spacing="xs">
          <Group align="flex-start">
            <Center w={32}>
              <IconNotes color={colors.gray[5]} />
            </Center>
            <Text
              size="sm"
              color={notes
                ? 'dark'
                : 'dimmed'}
              maw={isDesktop
                ? 560
                : '100%'}
            >
              {notes ?? 'N/A'}
            </Text>
          </Group>
          <Group>
            <Center w={32}>
              <Avatar
                classNames={{
                  placeholder: classes.avatar
                }}
                variant="light"
                color="gray"
                size="sm"
                radius="xl"
              >
                {`${firstName[0]}${lastName[0]}`}
              </Avatar>
            </Center>
            <Text size="sm">{`${firstName} ${lastName}`}</Text>
            <Text size="sm">{dayjs(timestamp).format(uiDateFormat)}</Text>
          </Group>
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

const maxRowHeight = 500

function AuditLog ({ didChangeHeight, agentId, type = 'ODOMETER' }: Props) {
  const { token } = useUser()
  const { classes } = useStyle()
  const { ref, height } = useElementSize()
  const logs = useApi(getAgentStatisticsLogs)

  useEffect(() => {
    if (didChangeHeight) {
      didChangeHeight(height > maxRowHeight
        ? maxRowHeight
        : height + 32,
      'audit')
    }
  }, [height])

  useEffect(() => {
    if (agentId) {
      const req = {
        agentId,
        type,
        page: 1,
        size: 999
      }
      void logs.fetch(req, token)
    }
  }, [agentId])

  return (
    <Stack>
      <Text
        transform="uppercase"
        size="sm"
        weight={500}
      >
        {MODULE_CONTENT.VEHICLE_AUDIT_LOG}
      </Text>
      {logs.data.caseOf({
        Nothing: () => (
          <Nothing
            nothing={logs.data.isNothing()}
            isLoading={logs.loading}
            placeholder="No audit log at the moment"
          />
        ),
        Just: ({ items }) => items.length
          ? (
            <ScrollArea h={maxRowHeight}>
              <Stack ref={ref}>
                <Accordion
                  variant="contained"
                  defaultValue={`${items[0].type}-${items[0].id}`}
                  classNames={{
                    item: classes.item,
                    label: classes.label,
                    control: classes.control,
                    icon: classes.icon
                  }}
                >
                  {items.map(item => (
                    <AuditItem key={`${type}-${item.id}`} {...item} />
                  ))}
                </Accordion>
              </Stack>
            </ScrollArea>
            )
          : (
            <Box ref={ref}>
              <Nothing nothing={logs.data.isNothing()}
                isLoading={logs.loading}
                placeholder="No audit log at the moment"
              />
            </Box>
            )
      })}
    </Stack>
  )
}

export default AuditLog
