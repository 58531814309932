import {
  type ReactElement,
  type ReactNode,
  type UIEvent,
  useEffect,
  useState
} from 'react'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { createStyles, Box, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { type DiagnosticMetricItem } from './Components/DiagnosticMetric'
import { type Status as ConnectivityStatusType } from './Components/ConnectivityStatus'
import Header from './Header'
import Details from './Details'
import Content from './Content'
import { type Fuel } from './Details/PictogramAndStatus/FuelType'
import { type SensorMetricItem } from './Components/SensorMetric'
import Context from './Context'

const useStyles = createStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [mq(theme.breakpoints.sm)]: {
      width: '80vw',
      maxWidth: 960
    },
    [mq('1440px')]: {
      width: '80vw',
      maxWidth: 'none'
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: 12,
    padding: '16px',
    [mq(theme.breakpoints.xs)]: {
      flexDirection: 'row'
    }
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  agentName: AgentDetails['agentName']
  assetType: AgentDetails['assetType']
  metadata: AgentDetails['metadata']
  geoLocation: AgentDetails['geoLocation']
  diagnosticMetrics?: DiagnosticMetricItem[]
  sensorMetrics?: SensorMetricItem[]
  disableMiniExpansion?: boolean
  statuses: ReactElement
  connectivityStatus: ConnectivityStatusType
  connectivityDuration?: string
  lastDataTransfer?: string
  totalAssetRuntime: string
  fuelType?: Fuel
  children?: ReactNode
}

export default function AssetTemplate ({
  agentId,
  agentName,
  assetType,
  metadata,
  geoLocation,
  sensorMetrics,
  diagnosticMetrics,
  statuses,
  connectivityStatus,
  connectivityDuration,
  lastDataTransfer,
  totalAssetRuntime,
  fuelType,
  disableMiniExpansion,
  children
}: Props) {
  const { classes } = useStyles()
  const { breakpoints: { sm } } = useMantineTheme()
  const isSmallScreen = useMediaQuery(mq(sm, false))
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false)

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget
    const isExpanded = (isSmallScreen && scrollTop >= 225)

    setIsHeaderExpanded(isExpanded)
  }

  useEffect(() => {
    if (!isSmallScreen) {
      setIsHeaderExpanded(false)
    }
  }, [isSmallScreen])

  return (
    <Context>
      <Box className={classes.root} onScroll={handleScroll}>
        <Box className={classes.container}>
          <Header
            agentId={agentId}
            agentName={agentName}
            assetType={assetType}
            connectivityStatus={connectivityStatus}
            connectivityDuration={connectivityDuration}
            statuses={statuses}
            diagnosticMetrics={diagnosticMetrics}
            expanded={disableMiniExpansion
              ? false
              : isHeaderExpanded}
          />
          <Box className={classes.body}>
            <Details
              agentId={agentId}
              agentName={agentName}
              assetType={assetType}
              metadata={metadata}
              geoLocation={geoLocation}
              connectivityStatus={connectivityStatus}
              connectivityDuration={connectivityDuration}
              lastDataTransfer={lastDataTransfer}
              totalAssetRuntime={totalAssetRuntime}
              fuelType={fuelType}
            />
            <Content sensorMetrics={sensorMetrics} diagnosticMetrics={diagnosticMetrics}>
              {children}
            </Content>
          </Box>
        </Box>
      </Box>
    </Context>
  )
}
