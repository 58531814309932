import { createStyles } from '@mantine/core'
import { mq } from 'src/utils/style'

interface GridParams {
  width?: React.CSSProperties['width']
  gap?: string
  margin?: React.CSSProperties['margin']
  marginVertical?: React.CSSProperties['margin']
}

const defaultParams = {
  width: '430px',
  gap: '20px',
  margin: '0px'
}

const useStyles = createStyles((theme, { width, margin, marginVertical, gap }: GridParams) => ({
  grid: {
    display: 'grid',
    gridGap: gap,
    gridTemplateColumns: 'repeat(auto-fill, 1fr)',
    margin,
    marginTop: marginVertical ?? margin ?? 'initial',
    marginBottom: marginVertical ?? margin ?? '20px',
    [mq(theme.breakpoints.xs)]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${width ?? defaultParams.width}, 1fr))`
    }
  }
}))

export interface Props extends GridParams {
  style?: React.CSSProperties
  children: React.ReactNode
}

export default function AutoGrid ({ children, style, ...rest }: Props) {
  const { classes } = useStyles({
    ...defaultParams,
    ...rest
  })

  return (
    <div className={classes.grid} style={style}>
      {children}
    </div>
  )
}
