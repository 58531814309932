/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Table as MantineTable,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { useDisclosure } from '@mantine/hooks'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { downloadCSV } from 'src/utils/files'
import { useNotifications } from 'src/utils/notifications'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import MapBuilderModal, { type BuilderProps } from 'src/Maps/MapToPdf/MapBuilderModal'
import { IconPrinter } from '@tabler/icons-react'
import { type ReportFileType } from '..'
import DuressAlerts from './DuressAlerts'
import ExportToPdf from './ExportToPdf'
import { type ParsedDuress } from './ExportToPdf/AgentDuressHistory'

interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setDuressData: (data: ParsedDuressEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface DuressAlertItem {
  sensor_instance_id: string
  trip_id: string
  trip_end_time: string
  org_user_last_name: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string | string[]
  user_groups_names: string
  org_user_id: string
  total_duress_events: string
  trip_start_time: string
  org_user_first_name: string
}

export interface ParsedDuressEvent {
  sensor_instance_id: string | null
  asset: string | null
  date: string | null
  count: string | null
  driver: string | null
  group: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: DuressAlertItem[]
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: DuressAlertItem
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

const Row = ({ trip, range, onDownloadFile }: RowProps) => {
  const ref = useRef<BuilderProps>(null)
  const {
    agent_id,
    trip_id,
    sensor_instance_id,
    agent_name,
    total_duress_events,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name
  } = trip
  const { colors } = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const onClickDownloadFile = useCallback((type: ReportFileType, mapUri?: string) => {
    if (onDownloadFile) {
      onDownloadFile(type, Number(sensor_instance_id), range, mapUri)
    }
  }, [onDownloadFile, sensor_instance_id, range])

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: open
            ? colors.gray[0]
            : 'white',
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text color="primary" weight={open ? 'bold' : 'normal'}>{agent_name}</Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_duress_events}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <td align="right" onClick={(e) => e.stopPropagation()}>
          <Group
            spacing="lg"
            position="center"
            align="center"
          >
            <Link to={`/?agentId=${agent_id}&tripId=${trip_id}&startTime=${encodeURIComponent(trip_start_time ?? '')}`}>
              <Button
                size="xs"
                leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
                variant="outline"
                color="primary"
              >
                View trip
              </Button>
            </Link>
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <IconPrinter size={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack align="flex-start" spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('CSV')}
                    >
                      Download as CSV
                    </Button>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => ref.current?.open()}
                    >
                      Download as PDF
                    </Button>
                    {/* Map modal so it won't show until download pdf is clicked */}
                    <MapBuilderModal
                      ref={ref}
                      mode="duress"
                      tripId={Number(trip_id)}
                      startTime={trip_start_time}
                      endTime={trip_end_time}
                      sensorInstanceId={parseInt(sensor_instance_id ?? '0')}
                      didFinishLoading={(uri) => onClickDownloadFile('PDF', uri)}
                    />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <DuressAlerts
                showTitle={false}
                startTime={trip_start_time}
                endTime={trip_end_time}
                sensorInstanceId={parseInt(sensor_instance_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const Rows = ({ data, range, maxWidth, onDownloadFile }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
        onDownloadFile={onDownloadFile}
      />
    ))
    : null
}

export default function DuressReportView (props: Props) {
  const {
    data,
    queryId,
    setDuressData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const { token } = useUser()
  const { showError } = useNotifications()
  const agentDuressSummary = usePaginatedApi(getIncidentsSummary)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [selectedSensorId, setSelectedSensorId] = useState<string | null>()
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [exportableData, setExportableData] = useState<ParsedDuress[]>([])
  const duressData = data[`${queryId}`] as unknown as DuressAlertItem[] ?? []
  const [selectedDuressAlertItem, setDuressAlertItem] = useState<DuressAlertItem | null>(null)
  const [exportableMapImage, setMapImage] = useState<string | null>(null)

  // Convert agent group ids into array of string
  const updatedDuressData = duressData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedDuressData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedDuressData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedDuressData,
  [updatedDuressData, agentGroupIds])

  // Filtered updatedTripData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedDuress: ParsedDuressEvent[] = filteredDataById.map(({
    sensor_instance_id,
    agent_name,
    trip_start_time,
    org_user_first_name,
    org_user_last_name,
    total_duress_events,
    user_groups_names
  }) => ({
    sensor_instance_id,
    asset: agent_name,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    group: user_groups_names ?? 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_duress_events
  }))

  const handleDownloadFile = useCallback(async (
    type: ReportFileType,
    sensorInstanceId: number,
    dateRange: Range | null,
    mapUri?: string
  ) => {
    setReportType(type)
    setSelectedSensorId(sensorInstanceId.toString())

    if (mapUri) {
      setMapImage(mapUri)
    }

    const duressItem = duressData.find(({ sensor_instance_id }) =>
      sensor_instance_id === sensorInstanceId.toString()
    )

    if (duressItem) {
      const {
        trip_end_time,
        trip_start_time
      } = duressItem

      await agentDuressSummary.fetch({
        sensorInstanceId: sensorInstanceId.toString(),
        startTime: trip_start_time,
        endTime: trip_end_time,
        page: 1,
        size: 999
      }, token)
    }
  }, [])

  useEffect(() => {
    setDuressAlertItem(
      filteredDataById.find(({ sensor_instance_id }) => sensor_instance_id === selectedSensorId) ??
      null
    )
  }, [selectedSensorId])

  useEffect(() => {
    setDuressData(parsedDuress)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  useEffect(() => {
    const agentName = selectedDuressAlertItem?.agent_name ?? '-'

    // generate and download agent's trips report
    agentDuressSummary.data.ifJust(async ({ items }) => {
      try {
        const events = await Promise.all(items.map(async (event, idx) => {
          const {
            detected,
            cleared,
            geoZoneName,
            agentLocationPoint
          } = event
          try {
            const duress_location = agentLocationPoint
              ? await reverseGeocode([agentLocationPoint?.longitude ?? 0, agentLocationPoint?.latitude ?? 0])
              : 'N/A'

            return reportType === 'CSV'
              ? {
                  'Event #': idx + 1,
                  'Event Time': dayjs(detected).format(uiDateFormatUniversal),
                  Location: duress_location,
                  Geozone: geoZoneName,
                  'Notification Cleared': dayjs(cleared).format(uiDateFormatUniversal)
                }
              : {
                  ...event,
                  location: duress_location
                }
          } catch (err: any) {
            throw Error('Failed to generate duress alert reports')
          }
        }))

        if (reportType === 'CSV') {
          downloadCSV(events as unknown as Array<Record<string, unknown>>, `${agentName} - Duress Event Report`)
        }

        if (reportType === 'PDF') {
          const data = events as unknown as ParsedDuress[]
          setExportableData(data)
          toggle()
        }
      } catch (error) {
        showError(error as Error)
      }
    })
  }, [agentDuressSummary.data])

  return (
    <>
      <Paper
        p="sm"
        shadow="xs"
        sx={{
          width: '100%',
          scrollbarWidth: 'thin'
        }}
      >
        <MantineTable
          sx={{
            position: 'relative'
          }}
        >
          <thead
            style={{
              background: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 100
            }}
          >
            <tr style={{ borderBottom: 1 }}>
              <th>Asset Name</th>
              <th style={{ width: 180 }}>Date</th>
              <th style={{ width: 100 }}>Count</th>
              <th>Driver</th>
              <th>Group</th>
              <th style={{ width: 180 }} />
            </tr>
          </thead>
          <tbody>
            <Rows
              {...props}
              data={filteredDataById}
              onDownloadFile={handleDownloadFile}
            />
          </tbody>
        </MantineTable>
      </Paper>
      {selectedDuressAlertItem && (
        <ExportToPdf
          agentName={selectedDuressAlertItem.agent_name ?? 'N/A'}
          data={exportableData}
          mapUri={exportableMapImage}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
          {...selectedDuressAlertItem}
        />
      )}
    </>
  )
}
