import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type ModbusDevice } from '@venturi-io/api/src/config/modbus'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'

export default function Row ({
  id,
  name,
  delay,
  swapWords,
  swapBytes,
  byteOrder
}: ModbusDevice & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(name)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(delay)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{swapWords ? 'Yes' : 'No'}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{swapBytes ? 'Yes' : 'No'}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(byteOrder)}</Text>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/modbus-devices/edit/${id}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
        <ActionButton
          label="Edit Registers"
          icon="memo-pad"
          iconType="fas"
          onClick={() => navigate(`/my-admin/modbus-devices/edit-registers/${id}`)}
          actionColor="primary"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
