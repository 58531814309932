import MapboxDraw, { constants, type DrawCustomMode } from '@mapbox/mapbox-gl-draw'

import { point } from '@turf/helpers'
import distance from '@turf/distance'
import circle from '@turf/circle'

const DrawCircle: DrawCustomMode = { ...MapboxDraw.modes.draw_polygon }

DrawCircle.onSetup = function () {
  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: []
    },
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  })

  this.addFeature(polygon)

  this.clearSelectedFeatures()
  this.updateUIClasses({ mouse: constants.cursors.ADD })
  this.activateUIButton(constants.types.POLYGON)
  this.setActionableState({
    trash: true,
    combineFeatures: false,
    uncombineFeatures: false
  })

  return {
    polygon,
    currentVertexPosition: 0
  }
}

DrawCircle.onClick = function (state, e) {
  const currentCenter = state.polygon.properties.center
  if (
    currentCenter.length > 1 &&
        currentCenter[0] !== e.lngLat.lng &&
        currentCenter[1] !== e.lngLat.lat
  ) {
    this.updateUIClasses({ mouse: 'pointer' })
    this.changeMode('direct_select', { featureId: state.polygon.id })
  } else {
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]
  }
}

DrawCircle.onMouseMove = function (state, e) {
  const center = state.polygon.properties.center
  if (center.length > 0) {
    const distanceInKm = distance(
      point(center),
      point([e.lngLat.lng, e.lngLat.lat]),
      { units: 'kilometers' })
    const circleFeature = circle(center, distanceInKm)
    state.polygon.incomingCoords(circleFeature.geometry.coordinates)
    state.polygon.properties.radiusInKm = distanceInKm
  }
}

DrawCircle.toDisplayFeatures = function (state, geojson, display) {
  if (geojson.type === 'Feature' && geojson.properties) {
    const isActivePolygon = geojson.properties.id === state.polygon.id
    geojson.properties.active = isActivePolygon
      ? constants.activeStates.ACTIVE
      : constants.activeStates.INACTIVE
    return display(geojson)
  }

  // Only render the rectangular polygon if it has the starting point
  if (!state.polygon.center) {
    return
  }
  return display(geojson)
}

export default DrawCircle
