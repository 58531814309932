import { useCallback, useEffect, useState } from 'react'
import { Group, Text, Paper } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { createStyles } from '@mantine/styles'
import { getInmarsatMessageLogs, type InmarsatMessage } from '@venturi-io/api/src/config/inmarsat'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { useUser } from 'src/UserContext'
import ActionButton from 'src/ActionButton'
import VirtualTable from './VirtualTable'

const useStyles = createStyles(theme => ({
  container: {
    position: 'relative',
    height: '90%'
  },
  loaderContainer: {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    padding: '5px 15px',
    background: theme.colors.primary[5],
    borderRadius: 5,
    color: 'white'
  }
}))

export default function Logs () {
  const { token } = useUser()
  const inmarsatMessageLogs = useApi(getInmarsatMessageLogs)
  const [cachedLogs, setCachedLogs] = useState<InmarsatMessage[]>([])
  const [enablePolling, setEnablePolling] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const { ref: containerRef, width, height } = useElementSize()
  const { classes } = useStyles()
  const tableHeight = height - 30
  const pageSize = 200

  const loadInmarsatMessageLogs = useCallback((page: number, size: number) => {
    setEnablePolling(false)

    void inmarsatMessageLogs.fetch({ page, size }, token)
  }, [])

  const load = useCallback(() => {
    setCachedLogs([])
    loadInmarsatMessageLogs(1, pageSize)
  }, [loadInmarsatMessageLogs])

  const loadNextPage = useCallback(() => {
    loadInmarsatMessageLogs(currentPage, pageSize)
  }, [loadInmarsatMessageLogs, currentPage])

  const loadMoreItems = inmarsatMessageLogs.loading
    ? () => null
    : loadNextPage

  useEffect(() => {
    inmarsatMessageLogs.data.ifJust(({ items, hasMore }) => {
      const allLogs = [
        ...cachedLogs,
        ...items
      ]

      if (enablePolling) {
        // filter unique logs and sort them by log id DESC
        const mapLogs = new Map()

        allLogs.forEach(log => mapLogs.set(log.id, log))

        const updatedLogs =
          [...mapLogs.values()]
            .sort((a, b) => (a.id < b.id) ? 1 : -1)
            .slice(0, cachedLogs.length)

        setCachedLogs(updatedLogs)
      } else {
        setCachedLogs(allLogs)
      }

      setHasNextPage(hasMore)
      setEnablePolling(true)

      if (hasMore) {
        setCurrentPage(currentPage + 1)
      }
    })
  }, [inmarsatMessageLogs.data])

  useEffect(() => {
    if (inmarsatMessageLogs.loading) inmarsatMessageLogs.abort()
    load()
  }, [])

  useEffect(() => {
    if (enablePolling) {
      inmarsatMessageLogs.startPolling({
        page: currentPage,
        size: pageSize
      }, token, 60)
    }

    return () => {
      inmarsatMessageLogs.stopPolling()
      inmarsatMessageLogs.abort()
    }
  }, [enablePolling, currentPage])

  return (
    <Paper
      ref={containerRef}
      p="md"
      shadow="xs"
      className={classes.container}
    >
      <Group position="right">
        <ActionButton
          label="Reload"
          icon="refresh"
          iconType="fas"
          onClick={load}
          iconColor="primary"
          iconSize="1x"
        />
      </Group>
      <VirtualTable
        width={width}
        height={tableHeight}
        inmarsatMessageLogs={cachedLogs}
        loadMoreItems={loadMoreItems}
        hasNextPage={hasNextPage}
      />
      {inmarsatMessageLogs.loading && (
        <Group
          className={classes.loaderContainer}
          spacing={3}
          mt={3}
          mr="md"
        >
          <Loader
            color="white"
            style={{
              width: '20px',
              height: '20px'
            }}
          />
          <Text>Loading...</Text>
        </Group>
      )}
    </Paper>
  )
}
