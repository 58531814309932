import {
  createStyles,
  Center,
  Container,
  Stack,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    color: theme.colors.primary[5]
  },
  message: {
    width: '60%'
  }
}))

export default function Unauthorized () {
  const { classes } = useStyles()

  return (
    <Container className={classes.container} fluid>
      <Center>
        <FontAwesomeIcon
          className={classes.icon}
          icon={['fas', 'cancel']}
          color="silver"
          size="8x"
        />
      </Center>
      <Stack mt="xs" spacing={0} align="center">
        <Text
          size={55}
          weight="bolder"
          color="primary"
          align="center"
        >
          401
        </Text>
        <Text
          mt={-15}
          size={35}
          weight="bolder"
          color="primary"
          align="center"
        >
          UNAUTHORIZED
        </Text>
      </Stack>
      <Center>
        <Text
          className={classes.message}
          mt="sm"
          size="sm"
          align="center"
          color="gray"
        >
          Oops! Sorry, you are not allowed to access this page.
          You may contact the Administrator to request an access if necessary.
        </Text>
      </Center>
    </Container>
  )
}
