import { Center, Stack, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SelectDashboardMessage = () => (
  <Stack mt={125} spacing="sm">
    <FontAwesomeIcon icon={['far', 'chart-radar']} color="silver" size="9x" />
    <Center>
      <Text size="md" sx={{ color: 'gray' }}>Please select a dashboard to display</Text>
    </Center>
  </Stack>
)

export default SelectDashboardMessage
