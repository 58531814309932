import { useCallback, useEffect } from 'react'
import {
  useMantineTheme,
  createStyles,
  Anchor,
  Button,
  Center,
  Col,
  Container,
  Grid,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { loginUser, type User } from '@venturi-io/api/src/userManager/auth'
import { useApi } from 'src/utils/useApi'
import { mq } from 'src/utils/style'
import { userMachineTz } from 'src/utils/dates'
import MainLogo from 'src/MainLogo'
import { getHost } from 'src/utils/host'
import { useForm } from '@mantine/form'
import PoweredBy from './PoweredBy'

const useStyles = createStyles(theme => ({
  wrapper: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.primary[6],
    background: `linear-gradient(to bottom right, ${theme.colors.brand[0]}, ${theme.colors.brand[1]})`
  },
  container: {
    height: '100%',
    color: theme.white,
    [mq(theme.breakpoints.md)]: {
      maxWidth: '100vw !important',
      paddingLeft: 100,
      paddingRight: 100
    },
    [mq(theme.breakpoints.xl)]: {
      maxWidth: '1200px !important',
      paddingLeft: 'initial',
      paddingRight: 'initial'
    }
  },
  noMargin: {
    margin: '0 !important'
  },
  formWrapper: {
    backgroundColor: theme.colors.primary[0],
    borderRadius: '3rem',
    padding: '48px !important' // TODO: Fix this
  },
  formText: {
    color: theme.colors.primary[7]
  },
  error: {
    marginTop: theme.spacing.sm,
    textAlign: 'center',
    [mq(theme.breakpoints.sm)]: {
      textAlign: 'left'
    }
  },
  errorTitle: {
    fontWeight: 'bold'
  },
  text: {
    color: theme.white
  },
  test: {
    '&:focus': {
      border: 'none'
    }
  },
  block: {
    display: 'block',
    width: 30,
    height: 8,
    background: 'white',
    marginTop: 20,
    marginBottom: 30
  }
}))

interface FormOutput {
  email: string
  password: string
}

interface Props {
  orgId: number
  signin: (user: User) => void
}

export default function Login ({ orgId, signin }: Props) {
  const orgUrl = getHost()
  const login = useApi(loginUser, false)

  const hasValue = (inputName: string, value: string) => value.length < 1 ? `Please enter valid ${inputName}` : null

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: value => hasValue('Username or Email', value),
      password: value => hasValue('Password', value)
    }
  })

  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { classes, cx } = useStyles()

  const doLogin = useCallback((input: FormOutput) => {
    void login.fetch({
      ...input,
      timezone: userMachineTz,
      orgUrl,
      orgId
    }, '')
  }, [orgId, signin])

  const loginError = login.error.mapOrDefault(error => error, null)

  useEffect(() => {
    login.data.ifJust(user => signin(user))
  }, [login.data])

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <Grid align="center" justify="center" className={cx(classes.container, classes.noMargin)}>
          {isDesktop && (
            <Col span={12} sm={6}>
              <MainLogo orgId={orgId} alignLeft />
              <Title order={1} className={classes.text}>
                WELCOME
              </Title>
              <Text size="md" className={classes.text}>
                Asset Management
                <br />
                Platform
              </Text>
              <div className={classes.block} />
              <PoweredBy orgId={orgId} />
            </Col>
          )}
          {!isDesktop && (
            <Col span={12}>
              <Center><MainLogo orgId={orgId} /></Center>
            </Col>
          )}
          <Col span={12} sm={6} className={classes.formWrapper}>
            <form onSubmit={form.onSubmit(doLogin)}>
              <Grid>
                <Col span={12}>
                  <Title order={2} className={classes.formText}>
                    Sign in
                  </Title>
                </Col>
                <Col span={12}>
                  <TextInput
                    withAsterisk
                    label="Username"
                    type="text"
                    classNames={{
                      label: classes.formText,
                      input: classes.test
                    }}
                    {...form.getInputProps('email')}
                  />
                </Col>
                <Col span={12}>
                  <TextInput
                    withAsterisk
                    label="Password"
                    type="password"
                    classNames={{
                      label: classes.formText,
                      input: classes.test
                    }}
                    {...form.getInputProps('password')}
                  />
                </Col>
                <Col span={12}>
                  <Center>
                    <Anchor
                      color="primary"
                      size="sm"
                      href="/accountVerification"
                      target="_self"
                    >
                      Forgot your password?
                    </Anchor>
                  </Center>
                </Col>
                <Col span={12}>
                  <Center>
                    <Button type="submit" variant="white" radius={0} color="primary">
                      SIGN IN
                    </Button>
                  </Center>
                  {login.loading && (
                    <Text size="md" className={classes.formText} align="center">
                      Loading...
                    </Text>
                  )}
                </Col>
              </Grid>
            </form>
            {loginError && (
              <div className={classes.error}>
                <Text size="md" color="red" align="center">
                  <span className={classes.errorTitle}>Error:</span>
                  {' '}
                  {loginError.message}
                </Text>
              </div>
            )}
          </Col>
          {!isDesktop && (
            <Col span={12}>
              <Center><PoweredBy orgId={orgId} /></Center>
            </Col>
          )}
        </Grid>
      </Container>
    </div>
  )
}
