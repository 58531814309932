import { Group } from '@mantine/core'
import Alarms from './Alarms'
import EngineState, { type Status as EngineStatus } from './EngineState'
import ControlMode, { type Mode } from './ControlMode'

interface Props {
  alarms: number
  engineState: EngineStatus
  mode: Mode
}

export default function Statuses ({ alarms, engineState, mode }: Props) {
  return (
    <Group position="center" align="center" spacing={16}>
      <Alarms count={alarms} />
      <EngineState status={engineState} />
      <ControlMode mode={mode} />
    </Group>
  )
}
