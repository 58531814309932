import MenuItem from './MenuItem'
import { type MenuListProps } from './MenuList'

interface MobileMenuProps extends MenuListProps {
  signout: () => void
}

export default function MobileExtraMenuItems ({ show, setShow, signout, isDesktop }: MobileMenuProps) {
  if (isDesktop) return null

  return (
    <>
      <MenuItem
        item={{
          title: 'User Profile',
          path: '/user-profile',
          icon: ['fas', 'user-circle'],
          children: []
        }}
        isSidebarOpen={show}
        closeMenu={() => setShow(false)}
      />
      <MenuItem
        item={{
          title: 'Sign Out',
          path: '/',
          icon: ['fas', 'sign-out'],
          children: []
        }}
        isSidebarOpen={show}
        closeMenu={() => setShow(false)}
        onClick={() => signout()}
      />
    </>
  )
}
