import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  createStyles,
  Affix,
  Box,
  Button,
  Center,
  Group,
  MediaQuery
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type AgentDetails,
  getAgentDetails,
  updateAgentSensorInstances
} from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useMeta } from 'src/MetaContext'
import NeedsRole from 'src/NeedsRole'
import Loader from 'src/Layout/Loader'
import NotFound from 'src/Router/NotFound'
import * as _ from 'lodash'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import Sensor from './Sensor'
import Create from './Create'

const useStyles = createStyles((theme) => ({
  listContainer: {
    height: '85vh',
    width: '100%',
    overflow: 'auto'

  },
  createBtn: {
    boxShadow: theme.shadows.md,
    backgroundColor: '#FFFFFFCC',
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  agentId: string
}

export interface SensorInstance {
  sensorInstanceId: number
  primaryFunction: boolean
  systemOnly: boolean
  name: string
  iconStyle: string | undefined
  sensorDataTransformerId?: number
}

export default function Sensors () {
  const { classes } = useStyles()
  const { search } = useLocation()
  const agentDetails = useApi(getAgentDetails)
  const [showNew, setShowNew] = useState(false)
  const { token } = useUser()
  const { meta, setMeta } = useMeta()
  const { agentId: id } = useParams<RouteParams>()
  const [isTemplate, setIsTemplate] = useState(false)
  const [allSensors, setAllSensors] = useState<AgentDetails['sensors']>([])
  const [loadedSensors, setLoadedSensors] = useState<AgentDetails['sensors']>([])
  const [agentSensor, setAgentSensor] = useState<SensorInstance>()
  const updateAgentSensors = useApi(updateAgentSensorInstances)
  const [sensorInstances, setSensorInstances] = useState<SensorInstance[]>([])
  const pageSize = 10

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const agentId = parseInt(id)

  const agent = useMemo(() => (
    agentDetails
      .data
      .mapOrDefault(agent_ => {
        setMeta({
          ...meta,
          pageTitle: agent_.agentName
        })

        return agent_
      }, null)
  ), [agentDetails.data])

  const loadAgent = () => {
    void agentDetails.fetch({ agentId }, token)
  }

  const loadMoreSensors = () => {
    const { length: currentSize } = loadedSensors
    setLoadedSensors(loadedSensors.concat(allSensors.slice(currentSize, currentSize + pageSize)))
  }

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const template = params.get('isTemplate')
      if (template) {
        setIsTemplate(template === 'true')
      }
    }
  }, [search])

  useEffect(() => {
    agentDetails.data.ifJust(({ sensors }) => {
      setAllSensors(sensors)
      setLoadedSensors(sensors.slice(0, pageSize))
    })
  }, [agentDetails.data])

  useEffect(() => {
    void loadAgent()
  }, [])

  const hide = { display: 'none' }

  const newButton = (
    <Button
      title="Create Sensor"
      size="sm"
      color="primary"
      leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
      onClick={() => setShowNew(true)}
    >
      Create Sensor
    </Button>
  )

  useEffect(() => {
    if (agentSensor) {
      const sensors = sensorInstances ?? []
      const allSensors = [
        ...sensors,
        agentSensor
      ]
      const removeDuplicates = _.uniqBy(_.reverse(allSensors), 'sensorInstanceId')

      setSensorInstances(removeDuplicates)
    }
  }, [agentSensor])

  const updateSensor = useCallback(() => {
    if (sensorInstances) {
      void updateAgentSensors.fetch({
        agentId: Number(agent?.agentId),
        sensorInstances
      }, token, 'Successfully updated sensors').finally(() => {
        setSensorInstances([])
        setAgentSensor(undefined)
        void loadAgent()
      })
    }
  }, [sensorInstances])

  useEffect(() => {
    setSensorInstances([])

    return () => {
      setSensorInstances([])
    }
  }, [])

  return (
    <>
      <LoadingOverlay visible={updateAgentSensors.loading} />
      {!showNew && (
        <NeedsRole role="ROLE_SUPERADMIN">
          <MediaQuery smallerThan="sm" styles={hide}>
            <Group mt={15} align="center" position="center">
              {newButton}
            </Group>
          </MediaQuery>
          <MediaQuery largerThan="sm" styles={hide}>
            <Affix position={{ right: 5, top: 105 }}>
              <Box className={classes.createBtn}>
                {newButton}
              </Box>
            </Affix>
          </MediaQuery>
        </NeedsRole>
      )}
      <Box id="scrollable-agent-sensors" className={classes.listContainer}>
        {agent && (
          <InfiniteScroll
            dataLength={loadedSensors.length}
            next={loadMoreSensors}
            hasMore={allSensors.length > loadedSensors.length}
            loader={<Center><Loader /></Center>}
            scrollableTarget="scrollable-agent-sensors"
          >
            {loadedSensors.map((sensor, index) => (
              <Sensor
                key={index}
                agentId={agent.agentId}
                sensor={sensor}
                agentLoading={agentDetails.loading}
                loadAgent={loadAgent}
                isTemplate={isTemplate}
                setAgentSensor={setAgentSensor}
              />
            ))}
          </InfiniteScroll>
        )}
      </Box>
      <NeedsRole role="ROLE_SUPERADMIN">
        <Create
          agentId={agentId}
          show={showNew}
          close={() => setShowNew(false)}
          onCreate={loadAgent}
        />
      </NeedsRole>
      <Group
        mt={20}
        sx={{
          justifyContent: 'flex-end'
        }}
      >
        <NeedsRole role="ROLE_SUPERADMIN">
          <Button
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['fas', 'arrows-rotate']} color="primary" />}
            onClick={() => {
              updateSensor()
            }}
            disabled={isTemplate}
            variant="outline"
          >
            Update Sensors
          </Button>
        </NeedsRole>
      </Group>
    </>
  )
}
