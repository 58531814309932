import { createStatelessApi } from '../configure'
import { type OrgIdRequest } from '../shared'
import { z } from 'zod'

// Agents
const agent = z.object({
  agentId: z.number(),
  agentName: z.string(),
  assetType: z.optional(z.string()),
  deviceId: z.optional(z.string())
})

export type Agent = z.infer<typeof agent>

const allAgents = z.array(agent)

interface AllAgentsNoPaginationRequest extends OrgIdRequest {
  assetType?: string
  search?: string
}

export const getAgents = createStatelessApi<AllAgentsNoPaginationRequest, typeof allAgents>(
  'config',
  '/org/:orgId/agents/ui',
  { method: 'GET', type: 'path' },
  allAgents
)

// Sensors
const sensor = z.object({
  id: z.number(),
  name: z.string()
})

export type Sensor = z.infer<typeof sensor>

const allSensors = z.array(sensor)

export const getSensors = createStatelessApi<Record<string, never>, typeof allSensors>(
  'config',
  '/sensors/ui',
  { method: 'GET', type: 'path' },
  allSensors
)

// Sensor Instances
const sensorInstance = z.object({
  sensorInstanceId: z.number(),
  name: z.string(),
  sensorId: z.number(),
  sensorName: z.string(),
  agentId: z.number(),
  agentName: z.string()
})

export type SensorInstance = z.infer<typeof sensorInstance>

const allSensorInstances = z.array(sensorInstance)

interface AllSensorInstancesRequest extends Record<string, unknown> {
  withAlarmConfig?: boolean
}

export const getSensorInstances = createStatelessApi<AllSensorInstancesRequest, typeof allSensorInstances>(
  'config',
  '/sensor-instances/ui',
  { method: 'GET', type: 'path' },
  allSensorInstances
)

// Agent Groups
const agentGroup = z.object({
  agentGroupId: z.number(),
  name: z.string()
})

export type AgentGroup = z.infer<typeof agentGroup>

const allAgentGroups = z.array(agentGroup)

export const getAgentGroups = createStatelessApi<Record<string, never>, typeof allAgentGroups>(
  'config',
  '/agent-groups/ui',
  { method: 'GET', type: 'path' },
  allAgentGroups
)
