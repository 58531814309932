import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import PlaceholderContainer from '../PlaceholderContainer'

interface Props {
  iconName: IconName
}

const ItemPlaceholder = ({ iconName }: Props) => (
  <PlaceholderContainer>
    <FontAwesomeIcon
      icon={['fas', iconName]}
      size="3x"
      color="#ddd"
    />
  </PlaceholderContainer>
)

export default ItemPlaceholder
