import { Group, Progress, Text } from '@mantine/core'

interface BarProps {
  primaryLabel: string
  secondaryLabel: string
  value: number
  color?: string
}

export const Bar = ({
  primaryLabel,
  secondaryLabel,
  value,
  color = '#E5AF59'
}: BarProps) => (
  <div>
    <Group position="apart" noWrap>
      <Text size="xs" lineClamp={1}>{primaryLabel}</Text>
      <Text size="xs" lineClamp={1}>{secondaryLabel}</Text>
    </Group>
    <Progress value={value} radius="lg" color={color} />
  </div>
)
