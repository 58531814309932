import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type PaginatedRequest, type AlarmWebhookIdRequest, paginated } from '../shared'

const alarmWebhook = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
  alarmKey: z.string(),
  clearKey: z.string()
})

const alarmWebhooks = z.array(alarmWebhook)

export type AlarmWebhook = z.infer<typeof alarmWebhook>

interface CreateAlarmWebhookRequest extends Record<string, unknown> {
  name: string
  url: string
  alarmKey: string
  clearKey: string
}
export const createAlarmWebhook = createStatefulApi<CreateAlarmWebhookRequest, typeof alarmWebhook>(
  'analytics',
  '/alarmWebhook',
  { method: 'POST' },
  alarmWebhook
)

interface AllAlarmWebhooksRequest extends PaginatedRequest {
  search?: string
}
const allAlarmWebhooks = paginated.extend({
  items: alarmWebhooks
})
export const getAlarmWebhooks = createStatelessApi<AllAlarmWebhooksRequest, typeof allAlarmWebhooks>(
  'analytics',
  '/alarmWebhooks',
  { method: 'GET', type: 'path' },
  allAlarmWebhooks
)

export const getAlarmWebhook = createStatelessApi<AlarmWebhookIdRequest, typeof alarmWebhook>(
  'analytics',
  '/alarmWebhook/:webhookId',
  { method: 'GET', type: 'path' },
  alarmWebhook
)

interface UpdateAlarmWebhookRequest extends AlarmWebhookIdRequest {
  name?: string
  url?: string
  alarmKey?: string
  clearKey?: string
}
export const updateAlarmWebhook = createStatefulApi<UpdateAlarmWebhookRequest, typeof alarmWebhook>(
  'analytics',
  '/alarmWebhook/:webhookId',
  { method: 'PUT' },
  alarmWebhook
)

export const deleteAlarmWebhook = createStatefulApi<AlarmWebhookIdRequest, z.ZodBoolean>(
  'analytics',
  '/alarmWebhook/:webhookId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
