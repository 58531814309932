import {
  ActionIcon,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Tooltip
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import Link from 'src/Layout/Link'
import ImmobiliseButton from 'src/Buttons/ImmobiliseButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime,
  hasImmobiliser
}: Agent & ListRow) {
  const batteryVoltage = findSensorByType('BATTERY_VOLTAGE', sensors)
  const extPowerSourceVoltage = findSensorByType('EXT_POWER_SOURCE_VOLTAGE', sensors)
  const positionDirection = findSensorByType('POSITION_DIRECTION', sensors)
  const positionSpeed = findSensorByType('POSITION_SPEED', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            to={`/assets/vehicle/${agentId}`}
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
          <Group
            spacing={4}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Actions" value="" />
            <Group spacing={7}>
              <Link to={`/assets/vehicle/${agentId}/transactions`}>
                <Tooltip
                  label="View Transactions"
                  position="top"
                  transitionProps={{
                    transition: 'pop',
                    duration: 200
                  }}
                  withArrow
                >
                  <ActionIcon color="primary" variant="transparent">
                    <FontAwesomeIcon icon={['far', 'receipt']} size="lg" />
                  </ActionIcon>
                </Tooltip>
              </Link>
              {hasImmobiliser && (
                <ImmobiliseButton
                  agentId={agentId}
                  onSuccess={() => {
                    window.location.reload()
                  }}
                />
              )}
            </Group>
          </Group>
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <TextGroup
            label="Battery Voltage"
            value={batteryVoltage?.currentValue
              ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="External Power Source Voltage"
            value={extPowerSourceVoltage?.currentValue
              ? `${extPowerSourceVoltage.currentValue} ${extPowerSourceVoltage.unit}`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="Position Direction"
            value={parseEmpty(positionDirection?.currentValue)}
            maxLength={25}
          />
          <TextGroup
            label="Position Speed"
            value={parseEmpty(positionSpeed?.currentValue)}
            maxLength={25}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
