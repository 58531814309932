import { memo, useEffect, useMemo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Agent, type Sensor, getAgents } from '@venturi-io/api/src/config/agent'
import dayjs, { type ManipulateType } from 'dayjs'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import StackedAreasChart, { type Props as StackedAreasProps } from 'src/Charts/StackedAreas'
import { type DataPoint } from 'src/Charts/shared'
import NoData from '../shared/NoData'
import { useSharedStyles } from '../shared'

type SensorWithExtra = Sensor & {
  key: string
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  agentAlarmStatus: Agent['alarmStatus']
}

interface SensorData extends SensorWithExtra {
  data: DataPoint[]
}

interface StackedSensorAllAgentsType extends Record<string, string> {}

export interface Props {
  id: string
  name: string
  data: {
    sensorId: Sensor['id']
    bucket: ChosenBucket<ItemKeys>
  }
  styles: StackedAreasProps['styles']
}

const StackedSensorAllAgents = ({
  id,
  name,
  data: {
    sensorId,
    bucket
  },
  styles
}: Props) => {
  const { token, orgId } = useUser()
  const allAgents = useApi(getAgents)
  const allSensorsData = useApi(getSensorData)

  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const agents = useMemo(() => (
    allAgents.data.mapOrDefault(({ items }) => items, [])
  ), [allAgents.data])

  const sensorsWithExtra: SensorWithExtra[] = useMemo(() => (
    agents.reduce((sensorsWithExtra: SensorWithExtra[], { agentId, agentName, alarmStatus, sensors }) => {
      const sensorInstancesWithExtra = sensors
        .filter(({ id }) => id === sensorId)
        .map(sensor => ({
          key: `${sensor.name} (${agentName})`,
          agentId,
          agentName,
          agentAlarmStatus: alarmStatus,
          ...sensor
        }))

      return [
        ...sensorsWithExtra,
        ...sensorInstancesWithExtra
      ]
    }, [])
  ), [agents, sensorId])

  const sensorInstanceIds = useMemo(() => (
    sensorsWithExtra.map(({ sensorInstanceId }) => sensorInstanceId)
  ), [sensorsWithExtra])

  const sensorsData: SensorData[] = useMemo(() => (
    allSensorsData.data.mapOrDefault(({ result }) => (
      result
        .reduce((sensorsData_: SensorData[], { sensorInstanceId, data }) => {
          const sensor: SensorWithExtra | undefined =
            sensorsWithExtra.find(s => s.sensorInstanceId === sensorInstanceId)

          if (sensor) {
            sensorsData_ = [
              ...sensorsData_,
              {
                ...sensor,
                data: data.map(({ x, y }) => (
                  {
                    x,
                    y: typeof y !== 'undefined' ? y : 0
                  }
                ))
              }
            ]
          }

          return sensorsData_
        }, [])
        .filter(sensor => sensor.data.length > 0)
    ), [])
  ), [allSensorsData.data, sensorsWithExtra])

  const sensorsHistory: StackedSensorAllAgentsType[] = useMemo(() => {
    const series = sensorsData.reduce((series: Record<string, StackedSensorAllAgentsType>, sensor) => {
      sensor.data.forEach(({ x, y }) => {
        const key = x.toString()
        const val = typeof y !== 'undefined' ? y.toString() : '0'

        if (series[key]) {
          series[key] = {
            ...series[key],
            [sensor.key]: val
          }
        } else {
          series[key] = {
            [sensor.key]: val
          }
        }
      })

      return series
    }, {})

    return Object.keys(series).map(date => ({ date, ...series[date] }))
  }, [sensorsData])

  const isLoading = useMemo(() => (
    allAgents.loading || allSensorsData.loading
  ), [allAgents.loading, allSensorsData.loading])

  useEffect(() => {
    if (sensorInstanceIds.length < 1) return

    const time = bucket.item.split(' ')
    const req = {
      sensorInstanceIds,
      startTime: dayjs().subtract(Number.parseInt(time[0]), time[1] as ManipulateType).format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: bucket.data.noOfRecords
    }

    void allSensorsData.fetch(req, token)
  }, [bucket, sensorInstanceIds])

  useEffect(() => {
    const request = {
      orgId,
      page: 1,
      size: 99999
    }

    void allAgents.fetch(request, token)
  }, [])

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      {isLoading && <Loader variant="bars" />}
      {!isLoading && (
        sensorsHistory.length > 0
          ? (
            <StackedAreasChart
              id={id}
              title={name}
              data={sensorsHistory}
              styles={{
                ...styles,
                width,
                height
              }}
            />
            )
          : <NoData />
      )}
    </Container>
  )
}

export default memo(StackedSensorAllAgents)
