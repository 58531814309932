import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type AssetIdRequest } from '../shared'

// Assets
const asset = z.object({
  id: z.number(),
  name: z.string(),
  assetTypeId: z.number()
})

const assets = z.array(asset)

export type Asset = z.infer<typeof asset>

interface CreateAssetRequest extends Record<string, unknown> {
  name: string
  assetTypeId: number
}
export const createAsset = createStatefulApi<CreateAssetRequest, typeof asset>(
  'config',
  '/asset',
  { method: 'POST' },
  asset
)

export const getAssets = createStatelessApi<Record<string, never>, typeof assets>(
  'config',
  '/assets',
  { method: 'GET', type: 'path' },
  assets
)

export const getAsset = createStatelessApi<AssetIdRequest, typeof asset>(
  'config',
  '/asset/:assetId',
  { method: 'GET', type: 'path' },
  asset
)

interface UpdateAssetRequest extends Record<string, unknown> {
  assetId: number
  name?: string
  assetTypeId?: number
}
export const updateAsset = createStatefulApi<UpdateAssetRequest, typeof asset>(
  'config',
  '/asset/:assetId',
  { method: 'PATCH' },
  asset
)

export const deleteAsset = createStatefulApi<AssetIdRequest, typeof asset>(
  'config',
  '/asset/:assetId',
  { method: 'DELETE', type: 'path' },
  asset
)

// Asset Types
const assetType = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string()
})

const assetTypes = z.array(assetType)

export const getAssetTypes = createStatelessApi<Record<string, never>, typeof assetTypes>(
  'config',
  '/types/asset',
  { method: 'GET', type: 'path' },
  assetTypes
)
