import { type CSSProperties, useCallback } from 'react'
import { Paper, useMantineTheme } from '@mantine/core'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { checkIfAlarm } from 'src/utils/status'
import AgentDetails from './AgentDetails'
import Sensors from './Sensors'
import type { Agent } from '@venturi-io/api/src/config/agent'

export interface Props {
  agent: Agent
  isSmall?: boolean
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}

export default function AgentCell ({
  agent: {
    agentId,
    agentName,
    assetType,
    sensors,
    lastSeenTime
  },
  isSmall = false,
  width,
  height
}: Props) {
  const theme = useMantineTheme()
  const navigate = useNavigate()

  const location = assetType
    ? `/assets/${assetType.toLowerCase()}/${agentId}`
    : `/agent/${agentId}`

  const agentDetailsredirect = useCallback(() => {
    navigate(location)
  }, [location])

  if (!sensors.length) {
    return null
  }

  const primarySensors = sensors.filter(sensor => sensor.primaryFunction)
  const battery = sensors.find(sensor => sensor.type === 'BATTERY')
  const isAlarm = sensors.some(sensor => checkIfAlarm(sensor.alarmStatus))

  const alarmStyles = isAlarm
    ? {
        border: '3px solid #E40000',
        boxShadow: `${theme.shadows.sm}, 0px 0px 10px 0px #E40000 inset`
      }
    : {}

  const lastSeen = lastSeenTime
    ? `Last seen: ${dayjs(lastSeenTime).format('DD/MM/YYYY HH:mm')}`
    : ''

  return (
    <Paper
      radius="md"
      shadow="sm"
      pt="sm"
      pb="md"
      px="lg"
      sx={{
        width: typeof width !== 'undefined' ? width : 'initial',
        height: typeof height !== 'undefined' ? height : 'initial',
        overflowX: 'auto',
        overflowY: 'clip',
        cursor: 'pointer',
        ...alarmStyles,
        '&:hover, &:hover div.sensors': {
          background: theme.colors.gray[1]
        },
        '&:active': {
          translate: '2px 2px'
        },
        transition: 'background .2s ease-in-out'
      }}
      onClick={agentDetailsredirect}
    >
      <AgentDetails
        agentId={agentId}
        agentName={agentName}
        assetType={assetType}
        lastSeen={lastSeen}
        battery={battery}
        isAlarm={isAlarm}
        isSmall={isSmall}
      />
      {!isSmall && <Sensors agentId={agentId} assetType={assetType} sensors={primarySensors} pt="sm" />}
    </Paper>
  )
}
