import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type GeozoneDataItem } from '@venturi-io/api/src/analytics/notification'
import { LabelValue, NotificationBody, NotificationHeading } from './shared'
import { type NotificationItemProps } from '.'

const normalizeRule = (ruleName: string) => {
  switch (ruleName) {
    case 'AGENT_INSIDE_ZONE':
      return 'entered'
    case 'AGENT_OUTSIDE_ZONE':
      return 'left'
    default:
      return ruleName
  }
}

function Geozone ({
  onRead,
  showClear,
  closeDrawer,
  expanded = false,
  ...incident
}: NotificationItemProps) {
  const {
    timestamp,
    data
  } = incident
  const {
    geoZoneName,
    geoZoneId,
    geoZoneRuleName,
    agentName,
    agentId
  } = data as GeozoneDataItem

  const [show, { toggle }] = useDisclosure(expanded)
  const handleClear = () => {
    if (onRead) {
      onRead(incident)
    }
  }

  return (
    <Flex
      direction="column"
      justify="center"
    >
      <NotificationHeading
        show={show}
        link={`/?agentId=${agentId}`}
        showClear={showClear}
        toggle={toggle}
        onClear={handleClear}
        closeDrawer={closeDrawer}
        heading={(
          <Text
            size="xs"
            sx={{
              width: '180px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            <strong>
              {agentName}
            </strong>
            {' '}
            {normalizeRule(geoZoneRuleName ?? '--')}
            {' '}
            <strong>
              {geoZoneName}
            </strong>
          </Text>
        )}
      />
      <NotificationBody
        show={show}
        timestamp={timestamp}
      >
        <>
          <LabelValue
            size="xs"
            label="Geozone rule"
            value={geoZoneRuleName}
          />
          <LabelValue
            size="xs"
            label="Geozone"
            value={geoZoneName}
            link={`/?geoZoneId=${geoZoneId}`}
            closeDrawer={closeDrawer}
          />
        </>
      </NotificationBody>
    </Flex>
  )
}

export default Geozone
