import { RichTextEditor, Link } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'

interface RTEProps {
  content: string
  onUpdate: (content: string) => void
  mode?: string
}

export default function RTE ({
  content,
  onUpdate,
  mode
}: RTEProps) {
  if (mode === 'EDIT' && !content) {
    return null
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      TextAlign.configure({ types: ['heading', 'paragraph'] })
    ],
    content,
    onUpdate (props) {
      const updatedContent = props.editor.getHTML()
      onUpdate(updatedContent)
    }
  })

  return (
    <RichTextEditor
      editor={editor}
      sx={{
        height: '100%'
      }}
    >
      <RichTextEditor.Toolbar>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
        </RichTextEditor.ControlsGroup>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content style={{
        flex: 1,
        overflow: 'auto',
        maxHeight: '190px'
      }}
      />
    </RichTextEditor>
  )
}
