import { useState, useEffect, type ReactNode } from 'react'
import { type ReportType } from '@venturi-io/api/src/config/report'
import Select from 'src/Input/Select'
import { toProperCase } from 'src/utils/strings'

interface Props {
  required?: boolean
  disabled?: boolean
  value?: number
  error?: ReactNode
  data: ReportType[]
  onChange: (value?: ReportType) => void
}

export default function SelectReportType ({
  required = false,
  disabled = false,
  error,
  data,
  ...props
}: Props) {
  const initialValue = props.value?.toString() ?? ''
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const type = data.find(({ id }) => id.toString() === value)
    if (type) {
      props.onChange(type)
    } else {
      props.onChange()
    }
  }, [value])

  return (
    <Select
      withAsterisk={required}
      disabled={disabled}
      placeholder="Choose a Report Type"
      value={initialValue}
      data={data.map(({ id, name }) => ({
        label: toProperCase(name),
        value: id.toString()
      }))}
      onChange={val => setValue(val ?? '')}
      error={error}
    />
  )
}
