import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createStyles,
  Button,
  Col,
  Group,
  Grid,
  Divider,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import {
  findUserById,
  updateUser as updateUserReq,
  deleteUser as deleteUserReq
} from '@venturi-io/api/src/userManager/user'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import Paper from 'src/Layout/Paper'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextInput from 'src/Input/TextInput'
import MultiSelectRole from 'src/Input/MultiSelect/MultiSelectRole'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import NotFound from 'src/Router/NotFound'
import { isEmptyValue } from 'src/utils/strings'

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

interface InitialValues {
  firstName: string
  lastName: string
  email?: string
  jobTitle?: string
  phoneNumber?: string
  roles?: string[]
  emergencyContact1Name?: string
  emergencyContact1PhoneNumber?: string
  emergencyContact2Name?: string
  emergencyContact2PhoneNumber?: string
}

interface RouteParams extends Record<string, string | undefined> {
  userId: string
}

export default function Edit () {
  const { userId: id } = useParams<RouteParams>()
  const { classes } = useStyles()
  const { token } = useUser()
  const navigate = useNavigate()
  const findUser = useApi(findUserById)
  const updateUser = useApi(updateUserReq)
  const deleteUser = useApi(deleteUserReq)
  const [showDelete, setShowDelete] = useState(false)
  const user = findUser.data.mapOrDefault(data => data, null)

  const redirectToListPage = useCallback(() => navigate('/settings/users'), [])

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const hasValue = (inputName: string, value?: string | string[]) => !value || value.length < 1
    ? `Please specify ${inputName}`
    : null

  const isUserContact = user?.status === 'INACTIVE'
  const isUserActive = user?.status === 'ACTIVE'

  const form = useForm<InitialValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      phoneNumber: '',
      roles: []
    },
    validate: {
      firstName: value => hasValue('First Name', value),
      lastName: value => hasValue('Last Name', value),
      jobTitle: value => isUserActive
        ? hasValue('Job Title', value)
        : null,
      email: value => !isUserContact
        ? value && /^\S+@\S+$/.test(value)
          ? null
          : 'Please input a valid Email'
        : null,
      phoneNumber: value => isUserActive
        ? hasValue('Phone Number', value)
        : null,
      roles: value => isUserActive
        ? hasValue('Role(s)', value)
        : null
    }
  })

  const handleSubmit = useCallback(({
    email,
    jobTitle,
    phoneNumber,
    emergencyContact1Name,
    emergencyContact1PhoneNumber,
    emergencyContact2Name,
    emergencyContact2PhoneNumber,
    ...values
  }: typeof form.values) => {
    if (updateUser.loading) return // Short-circuit

    if (user) {
      void updateUser
        .fetch({
          email: isEmptyValue(email),
          jobTitle: isEmptyValue(jobTitle),
          phoneNumber: isEmptyValue(phoneNumber),
          emergencyContact1Name: isEmptyValue(emergencyContact1Name),
          emergencyContact2Name: isEmptyValue(emergencyContact2Name),
          emergencyContact1PhoneNumber: isEmptyValue(emergencyContact1PhoneNumber),
          emergencyContact2PhoneNumber: isEmptyValue(emergencyContact2PhoneNumber),
          orgUserId: user.orgUserId,
          ...values
        }, token, 'Saved successfully!')
        .finally(redirectToListPage)
    }
  }, [form.values, user])

  const handleDelete = useCallback(() => {
    if (user) {
      const { firstName, lastName, orgUserId } = user
      const hasFirstOrLast = typeof firstName !== 'undefined' || typeof lastName !== 'undefined'
      const success = hasFirstOrLast
        ? `Succesfully deleted user "${firstName ?? ''} ${lastName ?? ''}"`
        : 'Successfully deleted user'

      void deleteUser
        .fetch({ orgUserId }, token, success)
        .finally(redirectToListPage)
    }
  }, [user])

  useEffect(() => {
    findUser.data.ifJust(({
      firstName,
      lastName,
      jobTitle,
      email,
      phoneNumber,
      roles,
      emergencyContact1Name,
      emergencyContact1PhoneNumber,
      emergencyContact2Name,
      emergencyContact2PhoneNumber
    }) => {
      form.setValues({
        firstName,
        lastName,
        jobTitle,
        email: email ?? '',
        phoneNumber,
        roles,
        emergencyContact1Name,
        emergencyContact1PhoneNumber,
        emergencyContact2Name,
        emergencyContact2PhoneNumber
      })
    })
  }, [findUser.data])

  useEffect(() => {
    void findUser.fetch({ orgUserId: parseInt(id) }, token)
  }, [id])

  return (
    <Paper className={classes.container}>
      <LoadingOverlay visible={findUser.loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow align="center" justify="center">
          <Col span={12} sm={6}>
            <TextInput
              withAsterisk
              label="First Name"
              {...form.getInputProps('firstName')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              withAsterisk
              label="Last Name"
              {...form.getInputProps('lastName')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              withAsterisk={isUserActive}
              label="Job Title"
              {...form.getInputProps('jobTitle')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              withAsterisk={!isUserContact}
              label="Email"
              {...form.getInputProps('email')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              withAsterisk={isUserActive}
              label="Phone"
              {...form.getInputProps('phoneNumber')}
            />
          </Col>
          <Col span={12} sm={6}>
            {isUserActive && (
              <MultiSelectRole
                withAsterisk={isUserActive}
                label="Role(s)"
                value={form.values.roles}
                onChange={roles => form.setFieldValue('roles', roles)}
                required
                error={form.errors.roles}
              />
            )}
          </Col>
          <Col span={12} sm={6}>
            <Divider my="sm" />
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 1  (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact1Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact1PhoneNumber')} />
            </Group>
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 2 (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact2Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact2PhoneNumber')} />
            </Group>
          </Col>
          <Col span={6}>
            <Group position="left" mt="sm">
              <Button
                color="red"
                leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                onClick={() => setShowDelete(true)}
              >
                Delete
              </Button>
            </Group>
          </Col>
          <Col span={6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={updateUser.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>

      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={user?.firstName && user?.lastName
          ? `Deleting "${user?.firstName} ${user?.lastName}"`
          : 'Delete User'}
        loading={deleteUser.loading}
        question="Are you sure you want to delete this user? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </Paper>
  )
}
