import { memo, useEffect, useMemo } from 'react'
import { Container } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import PercentGraph, { type Props as PercentGraphProps } from 'src/Charts/PercentGraph'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  data: {
    agentId: Agent['agentId']
    sensorInstanceId: Sensor['sensorInstanceId']
  }
  indicators: PercentGraphProps['indicators']
  styles: PercentGraphProps['styles']
}

const SensorPercentage = ({
  data: {
    agentId,
    sensorInstanceId
  },
  indicators,
  styles
}: Props) => {
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)

  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensor = useMemo(() => (
    agent?.sensors.find(s => s.sensorInstanceId === sensorInstanceId) ?? null
  ), [agent, sensorInstanceId])

  const percentValue = useMemo(() => {
    if (sensor === null) return 0

    const currentVal = sensor.currentValue ? parseFloat(sensor.currentValue) : 0
    const maxVal = sensor.maxValue ? sensor.maxValue : 100

    return (currentVal / maxVal) * 100
  }, [sensor])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Container ref={ref} className={classes.itemContainer} fluid>
      {agentDetails.loading && <Loader variant="bars" />}
      {(!agentDetails.loading && agent && sensor) && (
        <PercentGraph
          name={`${agent.agentName} - ${sensor.name ?? ''}`}
          percent={percentValue}
          label={`${sensor.currentValue ?? ''} ${sensor.unit ?? ''}`}
          styles={{
            ...styles,
            width,
            height
          }}
          indicators={indicators}
        />
      )}
    </Container>
  )
}

export default memo(SensorPercentage)
