import { type CSSProperties } from 'react'
import { type PaperProps, Paper } from '@mantine/core'
import { type LineSvgProps } from '@nivo/line'
import Line from 'src/Charts/Line'

interface Props {
  data: LineSvgProps['data']
  colors: LineSvgProps['colors']
  background: CSSProperties['background']
  height: number
  chartMargin?: LineSvgProps['margin']
  padding?: CSSProperties['padding']
  radius?: PaperProps['radius']
  shadow?: PaperProps['shadow']
  isInteractive?: LineSvgProps['isInteractive']
}

export default function SparkLine ({
  data,
  colors,
  background,
  height,
  chartMargin,
  padding,
  radius,
  shadow,
  isInteractive = false
}: Props) {
  return (
    <Paper
      sx={{
        height,
        minWidth: 0,
        width: '100%',
        padding,
        background
      }}
      radius={radius}
      shadow={shadow}
    >
      <Line
        height={height}
        data={data}
        curve="linear"
        margin={chartMargin ?? {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
        lineWidth={2}
        areaOpacity={0.1}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableArea={true}
        enableTouchCrosshair={true}
        useMesh={true}
        isInteractive={isInteractive}
        legends={[]}
        colors={colors}
      />
    </Paper>
  )
}
