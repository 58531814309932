import { Bar } from 'src/stories/Bar'
import {
  findSensorByType,
  type SensorType,
  barColor,
  computeRelativePercentage,
  convertStrToFloat,
  type Sensors
} from '../shared'

interface Props {
  voltName: string
  sensorTypes: SensorType[]
  sensors: Sensors['sensors']
}

export default function SensorBars ({
  voltName,
  sensorTypes,
  sensors
}: Props) {
  return sensorTypes.map(sensorType => {
    const sensor = findSensorByType(sensorType, sensors)
    const value = convertStrToFloat(sensor?.currentValue)
    const percentValue = sensor
      ? computeRelativePercentage(
        value,
        typeof sensor.minValue !== 'undefined'
          ? sensor.minValue
          : 0,
        typeof sensor.maxValue !== 'undefined'
          ? sensor.maxValue
          : 100
      )
      : 0
    const primaryLabel = sensorType
      .split('_')
      .join(' ')
      .replace(`${voltName} VOLTAGE`, '')
    const secondaryLabel = sensor?.unit
      ? `${value} ${sensor.unit}`
      : value.toString()

    return (
      <Bar
        key={sensorType}
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        value={percentValue}
        color={barColor(sensor?.alarmStatus)}
      />
    )
  })
}
