import { Table as MantineTable, ScrollArea, Title } from '@mantine/core'
import { type AlarmIncident } from '@venturi-io/api/src/analytics/alarmIncident'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoData from 'src/Layout/TableRowNoData'
import Link from 'src/Layout/Link'

export interface Props {
  incidents: AlarmIncident[]
}

function IncidentsRow ({ incidents }: Props) {
  return incidents.length > 0
    ? (
      <>
        {incidents.map(({
          incidentId,
          siteName,
          agentName,
          sensorInstanceName,
          unit,
          stringValue
        }) => (
          <tr key={incidentId}>
            <td>{siteName ?? ''}</td>
            <td>{`${agentName} - ${sensorInstanceName}`}</td>
            <td>{`${stringValue} ${unit ?? ''}`}</td>
            <td>
              <FontAwesomeIcon
                color="red"
                icon={['fas', 'warning']}
                size="lg"
                width="50%"
                fade
              />
            </td>
            <td>
              <Link to={`/alarms/incident/${incidentId}`}>
                View
              </Link>
            </td>
          </tr>
        ))}
      </>
      )
    : <NoData message="No active alarms" />
}

export default function Table ({ incidents }: Props) {
  return (
    <div>
      <Title order={3}>
        Active Alarms
      </Title>
      <ScrollArea type="hover" scrollbarSize={5}>
        <MantineTable striped highlightOnHover>
          <thead>
            <tr>
              <th>Site</th>
              <th>Device</th>
              <th>Current value</th>
              <th>Alarm status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            <IncidentsRow incidents={incidents} />
          </tbody>
        </MantineTable>
      </ScrollArea>
    </div>
  )
}
