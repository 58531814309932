import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type AlarmWebhook } from '@venturi-io/api/src/analytics/alarmWebhook'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import TextTooltip from 'src/Layout/TextTooltip'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'
import { getCurrentBreakpoint } from 'src/utils/style'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'

export default function Row ({
  id,
  name,
  url,
  alarmKey,
  clearKey
}: AlarmWebhook & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(name)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(url)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(alarmKey)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(clearKey)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/alarm-webhooks/edit/${id}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
