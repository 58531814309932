import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import { getGeoZoneAction, type GeoZoneAction } from '@venturi-io/api/src/config/geoZoneAction'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from './Form'

interface RouteParams extends Record<string, string | undefined> {
  geoZoneActionId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { token } = useUser()
  const geoZoneAction = useApi(getGeoZoneAction)
  const { classes } = useStyles()
  const { geoZoneActionId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<GeoZoneAction>()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const loadGeoZoneAction = () => {
    void geoZoneAction.fetch({ geoZoneActionId: parseInt(id) }, token)
  }

  useEffect(() => {
    loadGeoZoneAction()
  }, [])

  useEffect(() => {
    geoZoneAction.data.ifJust(setFormInitialValues)
  }, [geoZoneAction.data])

  return (
    <Paper className={classes.container} relative>
      {geoZoneAction.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={geoZoneAction.loading}
            nothing={geoZoneAction.data.isNothing()}
          />
        ),
        Just: () => <Form mode="EDIT" initialValues={formInitialValues} />
      })}
    </Paper>
  )
}
