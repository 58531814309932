import {
  Button,
  Group,
  Stack,
  Text,
  createStyles
} from '@mantine/core'
import { type UserGroup, getUserGroup } from '@venturi-io/api/src/userManager/userGroup'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Paper from 'src/Layout/Paper'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { getUsersForUserGroup } from '@venturi-io/api/src/userManager/user'
import ActionList from 'src/Layout/ActionList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from '../Form'
import { Header, Row, Card } from './UserList'
import UserAssignment from './UserAssignment'

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  },
  paper: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  userGroupId: string
}

export default function Edit () {
  const { classes } = useStyles()
  const { token } = useUser()
  const { userGroupId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<UserGroup>()
  const [showAddUser, setShowAddUser] = useState(false)
  const userGroup = useApi(getUserGroup)
  const users = usePaginatedApi(getUsersForUserGroup)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const userGroupId = parseInt(id)

  const loadUserGroup = () => {
    void userGroup.fetch({ userGroupId }, token)
  }

  const loadUsers = async (page?: number, size?: number): Promise<void> => {
    void users
      .fetch({
        userGroupId,
        page: page ?? users.page,
        size: size ?? users.size
      }, token)
  }

  const actions = {
    loadUsers: async () => await loadUsers(users.page)
  }

  useEffect(() => {
    userGroup.data.ifJust(setFormInitialValues)
  }, [userGroup.data])

  useEffect(() => {
    loadUserGroup()
    void loadUsers()
  }, [users.page])

  const hasUser = users.data.mapOrDefault(({ items }) => items.length > 0, false)

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.container} relative>
          {userGroup.data.caseOf({
            Nothing: () => (
              <Nothing
                isLoading={userGroup.loading}
                nothing={userGroup.data.isNothing()}
              />
            ),
            Just: () => <Form mode="EDIT" initialValues={formInitialValues} hasUser={hasUser} />
          })}
        </Paper>
        <Stack mt="lg" spacing={0}>
          <Group position="apart" align="start">
            <Text size="lg" weight="bold">Users</Text>
            <Button
              disabled={showAddUser}
              title="Add User to this group"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
              onClick={() => setShowAddUser(true)}
            >
              Add User
            </Button>
          </Group>
          <ActionList
            isLoading={users.loading}
            data={users.data.mapOrDefault(({ items }) => items, [])}
            actions={actions}
            extra={{ userGroupId }}
            getId={({ orgUserId }) => orgUserId}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={users.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            page={users.page}
            onPageChange={users.setPage}
          />
        </Stack>
      </div>
      <UserAssignment
        userGroupId={userGroupId}
        show={showAddUser}
        onClose={() => setShowAddUser(false)}
        onSave={loadUsers}
      />
    </>
  )
}
