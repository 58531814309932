import { Drawer, Title, createStyles } from '@mantine/core'
import Form from './Form'

const useStyles = createStyles(({
  fullScrollArea: {
    height: '100%',
    overflow: 'scroll'
  }
}))

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Create ({ show, close, onCreate }: Props) {
  const { classes } = useStyles()
  return (
    <Drawer
      className={classes.fullScrollArea}
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Sensor</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <Form mode="CREATE" onCreate={onCreate} onClose={close} />
    </Drawer>
  )
}
