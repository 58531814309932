import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type UserGroup } from '@venturi-io/api/src/userManager/userGroup'
import ActionButton from 'src/ActionButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

const Card = ({ id, name, description }: UserGroup & ListRow) => {
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => {
              navigate(`/settings/user-groups/edit/${id}`)
            }}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup
          label="Name"
          value={name}
          maxLength={textMaxLength}
        />
        <TextGroup
          label="Description"
          value={description}
          maxLength={30}
        />
      </Stack>
    </Paper>
  )
}

export default Card
