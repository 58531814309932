import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { useEffect } from 'react'
import { findMissingSensors } from '../shared'
import { MODULE_CONTENT } from './constants'
import PddDetails from './PddDetails'

interface Props {
  agent: AgentDetails
  contentKey: string
  setMissingSensors: (value: string[]) => void
}

export default function ModuleContainer ({
  agent,
  contentKey,
  setMissingSensors
}: Props) {
  const {
    agentId,
    agentName,
    sensors,
    geoLocation,
    alarmStatus,
    assignedTo
  } = agent

  useEffect(() => {
    const missingSensors = findMissingSensors([
      'POWER_STATUS',
      'BATTERY'
    ], sensors)

    setMissingSensors(missingSensors)
  }, [sensors])

  switch (contentKey) {
    case MODULE_CONTENT.PDD_DETAILS:
      return (
        <PddDetails
          agentId={agentId}
          agentName={agentName}
          geoLocation={geoLocation}
          sensors={sensors}
          alarmStatus={alarmStatus}
          assignedTo={assignedTo}
        />
      )

    default:
      return null
  }
}
