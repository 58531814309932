import { Box, Stack, Title } from '@mantine/core'
import Line from '../Charts/Line'
import { lightTextColor, darkTextColor } from '.'

const data = [
  {
    id: 'MTIS - BCV645',
    data: [
      {
        x: 'Jun 1',
        y: 143
      },
      {
        x: 'Jun 2',
        y: 251
      },
      {
        x: 'Jun 4',
        y: 114
      },
      {
        x: 'Jun 5',
        y: 133
      },
      {
        x: 'Jun 6',
        y: 51
      },
      {
        x: 'Jun 7',
        y: 59
      }
    ]
  },
  {
    id: 'MTIS - BEG570',
    data: [
      {
        x: 'Jun 1',
        y: 74
      },
      {
        x: 'Jun 2',
        y: 144
      },
      {
        x: 'Jun 4',
        y: 214
      },
      {
        x: 'Jun 5',
        y: 198
      },
      {
        x: 'Jun 6',
        y: 116
      },
      {
        x: 'Jun 7',
        y: 19
      }
    ]
  },
  {
    id: 'MTIS - BEL443',
    data: [
      {
        x: 'Jun 1',
        y: 98
      },
      {
        x: 'Jun 2',
        y: 173
      },
      {
        x: 'Jun 4',
        y: 244
      },
      {
        x: 'Jun 5',
        y: 30
      },
      {
        x: 'Jun 6',
        y: 127
      },
      {
        x: 'Jun 7',
        y: 14
      }
    ]
  },
  {
    id: 'MTIS - BEW732',
    data: [
      {
        x: 'Jun 1',
        y: 57
      },
      {
        x: 'Jun 2',
        y: 214
      },
      {
        x: 'Jun 4',
        y: 253
      },
      {
        x: 'Jun 5',
        y: 41
      },
      {
        x: 'Jun 6',
        y: 270
      },
      {
        x: 'Jun 7',
        y: 170
      }
    ]
  }
]

interface Props {
  lightModeOn: boolean
}

export default function Lines ({ lightModeOn = false }: Props) {
  return (
    <Box>
      <Stack spacing={2}>
        <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
          Power Source Voltage Report
        </Title>
        <Line
          height={400}
          data={data}
          curve="catmullRom"
          margin={{
            top: 50,
            right: 110,
            bottom: 50,
            left: 60
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          yFormat=" >-.2f"
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              },
              legend: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              }
            },
            legends: {
              text: {
                fill: lightModeOn
                  ? lightTextColor
                  : darkTextColor
              }
            }
          }}
          colors={{ scheme: 'set3' }}
          enableArea
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle',
            truncateTickAt: 0
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Voltage',
            legendOffset: -40,
            legendPosition: 'middle',
            truncateTickAt: 0
          }}
          pointSize={8}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-12}
          enableTouchCrosshair={true}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </Stack>
      <Stack spacing={2}>
        <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
          Sparkline (alternative from nivo)
        </Title>
        <Line
          height={400}
          data={[data[3]]}
          curve="linear"
          margin={{
            top: 50,
            right: 110,
            bottom: 50,
            left: 60
          }}
          areaOpacity={0.1}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-12}
          enableArea={true}
          isInteractive={false}
          enableTouchCrosshair={true}
          useMesh={true}
          legends={[]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              },
              legend: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              }
            },
            legends: {
              text: {
                fill: lightModeOn
                  ? lightTextColor
                  : darkTextColor
              }
            }
          }}
          colors={{ scheme: 'set3' }}
        />
      </Stack>
    </Box>
  )
}
