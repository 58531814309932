import { useCallback, useState, type ChangeEvent, useEffect } from 'react'
import {
  createStyles,
  ActionIcon,
  Checkbox,
  Popover,
  Stack,
  Text,
  Group,
  Switch,
  Indicator,
  Divider,
  Box,
  useMantineTheme
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { assetTypes } from '@venturi-io/api/src/config/agent'
import { useViewTypes } from 'src/Maps/GeoZoneMap/AgentAttendanceContext'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { colors } from 'src/utils/status'
import { type AgentType } from '..'

const useStyle = createStyles({
  label: {
    textTransform: 'capitalize'
  },
  indicator: {
    width: 12,
    height: 12,
    borderRadius: 6
  }
})

const allAssetTypes = [...assetTypes, '', undefined]
const statusLabels = ['Alarm', 'Online', 'Idling', 'Immobilized', 'Offline']

const statuses: Record<string, string> =
  Object.values(colors)
    .reduce((acc, color, idx) => ({
      ...acc,
      [color ?? 'unknown']: statusLabels[idx]
    }
    ), {})

const allStatuses = Object.keys(statuses)

export default function AssetsFilter () {
  const { colors } = useMantineTheme()
  const { classes } = useStyle()
  const [viewAll, setViewAll] = useState(true)
  const [viewAllType, setViewAllType] = useState(true)
  const [viewAllStatuses, setViewAllStatuses] = useState(true)
  const [lastTab, setLastTab] = useState<string>()
  const [show, { toggle, close }] = useDisclosure(false)
  const ref = useClickOutside(close)

  const {
    tab,
    query,
    filteredByQuery,
    allAgents,
    allPeople,
    allGroups,
    viewAgentsByTypes,
    viewAgentsByStatuses,
    setCheckedAgentIds,
    setCheckedPeopleIds,
    setCheckedGroupIds,
    setViewAgentsByTypes,
    setViewAgentsByStatuses
  } = useViewTypes()

  useEffect(() => {
    if (tab !== lastTab) {
      setLastTab(tab)
      setViewAll(true)
    }
  }, [tab])

  const handleClickAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const flag = event.currentTarget.checked
    setViewAll(flag)
    // all agents, people and groups as well

    setCheckedAgentIds(lastIds => (
      flag
        ? query.trim() !== ''
          ? [
              ...lastIds,
              ...filteredByQuery.map(({ agentId }) => agentId)
            ]
          : allAgents.map(({ agentId }) => agentId)
        : query.trim() !== ''
          ? lastIds.filter(id => (
            !filteredByQuery
              .map(({ agentId }) => agentId)
              .includes(id)
          ))
          : []
    ))
    setCheckedPeopleIds(flag
      ? allPeople.map(({ orgUserId }) => orgUserId)
      : [])
    setCheckedGroupIds(flag
      ? allGroups.map(({ agentGroupId }) => agentGroupId)
      : [])
  }, [query, allAgents, allPeople, setCheckedPeopleIds, setCheckedAgentIds])

  const handleClickType = useCallback((event: ChangeEvent<HTMLInputElement>, type: AgentType) => {
    if (event.currentTarget.checked) {
      if (type === 'OTHERS') {
        setViewAgentsByTypes([...viewAgentsByTypes, '', undefined, type?.toUpperCase()])
      } else {
        setViewAgentsByTypes([...viewAgentsByTypes, type?.toUpperCase() as AgentType])
      }
    } else {
      setViewAllType(false)
      if (type === 'OTHERS') {
        setViewAgentsByTypes(viewAgentsByTypes.filter((type_: AgentType) => {
          return (
            type_ !== '' &&
            type_ !== undefined &&
            type_ !== 'OTHERS'
          )
        }))
      } else {
        setViewAgentsByTypes(viewAgentsByTypes.filter((type_: AgentType) => (
          !type_?.includes(type?.toUpperCase() as string)
        )))
      }
    }
  }, [viewAgentsByTypes, setViewAgentsByTypes])

  const handleClickStatus = useCallback((event: ChangeEvent<HTMLInputElement>, color: string) => {
    const flag = event.currentTarget.checked
    setViewAgentsByStatuses(lastStatuses =>
      flag
        ? [...lastStatuses, color]
        : lastStatuses.filter(status => status !== color)
    )
    if (!flag) {
      setViewAllStatuses(false)
    }
  }, [setViewAgentsByStatuses])

  const handleClickAllType = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setViewAllType(event.currentTarget.checked)
    if (event.currentTarget.checked) {
      setViewAgentsByTypes(allAssetTypes)
    } else {
      setViewAgentsByTypes([])
    }
  }, [])

  const handleClickAllStatuses = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setViewAllStatuses(event.currentTarget.checked)
    if (event.currentTarget.checked) {
      setViewAgentsByStatuses(allStatuses)
    } else {
      setViewAgentsByStatuses([])
    }
  }, [])

  useEffect(() => {
    setViewAgentsByTypes(allAssetTypes)
    setViewAgentsByStatuses(allStatuses)
  }, [setViewAgentsByTypes])

  return (
    <Group
      position="apart"
      pt="md"
      px={28}
    >
      <Checkbox
        label={viewAll ? 'ALL' : 'NONE'}
        size="xs"
        color="primary"
        checked={viewAll}
        onChange={handleClickAll}
      />
      <Popover
        opened={show}
        width={220}
        position="right-start"
        shadow="lg"
        withinPortal
        offset={{
          mainAxis: 32
        }}
      >
        <Popover.Target>
          <Indicator
            disabled={(
              viewAgentsByTypes.length === allAssetTypes.length &&
              viewAgentsByStatuses.length === allStatuses.length
            )}
            size={8}
            offset={7}
            position="top-end"
            color="primary"
          >
            <ActionIcon
              onClick={toggle}
              variant="transparent"
              color="primary"
              size="md"
            >
              <FontAwesomeIcon icon={['far', 'filter-list']} color="primary" />
            </ActionIcon>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown pb="lg">
          <Stack ref={ref} spacing={0}>
            <Group position="apart">
              <Text size="xs" weight="bold">
                Asset Filters
              </Text>
              <ActionIcon onClick={close} size="xs">
                <FontAwesomeIcon icon={['far', 'xmark']} />
              </ActionIcon>
            </Group>
            <Stack my="md" spacing={8}>
              <Switch
                size="xs"
                label="All types"
                color="primary"
                checked={viewAllType}
                onChange={handleClickAllType}
              />
              {assetTypes.map(type => (
                <Switch
                  classNames={{
                    label: classes.label
                  }}
                  key={type}
                  size="xs"
                  label={type.toLowerCase()}
                  color="primary"
                  checked={Array.isArray(viewAgentsByTypes) && viewAgentsByTypes.includes(type)}
                  onChange={event => {
                    handleClickType(event, type)
                  }}
                />
              ))}
            </Stack>
            <Divider />
            <Text mt="md" size="xs" weight="bold">
              Status
            </Text>
            <Stack mt="md" spacing={8}>
              <Switch
                size="xs"
                label="All statuses"
                color="primary"
                checked={viewAllStatuses}
                onChange={handleClickAllStatuses}
              />
              {Object.keys(statuses).map(color => (
                <Group key={color} position="apart">
                  <Switch
                    checked={viewAgentsByStatuses.includes(color)}
                    classNames={{
                      label: classes.label
                    }}
                    size="xs"
                    color="primary"
                    label={statuses[color]}
                    onChange={event => {
                      handleClickStatus(event, color)
                    }}
                  />
                  <Box
                    className={classes.indicator}
                    sx={{
                      backgroundColor: colors[color]
                        ? colors[color][5]
                        : color
                    }}
                  />
                </Group>
              ))}
            </Stack>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Group>
  )
}
