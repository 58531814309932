import { useNavigate } from 'react-router-dom'
import { ActionIcon, Group, Paper, Stack } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'

export default function Card ({ ...agent }: Agent & ListRow) {
  const navigate = useNavigate()
  const { agentId, agentName } = agent

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={agentId.toString()} isKey />
          <ActionIcon
            title="Edit"
            color="green"
            variant="filled"
            onClick={() => navigate(`/settings/agents/${agentId}`)}
          >
            <FontAwesomeIcon icon={['fas', 'pencil']} color="white" />
          </ActionIcon>
        </Group>
        <TextGroup label="Name" value={agentName} maxLength={25} />
      </Stack>
    </Paper>
  )
}
