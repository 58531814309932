import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type SpeedDataItem } from '@venturi-io/api/src/analytics/notification'
import { LabelValue, NotificationBody, NotificationHeading } from './shared'
import { type NotificationItemProps } from '.'

function Speed ({
  onRead,
  showClear,
  closeDrawer,
  expanded = false,
  ...incident
}: NotificationItemProps) {
  const {
    timestamp,
    data
  } = incident
  const {
    speed,
    agentName,
    geoZoneName,
    geoZoneId,
    agentId,
    tripId,
    tripStartTime
  } = data as SpeedDataItem

  const [show, { toggle }] = useDisclosure(expanded)

  const handleClear = () => {
    if (onRead) {
      onRead(incident)
    }
  }

  return (
    <Flex
      direction="column"
      justify="center"
    >
      <NotificationHeading
        show={show}
        link={`/?agentId=${agentId}&tripId=${tripId}&startTime=${encodeURIComponent(tripStartTime ?? '')}`}
        showClear={showClear}
        toggle={toggle}
        onClear={handleClear}
        closeDrawer={closeDrawer}
        heading={(
          <Text
            size="xs"
            sx={{
              width: '240px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            <strong>
              {agentName}
            </strong>
            {' '}
            is Speeding
          </Text>
        )}
      />
      <NotificationBody
        show={show}
        timestamp={timestamp}
      >
        <>
          {(speed && tripStartTime) && (
            <LabelValue
              size="xs"
              label="Speed"
              value={`${speed} kph`}
              closeDrawer={closeDrawer}
              link={`/?agentId=${agentId}&tripId=${tripId}&startTime=${encodeURIComponent(tripStartTime)}`}
            />
          )}
          {geoZoneName && (
            <LabelValue
              size="xs"
              label="Geozone"
              value={geoZoneName}
              closeDrawer={closeDrawer}
              link={`/?geoZoneId=${geoZoneId}`}
            />
          )}
        </>
      </NotificationBody>
    </Flex>
  )
}

export default Speed
