import { useCallback, useEffect, useState } from 'react'
import {
  Center,
  Col,
  Grid,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { type SensorData, getSensorData } from '@venturi-io/api/src/collector/sensor'
import dayjs, { type ManipulateType } from 'dayjs'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Paper from 'src/Layout/Paper'
import Loader from 'src/Layout/Loader'
import Selector, { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import { capitalise } from 'src/utils/strings'
import Chart from './Chart'

interface SensorWithHistory extends Sensor {
  data: SensorData['data']
}

interface ChartsProps {
  req: number
  bucket: ChosenBucket<ItemKeys>
  sensorsHistory: SensorWithHistory[]
}

const Charts = ({ req, bucket, sensorsHistory }: ChartsProps) => (
  sensorsHistory.length > 0
    ? (
      <Stack pt="md" spacing="lg">
        {sensorsHistory.map(({ sensorInstanceId, name, unit, data }) => {
          const instanceId = `${sensorInstanceId}-${req}`
          const label = capitalise(unit ?? name)

          return (
            <Chart
              key={instanceId}
              id={instanceId}
              title={name}
              label={label}
              group={`agent-details-${bucket.item}-${req}`}
              data={data}
            />
          )
        })}
      </Stack>
      )
    : <Center><Text>No history data</Text></Center>
)

export interface Props {
  sensors: Sensor[]
  isLoadingSensors?: boolean
}

const History = ({ sensors, isLoadingSensors = false }: Props) => {
  const { token } = useUser()
  const sensorInstances = useApi(getSensorData)

  const [req, setReq] = useState(Math.floor(Math.random() * (9999 - 1) + 1))
  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '24 hours',
    data: {
      timeBucket: '1 hour',
      noOfRecords: 24
    }
  })

  const isLoading = isLoadingSensors || sensorInstances.loading

  const sensorInstanceIds = sensors.map(({ sensorInstanceId }) => sensorInstanceId)

  const sensorsHistory: SensorWithHistory[] = sensorInstances.data.mapOrDefault(({ result }) => (
    result.map(({ sensorInstanceId, data }) => {
      const sensor = sensors.find(sensor => sensor.sensorInstanceId === sensorInstanceId)

      if (!sensor) throw new Error(`No sensor found with sensor instance id "${sensorInstanceId}"`)

      return {
        ...sensor,
        data
      }
    })
  ), [])

  const updateBucket = useCallback((input: ChosenBucket<ItemKeys>) => {
    setBucket(input)
  }, [setBucket])

  const loadHistory = useCallback(() => {
    const {
      item,
      data: {
        noOfRecords,
        timeBucket
      }
    } = bucket
    const time = item.split(' ')
    const request = {
      sensorInstanceIds,
      startTime: dayjs().subtract(Number.parseInt(time[0]), time[1] as ManipulateType).format(dateFormat),
      endTime: dayjs().format(dateFormat),
      noOfRecords,
      timeZone: giveOffset() as '+10:00',
      timeBucket
    }

    void sensorInstances.fetch(request, token)
  }, [sensorInstanceIds, bucket])

  useEffect(() => {
    loadHistory()
  }, [sensors, bucket.item])

  useEffect(() => {
    setReq(req + 1)
  }, [sensorInstances.data])

  return (
    <Paper relative>
      <Grid>
        <Col span={12} sm={9}>
          <Title order={4}>History</Title>
        </Col>
        <Col span={12} sm={3}>
          <Selector chosenBucket={bucket} updateBucket={updateBucket} />
        </Col>
      </Grid>
      {!isLoading
        ? (
          <Charts
            req={req}
            bucket={bucket}
            sensorsHistory={sensorsHistory}
          />
          )
        : <Loader />}
    </Paper>
  )
}

export default History
