import {
  type CSSProperties,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Container, Title } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { getGeoZonesAndAgents } from '@venturi-io/api/src/config/geoZone'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import Donut, { type Props as DonutProps } from 'src/Charts/Donut'
import { round } from 'src/utils/math'
import { toProperCase } from 'src/utils/strings'
import { useSharedStyles } from '../shared'

interface ItemsSummary {
  stationary: number
  immobilised: number
  moving: number
  off: number
  'no actual state': number
  'no coordinates': number
}

type ItemType = keyof ItemsSummary

export interface Props {
  id: string
  name: string
}

const colors: Record<ItemType, CSSProperties['color']> = {
  stationary: '#F3473D',
  immobilised: '#FF9900',
  moving: '#4DB252',
  off: '#E3E4E9',
  'no actual state': '#FF9494',
  'no coordinates': '#FFEB3B'
}

const MotionState = ({ name }: Props) => {
  const { ref, width, height } = useElementSize()
  const { classes } = useSharedStyles()
  const { token, orgId } = useUser()
  const agentsAndGeoZones = useApi(getGeoZonesAndAgents)
  const [itemsSummary, setItemsSummary] = useState<ItemsSummary>({
    stationary: 0,
    immobilised: 0,
    moving: 0,
    off: 0,
    'no actual state': 0,
    'no coordinates': 0
  })
  const [totalCount, setTotalCount] = useState(0)

  const data: DonutProps['data'] = useMemo(() => (Object
    .keys(itemsSummary)
    .map((key, index) => {
      const value = itemsSummary[key as ItemType]

      return {
        id: index,
        label: toProperCase(key),
        value,
        valuePercent: value !== 0
          ? round((value / totalCount) * 100)
          : value,
        color: colors[key as ItemType]
      }
    })
  ), [itemsSummary, colors])

  useEffect(() => {
    agentsAndGeoZones.data.ifJust(({ agents }) => {
      const stationary = agents.filter(({ agentStatus }) => agentStatus === 'IDLE')
      const immobilised = agents.filter(({ agentStatus }) => agentStatus === 'IMMOBILISED')
      const moving = agents.filter(({ agentStatus }) => agentStatus === 'MOVING')
      const off = agents.filter(({ agentStatus }) => agentStatus === 'OFF')
      const noActualState = agents.filter(({ agentStatus }) => typeof agentStatus === 'undefined')
      const noCoordinates = agents.filter(({ geoLocation }) => typeof geoLocation === 'undefined')
      const summary: ItemsSummary = {
        stationary: stationary.length,
        immobilised: immobilised.length,
        moving: moving.length,
        off: off.length,
        'no actual state': noActualState.length,
        'no coordinates': noCoordinates.length
      }
      const total = Object.keys(summary).reduce((total, key) => (
        total + summary[key as keyof ItemsSummary]
      ), 0)

      setItemsSummary(summary)
      setTotalCount(total)
    })
  }, [agentsAndGeoZones.data])

  useEffect(() => {
    void agentsAndGeoZones.fetch({ orgId }, token)
  }, [orgId])

  return (
    <Container
      ref={ref}
      className={classes.itemContainer}
      sx={{
        background: 'white',
        overflow: 'clip'
      }}
      fluid
    >
      {!agentsAndGeoZones.loading && (
        <Title
          ml="md"
          mt="sm"
          order={3}
          sx={{
            alignSelf: 'start',
            marginTop: '2.5em !important'
          }}
        >
          {name}
        </Title>
      )}
      {agentsAndGeoZones.loading && <Loader variant="bars" />}
      {!agentsAndGeoZones.loading && (
        <Donut
          width={width}
          height={height}
          value={totalCount}
          data={data}
          withLegend
        />
      )}
    </Container>
  )
}

export default memo(MotionState)
