import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  type ColProps,
  useMantineTheme,
  Button,
  Col,
  Grid,
  Group,
  Stack
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createSensorDataTransformer,
  updateSensorDataTransformer,
  deleteSensorDataTransformer,
  type SensorDataTransformer
} from '@venturi-io/api/src/config/sensorDataTransformer'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { mq } from 'src/utils/style'
import Textarea from 'src/Input/Textarea'

interface Props {
  mode: FormMode
  initialValues?: SensorDataTransformer
  onCreate?: () => Promise<void>
  onClose?: () => void
}

export default function Form ({
  mode,
  initialValues,
  onCreate,
  onClose
}: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { token } = useUser()
  const navigate = useNavigate()
  const newSensorDataTransformer = useApi(createSensorDataTransformer)
  const editSensorDataTransformer = useApi(updateSensorDataTransformer)
  const removeSensorDataTransformer = useApi(deleteSensorDataTransformer)
  const [showDelete, setShowDelete] = useState(false)

  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<Omit<SensorDataTransformer, 'id'>>({
    initialValues: {
      name: '',
      function: ''
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null,
      function: value => value.length < 1
        ? 'Please specify Function'
        : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/my-admin/scale-factor')

  const handleCreate = ({ name, function: fn }: FormValues) => {
    void newSensorDataTransformer
      .fetch({
        name,
        function: fn
      }, token, 'Successfully created new scale factor')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }

  const handleUpdate = ({ name, function: fn }: FormValues) => {
    const sensorDataTransformerId = initialValues?.id

    if (!sensorDataTransformerId) throw new Error('Unable to perform the action, no scale factor provided')

    void editSensorDataTransformer
      .fetch({
        sensorDataTransformerId,
        name,
        function: fn
      }, token, 'Successfully updated scale factor')
      .finally(redirectToListPage)
  }

  const handleDelete = () => {
    const sensorDataTransformerId = initialValues?.id

    if (!sensorDataTransformerId) throw new Error('Unable to perform the action, no scale factor provided')

    void removeSensorDataTransformer
      .fetch({ sensorDataTransformerId }, token, 'Succesfully deleted scale factor')
      .finally(redirectToListPage)
  }

  const handleSubmit = (values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }

  const handleClose = () => {
    form.reset()

    if (onClose) onClose()
  }

  useEffect(() => {
    if (mode === 'EDIT' && initialValues) {
      const { name, function: fn } = initialValues
      form.setValues({
        name,
        function: fn
      })
    }
  }, [mode, initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <Stack spacing="md">
              <TextInput
                withAsterisk
                label="Name"
                {...form.getInputProps('name')}
              />
              <Textarea
                withAsterisk
                label="Function"
                {...form.getInputProps('function')}
              />
            </Stack>
          </Col>
          {isDesktop && <Col span={6} />}
          <Col span={12}>
            <Group position="apart">
              {inEditMode && (
                <Group
                  position="left"
                >
                  <Button
                    mt="sm"
                    color="red"
                    size={isDesktop ? 'sm' : 'xs'}
                    leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                    onClick={() => setShowDelete(true)}
                  >
                    Delete
                  </Button>
                </Group>
              )}
              <Group position="right" mt="sm">
                <Button
                  variant="subtle"
                  color="dark"
                  size={isDesktop ? 'sm' : 'xs'}
                  onClick={inCreateMode
                    ? handleClose
                    : redirectToListPage}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="green"
                  leftIcon={(
                    <FontAwesomeIcon
                      icon={[
                        'fas',
                        inCreateMode
                          ? 'plus'
                          : 'floppy-disk'
                      ]}
                      color="white"
                    />
                  )}
                  size={isDesktop ? 'sm' : 'xs'}
                  disabled={inCreateMode
                    ? newSensorDataTransformer.loading
                    : editSensorDataTransformer.loading}
                >
                  {inCreateMode
                    ? 'Create'
                    : 'Save'}
                </Button>
              </Group>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeSensorDataTransformer.loading}
          question="Are you sure you want to permanently delete this scale factor?"
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
