import { IconAlertTriangle } from '@tabler/icons-react'
import StatusBadge from 'src/AssetTemplate/Components/StatusBadge'
import colors from 'src/AssetTemplate/colors'

interface Props {
  count: number
}

export default function Alarms ({ count }: Props) {
  return (
    <StatusBadge
      title="Alarms"
      icon={<IconAlertTriangle size={20} />}
      // TODO: Determine the color based on alarm counter
      color={colors.yellow}
      textColor="black"
      value={count.toString()}
    />
  )
}
