import { useState } from 'react'
import { Group, Image, Stack, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Zone = () => (
  <Group
    position="center"
    my="lg"
    mx="md"
    style={{
      minHeight: 125,
      pointerEvents: 'none'
    }}
  >
    <FontAwesomeIcon icon={['fas', 'image']} size="4x" color="silver" />
    <Stack align="stretch" justify="center" spacing={1}>
      <Text size="lg" align="center">
        Drag image here or click to select file
      </Text>
      <Text size="sm" color="dimmed" align="center">
        Attach image file that should not exceed 5mb
      </Text>
    </Stack>
  </Group>
)

interface DropFile {
  imageFile: File
  imageUrl: string
}

interface Props {
  imageUrl?: string
  onChange?: (file: DropFile) => void
  loading?: boolean
}

export default function ImageDropzone ({ imageUrl, onChange, loading = false }: Props) {
  const [photo, setPhoto] = useState(imageUrl)
  const hasPhoto = typeof imageUrl !== 'undefined'

  return (
    <Dropzone
      onDrop={([file]) => {
        const url = URL.createObjectURL(file)
        setPhoto(url)

        if (onChange) {
          onChange({
            imageFile: file,
            imageUrl: url
          })
        }
      }}
      maxSize={3 * 1024 ** 2}
      accept={IMAGE_MIME_TYPE}
      multiple={false}
      loading={loading}
    >
      {hasPhoto
        ? <Image src={photo} width={350} height={170} fit="contain" withPlaceholder />
        : <Zone />}
    </Dropzone>
  )
}
