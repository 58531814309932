import { useCallback, useEffect, useState } from 'react'
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import { featureToGeoZone, type Feature, type MapMode } from '../shared'
import { MODES } from '../constants'
import { type GeoZoneData } from './'

interface Props {
  mode: MapMode
  orgId: number
  activeFeature: Feature
  selectedGeoZone: GeoZoneData | null
  onCreate: (geoZone: Omit<GeoZone, 'geoZoneId'>) => void
  onUpdate: (geoZone: GeoZone, newAgentGroupIds: number[], removedAgentGroupIds: number[]) => void
  onDelete: () => void
  onCancel: () => void
  setShowInformationBox: (value: boolean) => void
}

export default function GeoZoneProperties ({
  mode,
  orgId,
  activeFeature: selectedFeature,
  selectedGeoZone,
  onCreate,
  onUpdate,
  onDelete,
  onCancel,
  setShowInformationBox
}: Props) {
  const [isHoverDeleteButton, setIsHoverDeleteButton] = useState(false)
  const hasExistingAgentGroup = !!selectedGeoZone?.agentGroups && selectedGeoZone.agentGroups.length > 0
  const initialValues = selectedGeoZone ?? {
    geoZoneId: -1,
    orgId,
    name: '',
    description: '',
    agentGroups: [],
    hiddenOnMap: false
  }

  const form = useForm<GeoZoneData>({
    initialValues,
    validate: {
      name: value => !value || value.length < 1 ? 'Please specify Name' : null
    }
  })

  const newAgentGroupIds: number[] = form.values.agentGroups
    ?.filter(({ agentGroupId }) => (
      !selectedGeoZone?.agentGroups?.find((group) => group.agentGroupId === agentGroupId)
    ))
    .map(({ agentGroupId }) => agentGroupId) ??
  []

  const removedAgentGroupIds: number[] = selectedGeoZone?.agentGroups
    ?.filter(({ agentGroupId }) => (
      !form.values.agentGroups?.find((group) => group.agentGroupId === agentGroupId)
    ))
    .map(({ agentGroupId }) => agentGroupId) ??
  []

  const handleCreate = useCallback((geoZone: Omit<GeoZone, 'geoZoneId'>) => onCreate(geoZone), [form.values])

  const handleSubmit = useCallback((values: typeof form.values) => {
    selectedFeature.properties.data = { ...values }

    const geoZone: GeoZone = featureToGeoZone(selectedFeature)
    const { geoZoneId, ...geoZoneRaw } = geoZone

    if (geoZoneId && geoZoneId !== -1) {
      onUpdate(geoZone, newAgentGroupIds, removedAgentGroupIds)
    } else {
      handleCreate(geoZoneRaw)
    }

    setShowInformationBox(false)
  }, [newAgentGroupIds, removedAgentGroupIds])

  useEffect(() => {
    if (selectedGeoZone) {
      form.setValues(selectedGeoZone)
    } else {
      form.setValues(initialValues)
    }
  }, [selectedGeoZone])

  return (
    <>
      {mode?.id === MODES.EDITING && (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack spacing="md">
            <Stack spacing="sm">
              <TextInput
                withAsterisk
                label="Name"
                size="xs"
                {...form.getInputProps('name')}
              />
              <TextInput
                label="Description"
                size="xs"
                {...form.getInputProps('description')}
              />
              <MultiSelectAgentGroup
                label="Agent Groups"
                value={form.values?.agentGroups?.map(({ agentGroupId }) => agentGroupId.toString())}
                onChange={ids => (
                  form.setFieldValue('agentGroups', ids.map(id => ({ agentGroupId: Number(id) })))
                )}
                searchable
              />
              <Switch
                label="Hide Geozone on Map"
                color="primary"
                size="sm"
                checked={form.values.hiddenOnMap}
                onChange={event => {
                  form.setFieldValue('hiddenOnMap', event.currentTarget.checked)
                }}
              />
            </Stack>
            <Group spacing="xs">
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                size="xs"
              >
                Save
              </Button>
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                size="xs"
                onClick={() => {
                  onCancel()
                  setShowInformationBox(false)
                }}
              >
                Cancel
              </Button>
              <Tooltip
                opened={isHoverDeleteButton && hasExistingAgentGroup}
                position="bottom"
                color="red"
                label="You cannot delete a geo zone that has an agent group"
                withArrow
              >
                <Box
                  onMouseEnter={() => setIsHoverDeleteButton(true)}
                  onMouseLeave={() => setIsHoverDeleteButton(false)}
                >
                  <ActionIcon
                    variant="transparent"
                    onClick={onDelete}
                    disabled={hasExistingAgentGroup}
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} color={hasExistingAgentGroup ? 'silver' : 'red'} />
                  </ActionIcon>
                </Box>
              </Tooltip>
            </Group>
          </Stack>
        </form>
      )}
      {mode?.id === MODES.VIEWING && (
        <Box px="xs" pb="sm">
          <Stack spacing="xs">
            <div>
              <Text size="xs" weight={500}>Name</Text>
              <Text size="xs">{selectedGeoZone?.name ?? '-'}</Text>
            </div>
            <div>
              <Text size="xs" weight={500}>Description</Text>
              <Text size="xs">{selectedGeoZone?.description ?? '-'}</Text>
            </div>
            <div>
              <Text size="xs" weight={500}>Agent Groups</Text>
              <MultiSelectAgentGroup
                value={selectedGeoZone?.agentGroups?.map(({ agentGroupId }) => agentGroupId.toString())}
                disabled
              />
            </div>
          </Stack>
        </Box>
      )}
    </>
  )
}
