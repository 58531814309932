/**
 * This function renders the string based on our valueMap object from the server-side
 */
const valueMap = (
  valueMap: Record<string, string | number | boolean>,
  value: string | number | boolean | undefined
): string => {
  for (const key in valueMap) {
    if (key === value) {
      return valueMap[key].toString()
    }
  }

  return value?.toString() ?? '' // TODO: display this differently perhaps?
}

export default valueMap
