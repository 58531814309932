import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type PaginatedRequest, paginated, type AgentIdRequest, type Req } from '../shared'

// Get all active alarm incidents
const alarmIncident = z.object({
  incidentId: z.number(),
  stringValue: z.string(),
  agentName: z.string(),
  sensorInstanceName: z.string(),
  sensorInstanceId: z.number(),
  siteName: z.optional(z.string()),
  unit: z.optional(z.string())
})

export type AlarmIncident = z.infer<typeof alarmIncident>

const alarmIncidents = paginated.extend({
  items: z.array(alarmIncident)
})

interface AllAlarmIncidentsForOrgRequest extends PaginatedRequest {
  orgId: number
  sensorInstanceIds?: number[]
}

export const getAlarmIncidents = createStatelessApi<AllAlarmIncidentsForOrgRequest, typeof alarmIncidents>(
  'analytics',
  '/org/:orgId/alarms',
  { method: 'GET', type: 'path' },
  alarmIncidents
)

interface AllAlarmIncidentsForSiteRequest extends PaginatedRequest {
  siteId: number
}

interface ClearAlarmForSensorsRequest extends Req {
  sensorInstanceIds: number[]
}

export const getAlarmIncidentsForSite = createStatelessApi<AllAlarmIncidentsForSiteRequest, typeof alarmIncidents>(
  'analytics',
  '/site/:siteId/alarms',
  { method: 'GET', type: 'path' },
  alarmIncidents
)

export const clearAlarmIncidentForSensorInstance = createStatefulApi<ClearAlarmForSensorsRequest, z.ZodBoolean>(
  'analytics',
  '/sensor-instances/alarm-incidents/clear',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

export const clearAlarmIncidentForAgentId = createStatefulApi<AgentIdRequest, z.ZodBoolean>(
  'analytics',
  '/agents/:agentId/alarm-incidents/clear',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
