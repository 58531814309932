import {
  createStyles,
  Divider,
  Group,
  Pagination as MantinePagination,
  Text
} from '@mantine/core'
import Select from 'src/Input/Select'

interface Props {
  pageIndex: number
  pageSize: number
  totalRows: number
  setPageSize: (pageSize: number) => void
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void
}

const useStyles = createStyles(theme => ({
  paginationItemActive: {
    color: 'white',
    background: theme.colors.primary[5]
  },
  pageSelect: {
    width: '72px'
  }
}))

export default function Pagination ({
  pageIndex,
  pageSize,
  totalRows,
  setPageSize,
  gotoPage
}: Props) {
  const { classes } = useStyles()

  const page = pageIndex + 1
  const totalPages = Math.ceil(totalRows / pageSize)
  const pageSizeOptions = ['10', '25', '50', '100']

  const getPageInfo = () => {
    const firstRowNum = pageIndex * pageSize + 1
    const currLastRowNum = page * pageSize
    const lastRowNum = currLastRowNum < totalRows ? currLastRowNum : totalRows

    return `${firstRowNum} to ${lastRowNum} of ${totalRows} entries`
  }

  const handlePageChange = (pageNum: number) => gotoPage(pageNum - 1)

  return (
    <>
      <Divider mb="md" variant="dotted" />
      <Group position="apart">
        <MantinePagination
          classNames={{
            control: classes.paginationItemActive
          }}
          radius="xs"
          value={page}
          total={totalPages}
          onChange={handlePageChange}
          withControls={totalPages > 0}
          withEdges
        />
        <Text size="sm">{getPageInfo()}</Text>
        <Group position="center" spacing="xs">
          <Text size="sm">Show</Text>
          <Select
            className={classes.pageSelect}
            variant="filled"
            data={pageSizeOptions}
            value={pageSize.toString()}
            onChange={(pageSize) => setPageSize(Number(pageSize))}
          />
          <Text size="sm">rows</Text>
        </Group>
      </Group>
    </>
  )
}
