import { useState, useEffect, useContext, type RefObject } from 'react'
import { Button, Stack } from '@mantine/core'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import SelectFromTo from 'src/Input/Select/SelectFromTo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Range } from '@venturi-io/api'
import { type TripProps } from '..'
import { type FocusManagerRef } from '../FocusManager'
import TripsTable from './TripsTable'
import { getTripContext } from './AnimationContext'

interface Props {
  focusRef: RefObject<FocusManagerRef>
  loading: boolean
  timeRange: Range | null
  trips: TripProps[]
  setTimeRange?: (range: Range) => void
}

const Trips = ({
  focusRef,
  loading,
  trips,
  timeRange,
  setTimeRange
}: Props) => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [agentId] = useState(Number(params.get('agentId')))
  const { trip: animatingTrip, exitAnimation } = useContext(getTripContext())

  // check trips if it contains the current animating trip, else re-enable the animation buttons
  useEffect(() => {
    const newAgentId = Number(params.get('agentId'))
    if (trips && animatingTrip && newAgentId === agentId) {
      const includesAnimatingTrip = trips.find(trip => trip.tripId === animatingTrip.tripId)
      if (!includesAnimatingTrip) {
        void exitAnimation()
        const url = `/?agentId=${agentId}`
        navigate(url, { replace: true })
      }
    }
  }, [trips])

  return (
    <Stack>
      <SelectFromTo
        size="xs"
        withTime
        dateRange={timeRange}
        onChangeRange={date => {
          if (date && setTimeRange) {
            setTimeRange(date)
          }
        }}
        applyButton={(submit, disabled) => (
          <Button
            size="xs"
            disabled={disabled}
            onClick={submit}
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['far', 'rotate-right']} />}
          >
            Apply range
          </Button>
        )}
      />
      <TripsTable
        focusRef={focusRef}
        loading={loading}
        trips={trips}
      />
    </Stack>
  )
}

export default Trips
