import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type GeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import ActionButton from 'src/ActionButton'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  geoZoneRuleId,
  name,
  description,
  type
}: GeoZoneRule & ListRow) {
  const { classes, cx } = useStyles()
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{geoZoneRuleId}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(name)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(description)}
          maxLength={12}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip value={type} size="sm" />
      </div>
      <div className={cx(classes.col, classes.idCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => {
            navigate(`/settings/geozone-rules/edit/${geoZoneRuleId}`)
          }}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
