import { type ReactNode, useEffect } from 'react'
import { useMeta } from 'src/MetaContext'
import { type Role } from 'src/NeedsRole'
import { checkIfHasRole } from 'src/utils/role'
import Unauthorized from './Unauthorized'

interface Props {
  title?: string
  role?: Role | Role[]
  children?: ReactNode
}

export default function Page ({ title, children, role }: Props) {
  const { meta, setMeta } = useMeta()

  const allowed = role
    ? checkIfHasRole(role)
    : true

  useEffect(() => {
    setMeta({
      ...meta,
      pageTitle: title ?? ' '
    })
  }, [title])

  useEffect(() => {
    if (!allowed) {
      setMeta({
        ...meta,
        pageTitle: ''
      })
    }
  }, [role])

  return (!allowed)
    ? <Unauthorized />
    : children
}
