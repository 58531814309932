/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Center,
  Collapse,
  Stack,
  Text,
  createStyles,
  Paper,
  Checkbox,
  Button,
  useMantineTheme
} from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import TextTooltip from 'src/Layout/TextTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useCallback, useState } from 'react'
import { orderBy } from 'lodash'
import { mq } from 'src/utils/style'
import Loader from 'src/Layout/Loader'
import { type Agent as AgentNoSensor } from '@venturi-io/api/src/config/geoZone'
import { useViewTypes } from 'src/Maps/GeoZoneMap/AgentAttendanceContext'
import AgentStack from './AgentStack'

interface RenderProps extends Omit<Props, 'drivers'> {
  orgUserId: number
  firstName: string
  lastName: string
  loading?: boolean
  items: AgentNoSensor[]
}

function RenderAssets ({
  orgUserId,
  firstName,
  lastName,
  loading,
  onClick,
  items
}: RenderProps) {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))
  const [opened, { toggle }] = useDisclosure(false)
  const { classes } = useStyles({ isMobile, opened })
  const { peopleCheckReference, setCheckedPeopleIds, setCheckedAgentIds } = useViewTypes()

  const filteredItems = items.filter((item) => {
    return item.user?.orgUserId === orgUserId
  })

  const handleToggleGroupVisibility = useCallback((checked: boolean) => {
    setCheckedPeopleIds(lastIds => {
      // we also need to update the agentIds to be checked
      setCheckedAgentIds(lastAgentIds => checked
        ? [
            ...lastAgentIds,
            ...filteredItems.map(({ agentId }) => agentId)
          ]
        : lastAgentIds.filter(id => !filteredItems.map(({ agentId }) => agentId).includes(id))
      )

      return checked
        ? [
            ...lastIds,
            orgUserId
          ]
        : lastIds.filter(id => id !== orgUserId)
    })
  }, [orgUserId, setCheckedPeopleIds])

  return (
    <>
      <Paper
        className={`${classes.itemWrapper} ${classes.isActiveGroup}`}
        shadow="xs"
        p="sm"
        key={orgUserId}
        m="0 !important"
        onClick={toggle}
      >
        <Checkbox
          color="primary"
          size="xs"
          checked={peopleCheckReference[orgUserId]}
          onChange={event => {
            handleToggleGroupVisibility(event.currentTarget.checked)
          }}
          onClick={event => {
            event.stopPropagation()
          }}
        />
        <FontAwesomeIcon
          icon={['far', 'drivers-license']}
          color="gray"
          size="1x"
          fixedWidth
        />
        <TextTooltip
          size="xs"
          value={`${firstName} ${lastName}`}
          maxLength={30}
        />
      </Paper>
      <Collapse in={opened} pl={15} pb={15}>
        {loading
          ? <Loader />
          : (
            <AgentStack
              asChild
              isAgentGroup={false}
              agents={filteredItems}
              onClick={onClick}
            />)}
      </Collapse>
    </>
  )
}

interface DriverProps {
  isMobile: boolean
  opened: boolean
}

const useStyles = createStyles((theme, { isMobile, opened }: DriverProps) => ({
  itemWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    gap: 10,
    '&:hover': {
      background: theme.colors.gray[0]
    },
    overflowX: 'auto'
  },
  isActiveGroup: {
    border: opened ? '1px solid #103464' : 'none'
  },
  ctaButton: {
    position: 'fixed',
    bottom: 20,
    left: isMobile
      ? 130
      : 70,
    width: 'fit-content'
  }
}))

const NoData = () => (
  <Center>
    <Text size="sm" color="dimmed" mt="md" italic>No data retrieved</Text>
  </Center>
)

interface Props {
  onClick?: (agentId: Agent['agentId']) => void
}

export default function Drivers ({
  onClick
}: Props) {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { classes } = useStyles({ opened: false, isMobile })
  const {
    query,
    allPeople,
    filteredAgentsByAssetType,
    filteredByQuery
  } = useViewTypes()
  const drivers = orderBy(allPeople, ['firstName'], ['asc'])
  return allPeople.length === 0
    ? <NoData />
    : (
      <>
        <Stack spacing={10} mt={10}>
          {drivers.map(({ orgUserId, firstName, lastName }) => (
            <RenderAssets
              key={orgUserId}
              orgUserId={orgUserId}
              firstName={firstName ?? ''}
              lastName={lastName ?? ''}
              onClick={onClick}
              items={query
                ? filteredByQuery
                : filteredAgentsByAssetType}
            />
          ))}
        </Stack>
        {/* TODO: Navigate to driver group page once it's up */}
        <Button
          disabled={true}
          title="New People Group"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => {}}
          className={classes.ctaButton}
        >
          New People Group
        </Button>
      </>
      )
}
