import React, {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState
} from 'react'

export interface AssetContextProps {
  taskCount: number
  setTaskCount?: Dispatch<SetStateAction<number>>
}

export const AssetContext = createContext<AssetContextProps>({
  taskCount: 0
})

interface Props {
  children: ReactNode | JSX.Element
}

function Context ({ children }: Props) {
  const [taskCount, setTaskCount] = useState(0)

  return (
    <AssetContext.Provider value={{
      taskCount,
      setTaskCount
    }}
    >
      {children}
    </AssetContext.Provider>
  )
}

export default Context
