import { getGeoZoneActions, type GeoZoneAction } from '@venturi-io/api/src/config/geoZoneAction'
import { useEffect, useState } from 'react'
import MultiSelect from 'src/Input/MultiSelect'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'

type Value = string[]

interface Props {
  value?: Value
  onChange: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: React.ReactNode
}

const actionToSelect = ({ geoZoneActionId, name }: GeoZoneAction) => ({
  value: geoZoneActionId.toString(),
  label: name
})

function MultiSelectAction ({
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const actions = useApi(getGeoZoneActions)
  const [initialLoad, setInitialLoad] = useState(true)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectActions = actions.data.mapOrDefault(data => data.items.map(actionToSelect), [])

  useEffect(() => {
    void actions.fetch({ orgId, page: 1, size: 100000 }, token)

    if (initialLoad) {
      setInitialLoad(false)
    } else {
      setValue([])
    }
  }, [])

  return (
    <MultiSelect
      required={required}
      placeholder="Choose action(s)"
      data={selectActions}
      value={value}
      onChange={val => {
        setValue(val)
        onChange(val)
      }}
      clearSearchOnChange={false}
      clearSearchOnBlur
      disabled={actions.loading || disabled}
      searchable={searchable}
      error={error}
    />
  )
}

export default MultiSelectAction
