import { useEffect } from 'react'
import { getSitesWithAlarms, type SiteWithAlarms } from '@venturi-io/api/src/config/site'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import AutoGrid from 'src/Layout/AutoGrid'
import Paper from 'src/Layout/Paper'
import Incidents from 'src/Incidents'
import Nothing from 'src/Nothing'
import Cell from './Cell'

export const Site = (props: SiteWithAlarms) =>
  <Cell key={props.siteId} {...props} />

export default function Sites () {
  const sites = usePaginatedApi(getSitesWithAlarms)
  const { token, orgId } = useUser()

  const loadSitesWithAlarms = (page?: number, size?: number) => {
    const req = {
      orgId,
      page: page ?? sites.page,
      size: size ?? sites.size
    }

    void sites
      .fetch(req, token)
      .finally(() => {
        sites.stopPolling()
        sites.startPolling(req, token, 60)
      })

    return () => {
      sites.stopPolling()
      sites.abort()
    }
  }

  useEffect(() => {
    void loadSitesWithAlarms()
  }, [sites.page])

  return (
    <>
      {sites.data.caseOf<React.ReactNode>({
        Nothing: () => (
          <Nothing
            isLoading={sites.loading}
            nothing={sites.data.isNothing()}
          />
        ),
        Just: data => (
          <>
            <AutoGrid>
              {data.items.map(Site)}
            </AutoGrid>
            <Pagination
              mt="sm"
              value={sites.page}
              total={data.totalPages}
              onChange={sites.setPage}
            />
          </>
        )
      })}

      <Paper mt="md">
        <Incidents />
      </Paper>
    </>
  )
}
