import {
  type DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { createStyles } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  updateEdge,
  Background,
  Controls,
  type Node,
  MiniMap,
  ReactFlowProvider,
  type OnSelectionChangeParams,
  type Connection,
  type Edge
} from 'react-flow-renderer'
import { toProperCase } from 'src/utils/strings'
import { mq } from 'src/utils/style'
import nodeTypes from './Nodes/nodeTypes'
import { nodeStyles } from './Nodes/shared'
import Sidebar from './Sidebar'
import Tools from './Tools'
import EditForm, { type FormData } from './Edit'

const useStyles = createStyles(theme => ({
  wrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '97.5%',
    height: '95%',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  },
  panel: {
    flexGrow: 1,
    height: '100%'
  },
  input: {
    color: 'white',
    background: '#6727AB'
  },
  default: {
    color: 'white',
    background: '#991A97'
  },
  output: {
    color: 'white',
    background: '#CC0A85'
  }
}))

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true
}

const edgeOptions = {
  animated: true,
  style: {
    stroke: 'gray'
  }
}

const getId = () => `dndnode_${Math.random() * 10000}`

const DnDFlow = () => {
  const storeKey = 'advanced-routine-ux'

  const { classes } = useStyles()
  const flowPanelRef = useRef<HTMLDivElement>(null)
  const edgeUpdateSuccessful = useRef(true)
  const [store, setStore] = useLocalStorage({
    key: storeKey,
    defaultValue: ''
  })
  const [editMode, setEditMode] = useState(false)
  const [focusedNode, setFocusedNode] = useState<Node | null>(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const onConnect = useCallback((params: Connection) => setEdges(edges_ => addEdge(params, edges_)), [])

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const reactFlowBounds = flowPanelRef?.current?.getBoundingClientRect()
    const data = event.dataTransfer.getData('application/reactflow')
    const { type, label } = JSON.parse(data)

    if (typeof type === 'undefined' || !type) {
      return
    }

    const newNode: Node = {
      id: getId(),
      type,
      position: {
        x: event.clientX - (reactFlowBounds?.left ?? 0),
        y: event.clientY - (reactFlowBounds?.top ?? 0)
      },
      data: {
        label: toProperCase(label)
      },
      style: nodeStyles[type]
    }

    setNodes(nodes_ => nodes_.concat(newNode))
  }, [])

  const onSelectionChange = useCallback((params: OnSelectionChangeParams) => {
    const { nodes } = params

    setFocusedNode(null)

    // For editing single selected node
    if (nodes.length === 1) {
      setFocusedNode(nodes[0])
    }
  }, [])

  const onUpdateNode = useCallback(({ id, label, textColor, backgroundColor }: FormData) => {
    if (focusedNode) {
      setNodes(nodes_ => (
        nodes_.map(node => {
          if (node.id === id) {
            const customStyle = {
              ...node.style,
              color: textColor,
              background: backgroundColor,
              borderRadius: '5px'
            }

            node.data = {
              ...node.data,
              label,
              customStyle
            }
            node.style = customStyle
          }

          return node
        })
      ))

      setFocusedNode(null)
    }
  }, [focusedNode, setNodes, setFocusedNode])

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false
  }, [])

  const onEdgeUpdate = useCallback((oldEdge: Edge<unknown>, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true
    setEdges(els => updateEdge(oldEdge, newConnection, els))
  }, [])

  const onEdgeUpdateEnd = useCallback((_: unknown, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges(eds => eds.filter(e => e.id !== edge.id))
    }

    edgeUpdateSuccessful.current = true
  }, [])

  const onSaveItems = useCallback(() => {
    setStore(JSON.stringify({ nodes, edges }))
  }, [nodes, edges])

  const onClearItems = useCallback(() => {
    setNodes([])
    setEdges([])
    setStore('')
  }, [nodes, edges])

  const onEditNode = () => setEditMode(!editMode)

  const onDeleteNode = useCallback(() => {
    setNodes(nodes.filter(node_ => node_.id !== focusedNode?.id))
  }, [nodes, focusedNode])

  useEffect(() => {
    if (store) {
      const { nodes, edges } = JSON.parse(store)

      setNodes(nodes)
      setEdges(edges)
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.panel} ref={flowPanelRef}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onSelectionChange={onSelectionChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          onNodesChange={onNodesChange}
          defaultEdgeOptions={edgeOptions}
          proOptions={proOptions}
        >
          <Controls />
          <MiniMap />
          <Background />
        </ReactFlow>
      </div>
      {nodes && (
        <Tools
          editMode={editMode}
          onSaveItems={onSaveItems}
          onClearItems={onClearItems}
          onEditNode={onEditNode}
          onDeleteNode={onDeleteNode}
        />
      )}
      {editMode && focusedNode && (
        <EditForm
          data={{
            id: focusedNode.id,
            label: focusedNode.data.label,
            textColor: focusedNode?.style?.color,
            backgroundColor: focusedNode?.style?.background
          }}
          onUpdate={onUpdateNode}
        />
      )}
    </div>
  )
}

export default function AdvancedRoutine () {
  return (
    <ReactFlowProvider>
      <DnDFlow />
    </ReactFlowProvider>
  )
}
