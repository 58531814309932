import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { removeUsersFromGroup } from '@venturi-io/api/src/userManager/userGroup'
import { type User } from '@venturi-io/api/src/userManager/user'
import { type Actions, type ExtraData } from '.'

const Row = ({ actions, extra, ...user }: User & ListRow<Actions, ExtraData>) => {
  const { token } = useUser()
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const { orgUserId, firstName, lastName } = user
  const { userGroupId } = extra
  const { loadUsers } = actions
  const [showRemove, setShowRemove] = useState(false)
  const removeFromGroup = useApi(removeUsersFromGroup)

  const handleRemoveFromGroup = useCallback(() => {
    void removeFromGroup
      .fetch({
        userGroupId,
        userIds: [orgUserId]
      }, token)
      .finally(() => { void loadUsers() })
  }, [token, orgUserId, userGroupId])

  return (
    <>
      <div className={classes.row}>
        <div className={cx(classes.col, classes.idCol)}>
          <Text size="sm">{orgUserId}</Text>
        </div>
        <div className={classes.col}>
          <TextTooltip
            value={firstName + ' ' + lastName}
            maxLength={30}
            size="sm"
          />
        </div>
        <div className={cx(classes.col, classes.actionsCol)}>
          <ActionIcon
            title="Edit"
            color="green"
            variant="filled"
            onClick={() => {
              navigate(`/settings/users/edit/${orgUserId}`)
            }}
          >
            <FontAwesomeIcon
              size="sm"
              icon={['fas', 'pencil']}
              color="white"
            />
          </ActionIcon>
          <ActionIcon
            title="Remove"
            color="red"
            variant="filled"
            onClick={() => setShowRemove(true)}
            disabled={removeFromGroup.loading}
          >
            <FontAwesomeIcon
              size="lg"
              icon={['fas', 'square-minus']}
              color="white"
            />
          </ActionIcon>
        </div>
      </div>
      <ConfirmModal
        type="delete"
        opened={showRemove}
        title={`Removing "${firstName} ${lastName}" from this group`}
        confirmLabel="Remove"
        loading={removeFromGroup.loading}
        question="Are you sure you want to remove this user from this group? This cannot be undone."
        onClose={() => setShowRemove(false)}
        onCancel={() => setShowRemove(false)}
        onConfirm={handleRemoveFromGroup}
      />
    </>
  )
}

export default Row
