/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Image, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular, uiDateFormatUniversal } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type IdlingTrip } from '@venturi-io/api/src/collector/trip'
import { secondsToDhms } from 'src/Assets/shared'
import { type ParsedIdleEvent } from '..'

const formatToReadableDate = (date?: string) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'
interface Props extends Partial<ParsedIdleEvent> {
  agentName: string
  mapUri: string | null
  data: ParsedIdle[]
}

interface Value {
  value?: string | null
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Event #" />
    <THCell value="Event Date/Time" />
    <THCell value="Location" />
    <THCell value="Geozone" />
    <THCell value="Idle Time" />
  </View>
)

interface LabelValueProps {
  label: string
  value?: string | null
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? 'N/A'}
    </Text>
  </View>
)

interface DetailsProps {
  driver?: string | null
  group?: string | null
  date?: string | null
  count?: string | null
}
const Details = ({
  driver,
  group,
  date,
  count
}: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Driver"
      value={driver}
    />
    <LabelValue
      label="Date/time"
      value={date}
    />
    <LabelValue
      label="Group"
      value={group}
    />
    <LabelValue
      label="Count"
      value={count}
    />
  </View>
)

export interface ParsedIdle extends IdlingTrip {
  location: string
}

interface TableRowProps extends ParsedIdle {
  index: number
}

const TableRow = ({
  index,
  location,
  time,
  idlingDuration,
  geoZones
}: TableRowProps) => {
  const geoZoneName = geoZones.map(({ name }) => name).join(', ')
  return (
    <View style={reportStyles.group}>
      <TDCell value={index.toString()} />
      <TDCell value={formatToReadableDate(time)} />
      <TDCell value={location} />
      <TDCell value={geoZoneName} />
      <TDCell value={secondsToDhms(idlingDuration)} />
    </View>
  )
}

interface TableBodyProps {
  data: ParsedIdle[]
}
const TableBody = ({ data }: TableBodyProps) => data.map((item, idx) => (
  <TableRow
    key={item.id}
    index={idx + 1}
    {...item}
  />
))

const Space = () => <View style={reportStyles.space} />

function AgentIdleHistory ({
  data,
  mapUri,
  agentName,
  driver,
  group,
  date,
  count
}: Props) {
  const title = `${agentName ?? 'Unknown'} - Idle Time Event Report`
  return (
    <ReportTemplate
      title={title}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details
        driver={driver}
        group={group}
        date={dayjs(date).format(uiDateFormatUniversal)}
        count={count}
      />
      <Space />
      <TableHeader />
      <TableBody data={data} />
      {mapUri && (
        <>
          <Space />
          <View style={reportStyles.imageContainer}>
            <Image src={mapUri} style={reportStyles.image} />
          </View>
        </>
      )}
    </ReportTemplate>
  )
}

export default AgentIdleHistory
