import { Container, Group, SimpleGrid, Text } from '@mantine/core'
import { useSharedStyles, type Sensors, type SensorType } from '../shared'
import SensorBars from './SensorBars'
import { MODULE_CONTENT } from './constants'

const sensorTypeGroup: SensorType[] = [
  'S1_VOLTAGE_L1N',
  'S1_VOLTAGE_L2N',
  'S1_VOLTAGE_L3N'
]

export default function SVoltageA ({
  sensors
}: Sensors) {
  const { classes } = useSharedStyles()

  return (
    <Container className={classes.dock} fluid>
      <Group px={15} className={classes.contentTitle} position="apart" noWrap>
        <Text size="sm" weight={500}>
          {MODULE_CONTENT.S1_VOLTAGE.toUpperCase()}
        </Text>
      </Group>

      <SimpleGrid
        cols={1}
        spacing="xl"
        px={15}
        className={classes.center}
        style={{
          width: '95%'
        }}
      >
        <SensorBars
          voltName="S1"
          sensorTypes={sensorTypeGroup}
          sensors={sensors}
        />
      </SimpleGrid>
    </Container>
  )
}
