import { type CSSProperties, useCallback } from 'react'
import {
  createStyles,
  Button,
  Box,
  ColorInput,
  Stack
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import { mq } from 'src/utils/style'

const useStyles = createStyles(theme => ({
  container: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    padding: '15px',
    background: 'white',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 17.22447395324707px 0px',
    zIndex: 6,
    [mq(theme.breakpoints.md)]: {
      left: '250px'
    }
  }
}))

export interface FormData {
  id: string
  label: string
  textColor: CSSProperties['color']
  backgroundColor: CSSProperties['background']
}

interface Props {
  data: FormData
  onUpdate?: (data: FormData) => void
}

export default function Edit ({ data: formData, onUpdate }: Props) {
  const { classes } = useStyles()

  const form = useForm<FormData>({
    initialValues: { ...formData }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    if (onUpdate) {
      onUpdate(values)
    }
  }, [form.values])

  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <TextInput
            name="label"
            label="Label"
            placeholder="Label for node"
            {...form.getInputProps('label')}
          />
          <ColorInput
            name="textColor"
            label="Text Color"
            placeholder="Pick a color"
            {...form.getInputProps('textColor')}
          />
          <ColorInput
            name="backgroundColor"
            label="Background Color"
            placeholder="Pick a color"
            {...form.getInputProps('backgroundColor')}
          />
          <Button type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
