import { type Alarm } from '@venturi-io/api/src/analytics/notification'
import Default from './Default'
import Speed from './Speed'
import Geozone from './Geozone'

export interface NotificationItemProps extends Alarm {
  showClear?: boolean
  expanded?: boolean
  onRead?: (incident: Alarm) => void
  closeDrawer?: () => void
}

export default function NotificationItem (props: NotificationItemProps) {
  const { type } = props
  switch (type) {
    case 'SPEED_ALARM':
      return <Speed {...props} />
    case 'GEO_ZONE_ALARM':
      return <Geozone {...props} />
    default:
      return <Default {...props} />
  }
}
