import { IconArrowsDiff, IconBell, IconDroplet } from '@tabler/icons-react'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import AssetTemplate from 'src/AssetTemplate'
import { type DiagnosticMetricItem } from 'src/AssetTemplate/Components/DiagnosticMetric'
import { convertStrToFloat, findSensor } from 'src/AssetTemplate/shared'
import NotFound from 'src/Router/NotFound'
import { useUser } from 'src/UserContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import dayjs from 'dayjs'
import { getTankTransactions } from '@venturi-io/api/src/config/fms'
import { dateFormat } from 'src/utils/dates'
import { formatNumber } from 'src/utils/numbers'
import Nothing from 'src/Nothing'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import Transactions from './Transactions'
import Statuses from './Statuses'
import TankStatus from './TankStatus'
import CapacityHistory from './CapacityHistory'

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

export default function Dashboard () {
  const { assetId } = useParams<RouteParams>()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const agent = agentDetails.data.mapOrDefault(data => data, null)
  const outTransactions = usePaginatedApi(getTankTransactions)
  const inTransactions = usePaginatedApi(getTankTransactions)
  const getTransactions = usePaginatedApi(getTankTransactions)
  const tankOutTransactions = outTransactions.data.mapOrDefault(({ items }) => items, [])
  const tankInTransactions = inTransactions.data.mapOrDefault(({ items }) => items, [])
  const [alarms, setAlarms] = useState<Alarm[] | null>()

  const loadOutTransactions = async (page?: number, size?: number): Promise<void> => {
    void outTransactions.fetch({
      agentId: Number(assetId),
      page: page ?? outTransactions.page,
      size: size ?? outTransactions.size,
      sort: 'imported',
      order: 'desc',
      type: 'OUT',
      startTime: `${dayjs(new Date()).subtract(3, 'days').format(dateFormat)}`,
      endTime: `${dayjs(new Date()).format(dateFormat)}`
    }, token)
  }

  useEffect(() => {
    void loadOutTransactions()
  }, [outTransactions.page])

  const loadInTransactions = async (page?: number, size?: number): Promise<void> => {
    void inTransactions.fetch({
      agentId: Number(assetId),
      page: page ?? inTransactions.page,
      size: size ?? inTransactions.size,
      sort: 'imported',
      order: 'desc',
      type: 'IN',
      startTime: `${dayjs(new Date()).subtract(3, 'days').format(dateFormat)}`,
      endTime: `${dayjs(new Date()).format(dateFormat)}`
    }, token)
  }

  useEffect(() => {
    void loadInTransactions()
  }, [inTransactions.page])

  useEffect(() => {
    void getTransactions.fetch({
      agentId: Number(assetId),
      page: 1,
      size: 4,
      sort: 'imported',
      order: 'desc'
    }, token)
  }, [assetId])

  const getAllOutVol = tankOutTransactions.map(trans => trans.volume)
  const getAllInVol = tankInTransactions.map(trans => trans.volume)
  const totalOut = formatNumber(Math.floor(getAllOutVol.reduce((a, b) => a + b, 0)))
  const totalIn = formatNumber(Math.floor(getAllInVol.reduce((a, b) => a + b, 0)))

  if (typeof assetId === 'undefined' || isNaN(parseInt(assetId))) {
    return <NotFound />
  }

  useEffect(() => {
    void agentDetails.fetch({ agentId: parseInt(assetId) }, token)
  }, [])

  if (agent === null) {
    return <div></div>
  }

  const {
    agentId,
    agentName,
    assetType,
    metadata,
    geoLocation,
    sensors,
    lastSeenTime,
    connectionStatus
  } = agent

  // Tank sensors
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const fuelCapacity = findSensor('FUEL_CAPACITY', sensors)
  const fuelCapacityLiter = convertStrToFloat(fuelCapacity?.currentValue)

  const fuelLevelPercent = fuelLevelLiter
    ? Math.round(Number(fuelLevelLiter?.currentValue) / Number(fuelCapacityLiter) * 100)
    : 0

  const fuelRemainingPercent = 100 - fuelLevelPercent
  const getCurrFuelVal = (fuelLevelPercent / 100) * fuelCapacityLiter

  const getRemainingLiters = () => {
    let liters = 0
    const sortedAlarms = alarms?.sort((a, b) => Number(b.setPoint) - Number(a.setPoint))
    if (sortedAlarms) {
      for (let i = 0; i < sortedAlarms.length; i++) {
        if (getCurrFuelVal > sortedAlarms[i].setPoint) {
          liters = getCurrFuelVal - sortedAlarms[i].setPoint
          break
        } else if (getCurrFuelVal <= sortedAlarms[i].setPoint) {
          liters = getCurrFuelVal
        } else {
          liters = getCurrFuelVal - sortedAlarms[i].setPoint
        }
      }
    }

    return liters
  }

  const diagnosticMetrics: DiagnosticMetricItem[] = [
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Level',
      icon: <IconDroplet size={16} />,
      value: fuelLevelPercent,
      displayValue: fuelLevelPercent.toString(),
      displayValueUnit: '%',
      label: `${formatNumber(Number(fuelLevelLiter?.currentValue)) ?? 0} L / ${formatNumber(fuelCapacityLiter)} L`
    },
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Transaction Totals',
      icon: <IconArrowsDiff size={16} />,
      value: fuelLevelPercent,
      displayValue: totalIn?.toString() ?? '',
      displayValueUnit: 'L',
      label: 'Inwards',
      lowerLabel: 'Last 3 days',
      disableChart: true
    },
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Transaction Totals',
      icon: <IconArrowsDiff size={16} />,
      value: fuelLevelPercent,
      displayValue: totalOut?.toString() ?? '',
      displayValueUnit: 'L',
      label: 'Outwards',
      lowerLabel: 'Last 3 days',
      disableChart: true
    },
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Liters until next alarm',
      icon: <IconBell size={16} />,
      value: fuelLevelPercent,
      displayValue: `${formatNumber(Math.floor(Number(getRemainingLiters())))}`,
      displayValueUnit: 'L',
      disableChart: true
    }
  ]

  return (
    // TODO: Add fuelType in AssetTemplate & TankStatus props
    // TODO: Add connectivityDuration once available in the BE data
    <AssetTemplate
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      metadata={metadata}
      geoLocation={geoLocation}
      diagnosticMetrics={diagnosticMetrics}
      statuses={(
        <Statuses alarms={sensors.filter(sensor => sensor.alarmStatus === 'ALARM').length} />
      )}
      connectivityStatus={connectionStatus === 'ONLINE'
        ? 'CONNECTED'
        : 'DISCONNECTED'}
      totalAssetRuntime=""
      lastDataTransfer={lastSeenTime}
    >
      <TankStatus
        fuelCapacity={fuelCapacity}
        fuelConsumption={fuelConsumption}
        fuelLevel={fuelLevel}
        fuelLevelPercent={fuelLevelPercent}
        fuelRemainingPercent={fuelRemainingPercent}
        fuelLevelLiter={fuelLevelLiter}
        fuelCapacityLiter={fuelCapacityLiter}
        setAlarms={setAlarms}
      />
      {sensors.length > 0 && (
        <CapacityHistory sensors={sensors} />
      )}
      {getTransactions.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={getTransactions.loading}
            nothing={getTransactions.data.isNothing()}
          />
        ),
        Just: ({ items }) => <Transactions transactions={items} />
      })}
    </AssetTemplate>
  )
}
