import { useState } from 'react'
import {
  Paper,
  Text,
  Group,
  Button,
  Textarea,
  Stack
} from '@mantine/core'
import dayjs from 'dayjs'
import { type Comment } from '@venturi-io/api/src/config/task'
import ActionButton from 'src/ActionButton'
import ConfirmModal from 'src/Layout/ConfirmModal'

interface CommentProps {
  item: Comment
  key: number
  onEdit: (comment: Comment) => void
  onDelete: (commentId: number) => void
  orgUserId: number
  onSave: (comment: Comment, newContent: string) => void
  width: number
}

const ListComment = ({
  item,
  key,
  onDelete,
  orgUserId,
  onSave,
  width
}: CommentProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedContent, setEditedContent] = useState(item.content)
  const [showDelete, setShowDelete] = useState(false)
  const maxLength = 512

  const handleSave = () => {
    onSave(item, editedContent)
    setIsEditing(false)
  }

  const handleDelete = (commentId: number) => {
    onDelete(commentId)
  }

  return (
    <Paper
      shadow="xs"
      p="xs"
      key={key}
      withBorder
      style={{ marginBottom: '5px' }}
      w="100%"
    >
      <Text size="xs" fw={500}>
        {`${item.orgUser.firstName} ${item.orgUser.lastName} - ${dayjs(item.createdAt).fromNow()} ${item.edited
          ? '[edited]'
          : ''}`}
      </Text>
      {isEditing
        ? (
          <Stack>
            <Textarea
              maxLength={512}
              size="xs"
              value={editedContent}
              onChange={(event) => setEditedContent(event.currentTarget.value)}
              minRows={2}
            />
            <Text
              size="xs"
              color={editedContent.length === maxLength
                ? 'red'
                : 'gray'}
              style={{ marginTop: '-15px' }}
            >
              {editedContent.length}
              /
              {maxLength}
              {' '}
              characters
            </Text>
            <Group position="right" spacing="xs">
              <Button
                size="xs"
                variant="outline"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button
                size="xs"
                color="green"
                variant="outline"
                onClick={handleSave}
              >
                Save
              </Button>
            </Group>
          </Stack>
          )
        : (
          <>
            <Text
              size="xs"
              sx={{
                inlineSize: width - 30,
                overflowWrap: 'break-word'
              }}
            >
              {item.content}
            </Text>
            {orgUserId === item.orgUser.id && (
              <Group position="right" spacing="xs">
                <ActionButton
                  label="Edit"
                  icon="pencil"
                  iconType="fas"
                  onClick={() => setIsEditing(true)}
                  actionColor="green"
                  actionVariant="filled"
                  iconColor="white"
                  iconSize="xs"
                />
                <ActionButton
                  label="Delete"
                  icon="trash-can"
                  iconType="fas"
                  onClick={() => setShowDelete(true)}
                  actionColor="red"
                  actionVariant="filled"
                  iconColor="white"
                  iconSize="xs"
                  actionDisabled={false}
                />
              </Group>
            )}
          </>
          )}

      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={'Deleting task comment'}
        question="Are you sure you want to delete this comment? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => handleDelete(item.id)}
      />
    </Paper>
  )
}

export default ListComment
