import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Asset Name
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Status
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Battery Voltage
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          External Power Source Voltage
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Position Direction
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Position Speed
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Actions
        </Text>
      </div>
    </div>
  )
}

export default Header
