import { z } from 'zod'
import { createStatefulApi } from '../configure'
import { type SensorInstanceIdRequest, valueMap } from '../shared'

const sensorInstance = z.object({
  sensorId: z.number(),
  sensorInstanceId: z.number(),
  agentId: z.number(),
  name: z.string(),
  primaryFunction: z.boolean(),
  systemOnly: z.boolean(),
  showGraph: z.boolean(),
  valueMap,
  minValue: z.optional(z.number()),
  maxValue: z.optional(z.number()),
  iconStyle: z.optional(z.string()),
  pollInterval: z.optional(z.number())
})

type SensorInstance = z.infer<typeof sensorInstance>

export interface CreateSensorInstanceRequest extends Record<string, unknown> {
  agentId: number
  sensorId: number
  name: string
  sensorDataTransformerId?: number
  iconStyle?: string
  lowerLimit?: number
  upperLimit?: number
}
export const createSensorInstance = createStatefulApi<CreateSensorInstanceRequest, typeof sensorInstance>(
  'config',
  '/sensorInstance',
  { method: 'POST' },
  sensorInstance
)

export const updateSensorInstance = createStatefulApi<SensorInstance, typeof sensorInstance>(
  'config',
  '/sensorInstance/:sensorInstanceId',
  { method: 'PUT' },
  sensorInstance
)

export const deleteSensorInstance = createStatefulApi<SensorInstanceIdRequest, z.ZodBoolean>(
  'config',
  '/sensorInstance/:sensorInstanceId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

interface AlarmActionAssignmentRequest extends SensorInstanceIdRequest {
  alarmActionIds: number[]
}

export const assignAlarmActionsToSensorInstance = createStatefulApi<AlarmActionAssignmentRequest, z.ZodBoolean>(
  'config',
  '/sensor-instances/:sensorInstanceId/alarm-actions',
  { method: 'POST' },
  z.boolean()
)

export const removeAlarmActionsFromSensorInstance = createStatefulApi<AlarmActionAssignmentRequest, z.ZodBoolean>(
  'config',
  '/sensor-instances/:sensorInstanceId/alarm-actions',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
