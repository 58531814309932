import {
  createStyles,
  Box,
  Col,
  Container,
  Group,
  Grid,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import Map from 'src/Maps/GeoZoneMap'
import { mq, getBatteryLevelColor } from 'src/utils/style'
import { checkIfAlarm } from 'src/utils/status'
import { type PowerStatus, findSensorByType, powerStatusColor, useSharedStyles } from '../shared'
import GroupBoxValue from '../shared/GroupBoxValue'

const useStyles = createStyles(theme => ({
  dock: {
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: '92%'
  },
  grid: {
    width: '100%',
    height: '100%'
  },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '400px',
    [mq(theme.breakpoints.md)]: {
      height: '100%'
    }
  },
  detailsContainer: {
    height: '100%',
    paddingBottom: '15px',
    [mq(theme.breakpoints.md)]: {
      paddingBottom: '0px'
    }
  },
  detailsGroup: {
    height: '50%'
  }
}))

interface TextSizes {
  valueSize: React.CSSProperties['fontSize']
  labelSize: React.CSSProperties['fontSize']
}

interface Props {
  agentId: AgentDetails['agentId']
  agentName: AgentDetails['agentName']
  sensors: AgentDetails['sensors']
  geoLocation: AgentDetails['geoLocation']
  alarmStatus: AgentDetails['alarmStatus']
  assignedTo: AgentDetails['assignedTo']
}

export default function PddDetails ({
  agentId,
  agentName,
  sensors,
  alarmStatus,
  assignedTo
}: Props) {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useSharedStyles()
  const { ref, height, width } = useElementSize()
  const powerStatus = findSensorByType('POWER_STATUS', sensors)
  const batteryLevel = findSensorByType('BATTERY', sensors)
  const powerStatusVal = powerStatus?.currentValue as PowerStatus | undefined ?? 'OFF'
  const isAlert = checkIfAlarm(alarmStatus)
  const alertColor = isAlert ? 'red' : 'gray'
  const gridSizeSum = width + height
  const textSizes: TextSizes = {
    valueSize: `${gridSizeSum / 60}px`,
    labelSize: `${gridSizeSum / 70}px`
  }

  return (
    <Container ref={ref} className={classes.container} fluid>
      <Group
        className={sharedClasses.contentTitle}
        position="apart"
        noWrap
      >
        <Text size="sm" weight={500}>{agentName.toUpperCase()}</Text>
      </Group>
      <Grid className={classes.grid}>
        <Col span={12} md={6}>
          <Box className={classes.mapContainer}>
            <Map
              selectedAgentId={agentId}
              options={{
                embedded: true
              }}
            />
          </Box>
        </Col>
        <Col span={12} md={6}>
          <Stack
            className={classes.detailsContainer}
            justify="stretch"
            spacing="xs"
          >
            <Group className={classes.detailsGroup} position="apart" noWrap>
              <GroupBoxValue
                label="Power Status"
                value={powerStatusVal}
                color={powerStatusColor[powerStatusVal]}
                {...textSizes}
              />
              <GroupBoxValue
                label="Battery Level"
                value={batteryLevel?.currentValue
                  ? `${batteryLevel.currentValue} ${batteryLevel.unit}`
                  : '0%'}
                color={batteryLevel?.currentValue
                  ? getBatteryLevelColor(Number(batteryLevel.currentValue))
                  : 'gray'}
                {...textSizes}
              />
            </Group>
            <Group className={classes.detailsGroup} position="apart" noWrap>
              <Paper
                className={classes.dock}
                p="xs"
                shadow="xs"
              >
                <Stack
                  className={classes.dock}
                  align="center"
                  justify="center"
                  spacing={-5}
                >
                  <FontAwesomeIcon
                    color={alertColor}
                    icon={['fas', 'warning']}
                    size="3x"
                  />
                  <Text
                    sx={{
                      fontSize: textSizes.labelSize
                    }}
                    color={alertColor}
                    size="xs"
                  >
                    {alarmStatus}
                  </Text>
                </Stack>
              </Paper>
              <GroupBoxValue
                label="Assignee"
                value={assignedTo ?? '(none)'}
                color="gray"
                {...textSizes}
              />
            </Group>
          </Stack>
        </Col>
      </Grid>
    </Container>
  )
}
