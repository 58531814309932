import { useCallback, useEffect, useState } from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type CameraEvent,
  getAllCameraEvents,
  type EventType,
  eventTypes
} from '@venturi-io/api/src/config/cameraEvents'

import { useMockPaginatedApi } from 'src/utils/useApi'

import ActionList from 'src/Layout/ActionList'
import { type PaginatedResponse } from '@venturi-io/api'
import { useUser } from 'src/UserContext'
import { Col, Grid, Paper, TextInput } from '@mantine/core'
import Select from 'src/Input/Select'
import dayjs from 'dayjs'
import DateRangePicker from 'src/Input/DateRangePicker'
import { Card, Header, Row } from './List'
import mockIssues_ from './mockData.json'

interface MockCameraEvents extends PaginatedResponse {
  items: CameraEvent[]
}

const _mockIssues = mockIssues_ as MockCameraEvents

export default function CameraEvents () {
  // TODO: Replace with usePaginatedApi once the API is ready.
  const events = useMockPaginatedApi(getAllCameraEvents, _mockIssues)
  const [tagPoiLpr, setTagPoiLpr] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(tagPoiLpr, 700)
  const [eventType, setEventType] = useState<EventType | 'ALL'>('ALL')

  const dateToday = new Date()
  const [value, setValue] = useState<[Date, Date]>([
    dayjs(dateToday).subtract(7, 'days').toDate(),
    dayjs(dateToday).toDate()
  ])

  const { token } = useUser()

  const loadEvents = async (page?: number, size?: number): Promise<void> => {
    void events.fetch({
      page: page ?? events.page,
      size: size ?? events.size,
      tag: debouncedSearch,
      poi: debouncedSearch,
      lpr: debouncedSearch,
      eventType: eventType !== 'ALL' ? eventType : undefined
    }, token)
  }

  const onChange = useCallback(([first, second]: [Date, Date]) => {
    const result: [Date, Date] = [...value]
    const firstIsNull = first === null
    const secondIsNull = second === null
    const isSameFirstDate = dayjs(first).diff(value[0], 'days') === 0
    const isSameSecondDate = dayjs(second).diff(value[1], 'days') === 0

    if (isSameFirstDate && isSameSecondDate) return

    if (!firstIsNull) {
      result[0] = first
    }

    if (!secondIsNull) {
      result[1] = second
    }

    setValue(result)
  }, [value, events])

  // TODO: Add dependecy page here once paginated API is ready.
  useEffect(() => {
    void loadEvents(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadEvents()
  }, [events.page])

  return (
    <>
      <Paper mt="lg" p="sm" shadow="sm" style={{ marginTop: '0' }}>
        <Grid grow>
          <Col span={12} md={5}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search event here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setTagPoiLpr(e.target.value)}
            />
          </Col>
          <Col span={12} md={2}>
            <DateRangePicker
              placeholder="Choose date range"
              value={value}
              onChange={onChange}
            />
          </Col>
          <Col span={12} md={2}>
            <Select
              placeholder="Filter by Event Type"
              data={['ALL', ...eventTypes]}
              onChange={(value: EventType) => setEventType(value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={events.loading}
        data={events.data.mapOrDefault(({ items }) => items, [])}
        getId={({ eventId }) => eventId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={events.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={events.page}
        onPageChange={events.setPage}
      />
    </>
  )
}
