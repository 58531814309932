import { type Task } from '@venturi-io/api/src/config/task'
import Attachments from './Attachments/index'
import { MODULE_CONTENT } from './constants'
import Asset from './Asset'
import Description from './Description'
import Comments from './Comments/index'

interface Props {
  tasks: Task
  contentKey: string
}

export default function ModuleContainer ({
  tasks,
  contentKey
}: Props) {
  const { attachments, id } = tasks

  switch (contentKey) {
    case MODULE_CONTENT.TASK_DETAILS:
      return <Asset tasks={tasks} />
    case MODULE_CONTENT.TASK_DESCRIPTION:
      return <Description tasks={tasks} />
    case MODULE_CONTENT.TASK_COMMENTS: {
      return <Comments taskId={id} />
    }
    case MODULE_CONTENT.TASK_ATTACHMENTS: {
      return <Attachments attachments={attachments} />
    }
    default:
      return null
  }
}
