import {
  createStyles,
  Box,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistorySparkLineChart from 'src/AssetTemplate/Components/Charts/SensorHistorySparkLineChart'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { mq } from 'src/utils/style'
import { getSensorValue } from 'src/AssetTemplate/shared'
import { type VoltageSensors } from '..'
import VoltageBar from './VoltageBar'

const useStyles = createStyles((theme) => ({
  container: {
    margin: '0 auto',
    color: '#212121',
    padding: '24px 28px',
    [mq(theme.breakpoints.md)]: {
      padding: '32px 44px'
    }
  },
  itemContainer: {
    position: 'relative'
  },
  groupContainer: {
    width: '100%'
  },
  chartContainer: {
    minWidth: 0,
    width: 64
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: 8
  }
}))

interface Props {
  outputPower: Sensor | null
  powerFactor: Sensor | null
  powerFrequency: Sensor | null
  voltageLN: VoltageSensors
  voltageLL: VoltageSensors
  voltageCurrent: VoltageSensors
}

export default function PowerDetails ({
  outputPower,
  powerFactor,
  powerFrequency,
  voltageLN,
  voltageLL,
  voltageCurrent
}: Props) {
  const { classes } = useStyles()
  const outputPowerHasAlarm = outputPower?.alarmStatus === 'ALARM'
  const powerFactorHasAlarm = powerFactor?.alarmStatus === 'ALARM'
  const powerFrequencyHasAlarm = powerFrequency?.alarmStatus === 'ALARM'

  return (
    <Box className={classes.container}>
      <Stack align="center" justify="stretch" spacing={16}>
        <Group
          className={classes.groupContainer}
          position="apart"
          align="stretch"
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Total Output Power
            </Text>
            <Group>
              <Text size={24} weight={600}>
                {`${getSensorValue(outputPower, true)} kw`}
              </Text>
              <Box className={classes.chartContainer}>
                {outputPower?.sensorInstanceId && outputPower?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={outputPower.sensorInstanceId}
                    height={50}
                  />
                )}
              </Box>
            </Group>
            {outputPowerHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Power Factor
            </Text>
            <Group>
              <Text size={24} weight={600}>
                {getSensorValue(powerFactor, true)}
              </Text>
              <Box className={classes.chartContainer}>
                {powerFactor?.sensorInstanceId && powerFactor?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={powerFactor.sensorInstanceId}
                    height={50}
                  />
                )}
              </Box>
            </Group>
            {powerFactorHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
        </Group>
        <Group
          className={classes.groupContainer}
          position="apart"
          align="stretch"
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Frequency
            </Text>
            <Group>
              <Text size={24} weight={600}>
                {`${getSensorValue(powerFrequency, true)} Hz`}
              </Text>
              <Box className={classes.chartContainer}>
                {powerFrequency?.sensorInstanceId && powerFrequency?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={powerFrequency.sensorInstanceId}
                    height={50}
                  />
                )}
              </Box>
            </Group>
            {powerFrequencyHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack spacing={4}>
            <Text
              color="#A6A7AB"
              size={12}
              weight={500}
            >
              Total Energy Produced
            </Text>
            {/* TODO: Compute total energy produced */}
            <Text
              color="#A6A7AB"
              size={24}
              weight={600}
            >
              n/a
            </Text>
          </Stack>
        </Group>
      </Stack>
      <Group mt={40} position="apart">
        <Stack align="center" spacing={20}>
          <Text
            color="#5C5F66"
            size={14}
            weight={500}
          >
            Voltage L-N
          </Text>
          <Stack align="center" spacing={8}>
            <VoltageBar label="A" sensor={voltageLN.a} />
            <VoltageBar label="B" sensor={voltageLN.b} />
            <VoltageBar label="C" sensor={voltageLN.c} />
          </Stack>
        </Stack>
        <Stack align="center" spacing={16}>
          <Text
            color="#5C5F66"
            size={14}
            weight={500}
          >
            Voltage L-L
          </Text>
          <Stack align="center" spacing={8}>
            <VoltageBar label="A" sensor={voltageLL.a} />
            <VoltageBar label="B" sensor={voltageLL.b} />
            <VoltageBar label="C" sensor={voltageLL.c} />
          </Stack>
        </Stack>
        <Stack align="center" spacing={16}>
          <Text
            color="#5C5F66"
            size={14}
            weight={500}
          >
            Current
          </Text>
          <Stack align="center" spacing={8}>
            <VoltageBar label="A" sensor={voltageCurrent.a} />
            <VoltageBar label="B" sensor={voltageCurrent.b} />
            <VoltageBar label="C" sensor={voltageCurrent.c} />
          </Stack>
        </Stack>
      </Group>
    </Box>
  )
}
