import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon } from '@mantine/core'
import {
  type Cell,
  type CellTemplate,
  type Compatible,
  getCellProperty,
  type Uncertain
} from '@silevis/reactgrid'
import ControlTooltip from './ControlTooltip'

export interface DeleteCell extends Cell {
  type: 'delete'
  disabled: boolean
  onClick?: () => void
}

export class DeleteRowCell implements CellTemplate<DeleteCell> {
  getCompatibleCell (uncertainCell: Uncertain<DeleteCell>): Compatible<DeleteCell> {
    let disabled: boolean
    try {
      disabled = getCellProperty(uncertainCell, 'disabled', 'boolean')
    } catch {
      disabled = true
    }
    return { ...uncertainCell, text: 'delete', value: -1, disabled }
  }

  render (
    cell: Compatible<DeleteCell>
  ): React.ReactNode {
    return (
      <ControlTooltip disabled={cell.disabled} label="Delete" position="right">
        <ActionIcon disabled={cell.disabled} onClick={cell.onClick}>
          <FontAwesomeIcon icon={['fas', 'trash']} color="dark" />
        </ActionIcon>
      </ControlTooltip>
    )
  }
}
