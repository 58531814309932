import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Text } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { removeAgentsFromGroup } from '@venturi-io/api/src/config/agentGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { truncateWithEllipsis } from 'src/utils/strings'
import { type Actions, type ExtraData } from '.'

export default function Row ({
  actions,
  extra,
  ...agent
}: Agent & ListRow<Actions, ExtraData>) {
  const { token } = useUser()
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const { agentId, agentName } = agent
  const { agentGroupId } = extra
  const { loadAgents } = actions
  const removeFromGroup = useApi(removeAgentsFromGroup)
  const [showRemove, setShowRemove] = useState(false)

  const handleRemoveFromGroup = useCallback(() => {
    void removeFromGroup
      .fetch({
        agentGroupId,
        agentIds: [agentId]
      }, token)
      .finally(() => { void loadAgents() })
  }, [token, agentId, agentGroupId])

  return (
    <>
      <div className={classes.row}>
        <div className={cx(classes.col, classes.idCol)}>
          <Text size="sm">{agentId}</Text>
        </div>
        <div className={classes.col}>
          <TextTooltip value={agentName} maxLength={30} size="sm" />
        </div>
        <div className={cx(classes.col, classes.actionsCol)}>
          <ActionIcon
            title="Edit"
            color="green"
            variant="filled"
            onClick={() => navigate(`/settings/agents/${agentId}`)}
          >
            <FontAwesomeIcon size="sm" icon={['fas', 'pencil']} color="white" />
          </ActionIcon>
          <ActionIcon
            title="Remove"
            color="red"
            variant="filled"
            onClick={() => setShowRemove(true)}
            disabled={removeFromGroup.loading}
          >
            <FontAwesomeIcon size="lg" icon={['fas', 'square-minus']} color="white" />
          </ActionIcon>
        </div>
      </div>
      <ConfirmModal
        type="delete"
        opened={showRemove}
        title={`Removing "${truncateWithEllipsis(agentName, 23)}" from this group`}
        confirmLabel="Remove"
        loading={removeFromGroup.loading}
        question="Are you sure you want to remove this agent from this group? This cannot be undone."
        onClose={() => setShowRemove(false)}
        onCancel={() => setShowRemove(false)}
        onConfirm={handleRemoveFromGroup}
      />
    </>
  )
}
