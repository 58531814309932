/* eslint-disable react/jsx-curly-newline */
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  createStyles,
  Alert,
  Anchor,
  Button,
  Center,
  Paper,
  Stack,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { sendPasswordResetEmail } from '@venturi-io/api/src/userManager/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { getHost } from 'src/utils/host'
import MainLogo from 'src/MainLogo'

interface Props {
  orgId: number
}

const useStyles = createStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.primary[6],
    background: `linear-gradient(to bottom right, ${theme.colors.brand[0]}, ${theme.colors.brand[1]})`
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  form: {
    width: '25vw',
    minWidth: '400px'
  },
  formText: {
    color: theme.colors.primary[7]
  },
  test: {
    '&:focus': {
      border: 'none'
    }
  }
}))

const AccountVerification = ({ orgId }: Props) => {
  const sendResetLink = useApi(sendPasswordResetEmail, false)
  const navigate = useNavigate()
  const { classes } = useStyles()
  const orgUrl = getHost()

  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: value => value.length < 1 ? 'Email Address is required' : null
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    void sendResetLink.fetch({
      ...values,
      orgUrl,
      orgId
    }, '')
  }, [form.values])

  useEffect(() => {
    sendResetLink.data.ifJust((res) => {
      if (res) {
        showNotification({
          title: 'Success',
          message: 'Password reset link has been successfully sent to your email',
          color: 'green'
        })

        navigate('/')
      } else {
        showNotification({
          title: 'Error',
          message: 'Failed to send password reset link',
          color: 'red'
        })
      }
    })
  }, [sendResetLink.data])

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
          <Paper shadow="sm" radius="md" py={65} px={35}>
            <Stack align="stretch" justify="center" spacing="sm">
              <Center>
                <MainLogo orgId={orgId} />
              </Center>
              <Center>
                <Title order={2} mt="sm" mb="lg">ACCOUNT VERIFICATION</Title>
              </Center>
              <TextInput
                withAsterisk
                label="Email Address"
                placeholder="yourvalidemail@sample.com"
                icon={<FontAwesomeIcon icon={['far', 'envelope']} size="sm" color="gray" />}
                {...form.getInputProps('email')}
                classNames={{
                  label: classes.formText,
                  input: classes.test
                }}
              />
              <Alert
                icon={<FontAwesomeIcon icon={['far', 'circle-info']} size="1x" />}
                color="gray"
                sx={{
                  label: {
                    fontStyle: 'normal'
                  }
                }}
              >
                <Text color="gray" size="xs">
                  If the email address entered is valid, you will receive a password reset link shortly
                </Text>
              </Alert>
              <Button
                type="submit"
                color="primary"
                mt={5}
                leftIcon={<FontAwesomeIcon icon={['fas', 'paper-plane']} color="white" />}
                disabled={sendResetLink.loading}
              >
                Send Password Reset Link
              </Button>
              <Center>
                <Anchor
                  color="primary"
                  mt="lg"
                  size="sm"
                  href="/"
                  target="_self"
                >
                  Back to Login
                </Anchor>
              </Center>
            </Stack>
          </Paper>
        </form>
      </div>
    </div>
  )
}

export default AccountVerification
