import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack } from '@mantine/core'
import { type GeoZoneAction } from '@venturi-io/api/src/config/geoZoneAction'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'

export default function Card ({
  geoZoneActionId,
  name,
  action,
  description,
  userGroup: {
    name: userGroupName
  }
}: GeoZoneAction & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={geoZoneActionId.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/settings/geozone-actions/edit/${geoZoneActionId}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup label="Name" value={name} />
        <TextGroup label="Action" value={action} />
        <TextGroup label="Description" value={description} />
        <TextGroup label="User group" value={userGroupName ?? '-'} />
      </Stack>
    </Paper>
  )
}
