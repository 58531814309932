import { ActionIcon, Group, Stack, Text } from '@mantine/core'
import { IconClock } from '@tabler/icons-react'

interface Props {
  duration: string
}

export default function Runtime ({ duration }: Props) {
  return (
    <Stack
      spacing={4}
      align="flex-start"
      sx={{
        width: '100%'
      }}
    >
      <Group spacing={4} position="center">
        <ActionIcon
          variant="transparent"
          size="xs"
          color="dark.2"
          sx={{
            pointerEvents: 'none'
          }}
        >
          <IconClock size={16} />
        </ActionIcon>
        <Text size={12} weight={500} color="#373A40">
          Total Asset Runtime
        </Text>
      </Group>
      <Text ml={24} size={12} weight={500}>
        {duration}
      </Text>
    </Stack>
  )
}
