import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import Bar from 'src/Charts/Bar'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import {
  convertStrToFloat,
  computeRelativePercentage,
  getSensorValue
} from 'src/AssetTemplate/shared'

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    minWidth: 0
  },
  barContainer: {
    position: 'relative',
    minWidth: 0,
    width: 52,
    ':before': {
      content: '""',
      position: 'absolute',
      right: 0,
      background: '#C1C2C5',
      height: 16,
      width: 2,
      zIndex: 1
    }
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: -6
  }
}))

interface Props {
  label: string
  sensor: Sensor | null
}

export default function VoltageBar ({ label, sensor }: Props) {
  const { classes } = useStyles()
  const voltagePercent = sensor
    ? Math.floor(computeRelativePercentage(
      convertStrToFloat(sensor.currentValue),
      sensor.minValue,
      sensor.maxValue
    ))
    : 0
  const withAlarm = sensor?.alarmStatus === 'ALARM'
  const barColor = withAlarm
    ? '#FFE066'
    : '#12B886'

  return (
    <Stack className={classes.container} spacing={0}>
      {withAlarm && (
        <Box className={classes.alarmIndicatorContainer}>
          <AlarmIndicator size="sm" />
        </Box>
      )}

      <Group position="apart" spacing={8}>
        <Text
          size={12}
          weight={500}
          color="#212121"
        >
          {label}
        </Text>
        <Box className={classes.barContainer}>
          <Bar
            height={16}
            data={[
              {
                sensor: sensor?.name ?? '-',
                value: voltagePercent,
                remainingValue: 100 - voltagePercent
              }
            ]}
            keys={['value', 'remainingValue']}
            colors={[
              barColor,
              '#D9D9D9'
            ]}
            indexBy="sensor"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.2}
            minValue={0}
            maxValue={100}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id, formattedValue }) => (id === 'value' &&
              <Paper px={12} py={8} radius="sm" shadow="sm">
                <Text size={12}>{`${formattedValue}%`}</Text>
              </Paper>
            )}
          />
        </Box>
        <Text
          size={12}
          weight={500}
          color="#212121"
        >
          {getSensorValue(sensor, true, true)}
        </Text>
      </Group>
    </Stack>
  )
}
