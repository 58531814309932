import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  Center,
  type DefaultMantineColor,
  Group,
  RingProgress,
  Text,
  Title
} from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import Paper from 'src/Layout/Paper'
import { findSensorByType, forceToTenths } from '../shared'

interface Props {
  agents: Agent[]
}

interface TotalItemProps {
  label: string
  value: string | number
  color: DefaultMantineColor
}

function TotalItem ({ color, value, label }: TotalItemProps) {
  return (
    <Group spacing="xs">
      <Badge
        variant="filled"
        radius="xs"
        color={color}
      >
        {label}
      </Badge>
      <Text
        size="xs"
        color="dark.7"
        weight="bold"
      >
        {forceToTenths(value)}
      </Text>
    </Group>
  )
}

export default function Totals ({ agents }: Props) {
  const { on, off } = agents.reduce((acc, { sensors }) => {
    const engineState = findSensorByType('ENGINE_OPERATE_MODE', sensors)
    if (!engineState) {
      return {
        ...acc,
        off: acc.off + 1
      }
    }
    const { currentValue } = engineState
    if (currentValue === 'RUNNING') {
      return {
        ...acc,
        on: acc.on + 1
      }
    }
    return {
      ...acc,
      off: acc.off + 1
    }
  }, { on: 0, off: 0 })

  return (
    <Paper my="lg" p="sm" shadow="sm">
      <Group position="apart">
        <Group align="center">
          <FontAwesomeIcon size="lg" icon={['far', 'nfc-magnifying-glass']} />
          <Title order={3}>Total Generators</Title>
        </Group>
        <Group spacing="lg">
          <TotalItem
            label="ON"
            value={on}
            color="green"
          />
          <TotalItem
            label="OFF"
            value={off}
            color="red"
          />
          <RingProgress
            size={40}
            thickness={4}
            label={(
              <Center>
                <Text size="sm">{forceToTenths(agents.length)}</Text>
              </Center>
            )}
            sections={[
              { value: ((off / agents.length) * 100), color: 'red' },
              { value: ((on / agents.length) * 100), color: 'green' }
            ]}
          />
        </Group>
      </Group>
    </Paper>
  )
}
