import { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Group,
  Title,
  Popover,
  useMantineTheme,
  Stack
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { createOrgTheme } from '@venturi-io/api/src/userManager/org'
import { color, type Color } from 'src/utils/style'
import ColorPicker from 'src/Input/ColorPicker'
import ActionButton from 'src/ActionButton'
import ThemeGroupPicker from './ThemeGroupPicker'
import ThemeColorPicker from './ThemeColorPicker'

export default function Theme () {
  const theme = useMantineTheme()

  // Theme color state
  const [primary, setPrimary] = useState<Color>([...theme.colors.primary])
  const [secondary, setSecondary] = useState<Color>([...theme.colors.secondary])
  const [login1, setLogin1] = useState(theme.colors.brand[0])
  const [login2, setLogin2] = useState(theme.colors.brand[1])
  const [toolbar1, setToolbar1] = useState(theme.colors.brand[2])
  const [toolbar2, setToolbar2] = useState(theme.colors.brand[3])

  // Generation state
  const [showGenPrimary, setShowGenPrimary] = useState(false)
  const [showGenSecondary, setShowGenSecondary] = useState(false)

  // API handling
  const saveTheme = useApi(createOrgTheme)
  const { orgId, token } = useUser()

  const isDirty = useCallback(() => {
    let dirty = false
    theme.colors.primary.forEach((hex, index) => {
      if (hex !== primary[index]) {
        dirty = true
      }
    })

    theme.colors.secondary.forEach((hex, index) => {
      if (hex !== secondary[index]) {
        dirty = true
      }
    })

    if (theme.colors.brand[0] !== login1) {
      dirty = true
    }

    if (theme.colors.brand[1] !== login2) {
      dirty = true
    }

    if (theme.colors.brand[2] !== toolbar1) {
      dirty = true
    }

    if (theme.colors.brand[3] !== toolbar2) {
      dirty = true
    }

    return dirty
  }, [primary, secondary, login1, login2, toolbar1, toolbar2])

  const genPrimaryBtn = (
    <ActionButton
      label="Generate theme from single colour"
      icon="brush"
      iconType="far"
      onClick={() => setShowGenPrimary(true)}
      iconSize="1x"
      actionSize="lg"
    />
  )

  const genPrimary = (hex: string) => {
    setPrimary(color(hex))
  }

  const genSecondaryBtn = (
    <ActionButton
      label="Generate theme from single colour"
      icon="brush"
      iconType="far"
      onClick={() => setShowGenSecondary(true)}
      iconSize="1x"
      actionSize="lg"
    />
  )

  const genSecondary = (hex: string) => {
    setSecondary(color(hex))
  }

  const save = () => {
    void saveTheme.fetch({
      orgId,
      primary,
      secondary,
      brand: [login1, login2, toolbar1, toolbar2]
    }, token, 'Theme saved successfully. Please reload this page to view.')
  }

  return (
    <div>
      <Stack>
        <Box>
          <Title order={2} mb={10}>Primary</Title>
          <Group>
            <ThemeGroupPicker override={primary} name="primary" onChange={result => setPrimary(result)} />
            <Popover opened={showGenPrimary} position="right">
              <Popover.Dropdown>
                <ColorPicker
                  color={'#ffffff'}
                  onAccept={genPrimary}
                  close={() => setShowGenPrimary(false)}
                />
              </Popover.Dropdown>
              <Popover.Target>{genPrimaryBtn}</Popover.Target>
            </Popover>
          </Group>
        </Box>

        <Box>
          <Title order={2} mb={10}>Secondary</Title>
          <Group>
            <ThemeGroupPicker override={secondary} name="secondary" onChange={result => setSecondary(result)} />
            <Popover opened={showGenSecondary} position="right">
              <Popover.Dropdown>
                <ColorPicker
                  color={'#ffffff'}
                  onAccept={genSecondary}
                  close={() => setShowGenSecondary(false)}
                />
              </Popover.Dropdown>
              <Popover.Target>{genSecondaryBtn}</Popover.Target>
            </Popover>
          </Group>
        </Box>

        <Box>
          <Title order={2}>Brand</Title>
          <Group spacing={50}>
            <Box>
              <Title order={3} mb={8}>Login gradient #1</Title>
              <Group>
                <ThemeColorPicker
                  name="brand"
                  index={0}
                  override={login1}
                  onChange={hex => setLogin1(hex)}
                />
                <ActionButton
                  label="Discard changes"
                  icon="trash-undo"
                  iconType="far"
                  onClick={() => setLogin1(theme.colors.brand[0])}
                  iconSize="1x"
                  actionSize="lg"
                />
              </Group>
            </Box>
            <Box>
              <Title order={3} mb={8}>Login gradient #2</Title>
              <Group>
                <ThemeColorPicker
                  name="brand"
                  index={1}
                  override={login2}
                  onChange={hex => setLogin2(hex)}
                />
                <ActionButton
                  label="Discard changes"
                  icon="trash-undo"
                  iconType="far"
                  onClick={() => setLogin2(theme.colors.brand[1])}
                  iconSize="1x"
                  actionSize="lg"
                />
              </Group>
            </Box>
            <Box>
              <Title order={3} mb={8}>Toolbar</Title>
              <Group>
                <ThemeColorPicker
                  name="brand"
                  index={2}
                  override={toolbar1}
                  onChange={hex => setToolbar1(hex)}
                />
                <ActionButton
                  label="Discard changes"
                  icon="trash-undo"
                  iconType="far"
                  onClick={() => setToolbar1(theme.colors.brand[2])}
                  iconSize="1x"
                  actionSize="lg"
                />
              </Group>
            </Box>
            <Box>
              <Title order={3} mb={8}>Toolbar logo background</Title>
              <Group>
                <ThemeColorPicker
                  name="brand"
                  index={3}
                  override={toolbar2}
                  onChange={hex => setToolbar2(hex)}
                />
                <ActionButton
                  label="Discard changes"
                  icon="trash-undo"
                  iconType="far"
                  onClick={() => setToolbar2(theme.colors.brand[3])}
                  iconSize="1x"
                  actionSize="lg"
                />
              </Group>
            </Box>
          </Group>
        </Box>
      </Stack>
      {isDirty() && (
        <Group position="left" mt={20}>
          <Button
            disabled={saveTheme.loading}
            color="green"
            leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} />}
            size="lg"
            onClick={save}
          >
            Save theme
          </Button>
        </Group>
      )}
    </div>
  )
}
