import { useCallback, useEffect } from 'react'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Grid,
  Group,
  Text,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inviteUsers } from '@venturi-io/api/src/userManager/user'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { useNotifications } from 'src/utils/notifications'
import TextInput from 'src/Input/TextInput'
import CustomTextarea from 'src/Input/Textarea'
import MultiSelectRole from 'src/Input/MultiSelect/MultiSelectRole'
import { isEmptyValue } from 'src/utils/strings'

interface InitialValues {
  firstName: string
  lastName: string
  email: string
  emergencyContact1Name?: string
  emergencyContact1PhoneNumber?: string
  emergencyContact2Name?: string
  emergencyContact2PhoneNumber?: string
  jobTitle?: string
  phoneNumber?: string
  roles?: string[]
  message?: string
}

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Invite ({ show, close, onCreate }: Props) {
  const { token, orgId } = useUser()
  const invite = useApi(inviteUsers)
  const { showError, showSuccess } = useNotifications()

  const hasValue = (inputName: string, value: string | string[]) => value.length < 1
    ? `Please specify ${inputName}`
    : null

  const form = useForm<InitialValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: []
    },
    validate: {
      firstName: value => hasValue('First name', value),
      lastName: value => hasValue('Last name', value),
      email: value => /^\S+@\S+$/.test(value) ? null : 'Please input a valid Email'
    }
  })

  const handleSubmit = useCallback(({
    firstName,
    lastName,
    email,
    jobTitle,
    phoneNumber,
    emergencyContact1Name,
    emergencyContact2Name,
    emergencyContact1PhoneNumber,
    emergencyContact2PhoneNumber,
    message,
    ...values
  }: typeof form.values) => {
    if (invite.loading) return // Short-circuit

    void invite
      .fetch({
        orgId,
        users: [
          {
            firstName,
            lastName,
            email,
            jobTitle: isEmptyValue(jobTitle),
            phoneNumber: isEmptyValue(phoneNumber),
            emergencyContact1Name: isEmptyValue(emergencyContact1Name),
            emergencyContact2Name: isEmptyValue(emergencyContact2Name),
            emergencyContact1PhoneNumber: isEmptyValue(emergencyContact1PhoneNumber),
            emergencyContact2PhoneNumber: isEmptyValue(emergencyContact2PhoneNumber)
          }
        ],
        emailBody: message,
        ...values
      }, token)
      .finally(close)
  }, [form.values])

  const handleClose = useCallback(() => {
    form.reset()
    close()
  }, [])

  useEffect(() => {
    invite.data.ifJust(({ error }) => {
      if (error) {
        showError(new Error(error.messages.join('\n')))
        return
      }

      showSuccess('Invited user successfully!')
      form.reset()
      invite.clearAll()
      void onCreate()
    })
  }, [invite.data])

  useEffect(() => {
    invite.error.ifJust(err => {
      showError(err)
      invite.clearAll()
    })
  }, [invite.error])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>New Platform User</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <TextInput withAsterisk label="First name" {...form.getInputProps('firstName')} />
          </Col>
          <Col span={12}>
            <TextInput withAsterisk label="Last name" {...form.getInputProps('lastName')} />
          </Col>
          <Col span={12}>
            <TextInput
              withAsterisk
              label="Email"
              {...form.getInputProps('email')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              label="Job Title"
              {...form.getInputProps('jobTitle')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              label="Phone"
              {...form.getInputProps('phoneNumber')}
            />
          </Col>
          <Col span={12} sm={6}>
            <MultiSelectRole
              label="Role(s)"
              value={form.values.roles}
              onChange={roles => form.setFieldValue('roles', roles)}
              error={form.errors.roles}
            />
          </Col>
          <Col span={12}>
            <CustomTextarea
              label="Message"
              radius="md"
              minRows={4}
              maxRows={6}
              autosize
              {...form.getInputProps('message')}
            />
          </Col>
          <Col span={12}>
            <Divider my="sm" />
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 1  (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact1Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact1PhoneNumber')} />
            </Group>
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 2 (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact2Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact2PhoneNumber')} />
            </Group>
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} color="white" />}
                disabled={invite.loading}
              >
                Invite
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
