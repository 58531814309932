import { createStyles, Box } from '@mantine/core'
import { mq } from 'src/utils/style'
import Header from './Header'
import Details from './Details'
import Cards from './Cards'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [mq(theme.breakpoints.sm)]: {
      width: '80vw',
      maxWidth: 1200
    },
    background: '#FF2CF7'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: 12,
    padding: '16px',
    [mq(theme.breakpoints.xs)]: {
      flexDirection: 'row'
    }
  }
}))

export default function AdaptiveUiDemo () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Header />
      <Box className={classes.content}>
        <Details />
        <Cards />
      </Box>
    </Box>
  )
}
