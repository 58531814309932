import { Box, Title } from '@mantine/core'
import { type SensorData as SensorHistory } from '@venturi-io/api/src/collector/sensor'
import ApexChart from 'react-apexcharts'

interface Props {
  id: string
  title: string
  label: string
  group: string
  data: SensorHistory['data']
}

export default function Chart ({
  id,
  title,
  label,
  group,
  data
}: Props) {
  return (
    <Box>
      <Title order={5}>{title}</Title>
      <ApexChart
        options={{
          chart: {
            id,
            group
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false
            }
          },
          yaxis: {
            labels: {
              minWidth: 40
            }
          }
        }}
        series={[{
          name: label,
          data
        }] as ApexAxisChartSeries}
        type="line"
        height={400}
      />
    </Box>
  )
}
