import { createStyles, Container, Image } from '@mantine/core'
import zIndex from './utils/zIndex'

const useStyles = createStyles(() => ({
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    overflow: 'hidden',
    zIndex: zIndex.splash
  },
  logo: {
    animation: 'flash 1.3s infinite',
    userSelect: 'none'
  }
}))

export default function SplashScreen () {
  const { classes } = useStyles()

  return (
    <Container className={classes.container} fluid>
      <Image
        className={classes.logo}
        width={64}
        height={64}
        src="/logo.svg"
        alt="Brand Logo"
        fit="contain"
      />
    </Container>
  )
}
