import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { useContext, useEffect } from 'react'
import { getToolContext } from '../Trips/Layer/Devtools'
import { getTripContext } from '../Trips/AnimationContext'
import { useViewTypes } from '../AgentAttendanceContext'
import ClusterMarker from './ClusterMarker'
import Marker from './Marker'

interface MarkerProps {
  selectedAgent?: Agent
  handleAgentClick: (id: Agent['agentId']) => void
}

function Markers ({
  selectedAgent,
  handleAgentClick
}: MarkerProps) {
  const { trip } = useContext(getTripContext())
  const { agentPoints, agentClusters, agentCheckReference, handleClusterClick } = useViewTypes()
  const { showMarkerLayer, showMarkerElements, setShowMarkerElements } = useContext(getToolContext())

  useEffect(() => {
    // lets hide markers by default when a trip is present
    setShowMarkerElements(trip === null)
  }, [trip])

  return !showMarkerLayer && showMarkerElements && (
    <>
      {agentClusters.map((cluster, i) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
          point_count: pointCount
        } = cluster.properties

        return isCluster
          ? (
            <ClusterMarker
              key={`${cluster.id}-${i}`}
              clusterId={cluster.id}
              latitude={latitude}
              longitude={longitude}
              pointCount={pointCount ?? 0}
              totalPoints={agentPoints.length}
              onClick={handleClusterClick}
            />
            )
            // Render or not
          : agentCheckReference[cluster.properties.agent.agentId] && (
            <Marker
              key={cluster.properties.agent.agentId}
              agent={cluster.properties.agent}
              selected={cluster.properties.agent.agentId === selectedAgent?.agentId}
              onClick={handleAgentClick}
            />
          )
      })}
    </>
  )
}

export default Markers
