/* eslint-disable react/jsx-closing-tag-location */
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  createStyles,
  Text,
  FileButton,
  Box
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import TextInput from 'src/Input/TextInput'
import DateTimePicker from 'src/Input/DateTimePicker'
import SelectUser from 'src/Input/Select/SelectUser'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { dateFormat } from 'src/utils/dates'
import { type FormMode } from 'src/utils'
import {
  type Attachment,
  type Task,
  createTask,
  updateTask,
  deleteTask,
  taskTypes,
  priority,
  statuses,
  acceptedTypes,
  deleteTaskAttachments
} from '@venturi-io/api/src/config/task'
import SelectAgent from 'src/Input/Select/SelectAgent'
import Select from 'src/Input/Select'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useNotifications } from 'src/utils/notifications'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import ActionButton from 'src/ActionButton'
import RTE from 'src/Input/RTE'

const useStyles = createStyles(() => ({
  iconCol: {
    position: 'relative'
  }
}))

export interface ReqProps {
  agentId: number
  taskType: string
  priority: string
  assignedTo: number
  dueDate: Date | null
  status: string
  notes: string
}

interface Props {
  mode: FormMode
  forceValues?: ReqProps
  initialValues?: Task
  onCreate?: () => Promise<void>
  onClose?: () => void
  onDelete?: () => void
}

export default function Form ({
  mode,
  initialValues,
  forceValues,
  onCreate,
  onClose,
  onDelete
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const navigate = useNavigate()
  const [apiPath, setApiPath] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { showError, showSuccess } = useNotifications()
  const [showDelete, setShowDelete] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const newTask = useApi(createTask)
  const editTask = useApi(updateTask)
  const removeTask = useApi(deleteTask)
  const removeAttachedFiles = useApi(deleteTaskAttachments)
  const agentDetails = useApi(getAgentDetails)
  const [selectedValue, setSelectedValue] = useState<string | null>(null)
  const [showDeleteFile, setShowDeleteFile] = useState(false)
  const [deleteFile, setDeleteFile] = useState('')
  const [uploading, setUploading] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const handleSelectChange = (value: string | null) => {
    if (value) {
      setSelectedValue(value)
      form.setFieldValue('agentId', parseInt(value))
    }
  }

  const hasValue = (inputName: string, value: string) => value.length < 1
    ? `Please specify ${inputName}`
    : null

  const form = useForm <ReqProps>({
    initialValues: {
      agentId: -1,
      taskType: '',
      priority: '',
      assignedTo: -1,
      dueDate: null,
      status: '',
      notes: ''
    },
    validate: {
      agentId: (value: number) => value === -1
        ? 'Please select agent'
        : null,
      taskType: (value: string) => hasValue('task type', value),
      priority: (value: string) => hasValue('priority', value),
      assignedTo: (value: number) => value === -1
        ? 'Please select an assignee'
        : null,
      dueDate: (value: ReqProps['dueDate']) => value === null
        ? 'Please select a due date'
        : null,
      status: (value?: string) => hasValue('status', value ?? ''),
      notes: (value: string) => hasValue('notes', value)
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = useCallback(() => navigate('/tasks'), [])

  const handleCreate = useCallback(async ({
    agentId,
    taskType,
    priority,
    assignedTo,
    dueDate,
    status,
    notes
  }: FormValues) => {
    // used raw fetch termporarily to trigger upload asset task
    // since it's expecting a form-data payload which is not yet supported on our createAPI
    const formData = new FormData()
    formData.append('agentId', agentId.toString())
    formData.append('taskType', taskType)
    formData.append('priority', priority)
    formData.append('assignedTo', assignedTo.toString())
    formData.append(
      'dueDate',
      dayjs(dueDate)
        .utc()
        .format(`${dateFormat}Z`)
    )
    formData.append('status', status)
    formData.append('notes', notes)
    if (selectedFiles.length > 0) {
      selectedFiles.map((file) => formData.append('attachments', file))
    }

    try {
      setUploading(true)

      const request = {
        method: 'POST',
        headers: {
          'X-Auth-Token': token
        },
        body: formData
      }

      const result = await fetch(apiPath, request)
      if (result.ok) {
        form.reset()
        setSelectedFiles([])

        if (onClose) {
          onClose()
        }
        if (onCreate) {
          void onCreate()
        }

        showSuccess('Successfully created new task')
      } else {
        showError(new Error('Failed to create task'))
      }
    } catch (error: any) {
      showError(error)
    } finally {
      setUploading(false)
    }
  }, [form.values, selectedFiles])

  const handleUpdate = useCallback(async ({
    agentId,
    taskType,
    priority,
    assignedTo,
    dueDate,
    status,
    notes
  }: FormValues) => {
    const taskId = initialValues?.id
    if (!taskId) {
      throw new Error('Unable to perform the action, no task provided')
    }

    // used raw fetch termporarily to trigger upload asset task
    // since it's expecting a form-data payload which is not yet supported on our createAPI
    const formData = new FormData()
    formData.append('agentId', agentId.toString())
    formData.append('taskType', taskType)
    formData.append('priority', priority)
    formData.append('assignedTo', assignedTo.toString())
    formData.append(
      'dueDate',
      dayjs(dueDate)
        .utc()
        .format(`${dateFormat}Z`)
    )
    formData.append('status', status)
    formData.append('notes', notes)
    if (selectedFiles.length > 0) {
      selectedFiles.map((file) => {
        return formData.append('attachments', file)
      })
    }

    try {
      setUploading(true)

      const request = {
        method: 'PUT',
        headers: {
          'X-Auth-Token': token
        },
        body: formData
      }

      const result = await fetch(`${apiPath}/${taskId}`, request)

      if (result.ok) {
        redirectToListPage()
        showSuccess('Successfully updated task')
      } else {
        showError(new Error('Failed to update task'))
      }
    } catch (error: any) {
      showError(error)
    } finally {
      setUploading(false)
    }
  }, [form.values, selectedFiles])

  const handleDelete = useCallback(() => {
    const taskId = initialValues?.id

    if (!taskId) {
      throw new Error('Unable to perform the action, no task provided')
    }

    void removeTask
      .fetch({ taskId }, token, 'Succesfully deleted task')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) {
      void handleCreate(values)
    }
    if (inEditMode) {
      void handleUpdate(values)
    }
  }, [inCreateMode, inEditMode, form.values, selectedFiles])

  const handleClose = useCallback(() => {
    form.reset()
    if (onClose) {
      onClose()
    }
  }, [])

  const handleFileChange = (files: File[]) => {
    setErrorMessage('')
    if (selectedFiles.length + files.length > 5) {
      setErrorMessage('Maximum of 5 files upload only')
      return []
    }

    const validFiles = files.filter(({ type }) => {
      if (
        !acceptedTypes
          .join(',')
          .includes(type)
      ) {
        setErrorMessage('Invalid file type. Only Images, PDF, and Excel files are allowed.')
        return false
      }
      return true
    })
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles])
    return validFiles
  }

  const deleteAttachments = (attachmentIds: number[]) => {
    const taskId = initialValues?.id

    if (!taskId) {
      throw new Error('Unable to perform the action, no task provided')
    }

    void removeAttachedFiles
      .fetch({
        taskId,
        attachmentIds
      }, token, 'Succesfully deleted attachment')
      .finally(onDelete)
  }

  const handleDeleteExistingFile = () => {
    let fileToDelete
    // check if only has 1 attached file
    if (initialValues?.attachments.length === 1) {
      fileToDelete = initialValues?.attachments[0].id
      deleteAttachments([fileToDelete])
    } else {
      const getFileToDelete = initialValues?.attachments.filter((file) => file.name === deleteFile)
      if (getFileToDelete) {
        fileToDelete = getFileToDelete[0].id
        deleteAttachments([fileToDelete])
      }
    }
    setShowDeleteFile(false)
  }

  const handleDeleteNewFile = (name: string, isFileUploaded: boolean) => {
    if (!isFileUploaded) {
      setDeleteFile(name)
      setShowDeleteFile(true)
    } else {
      const updatedFiles = selectedFiles.filter((file) => file.name !== name)
      setSelectedFiles(updatedFiles)
    }
  }

  const handleUpdateNotes = (updateNotes: string) => {
    form.setFieldValue('notes', updateNotes)
  }

  useEffect(() => {
    void configure(getHost()).then(config => {
      setApiPath(`${config.config}/api/config/tasks`)
    })
  }, [])

  useEffect(() => {
    // load values in edit mode
    if (initialValues) {
      const {
        agent,
        taskType,
        priority,
        assignedTo,
        dueDate,
        status,
        notes
      } = initialValues

      form.setValues({
        agentId: agent.agentId,
        taskType,
        priority,
        assignedTo: assignedTo.id,
        dueDate: dayjs(dueDate).toDate(),
        status,
        notes
      })
    }
  }, [initialValues])

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  useEffect(() => {
    if (selectedValue) {
      const agentId = parseInt(selectedValue)
      void agentDetails.fetch({ agentId }, token)
    }
  }, [selectedValue])

  useEffect(() => {
    if (forceValues?.agentId) {
      form.setValues(forceValues)
      setSelectedValue(forceValues.agentId.toString())
    }
  }, [forceValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <SelectAgent
              defaultValue={initialValues?.agent.agentId.toString()}
              label="Asset"
              placeholder="Choose an asset"
              value={selectedValue}
              onChange={handleSelectChange}
              searchable
              required
            />
          </Col>
          <Col className={classes.iconCol} {...inputColProps}>
            <TextInput
              disabled
              label="Type"
              value={agent?.assetType ?? initialValues?.agent.assetType}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Task Type"
              data={taskTypes}
              {...form.getInputProps('taskType')}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Priority"
              data={priority}
              {...form.getInputProps('priority')}
            />
          </Col>
          <Col {...inputColProps}>
            <SelectUser
              withAsterisk
              label="Assignee"
              placeholder="Choose an assignee"
              {...form.getInputProps('assignedTo')}
              value={form.values.assignedTo?.toString()}
              searchable
            />
          </Col>
          <Col {...inputColProps}>
            <DateTimePicker
              withAsterisk
              valueFormat="DD/MM/YYYY hh:mm A"
              label="Due date"
              placeholder="Pick date and time"
              {...form.getInputProps('dueDate')}
            />
          </Col>
          <Col h={270} {...inputColProps}>
            <Box style={{ height: '95%' }}>
              <Text size="xs">
                Notes
                {' '}
                <span style={{ color: 'red' }}>*</span>
              </Text>
              <RTE
                content={form.values.notes}
                onUpdate={handleUpdateNotes}
                mode={mode}
              />
            </Box>
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Status"
              data={statuses}
              {...form.getInputProps('status')}
            />
          </Col>
          <Col {...inputColProps}>
            <FileButton onChange={handleFileChange} accept={acceptedTypes.join(',')} multiple>
              {(props) => (
                <Button
                  leftIcon={<FontAwesomeIcon icon={['fas', 'paperclip']} color="white" />}
                  {...props}
                  color="primary"
                >
                  Attach Images/Files
                </Button>
              )}
            </FileButton>
            {errorMessage && <Text color="red" size="sm">{errorMessage}</Text>}
            <div>
              {inEditMode &&
                initialValues && initialValues?.attachments.length > 0 &&
                initialValues?.attachments.map((file: Attachment, index: number) => (
                  <Group key={index} spacing="md" mt="xs">
                    <TextInput
                      key={index}
                      rightSection={(
                        <ActionButton
                          label="Delete"
                          icon="trash-can"
                          iconType="fas"
                          onClick={() => handleDeleteNewFile(file.name, false)}
                        />
                      )}
                      readOnly
                      value={file.name}
                      w="100%"
                    />
                  </Group>
                ))}
              {selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                <Group key={index} spacing="md" mt="xs">
                  <TextInput
                    key={index}
                    rightSection={(
                      <ActionButton
                        label="Delete"
                        icon="trash-can"
                        iconType="fas"
                        onClick={() => handleDeleteNewFile(file.name, true)}
                      />
                    )}
                    readOnly
                    value={file.name}
                    w="100%"
                  />
                </Group>
              ))}
            </div>
          </Col>
          {inEditMode && (
            <Col span={12}>
              <Group position="left" mt="lg" mb="sm">
                <Button
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode
                  ? newTask.loading || uploading
                  : editTask.loading || uploading}
              >
                {inCreateMode ? 'Create' : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting task for "${initialValues?.agent.agentName}"`}
          loading={removeTask.loading}
          question="Are you sure you want to delete this task? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDeleteFile}
          title={`Deleting attached file of "${initialValues?.agent.agentName}"`}
          loading={removeAttachedFiles.loading}
          question="Are you sure you want to delete this file? This cannot be undone."
          onClose={() => setShowDeleteFile(false)}
          onCancel={() => setShowDeleteFile(false)}
          onConfirm={handleDeleteExistingFile}
        />
      )}
    </>
  )
}
