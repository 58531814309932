/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  createReport,
  updateReport,
  deleteReport,
  getReports,
  getReportTypes,
  type ReportType
} from '@venturi-io/api/src/config/report'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import TextInput from 'src/Input/TextInput'
import Textarea from 'src/Input/Textarea'
import { type FormMode } from 'src/utils'
// import Queries from '../Queries'
import { useDisclosure } from '@mantine/hooks'
import ConfirmModal from 'src/Layout/ConfirmModal'
import SelectReportType from './SelectReportType'

interface Props {
  mode: FormMode
}

interface RouteParams extends Record<string, string | undefined> {
  reportId: string
}

export default function CreateOrEdit ({ mode }: Props) {
  const allReports = useApi(getReports)
  const allReportTypes = useApi(getReportTypes)
  const [showDelete, { close, open }] = useDisclosure(false)
  const create = useApi(createReport)
  const update = useApi(updateReport)
  const remove = useApi(deleteReport)
  const { token } = useUser()
  const navigate = useNavigate()
  const { reportId } = useParams<RouteParams>()
  const [_, setReportType] = useState<ReportType>()
  const reportTypes = allReportTypes.data.mapOrDefault(({ items }) => items, [])

  const inEditMode = mode === 'EDIT'

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      reportTypeId: -1
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify a Report Name' : null,
      reportTypeId: value => value === -1 ? 'Please choose a Report Type' : null
    }
  })

  const FormButton = () => {
    switch (mode) {
      case 'CREATE':
        return (
          <Button
            type="submit"
            color="green"
            leftIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} color="white" />}
            disabled={create.loading}
          >
            Create
          </Button>
        )

      case 'EDIT':
        return (
          <Button
            type="submit"
            color="green"
            leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
            disabled={update.loading}
          >
            Save
          </Button>
        )
    }
  }

  const redirectToReportPage = () => {
    form.reset()
    navigate('/reports')
  }

  const handleSubmit = (values: typeof form.values) => {
    switch (mode) {
      case 'CREATE':
        void create
          .fetch(values, token, 'Successfully created new report')
          .finally(redirectToReportPage)
        break

      case 'EDIT':
        void update
          .fetch({
            ...values,
            reportId: Number(reportId ?? -1)
          }, token, 'Successfully updated report')
          .finally(redirectToReportPage)
        break
    }
  }

  const handleDelete = async () => {
    if (reportId) {
      await remove.fetch({ reportId: Number(reportId) }, token, 'Successfully deleted report')
      redirectToReportPage()
    }
  }

  useEffect(() => {
    allReports
      .data
      .ifJust(({ items }) => {
        const report = items.find(r => r.id === Number(reportId ?? -1))

        if (report) {
          form.setValues({
            name: report.name,
            description: report.description ?? '',
            reportTypeId: report.reportType.id
          })
          setReportType(report.reportType)
        }
      })
  }, [allReports.data])

  useEffect(() => {
    if (mode === 'EDIT') {
      void allReports.fetch({
        page: 1,
        size: 99999
      }, token)
    }

    void allReportTypes.fetch({
      page: 1,
      size: 99999
    }, token)
  }, [])

  return (
    <>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={remove.loading}
          question="Are you sure you want to delete this report template? This cannot be undone."
          onClose={close}
          onCancel={close}
          onConfirm={handleDelete}
        />
      )}
      <Paper p="lg" mt="lg">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Col span={12}>
              <TextInput
                withAsterisk
                label="Report Name"
                placeholder="Report Name"
                {...form.getInputProps('name')}
              />
            </Col>
            <Col span={12}>
              <Textarea
                label="Description"
                placeholder="Description of a report"
                {...form.getInputProps('description')}
              />
            </Col>
            <Col span={12}>
              <SelectReportType
                required
                disabled={allReportTypes.loading}
                data={reportTypes}
                value={form.values.reportTypeId}
                onChange={type => {
                  form.setFieldValue('reportTypeId', type?.id ?? -1)
                  setReportType(type)
                }}
                error={form.errors.reportTypeId}
              />
            </Col>
            <Col span={12}>
              <Group
                position={inEditMode
                  ? 'apart'
                  : 'right'}
              >
                {inEditMode && (
                  <Button
                    color="red"
                    leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                    onClick={open}
                  >
                    Delete
                  </Button>
                )}
                <Group position="right" mt="sm" mr="md">
                  <Button
                    color="gray"
                    leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                    onClick={redirectToReportPage}
                  >
                    Cancel
                  </Button>
                  <FormButton />
                </Group>
              </Group>
            </Col>
          </Grid>
        </form>
      </Paper>
    </>
  )
}
