import { type ReactNode, useEffect, useMemo, useState } from 'react'
import { type SelectProps } from '@mantine/core'
import {
  type Dashboard,
  getDashboards,
  getUserDashboards
} from '@venturi-io/api/src/userManager/dashboard'
import Select from 'src/Input/Select'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { useNotifications } from 'src/utils/notifications'
import Item, { type Props as ItemProps } from './Item'

type Value = string | null

const sortByName = (a: ItemProps, b: ItemProps) => (
  a.label > b.label ? 1 : -1
)

interface Props extends Omit<SelectProps, 'data'> {
  value: Value
  onChange: (val: Value) => void
  setHasHomeDashboard: (hasDefault: boolean) => void
  setIsLoadingDashboardOptions: (isLoading: boolean) => void
  required?: boolean
  searchable?: boolean
  error?: ReactNode
}

export default function DashboardDropdown ({
  value,
  onChange,
  setIsLoadingDashboardOptions,
  setHasHomeDashboard,
  required = false,
  searchable = false,
  error,
  ...props
}: Props) {
  const { token, orgUserId } = useUser()
  const [allDashboards, setAllDashboards] = useState<Dashboard[]>([])
  const publicDashboards = useApi(getDashboards)
  const privateDashboards = useApi(getDashboards)
  const userDashboards = useApi(getUserDashboards)
  const { showError } = useNotifications()

  const isLoading = publicDashboards.loading ||
    privateDashboards.loading ||
    userDashboards.loading

  useEffect(() => setIsLoadingDashboardOptions(isLoading), [isLoading])

  const selectDashboards: ItemProps[] = useMemo(() => (
    userDashboards.data.mapOrDefault(userDashboards_ => {
      const options: ItemProps[] = allDashboards.map(({ dashboardId, name, isPrivate }) => {
        const matchedDashboard = userDashboards_.find(dashboard => dashboardId === dashboard.dashboardId)

        return {
          label: name,
          value: dashboardId.toString(),
          isPrivate,
          isDefault: matchedDashboard?.isDefault ?? false,
          isFavourite: matchedDashboard?.isFavourite ?? false
        }
      })

      const homeDashboard = options.filter(({ isDefault }) => isDefault)

      const favouriteDashboards = options
        .filter(({ isFavourite }) => isFavourite)
        .sort(sortByName)

      const otherDashboards = options
        .filter(({ isDefault, isFavourite }) => !isDefault && !isFavourite)
        .sort(sortByName)

      if (homeDashboard.length) {
        setHasHomeDashboard(true)
      }

      return [
        ...homeDashboard,
        ...favouriteDashboards,
        ...otherDashboards
      ]
    }, [])
  ), [userDashboards.data, allDashboards])

  useEffect(() => {
    publicDashboards.data.ifJust(({ items }) => {
      setAllDashboards([
        ...allDashboards,
        ...items
      ])
    })
  }, [publicDashboards.data])

  useEffect(() => {
    publicDashboards.error.ifJust(err => {
      showError(err)
    })
  }, [publicDashboards.error])

  useEffect(() => {
    privateDashboards.data.ifJust(({ items }) => {
      const userPrivateDashboards = items.filter(({ ownerOrgUserId }) => orgUserId === ownerOrgUserId)

      setAllDashboards([
        ...allDashboards,
        ...userPrivateDashboards
      ])
    })
  }, [privateDashboards.data])

  useEffect(() => {
    privateDashboards.error.ifJust(err => {
      showError(err)
    })
  }, [privateDashboards.error])

  useEffect(() => {
    void userDashboards.fetch({}, token)
  }, [])

  useEffect(() => {
    userDashboards.data.ifJust(() => {
      void privateDashboards
        .fetch({
          page: 1,
          size: 999999,
          privateOnly: true
        }, token)
        .then(() => {
          void publicDashboards.fetch({
            page: 1,
            size: 999999,
            privateOnly: false
          }, token)
        })
    })
  }, [userDashboards.data])

  useEffect(() => {
    userDashboards.error.ifJust(err => {
      showError(err)
    })
  }, [userDashboards.error])

  return (
    <Select
      required={required}
      searchable={searchable}
      disabled={isLoading}
      placeholder="Choose a dashboard"
      itemComponent={Item}
      value={value}
      data={selectDashboards}
      transitionProps={{
        transition: 'pop-top-left',
        duration: 100
      }}
      onChange={val => onChange(val)}
      error={error}
      {...props}
    />
  )
}
