import { Title } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import AutoGrid from 'src/Layout/AutoGrid'
import Paper from 'src/Layout/Paper'
import Instance from './Sensor'

interface Props {
  list: Sensor[]
}

export default function Sensors ({ list }: Props) {
  return (
    <Paper mt={0}>
      <Title order={4}>Sensors</Title>
      <AutoGrid marginVertical="12px">
        {list.map(sensor => <Instance key={sensor.sensorInstanceId} {...sensor} />)}
      </AutoGrid>
    </Paper>
  )
}
