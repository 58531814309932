import { type ReactNode, useEffect, useState } from 'react'
import { getAgentDetails, type Sensor } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import MultiSelect from 'src/Input/MultiSelect'

type Value = string[]
type Labels = string[]

export interface Props {
  agentId: number
  value?: Value
  onChange: (value: Value) => void
  setSelectedSensorNames?: (labels: Labels) => void
  required?: boolean
  searchable?: boolean
  error?: ReactNode
}

const sensorToSelect = ({ sensorInstanceId, name }: Sensor) => ({
  value: sensorInstanceId.toString(),
  label: name
})

export default function AgentSensorMultiSelect ({
  agentId,
  onChange,
  setSelectedSensorNames,
  required = false,
  searchable = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const agent = useApi(getAgentDetails)
  const [initialLoad, setInitialLoad] = useState(true)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectSensors = agent.data.mapOrDefault(({ sensors }) => (
    sensors.map(sensorToSelect)
  ), [])

  useEffect(() => {
    if (setSelectedSensorNames) {
      setSelectedSensorNames(
        value.map(val => {
          const sensor = selectSensors.find(sensor => sensor.value === val)

          return sensor?.label ?? ''
        })
      )
    }
  }, [value, selectSensors])

  useEffect(() => {
    void agent.fetch({ agentId }, token)

    if (initialLoad) {
      setInitialLoad(false)
    } else {
      setValue([])
    }
  }, [agentId])

  return (
    <MultiSelect
      required={required}
      searchable={searchable}
      disabled={agent.loading}
      label="Sensors"
      placeholder="Choose sensor(s)"
      data={selectSensors}
      value={value}
      onChange={val => {
        setValue(val)
        onChange(val)
      }}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
    />
  )
}
