import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { Stack, Text, Paper } from '@mantine/core'
import Attribute from './Attribute'
import Groups from './Groups'

interface Props {
  agentId: AgentDetails['agentId']
  metadata: AgentDetails['metadata']
}

export default function AssetDetails ({ agentId, metadata }: Props) {
  return (
    <Paper p={16} radius={4} shadow="xs">
      <Text size={14} weight={600}>Asset Details</Text>
      <Stack mt={16} spacing={16}>
        {metadata && Object.keys(metadata).map(prop => (
          <Attribute
            key={prop}
            label={prop}
            value={metadata[prop]}
          />
        ))}
        <Groups agentId={agentId} />
      </Stack>
    </Paper>
  )
}
