import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { valueMap, paginated, type SensorIdRequest, type PaginatedRequest } from '../shared'

export const sensorTypes = [
  'SENSOR',
  'GPS',
  'CELL_LAC',
  'CELL_MCC',
  'CELL_MNC',
  'CELL_PROTOCOL',
  'CELL_STRING',
  'CELL_ID'
] as const

export type SensorType = typeof sensorTypes[number]

export const valueFormats = [
  'STRING',
  'FLOAT',
  'GEOSPATIAL',
  'BOOLEAN'
] as const

export type ValueFormat = typeof valueFormats[number]

const sensor = z.object({
  id: z.number(),
  name: z.string(),
  type: z.enum(sensorTypes),
  sensorTypeName: z.optional(z.string()),
  valueFormat: z.optional(z.enum(valueFormats)),
  unit: z.optional(z.string()),
  valueMap,
  iconStyle: z.optional(z.string())
})

const sensors = paginated.extend({
  items: z.array(sensor)
})

export type Sensor = z.infer<typeof sensor>

export interface CreateSensorRequest extends Record<string, unknown> {
  name: string
  type: SensorType
  sensorTypeName: string
  valueFormat: ValueFormat
  unit?: string
  valueMap?: Record<string, string | number | boolean>
  iconStyle?: string
}
export const createSensor = createStatefulApi<CreateSensorRequest, typeof sensor>(
  'config',
  '/sensor',
  { method: 'POST' },
  sensor
)

interface GetSensorsRequest extends PaginatedRequest {
  name?: string
  type?: SensorType
}

export const getSensors = createStatelessApi<GetSensorsRequest, typeof sensors>(
  'config',
  '/sensors',
  { method: 'GET', type: 'path' },
  sensors
)

export const getSensor = createStatelessApi<SensorIdRequest, typeof sensor>(
  'config',
  '/sensor/:sensorId',
  { method: 'GET', type: 'path' },
  sensor
)

interface UpdateSensorRequest extends Record<string, unknown> {
  sensorId: number
  name?: string
  type?: SensorType
  sensorTypeName?: string
  valueFormat?: ValueFormat
  unit?: string
  valueMap?: Record<string, string | number | boolean>
  iconStyle?: string
}
export const updateSensor = createStatefulApi<UpdateSensorRequest, typeof sensor>(
  'config',
  '/sensor/:sensorId',
  { method: 'PUT' },
  sensor
)

export const deleteSensor = createStatefulApi<SensorIdRequest, z.ZodBoolean>(
  'config',
  '/sensor/:sensorId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

const sensorType = z.object({
  id: z.number(),
  name: z.string()
})

const allSensorTypes = z.array(sensorType)

export const getAllSensorTypes = createStatelessApi<Record<string, unknown>, typeof allSensorTypes>(
  'config',
  '/sensor-types/ui',
  { method: 'GET', type: 'path' },
  allSensorTypes
)
