import { useCallback, useEffect } from 'react'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Grid,
  Group,
  Text,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createBasicUser } from '@venturi-io/api/src/userManager/user'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { useNotifications } from 'src/utils/notifications'
import TextInput from 'src/Input/TextInput'
import { isEmptyValue } from 'src/utils/strings'

interface InitialValues {
  firstName: string
  lastName: string
  emergencyContact1Name?: string
  emergencyContact1PhoneNumber?: string
  emergencyContact2Name?: string
  emergencyContact2PhoneNumber?: string
  jobTitle?: string
  phoneNumber?: string
}

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Add ({ show, close, onCreate }: Props) {
  const { token, orgId } = useUser()
  const addUser = useApi(createBasicUser)
  const { showError, showSuccess } = useNotifications()

  const hasValue = (inputName: string, value: string | string[]) => value.length < 1
    ? `Please specify ${inputName}`
    : null

  const form = useForm<InitialValues>({
    initialValues: {
      firstName: '',
      lastName: ''
    },
    validate: {
      firstName: value => hasValue('First name', value),
      lastName: value => hasValue('Last name', value)
    }
  })

  const handleSubmit = useCallback(({
    phoneNumber,
    jobTitle,
    emergencyContact1Name,
    emergencyContact1PhoneNumber,
    emergencyContact2Name,
    emergencyContact2PhoneNumber,
    ...values
  }: typeof form.values) => {
    if (addUser.loading) return // Short-circuit

    void addUser
      .fetch({
        orgId,
        jobTitle: isEmptyValue(jobTitle),
        phoneNumber: isEmptyValue(phoneNumber),
        emergencyContact1Name: isEmptyValue(emergencyContact1Name),
        emergencyContact2Name: isEmptyValue(emergencyContact2Name),
        emergencyContact1PhoneNumber: isEmptyValue(emergencyContact1PhoneNumber),
        emergencyContact2PhoneNumber: isEmptyValue(emergencyContact2PhoneNumber),
        ...values
      }, token)
      .finally(close)
  }, [form.values])

  const handleClose = useCallback(() => {
    form.reset()
    close()
  }, [])

  useEffect(() => {
    addUser.data.ifJust(() => {
      showSuccess('Added user successfully!')
      form.reset()
      addUser.clearAll()
      void onCreate()
    })
  }, [addUser.data])

  useEffect(() => {
    addUser.error.ifJust(err => {
      showError(err)
      addUser.clearAll()
    })
  }, [addUser.error])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>New Contact User</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <TextInput withAsterisk label="First name" {...form.getInputProps('firstName')} />
          </Col>
          <Col span={12}>
            <TextInput withAsterisk label="Last name" {...form.getInputProps('lastName')} />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              label="Job Title"
              {...form.getInputProps('jobTitle')}
            />
          </Col>
          <Col span={12} sm={6}>
            <TextInput
              label="Phone"
              {...form.getInputProps('phoneNumber')}
            />
          </Col>
          <Col span={12}>
            <Divider my="sm" />
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 1  (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact1Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact1PhoneNumber')} />
            </Group>
          </Col>
          <Col span={12}>
            <Text size="xs">Emergency Contact 2 (Optional)</Text>
            <Group grow>
              <TextInput label="Name" {...form.getInputProps('emergencyContact2Name')} />
              <TextInput label="Phone Number" {...form.getInputProps('emergencyContact2PhoneNumber')} />
            </Group>
          </Col>
          <Col span={12}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="white" />}
                disabled={addUser.loading}
              >
                Submit
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
    </Drawer>
  )
}
