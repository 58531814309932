import { useCallback, useEffect, useState } from 'react'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from '@venturi-io/frontend/src/utils/style'
import { updateOnboardingStatusPerUser } from '@venturi-io/api/src/userManager/user'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Intro from './Intro'
import CheckList from './CheckList'
import MapTour from './Tours/MapTour'
import DashboardTour from './Tours/DashboardTour'
import AssetTour from './Tours/AssetTour'
import ReportTour from './Tours/ReportTour'
import AssetTaskTour from './Tours/AssetTaskTour'
import Outro from './Outro'

export default function UserTour () {
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const user = useUser()
  const updateOnboardingStatus = useApi(updateOnboardingStatusPerUser)
  const [isOpenIntro, setIsOpenIntro] = useState(true)
  const [isOpenCheckList, setIsOpenCheckList] = useState(true)
  const [isOpenMapTour, setIsOpenMapTour] = useState(false)
  const [isDoneMapTour, setIsDoneMapTour] = useState(false)
  const [isOpenDashboardTour, setIsOpenDashboardTour] = useState(false)
  const [isDoneDashboardTour, setIsDoneDashboardTour] = useState(false)
  const [isOpenAssetTour, setIsOpenAssetTour] = useState(false)
  const [isDoneAssetTour, setIsDoneAssetTour] = useState(false)
  const [isOpenReportTour, setIsOpenReportTour] = useState(false)
  const [isDoneReportTour, setIsDoneReportTour] = useState(false)
  const [isOpenAssetTaskTour, setIsOpenAssetTaskTour] = useState(false)
  const [isDoneAssetTaskTour, setIsDoneAssetTaskTour] = useState(false)
  const [isOpenOutro, setIsOpenOutro] = useState(false)

  const handleConfirmIntro = useCallback(() => {
    setIsOpenIntro(false)
    setIsOpenMapTour(true)
  }, [])

  const handleConfirmOutro = useCallback(() => {
    setIsOpenOutro(false)
    setIsOpenCheckList(false)
    void updateOnboardingStatus.fetch({
      userId: user.userId,
      status: 'FINISHED'
    }, user.token)
  }, [])

  useEffect(() => {
    // this will update the on boarding status of the current user in local storage
    updateOnboardingStatus.data.ifJust(({ status }) => {
      window.localStorage.setItem('user', JSON.stringify({
        ...user,
        onboardingStatus: status
      }))
    })
  }, [updateOnboardingStatus.data])

  useEffect(() => {
    // This will start map tour initially
    if (!isDoneMapTour) {
      const targetBtn = document.getElementById('btn-tour-map__menu')
      targetBtn?.click()
    }
  }, [])

  useEffect(() => {
    // This will start dashboard tour once map tour is done
    if (isDoneMapTour) {
      const targetBtn = document.getElementById('btn-tour-dashboard__menu')
      targetBtn?.click()
      setIsOpenDashboardTour(true)
    }
  }, [isDoneMapTour])

  useEffect(() => {
    // This will start asset tour once dashboard tour is done
    if (isDoneDashboardTour) {
      const targetBtn = document.getElementById('btn-tour-asset__menu')
      targetBtn?.click()
      setIsOpenAssetTour(true)
    }
  }, [isDoneDashboardTour])

  useEffect(() => {
    // This will start report tour once asset tour is done
    if (isDoneAssetTour) {
      const targetBtn = document.getElementById('btn-tour-report__menu')
      targetBtn?.click()
      // Added a delay to wait for the asset menu's close animation to be finished before rendering the tour
      setTimeout(() => {
        setIsOpenReportTour(true)
      }, 800)
    }
  }, [isDoneAssetTour])

  useEffect(() => {
    // This will start asset task tour once report tour is done
    if (isDoneReportTour) {
      const targetBtn = document.getElementById('btn-tour-asset-task__menu')
      targetBtn?.click()
      setIsOpenAssetTaskTour(true)
    }
  }, [isDoneReportTour])

  useEffect(() => {
    // This will show outro message once the whole tour is done
    if (isDoneAssetTaskTour) {
      const targetBtn = document.getElementById('btn-tour-map__menu')
      targetBtn?.click()
      setIsOpenOutro(true)
    }
  }, [isDoneAssetTaskTour])

  if (!isDesktop) {
    return
  }

  return (
    <>
      <Intro
        isOpen={isOpenIntro}
        setIsOpen={setIsOpenIntro}
        onConfirm={handleConfirmIntro}
      />
      {isOpenCheckList && (
        <CheckList
          isDoneMapTour={isDoneMapTour}
          isDoneDashboardTour={isDoneDashboardTour}
          isDoneAssetTour={isDoneAssetTour}
          isDoneReportTour={isDoneReportTour}
          isDoneAssetTaskTour={isDoneAssetTaskTour}
        />
      )}
      <MapTour
        isOpen={isOpenMapTour}
        setIsOpen={setIsOpenMapTour}
        setIsDone={setIsDoneMapTour}
      />
      <DashboardTour
        isOpen={isOpenDashboardTour}
        setIsOpen={setIsOpenDashboardTour}
        setIsDone={setIsDoneDashboardTour}
      />
      <AssetTour
        isOpen={isOpenAssetTour}
        setIsOpen={setIsOpenAssetTour}
        setIsDone={setIsDoneAssetTour}
      />
      <ReportTour
        isOpen={isOpenReportTour}
        setIsOpen={setIsOpenReportTour}
        setIsDone={setIsDoneReportTour}
      />
      <AssetTaskTour
        isOpen={isOpenAssetTaskTour}
        setIsOpen={setIsOpenAssetTaskTour}
        setIsDone={setIsDoneAssetTaskTour}
      />
      <Outro
        isOpen={isOpenOutro}
        setIsOpen={setIsOpenOutro}
        onConfirm={handleConfirmOutro}
      />
    </>
  )
}
