export interface StyleParams {
  headerHeight: string
  sidebarCollapsedWidth: string
  fullView: boolean
}

export const styleParams = {
  headerHeight: '70px',
  sidebarCollapsedWidth: '60px',
  fullView: false
}
