import { useEffect, useMemo } from 'react'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import SparkLine from 'src/Charts/SparkLine'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'

interface Props {
  sensorInstanceId: Sensor['sensorInstanceId']
  height: number
}

export default function SensorHistorySparkLineChart ({ sensorInstanceId, height }: Props) {
  const { token } = useUser()
  const getSensorHistory = useApi(getSensorData)
  const bucket: ChosenBucket<ItemKeys> = {
    item: '3 days',
    data: {
      timeBucket: '2 hours',
      noOfRecords: 36
    }
  }

  const sensorHistory = useMemo(() => (
    getSensorHistory.data.mapOrDefault(({ result }) => (
      result[0].data
        .map(({ x, y }) => ({
          x,
          y: typeof y !== 'undefined'
            ? y
            : 0
        }))
        .sort((a, b) => (a.x > b.x ? 1 : -1))
    ), [])
  ), [getSensorHistory.data])

  const data = useMemo(() => ([
    {
      id: sensorInstanceId,
      data: sensorHistory
    }
  ]), [sensorInstanceId, sensorHistory])

  useEffect(() => {
    const time = bucket.item.split(' ')
    void getSensorHistory.fetch({
      sensorInstanceIds: [sensorInstanceId],
      startTime: dayjs()
        .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
        .format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: bucket.data.noOfRecords
    }, token)
  }, [sensorInstanceId])

  return (
    <SparkLine
      data={data}
      colors={['#09A6FF']}
      background="transparent"
      height={height}
      chartMargin={{
        top: 0,
        right: 0,
        bottom: 4,
        left: 0
      }}
    />
  )
}
