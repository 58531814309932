import { ScrollArea, Table } from '@mantine/core'
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  type Column
} from 'react-table'
import Pagination from './Pagination'
import Header from './Header'
import Rows from './Rows'

interface Props {
  data: object[]
  columns: Column[]
  hasPagination?: boolean
  hasFilters?: boolean
  hasSorting?: boolean
}

export default function DataGrid ({
  data = [],
  columns,
  hasPagination = false,
  hasFilters = false,
  hasSorting = false
}: Props) {
  const {
    state: {
      pageIndex,
      pageSize
    },
    headerGroups,
    rows,
    page,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    gotoPage,
    setPageSize
  } = useTable(
    {
      initialState: {
        pageSize: 10,
        pageIndex: 0
      },
      columns,
      data,
      disableFilters: !hasFilters,
      disableSortBy: !hasSorting
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <>
      <ScrollArea type="hover" scrollbarSize={5}>
        <Table striped {...getTableProps()} highlightOnHover>
          <thead>
            <Header
              headerGroups={headerGroups}
              hasFilters={hasFilters}
              hasSorting={hasSorting}
            />
          </thead>
          <tbody {...getTableBodyProps()}>
            <Rows
              items={hasPagination ? page : rows}
              prepareRow={prepareRow}
            />
          </tbody>
        </Table>
      </ScrollArea>
      {hasPagination && (
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRows={rows.length}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
        />
      )}
    </>
  )
}
