import { z } from 'zod'
import { createStatelessApi } from '../configure'
import { type PaginatedRequest } from '../shared'

const inmarsatMessage = z.object({
  id: z.number(),
  mailboxId: z.number(),
  callTime: z.string(),
  errorId: z.optional(z.number()),
  messageId: z.optional(z.number()),
  messageUtc: z.optional(z.string()),
  receiveUtc: z.optional(z.string()),
  sin: z.optional(z.number()),
  mobileId: z.optional(z.string()),
  rawPayload: z.optional(z.string()),
  decodedRawPayload: z.optional(z.string()),
  agentId: z.optional(z.number())
})

const inmarsatMessageLogs = z.object({
  hasMore: z.boolean(),
  items: z.array(inmarsatMessage)
})

export type InmarsatMessage = z.infer<typeof inmarsatMessage>

export const getInmarsatMessageLogs = createStatelessApi<PaginatedRequest, typeof inmarsatMessageLogs>(
  'config',
  '/inmarsat/message/logs',
  { method: 'GET', type: 'path' },
  inmarsatMessageLogs
)
