import { useEffect, useState } from 'react'
import { createStyles, Image, Text } from '@mantine/core'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'

const useStyles = createStyles(theme => ({
  text: {
    color: theme.white
  },
  logo: {
    display: 'inline-block',
    marginTop: 7,
    width: 120
  }
}))

function DefaultLogo () {
  const { classes } = useStyles()

  return (
    <Image
      className={classes.logo}
      src="/venturi-logo.png"
      alt="Main Logo"
      width={120}
      height={50}
      fit="contain"
    />
  )
}

export interface Props {
  orgId: number
}

export default function PoweredBy ({ orgId }: Props) {
  const { classes } = useStyles()
  const [fullUrl, setFullUrl] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    void configure(getHost())
      .then(({ user }) => {
        setFullUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=bottomLogo&time=${Date.now()}`)
      })
  }, [])

  if (!fullUrl) return null

  return (
    <>
      <Text size="sm" className={classes.text}>
        Powered by:
      </Text>
      {!hasError
        ? (
          <Image
            className={classes.logo}
            src={fullUrl}
            alt="Bottom Logo"
            width={120}
            height={50}
            fit="contain"
            onError={() => {
              setHasError(true)
            }}
          />
          )
        : <DefaultLogo />}
    </>
  )
}
