import Reactour, { type ReactourStep } from 'reactour'
import { useMantineTheme, Button } from '@mantine/core'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export interface Props {
  steps: ReactourStep[]
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  setIsDone?: (status: boolean) => void
  onClickLastStepNextButton: () => void
}

export default function Tour ({
  steps,
  isOpen,
  setIsOpen,
  onClickLastStepNextButton
}: Props) {
  const theme = useMantineTheme()

  const NextTourButton = (
    <Button onClick={onClickLastStepNextButton} color="primary">
      Next
    </Button>
  )

  return (
    <Reactour
      steps={steps}
      accentColor={theme.colors.primary[6]}
      rounded={10}
      isOpen={isOpen}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      lastStepNextButton={NextTourButton}
      closeWithMask={false}
      showCloseButton={false}
      disableInteraction
    />
  )
}
