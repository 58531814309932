import { type RefObject, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useApi } from 'src/utils/useApi'
import { getTripDetails } from '@venturi-io/api/src/collector/trip'
import { useUser } from 'src/UserContext'
import { type MapRef } from 'react-map-gl'
import { randomHexColor } from '../mapbox'
import { type FocusManagerRef } from '../FocusManager'
import { getTripContext } from './AnimationContext'

interface Props {
  mapRef: RefObject<MapRef>
  focusRef: RefObject<FocusManagerRef>
}

let lastLoadedTripId = 0
function URLTripLoader ({ mapRef, focusRef }: Props) {
  const { token } = useUser()
  const [params] = useSearchParams()
  const [trip, setTrip] = useState({
    tripId: Number(params.get('tripId')),
    startTime: params.get('startTime')
  })
  const tripDetails = useApi(getTripDetails)
  const [color, setColor] = useState(randomHexColor())
  const { initialSetup, trip: activeTrip, exitAnimation } = useContext(getTripContext())

  const loadTrip = () => {
    const { tripId, startTime } = trip
    if (
      mapRef.current &&
      startTime &&
      tripId !== lastLoadedTripId &&
      tripId !== 0
    ) {
      lastLoadedTripId = tripId
      setColor(randomHexColor())
      void tripDetails.fetch({ tripId, startTime }, token)
    }
  }

  const clearAnimation = () => {
    if (activeTrip) {
      exitAnimation()
    }
  }

  useEffect(() => {
    clearAnimation()
    const newTripId = Number(params.get('tripId'))
    const newStartTime = params.get('startTime')
    const { tripId, startTime } = trip
    if (newTripId !== tripId && newStartTime !== startTime) {
      setTrip({
        tripId: newTripId,
        startTime: newStartTime
      })
    }
  }, [params])

  useEffect(() => {
    loadTrip()
  }, [trip, mapRef.current])

  useEffect(() => {
    tripDetails.data.ifJust((data) => {
      void initialSetup({ ...data, color })
      if (focusRef.current) {
        focusRef.current.changeFocusState(false)
      }
    })
  }, [tripDetails.data])

  return null
}

export default URLTripLoader
