import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Group, Paper, Text, type MantineTheme } from '@mantine/core'
import parseIconStyle from 'src/utils/parseIconStyle'
import valueMap from 'src/utils/valueMap'
import { checkIfAlarm } from 'src/utils/status'
import TextTooltip from 'src/Layout/TextTooltip'
import type { Sensor as AgentSensor } from '@venturi-io/api/src/config/agent'

export default function Sensor ({
  name,
  currentValue,
  unit,
  alarmStatus,
  iconStyle,
  valueMap: map
}: AgentSensor) {
  const isAlarm = checkIfAlarm(alarmStatus)
  const containerStyles = (theme: MantineTheme) => (isAlarm
    ? {
        border: '3px solid #E40000',
        boxShadow: `${theme.shadows.sm}, 0px 0px 10px 0px #E40000 inset`
      }
    : {})

  return (
    <Paper
      p="lg"
      radius="md"
      shadow="sm"
      sx={containerStyles}
    >
      <Group position="apart">
        <Group spacing="sm">
          <FontAwesomeIcon icon={parseIconStyle(iconStyle ?? '')} color="#a3a3a3" size="2x" />
          <TextTooltip
            weight="bolder"
            value={name}
            maxLength={20}
          />
        </Group>
        <Group spacing="lg">
          <Text>
            {valueMap(map ?? {}, currentValue ?? '')}
            &nbsp;
            {unit ?? ''}
          </Text>
          {isAlarm
            ? <FontAwesomeIcon icon={['fas', 'times-square']} color="red" size="2x" />
            : <FontAwesomeIcon icon={['fas', 'check-square']} color="green" size="2x" />}
        </Group>
      </Group>
    </Paper>
  )
}
