export function getHost () {
  if (process.env.REACT_APP_HOSTNAME) {
    return process.env.REACT_APP_HOSTNAME
  }

  const host = window.location.host

  return host.match(/localhost/)
    ? process.env.REACT_APP_LOCAL_HOSTNAME ?? 'localhost:3000'
    : host
}
