import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { type Props } from '..'
import MobileTable from './MobileTable'
import DesktopTable from './DesktopTable'

export default function Table ({ transactions }: Props) {
  const { breakpoints: { lg } } = useMantineTheme()
  const isLargerScreen = useMediaQuery(mq(lg, false))

  return isLargerScreen
    ? <DesktopTable transactions={transactions} />
    : <MobileTable transactions={transactions} />
}
