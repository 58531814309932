import { Stack } from '@mantine/core'
import Logs from './Logs'

export default function InmarsatLogs () {
  return (
    <Stack
      spacing={0}
      sx={{
        position: 'absolute',
        width: '97%',
        height: '95%'
      }}
    >
      <Logs />
    </Stack>
  )
}
