import { Text } from '@mantine/core'
import PlaceholderContainer from './PlaceholderContainer'

interface Props {
  message?: string
}

export default function NoData ({ message }: Props) {
  return (
    <PlaceholderContainer>
      <Text>{message ?? 'No data to display'}</Text>
    </PlaceholderContainer>
  )
}
