import { useCallback, useEffect, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import {
  type GeoZoneRuleWithActions,
  getGeoZoneRulesAndActions
} from '@venturi-io/api/src/config/geoZone'
import { deleteGeoZoneRuleAction } from '@venturi-io/api/src/config/geoZoneRuleAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { MODES } from '../../constants'
import { type GeoZoneData } from '..'
import { type MapMode } from '../../shared'
import FormAddRule from './FormAddRule'

const useStyles = createStyles((theme) => ({
  id: {
    backgroundColor: theme.colors.primary[5],
    padding: '3px 9px',
    borderRadius: 20,
    textAlign: 'center'
  },
  description: {
    display: 'inline',
    textAlign: 'justify'
  }
}))

interface Props {
  mode: MapMode
  selectedGeoZone: GeoZoneData
}

export default function GeoZoneRules ({ mode, selectedGeoZone }: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const findGeoZoneRulesAndActions = useApi(getGeoZoneRulesAndActions)
  const { showSuccess, showError } = useNotifications()
  const [isAddingRule, setIsAddingRule] = useState<boolean>(false)
  const [toDeleteIds, setToDeleteIds] = useState<number[]>([])
  const [deleteSubject, setDeleteSubject] = useState<string>('')
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const inEditMode = mode?.id === MODES.EDITING

  const geoZoneRulesAndActions: GeoZoneRuleWithActions[] = findGeoZoneRulesAndActions.data.mapOrDefault(
    ({ geoZoneRules }) => geoZoneRules ?? []
    , [])

  const loadRulesAndActions = useCallback(() => {
    void findGeoZoneRulesAndActions.fetch({ geoZoneId: selectedGeoZone.geoZoneId }, token)
  }, [selectedGeoZone])

  const handleDelete = useCallback(() => {
    setIsDeleting(true)

    const queries = toDeleteIds.map(async geoZoneRuleActionId => {
      const query = new Promise<string>((resolve, reject) => {
        void deleteGeoZoneRuleAction({ geoZoneRuleActionId }, token)
          .caseOf({
            Left: err => {
              reject(err)
            },
            Right: () => resolve(`Successfully deleted ${deleteSubject}`)
          })
      })
      return await query
    })

    void Promise.all(queries)
      .then(() => {
        showSuccess(`Successfully deleted ${deleteSubject}`)
        loadRulesAndActions()
      })
      .catch(() => {
        showError(new Error(`Failed to delete ${deleteSubject}`))
      })
      .finally(() => {
        setIsDeleting(false)
        setShowDelete(false)
      })
  }, [toDeleteIds])

  useEffect(() => {
    void loadRulesAndActions()
  }, [selectedGeoZone])

  return (
    <>
      <Stack mx="xs">
        {geoZoneRulesAndActions.length === 0 && !isAddingRule && (
          <Text my={10} align="center" italic >
            ** No rules assigned **
          </Text>
        )}
        <Stack spacing={30}>
          {geoZoneRulesAndActions.map(({ geoZoneRuleId, name, description, geoZoneActions }, i) => (
            <Box key={geoZoneRuleId}>
              <Group position="apart">
                <Group spacing={10}>
                  <Text className={classes.id} size="xs" weight="bold" color="white">{i + 1}</Text>
                  <Text size="xs" weight="bold">{name}</Text>
                </Group>
                {inEditMode && (
                  <ActionIcon
                    title="Delete Rule"
                    variant="transparent"
                    onClick={() => {
                      setToDeleteIds(geoZoneActions.map(({ geoZoneRuleActionId }) => geoZoneRuleActionId))
                      setDeleteSubject('Rule')
                      setShowDelete(true)
                    }}
                    disabled={!inEditMode}
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} color="red" size="xs" />
                  </ActionIcon>
                )}
              </Group>
              <Box mt={5} px={6}>
                <FontAwesomeIcon icon={['far', 'circle-info']} color="gray" size="lg" />
                <Text
                  className={classes.description}
                  ml={8}
                  color="gray"
                  weight="lighter"
                  italic
                >
                  {description}
                </Text>
              </Box>
              <Stack mt={12} mx={6} spacing={5}>
                <Divider label="Actions" />
                <Stack spacing="xs">
                  {geoZoneActions.map(({ geoZoneRuleActionId, geoZoneActionId, name, description }) => (
                    <Paper key={geoZoneActionId} p="md" withBorder>
                      <Group position="apart">
                        <Group spacing={5}>
                          <FontAwesomeIcon icon={['far', 'wand-sparkles']} color="black" size="1x" />
                          <Text weight="bold">{name}</Text>
                        </Group>
                        {inEditMode && (
                          <ActionIcon
                            title="Delete Action"
                            variant="transparent"
                            onClick={() => {
                              setToDeleteIds([geoZoneRuleActionId])
                              setDeleteSubject('Action')
                              setShowDelete(true)
                            }}
                            disabled={!inEditMode}
                          >
                            <FontAwesomeIcon icon={['fas', 'remove']} color="silver" size="xs" />
                          </ActionIcon>
                        )}
                      </Group>
                      <Text mt={0} color="gray" weight="lighter" italic>{description}</Text>
                    </Paper>
                  ))}
                </Stack>
              </Stack>
            </Box>
          ))}
          {isAddingRule && (
            <FormAddRule
              geoZoneId={selectedGeoZone.geoZoneId}
              existingRules={geoZoneRulesAndActions.map(({ geoZoneRuleId }) => geoZoneRuleId)}
              onCreate={() => {
                loadRulesAndActions()
                setIsAddingRule(false)
              }}
            />
          )}
        </Stack>

        {inEditMode && (
          isAddingRule
            ? (
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['far', 'ban']} color="white" />}
                onClick={() => setIsAddingRule(false)}
                disabled={!inEditMode}
              >
                Cancel
              </Button>
              )
            : (
              <Button
                color="primary"
                leftIcon={<FontAwesomeIcon icon={['far', 'plus']} color="white" />}
                onClick={() => setIsAddingRule(true)}
                disabled={!inEditMode}
              >
                Add Rule
              </Button>
              )
        )}
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting ${deleteSubject}`}
        loading={isDeleting}
        question={`Are you sure you want to delete this ${deleteSubject.toLowerCase()}? This cannot be undone.`}
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
        centered
      />
    </>
  )
}
