import { Tooltip, type TooltipProps } from '@mantine/core'
import { type ReactNode } from 'react'

interface ControlTooptipProps extends TooltipProps {
  label: string
  children: ReactNode
  disabled?: boolean
}

const ControlTooltip = ({ disabled = false, label, position = 'top', children }: ControlTooptipProps) => (
  <Tooltip
    disabled={disabled}
    label={label}
    position={position}
    transitionProps={{
      transition: 'pop',
      duration: 200
    }}
    withArrow
    withinPortal
  >
    {children}
  </Tooltip>
)

export default ControlTooltip
