import { Tooltip, type TooltipProps } from '@mantine/core'

interface Props extends TooltipProps {
  isSidebarOpen?: boolean
  children: JSX.Element
}

export default ({ isSidebarOpen, children, ...props }: Props) => (
  (!isSidebarOpen)
    ? (
      <Tooltip
        withArrow
        withinPortal
        position="right"
        offset={-210}
        transitionProps={{
          transition: 'scale-x',
          duration: 200
        }}
        {...props}
      >
        {children}
      </Tooltip>
      )
    : children
)
