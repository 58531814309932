import { Box, Stack, Title } from '@mantine/core'
import Bar from '../Charts/Bar'
import { lightTextColor, darkTextColor } from '.'

const data = [
  {
    month: 'Q1',
    online: 30,
    offline: 12,
    disconnected: 8
  },
  {
    month: 'Q2',
    online: 24,
    offline: 16,
    disconnected: 10
  },
  {
    month: 'Q3',
    online: 23,
    offline: 10,
    disconnected: 17
  },
  {
    month: 'Q4',
    online: 36,
    offline: 10,
    disconnected: 4
  }
]

interface Props {
  lightModeOn: boolean
}

export default function Bars ({ lightModeOn = false }: Props) {
  return (
    <Box>
      <Stack spacing={2}>
        <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
          Connection State (Bar-V)
        </Title>
        <Bar
          height={400}
          data={data}
          keys={[
            'online',
            'offline',
            'disconnected'
          ]}
          indexBy="month"
          margin={{
            top: 50,
            right: 130,
            bottom: 50,
            left: 60
          }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              },
              legend: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              }
            },
            legends: {
              text: {
                fill: lightModeOn
                  ? lightTextColor
                  : darkTextColor
              }
            }
          }}
          colors={{ scheme: 'set3' }}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Quarter',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'No. of Assets',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
        />
      </Stack>
      <Stack spacing={2}>
        <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
          Connection State (Bar-H)
        </Title>
        <Bar
          height={400}
          data={data}
          keys={[
            'online',
            'offline',
            'disconnected'
          ]}
          indexBy="month"
          layout="horizontal"
          margin={{
            top: 50,
            right: 130,
            bottom: 50,
            left: 60
          }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              },
              legend: {
                text: {
                  fill: lightModeOn
                    ? lightTextColor
                    : darkTextColor
                }
              }
            },
            legends: {
              text: {
                fill: lightModeOn
                  ? lightTextColor
                  : darkTextColor
              }
            }
          }}
          colors={{ scheme: 'paired' }}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'No. of Assets',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Quarter',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
        />
      </Stack>
    </Box>
  )
}
