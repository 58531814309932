import { Stack } from '@mantine/core'
import { useState } from 'react'
import ModeSwitch, { type Mode } from '../ModeSwitch'
import Bars from './Bars'
import Lines from './Lines'
import Pies from './Pies'
import Gauges from './Gauges'

export const lightTextColor = '#323232'
export const darkTextColor = '#F7F7F7'

export default function ComponentsDemo () {
  const [mode, setMode] = useState<Mode>('Light')
  const lightModeOn = mode === 'Light'

  return (
    <>
      <ModeSwitch mode={mode} setMode={setMode} />
      <Stack
        spacing="xl"
        style={{
          padding: 10,
          background: lightModeOn
            ? 'transparent'
            : '#2e2e2e',
          transition: 'background .3s ease-in'
        }}
      >
        <Bars lightModeOn={lightModeOn} />
        <Lines lightModeOn={lightModeOn} />
        <Pies lightModeOn={lightModeOn} />
        <Gauges lightModeOn={lightModeOn} />
      </Stack>
    </>
  )
}
