import {
  createStyles,
  Divider,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { useCallback } from 'react'
import { Marker, type MarkerDragEvent } from 'react-map-gl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type LocationMark } from './shared'

export interface Props {
  marker: LocationMark
  canPan: boolean
  setMarker: (value: LocationMark) => void
  onMarkerChange?: (value: LocationMark) => void
  showInformation?: boolean
}

const useStyles = createStyles(theme => ({
  panel: {
    width: '200px',
    position: 'absolute',
    top: 15,
    left: 15,
    opacity: 0.8,
    background: theme.colors.dark[8]
  },
  text: {
    color: theme.colors.gray[0]
  }
}))

export default function LocationMarker ({ marker, canPan, onMarkerChange, setMarker, showInformation = false }: Props) {
  const { classes } = useStyles()

  const onDragEnd = useCallback(({ lngLat }: MarkerDragEvent) => {
    const locationMarker: LocationMark = {
      longitude: lngLat.lng,
      latitude: lngLat.lat
    }

    setMarker(locationMarker)

    if (onMarkerChange) {
      onMarkerChange(locationMarker)
    }
  }, [])

  return (
    <>
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        offset={[0, -10]}
        draggable={canPan}
        onDragEnd={onDragEnd}
      >
        <FontAwesomeIcon icon={['fas', 'location-dot']} color="red" size="lg" />
      </Marker>

      {showInformation && (
        <Paper p="lg" radius="sm" shadow="lg" withBorder className={classes.panel}>
          <Text size="sm" weight={700} className={classes.text}>Location Marker</Text>
          <Divider />
          <Stack spacing="xs">
            <div>
              <Text size="xs" weight={500} className={classes.text}>Longitude</Text>
              <Text size="xs" className={classes.text}>{marker.longitude}</Text>
            </div>
            <div>
              <Text size="xs" weight={500} className={classes.text}>Latitude</Text>
              <Text size="xs" className={classes.text}>{marker.latitude}</Text>
            </div>
          </Stack>
        </Paper>
      )}
    </>
  )
}
