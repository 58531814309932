import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Col, Grid, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { getIncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { EitherAsync, type Maybe, Just, Nothing } from 'purify-ts'
import { useUser } from 'src/UserContext'
import DateRangePicker from 'src/Input/DateRangePicker'
import Paper from 'src/Layout/Paper'
import ActionList from 'src/Layout/ActionList'
import { Header, Row, Card } from './List'

interface Props {
  sensorInstanceIds: number[]
}

export interface Incident {
  alarmId: number
  sensorInstanceId: number
  sensorInstanceName: string
  name: string
  operator: string
  incidentId: number
  detected: string
  cleared?: string
}

export default function Incidents ({ sensorInstanceIds }: Props) {
  const { token } = useUser()
  const [incidents, setIncidents] = useState<Maybe<Incident[]>>(Nothing)
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)
  const dateToday = new Date()
  const [value, setValue] = useState<[Date, Date]>([
    dayjs(dateToday).subtract(7, 'days').toDate(),
    dayjs(dateToday).toDate()
  ])

  const loadIncidents = () => {
    setLoading(true)

    void EitherAsync.all(
      sensorInstanceIds.map(sensorInstanceId =>
        getIncidentsSummary({
          sensorInstanceId: sensorInstanceId.toString(),
          startTime: value[0].toISOString(),
          endTime: value[1].toISOString(),
          page: 1,
          size: 10
        }, token))
    )
      .map(set => set.reduce<Incident[]>(
        (prev, current) =>
          prev.concat(
            current.items.map(incident => ({
              ...incident,
              alarmId: incident.alarmId,
              sensorInstanceId: current.attributes.sensorInstanceId,
              sensorInstanceName: current.attributes.sensorInstanceName,
              name: incident.name,
              operator: incident.operator
            }))
          ),
        [])
      )
      .map(i => i.sort((a, b) => (dayjs(a.detected).isAfter(dayjs(b.detected)) ? -1 : 1)))
      .caseOf({
        Left: err => {
          showNotification({
            title: 'Error!',
            message: err.message,
            color: 'red'
          })
          setError(err)
        },
        Right: result => {
          setIncidents(Just(result))
          setError(null)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (loading || incidents.isJust() || error !== null) return

    loadIncidents()
  }, [sensorInstanceIds, value[0].toString(), value[1].toString(), loading])

  const onChange = useCallback(([first, second]: [Date, Date]) => {
    const result: [Date, Date] = [...value]
    const firstIsNull = first === null
    const secondIsNull = second === null
    const isSameFirstDate = dayjs(first).diff(value[0], 'days') === 0
    const isSameSecondDate = dayjs(second).diff(value[1], 'days') === 0

    if (isSameFirstDate && isSameSecondDate) return

    if (!firstIsNull) {
      result[0] = first
    }

    if (!secondIsNull) {
      result[1] = second
    }

    setValue(result)
    setIncidents(Nothing)
  }, [value, incidents])

  return (
    <Paper relative>
      <Grid>
        <Col span={12} sm={9}>
          <Title order={4}>Incidents</Title>
        </Col>
        <Col span={12} sm={3}>
          <DateRangePicker
            placeholder="Choose date range"
            value={value}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <ActionList
            isLoading={loading}
            data={incidents.mapOrDefault(items => items, [])}
            getId={({ incidentId }) => incidentId}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={0}
          />
        </Col>
      </Grid>
    </Paper>
  )
}
