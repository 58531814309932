import {
  type ReactNode,
  useEffect,
  useState,
  useCallback,
  forwardRef
} from 'react'
import { type SelectProps, type SelectItem, Stack, Text } from '@mantine/core'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import {
  getSensorDataTransformers,
  type SensorDataTransformer
} from '@venturi-io/api/src/config/sensorDataTransformer'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  setExternalSensorId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  description: string
}

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <Stack py="xs" spacing={0} ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Text size="xs" color="dimmed">
        {description}
      </Text>
    </Stack>
  )
)

const sensorToSelect = ({ id, name, function: fn }: SensorDataTransformer): SelectItem => ({
  value: id.toString(),
  label: name,
  description: fn
})

export default function SelectScaleFactor ({
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const sensorDataTransformers = usePaginatedApi(getSensorDataTransformers)
  const [scaleFactorId, setScaleFactorId] = useState<Value>(null)

  const selectSensors = sensorDataTransformers.data.mapOrDefault(({ items }) => items.map(sensorToSelect), [])

  const loadSensors = () => {
    void sensorDataTransformers.fetch({
      page: 1,
      size: 100
    }, token)
  }

  const handleChange = useCallback((val: Value) => {
    setScaleFactorId(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    if (defaultValue) {
      setScaleFactorId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadSensors()
  }, [])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a scale factor'}
      data={selectSensors}
      defaultValue={defaultValue}
      value={value ?? scaleFactorId}
      itemComponent={SelectItemComponent}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || sensorDataTransformers.loading}
      error={error}
      {...props}
    />
  )
}
