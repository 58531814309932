import { useCallback, useRef } from 'react'
import { type AgentLog } from '@venturi-io/api/src/config/agent'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import Table from './Table'
import Row from './Row'

interface Props {
  width: number | string
  height: number | string
  logs: AgentLog[]
  loadMoreItems: (startIndex: number, stopIndex: number) => void | Promise<void>
  hasNextPage: boolean
}

export default function VirtualTable ({ width, height, logs, loadMoreItems, hasNextPage }: Props) {
  const listRef = useRef(null)
  const itemSize = 70
  const logsCount = logs.length
  const itemCount = hasNextPage
    ? logsCount + 1
    : logsCount

  const isItemLoaded = useCallback((index: number) => (
    !hasNextPage || (index < logsCount)
  ), [hasNextPage, logsCount])

  return (
    <InfiniteLoader
      ref={listRef}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          ref={ref}
          className="List"
          height={height}
          width={width}
          innerElementType={Table}
          itemCount={itemCount}
          itemSize={itemSize}
          itemData={logs}
          onItemsRendered={onItemsRendered}
          overscanCount={0}
        >
          {props => <Row {...props} isItemLoaded={isItemLoaded} />}
        </List>
      )}
    </InfiniteLoader>
  )
}
