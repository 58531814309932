import { memo, useCallback } from 'react'
import { Text } from '@mantine/core'
import FormSensorHistory from '../Items/SensorHistory/Properties'
import FormMultiSensorHistory from '../Items/MultiSensorHistory/Properties'
import FormStackedSensorAllAgents from '../Items/StackedSensorAllAgents/Properties'
import FormSensorGauge from '../Items/SensorGauge/Properties'
import FormSensorPercentage from '../Items/SensorPercentage/Properties'
import FormSensorStatus from '../Items/SensorStatus/Properties'
import FormMultiAgentSensorHistory from '../Items/MultiAgentSensorHistory/Properties'
import FormMultiAgentSensorHistoryTable from '../Items/MultiAgentSensorHistoryTable/Properties'
import FormAgentStatus from '../Items/AgentStatus/Properties'
import FormAgentQuickView from '../Items/AgentQuickView/Properties'
import FormMap from '../Items/Map/Properties'
import FormAlarmList from '../Items/AlarmList/Properties'
import FormDuressAlarms from '../Items/DuressAlarms/Properties'
import FormIFrame from '../Items/IFrame/Properties'
import FormSpeedAlerts from '../Items/SpeedAlerts/Properties'
import FormLastSeen from '../Items/LastSeen/Properties'
import FormConnectionState from '../Items/ConnectionState/Properties'
import FormMotionState from '../Items/MotionState/Properties'
import FormAssetsInGeozones from '../Items/AssetsInGeozones/Properties'
import FormEngineHours from '../Items/EngineHours/Properties'
import FormOdometer from '../Items/Odometer/Properties'
import {
  type Item,
  type ItemType,
  type SensorHistoryProps,
  type MultiSensorHistoryProps,
  type StackedSensorAllAgentsProps,
  type SensorGaugeProps,
  type SensorPercentageProps,
  type SensorStatusProps,
  type AgentStatusProps,
  type AgentQuickViewProps,
  type MultiAgentSensorHistoryProps,
  type MultiAgentSensorHistoryTableProps,
  type MapProps,
  type AlarmListProps,
  type DuressAlarmsProps,
  type IFrameProps,
  type SpeedAlertProps,
  type LastSeenProps,
  type ConnectionStateProps,
  type MotionStateProps,
  type AssetsInGeozonesProps,
  type EngineHourProps,
  type OdometerProps
} from '../Items'

interface Props {
  initialValues: Item | null
  type: ItemType
  onSubmit: (item: Item) => void
}

const Form = ({ initialValues, type, onSubmit }: Props) => {
  const handleSubmit = useCallback((item: Item) => onSubmit(item), [type])

  switch (type) {
    case 'Sensor History':
      return (
        <FormSensorHistory
          initialValues={initialValues as SensorHistoryProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Multi-Sensor History':
      return (
        <FormMultiSensorHistory
          initialValues={initialValues as MultiSensorHistoryProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Stacked Sensor - All Agents':
      return (
        <FormStackedSensorAllAgents
          initialValues={initialValues as StackedSensorAllAgentsProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Sensor Gauge':
      return (
        <FormSensorGauge
          initialValues={initialValues as SensorGaugeProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Sensor Percentage':
      return (
        <FormSensorPercentage
          initialValues={initialValues as SensorPercentageProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Sensor Status':
      return (
        <FormSensorStatus
          initialValues={initialValues as SensorStatusProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Multi-Agent Sensor History':
      return (
        <FormMultiAgentSensorHistory
          initialValues={initialValues as MultiAgentSensorHistoryProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Multi-Agent Sensor History (Table)':
      return (
        <FormMultiAgentSensorHistoryTable
          initialValues={initialValues as MultiAgentSensorHistoryTableProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Agent Status':
      return (
        <FormAgentStatus
          initialValues={initialValues as AgentStatusProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Agent Quick View':
      return (
        <FormAgentQuickView
          initialValues={initialValues as AgentQuickViewProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Map':
      return (
        <FormMap
          initialValues={initialValues as MapProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Alarm List':
      return (
        <FormAlarmList
          initialValues={initialValues as AlarmListProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Duress Alarms':
      return (
        <FormDuressAlarms
          initialValues={initialValues as DuressAlarmsProps}
          onSubmit={handleSubmit}
        />
      )

    case 'IFrame':
      return (
        <FormIFrame
          initialValues={initialValues as IFrameProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Speed Alerts':
      return (
        <FormSpeedAlerts
          initialValues={initialValues as SpeedAlertProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Last Seen':
      return (
        <FormLastSeen
          initialValues={initialValues as LastSeenProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Connection State':
      return (
        <FormConnectionState
          initialValues={initialValues as ConnectionStateProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Motion State':
      return (
        <FormMotionState
          initialValues={initialValues as MotionStateProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Assets in Geozones':
      return (
        <FormAssetsInGeozones
          initialValues={initialValues as AssetsInGeozonesProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Engine Hours':
      return (
        <FormEngineHours
          initialValues={initialValues as EngineHourProps}
          onSubmit={handleSubmit}
        />
      )

    case 'Odometer':
      return (
        <FormOdometer
          initialValues={initialValues as OdometerProps}
          onSubmit={handleSubmit}
        />
      )

    default:
      return <Text>Please select a dashboard item you want to add</Text>
  }
}

export default memo(Form)
