import {
  ActionIcon,
  Stack,
  Switch,
  Text,
  Group,
  Tooltip,
  Divider,
  FileInput,
  Collapse,
  Button
} from '@mantine/core'
import React, {
  type Context,
  createContext,
  useContext,
  useState,
  useEffect,
  type RefObject,
  useCallback
} from 'react'
import Paper from 'src/Layout/Paper'
import { useDisclosure, useHotkeys, useLocalStorage } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Papa from 'papaparse'
import TextInput from 'src/Input/TextInput'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useApi } from 'src/utils/useApi'
import { getTripDetails } from '@venturi-io/api/src/collector/trip'
import { useUser } from 'src/UserContext'
import { type MapRef } from 'react-map-gl'
import { randomHexColor, type LngLat } from '../../mapbox'
import { getTripContext } from '../AnimationContext'
interface ToolContext {
  isVisible: boolean
  showSequence: boolean
  showRaw: boolean
  showRawLine: boolean
  showSpeeding: boolean
  showCsv: boolean
  showBe: boolean
  showFe: boolean
  showDashLine: boolean
  showMarkerElements: boolean
  showMarkerLayer: boolean
  showIdling: boolean
  csvPoints: LngLat[] | null
  setCsvMapboxLength: React.Dispatch<React.SetStateAction<number>>
  setFeLocalLength: React.Dispatch<React.SetStateAction<number>>
  setShowSequence: React.Dispatch<React.SetStateAction<boolean>>
  setShowRaw: React.Dispatch<React.SetStateAction<boolean>>
  setShowRawLine: React.Dispatch<React.SetStateAction<boolean>>
  setShowSpeeding: React.Dispatch<React.SetStateAction<boolean>>
  setShowCsv: React.Dispatch<React.SetStateAction<boolean>>
  setShowBe: React.Dispatch<React.SetStateAction<boolean>>
  setShowFe: React.Dispatch<React.SetStateAction<boolean>>
  setShowDashLine: React.Dispatch<React.SetStateAction<boolean>>
  setShowMarkerElements: React.Dispatch<React.SetStateAction<boolean>>
  setShowMarkerLayer: React.Dispatch<React.SetStateAction<boolean>>
  setShowIdling: React.Dispatch<React.SetStateAction<boolean>>
}

let toolCtx: Context<ToolContext> | null = null
export function getToolContext (tools?: ToolContext) {
  if (toolCtx !== null) return toolCtx

  if (!tools) throw new Error('No tools context instantiated...')

  toolCtx = createContext(tools)

  return toolCtx
}

interface DevToolContextProps {
  showAgentDetails: boolean
  children?: React.ReactElement | JSX.Element
  mapRef: RefObject<MapRef>
}

function Devtools ({ children, showAgentDetails, mapRef }: DevToolContextProps) {
  const { token } = useUser()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [agentId] = useState<number | null>(
    params.get('agentId')
      ? Number(params.get('agentId'))
      : null
  )
  const [feLocalLength, setFeLocalLength] = useState(0)
  const [csvRawPoints, setCsvRawPoints] = useState(0)
  const [csvMapboxLength, setCsvMapboxLength] = useState(0)
  const [showSequence, setShowSequence] = useState(false)
  const [showRaw, setShowRaw] = useState(false)
  const [showRawLine, setShowRawLine] = useState(false)
  const [showSpeeding, setShowSpeeding] = useState(true)
  const [showCsv, setShowCsv] = useState(false)
  const [showBe, setShowBe] = useState(true)
  const [showFe, setShowFe] = useState(false)
  const [showMarkerElements, setShowMarkerElements] = useState(true)
  const [showMarkerLayer, setShowMarkerLayer] = useLocalStorage({
    key: 'markerLayerToggle',
    defaultValue: false
  })
  const [showDashLine, setShowDashLine] = useState(false)
  const [showIdling, setShowIdling] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const [tripId, setTripId] = useState<string | null>(null)

  // custom csv
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [csvPoints, setFromCsvPoints] = useState<LngLat[] | null>(null)

  const tripDetails = useApi(getTripDetails)
  const [color] = useState(randomHexColor())

  const { initialSetup } = useContext(getTripContext())
  const { trip } = useContext(getTripContext())

  const [openDetails, { toggle: toggleDetails }] = useDisclosure(false)
  const [openSwitches, { toggle: toggleSwitches }] = useDisclosure(false)
  const [openFields, { toggle: toggleFields }] = useDisclosure(false)

  useHotkeys([
    ['mod+shift+D', () => setIsVisible(!isVisible)]
  ])

  const values = {
    showSequence,
    showRaw,
    showRawLine,
    showSpeeding,
    showCsv,
    showBe,
    showFe,
    showDashLine,
    showMarkerLayer,
    showMarkerElements,
    showIdling,
    isVisible,
    csvPoints,
    setCsvMapboxLength,
    setFeLocalLength,
    setShowSequence,
    setShowRaw,
    setShowRawLine,
    setShowSpeeding,
    setShowCsv,
    setShowBe,
    setShowFe,
    setShowDashLine,
    setShowMarkerLayer,
    setShowMarkerElements,
    setShowIdling
  }

  const ToolContext = getToolContext(values)

  const handleClose = () => {
    setShowSequence(false)
    setShowRaw(true)
    setShowRawLine(false)
    setShowSpeeding(true)
    setShowBe(true)
    setShowFe(false)
    setShowDashLine(false)
    setIsVisible(false)
  }

  const readCsvFile = useCallback(() => {
    if (csvFile) {
      Papa.parse(csvFile, {
        complete: ({ data }) => {
          const stringData = data as string[][]
          // convert to json
          const jsonData: LngLat[] = stringData.reduce<LngLat[]>((acc, item) => {
            if (item[0].includes('POINT')) {
              const [longitude, latitude] = item[0]
                .replace('POINT(', '')
                .replace(')', '')
                .split(' ')
              return [
                ...acc,
                {
                  longitude: parseFloat(longitude),
                  latitude: parseFloat(latitude)
                }
              ]
            }
            return acc
          }, [])
          setCsvRawPoints(jsonData.length)
          setFromCsvPoints(jsonData)
        }
      })
    } else {
      setFromCsvPoints(null)
    }
  }, [csvFile])

  const handleNavigateToPoint = useCallback(() => {
    if (mapRef.current && csvPoints) {
      const { longitude, latitude } = csvPoints[0]
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 16
      })
    }
  }, [mapRef.current, csvPoints])

  const handleChangeTrip = useCallback(() => {
    if (!tripId) return
    const url = agentId
      ? `/?agentId=${agentId}&tripId=${tripId}`
      : `/?tripId=${tripId}`
    navigate(url, { replace: true })
    // call trip API
    void tripDetails.fetch({ tripId: parseInt(tripId) }, token)
  }, [tripId, agentId, token])

  useEffect(() => {
    void readCsvFile()
  }, [csvFile])

  useEffect(() => {
    tripDetails.data.ifJust((data) => {
      void initialSetup({ ...data, color })
    })
  }, [tripDetails.data])

  return (
    <ToolContext.Provider value={values}>
      <>
        {isVisible && (
          <Paper
            p="lg"
            mt="lg"
            shadow="xl"
            sx={{
              transition: 'all ease-in-out .4s',
              zIndex: 91,
              position: 'absolute',
              top: showAgentDetails ? 0 : 40,
              right: showAgentDetails ? 360 : 20,
              minWidth: 250
            }}
          >
            <Stack spacing="xs">
              <Group position="apart">
                <Text size="md" weight="bold">Mapbox Devtool</Text>
                <ActionIcon size="sm" onClick={handleClose}>
                  <FontAwesomeIcon icon={['fas', 'close']} />
                </ActionIcon>
              </Group>
              <Divider />
              <Button
                color="primary"
                variant={openFields ? 'filled' : 'light'}
                size="xs"
                onClick={toggleFields}
                leftIcon={<FontAwesomeIcon icon={['far', 'pen']} />}
              >
                Fields
              </Button>
              <Collapse in={openFields}>
                <Stack spacing="xs">
                  <Group align="flex-end" spacing="xs">
                    <TextInput
                      label="tripId"
                      placeholder="Enter tripId"
                      size="xs"
                      onChange={(e) => setTripId(e.target.value)}
                      sx={{ flexGrow: 1 }}
                    />
                    <ActionIcon
                      loading={tripDetails.loading}
                      variant="filled"
                      color="green"
                      onClick={handleChangeTrip}
                    >
                      <FontAwesomeIcon icon={['far', 'check']} />
                    </ActionIcon>
                  </Group>
                  <Group align="flex-end" spacing="xs">
                    <FileInput
                      label="CSV File"
                      value={csvFile}
                      clearable
                      placeholder="Select a csv file here"
                      onChange={file => setCsvFile(file)}
                      size="xs"
                      sx={{ flexGrow: 1 }}
                    />
                    <ActionIcon
                      variant="filled"
                      color="blue"
                      onClick={handleNavigateToPoint}
                    >
                      <FontAwesomeIcon icon={['far', 'location-circle']} />
                    </ActionIcon>
                  </Group>
                </Stack>
              </Collapse>
              <Button
                color="primary"
                variant={openSwitches ? 'filled' : 'light'}
                size="xs"
                onClick={toggleSwitches}
                leftIcon={<FontAwesomeIcon icon={['far', 'light-switch-on']} />}
              >
                Switches
              </Button>
              <Collapse in={openSwitches}>
                <Stack spacing="xs">
                  <Switch
                    color="primary"
                    checked={showMarkerLayer}
                    onClick={() => setShowMarkerLayer(!showMarkerLayer)}
                    size="xs"
                    label="Marker as layer"
                  />
                  <Switch
                    color="primary"
                    checked={showCsv}
                    onClick={() => setShowCsv(!showCsv)}
                    size="xs"
                    label="csv points (yellow)"
                  />
                  <Switch
                    color="primary"
                    checked={showSequence}
                    onClick={() => setShowSequence(!showSequence)}
                    size="xs"
                    label="mapbox sequence"
                  />
                  <Switch
                    color="primary"
                    checked={showRaw}
                    onClick={() => setShowRaw(!showRaw)}
                    size="xs"
                    label={(
                      <Tooltip
                        withinPortal
                        position="top"
                        label="Make sure to toggle 'Show raw points' first"
                      >
                        <Text size="xs" underline>
                          raw point (arrow) (?)
                        </Text>
                      </Tooltip>
                    )}
                  />
                  <Switch
                    color="primary"
                    checked={showRawLine}
                    onClick={() => setShowRawLine(!showRawLine)}
                    size="xs"
                    label="raw point (blue thin line)"
                  />
                  <Switch
                    color="primary"
                    checked={showSpeeding}
                    onClick={() => setShowSpeeding(!showSpeeding)}
                    size="xs"
                    label="speeding (pulsating)"
                  />
                  <Switch
                    color="primary"
                    checked={showIdling}
                    onClick={() => setShowIdling(!showIdling)}
                    size="xs"
                    label="idling (orange circle)"
                  />
                  <Switch
                    color="primary"
                    checked={showBe}
                    onClick={() => setShowBe(!showBe)}
                    size="xs"
                    label="BE generated mapbox"
                  />
                  <Switch
                    color="primary"
                    checked={showFe}
                    onClick={() => setShowFe(!showFe)}
                    size="xs"
                    label="FE generated mapbox (red)"
                  />
                  <Switch
                    color="primary"
                    checked={showDashLine}
                    onClick={() => setShowDashLine(!showDashLine)}
                    size="xs"
                    label="BE Dash line (animated)"
                  />
                </Stack>
              </Collapse>
              <Button
                color="primary"
                variant={openDetails ? 'filled' : 'light'}
                size="xs"
                onClick={toggleDetails}
                leftIcon={<FontAwesomeIcon icon={['far', 'list']} />}
              >
                Points
              </Button>
              <Collapse in={openDetails}>
                <Stack spacing={0}>
                  <Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      BE raw points:
                    </Text>
                    {' '}
                    {trip?.rawPath.length}
                  </Text>
                  <Text>
                    <Text
                      weight="bold"
                      component="span"
                    >

                      BE mapbox points:
                    </Text>
                    {' '}
                    {trip?.mapboxPath.length}
                  </Text>
                  <Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      FE mapbox points:
                    </Text>
                    {' '}
                    {feLocalLength ?? 0}
                  </Text>
                  <Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      CSV raw points:
                    </Text>
                    {' '}
                    {csvRawPoints ?? 0}
                  </Text>
                  <Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      CSV mapbox points:
                    </Text>
                    {' '}
                    {csvMapboxLength ?? 0}
                  </Text>
                </Stack>
              </Collapse>
            </Stack>
          </Paper>
        )}
        {children}
      </>
    </ToolContext.Provider>
  )
}

export default Devtools
