import { memo, useCallback, useEffect, useState } from 'react'
import { ActionIcon, Tooltip } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  isFavourite: boolean
  disabled?: boolean
  onClick?: (isFavourite: boolean) => void
}

const Favorite = ({ isFavourite, disabled = false, onClick }: Props) => {
  const [isStarred, setIsStarred] = useState(isFavourite)

  const handleClick = useCallback(() => {
    const status = !isStarred

    setIsStarred(status)

    if (onClick) {
      onClick(status)
    }
  }, [isStarred, onClick])

  useEffect(() => {
    setIsStarred(isFavourite)
  }, [isFavourite])

  return (
    <Tooltip
      label={isStarred ? 'Remove from Favourites' : 'Add to Favorites'}
      position="right"
      color="yellow"
      transitionProps={{
        transition: 'pop',
        duration: 200
      }}
      disabled={disabled}
      withArrow
    >
      <ActionIcon>
        <FontAwesomeIcon
          size="1x"
          color={isStarred ? 'orange' : 'gray'}
          icon={[
            isStarred ? 'fas' : 'far',
            'star'
          ]}
          shake={isStarred}
          style={{
            animationDuration: '1.3s',
            animationIterationCount: 1
          }}
          onClick={!disabled ? handleClick : undefined}
        />
      </ActionIcon>
    </Tooltip>
  )
}

export default memo(Favorite)
