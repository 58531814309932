import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { type User } from '@venturi-io/api/src/userManager/user'
import { getUsers } from '@venturi-io/api/src/userManager/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  excludeUserIds?: number[]
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const userToSelect = ({ orgUserId, firstName, lastName }: User): SelectItem => ({
  value: orgUserId.toString(),
  label: `${firstName} ${lastName}`
})

export default function MultiSelectUser ({
  excludeUserIds,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const users = useApi(getUsers)
  const [value, setValue] = useState<Value>(props.value ?? [])
  const selectUsers = users.data.mapOrDefault(data => (
    data
      .filter(({ orgUserId }) => !excludeUserIds?.includes(orgUserId))
      .map(userToSelect)
  ), [])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void users.fetch({
      orgId,
      search: 'ACTIVE'
    }, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose user(s)'}
      value={value}
      data={selectUsers}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || users.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
