import { Box, Text, createStyles } from '@mantine/core'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'

interface Props {
  fuelLevelLiterAlarms: Alarm[]
  fuelCapacityLiter: number
}

const useStyles = createStyles(() => ({
  labelStyles: {
    position: 'absolute',
    top: 0,
    left: '8px',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  },
  lineStyles: {
    height: 2,
    background: 'rgba(0, 0, 0, 0.4)',
    transform: ' translate(10px, -8px)',
    display: 'block'
  },
  textStyles: {
    color: 'rgba(0, 0, 0, 0.40)',
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    opacity: '.2'
  }
}))

export default function MarkersAnnotationMid ({
  fuelLevelLiterAlarms,
  fuelCapacityLiter
}: Props) {
  const { classes } = useStyles()
  const getLowestAlarm =
    fuelLevelLiterAlarms?.map(alarm => Math.floor((alarm.setPoint / fuelCapacityLiter) * 100))

  return (
    <Box h="100%" className={classes.labelStyles}>
      {fuelLevelLiterAlarms?.map(alarm => {
        const alarmPos = Math.floor((alarm.setPoint / fuelCapacityLiter) * 100)

        return (
          <Text
            size={12}
            weight={400}
            key={alarm.setPoint}
            className={classes.textStyles}
            style={{
              bottom: (alarmPos - 3) + '%'
            }}
          >
            ►
            {' '}
            <span
              className={classes.lineStyles}
              style={{
                width: Math.min(...getLowestAlarm) === alarmPos
                  ? 55
                  : 24
              }}
            />
          </Text>
        )
      }).sort((a, b) => Number(b.key) - Number(a.key))}
    </Box>
  )
}
