import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 10
  },
  pageNumber: {
    fontSize: 9,
    color: 'gray'
  }
})

export default function Footer () {
  return (
    <View style={styles.container} fixed>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )}
        fixed
      />
    </View>
  )
}
