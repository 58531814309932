import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { type TankTransaction } from '@venturi-io/api/src/config/fms'
import { type ReactElement, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  IconArrowBigLeftLine,
  IconArrowBigRightLine,
  IconBolt,
  IconCar,
  IconClipboardText
} from '@tabler/icons-react'
import { type AssetType } from '@venturi-io/api/src/config/agent'
import Table from './Table'

export interface Props {
  transactions: TankTransaction[]
}

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

type Flow = 'IN' | 'OUT'

const useStyles = createStyles(() => ({
  titleContainer: {
    position: 'relative'
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'row'
  },
  container: {
    height: '90%',
    width: '100%',
    background: 'white'
  },
  button: {
    span: {
      marginRight: 5
    }
  }
}))

export const assetTypeIcon: Record<AssetType, ReactElement | undefined> = {
  GENSET: <IconBolt size={12} stroke={2} />,
  VEHICLE: <IconCar size={12} stroke={2} />,
  TANK: <IconBolt size={12} stroke={2} />,
  '3P-POWER': <IconBolt size={12} stroke={2} />,
  'POWER METER': <IconBolt size={12} stroke={2} />,
  ATS: <IconBolt size={12} stroke={2} />,
  DURESS: <IconBolt size={12} stroke={2} />,
  UPS: <IconBolt size={12} stroke={2} />,
  OTHERS: undefined
}

export const flowTypeIcon: Record<Flow, ReactElement> = {
  IN: <IconArrowBigLeftLine size={12} stroke={2} />,
  OUT: <IconArrowBigRightLine size={12} stroke={2} />
}

export default function Transactions ({ transactions }: Props) {
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { assetId } = useParams<RouteParams>()

  if (!assetId) {
    throw new Error('No asset ID given, routing is broken')
  }

  const handleRedirectToListPage = useCallback(() => {
    navigate(`/assets/tank/${assetId}/transactions`)
  }, [assetId])

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Group mb="sm" position="apart" noWrap>
          <Text
            size={14}
            weight={600}
            color="#212121"
          >
            Recent Transactions
          </Text>
          <Button
            px={6}
            variant="outline"
            radius={4}
            h={24}
            className={classes.button}
            leftIcon={(
              <ActionIcon
                color="228BE6"
                variant="transparent"
                size="xs"
              >
                <IconClipboardText height={12} width={12} />
              </ActionIcon>
            )}
            onClick={handleRedirectToListPage}
          >
            <Text
              size={12}
              weight={400}
              color="#228BE6"
            >
              All Transactions
            </Text>
          </Button>
        </Group>
      </Box>
      <Box className={classes.content}>
        <Table transactions={transactions} />
      </Box>
    </Paper>
  )
}
