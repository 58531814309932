import { useState, useEffect } from 'react'
import { ActionIcon, Box, Group, Paper } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { type Layout, type Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getCurrentBreakpoint, getMantineBreakpointsInPx } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { gridColumns } from '../shared'
import MissingSensorMessage from '../MissingSensorMessage'
import ModuleContainer from './ModuleContainer'
import { defaultLayouts } from './data'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

export default function AtsDashboard () {
  const agentDetails = useApi(getAgentDetails)
  const { token } = useUser()
  const [missingSensors, setMissingSensors] = useState<string[]>([])

  const { assetId } = useParams<RouteParams>()
  if (!assetId) throw new Error('No asset ID given, routing is broken')

  const breakpoints = getMantineBreakpointsInPx()
  const [layouts, setLayouts] = useLocalStorage({
    key: 'ats-dashboard-rgl',
    defaultValue: defaultLayouts
  })
  const [currentLayout, setCurrentLayout] = useState<Layout[]>([])
  const [currentBreakpoint, setCurrentBreakpoint] = useState('xxs')
  const initialBreakpoint = getCurrentBreakpoint()

  const onLayoutChange = (layout: Layout[]) => setCurrentLayout(layout)

  const onBreakpointChange = (breakpoint: string) => setCurrentBreakpoint(breakpoint)

  const onSave = () => {
    const updatedLayouts: Layouts = {
      ...layouts,
      [currentBreakpoint]: currentLayout
    }

    setLayouts(updatedLayouts)
    showNotification({
      title: 'Success',
      message: 'Layout successfully saved!',
      color: 'green'
    })
  }

  const onReset = () => {
    setLayouts(defaultLayouts)
    showNotification({
      title: 'Success',
      message: 'Layout has been reset!',
      color: 'green'
    })
  }

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  useEffect(() => {
    setCurrentBreakpoint(initialBreakpoint)
  }, [initialBreakpoint])

  useEffect(() => {
    const agentId = parseInt(assetId)

    void agentDetails
      .fetch({ agentId }, token)
      .finally(() => {
        agentDetails.startPolling({ agentId }, token, 60)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [])

  return (
    <Box mt="lg" >
      {missingSensors.length > 0 && (
        <MissingSensorMessage assetName={agent?.agentName} missingSensors={missingSensors} />
      )}
      <Paper p="sm" shadow="sm">
        <Group spacing="xs" position="right">
          <ActionIcon onClick={onSave} title="Save Layout">
            <FontAwesomeIcon icon={['fas', 'floppy-disk']} color="dark" size="1x" />
          </ActionIcon>
          <ActionIcon onClick={onReset} title="Reset Layout to Default">
            <FontAwesomeIcon icon={['fas', 'rotate-left']} color="dark" size="1x" />
          </ActionIcon>
        </Group>
      </Paper>
      {agentDetails.loading && <Loader />}
      {agent && (
        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          rowHeight={220}
          cols={gridColumns}
          breakpoints={breakpoints}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
        >
          {currentBreakpoint && (
            layouts[currentBreakpoint]?.map(item => (
              <Paper
                key={item.i}
                p="md"
                shadow="sm"
                style={{
                  overflow: 'none'
                }}
              >
                <ModuleContainer
                  agent={agent}
                  contentKey={item.i}
                  setMissingSensors={setMissingSensors}
                />
              </Paper>
            ))
          )}
        </ResponsiveGridLayout>
      )}
    </Box>
  )
}
