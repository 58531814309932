import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { getSites } from '@venturi-io/api/src/userManager/site'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateSite from './Create'

export default function Sites () {
  const sites = usePaginatedApi(getSites)
  const { token, orgId } = useUser()
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadSites = async (page?: number, size?: number): Promise<void> => {
    void sites
      .fetch({
        orgId,
        page: page ?? sites.page,
        size: size ?? sites.size,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadSites(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadSites()
  }, [sites.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create Site"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new site
        </Button>
      </Group>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search site here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={sites.loading}
        data={sites.data.mapOrDefault(({ items }) => items, [])}
        getId={({ siteId }) => siteId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={sites.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={sites.page}
        onPageChange={sites.setPage}
      />
      <CreateSite
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadSites}
      />
    </>
  )
}
