import { ScrollArea, Stack, Text } from '@mantine/core'
import {
  deleteComment,
  getComments,
  updateComment,
  type Comment
} from '@venturi-io/api/src/config/task'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { useEffect, useState } from 'react'
import { useElementSize } from '@mantine/hooks'
import { FormComment } from './FormComment'
import ListComment from './ListComment'

interface Props {
  taskId: number
}

function Comments ({ taskId }: Props) {
  const { token, orgUserId } = useUser()
  const allComments = useApi(getComments)
  const editComment = useApi(updateComment)
  const removeComment = useApi(deleteComment)

  const [editingComment, setEditingComment] = useState<Comment>()

  const { ref, width } = useElementSize()

  // add use effect here to load created comments
  const loadAllComments = () => {
    void allComments.fetch({
      taskId
    }, token)
  }

  useEffect(() => {
    loadAllComments()
  }, [])

  const handleEdit = (comment: Comment) => {
    setEditingComment(comment)
  }

  const handleSave = async (comment: Comment, newContent: string) => {
    await editComment
      .fetch({
        taskId,
        commentId: comment.id,
        edited: true,
        content: newContent
      }, token, 'Successfully updated the comment')
    handleCommentSaved()
  }

  const handleCommentSaved = () => {
    setEditingComment(undefined)
    loadAllComments()
  }

  const handleDelete = (commentId: number) => {
    void removeComment
      .fetch({
        taskId,
        commentId
      }, token, 'Succesfully deleted comment')
      .finally(() => {
        void loadAllComments()
      })
  }

  const commentsData = allComments.data.mapOrDefault(({ items }) => items, [])

  return (
    <Stack ref={ref}>
      <Stack spacing="xs">
        <FormComment
          taskId={taskId}
          comment={editingComment}
          onCommentSaved={handleCommentSaved}
          loadAllComments={loadAllComments}
        />
      </Stack>
      <ScrollArea
        type="auto"
        h={140}
        scrollbarSize={5}
      >
        {commentsData.length === 0
          ? (
            <Text align="center" size="sm" fw={500}>
              No comments found
            </Text>
            )
          : (commentsData.map(item => (
            <ListComment
              item={item}
              key={item.id}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onSave={handleSave}
              orgUserId={orgUserId}
              width={width}
            />))
            )}
      </ScrollArea>
    </Stack>
  )
}

export default Comments
