import { Container, Group, Center, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import CoreMap from 'src/Maps/CoreMap'

import { useSharedStyles } from '../shared'

interface Props {
  title: string
  agentLocation: AgentDetails['geoLocation']
}

export default function Location ({ title, agentLocation }: Props) {
  const { classes } = useSharedStyles()
  const { ref, height } = useElementSize()

  return (
    <Container ref={ref} className={classes.dock} fluid>
      <Group className={classes.contentTitle} position="apart" noWrap>
        <Text size="sm" weight={500}>{title.toUpperCase()}</Text>
      </Group>
      <Group position="center" grow>
        {agentLocation
          ? (
            <CoreMap
              {...agentLocation}
              height={`calc(${height}px - 35px)`}
              width="100%"
              zoom={15}
              showLocationMarker
              canZoom
            />
            )
          : (
            <Center mt="xl">
              <Text color="gray">(Location not configured)</Text>
            </Center>
            )}
      </Group>
    </Container>
  )
}
