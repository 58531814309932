import { type ReactNode } from 'react'
import { createStyles } from '@mantine/styles'
import Breadcrumbs, { type BreadcrumbsItem } from './Breadcrumbs'

interface StyleProps {
  form?: boolean
  noVerticalPadding?: boolean
}
const useStyles = createStyles((theme, { form, noVerticalPadding }: StyleProps) => ({
  container: {
    width: '100%',
    padding: `${noVerticalPadding
      ? '0'
      : '20px'} 20px`
  },
  children: {
    display: form
      ? 'flex'
      : 'initial',
    width: '100%'
  },
  form: {
    justifyContent: 'center'
  }
}))

export interface Props {
  form?: boolean
  breadcrumbs?: BreadcrumbsItem[]
  noVerticalPadding?: boolean
  children: ReactNode
}

export default function Page ({
  form,
  breadcrumbs,
  noVerticalPadding,
  children
}: Props) {
  const { classes, cx } = useStyles({ form, noVerticalPadding })
  const childrenClasses = cx({
    [classes.children]: true,
    [classes.form]: form
  })

  return (
    <div className={classes.container}>
      {breadcrumbs && (
        <Breadcrumbs items={breadcrumbs} />
      )}
      <div className={childrenClasses}>
        {children}
      </div>
    </div>
  )
}
