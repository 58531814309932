import { ActionIcon, Tooltip, type ActionIconProps } from '@mantine/core'
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { type SizeProp, type IconName, type IconPrefix } from '@fortawesome/fontawesome-svg-core'

interface Props {
  label: string
  icon: IconName
  loading?: boolean
  onClick?: () => void
  iconType?: IconPrefix
  iconColor?: FontAwesomeIconProps['color']
  iconSize?: SizeProp
  iconCursor?: FontAwesomeIconProps['cursor']
  actionColor?: ActionIconProps['color']
  actionVariant?: ActionIconProps['variant']
  actionRadius?: ActionIconProps['radius']
  actionSize?: ActionIconProps['size']
  actionDisabled?: ActionIconProps['disabled']
}

const ActionButton = ({
  label,
  icon,
  loading,
  onClick,
  iconType = 'fas',
  iconColor = 'dark',
  iconSize = '1x',
  iconCursor,
  actionColor,
  actionVariant,
  actionRadius,
  actionSize,
  actionDisabled
}: Props) => (
  <Tooltip
    label={label}
    position="top"
    transitionProps={{
      transition: 'pop',
      duration: 200
    }}
    withArrow
  >
    <ActionIcon
      loading={loading}
      onClick={onClick}
      color={actionColor}
      variant={actionVariant}
      radius={actionRadius}
      size={actionSize}
      disabled={actionDisabled}
    >
      <FontAwesomeIcon
        icon={[iconType, icon]}
        color={iconColor}
        size={iconSize}
        cursor={iconCursor}
      />
    </ActionIcon>
  </Tooltip>
)

export default ActionButton
