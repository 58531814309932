import { z } from 'zod'
import { createStatelessApi } from '../configure'
import { type Req } from '../shared'

// Get geozone for table
const geozoneEvent = z.object({
  geoZoneId: z.number(),
  geoZoneName: z.string(),
  timesVisited: z.number(),
  enteredTime: z.string(),
  leftTime: z.string(),
  totalParkedTime: z.optional(z.number()),
  totalDurationInsideZone: z.number()
})

const geozoneEvents = z.array(geozoneEvent)

export type GeozoneEvent = z.infer<typeof geozoneEvent>

export type GeozoneEvents = z.infer<typeof geozoneEvents>

export interface GeozoneEventTripRequest extends Req {
  tripId: number
}

export const getGeozoneEventsForTrip = createStatelessApi<GeozoneEventTripRequest, typeof geozoneEvents>(
  'analytics',
  '/geo-zone-events',
  { method: 'GET', type: 'path' },
  geozoneEvents
)
