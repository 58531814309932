import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, createStyles, Group, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { wrapWithNeedsRole } from 'src/NeedsRole'
import MenuItem, { type MenuItemProps } from './MenuItem'
import TooltipWrapper from './TooltipWrapper'

const useDropdownStyles = createStyles(theme => ({
  dropDownMenuContainer: {
    cursor: 'pointer',
    backgroundColor: theme.colors.brand[2],
    '&:hover': {
      backgroundColor: theme.colors.primary[7]
    }
  },
  dropDownMenuItemContainer: {
    border: '0px !important',
    '&:hover': {
      backgroundColor: theme.colors.brand[2]
    }
  },
  dropDownMenuItemTitle: {
    fontWeight: 400,
    fontSize: '0.9rem',
    paddingLeft: '15px'
  },
  hidden: {
    display: 'none'
  },
  icon: {
    minWidth: '17px',
    paddingLeft: '1px'
  }
}))

export default function DropdownMenu ({
  item: {
    title,
    path,
    icon,
    selector,
    children
  },
  open,
  isSidebarOpen,
  setSidebarOpen,
  closeMenu
}: MenuItemProps) {
  const [isOpen, setIsOpen] = useState(open ?? false)
  const { classes } = useDropdownStyles()
  const location = useLocation()
  const isSamePath = location.pathname.includes(path)

  useEffect(() => {
    if (isOpen && setSidebarOpen) {
      setSidebarOpen(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsOpen(false)
    }
  }, [isSidebarOpen])

  if (children.length === 0) return null

  const label = (
    <Group spacing="xs" position="left">
      <FontAwesomeIcon id={selector} icon={icon} className={classes.icon} />
      <Text className={classes.dropDownMenuItemTitle}>
        {title}
      </Text>
    </Group>
  )

  return (
    <TooltipWrapper label={title} isSidebarOpen={isSidebarOpen}>
      <div
        id={`btn-${selector}`}
        className={classes.dropDownMenuContainer}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Accordion
          key={title}
          value={isOpen ? title.toLowerCase() : ''}
          styles={theme => ({
            control: {
              backgroundColor:
                isSamePath
                  ? theme.colors.primary[7]
                  : theme.colors.brand[2],
              color:
                isSamePath
                  ? theme.colors.gray[0]
                  : theme.colors.dark[6],
              '&:hover': {
                backgroundColor: theme.colors.primary[7],
                color: theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0]
              }
            }
          })}
        >
          <Accordion.Item
            value={title.toLowerCase()}
            className={classes.dropDownMenuItemContainer}
          >
            <Accordion.Control>{label}</Accordion.Control>
            <Accordion.Panel>
              {children.map(child => wrapWithNeedsRole(child.role,
                <MenuItem
                  key={`${title}-${child.title}`}
                  item={child}
                  closeMenu={closeMenu}
                  isSidebarOpen={isSidebarOpen}
                />
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </TooltipWrapper>
  )
}
