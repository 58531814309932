import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { getAlarmWebhooks } from '@venturi-io/api/src/analytics/alarmWebhook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateAlarmWebhook from './Create'

export default function AlarmWebhooks () {
  const { token } = useUser()
  const alarmWebhooks = usePaginatedApi(getAlarmWebhooks)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadAlarmWebhooks = async (page?: number, size?: number): Promise<void> => {
    void alarmWebhooks
      .fetch({
        page: page ?? alarmWebhooks.page,
        size: size ?? alarmWebhooks.size,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadAlarmWebhooks(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadAlarmWebhooks()
  }, [alarmWebhooks.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create Alarm Webhook"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new alarm webhook
        </Button>
      </Group>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search alarm webhook here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={alarmWebhooks.loading}
        data={alarmWebhooks.data.mapOrDefault(({ items }) => items, [])}
        getId={({ id }) => id}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={alarmWebhooks.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={alarmWebhooks.page}
        onPageChange={alarmWebhooks.setPage}
      />
      <CreateAlarmWebhook
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadAlarmWebhooks}
      />
    </>
  )
}
