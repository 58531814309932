export const noBorder = {
  border: 'none'
}

export const textGeneralStyles = {
  paddingLeft: 0,
  ...noBorder
}

export const thStyle = {
  fontWeight: 400,
  fontSize: 10,
  color: '#5C5F66',
  ...textGeneralStyles
}

export const bgGrayStyle = {
  width: 65,
  fontWeight: 600,
  color: '#5C5F66',
  background: '#F1F3F5',
  borderRadius: 32,
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '.5px 7px',
  ...noBorder
}

export const assetTypeStyle = {
  ...bgGrayStyle,
  width: 74,
  marginTop: 6
}
