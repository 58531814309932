import {
  type ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import {
  type AlarmActionWithoutDependencies,
  getAlarmActionsForUI
} from '@venturi-io/api/src/analytics/alarmAction'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  excludeAlarmActionIds?: number[]
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const alarmActionToSelect = ({ id, name }: AlarmActionWithoutDependencies): SelectItem => ({
  value: id.toString(),
  label: name
})

export default function MultiSelectAlarmAction ({
  excludeAlarmActionIds,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const alarmActions = useApi(getAlarmActionsForUI)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectAlarmActions = useMemo(() => {
    const allAlarmActions = alarmActions.data.mapOrDefault(agent => agent, [])
    const filteredAlarmActions = allAlarmActions.filter(
      ({ id }) => !excludeAlarmActionIds?.some(alarmActionId => alarmActionId === id)
    )
    return filteredAlarmActions.map(alarmActionToSelect)
  }, [alarmActions.data])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void alarmActions.fetch({ orgId }, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose alarm action(s)'}
      value={value}
      data={selectAlarmActions}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || alarmActions.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
