// TODO: Set up color configuration once ready
const colors = {
  green: '#12B886',
  yellow: '#FFE066',
  red: '#F03E3E',
  gray: '#868E96',
  lighGray: '#E9ECEF'
}

export default colors
