import { type ReactElement } from 'react'
import { IconEngine, IconClockStop } from '@tabler/icons-react'
import StatusBadge from 'src/AssetTemplate/Components/StatusBadge'

export type Mode = 'Auto' | 'Stopped'

interface Props {
  mode: Mode
}

const modeIcon: Record<Mode, ReactElement> = {
  Auto: <IconEngine size={20} />,
  Stopped: <IconClockStop size={20} />
}

export default function ControlMode ({ mode }: Props) {
  return (
    <StatusBadge
      title="Control Mode"
      icon={modeIcon[mode]}
      color="gray.4"
      textColor="gray"
      value={mode}
    />
  )
}
