import {
  Group,
  Paper,
  Progress,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType, secondsToDhms } from 'src/Assets/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import dayjs from 'dayjs'
import { computeRelativePercentage, convertStrToFloat } from 'src/AssetTemplate/shared'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const controlMode = findSensorByType('CONTROL_MODE', sensors)
  const engineState = findSensorByType('ENGINE_OPERATE_MODE', sensors)
  const engineRpm = findSensorByType('ENGINE_SPEED', sensors)
  const engineRuntime = findSensorByType('ENGINE_RUN_TIME', sensors)
  const fuelLevel = findSensorByType('FUEL_LEVEL', sensors)
  const batteryVoltage = findSensorByType('ENGINE_BATTERY_VOLTAGE', sensors)

  const fuelLevelPercent = fuelLevel
    ? Math.floor(computeRelativePercentage(
      convertStrToFloat(fuelLevel.currentValue),
      fuelLevel.minValue,
      fuelLevel.maxValue
    ))
    : 0

  // get readable format
  const readableRuntime = secondsToDhms(parseInt(engineRuntime?.currentValue ?? '0'))
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
            to={`/assets/genset/${agentId}`}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <TextGroup
            label="Control Mode"
            value={controlMode?.currentValue
              ? `${controlMode.currentValue}`
              : '-'}
            maxLength={25}
          />
          <Group
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.0625rem'
            }}
          >
            <TextGroup label="Engine State" value="" />
            <Text
              size="sm"
              color={engineState?.currentValue === 'Running'
                ? 'green'
                : 'yellow'}
            >
              {engineState?.currentValue
                ? `${engineState.currentValue}`
                : '-'}
            </Text>
          </Group>
          <TextGroup
            label="Engine Speed"
            value={engineRpm?.currentValue
              ? `${engineRpm.currentValue} RPM`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="Engine Run-time"
            value={engineRpm?.currentValue
              ? readableRuntime
              : '-'}
            maxLength={25}
          />
          <Group
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Fuel" value="" />
            <SimpleGrid
              cols={2}
              sx={{
                alignItems: 'center '
              }}
            >
              <Progress
                color={fuelLevelPercent < 33
                  ? 'red'
                  : 'green'}
                radius="xs"
                size="lg"
                value={fuelLevelPercent}
              />
              <Text size="xs">
                {fuelLevelPercent}
                %
              </Text>
            </SimpleGrid>
          </Group>
          <TextGroup
            label="Battery Voltage"
            value={batteryVoltage?.currentValue
              ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="Last Seen"
            value={dayjs(lastSeenTime).fromNow()}
            maxLength={25}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
