import {
  useEffect,
  useState,
  useContext,
  useCallback,
  type RefObject,
  useMemo
} from 'react'
import {
  Layer as MapLayer,
  type LayerProps,
  Source,
  type MapRef,
  type SourceProps
} from 'react-map-gl'
import { useDebouncedValue } from '@mantine/hooks'
import { type MapboxPoint } from '@venturi-io/api/src/collector/trip'
import { AnimationMode, getTripContext } from '../AnimationContext'
import {
  type RawPointSourceProps,
  generateLinePath,
  generateTripPoints,
  generateSpeedingPoints,
  generateMapSource,
  generateMapboxPoints,
  type Geometry,
  generateRawLinePath,
  postMatchings,
  generateIdlingPoints
} from '../../mapbox'
import Popup, { type PositionDetails } from './Popup'
import { getToolContext } from './Devtools'
import { useDashLineLayer } from './DashLineLayer'
import type mapboxgl from 'mapbox-gl'

interface PathsProps {
  mapRef: RefObject<MapRef>
}

export type TimestampData = Record<string, PositionDetails>

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

const Layer = ({ mapRef }: PathsProps) => {
  const { dashLineArray, start: startDashAnimation, stop: stopDashAnimation } = useDashLineLayer()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [properties, setProperties] = useState<RawPointSourceProps | null>(null)
  const [matchedSources, setMatchedSource] = useState<Geometry[]>([])
  const [sampleSource, setSampleSource] = useState<Geometry[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredMapboxPath, setFilteredMapboxPath] = useState<MapboxPoint[] | null>(null)

  const {
    showSequence,
    showRaw,
    showRawLine,
    showSpeeding,
    showCsv,
    showBe,
    showFe,
    showDashLine,
    showIdling,
    isVisible,
    csvPoints,
    setCsvMapboxLength,
    setFeLocalLength
  } = useContext(getToolContext())

  // get current trip on trip context
  const {
    trip,
    mode,
    frameRange,
    pointerCoordinates: coordinates,
    pointerAngle: angle
  } = useContext(getTripContext())

  const refetchMapboxPoints = async () => {
    const source = await postMatchings(filteredRawPath)
    setFilteredMapboxPath(source.coordinates as never as number[][])
  }

  const filteredRawPath = useMemo(() => {
    if (!trip) return []
    const [start, stop] = frameRange
    return trip.rawPath.slice(start, stop)
  }, [trip, frameRange])

  const isInDefaultRange = useMemo(() => {
    if (!trip) return []
    const [start, stop] = frameRange
    return start === 0 && stop === trip.rawPath.length - 1
  }, [trip, frameRange])

  useEffect(() => {
    if (mode === AnimationMode.Play) {
      if (!isInDefaultRange) {
        // lets call the mapbox API to refetch the new rawPaths
        void refetchMapboxPoints()
      } else {
        // reset filtered mapboxPath to null
        setFilteredMapboxPath(null)
      }
    }
  }, [mode, isInDefaultRange, frameRange, filteredRawPath])

  const [debouncedRawPath] = useDebouncedValue(filteredRawPath, 250)

  const rawTripSource = useMemo(() => (
    trip
      ? generateTripPoints(filteredRawPath, trip.tripId, trip.color)
      : null
  ), [trip, filteredRawPath])

  const linePathSource = trip
    ? generateRawLinePath(trip.rawPath, trip.color)
    : null

  const lineMapboxPathSource = trip?.inProgress
    ? generateRawLinePath(trip.rawPath, trip.color)
    : trip
      ? generateLinePath(filteredMapboxPath ?? trip.mapboxPath, trip.color)
      : null

  const mapboxMatchSource = trip
    ? generateMapboxPoints(filteredMapboxPath ?? trip.mapboxPath, trip.tripId, trip.color)
    : null

  const speedingSource = useMemo(() => (
    trip
      ? generateSpeedingPoints(debouncedRawPath, trip.tripId, trip.color)
      : null
  ), [trip, debouncedRawPath])

  const idlingSource = useMemo(() => (
    trip
      ? generateIdlingPoints(debouncedRawPath, trip.tripId)
      : null
  ), [trip, debouncedRawPath])

  const arrowLayer: LayerProps = {
    id: 'arrow-points',
    beforeId: 'basePoint',
    type: 'symbol',
    layout: {
      'icon-image': 'arrow-indicator', // reference the image
      'icon-size': 1,
      'icon-rotate': ['get', 'direction'],
      'icon-allow-overlap': true
    }
  }

  const speeding: LayerProps = {
    id: 'speeding-arrow-points',
    beforeId: 'baseSpeeding',
    type: 'symbol',
    layout: {
      'icon-image': 'arrow-indicator', // reference the image
      'icon-size': 1,
      'icon-rotate': ['get', 'direction'],
      'icon-allow-overlap': true
    }
  }

  const lineBorder: LayerProps = {
    type: 'line',
    beforeId: 'baseLine',
    paint: {
      'line-color': 'gray',
      'line-opacity': 1,
      'line-gap-width': 9,
      'line-width': 1
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  const feLineLayer: LayerProps = {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': 'red',
      'line-opacity': 1,
      'line-width': 5
    }
  }

  const lineLayer: LayerProps = {
    id: 'main-line',
    beforeId: 'baseLine',
    type: 'line',
    paint: {
      'line-gradient': [
        'interpolate',
        ['linear'],
        ['line-progress'],
        0,
        'lightGray',
        1,
        trip?.color ?? 'red'
      ],
      'line-color': ['get', 'color'],
      'line-opacity': 1,
      'line-width': 9
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  const rawLineLayer: LayerProps = {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': 'blue',
      'line-opacity': 1,
      'line-width': 5
    }
  }

  const circleLayer: LayerProps = {
    type: 'circle',
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': 1,
      'circle-radius': 12,
      'circle-stroke-color': '#fff',
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 1
    }
  }

  const textLayer: LayerProps = {
    type: 'symbol',
    layout: {
      'text-field': ['get', 'position'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-allow-overlap': false
    }
  }

  const speedingLayer: LayerProps = {
    id: 'speeding-points',
    beforeId: 'baseSpeeding',
    type: 'symbol',
    layout: {
      'icon-image': 'speeding-dot',
      'icon-allow-overlap': true
    }
  }

  const dashLayer: LayerProps = {
    type: 'line',
    layout: {
      'line-join': 'miter',
      'line-cap': 'butt'
    },
    paint: {
      'line-color': 'white',
      'line-width': 9,
      'line-dasharray': dashLineArray
    }
  }

  const pointerSource: SourceProps = {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: { angle },
      geometry: {
        type: 'Point',
        coordinates
      }
    }
  }

  const dummySource: SourceProps = {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: { angle },
      geometry: {
        type: 'Point',
        coordinates
      }
    }
  }

  const pointLayer: LayerProps = {
    id: 'pointer',
    beforeId: 'basePointer',
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': 'arrow-pointer',
      'icon-size': 0.5,
      'icon-rotate': ['get', 'angle'],
      visibility: trip !== null
        ? 'visible'
        : 'none'
    }
  }

  const idlingPinLayer: LayerProps = {
    id: 'idlingFence',
    beforeId: 'baseIdling',
    type: 'symbol',
    layout: {
      'icon-image': 'idle-indicator', // reference the image
      'icon-size': 1,
      'icon-allow-overlap': true,
      'text-field': ['get', 'idlingInMins'],
      'text-font': ['Arial Unicode MS Bold'],
      'text-offset': [0, 0.5],
      'text-size': 10,
      'text-allow-overlap': true
    }
  }

  const baseMapLine: LayerProps = {
    id: 'baseLine',
    type: 'symbol'
  }

  const baseRawPoint: LayerProps = {
    id: 'basePoint',
    type: 'symbol'
  }

  const baseIdlingLayer: LayerProps = {
    id: 'baseIdling',
    type: 'symbol'
  }

  const baseSpeedingLayer: LayerProps = {
    id: 'baseSpeeding',
    type: 'symbol'
  }

  const basePointerLayer: LayerProps = {
    id: 'basePointer',
    type: 'symbol'
  }

  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  const handleHoverEvent = useCallback((pointer: string) => () => {
    if (map) {
      map.getCanvas().style.cursor = pointer
    }
  }, [map])

  const handlePointClick = useCallback((e: MouseEvent) => {
    if (map) {
      // Copy coordinates array.
      if (e.features) {
        const feature = e.features[0]
        const { properties, geometry } = feature
        if (properties && geometry.type === 'Point') {
          const props = properties as RawPointSourceProps
          const coordinates = geometry.coordinates.slice()
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          setProperties(() => {
            setIsPopupOpen(true)
            return {
              ...props,
              latitude: coordinates[1],
              longitude: coordinates[0]
            }
          })
        }
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', ['arrow-points', 'speeding-points'], handleHoverEvent(''))
      map.on('mouseenter', ['arrow-points', 'speeding-points'], handleHoverEvent('pointer'))
      map.on('click', ['arrow-points', 'speeding-points'], handlePointClick)
    }
    return () => {
      if (map) {
        map.off('mouseleave', ['arrow-points', 'speeding-points'], handleHoverEvent(''))
        map.off('mouseenter', ['arrow-points', 'speeding-points'], handleHoverEvent('pointer'))
        map.off('click', ['arrow-points', 'speeding-points'], handlePointClick)
      }
    }
  }, [map])

  const loadMapboxMatch = async () => {
    if (trip?.rawPath) {
      const sources = await postMatchings(trip.rawPath)
      setFeLocalLength(sources.coordinates.length)
      setMatchedSource([sources])
    }
  }

  useEffect(() => {
    if (isVisible) {
      void loadMapboxMatch()
    }
  }, [trip?.rawPath, isVisible])

  useEffect(() => {
    if (isVisible && csvPoints) {
      void loadSamplePoints()
    }
  }, [csvPoints, isVisible])

  useEffect(() => {
    if (showDashLine) {
      void startDashAnimation()
    } else {
      void stopDashAnimation()
    }
  }, [showDashLine])

  useEffect(() => {
    if (!trip) {
      if (isPopupOpen) {
        setIsPopupOpen(false)
      }
    }
  }, [trip])

  const loadSamplePoints = async () => {
    if (csvPoints) {
      const source = await postMatchings(csvPoints)
      setCsvMapboxLength(source.coordinates.length)
      setSampleSource([source])
    } else {
      setSampleSource([])
    }
  }

  return rawTripSource && linePathSource
    ? (
      <>
        <Popup
          isOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          properties={properties}
          map={map}
        />
        {showFe && matchedSources.length && (
          <>
            {matchedSources.map(source => {
              const matchedSource = generateMapSource(source, 'red')
              return (
                <Source key="test" {...matchedSource}>
                  <MapLayer {...feLineLayer} />
                </Source>
              )
            })}
          </>
        )}
        {showCsv && sampleSource.length && (
          <>
            {sampleSource.map(source => {
              const matchedSource = generateMapSource(source, 'yellow')
              return (
                <Source key="sample" {...matchedSource}>
                  <MapLayer {...lineBorder} />
                  <MapLayer {...lineLayer} />
                  {showDashLine && <MapLayer {...dashLayer} />}
                </Source>
              )
            })}
          </>
        )}
        {showRawLine && (
          <Source {...linePathSource}>
            <MapLayer {...rawLineLayer} />
          </Source>
        )}
        {showBe && lineMapboxPathSource && (
          <Source {...lineMapboxPathSource}>
            <MapLayer
              {...lineBorder}
            />
            <MapLayer
              {...lineLayer}
            />
            {showDashLine && <MapLayer {...dashLayer} />}
          </Source>
        )}
        {showSequence && mapboxMatchSource && (
          <Source {...mapboxMatchSource}>
            <MapLayer {...circleLayer} />
            <MapLayer {...textLayer} />
          </Source>
        )}
        {showRaw && (
          <Source {...rawTripSource}>
            <MapLayer {...arrowLayer} />
          </Source>
        )}
        {showIdling && idlingSource && (
          <Source {...idlingSource}>
            <MapLayer {...idlingPinLayer} />
          </Source>
        )}
        {showSpeeding && speedingSource && (
          <Source {...speedingSource}>
            <MapLayer {...speedingLayer} />
            <MapLayer {...speeding} />
          </Source>
        )}
        {trip && (
          <Source {...pointerSource}>
            <MapLayer {...pointLayer} />
          </Source>
        )}
        <Source {...dummySource}>
          <MapLayer {...baseMapLine} />
          <MapLayer {...baseRawPoint} />
          <MapLayer {...baseIdlingLayer} />
          <MapLayer {...baseSpeedingLayer} />
          <MapLayer {...basePointerLayer} />
        </Source>
      </>
      )
    : null
}

export default Layer
