import { Col, Grid } from '@mantine/core'
import Paper from 'src/Layout/Paper'
import OrgLogos from './OrgLogos'
import ThemeInputs from './Theme'

export default function Theme () {
  return (
    <Paper my={0}>
      <Grid p="md" grow>
        <Col span={12}>
          <OrgLogos />
        </Col>
        <Col span={12}>
          <ThemeInputs />
        </Col>
      </Grid>
    </Paper>
  )
}
