import {
  Col,
  Grid,
  ScrollArea,
  Stack,
  Text,
  Image,
  useMantineTheme
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { getHost } from 'src/utils/host'
import { useUser } from 'src/UserContext'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { MODULE_CONTENT } from '../constants'
import { ListAttachment } from './ListAttachment'

interface Props {
  attachments: Array<{ id: number, name: string, url: string, createdAt?: string }>
}

function Attachments ({ attachments }: Props) {
  const orgUrl = getHost()
  const [imageUrl, setImageUrl] = useState<string>()
  const { token } = useUser()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFile, setSelectedFile] = useState<string | null>(null)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))

  const handleFileClick = async (fileUrl: string) => {
    setSelectedFile(fileUrl)
    const url = await renderFilePreview(fileUrl)
    setImageUrl(url)
  }

  const renderFilePreview = async (file: string): Promise<string> => {
    const options = {
      method: 'GET',
      headers: {
        'X-Auth-Token': token
      }
    }

    const response = await fetch(`https://${orgUrl}${file}`, options)

    if (response.ok) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      return url
    }
    throw new Error('Failed to preview file')
  }

  const handleFileDownload = async (fileUrl: string, fileName: string) => {
    const options = {
      method: 'GET',
      headers: {
        'X-Auth-Token': token
      }
    }

    const response = await fetch(`https://${orgUrl}${fileUrl}`, options)

    if (response.ok) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.click()

      URL.revokeObjectURL(url)
    } else {
      throw new Error('Failed to download file')
    }
  }

  // Load the first attachment on page load
  useEffect(() => {
    if (attachments.length > 0) {
      void handleFileClick(attachments[0].url)
    }
  }, [attachments])

  return (
    <Stack>
      <Grid>
        <Col span={12}>
          <Text transform="uppercase" size="sm" weight={500}>
            {MODULE_CONTENT.TASK_ATTACHMENTS}
          </Text>
        </Col>
      </Grid>
      <Grid>
        <Col span={12} sm={5} md={4}>
          <ListAttachment
            attachments={attachments}
            onFileClick={handleFileClick}
            onFileDownload={handleFileDownload}
            isMobile={isMobile}
          />
        </Col>
        <Col span={12} sm={7} md={8}>
          <ScrollArea
            type="auto"
            h={400}
            scrollbarSize={5}
            style={{ flex: 1 }}
          >
            {imageUrl
              ? <Image mx="auto" src={imageUrl} alt="File preview" style={{ maxWidth: '100%' }} />
              : <Text align="center" fw={500} size="lg">No preview available</Text>}
          </ScrollArea>
        </Col>
      </Grid>
    </Stack>
  )
}

export default Attachments
