import {
  Button,
  Group,
  ScrollArea,
  Stack,
  Text,
  TypographyStylesProvider
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Task } from '@venturi-io/api/src/config/task'
import { useForm } from '@mantine/form'
import { useUser } from 'src/UserContext'
import { useEffect, useState } from 'react'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import { useNotifications } from 'src/utils/notifications'
import { useElementSize } from '@mantine/hooks'
import RTE from 'src/Input/RTE'
import { MODULE_CONTENT } from './constants'

interface Props {
  tasks: Task
}

interface RichTextEditorFormProps {
  content: string
  onSubmitForm: (value: string) => void
}

const RichTextEditorForm = ({
  content,
  onSubmitForm
}: RichTextEditorFormProps) => {
  const form = useForm({
    initialValues: {
      content
    },
    validate: {
      content: (value) => (value.trim().length === 0 ? 'Content is required' : null)
    }
  })

  const handleUpdate = (updatedContent: string) => {
    form.setFieldValue('content', updatedContent)
  }

  const handleSubmit = ({ content }: typeof form.values) => {
    onSubmitForm(content)
  }

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      style={{ height: '100%' }}
    >
      <Stack style={{ height: '100%' }}>
        <RTE
          content={content}
          onUpdate={handleUpdate}
          mode="EDIT"
        />
        <Group position="right">
          <Button
            type="submit"
            color="green"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

const renderRTEorDescription = (createdById: number,
  currentUserId: number,
  content: string,
  onSubmitForm: (value: string) => void
) => {
  if (createdById === currentUserId) {
    return <RichTextEditorForm
      content={content}
      onSubmitForm={onSubmitForm}
           />
  }
  return (
    <ScrollArea
      type="auto"
      scrollbarSize={5}
      style={{ height: '100%', flex: 1 }}
    >
      <TypographyStylesProvider>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </TypographyStylesProvider>
    </ScrollArea>
  )
}

function Description ({ tasks }: Props) {
  const {
    id,
    createdBy,
    notes
  } = tasks

  const { token, orgUserId } = useUser()
  const [apiPath, setApiPath] = useState('')
  const { showError, showSuccess } = useNotifications()
  const { ref } = useElementSize()

  useEffect(() => {
    void configure(getHost()).then(config => {
      setApiPath(`${config.config}/api/config/tasks`)
    })
  }, [])

  const handleRteSubmit = async (content: string) => {
    const taskId = id
    if (!taskId) {
      throw new Error('Unable to perform the action, no task provided')
    }

    const formData = new FormData()
    formData.append('notes', content)

    try {
      const request = {
        method: 'PATCH',
        headers: {
          'X-Auth-Token': token
        },
        body: formData
      }

      const result = await fetch(`${apiPath}/${taskId}`, request)

      if (result.ok) {
        showSuccess('Successfully update description')
      } else {
        showError(new Error('Failed to update description'))
      }
    } catch (error: any) {
      showError(error)
    }
  }

  const altHeight = createdBy.id === orgUserId
    ? '100%'
    : '85%'

  return (
    <Stack
      ref={ref}
      style={{
        height: '100%',
        display: 'flex'
      }}
    >
      <Group position="apart">
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.TASK_DESCRIPTION}
        </Text>
      </Group>
      <Stack
        spacing="xs"
        style={{
          height: altHeight,
          display: 'flex'
        }}
      >
        {renderRTEorDescription(createdBy.id, orgUserId, notes, handleRteSubmit)}
      </Stack>
    </Stack>
  )
}

export default Description
