export const MODULE_CONTENT = {
  VEHICLE_DETAILS: 'Asset Details',
  VEHICLE_STATUS: 'Asset Status',
  VEHICLE_LOCATION: 'Asset Location',
  VEHICLE_TRIP: 'Recent Trip Details',
  VEHICLE_TRANSACTIONS: 'Recent Transactions',
  VEHICLE_TANK_HISTORY: 'Fuel Tank History',
  VEHICLE_TASKS: 'Tasks',
  VEHICLE_AUDIT_LOG: 'Odometer Audit Log'
} as const
