import { useEffect } from 'react'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { useMeta } from 'src/MetaContext'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import History from './History'
import Incidents from './Incidents'
import Sensors from './Sensors'
import Location from './Location'

interface RouteParams extends Record<string, string | undefined> {
  agentId: string
}

export default function AgentDetails () {
  const agentDetails = useApi(getAgentDetails)
  const { agentId } = useParams<RouteParams>()
  const { token } = useUser()
  const { meta, setMeta } = useMeta()

  if (!agentId) {
    return null
  }

  const { geoLocation, sensorInstanceIds, sensorsNotSystemOnly, sensorsWithGraph } = agentDetails
    .data
    .mapOrDefault(({ sensors, geoLocation }) => ({
      sensorInstanceIds: sensors.map(({ sensorInstanceId }) => sensorInstanceId),
      sensorsNotSystemOnly: sensors.filter(({ systemOnly }) => !systemOnly),
      sensorsWithGraph: sensors.filter(({ showGraph }) => showGraph),
      geoLocation
    }), {
      sensorInstanceIds: [],
      sensorsNotSystemOnly: [],
      sensorsWithGraph: [],
      geoLocation: undefined
    })

  useEffect(() => {
    const req = {
      agentId: parseInt(agentId)
    }

    void agentDetails
      .fetch(req, token)
      .finally(() => {
        agentDetails.startPolling(req, token, 60)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [])

  useEffect(() => {
    agentDetails.data.ifJust(({ agentName }) => {
      if (meta.pageTitle === agentName) return

      setMeta({
        ...meta,
        pageTitle: agentName
      })
    })
  }, [agentDetails])

  return (
    <>
      <LoadingOverlay visible={agentDetails.loading} />
      <Sensors list={sensorsNotSystemOnly} />
      <Incidents sensorInstanceIds={sensorInstanceIds} />
      <History isLoadingSensors={agentDetails.loading} sensors={sensorsWithGraph} />
      {geoLocation && (
        <Location agentLocation={geoLocation} />
      )}
    </>
  )
}
