import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { getAgents, type Agent } from '@venturi-io/api/src/config/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  assetTypes?: string[]
  value?: Value
  onChange?: (value: Value, text?: string) => void
  setExternalAgentId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const agentToSelect = ({ agentId, agentName }: Agent): SelectItem => ({
  value: agentId.toString(),
  label: agentName
})

export default function SelectAgent ({
  defaultValue,
  value,
  label,
  assetTypes,
  placeholder,
  setExternalAgentId,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const agents = useApi(getAgents)
  const [agentId, setAgentId] = useState<Value>(null)
  const [selectAgents, setSelectAgents] = useState<SelectItem[]>([])

  const loadAgents = () => {
    void agents.fetch({ orgId }, token)
  }

  const handleChange = useCallback((val: Value) => {
    setAgentId(val)

    if (setExternalAgentId) {
      setExternalAgentId(val)
    }

    const selected = selectAgents.find(agent => agent.value === val)

    if (onChange && selected) {
      onChange(val, selected.label)
    }
  }, [setExternalAgentId, onChange, selectAgents])

  useEffect(() => {
    if (defaultValue) {
      setAgentId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadAgents()
  }, [])

  useEffect(() => {
    agents.data.ifJust(data => {
      const selections = assetTypes
        ? data
          .filter(({ assetType }: Agent) =>
            assetType
              ? assetTypes.includes(assetType)
              : false)
          .map(agentToSelect)
        : data.map(agentToSelect)
      setSelectAgents(selections)
    })
  }, [agents.data])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose an agent'}
      data={selectAgents}
      defaultValue={defaultValue}
      value={value ?? agentId}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || agents.loading}
      error={error}
      {...props}
    />
  )
}
