import { type DragEvent } from 'react'
import { createStyles } from '@mantine/core'
import { mq } from 'src/utils/style'

interface NodeProps {
  type: string
  group: 'input' | 'default' | 'output'
  label: string
}

const onDragStart = (event: DragEvent, nodeType: string) => {
  event.dataTransfer.setData('application/reactflow', nodeType)
  event.dataTransfer.effectAllowed = 'move'
}

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '98%',
    padding: '10px',
    gap: '10px',
    borderTop: '1px solid #e3e3e3',
    borderRight: 0,
    overflow: 'auto',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'column',
      justifyContent: 'start',
      width: '240px',
      height: '100%',
      borderTop: 0,
      borderRight: '1px solid #e3e3e3'
    }
  },
  description: {
    marginBottom: '10px',
    color: '#333'
  },
  node: {
    cursor: 'pointer',
    display: 'flex',
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 50px',
    border: '2px solid black',
    borderRadius: '5px',
    color: 'white',
    [mq(theme.breakpoints.md)]: {
      padding: '15px 10px'
    }
  },
  input: {
    background: theme.colors.primary[2]
  },
  default: {
    background: theme.colors.primary[5]
  },
  output: {
    background: theme.colors.primary[6]
  }
}))

const Sidebar = () => {
  const { classes, cx } = useStyles()

  const Node = ({ type, group, label }: NodeProps) => (
    <div
      className={cx(classes.node, classes[group])}
      onDragStart={(event: DragEvent) => onDragStart(event, JSON.stringify({ type, group, label }))}
      draggable
    >
      {label}
    </div>
  )

  return (
    <aside className={classes.container}>
      {/* INPUT NODES */}
      <Node
        type="input"
        group="input"
        label="Sensor Value"
      />
      <Node
        type="input"
        group="input"
        label="Date/Time"
      />
      <Node
        type="input"
        group="input"
        label="Call Webhook"
      />

      {/* DEFAULT NODES */}
      <Node
        type="default"
        group="default"
        label="Maths Function"
      />
      <Node
        type="default"
        group="default"
        label="Format data"
      />
      <Node
        type="ifThenElse"
        group="default"
        label="If/Then/Else"
      />
      <Node
        type="customScript"
        group="default"
        label="Custom Script"
      />

      {/* OUTPUT NODES */}
      <Node
        type="output"
        group="output"
        label="Alarm"
      />
      <Node
        type="output"
        group="output"
        label="Send Notification"
      />
      <Node
        type="output"
        group="output"
        label="Update SensorValue"
      />
      <Node
        type="output"
        group="output"
        label="Call Webhook"
      />
      <Node
        type="output"
        group="output"
        label="Trigger Routine"
      />
    </aside>
  )
}

export default Sidebar
