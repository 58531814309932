export const MODES = {
  READ_ONLY: 'Reading',
  VIEWING: 'Viewing',
  EDITING: 'Editing',
  MODIFYING: 'Modifying',
  DRAW_POLYGON: 'Drawing Polygon',
  DRAW_RECTANGLE: 'Drawing Rectangle',
  DRAW_SQUARE: 'Drawing Square',
  DRAW_CIRCLE: 'Drawing Circle'
}
