import { useEffect, useState, type ReactNode } from 'react'
import Select from 'src/Input/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AssetType {
  id: number
  name: string
  description: string
}

export interface Props {
  required?: boolean
  disabled?: boolean
  value?: number
  error?: ReactNode
  data: AssetType[]
  onChange: (value?: AssetType) => void
}

export default function SelectAssetType ({
  required = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const [value, setValue] = useState(props.value?.toString() ?? '')

  useEffect(() => {
    const type = props.data.find(type => type.id.toString() === value)
    if (type) {
      props.onChange(type)
    } else {
      props.onChange()
    }
  }, [value])

  return (
    <Select
      searchable
      withAsterisk={required}
      disabled={disabled}
      label="Asset Type"
      placeholder="Select asset type..."
      icon={<FontAwesomeIcon icon={['fas', 'search']} />}
      data={props.data.map(({ id, name }) => ({
        value: id.toString(),
        label: name
      }))}
      value={value}
      onChange={val => setValue(val ?? '')}
      error={error}
    />
  )
}
