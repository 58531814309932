import { getGeoZoneRules, type GeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import { useEffect, useState } from 'react'
import { type SelectProps } from '@mantine/core'
import Select from 'src/Input/Select'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'

interface Props extends Omit<SelectProps, 'data'> {
  existingRules: number[]
  setExternalRule: (value: string | null) => void
}

const ruleToSelect = ({ geoZoneRuleId, name }: GeoZoneRule) => ({
  value: geoZoneRuleId.toString(),
  label: name
})

export default function SelectRule ({
  existingRules,
  setExternalRule,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const rules = useApi(getGeoZoneRules)
  const [ruleId, setRuleId] = useState<string | null>(null)

  const selectRules = rules.data.mapOrDefault(data => (
    data.items
      .filter(({ geoZoneRuleId }) => !existingRules.includes(geoZoneRuleId))
      .map(ruleToSelect)
  ), [])

  const loadRules = () => {
    void rules.fetch({
      orgId,
      page: 1,
      size: 100000
    }, token)
  }

  useEffect(() => {
    void loadRules()
  }, [])

  return (
    <Select
      placeholder="Choose a rule"
      value={ruleId}
      data={selectRules}
      onChange={item => {
        setExternalRule(item)
        setRuleId(item)
      }}
      disabled={rules.loading}
      {...props}
    />
  )
}
