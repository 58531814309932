import { useNavigate } from 'react-router-dom'
import { Code, Group, Paper, Stack } from '@mantine/core'
import { type SensorDataTransformer } from '@venturi-io/api/src/config/sensorDataTransformer'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { parseEmpty } from 'src/utils/parseEmpty'

export default function Card ({ id, name, function: fn }: SensorDataTransformer & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/my-admin/scale-factor/edit/${id}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup label="Name" value={name} />
        <Code>{parseEmpty(fn).toString()}</Code>
      </Stack>
    </Paper>
  )
}
