import { type SizeProp } from '@fortawesome/fontawesome-svg-core'
import { createStyles } from '@mantine/core'
import { curveTypes } from 'src/Charts/shared'
import { capitalise } from 'src/utils/strings'

export const useSharedStyles = createStyles(() => ({
  scrollArea: {
    height: '100%',
    width: '100%',
    background: 'white'
  },
  itemContainer: {
    position: 'relative',
    padding: '0 !important', // TODO: fix this !important
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemBox: {
    height: '250px'
  },
  linkRedirect: {
    textDecoration: 'none'
  }
}))

export const curveTypeOptions = curveTypes.map(type => ({
  label: capitalise(type),
  value: type
}))

export const getResponsiveIconSize = (size: number, baseSize: number): SizeProp => {
  const value = Math.floor(size / baseSize)
  switch (value) {
    case 1:
      return 'sm'
    case 2:
      return 'lg'
    case 3:
      return '2x'
    case 4:
      return '3x'
    default:
      return '4x'
  }
}
