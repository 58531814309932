import { type ReactNode, useEffect, useState } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { getAgentDetails, type Sensor } from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  agentId: number
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const sensorToSelect = ({ sensorInstanceId, name }: Sensor): SelectItem => ({
  value: sensorInstanceId.toString(),
  label: name
})

export default function SelectAgentSensor ({
  agentId,
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const agent = useApi(getAgentDetails)
  const [initialLoad, setInitialLoad] = useState(true)
  const [sensorInstanceId, setSensorInstanceId] = useState<Value>(value ?? null)

  const selectSensors = agent.data.mapOrDefault(({ sensors }) => (
    sensors.map(sensorToSelect)
  ), [])

  useEffect(() => {
    void agent.fetch({ agentId }, token)

    if (initialLoad) {
      setInitialLoad(false)
    } else {
      setSensorInstanceId(null)
    }
  }, [agentId])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a sensor'}
      data={selectSensors}
      defaultValue={defaultValue}
      value={value ?? sensorInstanceId}
      onChange={value => {
        setSensorInstanceId(value)

        if (onChange) {
          onChange(value)
        }
      }}
      required={required}
      searchable={searchable}
      disabled={disabled || agent.loading}
      error={error}
      {...props}
    />
  )
}
