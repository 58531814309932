/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Group,
  Table as MantineTable,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { useDisclosure } from '@mantine/hooks'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { downloadCSV } from 'src/utils/files'
import { useNotifications } from 'src/utils/notifications'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { secondsToDhms } from 'src/Assets/shared'
import { getTripIdlingLocations } from '@venturi-io/api/src/collector/trip'
import MapToPdf from 'src/Maps/MapToPdf'
import MapBuilderModal, { type BuilderProps } from 'src/Maps/MapToPdf/MapBuilderModal'
import { IconPrinter } from '@tabler/icons-react'
import { type ReportFileType } from '..'
import IdleTime from './IdleTime'
import ExportToPdf from './ExportToPdf'
import { type ParsedIdle } from './ExportToPdf/AgentIdleHistory'

interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setIdleData: (data: ParsedIdleEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface IdlingItem {
  trip_id: string
  trip_end_time: string
  org_user_last_name: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string | string[]
  user_groups_names: string
  org_user_id: string
  trip_start_time: string
  org_user_first_name: string
  total_idling_events: string
  total_idling_duration_in_seconds: string
}

export interface ParsedIdleEvent {
  trip_id: string | null
  asset: string | null
  date: string | null
  count: string | null
  driver: string | null
  group: string | null
  total_idle: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: IdlingItem[]
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: IdlingItem
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

const Row = ({ trip, range, onDownloadFile }: RowProps) => {
  const ref = useRef<BuilderProps>(null)
  const {
    agent_id,
    trip_id,
    agent_name,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name,
    total_idling_events,
    total_idling_duration_in_seconds
  } = trip
  const { colors } = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const onClickDownloadFile = useCallback((type: ReportFileType, mapUri?: string) => {
    if (onDownloadFile) {
      onDownloadFile(type, Number(trip_id), range, mapUri)
    }
  }, [onDownloadFile, trip_id, range])

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: open
            ? colors.gray[0]
            : 'white',
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text color="primary" weight={open ? 'bold' : 'normal'}>{agent_name}</Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_idling_events}</td>
        <td>{secondsToDhms(Number(total_idling_duration_in_seconds))}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <td align="right" onClick={(e) => e.stopPropagation()}>
          <Group
            spacing="lg"
            position="center"
            align="center"
          >
            <Link to={`/?agentId=${agent_id}&tripId=${trip_id}&startTime=${encodeURIComponent(trip_start_time ?? '')}`}>
              <Button
                size="xs"
                leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
                variant="outline"
                color="primary"
              >
                View trip
              </Button>
            </Link>
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <IconPrinter size={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack align="flex-start" spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('CSV')}
                    >
                      Download as CSV
                    </Button>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => ref.current?.open()}
                    >
                      Download as PDF
                    </Button>
                    {/* Map modal so it won't show until download pdf is clicked */}
                    <MapBuilderModal
                      ref={ref}
                      mode="idle"
                      tripId={Number(trip_id)}
                      startTime={trip_start_time}
                      didFinishLoading={(uri) => onClickDownloadFile('PDF', uri)}
                    />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={7}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <Stack>
                <IdleTime
                  showTitle={false}
                  startTime={trip_start_time}
                  endTime={trip_end_time}
                  tripId={parseInt(trip_id ?? '0')}
                  style={{
                    width: '100%',
                    height: tableHeight,
                    margin: 0
                  }}
                  stickyTop={78}
                  didChangeHeight={(height) => {
                    setTableHeight(height)
                  }}
                />
              </Stack>
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const Rows = ({ data, range, maxWidth, onDownloadFile }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
        onDownloadFile={onDownloadFile}
      />
    ))
    : null
}

export default function IdleReportView (props: Props) {
  const {
    data,
    queryId,
    setIdleData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const { token } = useUser()
  const { showError } = useNotifications()
  const tripIdlings = useApi(getTripIdlingLocations)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [selectedTripId, setTripId] = useState<string | null>()
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [exportableData, setExportableData] = useState<ParsedIdle[]>([])
  const idleData = data[`${queryId}`] as unknown as IdlingItem[] ?? []
  const [selectedIdleItem, setIdleItem] = useState<IdlingItem | null>(null)
  const [exportableMapImage, setMapImage] = useState<string | null>(null)

  // Convert agent group ids into array of string
  const updatedIdleData = idleData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedIdleData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedIdleData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedIdleData
  , [updatedIdleData, agentGroupIds])

  // Filtered updatedIdleData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : filteredDataByGroups
  , [filteredDataByGroups, selectedAgentIds])

  const parsedIdle: ParsedIdleEvent[] = filteredDataById.map(({
    trip_id,
    agent_name,
    trip_start_time,
    org_user_first_name,
    org_user_last_name,
    total_idling_events,
    total_idling_duration_in_seconds,
    user_groups_names
  }) => ({
    trip_id,
    asset: agent_name,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    group: user_groups_names ?? 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_idling_events,
    total_idle: secondsToDhms(Number(total_idling_duration_in_seconds))
  }))

  const handleDownloadFile = useCallback(async (
    type: ReportFileType,
    tripId: number,
    dateRange: Range | null,
    mapUri?: string
  ) => {
    setReportType(type)
    setTripId(tripId.toString())
    if (mapUri) {
      setMapImage(mapUri)
    }

    const idleTimeItem = idleData.find(({ trip_id }) =>
      trip_id === tripId.toString()
    )

    if (idleTimeItem) {
      const {
        trip_end_time,
        trip_start_time
      } = idleTimeItem

      await tripIdlings.fetch({
        tripId,
        startTime: trip_start_time,
        endTime: trip_end_time
      }, token)
    }
  }, [])

  useEffect(() => {
    setIdleItem(
      filteredDataById.find(({ trip_id }) => trip_id === selectedTripId) ??
      null
    )
  }, [selectedTripId])

  useEffect(() => {
    setIdleData(parsedIdle)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  useEffect(() => {
    const agentName = selectedIdleItem?.agent_name ?? '-'

    // generate and download agent's trips report
    tripIdlings.data.ifJust(async (data) => {
      try {
        const events = await Promise.all(data.map(async (event, idx) => {
          const {
            id,
            time,
            latitude,
            longitude,
            idlingDuration,
            geoZones
          } = event
          try {
            const location = await reverseGeocode([longitude, latitude])

            const geoZoneName = geoZones.map(({ name }) => name).join(', ')

            return reportType === 'CSV'
              ? {
                  'Event #': idx + 1,
                  'Event Time': dayjs(time).format(uiDateFormatUniversal),
                  Location: location,
                  Geozone: geoZoneName,
                  'Idling Duration': secondsToDhms(idlingDuration)
                }
              : {
                  ...event,
                  location
                }
          } catch (err: any) {
            throw Error('Failed to generate idle time alert reports')
          }
        }))

        if (reportType === 'CSV') {
          downloadCSV(events as unknown as Array<Record<string, unknown>>, `${agentName} - Idling Time Report`)
        }

        if (reportType === 'PDF') {
          const data = events as unknown as ParsedIdle[]
          setExportableData(data)
          toggle()
        }
      } catch (error) {
        showError(error as Error)
      }
    })
  }, [tripIdlings.data])

  return (
    <>
      <Paper
        p="sm"
        shadow="xs"
        sx={{
          width: '100%',
          scrollbarWidth: 'thin'
        }}
      >
        <MantineTable
          sx={{
            position: 'relative'
          }}
        >
          <thead
            style={{
              background: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 100
            }}
          >
            <tr style={{ borderBottom: 1 }}>
              <th>Asset Name</th>
              <th style={{ width: 180 }}>Date</th>
              <th style={{ width: 100 }}>Count</th>
              <th>Total Idle Time</th>
              <th>Driver</th>
              <th>Group</th>
              <th style={{ width: 180 }} />
            </tr>
          </thead>
          <tbody>
            <Rows
              {...props}
              data={filteredDataById}
              onDownloadFile={handleDownloadFile}
            />
          </tbody>
        </MantineTable>
      </Paper>
      {selectedIdleItem && (
        <ExportToPdf
          agentName={selectedIdleItem.agent_name ?? 'N/A'}
          mapUri={exportableMapImage}
          data={exportableData}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
          {...selectedIdleItem}
        />
      )}
    </>
  )
}
