import { ActionIcon, Group, type GroupProps } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props extends GroupProps {
  hide?: boolean
  disabled?: boolean
  loading?: boolean
  extra?: JSX.Element
  save: () => void
  cancel: () => void
}

export default function Actions ({
  disabled,
  loading,
  extra,
  hide,
  save,
  cancel,
  ...rest
}: Props) {
  return (
    <Group {...rest}>
      {!hide && (
        <ActionIcon
          title="Cancel"
          size="sm"
          variant="filled"
          color="gray"
          disabled={loading}
          onClick={cancel}
        >
          <FontAwesomeIcon icon={['fas', 'times']} color="white" size="sm" />
        </ActionIcon>
      )}
      {!hide && !disabled && (
        <ActionIcon
          title="Save"
          size="sm"
          variant="filled"
          color="green"
          loading={loading}
          onClick={save}
        >
          <FontAwesomeIcon icon={['fas', 'check']} color="white" size="sm" />
        </ActionIcon>
      )}
      {extra}
    </Group>
  )
}
