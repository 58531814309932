import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Routes, Route } from 'react-router-dom'
import { type User } from '@venturi-io/api/src/userManager/auth'
import { useZendesk } from 'src/ZendeskContext'
import Login from '../Login'
import Registration from '../Registration'
import AccountVerification from '../PasswordReset/AccountVerification'
import PasswordReset from '../PasswordReset'

const NotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [])

  return <div></div>
}

interface LoginRouterProps {
  orgId: number
  signin: (user: User) => void
}

export default function LoginRouter ({ orgId, signin }: LoginRouterProps) {
  const { hide } = useZendesk()

  useEffect(() => {
    hide()
  }, [])

  return (
    <Routes>
      <Route path="/join/:activationId" element={<Registration signin={signin} orgId={orgId} />} />
      <Route path="/accountVerification" element={<AccountVerification orgId={orgId} />} />
      <Route path="/resetPassword/:resetId" element={<PasswordReset signin={signin} orgId={orgId} />} />
      <Route path="/" element={<Login signin={signin} orgId={orgId} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
