import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group,
  Switch
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import Select from 'src/Input/Select'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createGeoZoneRule,
  deleteGeoZoneRule,
  updateGeoZoneRule,
  type GeoZoneRule
} from '@venturi-io/api/src/config/geoZoneRule'
import { geoZoneRuleTypes } from '@venturi-io/api/src/config/geoZoneRule'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { TimeInput } from '@mantine/dates'
import { useSelectStyles } from 'src/Input/styles'
import SelectTimezone from 'src/Input/Select/SelectTimezone'

interface Props {
  mode: FormMode
  initialValues?: GeoZoneRule
  onCreate?: () => Promise<void>
  onClose?: () => void
}

const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]

const checkRuleType = (type: string) => {
  if (type === 'ENTER_ZONE_TIMEFRAME' || type === 'LEAVE_ZONE_TIMEFRAME') {
    return true
  }

  return false
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { token, orgId } = useUser()
  const { classes } = useSelectStyles()
  const navigate = useNavigate()
  const newGeoZoneRule = useApi(createGeoZoneRule)
  const editGeoZoneRule = useApi(updateGeoZoneRule)
  const removeGeoZoneRule = useApi(deleteGeoZoneRule)
  const [showDelete, setShowDelete] = useState(false)
  const [checkTypeIsSpeed, setCheckTypeIsSpeed] = useState(false)
  const [checkTypeIsZone, setCheckTypeIsZone] = useState(false)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<Omit<GeoZoneRule, 'geoZoneRuleId'>>({
    initialValues: {
      name: '',
      type: '',
      editable: true,
      quietMode: false,
      numericValue: undefined,
      startDay: '',
      startTime: '',
      endDay: '',
      endTime: '',
      timezone: ''
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null,
      type: value => value.length < 1
        ? 'Please specify Type'
        : null,
      numericValue: value => !value && checkTypeIsSpeed
        ? 'Please specify Speed Limit'
        : null,
      startDay: value => !value && checkTypeIsZone
        ? 'Please specify Start Day'
        : null,
      startTime: value => !value && checkTypeIsZone
        ? 'Please specify Start Time'
        : null,
      endDay: value => !value && checkTypeIsZone
        ? 'Please specify End Day'
        : null,
      endTime: value => !value && checkTypeIsZone
        ? 'Please specify End Time'
        : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/settings/geozone-rules')

  const handleCreate = useCallback(({
    type,
    startTime,
    endTime,
    startDay,
    endDay,
    timezone,
    ...values
  }: FormValues) => {
    const req = {
      orgId,
      type,
      startDay: checkRuleType(type)
        ? startDay?.toUpperCase()
        : undefined,
      startTime: checkRuleType(type)
        ? startTime
        : undefined,
      endDay: checkRuleType(type)
        ? endDay?.toUpperCase()
        : undefined,
      endTime: checkRuleType(type)
        ? endTime
        : undefined,
      timezone: checkRuleType(type)
        ? timezone
        : undefined,
      ...values
    }
    void newGeoZoneRule
      .fetch(req, token, 'Successfully created new geozone rule')
      .finally(() => {
        form.reset()

        if (onClose) {
          onClose()
        }

        if (onCreate) {
          void onCreate()
        }
      })
  }, [form.values])

  const handleUpdate = useCallback(({
    type,
    startDay,
    startTime,
    endDay,
    endTime,
    timezone,
    ...values
  }: FormValues) => {
    const geoZoneRuleId = initialValues?.geoZoneRuleId

    if (!geoZoneRuleId) {
      throw new Error('Unable to perform the action, no geozone rule provided')
    }

    const req = {
      orgId,
      type,
      geoZoneRuleId,
      startTime: checkRuleType(type)
        ? startTime
        : undefined,
      startDay: checkRuleType(type)
        ? startDay?.toUpperCase()
        : undefined,
      endTime: checkRuleType(type)
        ? endTime
        : undefined,
      endDay: checkRuleType(type)
        ? endDay?.toUpperCase()
        : undefined,
      timezone: checkRuleType(type)
        ? timezone
        : undefined,
      ...values
    }

    void editGeoZoneRule
      .fetch(req, token, 'Successfully updated geozone rule')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const geoZoneRuleId = initialValues?.geoZoneRuleId

    if (!geoZoneRuleId) {
      throw new Error('Unable to perform the action, no geozone rule provided')
    }

    void removeGeoZoneRule
      .fetch({
        orgId,
        geoZoneRuleId
      }, token, 'Succesfully deleted geozone rule')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) {
      handleCreate(values)
    }

    if (inEditMode) {
      handleUpdate(values)
    }
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  useEffect(() => {
    if (initialValues) {
      form.setValues(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    if (form.values.type === 'SPEED_EXCEEDS') {
      setCheckTypeIsSpeed(true)
    } else {
      setCheckTypeIsSpeed(false)
    }
    if (
      form.values.type === 'ENTER_ZONE_TIMEFRAME' ||
      form.values.type === 'LEAVE_ZONE_TIMEFRAME'
    ) {
      setCheckTypeIsZone(true)
    } else {
      setCheckTypeIsZone(false)
    }
  }, [form.values])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput
              withAsterisk
              label="Name"
              {...form.getInputProps('name')}
            />
          </Col>
          <Col {...inputColProps}>
            <TextInput
              label="Description"
              {...form.getInputProps('description')}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Type"
              data={geoZoneRuleTypes}
              {...form.getInputProps('type')}
            />
          </Col>
          {checkTypeIsSpeed && (
            <Col {...inputColProps}>
              <NumberInput
                label="Speed Limit"
                {...form.getInputProps('numericValue')}
                withAsterisk
              />
            </Col>
          )}
          {checkTypeIsZone && (
            <>
              <Col {...inputColProps}>
                <SelectTimezone
                  searchable
                  label="Time zone"
                  {...form.getInputProps('timezone')}
                  showInfo={false}
                />
              </Col>
              <Col span={6}>
                <Select
                  label="Start Day"
                  data={days}
                  {...form.getInputProps('startDay')}
                  withAsterisk={checkTypeIsZone}
                />
              </Col>
              <Col span={6}>
                <TimeInput
                  classNames={classes}
                  label="Start time"
                  {...form.getInputProps('startTime')}
                  withAsterisk={checkTypeIsZone}
                />
              </Col>
              <Col span={6}>
                <Select
                  label="End Day"
                  data={days}
                  {...form.getInputProps('endDay')}
                  withAsterisk={checkTypeIsZone}
                />
              </Col>
              <Col span={6}>
                <TimeInput
                  classNames={classes}
                  label="End time"
                  {...form.getInputProps('endTime')}
                  withAsterisk={checkTypeIsZone}
                />
              </Col>
            </>
          )}

          <Col {...inputColProps}>
            <Switch
              color="primary"
              label="Quiet mode"
              description="Hides the notification popup when enabled"
              checked={form.values.quietMode}
              {...form.getInputProps('quietMode')}
            />
          </Col>

          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="sm">
                <Button
                  mt="sm"
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode
                  ? handleClose
                  : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode
                      ? 'circle-plus'
                      : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode
                  ? newGeoZoneRule.loading
                  : editGeoZoneRule.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeGeoZoneRule.loading}
          question="Are you sure you want to delete this geozone rule? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
