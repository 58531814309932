import { useState } from 'react'
import { Modal, Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { type CameraEvent } from '@venturi-io/api/src/config/cameraEvents'
import { IFrame } from 'src/Dashboard/Items'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'
import TextTooltip from 'src/Layout/TextTooltip'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({ ...events }: CameraEvent & ListRow) {
  const { classes, cx } = useStyles()
  const {
    eventId,
    dateTime,
    startTime,
    endTime,
    length,
    eventType,
    tagId,
    poi,
    lpr,
    viewEvent
  } = events

  const [modalOpen, setModalOpen] = useState(false)
  const isOk = viewEvent === 'true'
  const color = isOk
    ? 'black'
    : 'gray'
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  const iframeProperties = {
    name: `${tagId}`,
    // eslint-disable-next-line max-len
    url: 'https://command.verkada.com/embed.html#%7B%22entityId%22%3A%20%22d2a85500-841f-42f8-be77-e39f9b34002a%22%2C%20%22embedLinkId%22%3A%20%22348b2a42-8298-4544-90d2-20fd4890a768%22%2C%20%22domains%22%3A%20%22%2A.venturi.io%22%2C%20%22token%22%3A%20%22v2_6fd862f9e3a6c1d1530764de0b07b283%22%2C%20%22urlSD%22%3A%20%22https%3A//vstream.command.verkada.com/filter/transcode/v2_6fd862f9e3a6c1d1530764de0b07b283/d2a85500-841f-42f8-be77-e39f9b34002a/low_res/livevideo.m3u8%22%2C%20%22urlHD%22%3A%20%22https%3A//vfilter.command.verkada.com/filter/transcode/v2_6fd862f9e3a6c1d1530764de0b07b283/d2a85500-841f-42f8-be77-e39f9b34002a/high_res/livevideo.m3u8%22%7D',
    id: '',
    title: '',
    customStyle: {
      width: '100%',
      height: '300px'
    }
  }

  const handleOpenModal = () => {
    if (isOk) {
      setModalOpen(true)
    }
  }

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{eventId}</Text>
      </div>
      <div className={cx(classes.col)}>
        <Text size="sm">{parseEmpty(dateTime)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(startTime)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(endTime)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(length)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(eventType)}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(tagId)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(poi)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(lpr)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <Modal
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          title="View Live Feed"
          centered
        >
          <IFrame {...iframeProperties} />
        </Modal>
        <ActionButton
          label="Events"
          icon="camera-security"
          iconType="far"
          onClick={() => handleOpenModal()}
          actionColor="white"
          actionSize="xs"
          actionRadius="lg"
          iconColor={color}
          iconSize="lg"
        />
      </div>
    </div>
  )
}
