/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Table as MantineTable,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal, uiDateOnly, uiTimeOnly } from 'src/utils/dates'
import { useDisclosure } from '@mantine/hooks'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { downloadCSV } from 'src/utils/files'
import { useNotifications } from 'src/utils/notifications'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { secondsToDhms } from 'src/Assets/shared'
import { getGeozoneEventsForTrip } from '@venturi-io/api/src/analytics/geozone'
import { IconPrinter } from '@tabler/icons-react'
import { type ReportFileType } from '..'
import ExportToPdf from './ExportToPdf'
import { type ParsedGeozone } from './ExportToPdf/AgentGeozoneHistory'
import GeozoneEvent from './GeozoneEvent'

interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setGeozoneReportData: (data: ParsedGeozoneEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface GeozoneItem {
  trip_id: string
  total_geo_zone_events: string
  trip_end_time: string
  org_user_last_name: string
  geo_zone_names: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string | string[]
  user_groups_names: string
  org_user_id: string
  trip_start_time: string
  org_user_first_name: string
}

export interface ParsedGeozoneEvent {
  trip_id: string | null
  asset: string | null
  geozoneNames: string | null
  date: string | null
  count: string | null
  driver: string | null
  group: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: GeozoneItem[]
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null) => void
}

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: GeozoneItem
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null) => void
}

const Row = ({ trip, range, onDownloadFile }: RowProps) => {
  const {
    agent_id,
    trip_id,
    agent_name,
    geo_zone_names,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name,
    total_geo_zone_events
  } = trip
  const { colors } = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const onClickDownloadFile = useCallback((type: ReportFileType) => {
    if (onDownloadFile) {
      onDownloadFile(type, Number(trip_id), range)
    }
  }, [onDownloadFile, trip_id, range])

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: open
            ? colors.gray[0]
            : 'white',
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text color="primary" weight={open ? 'bold' : 'normal'}>{agent_name}</Text>
        </td>
        <td>{geo_zone_names}</td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_geo_zone_events}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <td align="right" onClick={(e) => e.stopPropagation()}>
          <Group
            spacing="lg"
            position="center"
            align="center"
          >
            <Link to={`/?agentId=${agent_id}&tripId=${trip_id}&startTime=${encodeURIComponent(trip_start_time ?? '')}`}>
              <Button
                size="xs"
                leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
                variant="outline"
                color="primary"
              >
                View trip
              </Button>
            </Link>
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <IconPrinter size={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack align="flex-start" spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('CSV')}
                    >
                      Download as CSV
                    </Button>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('PDF')}
                    >
                      Download as PDF
                    </Button>
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={7}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <GeozoneEvent
                showTitle={false}
                startTime={trip_start_time}
                endTime={trip_end_time}
                tripId={parseInt(trip_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const Rows = ({ data, range, maxWidth, onDownloadFile }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
        onDownloadFile={onDownloadFile}
      />
    ))
    : null
}

export default function GeozoneReportView (props: Props) {
  const {
    data,
    queryId,
    setGeozoneReportData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const { token } = useUser()
  const { showError } = useNotifications()
  const geozoneEvents = useApi(getGeozoneEventsForTrip)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [selectedTripId, setTripId] = useState<string | null>()
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [exportableData, setExportableData] = useState<ParsedGeozone[]>([])
  const geozoneData = data[`${queryId}`] as unknown as GeozoneItem[] ?? []
  const [selectedGeozoneItem, setGeozoneItem] = useState<GeozoneItem | null>(null)

  // Convert agent group ids into array of string
  const updatedGeozoneData = geozoneData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedGeozoneData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedGeozoneData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedGeozoneData,
  [updatedGeozoneData, agentGroupIds])

  // Filtered updatedGeozoneData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id ?? ''))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedGeozone: ParsedGeozoneEvent[] = filteredDataById.map(({
    trip_id,
    agent_name,
    geo_zone_names,
    trip_start_time,
    org_user_first_name,
    org_user_last_name,
    total_geo_zone_events,
    user_groups_names
  }) => ({
    trip_id,
    asset: agent_name,
    geozoneNames: geo_zone_names,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    group: user_groups_names ?? 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_geo_zone_events
  }))

  const handleDownloadFile = useCallback(async (
    type: ReportFileType,
    tripId: number
  ) => {
    setReportType(type)
    setTripId(tripId.toString())
    await geozoneEvents.fetch({
      tripId
    }, token)
  }, [])

  useEffect(() => {
    setGeozoneItem(
      filteredDataById.find(({ trip_id }) => trip_id === selectedTripId) ??
      null
    )
  }, [selectedTripId])

  useEffect(() => {
    setGeozoneReportData(parsedGeozone)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  useEffect(() => {
    const agentName = selectedGeozoneItem?.agent_name ?? '-'

    // generate and download agent's trips report
    geozoneEvents.data.ifJust(async (data) => {
      try {
        const events = await Promise.all(data.map(async (event, idx) => {
          const {
            geoZoneName,
            timesVisited,
            enteredTime,
            leftTime,
            totalParkedTime,
            totalDurationInsideZone
          } = event

          return reportType === 'CSV'
            ? {
                'Event #': idx + 1,
                Geozone: geoZoneName,
                'Event Date': dayjs(enteredTime).format(uiDateOnly),
                'Time In': dayjs(enteredTime).format(uiTimeOnly),
                'Time Out': dayjs(leftTime).format(uiTimeOnly),
                'Duration In': secondsToDhms(totalDurationInsideZone),
                'Parked Duration': secondsToDhms(totalParkedTime ?? 0),
                'Times Visited': secondsToDhms(timesVisited)
              }
            : event
        }))

        if (reportType === 'CSV') {
          downloadCSV(events as unknown as Array<Record<string, unknown>>, `${agentName} - Geozone Event Report`)
        }

        if (reportType === 'PDF') {
          const data = events as unknown as ParsedGeozone[]
          setExportableData(data)
          toggle()
        }
      } catch (error) {
        showError(error as Error)
      }
    })
  }, [geozoneEvents.data])

  return (
    <>
      <Paper
        p="sm"
        shadow="xs"
        sx={{
          width: '100%',
          scrollbarWidth: 'thin'
        }}
      >
        <MantineTable
          sx={{
            position: 'relative'
          }}
        >
          <thead
            style={{
              background: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 100
            }}
          >
            <tr style={{ borderBottom: 1 }}>
              <th>Asset Name</th>
              <th>Geozones</th>
              <th style={{ width: 180 }}>Date</th>
              <th style={{ width: 100 }}>Count</th>
              <th>Driver</th>
              <th>Group</th>
              <th style={{ width: 180 }} />
            </tr>
          </thead>
          <tbody>
            <Rows
              {...props}
              data={filteredDataById}
              onDownloadFile={handleDownloadFile}
            />
          </tbody>
        </MantineTable>
      </Paper>
      {selectedGeozoneItem && (
        <ExportToPdf
          agentName={selectedGeozoneItem.agent_name ?? 'N/A'}
          data={exportableData}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
          {...selectedGeozoneItem}
        />
      )}
    </>
  )
}
