import { useCallback } from 'react'
import { Marker as MapboxMarker } from 'react-map-gl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Group, Stack, createStyles, Indicator } from '@mantine/core'
import parseIconStyle from 'src/utils/parseIconStyle'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { truncateWithEllipsis } from 'src/utils/strings'
import { useHover } from '@mantine/hooks'
import { getAgentStatusColor } from 'src/utils/status'

interface MarkerData {
  longitude: number
  latitude: number
  key: string
}

export const parseMarkerData = ({
  agentId,
  lastLocation: {
    geoLocation: {
      longitude,
      latitude
    }
  }
}: Agent): MarkerData => ({
  key: agentId.toString(),
  longitude,
  latitude
})

interface StyleOptions {
  selected: boolean
  isAlarm: boolean
}

const useStyles = createStyles((_, options: StyleOptions) => {
  const bgColor = options.selected ? '#ffffffee' : '#ffffffcc'
  const fontWeight = options.selected ? 'bold' : 'normal'

  return {
    pin: {
      position: 'relative',
      background: bgColor,
      borderRadius: 5,
      padding: '5px 10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      cursor: 'pointer',
      transition: 'all ease-in .4s',
      '&::after': {
        transition: 'all ease-in .4s',
        content: "''",
        position: 'absolute',
        top: 33.5,
        left: 'auto',
        width: 0,
        height: 0,
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
        borderTop: `16px solid ${bgColor}`
      },
      '&:hover': {
        background: '#fff'
      },
      '&:hover::after': {
        content: "''",
        position: 'absolute',
        top: 33.5,
        left: 'auto',
        width: 0,
        height: 0,
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
        borderTop: '16px solid #fff'
      },
      animation: options.isAlarm
        ? 'pulse-alarm 1s infinite'
        : ''
    },
    icon: {
      width: 24,
      height: 24
    },
    label: {
      fontSize: '.7rem',
      fontWeight
    },
    details: {
      position: 'relative',
      overflow: 'auto'
    }
  }
})

export interface Props {
  agent: Agent
  selected?: boolean
  onClick?: (agentId: Agent['agentId']) => void
}

function Marker ({ agent, selected = false, onClick }: Props) {
  const { hovered, ref } = useHover()
  const id = selected
    ? 'tour-map__focused-asset'
    : 'asset-marker'

  const {
    agentName,
    iconStyle,
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  } = agent
  const agentStatusColor = getAgentStatusColor(
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  )
  const isAlarm = agentStatusColor === 'red'
  const icon = parseIconStyle(iconStyle ?? '')
  const { classes } = useStyles({ selected, isAlarm })

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(agent.agentId)
    }
  }, [agent, onClick])

  return (
    <MapboxMarker
      {...parseMarkerData(agent)}
      offset={[0, -30]}
      onClick={handleClick}
      style={{
        zIndex: selected || hovered ? 90 : 0
      }}
    >
      <Indicator
        size={14}
        color={agentStatusColor}
        processing={isAlarm}
        withBorder
      >
        <div id={id} className={classes.pin} ref={ref}>
          <Stack>
            <Group>
              <FontAwesomeIcon className={classes.icon} icon={icon} color={agentStatusColor} size="1x" />
              <span className={classes.label}>{truncateWithEllipsis(agentName, 20)}</span>
            </Group>
          </Stack>
        </div>
      </Indicator>
    </MapboxMarker>
  )
}

export default Marker
