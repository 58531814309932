import { memo, useCallback, useMemo } from 'react'
import { Accordion, Button, Stack } from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MultiSelectAgentSensor from 'src/Input/MultiSelect/MultiSelectAgentSensor'
import ItemPreview from '../shared/ItemPreview'
import AlarmList, { type Props as AlarmListProps } from '.'

interface Props {
  initialValues: AlarmListProps | null
  onSubmit: (properties: AlarmListProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: AlarmListProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        sensorInstanceIds: []
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const parsedSensorInstanceIds = useMemo(() => (
    form.values.data.sensorInstanceIds.map(id => id.toString())
  ), [form.values.data.sensorInstanceIds])

  const hasSelectedSensor = useMemo(() => (
    form.values.data.sensorInstanceIds.length > 0
  ), [form.values.data.sensorInstanceIds])

  const previewItem = useMemo(() => hasSelectedSensor, [hasSelectedSensor])

  const setFormDataValue = useCallback((
    key: string,
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview
        iconName="siren-on"
        previewItem={previewItem}
        disabled
      >
        <AlarmList {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <MultiSelectAgentSensor
                    label="Sensors"
                    value={parsedSensorInstanceIds}
                    onChange={sensorInstanceIds => {
                      setFormDataValue(
                        'sensorInstanceIds',
                        sensorInstanceIds.map(id => Number(id))
                      )
                    }}
                    withAlarmConfig
                    required
                    searchable
                    error={form.errors.sensorInstanceIds}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
