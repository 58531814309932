import { useEffect, useState } from 'react'
import { createStyles, Image } from '@mantine/core'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from './utils/host'
import { mq } from './utils/style'

const useStyles = createStyles(theme => ({
  logo: {
    marginBottom: 10,
    [mq(theme.breakpoints.sm)]: {
      width: 'auto'
    }
  },
  image: {
    objectPosition: '0'
  }
}))

function DefaultLogo () {
  const { classes } = useStyles()

  return (
    <Image
      className={classes.logo}
      src="/venturi-logo.png"
      alt="Main Logo"
      width={300}
      height={80}
      fit="contain"
    />
  )
}

export interface Props {
  minimised?: boolean
  alignLeft?: boolean
  orgId: number
}

export default function MainLogo ({ minimised = false, alignLeft = false, orgId }: Props) {
  const { classes, cx } = useStyles()
  const [fullUrl, setFullUrl] = useState<string | null>(null)
  const [minUrl, setMinUrl] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    void configure(getHost())
      .then(config => {
        setFullUrl(`${config.user}/api/usermanager/image/org/${orgId}/logo.png?type=topLogo&time=${Date.now()}`)
        setMinUrl(`${config.user}/api/usermanager/image/org/${orgId}/logo.png?type=topMobileLogo&time=${Date.now()}`)
      })
  }, [])

  if (!minUrl || !fullUrl) {
    return null
  }

  return !hasError
    ? (
      <Image
        className={cx(classes.logo)}
        src={minimised
          ? minUrl
          : fullUrl}
        width={300}
        height={80}
        fit="contain"
        onError={() => {
          setHasError(true)
        }}
        styles={{
          image: {
            objectPosition: alignLeft
              ? '0'
              : 'unset'
          }
        }}
      />
      )
    : <DefaultLogo />
}
