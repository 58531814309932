import {
  ActionIcon,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Tooltip
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const fuelLevelPercent = findSensorByType('FUEL_LEVEL', sensors)
  const fuelLevelLiter = findSensorByType('FUEL_LEVEL_L', sensors)
  const fuelCapacity = findSensorByType('FUEL_CAPACITY', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
            to={`/assets/tank/${agentId}`}
          />
          <TextGroup
            label="Fuel Level (Percent)"
            value={fuelLevelPercent?.currentValue
              ? `${fuelLevelPercent.currentValue} ${fuelLevelPercent.unit}`
              : '-'}
          />
          <TextGroup
            label="Fuel Level (Liter)"
            value={fuelLevelLiter?.currentValue
              ? `${fuelLevelLiter.currentValue} ${fuelLevelLiter.unit}`
              : '-'}
          />
          <TextGroup
            label="Fuel Capacity"
            value={fuelCapacity?.currentValue
              ? `${fuelCapacity.currentValue} ${fuelCapacity.unit}`
              : '-'}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <Group
            spacing={4}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Transactions" value="" />
            <Link to={`/assets/tanks/${agentId}/transactions`}>
              <Tooltip
                label="View Transactions"
                position="top"
                transitionProps={{
                  transition: 'pop',
                  duration: 200
                }}
                withArrow
              >
                <ActionIcon color="primary" variant="transparent">
                  <FontAwesomeIcon icon={['far', 'receipt']} size="lg" />
                </ActionIcon>
              </Tooltip>
            </Link>
          </Group>
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
