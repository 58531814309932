import {
  ActionIcon,
  Group,
  Paper,
  TextInput,
  useMantineTheme
} from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useViewTypes } from '../AgentAttendanceContext'

function SearchBar () {
  const { colors } = useMantineTheme()
  const [value, setValue] = useState('')
  const { setQuery } = useViewTypes()

  useEffect(() => {
    setQuery(value)
  }, [value])

  return (
    <Paper
      bg="#fff"
      shadow="sm"
      h={48}
      py={8}
      px={12}
      mb="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <TextInput
        size="xs"
        value={value}
        variant="unstyled"
        placeholder="Search assets or drivers"
        rightSectionWidth={60}
        rightSection={(
          <Group w={60} position="right">
            {value.length > 0 && (
              <ActionIcon
                variant="filled"
                radius="lg"
                onClick={() => setValue('')}
                size="xs"
                color="gray.6"
              >
                <IconX size={12} />
              </ActionIcon>
            )}
            <IconSearch size={18} color={colors.primary[6]} />
          </Group>
        )}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
    </Paper>
  )
}

export default SearchBar
