import { createStyles, Box, Paper, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 240 // TODO: Define exact width
    }
  },
  card: {
    background: '#F6F5B6'
  },
  imageCard: {
    height: 200 // TODO: Define exact height
  },
  detailsCard: {
    height: 400 // TODO: Define exact height
  },
  locationCard: {
    height: 300 // TODO: Define exact height
  },
  tasksCard: {
    height: 60 // TODO: Define exact height
  },
  eventsCard: {
    height: 60 // TODO: Define exact height
  }
}))

export default function Details () {
  const { classes, cx } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <Paper
          className={cx(classes.imageCard, classes.card)}
          radius="sm"
        >
        </Paper>
        <Paper
          className={cx(classes.detailsCard, classes.card)}
          radius="sm"
        >
        </Paper>
        <Paper
          className={cx(classes.locationCard, classes.card)}
          radius="sm"
        >
        </Paper>
        <Paper
          className={cx(classes.tasksCard, classes.card)}
          radius="sm"
        >
        </Paper>
        <Paper
          className={cx(classes.eventsCard, classes.card)}
          radius="sm"
        >
        </Paper>
      </Stack>
    </Box>
  )
}
