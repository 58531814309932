import { useCallback } from 'react'
import { type ReactourStep } from 'reactour'
import Tour, { type Props } from '../Tour'
import InformationBox from '../InformationBox'

const steps: ReactourStep[] = [
  {
    selector: '#tour-asset-task__menu',
    content: () => (
      <InformationBox
        title="Managing Tasks on your Assets"
        message="Here we can set up, manage and remove tasks associated to assets such as adding registration
          information, maintenance requirements, and certification documentation."
      />
    )
  }
]

export default function AssetTaskTour ({
  isOpen,
  setIsOpen,
  setIsDone
}: Omit<Props, 'steps' | 'onClickLastStepNextButton'>) {
  const handleClickNextTour = useCallback(() => {
    if (setIsDone) {
      setIsDone(true)
    }
  }, [])

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClickLastStepNextButton={handleClickNextTour}
    />
  )
}
