import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

interface Props {
  color: FontAwesomeIconProps['color']
}

const MobiliseIcon = ({ color }: Props) => {
  return (
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon icon={['far', 'car']} color={color} size="1x" />
      <FontAwesomeIcon
        icon={['fas', 'transformer-bolt']}
        color={color}
        transform={{
          size: 10,
          x: 5,
          y: 5
        }}
      />
    </span>
  )
}

export default MobiliseIcon
