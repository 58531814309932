import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const upsStatus = findSensorByType('UPS_STATUS', sensors)
  const runtime = findSensorByType('UPS_RUNTIME', sensors)
  const batteryLevel = findSensorByType('BATTERY', sensors)
  const main = findSensorByType('MAINS_VOLTAGE', sensors)
  const load = findSensorByType('LOAD_KVA', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
            to={`/assets/ups/${agentId}`}
          />
          <TextGroup
            label="UPS Status"
            value={parseEmpty(upsStatus?.currentValue)}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <TextGroup
            label="Runtime"
            value={parseEmpty(runtime?.currentValue)}
          />
          <TextGroup
            label="Battery Level"
            value={batteryLevel?.currentValue
              ? `${batteryLevel.currentValue} ${batteryLevel.unit}`
              : '-'}
          />
          <TextGroup
            label="Main"
            value={main?.currentValue
              ? `${main.currentValue} ${main.unit}`
              : '-'}
          />
          <TextGroup
            label="Load"
            value={load?.currentValue
              ? `${load?.currentValue} ${load.unit}`
              : '-'}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
