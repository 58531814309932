import { type ReactNode, useEffect } from 'react'
import { Col, Grid } from '@mantine/core'
import { getComplianceSummary } from '@venturi-io/api/src/config/alarmHistory'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import Paper from 'src/Layout/Paper'
import { useUser } from 'src/UserContext'
import { type ApiData, useApi } from 'src/utils/useApi'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import SummaryCol from './SummaryCol'
import { type ChildProps } from '.'

function ComplianceRow ({
  average,
  minimum,
  maximum,
  samplesCollected,
  alarms,
  alarmIncidents,
  unit
}: ApiData<typeof getComplianceSummary>): ReactNode {
  return (
    <Grid gutter="xl">
      <Col span={6} sm={2}>
        <SummaryCol
          title="Average during period"
          item={average}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={2}>
        <SummaryCol
          title="Minimum during period"
          item={minimum}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={2}>
        <SummaryCol
          title="Maximum during period"
          item={maximum}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={2}>
        <SummaryCol
          title="Samples collected"
          item={samplesCollected}
        />
      </Col>
      <Col span={6} sm={2}>
        <SummaryCol
          title="Configured alarms"
          item={alarms}
        />
      </Col>
      <Col span={6} sm={2}>
        <SummaryCol
          title="Alarm incidents"
          item={alarmIncidents}
        />
      </Col>
    </Grid>
  )
}

interface ComplianceNoDataProps {
  agentId: string
  sensorInstanceId: string | null
}

function ComplianceNoData ({ agentId, sensorInstanceId }: ComplianceNoDataProps) {
  if (!agentId) {
    return <div>Please choose an agent</div>
  }

  return sensorInstanceId === ''
    ? <div>Please choose a sensor</div>
    : ''
}

function Compliance ({ agentId, sensorInstanceId, dateRange, setComplianceSummary }: ChildProps) {
  const complianceSummary = useApi(getComplianceSummary)
  const { token } = useUser()

  useEffect(() => {
    if (!sensorInstanceId) {
      return complianceSummary.clearAll()
    }
    if (complianceSummary.loading) {
      complianceSummary.abort()
      complianceSummary.clearAll()
    }

    const req = {
      sensorInstanceId,
      startTime: dayjs(dateRange[0]).format(`${dateFormat}`),
      endTime: dayjs(dateRange[1]).format(`${dateFormat}`)
    }

    void complianceSummary.fetch(req, token)
  }, [sensorInstanceId, dateRange[0], dateRange[1]])

  useEffect(() => {
    complianceSummary.data.ifJust((data) => {
      if (setComplianceSummary) {
        setComplianceSummary(data)
      }
    })
  }, [complianceSummary.data])

  return (
    <Paper relative>
      <LoadingOverlay visible={complianceSummary.loading} />
      {complianceSummary.data.mapOrDefault(ComplianceRow,
        <ComplianceNoData agentId={agentId} sensorInstanceId={sensorInstanceId} />
      )}
    </Paper>
  )
}

export default Compliance
