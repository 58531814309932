import { useNavigate } from 'react-router-dom'
import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type AlarmWebhook } from '@venturi-io/api/src/analytics/alarmWebhook'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'

export default function Card ({
  id,
  name,
  url,
  alarmKey,
  clearKey
}: AlarmWebhook & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/my-admin/alarm-webhooks/edit/${id}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <SimpleGrid cols={1} spacing="lg">
          <TextGroup label="Name" value={name} />
          <TextGroup
            label="Url"
            value={url}
            maxLength={40}
          />
          <TextGroup label="Alarm Key" value={alarmKey} />
          <TextGroup label="Clear Key" value={clearKey} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
