import { useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Checkbox,
  Collapse,
  Paper,
  Stack,
  createStyles
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type Agent, getAgentsForAgentGroup } from '@venturi-io/api/src/config/agent'
import { type AgentGroup } from '@venturi-io/api/src/config/agentGroup'
import Loader from 'src/Layout/Loader'
import TextTooltip from 'src/Layout/TextTooltip'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import { useViewTypes } from 'src/Maps/GeoZoneMap/AgentAttendanceContext'
import { fixedGeoLocation } from '../..'
import AgentStack from '../AgentStack'

const useStyles = createStyles((theme, opened: boolean) => ({
  itemWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    gap: 10,
    '&:hover': {
      background: theme.colors.gray[0]
    },
    overflowX: 'auto'
  },
  isActiveGroup: {
    border: opened ? '1px solid #103464' : 'none'
  }
}))

interface Props {
  agentGroup: AgentGroup
  onClick?: (agentId: Agent['agentId']) => void
}

export default function Group ({
  agentGroup,
  onClick
}: Props) {
  const { agentGroupId, name } = agentGroup
  const { token } = useUser()
  const agents = usePaginatedApi(getAgentsForAgentGroup)
  const [opened, { toggle }] = useDisclosure(false)
  const { classes } = useStyles(opened)
  const { groupCheckReference, viewAgentsByTypes, setCheckedGroupIds, setCheckedAgentIds } = useViewTypes()

  const groupAgentIds = agents.data.mapOrDefault(({ items }) => (
    items.map(({ agentId }) => agentId)
  ), [])

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agents
      .fetch({
        agentGroupId,
        page: page ?? 1,
        size: size ?? 100
      }, token)
  }

  const handleClick = useCallback(() => {
    toggle()
  }, [opened])

  const handleToggleGroupVisibility = useCallback((isVisible_: boolean) => {
    setCheckedGroupIds(lastGroupIds => {
      // we also need to update the agentIds to be checked
      setCheckedAgentIds(lastAgentIds => isVisible_
        ? [
            ...lastAgentIds,
            ...groupAgentIds.map(agentId => agentId)
          ]
        : lastAgentIds.filter(id => !groupAgentIds.map(agentId => agentId).includes(id))
      )

      return isVisible_
        ? [
            ...lastGroupIds,
            agentGroupId
          ]
        : lastGroupIds.filter(id => id !== agentGroupId)
    })
  }, [groupAgentIds, setCheckedGroupIds])

  useEffect(() => {
    void loadAgents()
  }, [agentGroupId, agentGroup])

  const mappedAgents = agents.data.mapOrDefault(({ items }) => (
    items.map(({
      lastLocation,
      ...agent
    }) => ({
      lastLocation: {
        geoLocation: lastLocation?.geoLocation ?? fixedGeoLocation
      },
      ...agent
    }))
  ), [])

  const filteredCheckedAgents = mappedAgents.filter(data =>
    viewAgentsByTypes.some(types => types === data.assetType)
  )

  return (
    <Stack spacing={5}>
      <Paper
        key={agentGroupId}
        className={`${classes.itemWrapper} ${classes.isActiveGroup}`}
        shadow="xs"
        p="sm"
        onClick={handleClick}
      >
        <Checkbox
          color="primary"
          size="xs"
          checked={groupCheckReference[agentGroupId]}
          onChange={event => {
            handleToggleGroupVisibility(event.currentTarget.checked)
          }}
          onClick={event => {
            event.stopPropagation()
          }}
        />
        <FontAwesomeIcon
          icon={['far', 'layer-group']}
          color="gray"
          size="1x"
          fixedWidth
        />
        <TextTooltip
          size="xs"
          value={name}
          maxLength={30}
        />
      </Paper>

      <Collapse in={opened} pl={15} pb={15}>
        {agents.loading
          ? <Loader />
          : (
            <AgentStack
              asChild
              isAgentGroup={true}
              agents={filteredCheckedAgents}
              onClick={onClick}
            />
            )}
      </Collapse>
    </Stack>
  )
}
