import { useEffect, useMemo, useState } from 'react'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type BarCommonProps } from '@nivo/bar'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Bar from 'src/Charts/Bar'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'
import SegmentedTimePeriodSelector, {
  type ChosenBucket,
  type ItemKeys
} from 'src/Input/SegmentedTimePeriodSelector'
import { type DataPoint } from 'src/Charts/shared'

const useStyles = createStyles(() => ({
  chartContainer: {
    minWidth: 0
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

type SensorWithExtra = Sensor & {
  key: string
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  agentAlarmStatus: Agent['alarmStatus']
}

interface SensorData extends SensorWithExtra {
  data: DataPoint[]
}

interface MultiSensorHistoryType extends Record<string, string | number> {}

interface Props {
  title?: string
  agentId: Agent['agentId']
  sensorInstanceIds: Array<Sensor['sensorInstanceId']>
  unit?: string
  height: number
  margin?: BarCommonProps<{}>['margin']
}

export default function SensorHistoryStackedBarChart ({
  title,
  agentId,
  sensorInstanceIds,
  unit,
  height,
  margin
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const getSensorsHistory = useApi(getSensorData)
  const index = 'index'
  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '7 days',
    data: {
      name: '7d',
      timeBucket: '12 hours',
      noOfRecords: 14
    }
  })
  const axisBottomFormat = bucket.item.includes('day')
    ? 'DD/MM'
    : 'h:mm A'

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensorsWithExtra: SensorWithExtra[] = useMemo(() => {
    if (!agent) return []

    const {
      agentId,
      agentName,
      alarmStatus,
      sensors
    } = agent

    return sensors
      .filter(({ sensorInstanceId }) => sensorInstanceIds.includes(sensorInstanceId))
      .map(sensor => ({
        key: sensor.name,
        agentId,
        agentName,
        agentAlarmStatus: alarmStatus,
        ...sensor
      }))
  }, [agent, sensorInstanceIds])

  const sensorsData: SensorData[] = useMemo(() => (
    getSensorsHistory.data.mapOrDefault(({ result }) => (
      result
        .reduce((sensorsData_: SensorData[], { sensorInstanceId, data }) => {
          const sensor: SensorWithExtra | undefined =
              sensorsWithExtra.find(s => s.sensorInstanceId === sensorInstanceId)

          if (sensor) {
            sensorsData_ = [
              ...sensorsData_,
              {
                ...sensor,
                data: data.map(({ x, y }) => (
                  {
                    x,
                    y: typeof y !== 'undefined'
                      ? y
                      : 0
                  }
                ))
              }
            ]
          }

          return sensorsData_
        }, [])
        .filter(sensor => sensor.data.length > 0)
    ), [])
  ), [getSensorsHistory.data, sensorsWithExtra])

  const sensorsHistory: MultiSensorHistoryType[] = useMemo(() => {
    const series = sensorsData.reduce((series: Record<string, MultiSensorHistoryType>, sensor) => {
      sensor.data.forEach(({ x, y }) => {
        const key = x.toString()
        const val = typeof y !== 'undefined'
          ? y
          : 0

        if (series[key]) {
          series[key] = {
            ...series[key],
            [sensor.key]: val
          }
        } else {
          series[key] = {
            [sensor.key]: val
          }
        }
      })

      return series
    }, {})

    return Object.keys(series).map(index => ({ index, ...series[index] }))
  }, [sensorsData])
  useEffect(() => {
    const time = bucket.item.split(' ')
    void getSensorsHistory.fetch({
      sensorInstanceIds,
      startTime: dayjs()
        .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
        .format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: bucket.data.noOfRecords
    }, token)
  }, [sensorInstanceIds, bucket])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Box>
      <Stack spacing={8}>
        <Group position="apart">
          <Text size={14} weight={600}>{title}</Text>
          <SegmentedTimePeriodSelector
            chosenBucket={bucket}
            updateBucket={item => setBucket(item)}
          />
        </Group>
        <Box className={classes.chartContainer}>
          <Bar
            data={sensorsHistory}
            indexBy={index}
            keys={sensorsHistory[0]
              ? Object.keys(sensorsHistory[0]).filter(key => key !== index)
              : []}
            height={height}
            colors={{
              scheme: 'tableau10'
            }}
            margin={margin}
            enableLabel={false}
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: (value) => dayjs(value).format(axisBottomFormat),
              tickSize: 0,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            enableGridX={false}
            legends={[]}
            motionConfig="gentle"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10,
                    fontWeight: 400,
                    fill: '#909296'
                  }
                }
              },
              grid: {
                line: {
                  stroke: '#E9ECEF'
                }
              }
            }}
            isInteractive={sensorsHistory.length > 1}
            tooltip={({
              id,
              indexValue,
              formattedValue,
              color
            }) => (
              <Paper
                className={classes.tooltip}
                px={12}
                py={8}
                radius="sm"
                shadow="sm"
              >
                <Text
                  size={12}
                  weight={500}
                  color={color}
                >
                  {id}
                </Text>
                <Text size={12} weight={500}>
                  {`${dayjs(indexValue).format('MMM D h:mm A')}`}
                </Text>
                <Text size={16} weight={600}>
                  {`${formattedValue} ${unit ?? ''}`}
                </Text>
              </Paper>
            )}
          />
        </Box>
      </Stack>
    </Box>
  )
}
