import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from '@mantine/form'
import {
  type ColProps,
  Box,
  Button,
  Col,
  Grid,
  Group,
  Tooltip
} from '@mantine/core'
import {
  type UserGroup,
  createUserGroup,
  deleteUserGroup,
  updateUserGroup,
  assignUsersToGroup
} from '@venturi-io/api/src/userManager/userGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import MultiSelectUser from 'src/Input/MultiSelect/MultiSelectUser'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { useApi } from 'src/utils/useApi'

interface Props {
  mode: FormMode
  initialValues?: UserGroup
  hasUser?: boolean
  onCreate?: () => Promise<void>
  onClose?: () => void
}

function Form ({
  mode,
  initialValues,
  hasUser = false,
  onCreate,
  onClose
}: Props) {
  const navigate = useNavigate()
  const { token } = useUser()
  const [showDelete, setShowDelete] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const addUsersToGroup = useApi(assignUsersToGroup)
  const newUserGroup = useApi(createUserGroup)
  const editUserGroup = useApi(updateUserGroup)
  const removeUserGroup = useApi(deleteUserGroup)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<Omit<UserGroup, 'id'> & { userIds?: string[] }>({
    initialValues: {
      name: '',
      description: '',
      userIds: []
    },
    validate: {
      name: value => value.length < 1 ? 'Please specify name' : null,
      description: value => value.length < 1 ? 'Please specify description' : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/settings/user-groups')

  const handleCreate = useCallback(({ name, description }: FormValues) => {
    void newUserGroup
      .fetch({ name, description }, token, 'Successfully added a user group')
  }, [form.values])

  const handleUpdate = useCallback(({ name, description }: FormValues) => {
    const userGroupId = initialValues?.id

    if (!userGroupId) {
      throw new Error('Unable to perform the action, no user group provided')
    }

    void editUserGroup
      .fetch({
        userGroupId,
        name,
        description
      }, token, 'Successfully updated a user group')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const userGroupId = initialValues?.id

    if (!userGroupId) {
      throw new Error('Unable to perform the action, no user group provided')
    }

    void removeUserGroup
      .fetch({ userGroupId }, token, 'Successfully deleted a user group')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) {
      handleCreate(values)
    }

    if (inEditMode) {
      handleUpdate(values)
    }
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) {
      onClose()
    }
  }, [])

  useEffect(() => {
    newUserGroup.data.ifJust(({ id }) => {
      if (form.values.userIds?.length) {
        void addUsersToGroup.fetch({
          userGroupId: id,
          userIds: form.values.userIds.map(id => Number(id))
        }, token)
      }

      form.reset()

      if (onClose) {
        onClose()
      }

      if (onCreate) {
        void onCreate()
      }
    })
  }, [newUserGroup.data])

  useEffect(() => {
    if (initialValues) {
      form.setValues(initialValues)
    }
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput
              withAsterisk
              label="Name"
              {...form.getInputProps('name')}
            />
          </Col>
          <Col {...inputColProps}>
            <TextInput
              withAsterisk
              label="Description"
              {...form.getInputProps('description')}
            />
          </Col>
          {inCreateMode && (
            <Col {...inputColProps}>
              <MultiSelectUser
                label="Users"
                value={form.values.userIds}
                onChange={ids => (
                  form.setFieldValue('userIds', ids)
                )}
                searchable
              />
            </Col>
          )}
          {inEditMode && (
            <Col {...inputColProps}>
              <Group position="left" mt="sm">
                <Tooltip
                  opened={isHover && hasUser}
                  position="right"
                  color="red"
                  label="You cannot delete a group that has users"
                  withArrow
                >
                  <Box
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <Button
                      mt="sm"
                      color="red"
                      leftIcon={(
                        <FontAwesomeIcon
                          icon={['fas', 'trash']}
                          color="white"
                        />
                      )}
                      onClick={() => setShowDelete(true)}
                      disabled={hasUser}
                    >
                      Delete
                    </Button>
                  </Box>
                </Tooltip>
              </Group>
            </Col>
          )}
          <Col {...inputColProps}>
            <Group position="right" mt="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode
                  ? newUserGroup.loading
                  : editUserGroup.loading}
              >
                Save
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeUserGroup.loading}
          question="Are you sure you want to delete this user group? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}

export default Form
