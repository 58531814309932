import { type MouseEvent, useCallback, useState } from 'react'
import {
  Box,
  Button,
  Group,
  Modal,
  type ModalProps,
  Stack,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { useNotifications } from 'src/utils/notifications'
import { type ModbusRegister } from '@venturi-io/api/src/config/modbus'
import Papa, { type ParseResult } from 'papaparse'
import { type DataTypes } from './shared'

function parseData (item: Record<string, unknown>, idx: number, type: DataTypes) {
  let value: unknown
  try {
    if (type === 'number') {
      value = parseInt(item[idx] as string)
    }
    if (type === 'string') {
      value = item[idx] as number
    }
  } catch (err) {
    if (type === 'number') {
      value = NaN
    }
    if (type === 'string') {
      value = ''
    }
  }
  return value
}

interface Props extends ModalProps {
  loading?: boolean
  onCancel: () => Promise<void> | void
  onAccept: (registers: ModbusRegister[]) => void
}

export default function CSVModal ({
  loading,
  onCancel,
  onAccept,
  ...rest
}: Props) {
  const { showError } = useNotifications()
  const [parsed, setParsed] = useState<ModbusRegister[] | undefined>(undefined)
  const [file, setFile] = useState<File | undefined>(undefined)

  const handleAccept = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (parsed) {
      onAccept([...parsed])
      setFile(undefined)
      setParsed(undefined)
    }
  }, [parsed])

  const handleRemove = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setParsed(undefined)
    setFile(undefined)
    void onCancel()
  }, [])

  const handleDrop = useCallback(([file]: File[]) => {
    setFile(file)
    Papa.parse(file, {
      complete: (results: ParseResult<Record<string, unknown>>) => {
        const result: ModbusRegister[] = results.data.map((item) => {
          const address = parseData(item, 0, 'number') as number
          const registerCount = parseData(item, 1, 'number') as number
          const dataType = parseData(item, 2, 'string') as string
          const scalingFactor = parseData(item, 3, 'number') as number
          const name = parseData(item, 4, 'string') as string
          const description = parseData(item, 5, 'string') as string

          return {
            address,
            registerCount,
            dataType,
            scalingFactor,
            name,
            description,
            sensorId: NaN,
            sensorName: undefined,
            function: 0

          }
        })
        setParsed(result)
      }
    })
  }, [])

  return (
    <Modal
      size="md"
      withCloseButton={false}
      {...rest}
    >
      <Stack align="center" spacing="xs">
        <Text size="xl" align="center" weight={700}>
          CSV File loader
        </Text>
        <Dropzone
          multiple={false}
          onDrop={handleDrop}
          onReject={() => showError(new Error('File rejected'))}
          maxSize={3 * 1024 ** 2}
          accept={[MIME_TYPES.csv]}
          mb="md"
        >
          {file
            ? (
              <Group>
                <FontAwesomeIcon icon={['fas', 'file-csv']} color="dark" size="2x" />
                <Stack spacing={0}>
                  <Text size="lg">
                    {file.name}
                  </Text>
                  <Text size="sm" color="dimmed" >
                    {file.size}
                  </Text>
                </Stack>
              </Group>
              )
            : (
              <Box>
                <Text size="xl" inline>
                  Drag or click to select a csv file
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  File should not exceed 5mb
                </Text>
              </Box>
              )}
        </Dropzone>
        <Group position="center">
          <Button color="green" onClick={handleAccept} disabled={loading}>
            Load
          </Button>
          <Button color="gray" onClick={handleRemove} disabled={loading}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
