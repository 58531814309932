import {
  type ColProps,
  createStyles,
  Button,
  Col,
  Grid,
  Group,
  Text
} from '@mantine/core'
import { useEffect, useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import IconSelector from 'src/Input/IconSelector'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createSensor,
  sensorTypes,
  valueFormats,
  type Sensor,
  type SensorType,
  updateSensor,
  type ValueFormat,
  deleteSensor,
  getAllSensorTypes
} from '@venturi-io/api/src/config/sensor'
import { useForm } from '@mantine/form'
import { type FormMode } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import ValueMap from 'src/stories/ValueMap/ValueMap'
import { defaultValueFormat } from '.'

const useStyles = createStyles(() => ({
  iconCol: {
    position: 'relative'
  }
}))

interface Props {
  mode: FormMode
  initialValues?: Sensor
  onCreate?: () => Promise<void>
  onClose?: () => void
}

interface FormProps extends Omit<Sensor, 'sensorTypeName'> {
  sensorTypeName: string
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const navigate = useNavigate()
  const [valueMapError, setValueMapError] = useState<string | null>(null)
  const newSensor = useApi(createSensor)
  const editSensor = useApi(updateSensor)
  const removeSensor = useApi(deleteSensor)
  const [showDelete, setShowDelete] = useState(false)
  const allSensorTypes = useApi(getAllSensorTypes)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const hasValue = (inputName: string, value: string) => value.length < 1
    ? `Please specify ${inputName}`
    : null

  const form = useForm<FormProps>({
    initialValues: {
      id: 1,
      name: '',
      type: 'SENSOR',
      sensorTypeName: '',
      valueFormat: defaultValueFormat,
      unit: '',
      valueMap: {}
    },
    validate: {
      name: (value: string) => hasValue('Name', value),
      type: (value: SensorType) => hasValue('Type', value),
      sensorTypeName: (value: string) => hasValue('Sensor Type', value),
      valueFormat: (value: ValueFormat | undefined) => hasValue('Value Format', value ?? '')
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = useCallback(() => navigate('/my-admin/sensors'), [])

  const handleCreate = useCallback(({ valueFormat, ...values }: FormValues) => {
    void newSensor
      .fetch({
        ...values,
        valueFormat: valueFormat ?? defaultValueFormat
      }, token, 'Successfully created new sensor')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }, [form.values])

  const handleUpdate = useCallback((values: FormValues) => {
    const sensorId = initialValues?.id

    if (!sensorId) throw new Error('Unable to perform the action, no sensor provided')

    void editSensor
      .fetch({
        ...values,
        sensorId
      }, token, 'Successfully updated sensor')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const sensorId = initialValues?.id

    if (!sensorId) throw new Error('Unable to perform the action, no sensor provided')

    void removeSensor
      .fetch({ sensorId }, token, 'Succesfully deleted sensor')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  const loadAllSensorType = async (): Promise<void> => {
    void allSensorTypes.fetch({}, token)
  }

  const selectSensorType = allSensorTypes
    .data
    .mapOrDefault(sensors => (
      sensors.map(({ name }) => ({
        label: name,
        value: name
      }))
    ), [])

  useEffect(() => {
    if (initialValues) {
      form.setValues(initialValues)
    }

    void loadAllSensorType()
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col className={classes.iconCol} {...inputColProps}>
            <IconSelector
              label="Icon"
              width="95%"
              {...form.getInputProps('iconStyle')}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Type"
              data={[...sensorTypes]}
              {...form.getInputProps('type')}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Sensor Type"
              data={[...selectSensorType]}
              {...form.getInputProps('sensorTypeName')}
            />
          </Col>
          <Col {...inputColProps}>
            <Select
              withAsterisk
              label="Value Format"
              data={[...valueFormats]}
              {...form.getInputProps('valueFormat')}
            />
          </Col>
          <Col {...inputColProps}>
            <TextInput
              label="Unit"
              value={form.values.unit}
              onChange={event => form.setFieldValue('unit', event.currentTarget.value)}
            />
          </Col>
          <Col span={12}>
            <ValueMap
              value={initialValues?.valueMap}
              onChange={value => {
                form.setFieldValue('valueMap', value)
              }}
              setErrorMessage={setValueMapError}
            />
            <Text
              color="red"
              size="sm"
              fw={500}
              ta="center"
            >
              {valueMapError}
            </Text>
          </Col>
          <Col span={inCreateMode ? 12 : 6}>
            <Group
              mt="md"
              position={inCreateMode
                ? 'right'
                : 'apart'}
            >
              {inEditMode && (
                <Button
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              )}
              <Group position="right">
                <Button
                  color="gray"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                  onClick={inCreateMode ? handleClose : redirectToListPage}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="green"
                  leftIcon={(
                    <FontAwesomeIcon
                      icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                      color="white"
                    />
                  )}
                  disabled={(inCreateMode ? newSensor.loading : editSensor.loading) || valueMapError !== null}
                >
                  {inCreateMode ? 'Create' : 'Save'}
                </Button>
              </Group>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeSensor.loading}
          question="Are you sure you want to delete this sensor? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
