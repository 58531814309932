/* eslint-disable @typescript-eslint/naming-convention */
import { Image, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular, uiDateFormatUniversal } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type SpeedAlert } from '@venturi-io/api/src/analytics/speedAlerts'
import { type ParsedSpeedEvent } from '..'

interface Props extends Partial<ParsedSpeedEvent> {
  agentName: string
  mapUri: string | null
  data: ParsedSpeedAlert[]
}

const formatToReadableDate = (date?: string) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'

interface Value {
  value: string | null
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Event #" />
    <THCell value="Event Date/Time" />
    <THCell value="Location" />
    <THCell value="Geozone" />
    <THCell value="Speed Limit" />
    <THCell value="Speed Violation" />
  </View>
)

interface LabelValueProps {
  label: string
  value?: string | null
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? 'N/A'}
    </Text>
  </View>
)

interface DetailsProps {
  driver?: string | null
  group?: string | null
  date?: string | null
  count?: string | null
}
const Details = ({
  driver,
  date,
  count
}: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Driver"
      value={driver}
    />
    <LabelValue
      label="Trip date/time"
      value={date}
    />
    <LabelValue
      label="Total Violations"
      value={count}
    />
  </View>
)

export interface ParsedSpeedAlert extends SpeedAlert {
  location: string
}

interface TableRowProps extends ParsedSpeedAlert {
  index: number
}

const TableRow = ({
  index,
  speed,
  speedLimit,
  speedDateTime,
  geoZoneName,
  location
}: TableRowProps) => (
  <View style={reportStyles.group}>
    <TDCell value={index.toString()} />
    <TDCell value={formatToReadableDate(speedDateTime)} />
    <TDCell value={location} />
    <TDCell value={geoZoneName ?? 'N/A'} />
    <TDCell value={`${speedLimit} km/h`} />
    <TDCell value={`${speed} km/h`} />
  </View>
)

interface TableBodyProps {
  data: ParsedSpeedAlert[]
}
const TableBody = ({ data }: TableBodyProps) => data.map((item, idx) => (
  <TableRow
    key={item.tripId}
    index={idx + 1}
    {...item}
  />
))

const Space = () => <View style={reportStyles.space} />

function AgentSpeedAlertHistory ({
  data,
  mapUri,
  agentName,
  driver,
  date,
  count
}: Props) {
  const title = `${agentName ?? 'Unknown'} - Speed Event Report`
  return (
    <ReportTemplate
      title={title}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details
        driver={driver}
        date={dayjs(date).format(uiDateFormatUniversal)}
        count={count}
      />
      <Space />
      <TableHeader />
      <TableBody data={data} />
      {mapUri && (
        <>
          <Space />
          <View style={reportStyles.imageContainer}>
            <Image src={mapUri} style={reportStyles.image} />
          </View>
        </>
      )}
    </ReportTemplate>
  )
}

export default AgentSpeedAlertHistory
