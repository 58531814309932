import { createStyles, Box } from '@mantine/core'
import DiagnosticMetric from '../Components/DiagnosticMetric'
import { type DiagnosticMetricItem } from '../Components/DiagnosticMetric'

const useStyles = createStyles(() => ({
  container: {
    display: 'inline-flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: 8
  }
}))

interface Props {
  items: DiagnosticMetricItem[]
}

export default function DiagnosticMetrics ({ items }: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      {/* Limit display upto 4 items only */}
      {items.slice(0, 4).map((props, index) => (
        <DiagnosticMetric key={index} {...props} />
      ))}
    </Box>
  )
}
