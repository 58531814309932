import { createStyles } from '@mantine/core'
import { thStyle } from '../Rows/styles'

const useStyles = createStyles(() => ({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff'
  }
}))

interface Props {
  isMobile?: boolean
}

export default function Head ({ isMobile }: Props) {
  const { classes } = useStyles()

  return (
    <thead className={classes.head}>
      <tr>
        {!isMobile && <th style={thStyle}>Date/Time</th>}
        <th style={thStyle}>Agent Name</th>
        <th style={thStyle}>Asset Type</th>
        <th style={thStyle}>Vol. (L)</th>
        <th style={thStyle}>Flow</th>
        <th
          style={{
            ...thStyle,
            textAlign: 'right'
          }}
        >
          LPM (Litres/Minute)
        </th>
      </tr>
    </thead>
  )
}
