import { useEffect } from 'react'
import { type CameraEvent, getEventsForCamera } from '@venturi-io/api/src/config/cameraEvents'
import { useMockPaginatedApi } from 'src/utils/useApi'
import ActionList from 'src/Layout/ActionList'
import { type PaginatedResponse } from '@venturi-io/api'
import { useUser } from 'src/UserContext'
import { useParams } from 'react-router-dom'
import { Card, Header, Row } from '../List'
import mockIssues_ from '../mockData.json'

interface MockCameraEvents extends PaginatedResponse {
  items: CameraEvent[]
}

const _mockIssues = mockIssues_ as MockCameraEvents

interface RouteParams extends Record<string, string | undefined> {
  cameraId: string
}

export default function Event () {
  // TODO: Replace with usePaginatedApi once the API is ready.
  const events = useMockPaginatedApi(getEventsForCamera, _mockIssues)
  const { cameraId: id } = useParams<RouteParams>()
  const { token } = useUser()

  const loadEvents = async (page?: number, size?: number): Promise<void> => {
    void events.fetch({
      cameraId: Number(id),
      page: page ?? events.page,
      size: size ?? events.size
    }, token)
  }

  // TODO: Add dependecy page here once paginated API is ready.
  useEffect(() => {
    void loadEvents()
  }, [events.page])

  return (
    <ActionList
      isLoading={events.loading}
      // TODO: remove this filtering once API is available
      data={events.data.mapOrDefault(({ items }) => {
        return items.filter(({ cameraId }) => cameraId === Number(id))
      }, [])}
      getId={({ eventId }) => eventId}
      header={() => <Header />}
      row={Row}
      card={Card}
      totalPages={events.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
      page={events.page}
      onPageChange={events.setPage}
    />
  )
}
