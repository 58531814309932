import { useNavigate } from 'react-router-dom'
import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type AlarmActionWithoutDependencies } from '@venturi-io/api/src/analytics/alarmAction'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  id,
  name,
  action,
  onTrigger,
  onClear
}: AlarmActionWithoutDependencies & ListRow) {
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={id.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/settings/alarm-actions/edit/${id}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Name"
            value={name}
            maxLength={textMaxLength}
          />
          <TextGroup label="Action" value={action} />
          <TextGroup
            label="On Trigger"
            value={onTrigger
              ? 'Yes'
              : 'No'}
          />
          <TextGroup
            label="onClear"
            value={onClear
              ? 'Yes'
              : 'No'}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
