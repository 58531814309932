import { type AssetType } from '@venturi-io/api/src/config/agent'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import NoData from 'src/Layout/TableRowNoData'
import { assetTypeIcon, flowTypeIcon } from '../..'
import { type Props } from '../../../Transactions'
import { assetTypeStyle, bgGrayStyle, noBorder, textGeneralStyles } from './styles'

interface RowProps extends Props {
  isMobile?: boolean
}

export default function Rows ({ transactions, isMobile = false }: RowProps) {
  return transactions.length > 0
    ? (
      <>
        {transactions.map(({
          imported,
          volume,
          type,
          transactionId,
          agent
        }) => {
          const assetType = agent?.assetType ?? 'OTHERS'
          return (
            <tr key={transactionId}>
              {!isMobile && (
                <td style={textGeneralStyles}>
                  {dayjs(imported).format(uiDateFormat)}
                </td>
              )}
              <td style={textGeneralStyles}>
                {agent?.agentName ?? '-'}
              </td>
              <td style={assetTypeStyle}>
                {assetType && assetTypeIcon[assetType as AssetType]}
                {' '}
                {agent?.assetType ?? '-'}
              </td>
              <td
                style={{
                  ...textGeneralStyles,
                  fontFeatureSettings: 'tnum'
                }}
              >
                {volume}
              </td>
              <td style={bgGrayStyle}>
                {type}
                {' '}
                {flowTypeIcon[type]}
              </td>
              {/* TODO: Update LPM value once data are available in API */}
              <td
                style={{
                  ...noBorder,
                  textAlign: 'right'
                }}
              >
                LPM
              </td>
            </tr>
          )
        })}
      </>
      )
    : <NoData message="No transaction available" />
}
