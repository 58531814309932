import { type CSSProperties } from 'react'
import {
  type Agent,
  type AlarmStatus,
  type AgentStatus,
  type ConnectionStatus
} from '@venturi-io/api/src/config/agent'

export const checkIfAlarm = (status: AlarmStatus) => status === 'ALARM'

type AgentStatusColor = 'green' | 'gray' | 'red' | 'orange' | 'yellow'

export const colors: Record<AgentStatusColor, CSSProperties['color']> = {
  red: 'red',
  green: 'green',
  yellow: '#FFE24D',
  orange: '#FF9F18',
  gray: 'gray'
}

export const getAgentStatusColor = (
  alarmStatus: AlarmStatus,
  agentStatus?: AgentStatus,
  connectionStatus?: ConnectionStatus,
  lastSeenTime?: Agent['lastSeenTime']
): CSSProperties['color'] => {
  const gracePeriod = Date.now() - 600000 // grace period of 10 minutes
  const lastSeenTimestamp = lastSeenTime && Date.parse(lastSeenTime.replace(' ', 'T'))

  if (alarmStatus === 'ALARM') {
    return colors.red
  }

  if (agentStatus === 'IMMOBILISED') {
    return colors.orange
  }

  if (agentStatus === 'IDLE') {
    return colors.yellow
  }

  if (agentStatus === 'OFF' || connectionStatus === 'OFFLINE') {
    return colors.gray
  }

  if (alarmStatus === 'OK' ||
    agentStatus === 'MOVING' ||
    connectionStatus === 'ONLINE' ||
    (lastSeenTimestamp && (Number(lastSeenTimestamp) > gracePeriod))) {
    return colors.green
  }

  return colors.gray
}
