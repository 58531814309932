import {
  type ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { type Role, getRoles } from '@venturi-io/api/src/userManager/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  excludeRoleIds?: number[]
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const roleToSelect = ({ name }: Role): SelectItem => ({
  value: name,
  label: name
})

export default function MultiSelectRole ({
  excludeRoleIds,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const roles = useApi(getRoles)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectRoles = useMemo(() => {
    const allRoles = roles.data.mapOrDefault(agent => agent, [])
    const filteredRoles = allRoles.filter(
      ({ roleId }) => !excludeRoleIds?.some(id => id === roleId)
    )
    return filteredRoles.map(roleToSelect)
  }, [roles.data])

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void roles.fetch({ orgId }, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose role(s)'}
      value={value}
      data={selectRoles}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || roles.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
