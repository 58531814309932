import { getUserGroups } from '@venturi-io/api/src/userManager/userGroup'
import { usePaginatedApi } from 'src/utils/useApi'
import { useEffect, useState } from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { Header, Row, Card } from './List'
import CreateUserGroup from './Create'

export default function UserGroups () {
  const { token } = useUser()
  const [userGroupName, setUserGroupName] = useState<string | undefined>()
  const [debouncedName] = useDebouncedValue(userGroupName, 700)
  const [showCreate, setShowCreate] = useState(false)
  const userGroups = usePaginatedApi(getUserGroups)

  const loadUserGroups = async (page?: number, size?: number): Promise<void> => {
    void userGroups
      .fetch({
        page: page ?? userGroups.page,
        size: size ?? userGroups.size,
        name: debouncedName
      }, token)
  }

  useEffect(() => {
    void loadUserGroups(1)
  }, [debouncedName])

  useEffect(() => {
    void loadUserGroups()
  }, [userGroups.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create User Group"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
          onClick={() => setShowCreate(true)}
        >
          Add new User Group
        </Button>
      </Group>
      <Paper mt="md" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} md={9}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search user group here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setUserGroupName(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={userGroups.loading}
        data={userGroups.data.mapOrDefault(({ items }) => items, [])}
        getId={({ id }) => id}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={userGroups.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={userGroups.page}
        onPageChange={userGroups.setPage}
      />
      <CreateUserGroup
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadUserGroups}
      />
    </>
  )
}
