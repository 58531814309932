import { useEffect, type ReactNode } from 'react'
import { useParams } from 'react-router'
import { ScrollArea, Table, Title } from '@mantine/core'
import dayjs from 'dayjs'
import { useUser } from 'src/UserContext'
import { usePaginatedApi, type ApiData } from 'src/utils/useApi'
import { uiDateFormat } from 'src/utils/dates'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import Paper from 'src/Layout/Paper'
import Pagination from 'src/Layout/Pagination'
import { getIncidentBreaches } from '@venturi-io/api/src/config/alarmHistory'
import NoData from 'src/Layout/TableRowNoData'

function BreachesRow ({ items }: ApiData<typeof getIncidentBreaches>): ReactNode {
  return items.length > 0
    ? items.map(({ breachId, value, detected }) => (
      <tr key={breachId}>
        <td>{dayjs(detected).format(uiDateFormat)}</td>
        <td>{value}</td>
      </tr>
    ))
    : <NoData />
}

interface RouteParams extends Record<string, string | undefined> {
  incidentId: string
}

function Breaches () {
  const incidentBreaches = usePaginatedApi(getIncidentBreaches)
  const { incidentId } = useParams<RouteParams>()
  const { token } = useUser()

  if (!incidentId) {
    return null
  }

  const loadIncidentBreaches = async (page?: number, size?: number): Promise<void> => {
    void incidentBreaches
      .fetch({
        incidentId,
        page: page ?? incidentBreaches.page,
        size: size ?? incidentBreaches.size
      }, token)
  }

  useEffect(() => {
    void loadIncidentBreaches()
  }, [incidentBreaches.page])

  return (
    <Paper relative>
      <LoadingOverlay visible={incidentBreaches.loading} />
      <Title order={3}>Breaches</Title>
      <ScrollArea type="hover" scrollbarSize={5}>
        <Table striped highlightOnHover>
          <thead>
            <tr>
              <th>Detected</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {incidentBreaches.data.mapOrDefault(BreachesRow, <NoData message="" />)}
          </tbody>
        </Table>
      </ScrollArea>
      <Pagination
        mt="sm"
        value={incidentBreaches.page}
        total={incidentBreaches.data.mapOrDefault(v => v.totalPages, 0)}
        onChange={incidentBreaches.setPage}
      />
    </Paper>
  )
}

export default Breaches
