import { createStyles } from '@mantine/core'
import { type LinkProps, Link as RRDLink } from 'react-router-dom'

const useStyles = createStyles(theme => ({
  link: {
    color: theme.colors.primary[6]
  }
}))

export default function Link (props: LinkProps) {
  const { classes } = useStyles()

  return <RRDLink className={classes.link} {...props} />
}
