import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Tooltip
} from '@mantine/core'
import { getAgentConfigs, updateAgentConfig } from '@venturi-io/api/src/config/agent'
import { createModbusSlave } from '@venturi-io/api/src/config/modbus'
import { type ReactNode, useCallback, useEffect, useState } from 'react'
import { useNotifications } from 'src/utils/notifications'
import { useApi } from 'src/utils/useApi'
import { type Json } from '@venturi-io/api'
import { useUser } from 'src/UserContext'
import { useDebouncedValue } from '@mantine/hooks'

import Paper from 'src/Layout/Paper'
import { useNavigate, useLocation } from 'react-router'
import SelectAgent from 'src/Input/Select/SelectAgent'
import Nothing from 'src/Nothing'
import JsonEditor from './JsonEditor'
import CreateModbus from './CreateModbus'

const useStyles = createStyles(() => ({
  scrollContainer: {
    height: 'calc(100vh - 250px)'
  }
}))

interface ControlTooptipProps {
  label: string
  children: ReactNode
}

const ControlTooptip = ({ label, children }: ControlTooptipProps) => (
  <Tooltip
    label={label}
    position="top"
    transitionProps={{
      transition: 'pop',
      duration: 200
    }}
    withArrow
  >
    {children}
  </Tooltip>
)

export default function FieldGatewayConfig () {
  const { classes } = useStyles()
  const { search } = useLocation()
  const navigate = useNavigate()
  const slave = useApi(createModbusSlave)
  const config = useApi(getAgentConfigs)

  const { token } = useUser()
  const [isRaw, setIsRaw] = useState(false)
  const [agentId, setAgentId] = useState<string | null>(null)
  const { showError, showSuccess } = useNotifications()
  const [updating, setUpdating] = useState(false)
  const [json, setJson] = useState<Json | null>(null)
  const [debouncedJson] = useDebouncedValue(json, 200)
  const [showCreate, setShowCreate] = useState(false)

  const handleChangeJson = (data: Json) => {
    setJson(data)
  }

  const handleRefresh = () => {
    if (agentId) { void loadConfig() }
  }

  const handleSwitch = useCallback(() => {
    setIsRaw(!isRaw)
    if (agentId) { void loadConfig() }
  }, [isRaw, agentId])

  const handleSave = useCallback(() => {
    if (agentId === null) return
    setUpdating(true)
    void updateAgentConfig({ agentId: parseInt(agentId), configuration: debouncedJson }, token)
      .caseOf({
        Left: err => showError(err),
        Right: () => {
          showSuccess('Successfully saved agent config')
          return loadConfig()
        }
      }).finally(() => setUpdating(false))
  }, [debouncedJson, agentId, token])

  const loadConfig = useCallback(() => {
    if (agentId === null) return
    void config.fetch({ agentId: parseInt(agentId) }, token)
  }, [agentId])

  useEffect(() => {
    if (agentId) {
      void loadConfig()
    }
  }, [agentId])

  useEffect(() => {
    config.data.ifJust(values => {
      setJson(values)
    })
  }, [config.data, isRaw])

  useEffect(() => {
    const params = new URLSearchParams()
    if (agentId) {
      params.append('agentId', agentId)
    }
    if (isRaw) {
      params.append('raw', 'true')
    }
    navigate(`?${params.toString()}`, { replace: true })
  }, [isRaw, agentId])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const id = params.get('agentId')
      const raw = params.get('raw')
      if (id) {
        setAgentId(id)
      }
      if (raw) {
        setIsRaw(true)
      }
    }
  }, [search])

  return (
    <>
      <Paper style={{ marginTop: '0' }}>
        <Group>
          <SelectAgent
            sx={{
              flexGrow: 1
            }}
            defaultValue={agentId}
            setExternalAgentId={setAgentId}
            searchable
          />
          <Group spacing={8} align="flex-end">
            <ControlTooptip label="Create Slave">
              <ActionIcon disabled={!agentId} onClick={() => setShowCreate(true)}>
                <FontAwesomeIcon size="1x" icon={['fas', 'puzzle-piece']} color="dark" />
              </ActionIcon>
            </ControlTooptip>
            <ControlTooptip label={isRaw ? 'Show Editor' : 'Show Raw'}>
              <ActionIcon onClick={handleSwitch}>
                <FontAwesomeIcon size="1x" icon={['fas', isRaw ? 'table-list' : 'code']} color="dark" />
              </ActionIcon>
            </ControlTooptip>
            <ControlTooptip label="Save">
              <ActionIcon disabled={slave.loading || config.loading} loading={updating} onClick={handleSave}>
                <FontAwesomeIcon size="1x" icon={['fas', 'floppy-disk']} color="dark" />
              </ActionIcon>
            </ControlTooptip>
          </Group>
        </Group>
      </Paper>
      <Box className={classes.scrollContainer}>
        {config.data.caseOf({
          Nothing: () => (
            <Nothing
              isLoading={config.loading}
              nothing={config.data.isNothing()}
              placeholder="Please select an agent"
            />
          ),
          Just: () => config.loading
            ? null
            : <JsonEditor
                json={config.data.mapOrDefault(data => data, {})}
                isRaw={isRaw}
                onChangeJson={handleChangeJson}
              />
        })}
      </Box>
      <CreateModbus
        show={showCreate}
        onSuccess={handleRefresh}
        close={() => setShowCreate(false)}
        agentId={agentId}
      />
    </>
  )
}
