import { useEffect, useState } from 'react'
import { configure } from '@venturi-io/api/src/configure'
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { getHost } from 'src/utils/host'
import { useUser } from 'src/UserContext'
import { reportStyles } from './styles'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10
  },
  logo: {
    width: 160,
    height: 50,
    objectFit: 'contain'
  }
})

interface TitleProps {
  title: string
}
const Title = ({ title }: TitleProps) => (
  <View>
    <Text style={reportStyles.title}>{title}</Text>
  </View>
)

export default function Header ({ title }: TitleProps) {
  const { orgId } = useUser()
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(() => {
    void configure(getHost())
      .then(({ user }) => {
        setImageUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topLogo&time=${Date.now()}`)
      })
  }, [])

  if (!imageUrl) {
    return null
  }

  return (
    <View style={styles.container} fixed>
      <Title title={title} />
      <Image src={imageUrl} style={styles.logo} />
    </View>
  )
}
