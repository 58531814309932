import { memo, useEffect, useMemo } from 'react'
import { Container, ScrollArea } from '@mantine/core'
import { getAlarmIncidents, type AlarmIncident } from '@venturi-io/api/src/analytics/alarmIncident'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import Loader from 'src/Layout/Loader'
import { useSharedStyles } from '../shared'
import Table from './Table'

export interface Props {
  id: string
  name: string
  data: {
    sensorInstanceIds: Array<AlarmIncident['sensorInstanceId']>
  }
}

const AlarmList = ({
  name,
  data: {
    sensorInstanceIds
  }
}: Props) => {
  const { orgId, token } = useUser()
  const alarmsForOrg = usePaginatedApi(getAlarmIncidents)

  const { classes } = useSharedStyles()

  const alarms = useMemo(() => (
    alarmsForOrg.data.mapOrDefault(({ items }) => items, [])
  ), [alarmsForOrg.data])

  const loadAlarms = (page?: number, size?: number) => {
    const req = {
      orgId,
      sensorInstanceIds,
      page: page ?? alarmsForOrg.page,
      size: size ?? alarmsForOrg.size
    }

    void alarmsForOrg.fetch(req, token)
  }

  useEffect(() => {
    loadAlarms()
  }, [alarmsForOrg.page, sensorInstanceIds])

  return (
    <Container className={classes.itemContainer} fluid>
      {alarmsForOrg.loading && <Loader variant="bars" />}
      {!alarmsForOrg.loading && (
        <ScrollArea
          style={{
            height: '100%',
            width: '100%',
            background: 'white'
          }}
          type="hover"
          scrollbarSize={5}
        >
          <Table
            title={name}
            incidents={alarms}
            onRefresh={loadAlarms}
          />
          <Pagination
            mt="sm"
            value={alarmsForOrg.page}
            total={alarmsForOrg.data.mapOrDefault(v => v.totalPages, 0)}
            onChange={alarmsForOrg.setPage}
          />
        </ScrollArea>
      )}
    </Container>
  )
}

export default memo(AlarmList)
