import { memo, useCallback, useMemo, useState } from 'react'
import {
  type Dashboard,
  type DashboardItem,
  deleteDashboardItem
} from '@venturi-io/api/src/userManager/dashboard'
import {
  createStyles,
  ActionIcon,
  Box,
  Group,
  Tooltip
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Layout, type Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { getMantineBreakpointsInPx } from 'src/utils/style'
import ConfirmModal from 'src/Layout/ConfirmModal'
import zIndex from 'src/utils/zIndex'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import {
  type MapProps,
  type SensorHistoryProps,
  type MultiSensorHistoryProps,
  type StackedSensorAllAgentsProps,
  type SensorGaugeProps,
  type SensorPercentageProps,
  type SensorStatusProps,
  type AgentStatusProps,
  type AgentQuickViewProps,
  type MultiAgentSensorHistoryProps,
  type MultiAgentSensorHistoryTableProps,
  type AlarmListProps,
  type DuressAlarmsProps,
  type IFrameProps,
  type SpeedAlertProps,
  type LastSeenProps,
  type ConnectionStateProps,
  type MotionStateProps,
  type AssetsInGeozonesProps,
  type EngineHourProps,
  type OdometerProps,
  Map,
  SensorHistory,
  MultiSensorHistory,
  StackedSensorAllAgents,
  SensorGauge,
  SensorPercentage,
  SensorStatus,
  AgentStatus,
  AgentQuickView,
  MultiAgentSensorHistory,
  MultiAgentSensorHistoryTable,
  AlarmList,
  DuressAlarms,
  IFrame,
  SpeedAlerts,
  LastSeen,
  ConnectionState,
  MotionState,
  AssetsInGeozones,
  EngineHours,
  Odometer
} from './Items'

const ResponsiveGridLayout = WidthProvider(Responsive)

const gridColumns = {
  xl: 12,
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2
}

interface StyleParams {
  isEditable: boolean
}

const useStyles = createStyles((_, params: StyleParams) => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    // This will disable each grid item's pointer events except for editControls if dashboard is in Edit Mode
    '*:not(#editControls) > *': {
      pointerEvents: params.isEditable
        ? 'none'
        : 'initial'
    }
  },
  editControlsContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: zIndex.dashEditControls,
    opacity: 0.7,
    transition: '.2s all ease-in',
    ':hover': {
      opacity: 1
    }
  }
}))

interface Props {
  selectedDashboard: Dashboard | null
  currentBreakpoint: string
  setCurrentLayout?: (layout: Layout[]) => void
  setCurrentBreakpoint: (breakpoint: string) => void
  setSelectedDashboard?: (dashboard: Dashboard | null) => void
  setSelectedDashboardItem?: (item: DashboardItem) => void
  onDelete?: (itemId: DashboardItem['dashboardItemId']) => void
  isEditable?: boolean
}

const Grid = ({
  isEditable = false,
  currentBreakpoint,
  selectedDashboard,
  setCurrentLayout,
  setCurrentBreakpoint,
  setSelectedDashboard,
  setSelectedDashboardItem,
  onDelete
}: Props) => {
  const { classes } = useStyles({ isEditable })
  const { token } = useUser()
  const [currentDashboardItem, setCurrentDashboardItem] = useState<DashboardItem | null>(null)
  const removeDashboardItem = useApi(deleteDashboardItem)
  const [isOpeneDeleteModal, setIsOpenDeleteModal] = useState(false)
  const breakpoints = useMemo(() => {
    return getMantineBreakpointsInPx()
  }, [])
  const items = selectedDashboard?.dashboardItems ?? []
  const layouts: Layouts = selectedDashboard?.layouts as Layouts ?? {}

  const [y, setY] = useState(0)

  const getItemLayout = useCallback((i: string, breakpoint: string, fallbackLayout: Layout) => {
    const items = layouts[breakpoint]

    if (!items) {
      return fallbackLayout
    }

    const itemLayout = items.find(item => item.i === i)

    return itemLayout ?? fallbackLayout
  }, [layouts])

  const onLayoutChange = useCallback((layout: Layout[]) => {
    if (setCurrentLayout) {
      setCurrentLayout(layout)
    }
  }, [setCurrentLayout])

  const onBreakpointChange = useCallback((breakpoint: string) => {
    setCurrentBreakpoint(breakpoint)
  }, [setCurrentBreakpoint])

  const handleEditItem = useCallback((item: DashboardItem) => {
    if (isEditable && setSelectedDashboardItem) {
      setSelectedDashboardItem(item)
    }
  }, [isEditable, setSelectedDashboardItem])

  const handleDeleteItem = useCallback(() => {
    if (selectedDashboard && currentDashboardItem && isEditable) {
      const { dashboardItemId } = currentDashboardItem

      void removeDashboardItem
        .fetch({ dashboardItemId }, token)
        .finally(() => {
          if (setSelectedDashboard) {
            setSelectedDashboard({
              ...selectedDashboard,
              dashboardItems: selectedDashboard?.dashboardItems?.filter(
                item => item.dashboardItemId !== dashboardItemId
              )
            })
          }

          if (onDelete) {
            onDelete(dashboardItemId)
          }

          setIsOpenDeleteModal(false)
        })
    }
  }, [isEditable, selectedDashboard, currentDashboardItem])

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        rowHeight={20}
        breakpoints={breakpoints}
        cols={gridColumns}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        isDraggable={isEditable}
        isDroppable={isEditable}
        isResizable={isEditable}
        onBreakpointChange={onBreakpointChange}
      >
        {items.map((item, i) => {
          const { dashboardItemId, type, properties } = item
          const itemId = dashboardItemId.toString()
          const x = (i * 4) % 12

          if (x === 9) {
            setY(prevY => prevY + 1)
          }

          const defaultLayout = {
            i: itemId,
            x,
            y,
            w: 4,
            h: 10
          }

          const itemLayout = getItemLayout(itemId, currentBreakpoint, defaultLayout)

          return (
            <Box key={dashboardItemId} className={classes.container} data-grid={itemLayout}>
              {isEditable && (
                <Group id="editControls" className={classes.editControlsContainer} spacing={0}>
                  <Tooltip
                    label="Edit widget"
                    transitionProps={{
                      transition: 'pop',
                      duration: 200
                    }}
                    withArrow
                    withinPortal
                  >
                    <ActionIcon
                      variant="filled"
                      color="green"
                      radius={0}
                      onClick={() => handleEditItem(item)}
                    >
                      <FontAwesomeIcon icon={['fas', 'pencil']} size="1x" />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip
                    label="Delete widget"
                    transitionProps={{
                      transition: 'pop',
                      duration: 200
                    }}
                    withArrow
                    withinPortal
                  >
                    <ActionIcon
                      variant="filled"
                      color="red"
                      radius={0}
                      onClick={() => {
                        setCurrentDashboardItem(item)
                        setIsOpenDeleteModal(true)
                      }}
                    >
                      <FontAwesomeIcon icon={['fas', 'trash']} size="1x" />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              )}
              {type === 'Sensor History' && <SensorHistory {...properties as unknown as SensorHistoryProps} />}

              {type === 'Multi-Sensor History' && (
                <MultiSensorHistory {...properties as unknown as MultiSensorHistoryProps} />
              )}

              {type === 'Stacked Sensor - All Agents' && (
                <StackedSensorAllAgents {...properties as unknown as StackedSensorAllAgentsProps} />
              )}

              {type === 'Sensor Gauge' && <SensorGauge {...properties as unknown as SensorGaugeProps} />}

              {type === 'Sensor Percentage' && <SensorPercentage {...properties as unknown as SensorPercentageProps} />}

              {type === 'Sensor Status' && <SensorStatus {...properties as unknown as SensorStatusProps} />}

              {type === 'Multi-Agent Sensor History' && (
                <MultiAgentSensorHistory {...properties as unknown as MultiAgentSensorHistoryProps} />
              )}

              {type === 'Multi-Agent Sensor History (Table)' && (
                <MultiAgentSensorHistoryTable {...properties as unknown as MultiAgentSensorHistoryTableProps} />
              )}

              {type === 'Agent Status' && <AgentStatus {...properties as unknown as AgentStatusProps} />}

              {type === 'Agent Quick View' && <AgentQuickView {...properties as unknown as AgentQuickViewProps} />}

              {type === 'Map' && <Map {...properties as unknown as MapProps} />}

              {type === 'Alarm List' && <AlarmList {...properties as unknown as AlarmListProps} />}

              {type === 'Duress Alarms' && <DuressAlarms {...properties as unknown as DuressAlarmsProps} />}

              {type === 'IFrame' && <IFrame {...properties as unknown as IFrameProps} />}

              {type === 'Speed Alerts' && <SpeedAlerts {...properties as unknown as SpeedAlertProps} />}

              {type === 'Last Seen' && <LastSeen {...properties as unknown as LastSeenProps} />}

              {type === 'Connection State' && <ConnectionState {...properties as unknown as ConnectionStateProps} />}

              {type === 'Motion State' && <MotionState {...properties as unknown as MotionStateProps} />}

              {type === 'Assets in Geozones' && (
                <AssetsInGeozones {...properties as unknown as AssetsInGeozonesProps} />
              )}

              {type === 'Engine Hours' && <EngineHours {...properties as unknown as EngineHourProps} />}

              {type === 'Odometer' && <Odometer {...properties as unknown as OdometerProps} />}
            </Box>
          )
        })}
      </ResponsiveGridLayout>
      {isEditable && selectedDashboard && currentDashboardItem && (
        <ConfirmModal
          type="delete"
          opened={isOpeneDeleteModal}
          title={`Deleting "${currentDashboardItem.name}"`}
          question="Are you sure you want to proceed? This action cannot be undone."
          onClose={() => setIsOpenDeleteModal(false)}
          onCancel={() => setIsOpenDeleteModal(false)}
          onConfirm={handleDeleteItem}
        />
      )}
    </>
  )
}

export default memo(Grid)
