import { type Sensor } from '@venturi-io/api/src/config/agent'

export const generateRandomNumber = (min: number, max: number) => Math.floor(Math.random() * ((max - min) + 1) + min)

export const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

export const roundToKm = (num?: number) => Math.round((num ?? 0) / 1000)

export const computeSensorPercentage = (sensor: Sensor) => {
  const currentVal = sensor?.currentValue
    ? parseFloat(sensor.currentValue)
    : 0

  const maxVal = sensor?.maxValue
    ? sensor.maxValue
    : 100

  return round((currentVal / maxVal) * 100)
}
