import { memo, useMemo } from 'react'
import { Title } from '@mantine/core'
import { type Column, type ColumnGroup, type CellProps } from 'react-table'
import dayjs from 'dayjs'
import DataGrid from 'src/Layout/DataGrid'
import { uiDateFormat } from 'src/utils/dates'
import { type SensorHistory } from '.'

type Row = SensorHistory

interface TableCellProps extends CellProps<{}> {
  sensorKey: string
}

function TableCell ({ row, sensorKey }: TableCellProps) {
  const origRow = row.original as Row
  const sensor = origRow.data.find(({ key }) => key === sensorKey)

  return (
    sensor
      ? <p>{`${sensor.value} ${sensor.unit}`}</p>
      : null
  )
}

export interface Props {
  title: string
  data: SensorHistory[]
}

const Table = ({ title, data }: Props) => {
  const columns: Column[] = useMemo(() => {
    const { data: sample } = data[0]
    const agentsMap = new Map<string, ColumnGroup>()

    sample.forEach(({ key, agentName, name: sensorName }) => {
      const newSensorColumn: Column = {
        id: key,
        Header: sensorName,
        Cell: (props: CellProps<{}>) => <TableCell {...props} sensorKey={key} />
      }

      const agentColumn: ColumnGroup | undefined = agentsMap.get(agentName)

      agentsMap.set(agentName, {
        Header: agentName,
        columns: [
          ...agentColumn?.columns ?? [],
          newSensorColumn
        ]
      })
    })

    return [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: props => <div>{dayjs(props.value).format(uiDateFormat)}</div>
      },
      ...agentsMap.values()
    ]
  }, [data])

  return (
    <>
      <Title ml="md" mt="sm" order={3}>{title}</Title>
      <DataGrid data={data} columns={columns} />
    </>
  )
}

export default memo(Table)
