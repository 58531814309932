import { DateTimePicker as MantineDateTimePicker, type DateTimePickerProps } from '@mantine/dates'
import { useSelectStyles } from './styles'

export default function DateTimePicker (props: DateTimePickerProps) {
  const { classes } = useSelectStyles()

  return (
    <MantineDateTimePicker
      color="primary"
      classNames={classes}
      {...props}
    />
  )
}
