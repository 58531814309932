import { useEffect } from 'react'
import { getAllSensorDataLogs } from '@venturi-io/api/src/collector/sensor'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { dateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import ActionList from 'src/Layout/ActionList'
import Nothing from 'src/Nothing'
import { type Range } from '@venturi-io/api'
import { Card, Header, Row } from './List'

interface Props {
  agentId?: number
  sensorInstanceId?: number
  dateRange: Range | null
}

export default function Logs ({ agentId, sensorInstanceId, dateRange }: Props) {
  const { token } = useUser()
  const allSensorDataLogs = usePaginatedApi(getAllSensorDataLogs)

  const { from, to } = dateRange
    ? {
        from: dayjs(dateRange.from, dateFormat),
        to: dayjs(dateRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const isMoreThanAWeek = to?.isBefore(from.add(7, 'days').add(1, 'minute'))

  useEffect(() => {
    if (!isMoreThanAWeek) {
      return
    }

    const req = {
      page: allSensorDataLogs.page,
      size: allSensorDataLogs.size,
      agentId,
      sensorInstanceId,
      startTime: dayjs(from).format(`${dateFormat}Z`),
      endTime: dayjs(to).format(`${dateFormat}Z`)
    }

    void allSensorDataLogs
      .fetch(req, token)
      .finally(() => {
        allSensorDataLogs.startPolling(req, token, 60)
      })

    return () => {
      allSensorDataLogs.stopPolling()
      allSensorDataLogs.abort()
    }
  }, [allSensorDataLogs.page, agentId, sensorInstanceId, dateRange])

  return (
    <>
      {allSensorDataLogs.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={allSensorDataLogs.loading}
            nothing={allSensorDataLogs.data.isNothing()}
          />
        ),
        Just: ({ items }) => (
          <ActionList
            isLoading={allSensorDataLogs.loading}
            data={items}
            getId={({ id }) => id}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={allSensorDataLogs.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            page={allSensorDataLogs.page}
            onPageChange={allSensorDataLogs.setPage}
          />
        )
      })}
    </>
  )
}
