import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Image, createStyles } from '@mantine/core'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import { useNavigate } from 'react-router'
import { type StyleParams, styleParams } from '../shared'

const useStyles = createStyles((theme, params: StyleParams) => ({
  root: {
    background: theme.colors.brand[3],
    display: 'flex',
    alignItems: 'center',
    height: params.headerHeight
  },
  logo: {
    cursor: 'pointer'
  },
  brandLogo: {
    marginLeft: '15px',
    marginBottom: '0'
  }
}))

function DefaultMobileLogo () {
  const { classes } = useStyles(styleParams)
  const navigate = useNavigate()

  return (
    <Image
      key="mobile"
      className={classes.brandLogo}
      src="/logo.svg"
      alt="Mobile Logo"
      width={40}
      onClick={() => {
        navigate('/')
      }}
    />
  )
}

interface DefaultLogoProps {
  minimised: boolean
}

function DefaultLogo ({ minimised }: DefaultLogoProps) {
  const { classes } = useStyles(styleParams)
  const navigate = useNavigate()
  const src = minimised
    ? '/logo.svg'
    : '/venturi-logo.png'

  return (
    <Image
      key="logo"
      className={classes.logo}
      src={src}
      alt="Main Logo"
      height={45}
      width="auto"
      onClick={() => {
        navigate('/')
      }}
    />
  )
}

export interface Props {
  isDesktop: boolean
  minimised?: boolean
  orgId: number
}

export default function Logo ({ isDesktop, minimised = false, orgId }: Props) {
  const navigate = useNavigate()
  const { classes } = useStyles(styleParams)
  const [fullUrl, setFullUrl] = useState<string | null>(null)
  const [minUrl, setMinUrl] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)
  const [imgWidth, setImgWidth] = useState<number>()
  const [paddingLeft, setPaddingLeft] = useState('')
  const imgRef = useRef<HTMLDivElement>(null)

  const computedPadding = imgWidth
    ? `${Math.floor((60 - imgWidth) / 2)}px`
    : '5px'

  const redirectToHomePage = useCallback(() => {
    navigate('/')
  }, [])

  const handleImageError = useCallback(() => {
    setHasError(true)
  }, [setHasError])

  useEffect(() => {
    const dateToday = Date.now()

    void configure(getHost())
      .then(({ user }) => {
        setFullUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topLogo&time=${dateToday}`)
        setMinUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topMobileLogo&time=${dateToday}`)
      })
  }, [])

  useEffect(() => {
    setInterval(() => {
      const img = imgRef.current?.children
      setImgWidth(img?.[0].clientWidth)
    }, 1000)
  }, [])

  useEffect(() => {
    if (minimised) {
      setPaddingLeft(computedPadding)
    }
  }, [computedPadding])

  if (!minUrl || !fullUrl) {
    return null
  }

  if (!isDesktop) {
    return !hasError
      ? (
        <Image
          key="mobile"
          className={classes.brandLogo}
          src={minUrl}
          alt="Mobile Logo"
          width={40}
          onClick={redirectToHomePage}
          onError={handleImageError}
        />
        )
      : <DefaultMobileLogo />
  }

  return (
    <Box className={classes.root}>
      {!hasError
        ? (
          <Image
            ref={imgRef}
            key="logo"
            className={classes.logo}
            src={minimised ? minUrl : fullUrl}
            alt="Main Logo"
            height={45}
            width="auto"
            onClick={redirectToHomePage}
            onError={handleImageError}
            pl={paddingLeft}
          />
          )
        : <DefaultLogo minimised={minimised} />}
    </Box>
  )
}
