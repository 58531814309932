import { Group, Paper, Stack } from '@mantine/core'
import { type Role } from '@venturi-io/api/src/userManager/role'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'

export default function Card ({ ...role }: Role & ListRow) {
  const { roleId, name } = role

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={roleId.toString()} isKey />
        </Group>
        <TextGroup label="Name" value={name} />
      </Stack>
    </Paper>
  )
}
