import { type Layouts } from 'react-grid-layout'
import { MODULE_CONTENT } from './constants'

export const reuse = {
  static: true,
  isDraggable: false
}

export const initialLayout = [
  { i: MODULE_CONTENT.TASK_DETAILS, x: 0, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_DESCRIPTION, x: 4, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_COMMENTS, x: 8, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_ATTACHMENTS, x: 0, y: 1, w: 12, h: 1.5, ...reuse }
]

export const mdLayout = [
  { i: MODULE_CONTENT.TASK_DETAILS, x: 0, y: 0, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_DESCRIPTION, x: 0, y: 1, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_COMMENTS, x: 0, y: 2, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.TASK_ATTACHMENTS, x: 0, y: 3, w: 12, h: 1.5, ...reuse }
]

export const defaultLayouts: Layouts = {
  xl: initialLayout,
  lg: initialLayout,
  md: mdLayout,
  sm: mdLayout,
  xs: mdLayout,
  xxs: mdLayout
}
