import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  type ColProps,
  useMantineTheme,
  Button,
  Col,
  Grid,
  Group,
  Stack,
  Title,
  Switch,
  createStyles
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import MultiSelectUser from 'src/Input/MultiSelect/MultiSelectUser'
import { createRole, deleteRole, updateRole, type Role } from '@venturi-io/api/src/userManager/role'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { mq } from 'src/utils/style'

interface FormProps extends Omit<Role, 'roleId' | 'aclPermissions'> {
  orgUserIds?: string[]
  agentGroupIds?: string[]
}

interface Props {
  mode: FormMode
  isBaseRole?: boolean
  initialValues?: Role
  onCreate?: () => Promise<void>
  onClose?: () => void
}

const useStyle = createStyles(() => ({
  description: {
    marginTop: 0
  }
}))

export default function Form ({
  mode,
  isBaseRole,
  initialValues,
  onCreate,
  onClose
}: Props) {
  const { classes } = useStyle()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { token, orgId } = useUser()
  const navigate = useNavigate()
  const newRole = useApi(createRole)
  const editRole = useApi(updateRole)
  const removeRole = useApi(deleteRole)
  const [showDelete, setShowDelete] = useState(false)

  const [hasRead, setHasRead] = useState(false)
  const [hasWrite, setHasWrite] = useState(false)
  const [hasDelete, setHasDelete] = useState(false)

  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const form = useForm<FormProps>({
    initialValues: {
      name: ''
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null
    }
  })

  type FormValues = typeof form.values

  const aclPermissions = useMemo(() => {
    const perms: string[] = []
    if (hasRead) {
      perms.push('READ')
    }
    if (hasWrite) {
      perms.push('WRITE')
    }
    if (hasDelete) {
      perms.push('DELETE')
    }
    return perms
  }, [hasRead, hasWrite, hasDelete])

  const redirectToListPage = () => navigate('/my-admin/roles')

  const handleCreate = ({ name, orgUserIds, agentGroupIds }: FormValues) => {
    void newRole
      .fetch({
        orgId,
        name,
        orgUsers: orgUserIds?.map(id => ({ id: Number(id) })),
        agentGroups: agentGroupIds?.map(id => ({
          agentGroupId: Number(id),
          name: '',
          description: ''
        })),
        aclPermissions
      }, token, 'Successfully created new role')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }

  const handleUpdate = ({ name, orgUserIds, agentGroupIds }: FormValues) => {
    const roleId = initialValues?.roleId

    if (!roleId) throw new Error('Unable to perform the action, no role provided')

    void editRole
      .fetch({
        orgId,
        roleId,
        name,
        orgUsers: orgUserIds?.map(id => ({ id: Number(id) })),
        agentGroups: agentGroupIds?.map(id => ({
          agentGroupId: Number(id),
          name: '',
          description: ''
        })),
        aclPermissions
      }, token, 'Successfully updated role')
      .finally(redirectToListPage)
  }

  const handleDelete = () => {
    const roleId = initialValues?.roleId

    if (!roleId) throw new Error('Unable to perform the action, no role provided')

    void removeRole
      .fetch({ roleId }, token, 'Succesfully deleted role')
      .finally(redirectToListPage)
  }

  const handleSubmit = (values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }

  const handleClose = () => {
    form.reset()

    if (onClose) onClose()
  }

  useEffect(() => {
    if (initialValues) {
      const { name, orgUsers, agentGroups, aclPermissions } = initialValues
      form.setValues({
        name,
        orgUserIds: orgUsers?.map(({ id }) => id.toString()) ?? [],
        agentGroupIds: agentGroups?.map(({ agentGroupId }) => agentGroupId.toString()) ?? []
      })
      if (aclPermissions) {
        setHasRead(aclPermissions.includes('READ'))
        setHasWrite(aclPermissions.includes('WRITE'))
        setHasDelete(aclPermissions.includes('DELETE'))
      }
    }
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <Stack spacing="md">
              <TextInput
                withAsterisk
                label="Name"
                {...form.getInputProps('name')}
                disabled={inEditMode}
              />
              <MultiSelectUser
                label="Users"
                value={form.values.orgUserIds}
                placeholder="select Users"
                onChange={ids => (
                  form.setFieldValue('orgUserIds', ids)
                )}
                searchable
              />
              {!isBaseRole && (
                <>
                  <MultiSelectAgentGroup
                    label="Agent Groups"
                    value={form.values.agentGroupIds}
                    placeholder="select Agent groups"
                    onChange={ids => (
                      form.setFieldValue('agentGroupIds', ids)
                    )}
                    searchable
                  />
                  <Stack mt="xl">
                    <Title order={4}>Permissions</Title>
                    <Switch
                      label="View only"
                      color="primary"
                      checked={hasRead}
                      onChange={event => setHasRead(event.currentTarget.checked)}
                    />
                    <Switch
                      label="Update"
                      description="The role is able to update linked agent groups and their agents"
                      color="primary"
                      classNames={{
                        description: classes.description
                      }}
                      checked={hasWrite}
                      onChange={event => setHasWrite(event.currentTarget.checked)}
                    />
                    <Switch
                      label="Delete"
                      description="The role is able to delete linked agent groups and their agents"
                      color="primary"
                      classNames={{
                        description: classes.description
                      }}
                      checked={hasDelete}
                      onChange={event => setHasDelete(event.currentTarget.checked)}
                    />
                  </Stack>
                </>
              )}
            </Stack>
          </Col>
          {inEditMode && !isBaseRole && (
            <Col span={6}>
              <Group
                position={isMobile
                  ? 'right'
                  : 'left'}
              >
                <Button
                  mt="sm"
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col
            span={inCreateMode
              ? 12
              : isBaseRole
                ? 12
                : 6}
          >
            <Group position="right" mt="sm">
              <Button
                variant="subtle"
                color="dark"
                onClick={inCreateMode
                  ? handleClose
                  : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={[
                      'fas',
                      inCreateMode
                        ? 'plus'
                        : 'floppy-disk'
                    ]}
                    color="white"
                  />
                )}
                disabled={inCreateMode
                  ? newRole.loading
                  : editRole.loading}
              >
                {inCreateMode
                  ? 'Create'
                  : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeRole.loading}
          question="Are you sure you want to permanently delete this role?"
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
