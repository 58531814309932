import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Button,
  Group,
  Paper,
  Popover,
  SimpleGrid,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { inviteUser, type AllUsers } from '@venturi-io/api/src/userManager/user'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import NeedsRole from 'src/NeedsRole'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import RestartOnboardingButton from 'src/Buttons/RestartOnboardingButton'

interface Props extends ListRow {
  setSentInvite: (status: boolean) => void
}

export default function Card ({
  userId,
  orgUserId,
  email,
  firstName,
  lastName,
  jobTitle,
  status,
  roles,
  setSentInvite
}: AllUsers[0] & Props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const invite = useApi(inviteUser)
  const { token } = useUser()

  const form = useForm<{ email: string }>({
    initialValues: {
      email: ''
    },
    validate: {
      email: value => /^\S+@\S+$/.test(value)
        ? null
        : 'Please input a valid Email'
    }
  })

  const handleSubmit = useCallback(({ email }: typeof form.values) => {
    void invite
      .fetch({
        orgUserId,
        email
      }, token, 'Successfully invited')
      .finally(() => {
        close()
        setOpen(false)
        setSentInvite(true)
      })
  }, [form.values])

  const showEmailForm = () => {
    setOpen(true)
    form.setValues({
      email: email ?? ''
    })
  }

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={orgUserId.toString()} isKey />
          <Group spacing={8}>
            <ActionButton
              label="Edit"
              icon="pencil"
              iconType="fas"
              onClick={() => navigate(`/settings/users/edit/${orgUserId}`)}
              actionColor="green"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
            {status === 'INACTIVE' && (
              <NeedsRole role="ROLE_SUPERADMIN">
                <div
                  style={{
                    position: 'relative'
                  }}
                >
                  <Popover
                    width={250}
                    position="right-start"
                    shadow="lg"
                    withArrow
                    opened={open}
                    onClose={() => setOpen(false)}
                  >
                    <Popover.Target>
                      <Tooltip
                        label="Invite"
                        position="top"
                        transitionProps={{
                          transition: 'pop',
                          duration: 200
                        }}
                        withArrow
                      >
                        <ActionIcon onClick={showEmailForm} color="primary" variant="filled">
                          <FontAwesomeIcon icon={['far', 'envelope']} size="sm" />
                        </ActionIcon>
                      </Tooltip>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <form onSubmit={form.onSubmit(handleSubmit)}>
                        <TextInput
                          label="Email"
                          placeholder="Email"
                          size="xs"
                          {...form.getInputProps('email')}
                        />
                        <Button
                          type="submit"
                          color="green"
                          mt={10}
                          leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} color="white" />}
                        >
                          Invite
                        </Button>
                      </form>
                    </Popover.Dropdown>
                  </Popover>
                </div>
              </NeedsRole>
            )}
            {status === 'ACTIVE' && (
              <RestartOnboardingButton
                id={userId}
                name={`${firstName} ${lastName}`}
                type="USER"
              />
            )}
          </Group>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup label="First Name" value={firstName ?? '-'} />
          <TextGroup label="Last Name" value={lastName ?? '-'} />
          <TextGroup label="Job Title" value={jobTitle ?? '-'} />
          <TextGroup label="Status" value={status} />
          <TextGroup label="Email" value={email ?? '-'} />
          <Stack spacing={2}>
            <TextGroup label="Role" value="" />
            {roles.map((role) => (
              <Text key={role} size="sm">
                {role}
              </Text>
            ))}
          </Stack>
        </SimpleGrid>

      </Stack>
    </Paper>
  )
}
