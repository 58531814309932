import { useState } from 'react'
import {
  ColorPicker as Picker,
  Group,
  Button,
  TextInput,
  Stack
} from '@mantine/core'
import { validateHex } from 'src/utils/style'

interface Props {
  color: string
  close?: () => void
  onAccept?: (color: string) => void
  onCancel?: () => void
}

export default function ColorPicker ({ color, close, onAccept, onCancel }: Props) {
  const [internal, setInternal] = useState(color)
  const [hex, setHex] = useState(color)
  const hasChanged = color !== internal

  const onColor = (input: string) => {
    setInternal(input)
    setHex(input)
  }

  const validateColor = () => {
    if (validateHex(hex)) {
      setInternal(hex)
    } else {
      setHex(internal)
    }
  }

  const accept = () => {
    if (onAccept) onAccept(internal)
    if (close) close()
  }

  const cancel = () => {
    if (onCancel) onCancel()
    if (close) close()
  }

  return (
    <Stack align="center">
      <Picker format="hex" value={internal} onChange={onColor} />
      <TextInput
        value={hex}
        onChange={ev => setHex(ev.currentTarget.value)}
        onBlur={validateColor}
      />
      <Group>
        <Button color="gray" onClick={cancel}>Cancel</Button>
        <Button disabled={!hasChanged} onClick={accept}>Accept</Button>
      </Group>
    </Stack>
  )
}
