import { useMantineTheme, Group } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { useContext } from 'react'
import { AssetContext } from 'src/AssetTemplate/Context'
import Alarms from './Alarms'
import Tasks from './Tasks'

interface Props {
  alarms: number
}

export default function Statuses ({ alarms }: Props) {
  const { taskCount } = useContext(AssetContext)
  const { breakpoints: { xs } } = useMantineTheme()
  const isSmallerScreen = useMediaQuery(mq(xs, false))

  return (
    <Group
      position="center"
      align="center"
      spacing={isSmallerScreen
        ? 16
        : 8}
    >
      <Alarms count={alarms} showTitle={isSmallerScreen} />
      <Tasks count={taskCount} showTitle={isSmallerScreen} />
    </Group>
  )
}
