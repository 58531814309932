import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getGeoZoneRules } from '@venturi-io/api/src/config/geoZoneRule'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateGeoZoneRule from './Create'

export default function GeoZoneRules () {
  const { token, orgId } = useUser()
  const geoZoneRules = usePaginatedApi(getGeoZoneRules)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadGeoZoneRules = async (page?: number, size?: number): Promise<void> => {
    void geoZoneRules
      .fetch({
        orgId,
        page: page ?? geoZoneRules.page,
        size: size ?? geoZoneRules.size,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadGeoZoneRules(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadGeoZoneRules()
  }, [geoZoneRules.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create New Rule"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add New Rule
        </Button>
      </Group>
      <Paper
        mt="md"
        p="sm"
        shadow="sm"
      >
        <Grid grow>
          <Col span={12}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={geoZoneRules.loading}
        data={geoZoneRules.data.mapOrDefault(({ items }) => items, [])}
        getId={({ geoZoneRuleId }) => geoZoneRuleId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={geoZoneRules.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={geoZoneRules.page}
        onPageChange={geoZoneRules.setPage}
      />
      <CreateGeoZoneRule
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadGeoZoneRules}
      />
    </>
  )
}
