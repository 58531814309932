import { memo, useCallback, useMemo } from 'react'
import { Accordion, Button, Stack } from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ItemPreview from '../shared/ItemPreview'
import Donut, { type Props as DonutProps } from '.'

interface Props {
  initialValues: DonutProps | null
  onSubmit: (properties: DonutProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: DonutProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: ''
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="signal-stream" previewItem>
        <Donut {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
