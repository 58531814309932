import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getAllTasks,
  type TaskType,
  taskTypes,
  type Status,
  statuses
} from '@venturi-io/api/src/config/task'
import { type AssetType } from '@venturi-io/api/src/config/agent'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import ActionList from 'src/Layout/ActionList'
import SelectAssetType from 'src/Input/Select/SelectAssetType'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import { Header, Row, Card } from './List'
import CreateTask from './Create'
import CreateTaskForAgent from './CreateForSingleAgent'

interface Props {
  agentId?: number
  agentName?: string
  title?: string
  hideToolbar?: boolean
  hideShadow?: boolean
  didChangeHeight?: (height: number, key: string) => void
}

function Tasks ({
  agentId,
  agentName,
  title,
  hideToolbar,
  hideShadow,
  didChangeHeight
}: Props) {
  const { search } = useLocation()
  const { token } = useUser()
  const tasks = usePaginatedApi(getAllTasks)
  const [showCreate, setShowCreate] = useState(false)
  const [showSingleCreate, setShowSingleCreate] = useState(false)
  const [assetId, setAssetId] = useState<string | null>(null)
  const [assetName, setAssetName] = useState<string | undefined>()
  const [debouncedAssetName] = useDebouncedValue(assetName, 700)
  const [taskType, setTaskType] = useState<TaskType | ''>('')
  const [assetType, setAssetType] = useState<AssetType | string>('')
  const [status, setStatus] = useState<Status | ''>('')

  const selectTaskType = [
    {
      value: '',
      label: 'NONE'
    },
    ...taskTypes
  ]

  const loadTasks = async (page?: number, size?: number): Promise<void> => {
    void tasks.fetch({
      page: page ?? tasks.page,
      size: size ?? tasks.size,
      agentId: assetId ?? undefined,
      assetName: agentName ?? debouncedAssetName,
      sort: 'id',
      order: 'desc',
      taskType: taskType !== ''
        ? taskType
        : undefined,
      assetType: assetType !== ''
        ? assetType
        : undefined,
      status: status !== ''
        ? status
        : undefined
    }, token)
  }

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const assetId_ = params.get('assetId')

      if (assetId_) {
        setAssetId(assetId_)
      }
    }
  }, [search])

  const selectStatus = [
    {
      value: '',
      label: 'NONE'
    },
    ...statuses
  ]

  useEffect(() => {
    void loadTasks(1)
  }, [assetId, debouncedAssetName, taskType, assetType, status])

  useEffect(() => {
    void loadTasks()
  }, [tasks.page])

  return (
    <>
      <NeedsRole role={adminRoles}>
        <Group
          position={title
            ? 'apart'
            : 'right'}
          spacing="xs"
        >
          {title && (
            <Text
              transform="uppercase"
              size="sm"
              weight={500}
            >
              {title}
            </Text>
          )}
          <Button
            disabled={showCreate}
            title="Create New Task"
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
            onClick={() => agentId ? setShowSingleCreate(true) : setShowCreate(true)}
          >
            Add new task
          </Button>
        </Group>
      </NeedsRole>
      {!hideToolbar && (
        <Paper mt="lg" p="sm" shadow="sm">
          <Grid grow>
            <Col span={12} md={5}>
              <TextInput
                variant="unstyled"
                radius="xl"
                placeholder="Search task here..."
                icon={<FontAwesomeIcon icon={['fas', 'search']} />}
                onChange={e => setAssetName(e.target.value)}
              />
            </Col>
            <Col span={12} md={2}>
              <SelectAssetType
                placeholder="Filter by type"
                onChange={value => setAssetType(value ?? '')}
              />
            </Col>
            <Col span={12} md={2}>
              <Select
                placeholder="Filter by task type"
                data={selectTaskType}
                onChange={(value: TaskType) => setTaskType(value)}
              />
            </Col>
            <Col span={12} md={2}>
              <Select
                placeholder="Filter by status"
                data={selectStatus}
                onChange={(value: Status) => setStatus(value)}
              />
            </Col>
          </Grid>
        </Paper>
      )}
      <ActionList
        identifier="tasks"
        hideShadow={hideShadow}
        isLoading={tasks.loading}
        data={tasks.data.mapOrDefault(({ items }) => items, [])}
        getId={({ id }) => id}
        header={() => <Header />}
        actions={{ loadTasks }}
        row={Row}
        card={Card}
        totalPages={tasks.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={tasks.page}
        onPageChange={tasks.setPage}
        didChangeHeight={didChangeHeight}
      />
      <CreateTask
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadTasks}
      />
      {agentId && (
        <CreateTaskForAgent
          agentId={agentId}
          show={showSingleCreate}
          close={() => setShowSingleCreate(false)}
          onCreate={loadTasks}
        />
      )}
    </>
  )
}

export default Tasks
