import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  createStyles,
  Button,
  Group,
  Stack,
  Text,
  Alert
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentGroup, type AgentGroup } from '@venturi-io/api/src/config/agentGroup'
import { getAgentsForAgentGroup, getRolesForAgentGroup } from '@venturi-io/api/src/config/agent'
import { useApi, useMockApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import ActionList from 'src/Layout/ActionList'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from '../Form'
import { Header, Row, Card } from './AgentList'
import { Header as UserListHeader, Row as UserListRow, Card as UserListCard } from './RoleList'
import AgentAssignment from './AgentAssignment'
import RoleAssignment from './RoleAssignment'

interface RouteParams extends Record<string, string | undefined> {
  agentGroupId: string
}

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  },
  paper: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

// TODO: Remove this mock data once API for getRoles is ready
const mockRoles = [
  {
    roleId: 1,
    name: 'ROLE_ADMIN'
  },
  {
    roleId: 3,
    name: 'ROLE_SUPERADMIN'
  }
]

export default function Edit () {
  const { token } = useUser()
  const agentGroup = useApi(getAgentGroup)
  const agents = usePaginatedApi(getAgentsForAgentGroup)
  const { classes } = useStyles()
  const { agentGroupId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<AgentGroup>()
  const [showAddAgent, setShowAddAgent] = useState(false)
  const [showAddRole, setShowAddRole] = useState(false)
  // TODO: Replace with usePaginatedApi once the API for getRolesForAgentGroup is ready.
  const roles = useMockApi(getRolesForAgentGroup, mockRoles)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const agentGroupId = parseInt(id)

  // TODO: Update this fetching of roles once getRoles paginated API is ready.
  const loadRoles = async (): Promise<void> => {
    void roles.fetch({ agentGroupId }, token)
  }

  const loadAgentGroup = () => {
    void agentGroup.fetch({ agentGroupId }, token)
  }

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agents
      .fetch({
        agentGroupId,
        page: page ?? agents.page,
        size: size ?? agents.size
      }, token)
  }

  const actions = {
    loadAgents: async () => await loadAgents(agents.page)
  }

  // TODO: Add dependecy page here once getRoles paginated API is ready.
  const actionsRole = {
    loadRoles: async () => await loadRoles()
  }

  useEffect(() => {
    loadAgentGroup()
  }, [])

  useEffect(() => {
    agentGroup.data.ifJust(setFormInitialValues)
  }, [agentGroup.data])

  // TODO: Add dependecy page here once getRoles paginated API is ready.
  useEffect(() => {
    void loadRoles()
  }, [])

  useEffect(() => {
    loadAgentGroup()
    void loadAgents()
  }, [agents.page])

  const hasAgent = agents.data.mapOrDefault(({ items }) => (
    items.length > 0
  ), false)

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.container} relative>
          {agentGroup.data.caseOf({
            Nothing: () => (
              <Nothing
                isLoading={agentGroup.loading}
                nothing={agentGroup.data.isNothing()}
              />
            ),
            Just: () => <Form mode="EDIT" initialValues={formInitialValues} hasAgent={hasAgent} />
          })}
        </Paper>
        <Stack mt="lg" spacing={0}>
          <Group position="apart" align="start">
            <Text size="lg" weight="bold">Agents</Text>
            <Button
              disabled={showAddAgent}
              title="Add Agent to this group"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
              onClick={() => setShowAddAgent(true)}
            >
              Add Agent
            </Button>
          </Group>
          <ActionList
            isLoading={agents.loading}
            data={agents.data.mapOrDefault(({ items }) => items, [])}
            actions={actions}
            extra={{ agentGroupId }}
            getId={({ agentId }) => agentId}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={agents.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            page={agents.page}
            onPageChange={agents.setPage}
          />
        </Stack>
        <Stack mt={50} spacing={2}>
          <Group position="apart" align="start" mb="xs">
            <Text size="lg" weight="bold" >
              Permissions
            </Text>
            <Button
              disabled={showAddAgent}
              title="Add Role to this group"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
              onClick={() => setShowAddRole(true)}
              size="sm"
            >
              Add Role
            </Button>
          </Group>
          <Alert title="Configure Permissions" icon={<FontAwesomeIcon icon={['far', 'info-circle']} />} mb={-10}>
            {`Enhance security by assigning role groups to restrict agent
              visibility within this group to specific roles; in the absence of specified roles,
              all organisation users will have access to view agents in the group.`}
          </Alert>
          {/* TODO: Update the values once the API for paginated getRoles is ready */}
          <ActionList
            isLoading={roles.loading}
            data={roles.data.mapOrDefault((items) => items, [])}
            actions={actionsRole}
            extra={{ agentGroupId }}
            getId={({ roleId }) => roleId}
            header={() => <UserListHeader />}
            row={UserListRow}
            card={UserListCard}
            totalPages={roles.data.mapOrDefault(() => 1, 0)}
            page={1}
            onPageChange={() => 1}
          />
        </Stack>
      </div>
      <AgentAssignment
        agentGroupId={agentGroupId}
        show={showAddAgent}
        onClose={() => setShowAddAgent(false)}
        onSave={loadAgents}
      />
      <RoleAssignment
        agentGroupId={agentGroupId}
        show={showAddRole}
        onClose={() => setShowAddRole(false)}
        onSave={loadRoles}
        excludeRoleIds={roles.data.mapOrDefault(role =>
          role.map(({ roleId }) => roleId), []
        )}
      />
    </>
  )
}
