import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { getAgentTemplates } from '@venturi-io/api/src/config/agentTemplate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import NeedsRole from 'src/NeedsRole'
import ActionList from 'src/Layout/ActionList'
import { useDebouncedValue } from '@mantine/hooks'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateAgentTemplate from './Create'

export default function AgentTemplates () {
  const { token } = useUser()
  const agentTemplates = usePaginatedApi(getAgentTemplates)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadAgentTemplates = async (page?: number, size?: number): Promise<void> => {
    void agentTemplates
      .fetch({
        page: page ?? agentTemplates.page,
        size: size ?? agentTemplates.size,
        search: debouncedSearch
      }, token)
  }
  useEffect(() => {
    void loadAgentTemplates(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadAgentTemplates()
  }, [agentTemplates.page])

  return (
    <>
      <NeedsRole role="ROLE_SUPERADMIN">
        <Group
          position="right"
          spacing="xs"
          mb="lg"
        >
          <Button
            disabled={showCreate}
            title="Create Agent Template"
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
            onClick={() => {
              setShowCreate(true)
            }}
          >
            Create Agent Template
          </Button>
        </Group>
        <Paper
          mt="lg"
          p="sm"
          shadow="sm"
          my={0}
        >
          <Grid grow>
            <Col span={12} md={2}>
              <TextInput
                radius="sm"
                placeholder="Search agent templates here..."
                icon={<FontAwesomeIcon icon={['fas', 'search']} />}
                onChange={e => setSearch(e.target.value)}
              />
            </Col>
          </Grid>
        </Paper>
      </NeedsRole>
      <ActionList
        isLoading={agentTemplates.loading}
        data={agentTemplates.data.mapOrDefault(({ items }) => items, [])}
        getId={({ agentId }) => agentId}
        actions={{ loadAgentTemplates }}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={agentTemplates.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={agentTemplates.page}
        onPageChange={agentTemplates.setPage}
      />
      <CreateAgentTemplate
        show={showCreate}
        close={() => {
          setShowCreate(false)
        }}
        onCreate={loadAgentTemplates}
      />
    </>
  )
}
