import { createStyles, Paper as MantinePaper, type PaperProps } from '@mantine/core'
import type { ReactNode } from 'react'

interface Params {
  relative?: boolean
}

const useStyles = createStyles((_, { relative }: Params) => ({
  withPosition: {
    position: relative ? 'relative' : 'initial'
  }
}))

interface Props extends PaperProps, Params {
  children: ReactNode
}

export default function Paper ({ relative, className, ...props }: Props) {
  const { classes, cx } = useStyles({ relative })

  return (
    <MantinePaper
      my={20}
      p="md"
      shadow="xs"
      className={cx(className, classes.withPosition)}
      {...props}
    >
      {props.children}
    </MantinePaper>
  )
}
