import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { useEffect } from 'react'
import { findMissingSensors } from '../shared'
import { MODULE_CONTENT } from './constants'
import AtsStatus from './AtsStatus'
import SVoltageA from './SVoltageA'
import SVoltageB from './SVoltageB'

interface Props {
  agent: AgentDetails
  contentKey: string
  setMissingSensors: (value: string[]) => void
}

export default function ModuleContainer ({
  agent,
  contentKey,
  setMissingSensors
}: Props) {
  const { sensors } = agent

  useEffect(() => {
    const missingSensors = findMissingSensors([
      'S1_AVAILABLE',
      'S1_SWITCH',
      'S2_AVAILABLE',
      'S2_SWITCH',
      'S1_VOLTAGE_L1N',
      'S1_VOLTAGE_L2N',
      'S1_VOLTAGE_L3N',
      'S2_VOLTAGE_L1N',
      'S2_VOLTAGE_L2N',
      'S2_VOLTAGE_L3N'
    ], sensors)

    setMissingSensors(missingSensors)
  }, [sensors])

  switch (contentKey) {
    case MODULE_CONTENT.ATS_STATUS:
      return <AtsStatus sensors={sensors} />

    case MODULE_CONTENT.S1_VOLTAGE:
      return <SVoltageA sensors={sensors} />

    case MODULE_CONTENT.S2_VOLTAGE:
      return <SVoltageB sensors={sensors} />

    default:
      return null
  }
}
