import { useRef, useState } from 'react'

const dashArraySequence: number[][] = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1],
  [0, 3.5, 3, 0.5]
]

let step = 0

export const useDashLineLayer = () => {
  const animation = useRef<number>(0)
  const [dashLineArray, setDashLineArray] = useState<number[]>(dashArraySequence[0])

  const start = (timestamp?: number) => {
    const frame = (timestamp ?? 0 / 10000) / 80
    // Update line-dasharray using the next value in dashArraySequence. The
    // divisor in the expression `timestamp / 50` controls the animation speed.
    const newStep = parseInt(`${frame % dashArraySequence.length}`)

    if (newStep !== step) {
      setDashLineArray(dashArraySequence[step])
      step = newStep
    }

    // Request the next frame of the animation.
    animation.current = requestAnimationFrame(start)
  }

  const stop = () => {
    cancelAnimationFrame(animation.current)
  }

  return {
    dashLineArray,
    stop,
    start
  }
}
