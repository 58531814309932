export const arraysEqual = (a: Array<number | string>, b: Array<number | string>): boolean => {
  const sortedA = a
    .sort((x, y) => (x < y)
      ? 1
      : -1)
    .join('')
  const sortedB = b
    .sort((x, y) => (x < y)
      ? 1
      : -1)
    .join('')

  return a.length === b.length && sortedA === sortedB
}
