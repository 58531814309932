/* eslint-disable react/jsx-curly-newline */
import {
  type RefObject,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  type CheckboxProps,
  Flex,
  Group,
  Stack,
  Text,
  Tooltip,
  createStyles,
  Center
} from '@mantine/core'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormat, uiDateOnlyFormatUniversal } from 'src/utils/dates'
import { useApi } from 'src/utils/useApi'
import { getTripDetails } from '@venturi-io/api/src/collector/trip'
import { useUser } from 'src/UserContext'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { round } from 'src/utils/math'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { secondsToDhms } from 'src/Assets/shared'
import { getTripContext } from '../AnimationContext'
import { type TripProps } from '../..'
import { type FocusManagerRef } from '../../FocusManager'

interface Props {
  focusRef: RefObject<FocusManagerRef>
  trip: TripProps
  position: number
  showDate: boolean
}

const useStyle = createStyles((theme) => ({
  circle: {
    width: 10,
    height: 10,
    borderRadius: 5
  },
  tooltip: {
    fontSize: theme.fontSizes.xs
  }
}))

interface TripItemLineProps {
  icon: IconProp
  iconColor?: string
  label: string
  value: string
}

export const TripItemLine = ({
  icon,
  iconColor,
  label,
  value
}: TripItemLineProps) => {
  const { classes } = useStyle()
  return (
    <Tooltip
      classNames={{
        tooltip: classes.tooltip
      }}
      label={label}
      position="left"
      withArrow
      withinPortal
    >
      <Group spacing="xs">
        <Box
          sx={{
            width: '13px'
          }}
        >
          <FontAwesomeIcon size="xs" icon={icon} color={iconColor} />
        </Box>
        <Text size="xs">
          {value}
        </Text>
      </Group>
    </Tooltip>
  )
}

export const CheckboxIcon: CheckboxProps['icon'] = ({ className }) =>
  <FontAwesomeIcon icon={['fas', 'eye']} className={className} />

function TripsCell ({
  trip,
  position,
  showDate,
  focusRef
}: Props) {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [agentId] = useState(Number(params.get('agentId')))
  const tripDetails = useApi(getTripDetails)
  const { token } = useUser()
  const {
    tripId: id,
    startTime,
    color
  } = trip
  const { classes } = useStyle()
  const { mode, trip: animatingTrip, exitAnimation, initialSetup } = useContext(getTripContext())

  const shouldDisable = animatingTrip
    ? id !== animatingTrip.tripId
    : false

  const isAnimatingPath = animatingTrip
    ? id === animatingTrip.tripId
    : false

  const handleShowTrip = useCallback(() => {
    let url = '/'
    if (isAnimatingPath) {
      if (agentId) {
        url += `?agentId=${agentId}`
      }
      void exitAnimation()
    } else {
      url += `?tripId=${trip.tripId}&startTime=${encodeURIComponent(trip.startTime)}`
      if (agentId) {
        url += `&agentId=${agentId}`
      }
      void tripDetails.fetch({
        tripId: id,
        startTime
      }, token)
    }
    navigate(url, { replace: true })
  }, [mode, isAnimatingPath])

  useEffect(() => {
    tripDetails.data.ifJust((data) => {
      if (focusRef.current) {
        focusRef.current.changeFocusState(false)
      }
      void initialSetup({ ...data, color })
    })
  }, [tripDetails.data, focusRef])

  return (
    <td colSpan={2}>
      <Stack
        style={{
          flex: 1
        }}
        spacing={0}
      >
        {showDate && (
          <Center py="sm" mb="xs" bg="gray.3">
            <Text
              size="sm"
              weight="bold"
              color="dark"
            >
              {dayjs(trip.startTime, dateFormat).format(uiDateOnlyFormatUniversal)}
            </Text>
          </Center>
        )}
        <Flex direction="row" justify="space-between" align="center">
          <Group position="apart" sx={{ flexGrow: 1 }}>
            <Group>
              <div
                className={classes.circle}
                style={{
                  backgroundColor: color
                }}
              />
              <Text weight="bold" color="dark.3">
                Trip #
                {position + 1}
              </Text>
              {trip.inProgress && (
                <Text size="xs" color="dimmed">
                  In progress
                </Text>
              )}
            </Group>
            <Tooltip
              withinPortal
              label="Distance traveled"
              position="top"
              withArrow
            >
              <Text size="xs" color="dimmed">
                {`${round(trip.distance / 1000)} kms`}
              </Text>
            </Tooltip>
          </Group>
        </Flex>
        <TripItemLine
          icon={['fas', 'circle-play']}
          iconColor={color}
          label="Start"
          value={dayjs(trip.startTime, dateFormat).format(uiDateFormat)}
        />
        <TripItemLine
          icon={['fas', 'flag-checkered']}
          iconColor={color}
          label="End"
          value={dayjs(trip.endTime, dateFormat).format(uiDateFormat)}
        />
        <Group>
          <TripItemLine
            icon={['fas', 'shipping-timed']}
            iconColor={color}
            label="Driving duration"
            value={secondsToDhms(trip.drivingDuration ?? 0)}
          />
          <TripItemLine
            icon={['fas', 'hourglass-clock']}
            iconColor={color}
            label="Idling duration"
            value={secondsToDhms(trip.idlingDuration ?? 0)}
          />
        </Group>
        <Group grow>
          <Button
            mt="xs"
            loading={tripDetails.loading}
            variant={isAnimatingPath ? 'filled' : 'outline'}
            color={isAnimatingPath ? 'red.6' : 'primary'}
            size="xs"
            leftIcon={
              isAnimatingPath
                ? <FontAwesomeIcon size="1x" icon="xmark-circle" />
                : <FontAwesomeIcon size="1x" icon="eye" />
            }
            disabled={shouldDisable}
            onClick={handleShowTrip}
          >
            {isAnimatingPath ? 'Exit trip' : 'Show trip'}
          </Button>
        </Group>
      </Stack>
    </td>
  )
}

export default TripsCell
