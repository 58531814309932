import { type SelectItem } from '@mantine/core'
import {
  type CellChange,
  type Column,
  type DefaultCellTypes,
  type Row,
  type TextCell
} from '@silevis/reactgrid'
import { type ModbusRegister } from '@venturi-io/api/src/config/modbus'
import { type SelectCell } from './SelectRowCell'

export interface LocalRegister extends ModbusRegister {
  isDataTypeOpen?: boolean
  isSensorOpen?: boolean
  status: string
  edited: boolean
}

export const getColumns = (): Column[] => [
  { columnId: 'delete', width: 30, resizable: true },
  { columnId: 'address', width: 120, resizable: true },
  { columnId: 'registerCount', width: 120, resizable: true },
  { columnId: 'dataType', width: 120, resizable: true },
  { columnId: 'scalingFactor', width: 120, resizable: true },
  { columnId: 'name', width: 300, resizable: true },
  { columnId: 'description', width: 400, resizable: true },
  { columnId: 'sensorName', width: 300, resizable: true }
]

export const headerRow: Row = {
  rowId: 'header',
  cells: [
    { type: 'header', text: '' },
    { type: 'header', text: 'Address*' },
    { type: 'header', text: 'Register count*' },
    { type: 'header', text: 'Data type*' },
    { type: 'header', text: 'Scaling factor*' },
    { type: 'header', text: 'Name*' },
    { type: 'header', text: 'Description' },
    { type: 'header', text: 'Sensor name*' }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const dataTypeOpts: SelectItem[] = [
  {
    label: 'INT16',
    value: 'INT16'
  },
  {
    label: 'UINT16',
    value: 'UINT16'
  },
  {
    label: 'INT32',
    value: 'INT32'
  },
  {
    label: 'SINT32',
    value: 'SINT32'
  },
  {
    label: 'INT64',
    value: 'INT64'
  },
  {
    label: 'SINT64',
    value: 'SINT64'
  },
  {
    label: 'FLOAT',
    value: 'FLOAT'
  }
]

export const applyChangesToRegisters = (
  changes: Array<CellChange<DefaultCellTypes | SelectCell>>,
  prevRegisters: LocalRegister[]
): LocalRegister[] => {
  changes.forEach((change) => {
    const rowId = change.rowId as number
    const fieldName = change.columnId as keyof LocalRegister
    switch (change.type) {
      case 'select': {
        const newCell = change.newCell
        if (fieldName === 'sensorName') {
          if (newCell.selectedValue) {
            prevRegisters[rowId].sensorName = newCell.selectedValue
            prevRegisters[rowId].sensorId = parseInt(newCell.selectedValue)
          }
        }
        prevRegisters[rowId][fieldName] = newCell.selectedValue as never
        break
      }
      case 'number': {
        const cell = change.newCell
        prevRegisters[rowId][fieldName] = cell.value as never
        break
      }
      default: {
        const cell = change.newCell as TextCell
        prevRegisters[rowId][fieldName] = cell.text as never
        break
      }
    }
    if (!prevRegisters[rowId].edited) {
      prevRegisters[rowId].edited = true
    }
  })
  return [...prevRegisters]
}

export type DataTypes = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function'
