import { type DrawCustomMode } from '@mapbox/mapbox-gl-draw'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'

const DrawRectangle: DrawCustomMode = {
  // When the mode starts this function will be called.
  onSetup: function () {
    const rectangle = this.newFeature({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [[]]
      }
    })
    this.addFeature(rectangle)
    this.clearSelectedFeatures()
    this.updateUIClasses({ mouse: 'add' })
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false
    })
    return {
      rectangle
    }
  },
  // support mobile taps
  onTap: function (state, e) {
    const mouseEvent = e as never as MapboxDraw.MapMouseEvent
    // emulate 'move mouse' to update feature coords
    if (state.startPoint && this.onMouseMove) {
      this.onMouseMove(state, mouseEvent)
    }
    // emulate onClick
    if (this.onClick) {
      this.onClick(state, mouseEvent)
    }
  },
  // Whenever a user clicks on the map, Draw will call `onClick`
  onClick: function (state, e) {
    // if state.startPoint exist, means its second click
    // change to  direct_select mode
    if (
      state.startPoint &&
      state.startPoint[0] !== e.lngLat.lng &&
      state.startPoint[1] !== e.lngLat.lat
    ) {
      this.updateUIClasses({ mouse: 'pointer' })
      state.endPoint = [e.lngLat.lng, e.lngLat.lat]
      this.changeMode('direct_select', { featureId: state.rectangle.id })
    }
    // on first click, save clicked point coords as starting for  rectangle
    state.startPoint = [e.lngLat.lng, e.lngLat.lat]
  },
  onMouseMove: function (state, e) {
    // if startPoint, update the feature coordinates, using the bounding box concept
    // we are simply using the startingPoint coordinates and the current Mouse Position
    // coordinates to calculate the bounding box on the fly, which will be our rectangle
    if (state.startPoint) {
      state.rectangle.updateCoordinate(
        '0.0',
        state.startPoint[0],
        state.startPoint[1]
      ) // minX, minY - the starting point
      state.rectangle.updateCoordinate(
        '0.1',
        e.lngLat.lng,
        state.startPoint[1]
      ) // maxX, minY
      state.rectangle.updateCoordinate('0.2', e.lngLat.lng, e.lngLat.lat) // maxX, maxY
      state.rectangle.updateCoordinate(
        '0.3',
        state.startPoint[0],
        e.lngLat.lat
      ) // minX,maxY
      state.rectangle.updateCoordinate(
        '0.4',
        state.startPoint[0],
        state.startPoint[1]
      ) // minX,minY - ending point (equals to starting point)
    }
  },
  // Whenever a user clicks on a key while focused on the map, it will be sent here
  onKeyUp: function (state, e) {
    if (e.keyCode === 27) {
      return this.changeMode('direct_select')
    }
  },
  onStop: function (state) {
    this.updateUIClasses({ mouse: 'none' })
    this.activateUIButton()

    // check to see if we've deleted this feature
    if (this.getFeature(state.rectangle.id) === undefined) {
      return
    }

    // remove last added coordinate
    state.rectangle.removeCoordinate('0.4')
    if (state.rectangle.isValid()) {
      this.map.fire('draw.create', {
        features: [state.rectangle.toGeoJSON()]
      })
    } else {
      if (state.rectangle) {
        const id: string = state.rectangle.id
        this.deleteFeature(id, { silent: true })
        this.changeMode('simple_select', {}, { silent: true })
      }
    }
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (geojson.type === 'Feature') {
      if (geojson.properties) {
        const isActivePolygon = geojson.properties.id === state.rectangle.id
        geojson.properties.active = isActivePolygon
          ? 'true'
          : 'false'
        if (!isActivePolygon) {
          return display(geojson)
        }
      }
    }

    // Only render the rectangular polygon if it has the starting point
    if (!state.startPoint) {
      return
    }
    return display(geojson)
  },
  onTrash: function (state) {
    const id: string = state.rectangle.id
    this.deleteFeature(id, { silent: true })
    this.changeMode('simple_select')
  }
}

export default DrawRectangle
