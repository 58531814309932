import { Text, createStyles } from '@mantine/core'

interface Props {
  fuelCapacityLiter: number
  fuelLevelPercent: number
}

const useStyles = createStyles(() => ({
  generalTextStyles: {
    fontSize: 14,
    lineHeight: '17px',
    position: 'absolute'
  }
}))

export default function MarkersAnnotationRight ({
  fuelCapacityLiter,
  fuelLevelPercent
}: Props) {
  const { classes } = useStyles()

  return (
    <>
      <Text
        size={12}
        weight={400}
        key={fuelCapacityLiter * 0.95}
        className={classes.generalTextStyles}
        style={{
          bottom: (95 - 2.5) + '%',
          color: '#666'
        }}
      >
        95% •
        {' '}
        {fuelCapacityLiter * 0.95}
        {' '}
        L
      </Text>
      <Text
        size={12}
        weight={400}
        key={fuelLevelPercent}
        className={classes.generalTextStyles}
        style={{
          bottom: (fuelLevelPercent - 2.5) + '%',
          color: '#212121',
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {fuelLevelPercent}
        % •
        {' '}
        {Math.floor(fuelCapacityLiter * (fuelLevelPercent / 100))}
        {' '}
        L
      </Text>
    </>
  )
}
