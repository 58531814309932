export function convertMillisecondsToHumanReadableTime (ms: number) {
  let seconds = Math.floor(ms / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  hours = hours % 24
  seconds = seconds % 60
  minutes = minutes % 60

  const hoursString = hours < 10 ? '0' + hours : hours
  const minutesString = minutes < 10 ? '0' + minutes : minutes
  const secondsString = seconds < 10 ? '0' + seconds : seconds

  return `${hoursString}:${minutesString}:${secondsString}`
}
