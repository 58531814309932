import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  LoadingOverlay,
  Paper
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentGroups } from '@venturi-io/api/src/config/agentGroup'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import { useDebouncedValue } from '@mantine/hooks'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateAgentGroup from './Create'

export default function AgentGroups () {
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined >()
  const [debouncedSearch] = useDebouncedValue(search, 700)
  const agentGroups = usePaginatedApi(getAgentGroups)
  const { token } = useUser()

  const loadAgentGroups = async (page?: number, size?: number): Promise<void> => {
    void agentGroups
      .fetch({
        page: page ?? agentGroups.page,
        size: size ?? agentGroups.size,
        search: debouncedSearch
      }, token)
  }
  useEffect(() => {
    void loadAgentGroups(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadAgentGroups()
  }, [agentGroups.page])

  return (
    <>
      <LoadingOverlay visible={agentGroups.loading} color="primary" />
      <Group
        position="right"
        spacing="xs"
        mb="lg"
      >
        <Button
          disabled={showCreate}
          title="Create Agent Group"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new Agent Group
        </Button>
      </Group>
      <Paper
        mt="lg"
        p="sm"
        shadow="sm"
        my={0}
      >
        <Grid grow>
          <Col span={4} md={2}>
            <TextInput
              radius="sm"
              placeholder="Search agent groups here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={agentGroups.loading}
        data={agentGroups.data.mapOrDefault(({ items }) => items, [])}
        getId={({ agentGroupId }) => agentGroupId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={agentGroups.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={agentGroups.page}
        onPageChange={agentGroups.setPage}
      />
      <CreateAgentGroup
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadAgentGroups}
      />
    </>
  )
}
