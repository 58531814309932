import { z } from 'zod'
import { paginated, type Req, type PaginatedRequest } from '../shared'
import { createStatefulApi, createStatelessApi } from '../configure'

export const userGroup = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string()
})

const allUserGroups = paginated.extend({
  items: z.array(userGroup)
})

interface UserGroupIdRequest extends Req {
  userGroupId: number
}

export type UserGroup = z.infer<typeof userGroup>

interface GetUserGroupsRequest extends PaginatedRequest {
  name?: string
}

export const getUserGroups = createStatelessApi<GetUserGroupsRequest, typeof allUserGroups>(
  'user',
  '/user-groups',
  { method: 'GET', type: 'path' },
  allUserGroups
)

export const getUserGroup = createStatelessApi<UserGroupIdRequest, typeof userGroup>(
  'user',
  '/user-groups/:userGroupId',
  { method: 'GET', type: 'path' },
  userGroup
)

interface CreateUserGroupRequest extends Req {
  name: string
  description: string
}

export const createUserGroup = createStatefulApi<CreateUserGroupRequest, typeof userGroup>(
  'user',
  '/user-groups',
  { method: 'POST' },
  userGroup
)

interface UpdateUserGroupRequest extends UserGroupIdRequest {
  name: string
  description: string
}

export const updateUserGroup = createStatefulApi<UpdateUserGroupRequest, typeof userGroup>(
  'user',
  '/user-groups/:userGroupId',
  { method: 'PUT' },
  userGroup
)

export const deleteUserGroup = createStatefulApi<UserGroupIdRequest, z.ZodBoolean>(
  'user',
  '/user-groups/:userGroupId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

interface UserAssignmentRequest extends UserGroupIdRequest {
  userIds: number[]
}

export const assignUsersToGroup = createStatefulApi<UserAssignmentRequest, z.ZodBoolean>(
  'user',
  '/user-groups/:userGroupId/users',
  { method: 'POST' },
  z.boolean()
)

export const removeUsersFromGroup = createStatefulApi<UserAssignmentRequest, z.ZodBoolean>(
  'user',
  '/user-groups/:userGroupId/users',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
