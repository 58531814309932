import { type ReactElement } from 'react'
import {
  type MantineColor,
  createStyles,
  Badge,
  Stack,
  Text
} from '@mantine/core'

interface Props {
  title: string
  value: string
  color: MantineColor
  textColor: MantineColor
  icon: ReactElement | null
  expanded?: boolean
  showTitle?: boolean
}

interface StyleParams {
  color: MantineColor
  textColor: MantineColor
}

const useStyles = createStyles((_, { color, textColor }: StyleParams) => ({
  root: {
    height: 32,
    padding: '0px 8px',
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'normal',
    textTransform: 'capitalize',
    color: textColor,
    letterSpacing: 0,
    background: color,
    userSelect: 'none'
  },
  leftSection: {
    display: 'flex'
  }
}))

export default function StatusBadge ({
  title,
  value,
  color,
  textColor,
  icon,
  expanded = false,
  showTitle = false
}: Props) {
  const { classes } = useStyles({ color, textColor })

  return (
    <Stack spacing={0}>
      {showTitle && (
        <Text
          color="gray.6"
          size={10}
          weight={500}
          transform="capitalize"
        >
          {title}
        </Text>
      )}
      <Badge
        classNames={classes}
        color={color}
        size="md"
        radius={4}
        variant="filled"
        leftSection={icon}
      >
        {expanded ? value : ''}
      </Badge>
    </Stack>
  )
}
