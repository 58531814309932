import { SegmentedControl } from '@mantine/core'

const items = {
  '1 hour': {
    name: '1h',
    timeBucket: '5 minutes',
    noOfRecords: 12
  },
  '4 hours': {
    name: '4h',
    timeBucket: '30 minutes',
    noOfRecords: 8
  },
  '12 hours': {
    name: '12h',
    timeBucket: '1 hour',
    noOfRecords: 12
  },
  '24 hours': {
    name: '24h',
    timeBucket: '2 hours',
    noOfRecords: 12
  },
  '3 days': {
    name: '3d',
    timeBucket: '6 hours',
    noOfRecords: 12
  },
  '7 days': {
    name: '7d',
    timeBucket: '1 day',
    noOfRecords: 7
  },
  '30 days': {
    name: '30d',
    timeBucket: '3 days',
    noOfRecords: 10
  },
  '90 days': {
    name: '90d',
    timeBucket: '1 week',
    noOfRecords: 12
  }
}

type Items = typeof items

export type ItemKeys = keyof Items

export interface ChosenBucket<K extends ItemKeys> {
  item: K
  data: Items[K]
}

export interface Props {
  chosenBucket: ChosenBucket<ItemKeys>
  updateBucket: (input: ChosenBucket<ItemKeys>) => void
}

export default function SegmentedTimePeriodSelector ({ chosenBucket, updateBucket }: Props) {
  return (
    <SegmentedControl
      data={Object.keys(items).map(key => ({
        value: key,
        label: items[key as ItemKeys].name
      }))}
      value={chosenBucket.item}
      onChange={(key: ItemKeys) => updateBucket({
        item: key,
        data: items[key]
      })}
    />
  )
}
