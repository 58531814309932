import { useEffect } from 'react'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { IconBatteryCharging2, IconBolt, IconDroplet, IconGauge } from '@tabler/icons-react'
import { msToDhms } from 'src/utils/dates'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import AssetTemplate from 'src/AssetTemplate'
import {
  computeRelativePercentage,
  convertStrToFloat,
  findSensor,
  getSensorValue
} from 'src/AssetTemplate/shared'
import NotFound from 'src/Router/NotFound'
import { type DiagnosticMetricItem } from 'src/AssetTemplate/Components/DiagnosticMetric'
import Statuses from './Statuses'
import EngineStatus from './EngineStatus'
import FuelStatus from './FuelStatus'
import PowerStatus from './PowerStatus'
import Analytics from './Analytics'

interface RouteParams extends Record<string, string | undefined> {
  id: string
}

export default function Dashboard () {
  const { id } = useParams<RouteParams>()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const agent = agentDetails.data.mapOrDefault(data => data, null)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  useEffect(() => {
    void agentDetails.fetch({ agentId: parseInt(id) }, token)
  }, [])

  if (agent === null) {
    return <div></div>
  }

  // TODO: Add skeleton while API is loading

  const {
    agentId,
    agentName,
    assetType,
    metadata,
    geoLocation,
    sensors,
    lastSeenTime,
    connectionStatus
  } = agent

  // Engine sensors
  const engineRuntime = findSensor('ENGINE_RUN_TIME', sensors)
  const totalRuntimeMs = convertStrToFloat(engineRuntime?.currentValue) * 1000
  const totalRuntime = msToDhms(totalRuntimeMs)
  const engineState = findSensor('ENGINE_OPERATE_MODE', sensors)
  const controlMode = findSensor('CONTROL_MODE', sensors)
  const engineSpeed = findSensor('ENGINE_SPEED', sensors)
  const engineBatteryVoltage = findSensor('ENGINE_BATTERY_VOLTAGE', sensors)
  const coolantTemperature = findSensor('COOLANT_TEMPERATURE', sensors)
  const oilPressure = findSensor('OIL_PRESSURE', sensors)

  // Fuel sensors
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)
  const fuelCapacity = findSensor('FUEL_CAPACITY', sensors)
  const fuelLevelPercent = fuelLevel
    ? Math.floor(computeRelativePercentage(
      convertStrToFloat(fuelLevel.currentValue),
      fuelLevel.minValue,
      fuelLevel.maxValue
    ))
    : 0
  const fuelConsumptionLiter = convertStrToFloat(fuelConsumption?.currentValue)
  const fuelCapacityLiter = convertStrToFloat(fuelCapacity?.currentValue)
  const fuelRemainingPercent = 100 - fuelLevelPercent
  const fuelLevelLiter = fuelCapacityLiter * (fuelRemainingPercent / 100)

  // Power sensors
  const powerLevel = findSensor('POWER_LEVEL_KVA', sensors)
  const powerLevelKva = convertStrToFloat(powerLevel?.currentValue)
  const powerCapacity = findSensor('POWER_CAPACITY', sensors)
  const powerCapacityKva = convertStrToFloat(powerCapacity?.currentValue)
  const powerLevelPercent = Math.floor(powerCapacityKva > 0
    ? powerLevelKva / powerCapacityKva
    : 0)
  const outputPower = findSensor('POWER_LEVEL_KWH', sensors)
  const powerFactor = findSensor('POWER_FACTOR_THREE_PHASE', sensors)
  const powerFrequency = findSensor('POWER_FREQUENCY', sensors)
  const voltageLNA = findSensor('GENERATOR_L1N_VOLTAGE', sensors)
  const voltageLNB = findSensor('GENERATOR_L2N_VOLTAGE', sensors)
  const voltageLNC = findSensor('GENERATOR_L3N_VOLTAGE', sensors)
  const voltageLLA = findSensor('GENERATOR_L1L2_VOLTAGE', sensors)
  const voltageLLB = findSensor('GENERATOR_L1L3_VOLTAGE', sensors)
  const voltageLLC = findSensor('GENERATOR_L3L1_VOLTAGE', sensors)
  const voltageCurrentA = findSensor('GENERATOR_L1_CURRENT', sensors)
  const voltageCurrentB = findSensor('GENERATOR_L2_CURRENT', sensors)
  const voltageCurrentC = findSensor('GENERATOR_L3_CURRENT', sensors)

  // Statuses
  const statusEngineState = (
    typeof engineState?.currentValue === 'undefined' ||
    engineState?.currentValue.toLowerCase().includes('stop') ||
    engineState?.currentValue.toLowerCase().includes('off')
  )
    ? 'Stopped'
    : 'Running'
  const statusControlMode = (
    typeof controlMode?.currentValue === 'undefined' ||
    controlMode?.currentValue.toLowerCase().includes('stop') ||
    controlMode?.currentValue.toLowerCase().includes('off')
  )
    ? 'Stopped'
    : controlMode?.currentValue?.toLowerCase().includes('auto')
      ? 'Auto'
      : 'Other'
  const statusConnectivity = connectionStatus === 'ONLINE'
    ? 'CONNECTED'
    : 'DISCONNECTED'

  const diagnosticMetrics: DiagnosticMetricItem[] = [
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Fuel Level',
      icon: <IconDroplet size={16} />,
      value: fuelLevelPercent,
      displayValue: fuelLevelPercent.toString(),
      displayValueUnit: '%',
      label: `${fuelLevelLiter} L / ${fuelCapacityLiter} L`
    },
    {
      sensorInstanceId: powerLevel?.sensorInstanceId ?? -1,
      name: 'Power Capacity',
      icon: <IconBolt size={16} />,
      value: powerLevelPercent,
      displayValue: powerLevelPercent.toString(),
      displayValueUnit: '%',
      label: `${powerLevelKva} / ${powerCapacityKva} kVa`
    },
    {
      sensorInstanceId: engineSpeed?.sensorInstanceId ?? -1,
      name: 'Engine Speed',
      icon: <IconGauge size={16} />,
      value: convertStrToFloat(engineSpeed?.currentValue),
      displayValue: getSensorValue(engineSpeed, true, false),
      displayValueUnit: engineSpeed?.unit ?? ''
    },
    {
      sensorInstanceId: engineBatteryVoltage?.sensorInstanceId ?? -1,
      name: 'Battery Voltage',
      icon: <IconBatteryCharging2 size={16} />,
      value: convertStrToFloat(engineBatteryVoltage?.currentValue),
      displayValue: getSensorValue(engineBatteryVoltage, true, false),
      displayValueUnit: engineBatteryVoltage?.unit ?? ''
    }
  ]

  return (
    <AssetTemplate
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      metadata={metadata}
      geoLocation={geoLocation}
      diagnosticMetrics={diagnosticMetrics}
      statuses={(
        <Statuses
          alarms={sensors.filter(sensor => sensor.alarmStatus === 'ALARM').length}
          engineState={statusEngineState}
          mode={statusControlMode}
        />
      )}
      connectivityStatus={statusConnectivity}
      lastDataTransfer={lastSeenTime}
      totalAssetRuntime={totalRuntime}
    >
      <EngineStatus
        engineSpeed={engineSpeed}
        engineBatteryVoltage={engineBatteryVoltage}
        coolantTemperature={coolantTemperature}
        oilPressure={oilPressure}
      />
      <FuelStatus
        fuelLevel={fuelLevel}
        fuelLevelPercent={fuelLevelPercent}
        fuelRemainingPercent={fuelRemainingPercent}
        fuelLevelLiter={fuelLevelLiter}
        fuelCapacity={fuelCapacity}
        fuelCapacityLiter={fuelCapacityLiter}
        fuelConsumption={fuelConsumption}
        fuelConsumptionLiter={fuelConsumptionLiter}
      />
      <PowerStatus
        powerLevel={powerLevel}
        outputPower={outputPower}
        powerFactor={powerFactor}
        powerFrequency={powerFrequency}
        voltageLN={{
          a: voltageLNA,
          b: voltageLNB,
          c: voltageLNC
        }}
        voltageLL={{
          a: voltageLLA,
          b: voltageLLB,
          c: voltageLLC
        }}
        voltageCurrent={{
          a: voltageCurrentA,
          b: voltageCurrentB,
          c: voltageCurrentC
        }}
      />
      <Analytics agentId={agentId} sensors={sensors} />
    </AssetTemplate>
  )
}
