import { forwardRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  ActionIcon,
  Box,
  Group,
  Paper,
  Text,
  Tooltip
} from '@mantine/core'
import { IconWindowMaximize } from '@tabler/icons-react'
import CoreMap from 'src/Maps/CoreMap'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'

const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    overflow: 'clip'
  },
  header: {
    height: 48,
    padding: '12px 16px'
  },
  mapContainer: {
    height: 160
  },
  addressContainer: {
    height: 'auto',
    padding: '12px 16px'
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  geoLocation: AgentDetails['geoLocation']
}

const Location = forwardRef<HTMLDivElement, Props>(({ agentId, geoLocation }, ref) => {
  const { classes } = useStyles()
  const [address, setAddress] = useState('')

  const loadAddress = async () => {
    if (geoLocation) {
      const { longitude, latitude } = geoLocation
      const place = await reverseGeocode([longitude, latitude])
      setAddress(place)
    }
  }

  useEffect(() => {
    void loadAddress()
  }, [geoLocation])

  return (
    <Paper
      ref={ref}
      className={classes.container}
      p={0}
      radius={4}
      shadow="xs"
    >
      <Group className={classes.header} position="apart">
        <Text size={14} weight={600}>Location</Text>
        <Tooltip
          label="View in Map"
          position="right"
          transitionProps={{
            transition: 'pop',
            duration: 200
          }}
          withArrow
        >
          <ActionIcon
            component={Link}
            to={`/?agentId=${agentId}`}
            variant="subtle"
            size="xs"
            color="dark.2"
          >
            <IconWindowMaximize size={20} stroke={2} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <Box className={classes.mapContainer}>
        <CoreMap
          {...geoLocation}
          height="100%"
          width="100%"
          zoom={15}
          showLocationMarker
          canPan
          canZoom
        />
      </Box>
      <Box className={classes.addressContainer}>
        <Text size={12} weight={400}>
          {address}
        </Text>
      </Box>
    </Paper>
  )
})

export default Location
