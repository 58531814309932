import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { getUserGroups } from '@venturi-io/api/src/userManager/ui'
import { type UserGroup } from '@venturi-io/api/src/userManager/userGroup'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  setExternalUserGroupId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const userGroupToSelect = ({ id, name }: UserGroup): SelectItem => ({
  value: id.toString(),
  label: name
})

export default function SelectUserGroup ({
  defaultValue,
  value,
  label,
  placeholder,
  setExternalUserGroupId,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const userGroups = useApi(getUserGroups)
  const [userGroupId, setUserGroupId] = useState<Value>(null)
  const selectUserGroups = userGroups.data.mapOrDefault(data => data.map(userGroupToSelect), [])

  const loadUserGroups = () => {
    void userGroups.fetch({ orgId }, token)
  }

  const handleChange = useCallback((val: Value) => {
    setUserGroupId(val)

    if (setExternalUserGroupId) {
      setExternalUserGroupId(val)
    }

    if (onChange) {
      onChange(val)
    }
  }, [setExternalUserGroupId, onChange])

  useEffect(() => {
    if (defaultValue) {
      setUserGroupId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadUserGroups()
  }, [])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a user group'}
      data={selectUserGroups}
      defaultValue={defaultValue}
      value={value ?? userGroupId}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || userGroups.loading}
      error={error}
      {...props}
    />
  )
}
