import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction
} from 'react'

interface Meta {
  pageTitle: string
}

const defaultMeta: Meta = {
  pageTitle: ''
}

interface Context {
  meta: Meta
  setMeta: Dispatch<SetStateAction<Meta>>
}

const MetaContext = createContext<Context>({
  meta: defaultMeta,
  setMeta: () => null
})

interface Props {
  children: React.ReactNode
}

export function MetaProvider ({ children }: Props) {
  const [meta, setMeta] = useState<Meta>(defaultMeta)

  return (
    <MetaContext.Provider value={{ meta, setMeta }}>
      {children}
    </MetaContext.Provider>
  )
}

export function useMeta () {
  const state = useContext(MetaContext)

  return state
}
