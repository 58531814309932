import { memo, useEffect, useState } from 'react'
import {
  Container,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { getAgentStatistics } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { round } from 'src/utils/math'
import { truncateWithEllipsis } from 'src/utils/strings'
import { useElementSize } from '@mantine/hooks'
import { getResponsiveIconSize, useSharedStyles } from '../shared'

export interface Props {
  id: string
  agentId: string
  agentName: string
  name: string
  notes: string
  odometerInMeters: number
  passToParent?: (odometerInMeters: number) => void
}

const Odometer = ({
  name,
  agentId,
  agentName,
  odometerInMeters,
  passToParent
}: Props) => {
  const { colors } = useMantineTheme()
  const { classes } = useSharedStyles()
  const { token } = useUser()
  const { ref, width, height } = useElementSize()
  const agentStatistic = useApi(getAgentStatistics)
  const [meters, setMeters] = useState(0)

  const baseSize = 100
  const containerSize = width + height
  const iconSize = getResponsiveIconSize(width, baseSize)

  useEffect(() => {
    agentStatistic.data.ifJust(({ odometerInMeters }) => {
      if (passToParent) {
        passToParent(odometerInMeters ?? 0)
      }
      setMeters(odometerInMeters ?? 0)
    })
  }, [agentStatistic.data])

  useEffect(() => {
    if (agentId !== '' && agentId !== '0') {
      const req = {
        agentId: Number(agentId)
      }
      void agentStatistic.fetch(req, token)
      agentStatistic.startPolling(req, token, 30)
    }

    return () => {
      if (agentId !== '' && agentId !== '0') {
        agentStatistic.stopPolling()
      }
    }
  }, [agentId])

  useEffect(() => {
    setMeters(odometerInMeters)
  }, [odometerInMeters])

  return (
    <Container
      ref={ref}
      className={classes.itemContainer}
      bg="white"
      fluid
    >
      {!agentStatistic.loading && (
        <Title
          ml="md"
          mt="sm"
          order={3}
          sx={{
            alignSelf: 'start'
          }}
        >
          {name}
        </Title>
      )}
      {agentStatistic.loading && <Loader variant="bars" />}
      {!agentStatistic.loading && (
        <Flex
          align="center"
          justify="center"
          direction="column"
          sx={{
            height: '100%',
            width: '100%'
          }}
        >
          <Group>
            <FontAwesomeIcon
              size={iconSize}
              color={colors.primary[6]}
              icon={['fas', 'dashboard']}
            />
            <Stack spacing={0}>
              <Title size={`${containerSize / 24}px`} weight="normal">
                {round(meters / 1000)}
                &nbsp;km
              </Title>
              <Divider />
              <Title size={`${containerSize / 32}px`} weight="normal">{truncateWithEllipsis(agentName, 20)}</Title>
              <Text size={`${containerSize / 48}px`} color="dimmed">
                Odometer
              </Text>
            </Stack>
          </Group>
        </Flex>
      )}
    </Container>
  )
}

export default memo(Odometer)
