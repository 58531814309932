import { createStyles, useMantineTheme, Box, Switch } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type Mode = 'Light' | 'Dark'

interface Props {
  mode: Mode
  setMode: (mode: Mode) => void
}

const useStyles = createStyles({
  container: {
    position: 'absolute',
    top: 30,
    right: 30,
    padding: 10,
    zIndex: 1
  }
})

export default function ModeSwitch ({ mode, setMode }: Props) {
  const { classes } = useStyles()
  const { colors } = useMantineTheme()
  const lightModeOn = mode === 'Light'

  return (
    <Box className={classes.container}>
      <Switch
        size="lg"
        color="dark.4"
        onLabel={(
          <FontAwesomeIcon
            icon={['far', 'sun-alt']}
            color={colors.yellow[4]}
            size="2x"
          />
        )}
        offLabel={(
          <FontAwesomeIcon
            icon={['far', 'moon-stars']}
            color={colors.blue[5]}
            size="2x"
          />
        )}
        checked={lightModeOn}
        onChange={() => (
          setMode(
            lightModeOn
              ? 'Dark'
              : 'Light'
          )
        )}
      />
    </Box>
  )
}
