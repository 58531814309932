import { Stack, Box, Text } from '@mantine/core'
import convert from 'convert-units'

interface Props {
  featureArea: number
}

export default function AreaDetails ({ featureArea }: Props) {
  return (
    <Box px="xs" pb="sm">
      <Stack spacing="xs">
        <div>
          <Text size="xs" weight={500}>Square Meters</Text>
          <Text size="xs">{featureArea}</Text>
        </div>
        <div>
          <Text size="xs" weight={500}>Square Kilometers</Text>
          <Text size="xs">{convert(featureArea).from('m2').to('km2')}</Text>
        </div>
        <div>
          <Text size="xs" weight={500}>Square Feet</Text>
          <Text size="xs">{convert(featureArea).from('m2').to('ft2')}</Text>
        </div>
        <div>
          <Text size="xs" weight={500}>Acres</Text>
          <Text size="xs">{convert(featureArea).from('m2').to('ac')}</Text>
        </div>
        <div>
          <Text size="xs" weight={500}>Square Miles</Text>
          <Text size="xs">{convert(featureArea).from('m2').to('mi2')}</Text>
        </div>
      </Stack>
    </Box>
  )
}
