import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { getSites, type Site } from '@venturi-io/api/src/userManager/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  setExternalSiteId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const siteToSelect = ({ siteId, name }: Site): SelectItem => ({
  value: siteId.toString(),
  label: name
})

export default function SelectSite ({
  defaultValue,
  value,
  label,
  placeholder,
  setExternalSiteId,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const sites = useApi(getSites)
  const [siteId, setSiteId] = useState<Value>(null)
  const selectSites = sites.data.mapOrDefault(data => data.map(siteToSelect), [])

  const loadSites = () => {
    void sites.fetch({ orgId }, token)
  }

  const handleChange = useCallback((val: Value) => {
    setSiteId(val)

    if (setExternalSiteId) {
      setExternalSiteId(val)
    }

    if (onChange) {
      onChange(val)
    }
  }, [setExternalSiteId, onChange])

  useEffect(() => {
    if (defaultValue) {
      setSiteId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadSites()
  }, [])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a site'}
      data={selectSites}
      defaultValue={defaultValue}
      value={value ?? siteId}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || sites.loading}
      error={error}
      {...props}
    />
  )
}
