import { z } from 'zod'
import dayjs from 'dayjs'
import { createStatelessApi } from '../configure'
import { type AgentIdRequest, type PaginatedRequest, paginated } from '../shared'

const transactionTypes = ['IN', 'OUT'] as const

export type TransactionType = typeof transactionTypes[number]

interface TransactionsRequest extends AgentIdRequest, PaginatedRequest {
  search?: string
  type?: TransactionType
  startTime?: string
  endTime?: string
}

// TANK
const tankTransaction = z.object({
  imported: z.string().transform(input => dayjs(input).toDate()),
  transactionId: z.number(),
  rfidTag: z.optional(z.string()),
  volume: z.number(),
  type: z.enum(transactionTypes),
  person: z.optional(z.string()),
  agent: z.optional(z.object({
    agentId: z.number(),
    agentName: z.string(),
    assetType: z.optional(z.string())
  }))
})
const tankTransactions = z.array(tankTransaction)
const paginatedTankTransactions = paginated.extend({
  items: tankTransactions
})

export type TankTransaction = z.infer<typeof tankTransaction>

export const getTankTransactions = createStatelessApi<TransactionsRequest, typeof paginatedTankTransactions>(
  'config',
  '/agents/:agentId/tank-transactions',
  { method: 'GET', type: 'path' },
  paginatedTankTransactions
)

// VEHICLE
const vehicleTransaction = z.object({
  imported: z.string().transform(input => dayjs(input).toDate()),
  transactionId: z.number(),
  rfidTag: z.optional(z.string()),
  volume: z.number(),
  person: z.optional(z.string()),
  registrationNumber: z.string(),
  odometerKilometres: z.number(),
  kmTravelled: z.optional(z.number()),
  agent: z.optional(z.object({
    agentId: z.number(),
    agentName: z.string(),
    assetType: z.optional(z.string())
  }))
})
const vehicleTransactions = z.array(vehicleTransaction)
const paginatedVehicleTransactions = paginated.extend({
  items: vehicleTransactions
})

export type VehicleTransaction = z.infer<typeof vehicleTransaction>

export const getVehicleTransactions = createStatelessApi<TransactionsRequest, typeof paginatedVehicleTransactions>(
  'config',
  '/agents/:agentId/vehicle-transactions',
  { method: 'GET', type: 'path' },
  paginatedVehicleTransactions
)
