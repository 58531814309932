import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import {
  type PaginatedRequest,
  type GeoZoneRuleIdRequest,
  type OrgIdRequest,
  paginated
} from '../shared'

export const geoZoneRuleTypes = [
  'LOCATION',
  'SPEED_EXCEEDS',
  'ENTER_ZONE_TIMEFRAME',
  'LEAVE_ZONE_TIMEFRAME'
] as const

export const geoZoneRule = z.object({
  geoZoneRuleId: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  type: z.string(),
  orgId: z.optional(z.number()),
  numericValue: z.optional(z.number()),
  editable: z.boolean(),
  quietMode: z.optional(z.boolean()),
  startTime: z.optional(z.string()),
  endTime: z.optional(z.string()),
  timezone: z.optional(z.string()),
  startDay: z.optional(z.string()),
  endDay: z.optional(z.string()),
  geoZoneActions: z.optional(z.array(z.string()))
})

export type GeoZoneRule = z.infer<typeof geoZoneRule>

const allGeoZoneRules = paginated.extend({
  items: z.array(geoZoneRule)
})

interface GeoZoneRuleRequest extends OrgIdRequest {
  name: string
  description?: string
  type: string
  numericValue?: number
  startTime?: string
  endTime?: string
  timezone?: string
  startDay?: string
  endDay?: string
  quietMode?: boolean
}

interface AllGeoZoneRulesRequest extends PaginatedRequest, OrgIdRequest {
  search?: string
}
export const getGeoZoneRules = createStatelessApi<AllGeoZoneRulesRequest, typeof allGeoZoneRules>(
  'config',
  '/org/:orgId/geo/zone-rules',
  { method: 'GET', type: 'path' },
  allGeoZoneRules
)

export const getGeoZoneRule = createStatelessApi<GeoZoneRuleIdRequest & OrgIdRequest, typeof geoZoneRule>(
  'config',
  '/org/:orgId/geo/zone-rule/:geoZoneRuleId',
  { method: 'GET', type: 'path' },
  geoZoneRule
)

export const createGeoZoneRule = createStatefulApi<GeoZoneRuleRequest, typeof geoZoneRule>(
  'config',
  '/org/:orgId/geo/zone-rule',
  { method: 'POST' },
  geoZoneRule
)

export const updateGeoZoneRule = createStatefulApi<GeoZoneRuleRequest & GeoZoneRuleIdRequest, typeof geoZoneRule>(
  'config',
  '/org/:orgId/geo/zone-rule/:geoZoneRuleId',
  { method: 'PUT' },
  geoZoneRule
)

export const deleteGeoZoneRule = createStatefulApi<GeoZoneRuleIdRequest & OrgIdRequest, z.ZodBoolean>(
  'config',
  '/org/:orgId/geo/zone-rule/:geoZoneRuleId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
