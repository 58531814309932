import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { type UserGroup } from '@venturi-io/api/src/userManager/userGroup'
import TextTooltip from 'src/Layout/TextTooltip'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import ActionButton from 'src/ActionButton'

const Row = ({ id, name, description }: UserGroup & ListRow) => {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(name)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(description)}
          maxLength={textMaxLength}
        >
          {parseEmpty(description)}
        </TextTooltip>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => {
            navigate(`/settings/user-groups/edit/${id}`)
          }}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}

export default Row
