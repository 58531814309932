import { memo, useCallback, useMemo } from 'react'
import { Accordion, Button, Stack } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import { useDebouncedValue, useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import SelectGeozone from 'src/Input/MultiSelect/MultiSelectGeozone'
import ItemPreview from '../shared/ItemPreview'
import { type Props as SpeedAlertProps } from '.'
import SpeedAlerts from '.'

interface Props {
  initialValues: SpeedAlertProps
  onSubmit: (properties: SpeedAlertProps) => void
}
const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: SpeedAlertProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        geozoneIds: [],
        bucket: {
          item: '7 days',
          data: {
            timeBucket: '1 hour',
            noOfRecords: 24
          }
        }
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'please specify name'
          : null
      )
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  const [debouncedFormValues] = useDebouncedValue(form.values, 350)

  return (
    <>
      <ItemPreview iconName="frame" previewItem >
        <SpeedAlerts {...debouncedFormValues} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectGeozone
                    label="Geozone"
                    required
                    searchable
                    {...form.getInputProps('data.geozoneIds')}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>

    </>
  )
}

export default memo(Properties)
