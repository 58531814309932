import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Group, Paper, Stack } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type User } from '@venturi-io/api/src/userManager/user'
import { removeUsersFromGroup } from '@venturi-io/api/src/userManager/userGroup'
import { type Actions, type ExtraData } from '.'

const Card = ({ actions, extra, ...user }: User & ListRow<Actions, ExtraData>) => {
  const { token } = useUser()
  const navigate = useNavigate()
  const { orgUserId, firstName, lastName } = user
  const { userGroupId } = extra
  const { loadUsers } = actions
  const [showRemove, setShowRemove] = useState(false)
  const removeFromGroup = useApi(removeUsersFromGroup)

  const handleRemoveFromGroup = useCallback(() => {
    void removeFromGroup
      .fetch({
        userGroupId,
        userIds: [orgUserId]
      }, token)
      .finally(() => { void loadUsers() })
  }, [token, orgUserId, userGroupId])

  return (
    <>
      <Paper p="lg" my={10} shadow="md">
        <Stack>
          <Group position="apart" align="start">
            <TextGroup label="ID" value={orgUserId.toString()} isKey />
            <Group spacing="sm">
              <ActionIcon
                title="Edit"
                color="green"
                variant="filled"
                onClick={() => {
                  navigate(`/settings/user-group/${orgUserId}`)
                }}
              >
                <FontAwesomeIcon icon={['fas', 'pencil']} color="white" />
              </ActionIcon>
              <ActionIcon
                title="Remove"
                color="red"
                variant="filled"
                onClick={() => setShowRemove(true)}
                disabled={removeFromGroup.loading}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={['fas', 'square-minus']}
                  color="white"
                />
              </ActionIcon>
            </Group>
          </Group>
          <TextGroup
            label="Name"
            value={firstName + ' ' + lastName}
            maxLength={25}
          />
        </Stack>
      </Paper>
      <ConfirmModal
        type="delete"
        opened={showRemove}
        title={`Removing "${firstName} ${lastName}" from this group`}
        confirmLabel="Remove"
        loading={removeFromGroup.loading}
        question="Are you sure you want to remove this user from this group? This cannot be undone."
        onClose={() => setShowRemove(false)}
        onCancel={() => setShowRemove(false)}
        onConfirm={handleRemoveFromGroup}
      />
    </>
  )
}

export default Card
