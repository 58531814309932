import { Text, View } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'

interface Props {
  report: Report
  data: Array<Record<string, string | null>>
}

interface TitleProps {
  report: Report
}
const Title = ({ report }: TitleProps) => (
  <View>
    <Text style={reportStyles.title}>{report.name}</Text>
  </View>
)

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  report: Report
}
const Details = ({ report }: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Report type"
      value={report.reportType.name}
    />
  </View>
)

interface Cell {
  value: string | null
}

const THCell = ({ value }: Cell) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Cell) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Min Read" />
    <THCell value="Date Collect" />
    <THCell value="Max Read" />
    <THCell value="Avg Read" />
  </View>
)

const TableRow = ({ data }: { data: Record<string, string | null> }) => (
  <View style={reportStyles.group}>
    {Object.values(data).map(value => (
      <TDCell key={value} value={value} />
    ))}
  </View>
)

interface TableBodyProps {
  data: Array<Record<string, string | null>>
}
const TableBody = ({ data }: TableBodyProps) => data.map((item, idx) => (
  <TableRow key={idx} data={item} />
))

const Space = () => <View style={reportStyles.space} />

function ConditionSummary ({ report, data }: Props) {
  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Title report={report} />
      <Space />
      <Details report={report} />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default ConditionSummary
