import { useNavigate } from 'react-router-dom'
import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type GeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  geoZoneRuleId,
  name,
  description,
  type
}: GeoZoneRule & ListRow) {
  const navigate = useNavigate()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={geoZoneRuleId.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => {
              navigate(`/settings/geozone-rules/edit/${geoZoneRuleId}`)
            }}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Name"
            value={name}
            maxLength={textMaxLength}
          />
          <TextGroup label="Type" value={type} />
          <TextGroup
            label="Description"
            value={parseEmpty(description)}
            maxLength={30}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
