import { type ThemeIconProps, ThemeIcon } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import colors from '../colors'

interface Props {
  size: ThemeIconProps['size']
}

export default function AlarmIndicator ({ size }: Props) {
  return (
    <ThemeIcon size={size} p={4} color={colors.yellow}>
      <IconAlertTriangle size={24} color="black" />
    </ThemeIcon>
  )
}
