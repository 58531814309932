import { createStatelessApi } from '../configure'
import { type OrgIdRequest, type Req } from '../shared'
import { z } from 'zod'
import { user } from './user'
import { userGroup } from './userGroup'

// Users
const users = z.array(user)

interface GetUsersRequest extends Record<string, unknown> {
  search?: string
}

export const getUsers = createStatelessApi<GetUsersRequest, typeof users>(
  'user',
  '/org/:orgId/users/ui',
  { method: 'GET', type: 'path' },
  users
)

// User Groups
const userGroups = z.array(userGroup)

export const getUserGroups = createStatelessApi<Record<string, unknown>, typeof userGroups>(
  'user',
  '/user-groups/ui',
  { method: 'GET', type: 'path' },
  userGroups
)

// Sites
const site = z.object({
  siteId: z.number(),
  name: z.string()
})

export type Site = z.infer<typeof site>

const allSites = z.array(site)

export const getSites = createStatelessApi<OrgIdRequest, typeof allSites>(
  'user',
  '/org/:orgId/sites/ui',
  { method: 'GET', type: 'path' },
  allSites
)

const role = z.object({
  roleId: z.number(),
  name: z.string()
})

export type Role = z.infer<typeof role>

const allRoles = z.array(role)

export const getRoles = createStatelessApi<Req, typeof allRoles>(
  'user',
  '/roles/ui',
  { method: 'GET', type: 'path' },
  allRoles
)
