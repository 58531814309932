import { createContext, useEffect, type PropsWithChildren, useContext } from 'react'
import React, { useRef, useState } from 'react'

const initialize = (apiKey: string, onLoad: () => void) => {
  if (
    !!apiKey &&
    typeof window !== 'undefined' &&
    typeof window.zE === 'undefined'
  ) {
    const appendScript = function () {
      setTimeout(function () {
        const s = document.createElement('script')
        s.id = 'ze-snippet'
        s.type = 'text/javascript'
        s.async = true
        s.onload = onLoad

        s.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + apiKey

        const x = document.getElementsByTagName('script')[0]
        x?.parentNode.insertBefore(s, x)
      }, 0)
    }

    if (document.readyState === 'complete') {
      appendScript()
    } else if (window.attachEvent) {
      window.attachEvent('onload', appendScript)
    } else {
      window.addEventListener('load', appendScript, false)
    }
  }
}
interface ZendeskProviderProps {
  apiKey: string
  onOpen?: () => void
  onClose?: () => void
  onUnreadMessages?: (count: number) => void
}

interface ZendeskConversationField {
  id: string
  value: string | number | boolean
}

interface ZendeskContextValues {
  show: () => void
  hide: () => void
  open: () => void
  close: () => void
  setLocale: (newLocale: string) => void
  setZIndex: (newZIndex: number) => void
  setCookies: (isEnabled: boolean) => void
  setConversationFields: (
    conversationFields: ZendeskConversationField[],
  ) => void
  setConversationTags: (conversationTags: string[]) => void
  loginUser: (jwtToken: string) => void
  logoutUser: () => void
  isOpen: boolean
  unreadMessages: number | undefined
}

type ZendeskMethod =
  | 'show'
  | 'hide'
  | 'open'
  | 'close'
  | 'unreadMessages'
  | 'locale'
  | 'zIndex'
  | 'cookies'
  | 'conversationFields'
  | 'conversationTags'
  | 'loginUser'
  | 'logoutUser'

type Scope = 'messenger' | 'messenger:on' | 'messenger:set'

const isSSR = typeof window === 'undefined'

const ZendeskApi = (
  scope: Scope,
  method: ZendeskMethod,
  ...args: unknown[]
) => {
  if (!isSSR && !!window && window.zE) {
    return window.zE.apply(null, [scope, method, ...args])
  }
}

const ZendeskContext = createContext<ZendeskContextValues | undefined>(
  undefined
)

export const ZendeskProvider: React.FC<
PropsWithChildren<ZendeskProviderProps>
> = ({ apiKey, onOpen, onClose, onUnreadMessages, children }) => {
  const isRegisteredCb = useRef(false)

  const [isOpen, setIsOpen] = useState(false)
  const [unreadMessages, setUnreadMessages] = useState<number | undefined>(undefined)

  const registerCallback = () => {
    if (isRegisteredCb.current) {
      return
    }

    ZendeskApi('messenger:on', 'open', function () {
      setIsOpen(true)
      onOpen && onOpen()
    })

    ZendeskApi('messenger:on', 'close', function () {
      setIsOpen(false)
      onClose && onClose()
    })

    ZendeskApi(
      'messenger:on',
      'unreadMessages',
      function (unreadMessages: number) {
        setUnreadMessages(unreadMessages)
        onUnreadMessages && onUnreadMessages(unreadMessages)
      }
    )
    isRegisteredCb.current = true
  }

  useEffect(() => {
    initialize(apiKey, registerCallback)
  }, [apiKey])

  const show = React.useCallback(() => {
    ZendeskApi('messenger', 'show')
  }, [])

  const hide = React.useCallback(() => {
    ZendeskApi('messenger', 'hide')
  }, [])

  const open = React.useCallback(() => {
    ZendeskApi('messenger', 'open')
  }, [])

  const close = React.useCallback(() => {
    ZendeskApi('messenger', 'close')
  }, [])

  const setLocale = React.useCallback((newLocale: string) => {
    ZendeskApi('messenger:set', 'locale', newLocale)
  }, [])

  const setZIndex = React.useCallback((newZIndex: number) => {
    ZendeskApi('messenger:set', 'zIndex', newZIndex)
  }, [])

  const setCookies = React.useCallback((isEnabled: boolean) => {
    ZendeskApi('messenger:set', 'cookies', isEnabled)
  }, [])

  const setConversationFields = React.useCallback(
    (conversationFields: ZendeskConversationField[]) => {
      ZendeskApi('messenger:set', 'conversationFields', conversationFields)
    },
    []
  )

  const setConversationTags = React.useCallback(
    (conversationTags: string[]) => {
      ZendeskApi('messenger:set', 'conversationTags', conversationTags)
    },
    []
  )

  const loginUser = React.useCallback((jwtToken: string) => {
    ZendeskApi(
      'messenger',
      'loginUser',
      (callback: (token: string) => void) => {
        callback(jwtToken)
      }
    )
  }, [])

  const logoutUser = React.useCallback(() => {
    ZendeskApi('messenger', 'logoutUser')
  }, [])

  const initialProviderValue: ZendeskContextValues = {
    show,
    hide,
    open,
    close,
    setLocale,
    setZIndex,
    setCookies,
    setConversationFields,
    setConversationTags,
    loginUser,
    logoutUser,
    isOpen,
    unreadMessages
  }

  return (
    <ZendeskContext.Provider value={initialProviderValue}>
      {children}
    </ZendeskContext.Provider>
  )
}

export const useZendesk = () => {
  const context = useContext(ZendeskContext)
  if (context === undefined) {
    throw new Error('"useZendesk" must be used within "ZendeskProvider"')
  }
  return context
}
