import { memo, useCallback, useMemo } from 'react'
import {
  useMantineTheme,
  Accordion,
  Box,
  Button,
  Checkbox,
  Group,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import ColorInput from 'src/Input/ColorInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultStyles as defaultAutoGridStyles } from 'src/Charts/AutoGrid'
import SelectAgent from 'src/Input/Select/SelectAgent'
import SelectAgentSensor from 'src/Input/Select/SelectAgentSensor'
import ItemPreview from '../shared/ItemPreview'
import SensorStatus, { type Props as SensorStatusProps } from '.'

interface Props {
  initialValues: SensorStatusProps | null
  onSubmit: (properties: SensorStatusProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const { colors } = useMantineTheme()
  const focusTrapRef = useFocusTrap()

  const formValues: SensorStatusProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        agentId: -1,
        sensorInstanceId: -1
      },
      styles: {
        ...defaultAutoGridStyles,
        theme: {
          textColor: colors.gray[1],
          backgroundColor: {
            primary: colors.primary[4],
            secondary: colors.primary[6]
          }
        }
      }
    }
  ), [initialValues, colors])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const alignments = useMemo(() => (
    [
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-left']}
              color="primary"
            />
            <Text size="sm">Left</Text>
          </Group>
        ),
        value: 'flex-start'
      },
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-center']}
              color="primary"
            />
            <Text size="sm">Center</Text>
          </Group>
        ),
        value: 'center'
      },
      {
        label: (
          <Group spacing="sm" position="center">
            <FontAwesomeIcon
              icon={['fas', 'align-right']}
              color="primary"
            />
            <Text size="sm">Right</Text>
          </Group>
        ),
        value: 'flex-end'
      }
    ]
  ), [])

  const textSizes = useMemo(() => (
    [
      {
        label: 'Small',
        value: 'sm'
      },
      {
        label: 'Medium',
        value: 'md'
      },
      {
        label: 'Large',
        value: 'lg'
      }
    ]
  ), [])

  const hasSelectedAgent = useMemo(() => form.values.data.agentId > 0, [form.values.data.agentId])

  const hasSelectedSensorInstance = useMemo(() => (
    form.values.data.sensorInstanceId > 0
  ), [form.values.data.sensorInstanceId])

  const previewItem = useMemo(() => (
    hasSelectedAgent && hasSelectedSensorInstance
  ), [hasSelectedAgent, hasSelectedSensorInstance])

  const setFormDataValue = useCallback((
    key: keyof SensorStatusProps['data'],
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormStylesValue = useCallback((
    key: keyof SensorStatusProps['styles'],
    value: unknown
  ) => {
    form.setValues({
      ...form.values,
      styles: {
        ...form.values.styles,
        [key]: value
      }
    })
  }, [form.values.styles])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="sensor-on" previewItem={previewItem}>
        <SensorStatus {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectAgent
                    label="Agent"
                    value={form.values.data.agentId.toString()}
                    onChange={agentId => setFormDataValue('agentId', Number(agentId))}
                    required
                    searchable
                    error={form.errors.agentId}
                  />
                  {hasSelectedAgent && (
                    <SelectAgentSensor
                      agentId={form.values.data.agentId}
                      label="Sensor"
                      value={(
                        hasSelectedSensorInstance
                          ? form.values.data.sensorInstanceId.toString()
                          : null
                      )}
                      required
                      searchable
                      onChange={sensorInstanceId => setFormDataValue('sensorInstanceId', Number(sensorInstanceId))}
                      error={form.errors.sensorInstanceId}
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="styles">
              <Accordion.Control>Styles</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <Stack spacing="xs">
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Top"
                        placeholder="Padding Top"
                        value={form.values.styles.padding.top}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            top: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Bottom"
                        placeholder="Padding Bottom"
                        value={form.values.styles.padding.bottom}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            bottom: value
                          })
                        }}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Padding Left"
                        placeholder="Padding Left"
                        value={form.values.styles.padding.left}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            left: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Padding Right"
                        placeholder="Padding Right"
                        value={form.values.styles.padding.right}
                        onChange={value => {
                          setFormStylesValue('padding', {
                            ...form.values.styles.padding,
                            right: value
                          })
                        }}
                      />
                    </SimpleGrid>
                  </Stack>

                  <Box>
                    <Text size="xs">Align Text</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.align}
                      data={alignments}
                      onChange={value => setFormStylesValue('align', value)}
                    />
                  </Box>

                  <Box>
                    <Text size="xs">Text Size</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.textSize}
                      data={textSizes}
                      onChange={value => setFormStylesValue('textSize', value)}
                    />
                  </Box>

                  <ColorInput
                    name="textColor"
                    label="Text Color"
                    placeholder="Pick a color"
                    value={form.values.styles.theme?.textColor}
                    onChange={value => {
                      setFormStylesValue('theme', {
                        ...form.values.styles.theme,
                        textColor: value
                      })
                    }}
                  />
                  <Box>
                    <Text size="xs">Background Color</Text>
                    <SimpleGrid cols={2} spacing="md">
                      <ColorInput
                        name="backgroundColorPrimary"
                        placeholder="Primary"
                        value={form.values.styles.theme?.backgroundColor?.primary}
                        onChange={value => {
                          setFormStylesValue('theme', (
                            form.values.styles.theme?.backgroundColor
                              ? {
                                  ...form.values.styles.theme,
                                  backgroundColor: {
                                    ...form.values.styles.theme.backgroundColor,
                                    primary: value
                                  }
                                }
                              : {
                                  ...form.values.styles.theme,
                                  backgroundColor: {
                                    primary: value
                                  }
                                }
                          ))
                        }}
                      />
                      <ColorInput
                        name="backgroundColorSecondary"
                        placeholder="Secondary"
                        value={form.values.styles.theme?.backgroundColor?.secondary}
                        onChange={value => {
                          setFormStylesValue('theme', (
                            form.values.styles.theme?.backgroundColor
                              ? {
                                  ...form.values.styles.theme,
                                  backgroundColor: {
                                    ...form.values.styles.theme.backgroundColor,
                                    secondary: value
                                  }
                                }
                              : {
                                  ...form.values.styles.theme,
                                  backgroundColor: {
                                    secondary: value
                                  }
                                }
                          ))
                        }}
                      />
                    </SimpleGrid>
                  </Box>
                  <Checkbox
                    mt="sm"
                    checked={!form.values.styles.withoutTitle}
                    label="show Title"
                    onChange={event => setFormStylesValue('withoutTitle', !event.currentTarget.checked)}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
