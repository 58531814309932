import { useState } from 'react'
import { Text, Tooltip, type TextProps } from '@mantine/core'
import { truncateWithEllipsis } from 'src/utils/strings'

interface Props extends TextProps {
  value: string
  maxLength?: number
}

export default function TextTooltip ({ value, maxLength, ...props }: Props) {
  const [showTooltip, setShowTooltip] = useState(false)
  const isOpenTooltip = typeof maxLength !== 'undefined' &&
    showTooltip &&
    value.length > maxLength

  return (
    <Tooltip label={value} opened={isOpenTooltip} withArrow withinPortal>
      <Text
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        {...props}
      >
        {maxLength
          ? truncateWithEllipsis(value, maxLength)
          : value}
      </Text>
    </Tooltip>
  )
}
