import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType } from 'src/Assets/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const kwHour = findSensorByType('ACTIVE_THREE_PHASE', sensors)
  const Hz = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)
  const V = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)
  const A = findSensorByType('RMS_AVG_VALUE_VOLTAGE', sensors)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            to={`/assets/power-meter/${agentId}`}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <TextGroup
            label="KW Hour"
            value={parseEmpty(kwHour?.currentValue)}
          />
          <TextGroup
            label="Hz"
            value={parseEmpty(Hz?.currentValue)}
          />
          <TextGroup
            label="V"
            value={parseEmpty(V?.currentValue)}
          />
          <TextGroup
            label="A"
            value={parseEmpty(A?.currentValue)}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
