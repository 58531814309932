import React, { useEffect } from 'react'
import {
  ActionIcon,
  Button,
  Group,
  ScrollArea,
  Text
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePaginatedApi } from 'src/utils/useApi'
import { getVehicleTransactions } from '@venturi-io/api/src/config/fms'
import { useUser } from 'src/UserContext'
import Nothing from 'src/Nothing'
import Table from '../VehicleOverview/Transactions/Table'
import { MODULE_CONTENT } from './constants'

interface Props {
  agentId: number
  didChangeHeight?: (height: number, key: string) => void
}

export default function Transactions ({ agentId, didChangeHeight }: Props) {
  const { token } = useUser()
  const transactions = usePaginatedApi(getVehicleTransactions)

  useEffect(() => {
    void transactions.fetch({
      agentId,
      page: 1,
      size: 10,
      sort: 'imported',
      order: 'desc'
    }, token)
  }, [agentId])

  return (
    <>
      <Group position="apart">
        <Text transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.VEHICLE_TRANSACTIONS}
        </Text>
        <Button
          component={Link}
          to={`/assets/vehicle/${agentId}/transactions`}
          variant="transparent"
          leftIcon={(
            <ActionIcon color="primary" variant="transparent">
              <FontAwesomeIcon icon={['far', 'receipt']} size="lg" />
            </ActionIcon>
          )}
        >
          <Text size="sm" weight={500}>All Transactions</Text>
        </Button>
      </Group>
      <ScrollArea h={500}>
        {transactions.data.caseOf({
          Nothing: () => (
            <Nothing
              isLoading={transactions.loading}
              nothing={transactions.data.isNothing()}
            />
          ),
          Just: ({ items }) => (
            <Table
              transactions={items}
              didChangeHeight={didChangeHeight}
            />
          )
        })}
      </ScrollArea>
    </>
  )
}
