import { createStyles, Box, Stack, Text } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { getSensorValue } from 'src/AssetTemplate/shared'

const useStyles = createStyles(() => ({
  container: {
    margin: '0 auto',
    color: '#212121',
    padding: '32px 16px'
  }
}))

interface Props {
  powerLevel: Sensor | null
}

export default function PowerState ({ powerLevel }: Props) {
  const { classes } = useStyles()
  const apparentPower = getSensorValue(powerLevel, true)

  return (
    <Box className={classes.container}>
      <Stack spacing={4} align="center">
        <Text size={12} weight={500}>
          Apparent Power
        </Text>
        <Text size={20} weight={600}>
          {`${apparentPower} kVa`}
        </Text>
      </Stack>
    </Box>
  )
}
