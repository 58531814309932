import { Progress, SimpleGrid, Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { findSensorByType, secondsToDhms } from 'src/Assets/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import dayjs from 'dayjs'
import { computeRelativePercentage, convertStrToFloat } from 'src/AssetTemplate/shared'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const controlMode = findSensorByType('CONTROL_MODE', sensors)
  const engineState = findSensorByType('ENGINE_OPERATE_MODE', sensors)
  const engineRpm = findSensorByType('ENGINE_SPEED', sensors)
  const engineRuntime = findSensorByType('ENGINE_RUN_TIME', sensors)
  const fuelLevel = findSensorByType('FUEL_LEVEL', sensors)
  const batteryVoltage = findSensorByType('ENGINE_BATTERY_VOLTAGE', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  const fuelLevelPercent = fuelLevel
    ? Math.floor(computeRelativePercentage(
      convertStrToFloat(fuelLevel.currentValue),
      fuelLevel.minValue,
      fuelLevel.maxValue
    ))
    : 0

  // get readable format
  const readableRuntime = secondsToDhms(parseInt(engineRuntime?.currentValue ?? '0'))

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/genset/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(controlMode?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text
          size="sm"
          color={engineState?.currentValue === 'Running'
            ? 'green'
            : 'yellow'}
        >
          {parseEmpty(engineState?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(engineRpm?.currentValue)}
          {' '}
          RPM
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(readableRuntime)}
        </Text>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <SimpleGrid cols={1}>
          <Progress
            color={fuelLevelPercent < 33
              ? 'red'
              : 'green'}
            radius="sm"
            size="lg"
            value={fuelLevelPercent}
          />
          <Text size="sm">
            {fuelLevelPercent}
            %
          </Text>
        </SimpleGrid>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {batteryVoltage?.currentValue
            ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(lastSeenTime).fromNow()}
        </Text>
      </div>
    </div>
  )
}
