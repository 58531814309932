import { type Row as ListRow } from 'src/Layout/ActionList'
import { type CameraEvent } from '@venturi-io/api/src/config/cameraEvents'
import {
  Group,
  Modal,
  Paper,
  SimpleGrid,
  Stack
} from '@mantine/core'
import { IFrame } from 'src/Dashboard/Items'
import { useState } from 'react'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { parseEmpty } from 'src/utils/parseEmpty'

export default function Card ({ ...events }: CameraEvent & ListRow) {
  const {
    eventId,
    dateTime,
    startTime,
    endTime,
    length,
    eventType,
    tagId,
    poi,
    lpr,
    viewEvent
  } = events

  const [modalOpen, setModalOpen] = useState(false)
  const isOk = viewEvent === 'true'
  const color = isOk
    ? 'black'
    : 'gray'
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const iframeProperties = {
    name: `${tagId}`,
    // eslint-disable-next-line max-len
    url: 'https://command.verkada.com/embed.html#%7B%22entityId%22%3A%20%22d2a85500-841f-42f8-be77-e39f9b34002a%22%2C%20%22embedLinkId%22%3A%20%22348b2a42-8298-4544-90d2-20fd4890a768%22%2C%20%22domains%22%3A%20%22%2A.venturi.io%22%2C%20%22token%22%3A%20%22v2_6fd862f9e3a6c1d1530764de0b07b283%22%2C%20%22urlSD%22%3A%20%22https%3A//vstream.command.verkada.com/filter/transcode/v2_6fd862f9e3a6c1d1530764de0b07b283/d2a85500-841f-42f8-be77-e39f9b34002a/low_res/livevideo.m3u8%22%2C%20%22urlHD%22%3A%20%22https%3A//vfilter.command.verkada.com/filter/transcode/v2_6fd862f9e3a6c1d1530764de0b07b283/d2a85500-841f-42f8-be77-e39f9b34002a/high_res/livevideo.m3u8%22%7D',
    id: '',
    title: '',
    customStyle: {
      width: '100%',
      height: '300px'
    }
  }

  const handleOpenModal = () => {
    if (isOk) {
      setModalOpen(true)
    }
  }

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={eventId.toString()} isKey />
          <Group position="right">
            <Modal
              opened={modalOpen}
              onClose={() => setModalOpen(false)}
              title="View Live Feed"
              centered
            >
              <IFrame {...iframeProperties} />
            </Modal>
            <ActionButton
              label="Events"
              icon="camera-security"
              iconType="far"
              onClick={() => handleOpenModal()}
              actionColor="white"
              actionSize="xs"
              actionRadius="lg"
              iconColor={color}
              iconSize="lg"
            />
          </Group>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Date/Time"
            value={parseEmpty(dateTime)}
          />
          <TextGroup
            label="Start Time"
            value={parseEmpty(startTime)}
          />
          <TextGroup
            label="End Time"
            value={parseEmpty(endTime)}
          />
          <TextGroup
            label="Length (h:m:s)"
            value={parseEmpty(length)}
          />
          <TextGroup
            label="Event Type"
            value={parseEmpty(eventType)}
          />
          <TextGroup
            label="Tag ID"
            value={parseEmpty(tagId)}
            maxLength={textMaxLength}
          />
          <TextGroup
            label="POI"
            value={parseEmpty(poi)}
            maxLength={textMaxLength}
          />
          <TextGroup
            label="LPR"
            value={parseEmpty(lpr)}
            maxLength={textMaxLength}
          />
        </SimpleGrid>
      </Stack>
    </Paper>

  )
}
