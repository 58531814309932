import { useState, useEffect, useMemo } from 'react'
import {
  ActionIcon,
  Box,
  Paper,
  Popover,
  ScrollArea,
  Tooltip,
  createStyles
} from '@mantine/core'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getCurrentBreakpoint, getMantineBreakpointsInPx } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableRowNoData from 'src/Layout/TableRowNoData'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import dayjs, { type ManipulateType } from 'dayjs'
import { dateFormat, giveOffset } from 'src/utils/dates'
import { type SensorData, getSensorData } from '@venturi-io/api/src/collector/sensor'
import NotFound from 'src/Router/NotFound'
import { gridColumns } from '../shared'
import MissingSensorMessage from '../MissingSensorMessage'
import ModuleContainer from './ModuleContainer'
import { TimeBucketContext, defaultLayouts } from './data'
import SystemSensors from './SystemSensors'

const useStyle = createStyles(() => ({
  container: {
    position: 'relative'
  },
  systemContainer: {
    position: 'absolute',
    top: -40,
    right: 0
  }
}))

const ResponsiveGridLayout = WidthProvider(Responsive)

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

export default function PowerMeterDashboard () {
  const { classes } = useStyle()
  const agentDetails = useApi(getAgentDetails)
  const { token } = useUser()
  const initialBreakpoint = getCurrentBreakpoint()
  const allSensorsData = useApi(getSensorData)
  const [showSystem, setShowSystem] = useState(false)
  const [sensorData, setSensorData] = useState<SensorData[]>([])
  const [missingSensors, setMissingSensors] = useState<string[]>([])

  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '30 days',
    data: {
      timeBucket: '12 hours',
      noOfRecords: 60
    }
  })

  const { assetId } = useParams<RouteParams>()
  if (!assetId) {
    return <NotFound />
  }

  const breakpoints = useMemo(() => {
    return getMantineBreakpointsInPx()
  }, [])
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint)

  const onBreakpointChange = (breakpoint: string) => setCurrentBreakpoint(breakpoint)

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  useEffect(() => {
    const agentId = parseInt(assetId)

    void agentDetails
      .fetch({ agentId }, token)
      .finally(() => {
        agentDetails.startPolling({ agentId }, token, 60)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [])

  useEffect(() => {
    if (agent) {
      const time = bucket.item.split(' ')
      const req = {
        sensorInstanceIds: agent.sensors.map(sensor => sensor.sensorInstanceId),
        startTime: dayjs()
          .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
          .format(dateFormat),
        endTime: dayjs().format(dateFormat),
        timeBucket: bucket.data.timeBucket,
        timeZone: giveOffset() as '+10:00',
        noOfRecords: bucket.data.noOfRecords
      }

      void allSensorsData.fetch(req, token)
    }
  }, [bucket, agent])

  useEffect(() => {
    allSensorsData.data.ifJust(({ result: data }) => {
      setSensorData(data)
    })
  }, [allSensorsData.data])

  const handleUpdate = (input: ChosenBucket<ItemKeys>) => {
    setBucket(input)
  }

  return (
    <Box mt="lg" className={classes.container}>
      {missingSensors.length > 0 && (
        <MissingSensorMessage assetName={agent?.agentName} missingSensors={missingSensors} />
      )}
      <Popover width={480} position="bottom" withArrow shadow="md">
        <Box mx="xs" className={classes.systemContainer}>
          <Tooltip position="left" label="Other information">
            <Popover.Target>
              <ActionIcon
                color={showSystem ? 'primary' : 'gray'}
                size="xs"
                onClick={() => setShowSystem(!showSystem)}
              >
                <FontAwesomeIcon icon={['fas', 'microchip']} />
              </ActionIcon>
            </Popover.Target>
          </Tooltip>
        </Box>
        <Popover.Dropdown>
          <ScrollArea h={500}>
            {agentDetails.data.mapOrDefault(SystemSensors, <TableRowNoData message="" />)}
          </ScrollArea>
        </Popover.Dropdown>
      </Popover>
      {agentDetails.loading && <Loader />}
      {agent && (
        <TimeBucketContext.Provider
          value={{
            bucket,
            updateBucket: handleUpdate,
            sensorData,
            loading: allSensorsData.loading
          }}
        >
          <ResponsiveGridLayout
            className="layout"
            layouts={defaultLayouts}
            rowHeight={280}
            cols={gridColumns}
            breakpoints={breakpoints}
            onBreakpointChange={onBreakpointChange}
          >
            {currentBreakpoint && (
              defaultLayouts[currentBreakpoint]?.map(item => (
                <Paper
                  key={item.i}
                  p="md"
                  shadow="sm"
                  style={{
                    overflow: 'none'
                  }}
                >
                  <ModuleContainer
                    agent={agent}
                    contentKey={item.i}
                    setMissingSensors={setMissingSensors}
                  />
                </Paper>
              ))
            )}
          </ResponsiveGridLayout>
        </TimeBucketContext.Provider>
      )}
    </Box>
  )
}
