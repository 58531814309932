import { Col, Grid } from '@mantine/core'
import { getFullIncidentReport } from '@venturi-io/api/src/config/alarmHistory'
import dayjs from 'dayjs'
import { type ReactNode, useEffect } from 'react'
import { useParams } from 'react-router'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import Paper from 'src/Layout/Paper'
import { useMeta } from 'src/MetaContext'
import { useUser } from 'src/UserContext'
import { uiDateFormat } from 'src/utils/dates'
import { type ApiData, useApi } from 'src/utils/useApi'
import SummaryCol from '../SummaryCol'

function ReportRow ({
  detected,
  cleared,
  timeGap,
  minimum,
  unit,
  maximum,
  average,
  operator,
  setPoint
}: ApiData<typeof getFullIncidentReport>): ReactNode {
  return (
    <Grid>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Detected"
          item={dayjs(detected).format(uiDateFormat)}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Resolved"
          item={cleared
            ? dayjs(cleared.toDate()).format(uiDateFormat)
            : 'Active'}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Duration"
          item={timeGap}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Minimum during period"
          item={minimum}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Maximum during period"
          item={maximum}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Average during period"
          item={average}
          unit={unit}
        />
      </Col>
      <Col span={6} sm={3}>
        <SummaryCol
          title="Alarm threshold"
          item={`${operator}${setPoint}`}
        />
      </Col>
    </Grid>
  )
}

interface RouteParams extends Record<string, string | undefined> {
  incidentId: string
}

function Report () {
  const { incidentId } = useParams<RouteParams>()
  const incidentReport = useApi(getFullIncidentReport)
  const { meta, setMeta } = useMeta()
  const { token } = useUser()

  if (!incidentId) {
    return null
  }

  useEffect(() => {
    void incidentReport.fetch({ incidentId }, token)
  }, [incidentId])

  useEffect(() => {
    incidentReport
      .data
      .ifJust(({ name }) => {
        setMeta({ ...meta, pageTitle: `Incident: ${name}` })
      })
  }, [incidentReport.data])

  return (
    <Paper relative>
      <LoadingOverlay visible={incidentReport.loading} />
      {incidentReport.data.mapOrDefault(ReportRow, <div>Nothing to report</div>)}
    </Paper>
  )
}

export default Report
