import { useCallback, useState } from 'react'
import {
  createStyles,
  Col,
  Grid,
  Group,
  Paper,
  Stack
} from '@mantine/core'
import { type Dashboard, setFavouriteDashboard } from '@venturi-io/api/src/userManager/dashboard'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type ItemType } from '../Items'
import Controls from './Controls'
import DashboardDropdown from './DashboardDropdown'
import HomeBadge from './HomeBadge'
import EditModeBadge from './EditModeBadge'
import Favorite from './Favorite'
import HomeDashboardMessage from './HomeDashboardMessage'
import SelectDashboardMessage from './SelectDashboardMessage'

const useStyles = createStyles({
  select: {
    width: '73%'
  }
})

interface Props {
  selectedDashboard: Dashboard | null
  editMode: boolean
  onAddItem: (type: ItemType) => void
  onSaveLayout: () => void
  onResetLayout: () => void
  onDeleteDashboard: (dashboardId: Dashboard['dashboardId']) => void
  setShowCreateDashboard: (status: boolean) => void
  setSelectedDashboardId: (dashboardId: Dashboard['dashboardId'] | null) => void
  setSelectedDashboard: (dashboard: Dashboard | null) => void
  setEditMode: (status: boolean) => void
}

export default function Header ({
  selectedDashboard,
  editMode,
  onAddItem,
  onSaveLayout,
  onResetLayout,
  onDeleteDashboard,
  setShowCreateDashboard,
  setSelectedDashboardId,
  setSelectedDashboard,
  setEditMode
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const editFavoriteDashboard = useApi(setFavouriteDashboard)
  const [isLoadingDashboardOptions, setIsLoadingDashboardOptions] = useState(true)
  const [hasHomeDashboard, setHasHomeDashboard] = useState(false)

  const handleClickFavourite = useCallback((isFavourite: boolean) => {
    if (selectedDashboard) {
      setSelectedDashboard({
        ...selectedDashboard,
        isFavourite
      })

      void editFavoriteDashboard.fetch({ dashboardId: selectedDashboard.dashboardId, isFavourite }, token)
    }
  }, [selectedDashboard])

  return (
    <Stack spacing="xs">
      <Paper p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} xs={7}>
            <Group spacing="xs">
              <DashboardDropdown
                className={classes.select}
                value={selectedDashboard?.dashboardId?.toString() ?? null}
                onChange={dashboardId => {
                  setSelectedDashboardId(Number(dashboardId))
                }}
                setIsLoadingDashboardOptions={setIsLoadingDashboardOptions}
                setHasHomeDashboard={setHasHomeDashboard}
                searchable
              />
              {!editMode && selectedDashboard?.isDefault && <HomeBadge />}
              {!editMode && selectedDashboard !== null && !selectedDashboard.isDefault && (
                <Favorite
                  isFavourite={selectedDashboard?.isFavourite ?? false}
                  onClick={handleClickFavourite}
                />
              )}
              {editMode && <EditModeBadge />}
            </Group>
          </Col>
          <Col span={12} xs={5}>
            <Controls
              selectedDashboard={selectedDashboard}
              setShowCreateDashboard={setShowCreateDashboard}
              editMode={editMode}
              onSaveLayout={onSaveLayout}
              onResetLayout={onResetLayout}
              setEditMode={setEditMode}
              onDeleteDashboard={onDeleteDashboard}
              onAddItem={onAddItem}
            />
          </Col>
        </Grid>
      </Paper>
      {!isLoadingDashboardOptions && !hasHomeDashboard && <HomeDashboardMessage />}
      {!isLoadingDashboardOptions && !selectedDashboard && <SelectDashboardMessage />}
    </Stack>
  )
}
