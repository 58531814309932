import { Component, type ErrorInfo, type ReactNode } from 'react'

interface Props {
  children: ReactNode
}

type ErrorState =
  | { hasError: false, error: null, errorInfo: null }
  | { hasError: true, error: Error, errorInfo: ErrorInfo }

export default class ErrorBoundary extends Component<Props, ErrorState> {
  constructor (props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  // static getDerivedStateFromError (error: Error) {
  //   return { hasError: true, error: error }
  // }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    console.debug(error, errorInfo)
    this.setState({
      hasError: true,
      error,
      errorInfo
    })
  }

  render () {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === 'production') {
        return <div><h1>Something has gone wrong.</h1></div>
      }

      return (
        <div style={{ padding: 20 }}>
          <h1>Something has gone wrong</h1>
          <pre>
            {JSON.stringify(this.state.error, Object.getOwnPropertyNames(this.state.error), 2)}
          </pre>
          <pre>
            {this.state.errorInfo.componentStack}
          </pre>
        </div>
      )
    }

    return this.props.children
  }
}
