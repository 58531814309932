import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAlarmActions } from '@venturi-io/api/src/analytics/alarmAction'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateAlarmAction from './Create'

export default function AlarmActions () {
  const { token, orgId } = useUser()
  const alarmActions = usePaginatedApi(getAlarmActions)
  const [showCreate, setShowCreate] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadAlarmActions = async (page?: number, size?: number): Promise<void> => {
    void alarmActions
      .fetch({
        orgId,
        page: page ?? alarmActions.page,
        size: size ?? alarmActions.size,
        search: debouncedSearch
      }, token)
  }

  useEffect(() => {
    void loadAlarmActions(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadAlarmActions()
  }, [alarmActions.page])

  return (
    <>
      <Group position="right" spacing="xs">
        <Button
          disabled={showCreate}
          title="Create New Alarm Action"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add New Alarm Action
        </Button>
      </Group>
      <Paper mt="lg" p="sm" shadow="sm">
        <Grid grow>
          <Col span={12} md={12}>
            <TextInput
              variant="unstyled"
              radius="xl"
              placeholder="Search here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={alarmActions.loading}
        data={alarmActions.data.mapOrDefault(({ items }) => items, [])}
        getId={({ id }) => id}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={alarmActions.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={alarmActions.page}
        onPageChange={alarmActions.setPage}
      />
      <CreateAlarmAction
        show={showCreate}
        close={() => {
          setShowCreate(false)
        }}
        onCreate={loadAlarmActions}
      />
    </>
  )
}
