import { Group, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import Alarms from './Alarms'

interface Props {
  alarms: number
}

export default function Statuses ({ alarms }: Props) {
  const { breakpoints: { xs } } = useMantineTheme()
  const isSmallerScreen = useMediaQuery(mq(xs, false))

  return (
    <Group
      position="center"
      align="center"
      spacing={isSmallerScreen
        ? 16
        : 8}
    >
      {alarms > 0 && (
        <Alarms
          count={alarms}
          showTitle={isSmallerScreen}
        />
      )}
    </Group>
  )
}
