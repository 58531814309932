import { Badge, type BadgeProps } from '@mantine/core'

const HomeBadge = (props: BadgeProps) => (
  <Badge
    variant="gradient"
    gradient={{
      from: 'indigo',
      to: 'cyan'
    }}
    {...props}
  >
    Home
  </Badge>
)

export default HomeBadge
