import { useCallback } from 'react'
import { useMantineTheme } from '@mantine/core'
import { truncateWithEllipsis } from 'src/utils/strings'
import { type Padding } from '../shared'
import Bar from './Bar'

interface Theme {
  textColor?: React.CSSProperties['color']
  barColor?: React.CSSProperties['color']
  barWarningColor?: React.CSSProperties['color']
  barCriticalColor?: React.CSSProperties['color']
  backgroundColor?: React.CSSProperties['color']
}

interface Threshold {
  high?: number
  low?: number
}

interface Indicators {
  warning?: Threshold | number
  critical?: Threshold | number
}

interface Styles {
  width: number
  height: number
  padding: Padding
  orientation?: 'vertical' | 'horizontal'
  theme?: Theme
}

export const defaultStyles: Styles = {
  width: 300,
  height: 500,
  padding: {
    top: 10,
    right: 5,
    bottom: 10,
    left: 5
  },
  orientation: 'vertical'
}

export interface Props {
  name: string
  percent: number
  label?: string
  styles: Styles
  indicators: Indicators
  hiddenName?: boolean
  hiddenLabel?: boolean
}

export default function PercentGraph ({
  name,
  percent,
  label,
  styles = defaultStyles,
  indicators,
  hiddenName = false,
  hiddenLabel = false
}: Props) {
  const { colors } = useMantineTheme()
  const {
    width,
    height,
    padding,
    theme,
    orientation
  } = styles
  const { top, right, bottom, left } = padding
  const containerSize = width + height
  const isVertical = orientation === 'vertical'
  const barsNum = 10
  const bars = [...Array(barsNum).keys()]

  // set default theme
  const textColor = theme?.textColor ?? colors.dark[6]
  const barColor = theme?.barColor ?? colors.blue[5]
  const barWarningColor = theme?.barWarningColor ?? colors.yellow[5]
  const barCriticalColor = theme?.barCriticalColor ?? colors.red[5]
  const backgroundColor = theme?.backgroundColor ?? colors.white[0]

  const getBarColor = useCallback((value: number) => {
    const isFilled = percent >= (value - 10)
    const defaultColor = isFilled ? barColor : `${barColor}44`
    const warningColor = isFilled ? barWarningColor : `${barWarningColor}44`
    const criticalColor = isFilled ? barCriticalColor : `${barCriticalColor}44`

    if (indicators?.critical) {
      const { critical } = indicators

      if (typeof critical === 'number' && value >= critical) {
        return criticalColor
      }

      if (
        typeof critical === 'object' &&
        ((critical.low && value <= critical.low) ?? (critical.high && value >= critical.high))
      ) {
        return criticalColor
      }
    }

    if (indicators?.warning) {
      const { warning } = indicators

      if (typeof warning === 'number' && value >= warning) {
        return warningColor
      }

      if (
        typeof warning === 'object' &&
        ((warning.low && value <= warning.low) ?? (warning.high && value >= warning.high))
      ) {
        return warningColor
      }
    }

    return defaultColor
  }, [percent, indicators, barColor, barWarningColor, barCriticalColor])

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor,
        width,
        height,
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isVertical ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: isVertical ? 'stretch' : 'center',
          gap: '2px',
          width,
          height,
          padding: `${top}px ${right}px ${bottom}px ${left}px`,
          textAlign: 'center'
        }}
      >
        {!hiddenLabel && label && (
          <label
            style={{
              flex: 1,
              fontSize: `${containerSize / (isVertical ? 25 : 40)}px`,
              fontWeight: 700,
              transform: isVertical ? 'rotate(0deg)' : 'rotate(90deg)',
              color: getBarColor(percent),
              order: isVertical ? 1 : 3
            }}
          >
            {truncateWithEllipsis(label, 15)}
          </label>
        )}
        <div
          style={{
            flex: 3,
            display: 'flex',
            flexDirection: isVertical ? 'column-reverse' : 'row',
            flexWrap: 'nowrap',
            gap: '2px',
            height: '100%',
            width: isVertical ? '100%' : '80%',
            order: 2
          }}
        >
          {bars.map(n => (<Bar key={n} color={getBarColor((n + 1) * barsNum)} />))}
        </div>
        {!hiddenName && (
          <label
            style={{
              flex: 0.5,
              fontSize: `${containerSize / 45}px`,
              transform: isVertical ? 'rotate(0deg)' : 'rotate(90deg)',
              color: textColor,
              order: isVertical ? 3 : 1
            }}
          >
            {truncateWithEllipsis(name, 20)}
          </label>
        )}
      </div>
    </div>
  )
}
