import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Button,
  Popover,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { inviteUser, type AllUsers } from '@venturi-io/api/src/userManager/user'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'
import TextTooltip from 'src/Layout/TextTooltip'
import TextInput from 'src/Input/TextInput'
import NeedsRole from 'src/NeedsRole'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import { useCallback, useState } from 'react'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import RestartOnboardingButton from 'src/Buttons/RestartOnboardingButton'

interface Props extends ListRow {
  setSentInvite: (status: boolean) => void
}

export default function Row ({
  userId,
  orgUserId,
  email,
  firstName,
  lastName,
  jobTitle,
  status,
  roles,
  setSentInvite
}: AllUsers[0] & Props) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const [open, setOpen] = useState(false)
  const invite = useApi(inviteUser)
  const { token } = useUser()

  const form = useForm<{ email: string }>({
    initialValues: {
      email: ''
    },
    validate: {
      email: value => /^\S+@\S+$/.test(value)
        ? null
        : 'Please input a valid Email'
    }
  })

  const handleSubmit = useCallback(({ email }: typeof form.values) => {
    void invite
      .fetch({
        orgUserId,
        email
      }, token, 'Successfully invited')
      .finally(() => {
        close()
        setOpen(false)
        setSentInvite(true)
      })
  }, [form.values])

  const showEmailForm = () => {
    setOpen(true)
    form.setValues({
      email: email ?? ''
    })
  }

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{orgUserId}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(firstName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(lastName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(jobTitle)}</Text>
      </div>
      <div className={classes.col}>
        <Stack spacing={2}>
          {roles.map((role) => (
            <Text key={role} size="sm">
              {role}
            </Text>
          ))}
        </Stack>
      </div>
      <div className={classes.col}>
        <TextTooltip size="sm" value={parseEmpty(email).toString()} maxLength={12} />
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(status)}</Text>
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/settings/users/edit/${orgUserId}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
        {status === 'INACTIVE' && (
          <NeedsRole role="ROLE_SUPERADMIN">
            <div
              style={{
                position: 'relative'
              }}
            >
              <Popover
                width={250}
                position="right-start"
                shadow="lg"
                withArrow
                opened={open}
                onClose={() => setOpen(false)}
              >
                <Popover.Target>
                  <Tooltip
                    label="Invite"
                    position="top"
                    transitionProps={{
                      transition: 'pop',
                      duration: 200
                    }}
                    withArrow
                  >
                    <ActionIcon onClick={showEmailForm} color="primary" variant="filled">
                      <FontAwesomeIcon icon={['far', 'envelope']} size="sm" />
                    </ActionIcon>
                  </Tooltip>
                </Popover.Target>
                <Popover.Dropdown>
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      size="xs"
                      {...form.getInputProps('email')}
                    />
                    <Button
                      type="submit"
                      color="green"
                      mt={10}
                      leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} color="white" />}
                    >
                      Invite
                    </Button>
                  </form>
                </Popover.Dropdown>
              </Popover>
            </div>
          </NeedsRole>
        )}
        {status === 'ACTIVE' && (
          <RestartOnboardingButton
            id={userId}
            name={`${firstName} ${lastName}`}
            type="USER"
          />
        )}
      </div>
    </div>
  )
}
