import { type CSSProperties } from 'react'
import { localPoint } from '@visx/event'
import { scaleOrdinal } from '@visx/scale'
import { Group } from '@visx/group'
import { Text } from '@visx/text'
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip'
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'
import Pie, { type PieArcDatum } from '@visx/shape/lib/shapes/Pie'
import { toProperCase } from 'src/utils/strings'

interface Data {
  id: number
  label: string
  value: number
  valuePercent: number
  color: CSSProperties['color']
}

interface LegendProps {
  title?: string
  data: Data[]
}

function Legend ({ title, data }: LegendProps) {
  const legendGlyphSize = 13
  const scale = scaleOrdinal({
    domain: data.map(({ label }) => label),
    range: data.map(({ color }) => color)
  })

  return (
    <div
      style={{
        position: 'absolute',
        right: 20,
        lineHeight: '.9em',
        color: 'gray',
        fontSize: '10px',
        margin: '0 10px 0 5px'
      }}
    >
      {title && (
        <div
          style={{
            fontSize: '12px',
            marginBottom: '10px',
            fontWeight: 'bold'
          }}
        >
          {title}
        </div>
      )}

      <LegendOrdinal
        scale={scale}
        labelFormat={(label) => toProperCase(label)}
      >
        {(labels) => (
          <div
            style={{
              width: 125,
              display: 'flex',
              flexDirection: 'column',
              gap: 7
            }}
          >
            {labels.map(({ text, value }, i) => (
              <LegendItem
                key={`legend-orginal-${text}-${i}`}
                margin="0 5px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <rect
                    fill={value}
                    width={legendGlyphSize}
                    height={legendGlyphSize}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 4px">
                  {text}
                </LegendLabel>
                <LegendLabel align="right" margin="0 0 0 10px">
                  {`${data[i].value}`}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </div>
  )
}

export interface Props {
  value: number
  width?: number
  height?: number
  data: Data[]
  withLegend?: boolean
}

const tooltipStyles = {
  ...defaultStyles,
  display: 'flex',
  flexDirection: 'column' as const,
  gap: 5,
  padding: 10,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white'
}

let tooltipTimeout: number

export default function Donut ({
  value,
  width = 300,
  height = 300,
  data,
  withLegend = false
}: Props) {
  const containerDivisor = withLegend
    ? 8
    : 6.5
  const containerSize = (width + height) / containerDivisor
  const halfHeight = (height + 20) / 2
  const halfWidth = width / 2
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip<PieArcDatum<Data>>()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  })

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <svg
        ref={containerRef}
        width={width}
        height={height}
      >
        <Group
          top={withLegend
            ? halfHeight - 15
            : halfHeight - 20}
          left={withLegend
            ? halfWidth - 65
            : halfWidth}
        >
          <Pie
            data={data}
            pieValue={({ valuePercent }) => valuePercent}
            pieSortValues={null}
            innerRadius={containerSize - 60}
            outerRadius={containerSize}
            cornerRadius={1}
          >
            {pie => (pie.arcs.map((arc) => (
              <g
                key={arc.data.id}
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip()
                  }, 200)
                }}
                onMouseMove={(event) => {
                  if (tooltipTimeout) {
                    clearTimeout(tooltipTimeout)
                  }

                  const eventSvgCoords = localPoint(event)
                  showTooltip({
                    tooltipData: arc,
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x
                  })
                }}
              >
                <path d={pie.path(arc) ?? ''} fill={arc.data.color}></path>
              </g>
            )))}
          </Pie>
          <Text
            textAnchor="middle"
            fontSize={`${containerSize / 8}px`}
            fontWeight={500}
            fill="gray"
            y={5}
          >
            {value}
          </Text>
        </Group>
      </svg>
      {withLegend && <Legend data={data} />}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div
            style={{
              color: tooltipData.data.color
            }}
          >
            <strong>
              {tooltipData.data.label}
            </strong>
          </div>
          <div>
            {`${tooltipData.data.valuePercent}% (${tooltipData.data.value})`}
          </div>
        </TooltipInPortal>
      )}
    </div>
  )
}
