import { Text, Alert } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function AddWidgetInfo () {
  return (
    <Alert
      title="Add Dashboard Widgets"
      icon={<FontAwesomeIcon icon={['far', 'info-circle']} />}
      color="primary"
      mt="xs"
      pb="md"
    >
      <Text>Get started adding widgets Today! 🚀</Text>
    </Alert>
  )
}
