import { memo, useCallback, useMemo } from 'react'
import {
  useMantineTheme,
  Accordion,
  Box,
  Button,
  Checkbox,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import ColorInput from 'src/Input/ColorInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultStyles as defaultStackedAreasStyles } from 'src/Charts/StackedAreas'
import TimePeriodSelector from 'src/Input/TimePeriodSelector'
import SelectSensor from 'src/Input/Select/SelectSensor'
import ItemPreview from '../shared/ItemPreview'
import { curveTypeOptions } from '../shared'
import StackedSensorAllAgents, { type Props as StackedSensorAllAgentsProps } from '.'

interface Props {
  initialValues: StackedSensorAllAgentsProps | null
  onSubmit: (properties: StackedSensorAllAgentsProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const { colors } = useMantineTheme()
  const focusTrapRef = useFocusTrap()

  const formValues: StackedSensorAllAgentsProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      data: {
        sensorId: -1,
        bucket: {
          item: '7 days',
          data: {
            timeBucket: '1 hour',
            noOfRecords: 24
          }
        }
      },
      styles: {
        ...defaultStackedAreasStyles,
        theme: {
          titleColor: colors.dark[6],
          textColor: colors.dark[5],
          backgroundColor: {
            primary: colors.white[0],
            secondary: colors.white[0]
          },
          axisColor: colors.dark[3]
        }
      }
    }
  ), [initialValues, colors])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const hasSelectedSensor = useMemo(() => form.values.data.sensorId > 0, [form.values.data.sensorId])

  const previewItem = useMemo(() => hasSelectedSensor, [hasSelectedSensor])

  const setFormDataValue = useCallback((
    key: keyof StackedSensorAllAgentsProps['data'],
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormStylesValue = useCallback((
    key: keyof StackedSensorAllAgentsProps['styles'],
    value: unknown
  ) => {
    form.setValues({
      ...form.values,
      styles: {
        ...form.values.styles,
        [key]: value
      }
    })
  }, [form.values.styles])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="chart-line" previewItem={previewItem}>
        <StackedSensorAllAgents {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                  />
                  <SelectSensor
                    label="Sensor"
                    value={(
                      hasSelectedSensor
                        ? form.values.data.sensorId.toString()
                        : null
                    )}
                    onChange={sensorId => setFormDataValue('sensorId', Number(sensorId))}
                    required
                    searchable
                    error={form.errors.sensorId}
                  />
                  {hasSelectedSensor && (
                    <TimePeriodSelector
                      required
                      label="Time Period"
                      chosenBucket={form.values.data.bucket}
                      updateBucket={bucket => setFormDataValue('bucket', bucket)}
                    />
                  )}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="styles">
              <Accordion.Control>Styles</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <Stack spacing="xs">
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Margin Top"
                        placeholder="Margin Top"
                        value={form.values.styles.margin.top}
                        onChange={value => {
                          setFormStylesValue('margin', {
                            ...form.values.styles.margin,
                            top: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Margin Bottom"
                        placeholder="Margin Bottom"
                        value={form.values.styles.margin.bottom}
                        onChange={value => {
                          setFormStylesValue('margin', {
                            ...form.values.styles.margin,
                            bottom: value
                          })
                        }}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <NumberInput
                        label="Margin Left"
                        placeholder="Margin Left"
                        value={form.values.styles.margin.left}
                        onChange={value => {
                          setFormStylesValue('margin', {
                            ...form.values.styles.margin,
                            left: value
                          })
                        }}
                      />
                      <NumberInput
                        label="Margin Right"
                        placeholder="Margin Right"
                        value={form.values.styles.margin.right}
                        onChange={value => {
                          setFormStylesValue('margin', {
                            ...form.values.styles.margin,
                            right: value
                          })
                        }}
                      />
                    </SimpleGrid>
                  </Stack>

                  <Box>
                    <Text size="xs">Curve type</Text>
                    <SegmentedControl
                      fullWidth
                      value={form.values.styles.curveType}
                      data={curveTypeOptions}
                      onChange={value => setFormStylesValue('curveType', value)}
                    />
                  </Box>

                  <Stack spacing="xs">
                    <ColorInput
                      name="titleColor"
                      label="Title Color"
                      placeholder="Pick a color"
                      value={form.values.styles.theme?.titleColor}
                      onChange={value => {
                        setFormStylesValue('theme', {
                          ...form.values.styles.theme,
                          titleColor: value
                        })
                      }}
                    />
                    <ColorInput
                      name="textColor"
                      label="Text Color"
                      placeholder="Pick a color"
                      value={form.values.styles.theme?.textColor}
                      onChange={value => {
                        setFormStylesValue('theme', {
                          ...form.values.styles.theme,
                          textColor: value
                        })
                      }}
                    />
                    <Box>
                      <Text size="xs">Background Color</Text>
                      <SimpleGrid cols={2} spacing="md">
                        <ColorInput
                          name="backgroundColorPrimary"
                          placeholder="Primary"
                          value={form.values.styles.theme?.backgroundColor?.primary}
                          onChange={value => {
                            setFormStylesValue('theme', (
                              form.values.styles.theme?.backgroundColor
                                ? {
                                    ...form.values.styles.theme,
                                    backgroundColor: {
                                      ...form.values.styles.theme.backgroundColor,
                                      primary: value
                                    }
                                  }
                                : {
                                    ...form.values.styles.theme,
                                    backgroundColor: {
                                      primary: value
                                    }
                                  }
                            ))
                          }}
                        />
                        <ColorInput
                          name="backgroundColorSecondary"
                          placeholder="Secondary"
                          value={form.values.styles.theme?.backgroundColor?.secondary}
                          onChange={value => {
                            setFormStylesValue('theme', (
                              form.values.styles.theme?.backgroundColor
                                ? {
                                    ...form.values.styles.theme,
                                    backgroundColor: {
                                      ...form.values.styles.theme.backgroundColor,
                                      secondary: value
                                    }
                                  }
                                : {
                                    ...form.values.styles.theme,
                                    backgroundColor: {
                                      secondary: value
                                    }
                                  }
                            ))
                          }}
                        />
                      </SimpleGrid>
                    </Box>
                    <ColorInput
                      name="axisColor"
                      label="Axis Color"
                      placeholder="Pick a color"
                      value={form.values.styles.theme?.axisColor}
                      onChange={value => {
                        setFormStylesValue('theme', {
                          ...form.values.styles.theme,
                          axisColor: value
                        })
                      }}
                    />
                  </Stack>

                  <Stack spacing="xs" mt="xs">
                    <SimpleGrid cols={2} spacing="md">
                      <Checkbox
                        checked={!form.values.styles.withoutTitle}
                        label="show Title"
                        onChange={event => setFormStylesValue('withoutTitle', !event.currentTarget.checked)}
                      />
                      <Checkbox
                        checked={!form.values.styles.hideTotalStackedValue}
                        label="show Total Stacked Value"
                        onChange={event => setFormStylesValue('hideTotalStackedValue', !event.currentTarget.checked)}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <Checkbox
                        checked={!form.values.styles.withoutGridRows}
                        label="show Grid Rows"
                        onChange={event => setFormStylesValue('withoutGridRows', !event.currentTarget.checked)}
                      />
                      <Checkbox
                        checked={!form.values.styles.withoutGridColumns}
                        label="show Grid Columns"
                        onChange={event => setFormStylesValue('withoutGridColumns', !event.currentTarget.checked)}
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing="md">
                      <Checkbox
                        checked={!form.values.styles.withoutXAxis}
                        label="show X-axis"
                        onChange={event => setFormStylesValue('withoutXAxis', !event.currentTarget.checked)}
                      />
                      <Checkbox
                        checked={!form.values.styles.withoutYAxis}
                        label="show Y-axis"
                        onChange={event => setFormStylesValue('withoutYAxis', !event.currentTarget.checked)}
                      />
                    </SimpleGrid>
                    <Checkbox
                      checked={!form.values.styles.withoutTooltip}
                      label="show Tooltip"
                      onChange={event => setFormStylesValue('withoutTooltip', !event.currentTarget.checked)}
                    />
                  </Stack>
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
