import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  type ButtonProps,
  Divider,
  Input,
  Popover,
  Stack,
  Text,
  createStyles,
  useMantineTheme,
  Group,
  Tooltip
} from '@mantine/core'
import { type DatesRangeValue, type DateTimePickerProps } from '@mantine/dates'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import React, { type FormEvent, useEffect, useState, useMemo } from 'react'
import DateTimeRangePicker from 'src/Input/DateTimeRangePicker'
import { dateFormat } from 'src/utils/dates'

const useStyle = createStyles(theme => ({
  button: {
    fontWeight: 'normal',
    color: theme.colors.dark[5],
    border: `1px solid ${theme.colors.gray[4]}`
  },
  input: {
    paddingLeft: '2rem !important'
  },
  inputIcon: {
    width: '2rem'
  },
  period: {
    fontWeight: 500,
    color: theme.colors.dark[5],
    textAlign: 'right',
    padding: 0
  },
  mainInner: {
    justifyContent: 'flex-start'
  },
  inner: {
    justifyContent: 'flex-start',
    paddingLeft: '2rem',
    position: 'relative'
  },
  periodIcon: {
    position: 'absolute',
    left: 13
  }
}))

interface TimePeriod {
  value: string
  label: string
}

const supportedUnits = ['h', 'd', 'm', 'M', 'w']

const fixedOptions: TimePeriod[] = [
  {
    value: '1h',
    label: 'Last Hour'
  },
  {
    value: '24h',
    label: 'Last 24 hours'
  },
  {
    value: '3d',
    label: 'Last 3 days'
  },
  {
    value: '7d',
    label: 'Last 7 days'
  },
  {
    value: '14d',
    label: 'Last 14 days'
  },
  {
    value: '30d',
    label: 'Last 30 days'
  },
  {
    value: '90d',
    label: 'Last 90 days'
  }
]

type PeriodType = 'fixed' | 'absolute'

const inputWrapperOrder: Array<'label' | 'error' | 'input' | 'description'> = ['label', 'error', 'input', 'description']
const sharedProps: Partial<DateTimePickerProps> = {
  inputWrapperOrder,
  descriptionProps: {
    style: {
      fontStyle: 'italic'
    }
  },
  maxDate: dayjs().toDate()
}

interface PeriodButtonProps extends ButtonProps {
  value: string
  label: string
  isChecked?: boolean
  onClick: (value: string) => void
}

function PeriodButton ({
  label,
  value,
  isChecked,
  onClick,
  ...rest
}: PeriodButtonProps) {
  const { classes } = useStyle()
  return (
    <Button
      size="sm"
      classNames={{
        root: classes.period,
        inner: classes.inner,
        icon: classes.periodIcon
      }}
      variant="white"
      onClick={() => onClick(value)}
      leftIcon={isChecked && <FontAwesomeIcon icon={['far', 'check']} />}
      {...rest}
    >
      {label}
    </Button>
  )
}

interface TimePeriodProps {
  dateRange: Range | null
  onChange: (range: Range | null) => void
  onSubmit: () => void
}

const maxRange = dayjs().subtract(90, 'd').subtract(1, 'm')

function TimePeriodButton ({ onChange, onSubmit, dateRange }: TimePeriodProps) {
  const { colors } = useMantineTheme()
  const { classes } = useStyle()
  const [show, { toggle, close }] = useDisclosure()
  const ref = useClickOutside(close)
  const [mode, setMode] = useState<PeriodType>('fixed')
  const [stringValue, setStringValue] = useState('24h')
  const [lastValidValue, setLastValidValue] = useState(stringValue)

  const { from, to } = dateRange
    ? {
        from: dayjs(dateRange.from, dateFormat),
        to: dayjs(dateRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const range: DatesRangeValue = [
    from?.toDate() ?? null,
    to?.toDate() ?? null
  ]

  const isValidRange = from && to
    ? from.isBefore(to)
    : true

  const activeTimePeriod = fixedOptions.find(({ value }) => value === stringValue)

  const handlePeriodChange = (value: string) => {
    if (mode !== 'fixed') {
      setMode('fixed')
    }
    setStringValue(value)
  }

  const translateTime = () => {
    const now = dayjs()

    let toSubtract = null
    let timeUnit = null
    supportedUnits.forEach((unit) => {
      if (stringValue.includes(unit)) {
        const index = stringValue.indexOf(unit)
        toSubtract = stringValue.slice(0, index)
        timeUnit = stringValue[index]
      }
    })

    return {
      now,
      toSubtract,
      timeUnit
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (hasError) {
      return
    }
    onSubmit()
    close()
  }

  const { hasError, errorMessage } = useMemo(() => {
    const { toSubtract, timeUnit, now } = translateTime()
    if (!toSubtract || !timeUnit) {
      return {
        hasError: true,
        errorMessage: 'Invalid time format'
      }
    }
    const output = now.subtract(toSubtract, timeUnit as unknown as dayjs.ManipulateType)
    if (!output.isValid()) {
      return {
        hasError: !output.isValid(),
        errorMessage: 'Invalid time format'
      }
    }

    // check max range
    if (output.isBefore(maxRange)) {
      return {
        hasError: true,
        errorMessage: 'Limit is only 90 days'
      }
    }

    return {
      hasError: false,
      errorMessage: null
    }
  }, [stringValue])

  useEffect(() => {
    const { toSubtract, timeUnit, now } = translateTime()
    if (hasError) {
      return
    }
    setLastValidValue(stringValue)
    onChange({
      from: now
        .subtract(Number(toSubtract), timeUnit as unknown as dayjs.ManipulateType)
        .format(dateFormat),
      to: now.format(dateFormat)
    })
  }, [stringValue])

  useEffect(() => {
    if (!show) {
      // reset to a valid value
      setStringValue(lastValidValue)
    }
  }, [show])

  return (
    <Group>
      <Popover
        opened={show}
        position="bottom-start"
        withArrow
        withinPortal
        arrowOffset={12}
        shadow="sm"
      >
        <Popover.Target>
          <Button
            w={194}
            leftIcon={<FontAwesomeIcon color={colors.gray[5]} icon={['far', 'calendar-day']} />}
            variant="white"
            classNames={{
              root: classes.button,
              inner: classes.mainInner
            }}
            onClick={toggle}
          >
            {mode === 'fixed'
              ? activeTimePeriod?.label ?? `Last ${stringValue}`
              : 'Absolute date'}
          </Button>
        </Popover.Target>
        <Popover.Dropdown p="0">
          <Stack ref={ref} spacing="0.5rem" w="12rem">
            <Text size="xs" px="sm" pt="xs" color="dimmed">Time Range</Text>
            <Stack spacing="0.3rem">
              <Divider />
              <form onSubmit={handleSubmit}>
                <Tooltip
                  display={hasError ? 'initial' : 'none'}
                  label={errorMessage}
                  position="left"
                  withinPortal
                >
                  <Input
                    classNames={{
                      input: classes.input,
                      icon: classes.inputIcon
                    }}
                    icon={hasError && (
                      <FontAwesomeIcon
                        color="red"
                        icon={['far', 'exclamation-circle']}
                      />
                    )}
                    size="sm"
                    placeholder="Custom 4h, 6d, 2m..."
                    onChange={(e) => {
                      setMode('fixed')
                      setStringValue(e.target.value)
                    }}
                    error={hasError}
                  />
                </Tooltip>
              </form>
              <Divider />
            </Stack>
            <Stack spacing={0}>
              {fixedOptions.map(item => (
                <PeriodButton
                  key={item.value}
                  isChecked={mode === 'fixed'
                    ? item.value === stringValue
                    : false}
                  onClick={handlePeriodChange}
                  {...item}
                />
              ))}
            </Stack>
            <Stack spacing="0.3rem">
              <Divider />
              <PeriodButton
                value="absolute"
                label="Absolute date"
                leftIcon={<FontAwesomeIcon icon={['far', 'calendar-day']} />}
                variant="white"
                onClick={() => {
                  close()
                  setMode('absolute')
                }}
              />
            </Stack>
          </Stack>
        </Popover.Dropdown>
      </Popover>
      {mode === 'absolute' && (
        <DateTimeRangePicker
          fromProps={{
            ...sharedProps,
            size: 'sm',
            valueFormat: 'dddd, DD MMMM  HH:mm',
            error: !isValidRange,
            defaultValue: range[0],
            onChange: date => {
              if (!date) {
                return onChange(null)
              }
              onChange({
                from: dayjs(date)
                  .format(dateFormat),
                to: dayjs(range[1])
                  .format(dateFormat)
              })
            }
          }}
          toProps={{
            ...sharedProps,
            size: 'sm',
            valueFormat: 'HH:mm  dddd, DD MMMM',
            error: !isValidRange,
            defaultValue: range[1],
            onChange: date => {
              if (!date) {
                return onChange(null)
              }
              onChange({
                from: dayjs(range[0])
                  .set('seconds', 0)
                  .format(dateFormat),
                to: dayjs(date)
                  .set('seconds', 0)
                  .format(dateFormat)
              })
            }
          }}
        />
      )}
    </Group>
  )
}

export default TimePeriodButton
