import { type MantineSize } from '@mantine/core'

export const capitalise = (input: string) => (input.charAt(0).toUpperCase() + input.slice(1).toLowerCase())

export const toProperCase = (text: string) => (
  text
    .replace(/_|-/g, ' ')
    .toLowerCase()
    .split(' ')
    .map(capitalise)
    .join(' ')
)

export const hasKeyword = (ref: string, word: string) => ref.toLowerCase().includes(word.toLowerCase())

export const truncateWithEllipsis = (input: string, length: number) => (
  input.length <= length
    ? input
    : `${input.slice(0, length)}...`
)

export const isValidPhoneNumber = (input: string) => {
  const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/
  return regex.test(input)
}

export const isEmptyValue = (value: string | undefined) => {
  if (typeof value === 'string' && value.length > 0) {
    return value
  }

  return undefined
}

export const getTextMaxLengthPerBreakpoint = (size: MantineSize, hasManyCols = false) => {
  switch (size) {
    case 'xl':
      return 30
    case 'lg':
      return hasManyCols
        ? 15
        : 18
    case 'md':
      return hasManyCols
        ? 12
        : 15
    case 'sm':
      return hasManyCols
        ? 8
        : 12
    default:
      return 20
  }
}
