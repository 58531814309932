import { memo } from 'react'
import { Title, Table as MantineTable, ActionIcon, createStyles } from '@mantine/core'
import { type SpeedAlert } from '@venturi-io/api/src/analytics/speedAlerts'
import NoData from 'src/Layout/TableRowNoData'
import Link from 'src/Layout/Link'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyle = createStyles(() => ({
  table: {
    width: 800
  }
}))
export interface Props {
  title: string
  data: SpeedAlert[]
}

const Rows = ({ data }: Omit<Props, 'title'>) => {
  return data.length > 0
    ? data.map(({
      id,
      speedDateTime,
      userFirstName,
      userLastName,
      agentId,
      agentName,
      geoZoneName,
      speedLimit,
      speed,
      tripId,
      tripStartTime
    }) => (
      <tr key={id}>
        <td>{dayjs(speedDateTime).format(uiDateFormat)}</td>
        <td>{`${userFirstName} ${userLastName}`}</td>
        <td>{agentName}</td>
        <td>{geoZoneName}</td>
        <td>{speedLimit}</td>
        <td>{speed}</td>
        <td>
          <ActionIcon
            component={Link}
            color="primary"
            to={`/?agentId=${agentId}&tripId=${tripId}&startTime=${tripStartTime}`}
          >
            <FontAwesomeIcon icon={['fas', 'flag-checkered']} />
          </ActionIcon>
        </td>
      </tr>
    ))
    : <NoData message="No data available" />
}

const Table = ({ title, data }: Props) => {
  const { classes } = useStyle()
  return (
    <>
      <Title ml="md" mt="sm" order={3}>{title}</Title>
      <MantineTable
        className={classes.table}
        highlightOnHover
        striped
      >
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Driver Name</th>
            <th>Agent Name</th>
            <th>Geozone Name</th>
            <th>Speed Limit (KMs)</th>
            <th>Driver Speed (KMs)</th>
            <th>View Trip</th>
          </tr>
        </thead>
        <tbody>
          <Rows data={data} />
        </tbody>
      </MantineTable>
    </>
  )
}

export default memo(Table)
