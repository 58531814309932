import { Box, Stack, Title } from '@mantine/core'
import GaugeComponent from 'react-gauge-component'

interface Props {
  lightModeOn: boolean
}

export default function Gauges ({ lightModeOn = false }: Props) {
  return (
    <Box>
      <Title order={4} color={lightModeOn ? 'dark' : 'white'}>
        Gauges
      </Title>
      <Stack spacing={80} align="center">
        <Box sx={{ width: '60%' }}>
          <GaugeComponent
            arc={{
              subArcs: [
                {
                  limit: 20,
                  color: '#EA4228',
                  showTick: true,
                  tooltip: {
                    text: 'Temperature is too low'
                  }
                },
                {
                  limit: 40,
                  color: '#F58B19',
                  showTick: true,
                  tooltip: {
                    text: 'Temperature is getting low'
                  }
                },
                {
                  limit: 60,
                  color: '#F5CD19',
                  showTick: true,
                  tooltip: {
                    text: 'Temperature is normal'
                  }
                },
                {
                  limit: 100,
                  color: '#5BE12C',
                  showTick: true,
                  tooltip: {
                    text: 'Temperature is too high'
                  }
                }
              ]
            }}
            value={35}
            type="grafana"
            pointer={{ type: 'blob', animationDelay: 0 }}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <GaugeComponent
            value={50}
            type="radial"
            labels={{
              tickLabels: {
                type: 'inner',
                ticks: [
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 }
                ]
              }
            }}
            arc={{
              colorArray: ['#EA6F6C', '#CDDC39'],
              subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
              padding: 0.02,
              width: 0.3
            }}
            pointer={{
              elastic: true,
              animationDelay: 0
            }}
          />
        </Box>
        <Box sx={{ width: '60%' }}>
          <GaugeComponent
            type="semicircle"
            arc={{
              colorArray: ['#00FF15', '#FF2121'],
              padding: 0.02,
              subArcs: [
                { limit: 40 },
                { limit: 60 },
                { limit: 70 },
                {},
                {},
                {},
                {}
              ]
            }}
            pointer={{ type: 'blob', animationDelay: 0 }}
            value={48}
          />
        </Box>
      </Stack>
    </Box>
  )
}
