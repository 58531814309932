import { z } from 'zod'
import { createStatelessApi } from '../configure'
import { type PaginatedRequest, paginated } from '../shared'

// Get speed alerts for table
const speedAlert = z.object({
  id: z.number(),
  orgId: z.number(),
  tripId: z.number(),
  tripStartTime: z.string(),
  orgUserId: z.optional(z.number()),
  userFirstName: z.optional(z.string()),
  userLastName: z.optional(z.string()),
  agentId: z.number(),
  agentName: z.string(),
  agentLocationId: z.number(),
  speed: z.number(),
  speedLimit: z.number(),
  speedDateTime: z.string(),
  geoZoneId: z.optional(z.number()),
  geoZoneName: z.optional(z.string()),
  maxSpeed: z.optional(z.number()),
  agentLocationPoint: z.optional(z.object({
    latitude: z.number(),
    longitude: z.number()
  }))
})

const speedAlerts = paginated.extend({
  items: z.array(speedAlert)
})

export type SpeedAlert = z.infer<typeof speedAlert>

export type SpeedAlerts = z.infer<typeof speedAlerts>

export interface SpeedAlertRequest extends PaginatedRequest {
  orgId: number
  geoZoneIds?: string
  tripId?: number
  parentLog?: boolean
}

export const getSpeedAlerts = createStatelessApi<SpeedAlertRequest, typeof speedAlerts>(
  'analytics',
  '/speed-alerts',
  { method: 'GET', type: 'path' },
  speedAlerts
)
