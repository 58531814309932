import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  createStyles,
  Button,
  Center,
  Paper,
  Stack,
  Text,
  TextInput,
  PasswordInput,
  SimpleGrid,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import { findUserByActivationId, activateUser } from '@venturi-io/api/src/userManager/user'
import { type User, loginUser } from '@venturi-io/api/src/userManager/auth'
import MainLogo from 'src/MainLogo'
import { getHost } from 'src/utils/host'
import { giveOffset } from 'src/utils/dates'

interface RouteParams extends Record<string, string | undefined> {
  activationId: string
}

interface Props {
  orgId: number
  signin: (user: User) => void
}

const useStyles = createStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.primary[6],
    background: `linear-gradient(to bottom right, ${theme.colors.brand[0]}, ${theme.colors.brand[1]})`,
    overflowY: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  form: {
    width: '30vw',
    minWidth: '400px'
  }
}))

const Registration = ({ orgId, signin }: Props) => {
  const orgUrl = getHost()
  const timezone = giveOffset()
  const findInactiveUser = useApi(findUserByActivationId, false)
  const registerUser = useApi(activateUser, false)
  const login = useApi(loginUser, false)
  const { activationId } = useParams<RouteParams>()
  const navigate = useNavigate()
  const { classes } = useStyles()

  if (!activationId) {
    return null
  }

  const inactiveUser = findInactiveUser.data.mapOrDefault(data => data, null)

  const hasValue = (inputName: string, value: string) => value.length < 1
    ? `${inputName} is required`
    : null

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      phoneNumber: '',
      password: '',
      matchingPassword: ''
    },
    validate: {
      firstName: value => hasValue('First Name', value),
      lastName: value => hasValue('Last Name', value),
      jobTitle: value => hasValue('Job Title', value),
      phoneNumber: value => hasValue('Phone Number', value),
      password: value => hasValue('Password', value),
      matchingPassword: (value, inputs) => value !== inputs.password ? 'Password do not match' : null
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    if (inactiveUser) {
      void registerUser.fetch({
        activationId,
        email: inactiveUser.email,
        ...values,
        orgUrl,
        timezone
      }, '', 'Successfully registered')
    }
  }, [form.values])

  const loginError = login.error.mapOrDefault(error => error, null)

  useEffect(() => {
    login.data.ifJust(user => {
      // This will automatically sign in the newly created user after successful registration
      setTimeout(() => {
        signin(user)
        navigate('/')
      }, 100)
    })
  }, [login.data])

  useEffect(() => {
    registerUser.data.ifJust(({ email, orgId }) => {
      void login.fetch({
        email,
        password: form.values.password,
        orgId,
        timezone,
        orgUrl
      }, '')
    })
  }, [registerUser.data])

  useEffect(() => {
    findInactiveUser.error.ifJust(() => {
      // this will redirect the user to login page if the reset id is invalid
      navigate('/')
    })
  }, [findInactiveUser.error])

  useEffect(() => {
    void findInactiveUser.fetch({ activationId }, '')
  }, [])

  useEffect(() => {
    findInactiveUser.data.ifJust(({
      firstName,
      lastName,
      jobTitle,
      phoneNumber
    }) => {
      form.setValues({
        firstName,
        lastName,
        jobTitle,
        phoneNumber
      })
    })
  }, [findInactiveUser.data])

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
          <Paper shadow="sm" radius="md" py={30} px={25}>
            <Stack align="stretch" justify="center" spacing="xs">
              <Center>
                <MainLogo orgId={orgId} />
              </Center>
              <Center>
                <Title order={2} mb="xs">ACCOUNT REGISTRATION</Title>
              </Center>
              <SimpleGrid cols={2}>
                <TextInput
                  withAsterisk
                  label="First Name"
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  withAsterisk
                  label="Last Name"
                  {...form.getInputProps('lastName')}
                />
              </SimpleGrid>
              <TextInput
                withAsterisk
                label="Job Title"
                {...form.getInputProps('jobTitle')}
              />
              <TextInput
                withAsterisk
                label="Phone Number"
                {...form.getInputProps('phoneNumber')}
              />
              <PasswordInput
                withAsterisk
                label="Password"
                {...form.getInputProps('password')}
              />
              <PasswordInput
                withAsterisk
                label="Confirm Password"
                {...form.getInputProps('matchingPassword')}
              />
              {loginError && (
                <Center>
                  <Text size="md" color="red">
                    {loginError.message}
                  </Text>
                </Center>
              )}
              <Button
                type="submit"
                color="primary"
                leftIcon={<FontAwesomeIcon icon={['fas', 'right-to-bracket']} color="white" />}
                disabled={registerUser.loading}
              >
                Register
              </Button>
            </Stack>
          </Paper>
        </form>
      </div>
    </div>
  )
}

export default Registration
