import { useNavigate } from 'react-router-dom'
import { Code, Stack, Text } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/sensor'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { isEmpty } from 'src/utils/objects'
import ActionButton from 'src/ActionButton'
import TextTooltip from 'src/Layout/TextTooltip'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  id,
  name,
  type,
  sensorTypeName,
  unit,
  valueMap
}: Sensor & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const ValueMapList = () => (
    <Stack spacing="xs" align="flex-start" justify="center">
      {Object.entries(valueMap).map(([input, output]) => (
        <Code key={input}>
          {`${input.toString()} = ${output.toString()}`}
        </Code>
      ))}
    </Stack>
  )

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{id}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(name)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(type)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(sensorTypeName)}</Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">{parseEmpty(unit)}</Text>
      </div>
      <div className={classes.col}>
        {isEmpty(valueMap)
          ? <Text size="sm">-</Text>
          : <ValueMapList />}
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/my-admin/sensors/edit/${id}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </div>
    </div>
  )
}
