import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMantineTheme, MantineProvider, type MantineThemeOverride } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { type Maybe, Just } from 'purify-ts'
import { configureOnUnauthorized, configure } from '@venturi-io/api/src/configure'
import { user as userDecoder, type User } from '@venturi-io/api/src/userManager/auth'
import { getOrgTheme } from '@venturi-io/api/src/userManager/org'
import { init as initFullStory } from '@fullstory/browser'
import SplashScreen from './SplashScreen'
import { color, adjustColor, simpleColor } from './utils/style'
import Core, { publicLocations } from './Core'
import LoginRouter from './Router/LoginRouter'
import { useApi } from './utils/useApi'
import { getHost } from './utils/host'
import { useZendesk } from './ZendeskContext'

const colors = {
  primary: adjustColor(color('#274873'), '#274873', 6),
  secondary: color('#b2ffb2'),
  brand: simpleColor([
    '#052133',
    '#5ba7d4'
  ]),
  white: color('#fff')
}

const defaultTheme: MantineThemeOverride = {
  breakpoints: {
    xl: '112.5em',
    lg: '68.75em'
  },
  colors: {
    ...colors
  },
  fontFamily: 'Inter, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Inter, sans-serif' }
}
export interface Org {
  orgId: number
  name: string
}

function getUser () {
  const rawUser = window.localStorage.getItem('user')
  const parsedUser = userDecoder.safeParse(JSON.parse(rawUser ?? 'null'))

  return parsedUser.success ? parsedUser.data : null
}

export default function App () {
  const location = useLocation()
  const navigate = useNavigate()
  const orgTheme = useApi(getOrgTheme, false, false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [initialLocationPath, setInitialLocationPath] = useState<string | null>(null)
  const [org, setOrg] = useState<Maybe<Org> | null>(null)
  const defaultMantineTheme = useMantineTheme()
  const [rawTheme, setRawTheme] = useLocalStorage<MantineThemeOverride>({
    key: 'theme',
    defaultValue: defaultTheme
  })
  const [theme, setTheme] = useState<MantineThemeOverride>(rawTheme)

  const [_, setEnvironment] = useLocalStorage({
    key: 'environment',
    defaultValue: ''
  })

  const [localUser, setLocalUser] = useState<User | null>(getUser)
  const { hide, isOpen, open, close, setCookies } = useZendesk()

  useEffect(() => {
    if (localUser !== null) {
      window.localStorage.setItem('user', JSON.stringify(localUser))
    } else {
      window.localStorage.removeItem('user')
    }
  }, [localUser])

  useEffect(() => {
    const iframe = document.querySelector<HTMLElement>('#launcher')

    if (iframe) {
      const iframeStyle = iframe.style
      const animationStyle = iframeStyle.animationName
      if (!animationStyle.includes('launcherOnOpen')) {
        iframeStyle.visibility = 'hidden'
      } else {
        iframeStyle.visibility = 'visible'
      }
    }
  }, [open, close, isOpen])

  // const setUser = (user: User) => window.localStorage.setItem('user', JSON.stringify(user))
  // const clearUser = () => window.localStorage.removeItem('user')

  const loadOrg = () => {
    void configure(getHost())
      .then(result => {
        setEnvironment(result.environment)

        setOrg(Just({
          orgId: result.orgId,
          name: result.orgName
        }))
      })
  }

  useEffect(() => {
    void loadOrg()
  }, [])

  const signin = (user: User) => {
    setLocalUser(user)
  }

  const signout = () => {
    setCookies(false)
    setLocalUser(null)
    void loadOrg()
  }

  useEffect(() => {
    configureOnUnauthorized(signout)
  }, [])

  useEffect(() => {
    org?.caseOf({
      Nothing: () => {
        document.title = 'Venturi AMP'
      },
      Just: ({ orgId, name }) => {
        const orgName = name || 'Organisation not defined'
        document.title = `${orgName} - Venturi AMP`

        // Request theme
        void orgTheme
          .fetch({ orgId }, '')
          .finally(() => {
            setInitialLoad(false)
          })
      }
    })
  }, [org])

  useEffect(() => {
    orgTheme.data.ifJust(result => {
      setRawTheme({
        ...theme,
        colors: {
          ...theme.colors,
          primary: result.primary,
          secondary: result.secondary,
          brand: (() => {
            const res = color('#fff')
            res[0] = result.brand[0]
            res[1] = result.brand[1]
            res[2] = result.brand[2]
            res[3] = result.brand[3]

            return res
          })()
        },
        breakpoints: {
          ...defaultMantineTheme.breakpoints,
          ...theme.breakpoints
        }
      })
    })
  }, [orgTheme.data])

  useEffect(() => {
    setTheme(rawTheme)
  }, [rawTheme])

  useEffect(() => {
    if (localUser !== null &&
      initialLocationPath !== null &&
      initialLocationPath !== '/' &&
      !publicLocations.includes(initialLocationPath.split('/')[1])
    ) {
      // This will redirect the newly signed in User to initial target URL once authenticated
      navigate(initialLocationPath)
      setInitialLocationPath(null)
    }
  }, [localUser, initialLocationPath])

  useEffect(() => {
    setInitialLoad(true)
    setInitialLocationPath(`${location.pathname}${location.search}`)
  }, [])

  useEffect(() => {
    hide()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      hide()
    }
  }, [isOpen])

  useEffect(() => {
    if (localUser === null) {
      hide()
    }
  }, [localUser])

  if (initialLoad || org === null) {
    return <SplashScreen />
  }

  if (process.env.NODE_ENV === 'production') {
    initFullStory({ orgId: '130T6C' })
  }

  return org.caseOf({
    Nothing: () => <div>{'No organisation by this URL'}</div>,
    Just: org_ => (
      <MantineProvider theme={theme}>
        <Notifications />
        <ModalsProvider>
          {localUser !== null
            ? <Core user={localUser} org={org_} signout={signout} />
            : <LoginRouter signin={signin} orgId={org_.orgId} />}
        </ModalsProvider>
      </MantineProvider>
    )
  })
}
