import { memo, useCallback, useMemo } from 'react'
import { Accordion, Button, Stack, Switch } from '@mantine/core'
import { useFocusTrap } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'src/Input/TextInput'
import ItemPreview from '../../shared/ItemPreview'
import Map, { type Props as MapProps } from '..'
import AgentMultiSelect from './AgentMultiSelect'
import GeoZoneMultiSelect from './GeoZoneMultiSelect'

interface Props {
  initialValues: MapProps | null
  onSubmit: (properties: MapProps) => void
}

const Properties = ({ initialValues, onSubmit }: Props) => {
  const focusTrapRef = useFocusTrap()

  const formValues: MapProps = useMemo(() => (
    initialValues ?? {
      id: '',
      name: '',
      options: {
        embedded: true
      }
    }
  ), [initialValues])

  const form = useForm({
    initialValues: formValues,
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Name'
          : null
      )
    }
  })

  const setFormDataValue = useCallback((
    key: string,
    value: unknown
  ) => {
    form.setFieldValue('data', {
      ...form.values.data,
      [key]: value
    })
  }, [form.values.data])

  const setFormOptionsValue = useCallback((
    key: string,
    value: unknown
  ) => {
    form.setFieldValue('options', {
      ...form.values.options,
      [key]: value
    })
  }, [form.values.options])

  const handleSubmit = useCallback((values: typeof form.values) => {
    onSubmit(values)

    form.reset()
  }, [form.values])

  return (
    <>
      <ItemPreview iconName="map-location-dot" previewItem>
        <Map {...form.values} />
      </ItemPreview>
      <form ref={focusTrapRef} onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing="xs">
          <Accordion defaultValue="data">
            <Accordion.Item value="data">
              <Accordion.Control>Data</Accordion.Control>
              <Accordion.Panel>
                <Stack spacing="xs">
                  <TextInput
                    data-autofocus
                    required
                    label="Name"
                    placeholder="Name"
                    value={form.values.name}
                    onChange={event => form.setFieldValue('name', event.currentTarget.value)}
                    error={form.errors.name}
                  />
                  <AgentMultiSelect
                    searchable
                    value={form.values.data?.agentIds?.map(id => id.toString())}
                    onChange={agentIds => {
                      setFormDataValue(
                        'agentIds',
                        agentIds.map(id => Number(id))
                      )
                    }}
                  />
                  <GeoZoneMultiSelect
                    searchable
                    value={form.values.data?.geoZoneIds?.map(id => id.toString())}
                    onChange={geoZoneIds => {
                      setFormDataValue(
                        'geoZoneIds',
                        geoZoneIds.map(id => Number(id))
                      )
                    }}
                  />
                  <Switch
                    label="Embed Map"
                    color="primary"
                    size="sm"
                    checked={form.values.options?.embedded}
                    onChange={event => {
                      setFormOptionsValue('embedded', event.currentTarget.checked)
                    }}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Button
            type="submit"
            color="primary"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'floppy-disk']}
                color="white"
              />
            )}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default memo(Properties)
