import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Group, Switch, Text } from '@mantine/core'
import { getAgentsForSite } from '@venturi-io/api/src/config/agent'
import { getSitesWithAlarms } from '@venturi-io/api/src/config/site'
import { useUser } from 'src/UserContext'
import { useMeta } from 'src/MetaContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import Incidents from 'src/Incidents'
import AutoGrid from 'src/Layout/AutoGrid'
import AgentCell from 'src/Home/Agents/AgentCell'
import Loader from 'src/Layout/Loader'
import Paper from 'src/Layout/Paper'

interface RouteParams extends Record<string, string | undefined> {
  siteId: string
}

export default function Details () {
  const { siteId: id } = useParams<RouteParams>()
  const sites = useApi(getSitesWithAlarms)
  const agents = usePaginatedApi(getAgentsForSite)

  const [small, setSmall] = useState(false)
  const [isFirst, setIsFirst] = useState(true)

  const { token, orgId } = useUser()
  const { meta, setMeta } = useMeta()

  if (!id) {
    return null
  }

  const siteId = parseInt(id)

  const loadAgents = (page?: number, size?: number) => {
    const req = {
      siteId,
      page: page ?? agents.page,
      size: size ?? agents.size
    }

    void agents
      .fetch(req, token)
      .finally(() => {
        agents.stopPolling()
        agents.startPolling(req, token, 60)
        setIsFirst(false)
      })

    return () => {
      agents.stopPolling()
      agents.abort()
    }
  }

  useEffect(() => {
    void sites.fetch({
      orgId,
      page: 1,
      size: 999
    }, token)
    loadAgents()
  }, [agents.page])

  useEffect(() => {
    if (!isFirst) return

    agents.data.ifJust(({ items }) => {
      if (items.length > 6) setSmall(true)
    })
  }, [agents.data])

  const Nothing = () => agents.loading
    ? <Loader first />
    : <Loader first><Text>No agents retreived for this organisation</Text></Loader>

  useEffect(() => {
    sites
      .data
      .ifJust(({ items }) => {
        const site = items.find(site => site.siteId === siteId)

        if (site) {
          setMeta({
            ...meta,
            pageTitle: site.name
          })
        }
      })
  }, [sites.data])

  return (
    <>
      {agents.data.caseOf({
        Nothing,
        Just: data =>
          (
            <>
              <Group position="right" mb={12} mt={-6}>
                <Switch
                  label="Compact View"
                  color="primary"
                  size="sm"
                  checked={small}
                  onChange={ev => setSmall(ev.currentTarget.checked)}
                />
              </Group>
              <AutoGrid marginVertical="20px">
                {data
                  .items
                  .sort((a, b) => a.agentName.localeCompare(b.agentName))
                  .map(agent => (
                    <AgentCell
                      key={agent.agentId}
                      agent={agent}
                      isSmall={small}
                    />
                  ))}
              </AutoGrid>
              <Pagination
                mt="sm"
                value={agents.page}
                total={data.totalPages}
                onChange={agents.setPage}
              />
            </>
          )
      })}

      <Paper relative>
        {agents.loading
          ? <Nothing />
          : <Incidents siteId={siteId} />}
      </Paper>
    </>
  )
}
