import { useEffect, useRef, useState } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import { Stack } from '@mantine/core'
import { type Json } from '@venturi-io/api'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  & .jsoneditor {
    border-color: ${({ theme }) => theme.colors.primary[6]};
  }
  & .jsoneditor-menu {
    background: ${({ theme }) => theme.colors.primary[6]};
  }
`

interface Props {
  json: Json
  isRaw: boolean
  onChangeJson: (json: Json) => void
}

export default function JsonEditor ({ json, isRaw, onChangeJson }: Props) {
  const editorRef = useRef<Editor>(null)
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (json && initial) {
      setTimeout(() => {
        if (editorRef.current && !isRaw) {
          editorRef.current.expandAll()
        }
      }, 500)
      setInitial(false)
    }
  }, [json, editorRef.current, isRaw, initial])

  return (
    <Stack>
      <StyledContainer>
        <Editor
          ref={editorRef}
          mode={isRaw ? 'code' : 'tree'}
          value={json}
          onChange={onChangeJson}
          htmlElementProps={{ style: { height: 'calc(100vh - 250px)' } }}
        />
      </StyledContainer>
    </Stack>
  )
}
