import { useCallback, useState } from 'react'
import {
  updateOnboardingStatusPerUser,
  updateOnboardingStatusPerOrg
} from '@venturi-io/api/src/userManager/user'
import ActionButton from 'src/ActionButton'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { adminRoles, checkIfHasRole } from 'src/utils/role'

interface Props {
  id: number
  name: string
  type: 'USER' | 'ORG'
}

export default function RestartOnboardingButton ({ id, name, type }: Props) {
  const { token } = useUser()
  const updateStatusPerUser = useApi(updateOnboardingStatusPerUser)
  const updateStatusPerOrg = useApi(updateOnboardingStatusPerOrg)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const isAllowed = checkIfHasRole(adminRoles)
  const disabled = !isAllowed ||
    updateStatusPerUser.loading ||
    updateStatusPerOrg.loading

  const handleRestartOnboarding = useCallback(() => {
    const successMessage = `Successfully restarted "${name}" onboarding`

    if (type === 'USER') {
      void updateStatusPerUser.fetch({
        userId: id,
        status: 'NOT_STARTED'
      }, token, successMessage)
    }

    if (type === 'ORG') {
      void updateStatusPerOrg.fetch({
        orgId: id,
        status: 'NOT_STARTED'
      }, token, successMessage)
    }

    setIsOpenModal(false)
  }, [id, name])

  const handleClose = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  return (
    <>
      <ActionButton
        label="Restart onboarding"
        icon="chalkboard-user"
        iconType="fas"
        actionColor="yellow"
        actionVariant="filled"
        iconColor="white"
        iconSize="sm"
        actionDisabled={disabled}
        onClick={() => {
          setIsOpenModal(true)
        }}
      />
      <ConfirmModal
        type="confirm"
        opened={isOpenModal}
        title={`Restart "${name}" onboarding`}
        question={`Are you sure you want to restart the onboarding of this ${type.toLowerCase()}?`}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleRestartOnboarding}
      />
    </>
  )
}
