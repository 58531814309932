import { type ReactElement } from 'react'
import { IconCheck, IconAlertOctagon } from '@tabler/icons-react'
import StatusBadge from 'src/AssetTemplate/Components/StatusBadge'
import colors from 'src/AssetTemplate/colors'

export type Status = 'Running' | 'Stopped'

interface Props {
  status: Status
}

const statusIcon: Record<Status, ReactElement> = {
  Running: <IconCheck size={20} />,
  Stopped: <IconAlertOctagon size={20} />
}

const statusColor: Record<Status, string> = {
  Running: colors.green,
  Stopped: colors.red
}

export default function EngineState ({ status }: Props) {
  return (
    <StatusBadge
      title="Engine State"
      icon={statusIcon[status]}
      color={statusColor[status]}
      textColor="white"
      value={status}
    />
  )
}
