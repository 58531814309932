import { cond, equals } from 'ramda'
import urlcat, { query, join, type ParamMap } from 'urlcat'
import { type z, type ZodType, type ZodTypeDef } from 'zod'
import {
  apiConfig,
  apiErrorData,
  topLevelError,
  ApiResError,
  type Api,
  type Method,
  type Options,
  type OnUnauthorized,
  type POST,
  type PATCH,
  type PUT,
  type DELETE,
  type StatefulOptions,
  type GET,
  type StatelessOptions,
  type ApiConfig,
  type ApiCallable
} from './shared'
import { EitherAsync } from 'purify-ts'

const giveHeaders = (sendJson = true, token?: string, bearerToken?: string): HeadersInit => {
  const appJson = 'application/json'
  const cacheControl = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Clear-Site-Data': 'cache',
    Pragma: 'no-cache',
    Expires: '0'
  }

  if (token) {
    if (sendJson) {
      return {
        Accept: appJson,
        'Content-Type': appJson,
        'X-Auth-Token': token,
        ...cacheControl
      }
    }

    return {
      Accept: appJson,
      'X-Auth-Token': token,
      ...cacheControl
    }
  }

  // TODO: Adjust the setting of jwt token to header once whole Token System is configured
  if (bearerToken) {
    if (sendJson) {
      return {
        Accept: appJson,
        'Content-Type': appJson,
        Authorization: `Bearer ${bearerToken}`,
        ...cacheControl
      }
    }

    return {
      Accept: appJson,
      Authorization: `Bearer ${bearerToken}`,
      ...cacheControl
    }
  }

  if (sendJson) {
    return {
      Accept: appJson,
      'Content-Type': appJson,
      ...cacheControl
    }
  }

  return { Accept: appJson, ...cacheControl }
}

const isGetOrDelete = (method: Method): method is GET | DELETE => {
  if (method.method === 'GET') return true
  if (method.method === 'DELETE') return true

  return false
}

// NOTE: This is not very clean, definitely should approach in a better way
function removePathParams<Body extends Record<string, unknown>> (
  url: string,
  body?: Body
) {
  const check = /:([_A-Za-z][_A-Za-z0-9]*)+/g

  if (!body) return body
  if (!check.test(url)) return body

  // Reset
  check.lastIndex = 0

  const matches = [...(url.toString()).matchAll(check)]
  for (const [,match] of matches) {
    if (body[match]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete body[match]
    }
  }

  return body
}

function removeNonPathParams<Body extends Record<string, unknown>> (
  url: string,
  body?: Body
) {
  const check = /:([_A-Za-z][_A-Za-z0-9]*)+/g

  if (!body) return body
  if (!check.test(url)) return body

  // Reset
  check.lastIndex = 0
  const matches = [...(url.toString()).matchAll(check)]

  const result: ParamMap = {}
  for (const [,match] of matches) {
    if (body[match]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      result[match] = body[match]
    }
  }

  return result
}

export async function configure (hostname: string, force = false, mobile = false): Promise<ApiConfig> {
  const hostUrl = `https://${hostname}`

  if (apiConfig.hasConfigured && !force) {
    return apiConfig.config
  }

  let launchUrl = window.location.host.match(/localhost/)
    ? process.env.REACT_APP_URL_LAUNCH ?? 'https://launch.venturi.io'
    : hostUrl

  if (mobile) {
    launchUrl = process.env.EXPO_PUBLIC_URL_LAUNCH ?? ''
  }

  const globalConfigUrl = `${launchUrl}/api/launch/config?orgUrl=${hostname}`
  const result = await fetch(globalConfigUrl).then(async res => await res.json())

  apiConfig.hasConfigured = true
  // Set API URLs based on the current host
  apiConfig.config = {
    environment: result.environment,
    globalConfig: launchUrl,
    user: hostUrl,
    collector: hostUrl,
    config: hostUrl,
    analytics: hostUrl,
    orgId: result.orgId,
    orgName: result.orgName
  }

  // Set API URLs from launch config API's response if the flag is true
  if (process.env.REACT_APP_CUSTOM_CONFIG_URL === 'true') {
    apiConfig.config = {
      environment: result.environment,
      globalConfig: launchUrl,
      user: result.USER_MANAGER_URL,
      collector: result.COLLECTOR_URL,
      config: result.CONFIG_URL,
      analytics: result.ANALYTICS_URL,
      orgId: result.orgId,
      orgName: result.orgName
    }
  }

  return apiConfig.config
}

const apiUrl = (api: Api, config: ApiConfig): string =>
  cond([
    [equals('user'), () => `${config.user}/api/usermanager`],
    [equals('collector'), () => `${config.collector}/api/collector`],
    [equals('config'), () => `${config.config}/api/config`],
    [equals('globalConfig'), () => `${config.globalConfig}/api/globalconf`],
    [equals('analytics'), () => `${config.analytics}/api/analytics`]
  ])(api)

// TODO: Clean up these casts
export function fetchConfig<Body extends Record<string, unknown>> (
  api: Api,
  url: string,
  config: ApiConfig,
  method: Method,
  body?: Body,
  token?: string,
  bearerToken?: string,
  signal?: AbortSignal
): [string, RequestInit] {
  const baseUrl = apiUrl(api, config)
  if (isGetOrDelete(method)) {
    if (method.type === 'param') {
      const url_ = urlcat(baseUrl, join(url, '?', query(body ?? {})))
      const headers = giveHeaders(false, token, bearerToken)

      return [url_, {
        method: method.method,
        headers,
        signal
      }]
    }

    if (method.type === 'path') {
      const url_ = urlcat(baseUrl, url, body ?? {})
      const headers = giveHeaders(false, token, bearerToken)

      return [url_, {
        method: method.method,
        headers,
        signal
      }]
    }
  }

  const url_ = urlcat(baseUrl, url, removeNonPathParams(url, body ?? {}) ?? {})
  const body_ = removePathParams(url, body ?? {})
  return [url_, {
    method: method.method,
    headers: giveHeaders(true, token, bearerToken),
    body: JSON.stringify(body_),
    signal
  }]
}

let onUnauthorized: OnUnauthorized = () => undefined

export function configureOnUnauthorized (handler: OnUnauthorized) {
  onUnauthorized = handler
}

export type HostConfig = () => ({
  host: string
  isMobile: boolean
})

const getConfig = () => {
  // This will check if we're on mobile or web
  const isMobile = typeof window === 'undefined' || typeof document === 'undefined'
  const mobileHost = process.env.EXPO_PUBLIC_HOSTNAME ?? ''

  if (isMobile) {
    return {
      isMobile: true,
      host: mobileHost
    }
  }

  let webHost = window.location.host.match(/localhost/)
    ? process.env.REACT_APP_LOCAL_HOSTNAME ?? 'localhost:3000'
    : window.location.host

  if (process.env.REACT_APP_HOSTNAME) {
    webHost = process.env.REACT_APP_HOSTNAME
  }

  return {
    host: webHost,
    isMobile: false
  }
}

function createApi <Request extends Record<string, unknown>, Decoder extends ZodType<unknown, ZodTypeDef, unknown>> (
  api: Api,
  url: string,
  method: Method,
  responseParser: Decoder,
  options?: Options
): ApiCallable<Request, z.output<typeof responseParser>> {
  return (request: Request, token?: string, bearerToken?: string, signal?: AbortSignal): EitherAsync<ApiResError, z.output<typeof responseParser>> => {
    const { host, isMobile } = getConfig()
    return EitherAsync(async ({ throwE }) => {
      const config = apiConfig.hasConfigured
        ? apiConfig.config
        : await configure(host, false, isMobile)

      const [uri, req] = fetchConfig(api, url, config, method, request, token, bearerToken, signal)
      const response = await fetch(uri, req)
        .then(async r => {
          if (r.status === 401) {
            onUnauthorized()
            throwE(new ApiResError('401 Unauthorized'))
          }

          if (r.status === 403) {
            throwE(new ApiResError('403 Forbidden'))
          }

          const raw = await r.text()

          try {
            const body = JSON.parse(raw)

            // TODO: Handle response status here
            if (r.status >= 300) {
              if (process.env.REACT_APP_DEBUG === 'true') {
                console.debug(uri, req, body)
              }

              const errorData = apiErrorData.safeParse(body)
              if (errorData.success) {
                throwE(new ApiResError(errorData.data, options))
              } else {
                const topLevel = topLevelError.safeParse(body)
                if (topLevel.success) {
                  throwE(new ApiResError(topLevel.data.error, options))
                } else {
                  throwE(new ApiResError(raw, options))
                }
              }
            }
            return body
          } catch (err) {
            const error = err as Error
            throwE(new ApiResError(error.message, options))
          }
        })

      const result = responseParser.safeParse(response)
      if (!result.success) {
        // TODO: Write a custom error that extends ZodError and adds data and request URI
        if (process.env.REACT_APP_DEBUG === 'true') {
          console.debug(uri, req, response)
        }

        return throwE(new ApiResError(result.error.message, options))
      }

      return result.data
    })
  }
}

export function createStatefulApi <Request extends Record<string, unknown>, Decoder extends ZodType<unknown, ZodTypeDef, unknown>> (
  api: Api,
  url: string,
  method: POST | PATCH | PUT | DELETE,
  responseParser: Decoder,
  options?: Omit<StatefulOptions, 'isStateful'>
): ApiCallable<Request, z.output<typeof responseParser>> {
  const defaultOptions: StatefulOptions = {
    isStateful: true,
    isRetryable: false,
    retryLimit: 0,
    retryDelay: 0
  }
  const options_: StatefulOptions = options
    ? { ...options, isStateful: true }
    : defaultOptions

  return createApi<Request, typeof responseParser>(api, url, method, responseParser, options_)
}

export function createStatelessApi <Request extends Record<string, unknown>, Decoder extends ZodType<unknown, ZodTypeDef, unknown>> (
  api: Api,
  url: string,
  method: GET,
  responseParser: Decoder,
  options?: Omit<StatelessOptions, 'isStateful'>
): ApiCallable<Request, z.output<typeof responseParser>> {
  const defaultOptions: StatelessOptions = {
    isStateful: false,
    isRetryable: true,
    retryLimit: 1,
    retryDelay: 1
  }
  const options_: StatelessOptions = options
    ? { ...options, isStateful: false }
    : defaultOptions

  return createApi<Request, typeof responseParser>(api, url, method, responseParser, options_)
}
