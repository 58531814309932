import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ActionIcon, Menu, Tooltip } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import { type Agent } from '@venturi-io/api/src/config/ui'

const itemTypes = [
  'Agent logs',
  'Event logs',
  'Field gateway config',
  'Sensors & Alarms',
  'Configure',
  'Delete Agent'
] as const

type ItemType = typeof itemTypes[number]

interface Item {
  name: ItemType
  icon: IconName
  path: string
}

export const items: Item[] = [
  {
    name: 'Configure',
    icon: 'cog',
    path: '/settings/agents/:id'
  },
  {
    name: 'Agent logs',
    icon: 'clipboard',
    path: '/my-admin/agent/logs?agentId=:id'
  },
  {
    name: 'Event logs',
    icon: 'clock-rotate-left',
    path: '/my-admin/event-logs?agentId=:id'
  },
  {
    name: 'Field gateway config',
    icon: 'brackets-curly',
    path: '/my-admin/field-gateway-config?agentId=:id'
  },
  {
    name: 'Sensors & Alarms',
    icon: 'bell',
    path: '/settings/agents/:id/sensors'
  }
]

interface Props {
  agentId: Agent['agentId']
  isOpen: boolean
  setIsOpen?: (status: boolean) => void
  action?: () => void
  setShowDelete?: (status: boolean) => void
  disableDelete?: boolean
}

export default function Settings ({
  isOpen = false,
  setIsOpen,
  agentId,
  action,
  setShowDelete,
  disableDelete = false
}: Props) {
  const toggleIsOpen = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(!isOpen)
    }
  }, [isOpen, setIsOpen])

  const handleDelete = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(false)
    }

    if (action) {
      action()
    }

    if (setShowDelete) {
      setShowDelete(true)
    }
  }, [setIsOpen, action, setShowDelete])

  return (
    <Menu
      opened={isOpen}
      width="xl"
      shadow="md"
      position="bottom-end"
      transitionProps={{
        transition: 'pop-top-right'
      }}
      closeOnClickOutside
      onClose={toggleIsOpen}
    >
      <Menu.Target>
        <Tooltip
          label="Settings"
          position="top"
          transitionProps={{
            transition: 'pop',
            duration: 200
          }}
          withArrow
        >
          <ActionIcon onClick={toggleIsOpen}>
            <FontAwesomeIcon
              color="black"
              icon={['fas', 'gears']}
              size="1x"
            />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        {items.map(({ name, icon, path }) => (
          <Menu.Item
            key={name}
            icon={<FontAwesomeIcon icon={['fas', icon]} size="sm" />}
            component={Link}
            to={path.replace(':id', agentId.toString())}
          >
            {name}
          </Menu.Item>
        ))}
        {!disableDelete && (
          <Menu.Item
            key="Delete Agent"
            icon={<FontAwesomeIcon icon={['fas', 'trash']} size="sm" />}
            onClick={handleDelete}
          >
            Delete Agent
          </Menu.Item>)}
      </Menu.Dropdown>
    </Menu>
  )
}
