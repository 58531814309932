import { Handle, type Node, Position } from 'react-flow-renderer'
import { useSharedStyles, nodeStyles } from './shared'

export default function DefaultTriple ({
  data: { label, customStyle },
  isConnectable
}: Node['data']) {
  const { classes } = useSharedStyles()

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        className={classes.edge}
        isConnectable={isConnectable}
      />
      <div
        className={classes.container}
        style={{
          color: customStyle?.color ?? nodeStyles.default.color,
          background: customStyle?.background ?? nodeStyles.default.background
        }}
      >
        {label}
      </div>
      <Handle
        id="a"
        type="source"
        position={Position.Bottom}
        className={classes.edge}
        style={{ left: '20%' }}
        isConnectable={isConnectable}
      />
      <Handle
        id="b"
        type="source"
        position={Position.Bottom}
        className={classes.edge}
        style={{ left: '50%' }}
        isConnectable={isConnectable}
      />
      <Handle
        id="c"
        type="source"
        position={Position.Bottom}
        className={classes.edge}
        style={{ left: '80%' }}
        isConnectable={isConnectable}
      />
    </>
  )
}
