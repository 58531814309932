import { Drawer, Title } from '@mantine/core'
import Form from './Form'

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Create ({ show, close, onCreate }: Props) {
  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Modbus Device</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <Form
        mode="CREATE"
        onClose={close}
        onCreate={onCreate}
      />
    </Drawer>
  )
}
