import { useNavigate } from 'react-router-dom'
import { ActionIcon, Text } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'

export default function Row ({ ...agent }: Agent & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const { agentId, agentName } = agent

  return (
    <div className={classes.row}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">{agentId}</Text>
      </div>
      <div className={classes.col}>
        <TextTooltip value={agentName} maxLength={30} size="sm" />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <ActionIcon
          title="Edit"
          color="green"
          variant="filled"
          onClick={() => navigate(`/settings/agents/${agentId}`)}
        >
          <FontAwesomeIcon size="sm" icon={['fas', 'pencil']} color="white" />
        </ActionIcon>
      </div>
    </div>
  )
}
