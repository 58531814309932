import { useEffect, type ReactNode, useState } from 'react'
import {
  Accordion,
  Box,
  Button,
  Center,
  Collapse,
  Group,
  Loader,
  Popover,
  ScrollArea,
  Stack,
  Table,
  Text,
  createStyles
} from '@mantine/core'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi, type ApiData } from 'src/utils/useApi'
import valueMap from 'src/utils/valueMap'
import { capitalise } from 'src/utils/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'src/Layout/Link'
import { type User, findUserById } from '@venturi-io/api/src/userManager/user'
import { type FloatingPosition } from '@mantine/core/lib/Floating'
import { getAgentDurationAggregate } from '@venturi-io/api/src/collector/agent'
import dayjs from 'dayjs'
import { giveOffset, secondsToHoursMins, toUtc } from 'src/utils/dates'
import { reverseGeocode } from './mapbox'

const useStyle = createStyles((theme, alarmStatus: string | undefined) => ({
  content: {
    padding: 0
  },
  rowBorder: {
    borderBottom: `0.0625rem solid ${theme.colors.gray[3]}`
  },
  control: {
    paddingLeft: 10,
    paddingRight: 10
  },
  rowColor: {
    color: alarmStatus === 'ALARM' ? 'red' : 'initial'
  },
  isClickable: {
    cursor: 'pointer'
  }
}))

function TableDataCell ({
  property,
  value,
  labelWidth = 170,
  isClickable = false,
  alarmStatus
}: TableRowProps) {
  const { classes } = useStyle(alarmStatus)

  return (
    <>
      <td width={labelWidth} className={classes.rowColor}>
        <Text
          sx={{
            minWidth: 80
          }}
          weight="bold"
          size="xs"
        >
          {property}
        </Text>
      </td>
      <td className={classes.rowColor}>
        <Text size="xs">
          {value}
          {isClickable && (
            <FontAwesomeIcon
              color="gray"
              style={{
                paddingLeft: '5px'
              }}
              icon={['far', 'circle-info']}
            />
          )}
        </Text>
      </td>
    </>
  )
}

interface TableRowProps {
  property: string
  value: string
  labelWidth?: number
  hasBorderBottom?: boolean
  isClickable?: boolean
  details?: User | null
  alarmStatus?: string
}

function TableRow ({
  property,
  value,
  labelWidth = 170,
  hasBorderBottom = false,
  isClickable = false,
  details,
  alarmStatus
}: TableRowProps) {
  const { classes, cx } = useStyle(alarmStatus)
  const [popupPos, setPopupPos] = useState<string | null>('')

  const checkPosition = (pos: FloatingPosition) => {
    if (pos === 'top') {
      setPopupPos('top')
    } else {
      setPopupPos('')
    }
  }

  const tableRowClasses = cx({
    [classes.rowBorder]: hasBorderBottom,
    [classes.isClickable]: isClickable
  })

  return !isClickable
    ? (
      <tr className={tableRowClasses}>
        <TableDataCell
          property={property}
          value={value}
          labelWidth={labelWidth}
          isClickable={isClickable}
          alarmStatus={alarmStatus}
        />
      </tr>
      )
    : (
      <Popover
        width={290}
        position="top"
        withArrow
        shadow="md"
        onPositionChange={checkPosition}
      >
        <Popover.Target>
          <tr className={tableRowClasses}>
            <TableDataCell
              property={property}
              value={value}
              labelWidth={labelWidth}
              isClickable={isClickable}
              alarmStatus={alarmStatus}
            />
          </tr>
        </Popover.Target>
        <Popover.Dropdown
          style={{
            height: popupPos === 'top'
              ? '120px'
              : 'auto',
            overflowY: popupPos === 'top'
              ? 'scroll'
              : 'unset'
          }}
        >
          <Group
            sx={{
              flexDirection: 'column'
            }}
            align="left"
            spacing="0"
          >
            <Group>
              <Text
                weight="bold"
                size="xs"
                w={115}
              >
                Name:
              </Text>
              <Text size="xs">
                {details?.firstName}
                {' '}
                {details?.lastName}
              </Text>
            </Group>
            <Group>
              <Text
                weight="bold"
                size="xs"
                w={115}
              >
                Number:
              </Text>
              <Text size="xs">{details?.phoneNumber}</Text>
            </Group>
            {details?.emergencyContact1Name && (
              <Stack spacing={2} pt={3}>
                <Text
                  weight="bold"
                  size="xs"
                >
                  Emergency Contact 1
                </Text>
                <Text size="xs">
                  <Group pl={15}>
                    <Text
                      weight="bold"
                      size="xs"
                      w={100}
                    >
                      Name:
                    </Text>
                    <Text size="xs">{details?.emergencyContact1Name}</Text>
                  </Group>
                  <Group pl={15}>
                    <Text
                      weight="bold"
                      size="xs"
                      w={100}
                    >
                      Number:
                    </Text>
                    <Text size="xs">{details?.emergencyContact1PhoneNumber}</Text>
                  </Group>
                </Text>
              </Stack>
            )}
            {details?.emergencyContact2Name && (
              <Stack spacing={2} pt={3}>
                <Text
                  weight="bold"
                  size="xs"
                >
                  Emergency Contact 2
                </Text>
                <Text size="xs">
                  <Group pl={15}>
                    <Text
                      weight="bold"
                      size="xs"
                      w={100}
                    >
                      Name:
                    </Text>
                    <Text size="xs">{details?.emergencyContact2Name}</Text>
                  </Group>
                  <Group pl={15}>
                    <Text
                      weight="bold"
                      size="xs"
                      w={100}
                    >
                      Number:
                    </Text>
                    <Text size="xs">{details?.emergencyContact2PhoneNumber}</Text>
                  </Group>
                </Text>
              </Stack>
            )}
          </Group>
        </Popover.Dropdown>
      </Popover>
      )
}

function AgentDetails ({
  agentId,
  assetType,
  rfidTag,
  user,
  owner,
  geoLocation,
  alarmStatus,
  agentStatus,
  lastSeenTime,
  connectionStatus
}: ApiData<typeof getAgentDetails>): ReactNode {
  const { token } = useUser()
  const [address, setAddress] = useState('')
  const findUser = useApi(findUserById)
  const findOwner = useApi(findUserById)
  const duration = useApi(getAgentDurationAggregate)
  const userDetails = findUser.data.mapOrDefault(data => data, null)
  const ownerDetails = findOwner.data.mapOrDefault(data => data, null)
  const agentDuration = duration.data.mapOrDefault(data => data, null)
  const showStatuses = assetType && (
    assetType === 'VEHICLE' ||
    assetType === 'POWER METER' ||
    assetType === '3P-POWER' ||
    assetType === 'GENSET'
  )

  const loadAddress = async () => {
    if (geoLocation) {
      const { longitude, latitude } = geoLocation
      const place = await reverseGeocode([longitude, latitude])
      setAddress(place)
    }
  }

  useEffect(() => {
    if (user) {
      void findUser.fetch({ orgUserId: Number(user.orgUserId) }, token)
    }
  }, [user?.orgUserId])

  useEffect(() => {
    if (owner) {
      void findOwner.fetch({ orgUserId: Number(owner.orgUserId) }, token)
    }
  }, [owner?.orgUserId])

  useEffect(() => {
    void duration.fetch({
      agentId,
      startTime: toUtc(dayjs().subtract(24, 'hour')) + giveOffset(),
      endTime: toUtc(dayjs()) + giveOffset()
    }, token)
  }, [agentId])

  useEffect(() => {
    void loadAddress()
  }, [geoLocation])

  return (
    <Table>
      <tbody>
        {showStatuses && (
          <>
            <TableRow
              property="Asset Status:"
              value={(
              agentStatus
                ? capitalise(agentStatus)
                : '-'
              )}
            />
            <TableRow
              property="Connection Status:"
              value={(
              connectionStatus
                ? capitalise(connectionStatus)
                : 'Not connected'
              )}
            />
            <TableRow
              property="Last Message:"
              value={(
                lastSeenTime
                  ? capitalise(lastSeenTime)
                  : '-'
              )}
              hasBorderBottom={true}
            />
          </>
        )}
        <TableRow
          property="Alarm Status:"
          value={(
            alarmStatus
              ? capitalise(alarmStatus)
              : '-'
          )}
        />
        {assetType && (
          <TableRow
            property="Asset Type:"
            value={(
              assetType
                ? capitalise(assetType)
                : '-'
            )}
          />
        )}
        {rfidTag && (
          <TableRow
            property="RFID:"
            value={rfidTag}
          />
        )}
        {user && (
          <TableRow
            property="User:"
            value={capitalise(`${user?.firstName} ${user?.lastName}`)}
            isClickable={true}
            details={userDetails}
          />
        )}
        {owner && (
          <TableRow
            property="Owner:"
            value={capitalise(`${owner?.firstName} ${owner?.lastName}`)}
            isClickable={true}
            details={ownerDetails}
          />
        )}
        <TableRow
          property="Location:"
          value={address}
        />
        <TableRow
          property="Parked Time:"
          value={secondsToHoursMins(agentDuration?.totalParkedDuration)}
        />
        <TableRow
          property="Ignition Time:"
          value={secondsToHoursMins(agentDuration?.totalEngineIgnitionDuration)}
        />
        <TableRow
          property="Idle Time:"
          value={secondsToHoursMins(agentDuration?.totalIdlingDuration)}
          hasBorderBottom={true}
        />
      </tbody>
    </Table>
  )
}

function PrimarySensors ({ sensors, assetType }: ApiData<typeof getAgentDetails>): ReactNode {
  const filtered = sensors.filter(({ primaryFunction }) => primaryFunction)
  return filtered.length > 0
    ? assetType === 'POWER METER' ||
      assetType === '3P-POWER'
      ? (
        <Accordion
          multiple
          sx={{
            width: '100%'
          }}
        >
          <Accordion.Item value="other">
            <Accordion.Control>
              <Text size="xs" weight="bold">Other information</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Table>
                <tbody>
                  {filtered.map(({ id, name, currentValue, unit, valueMap: vm, alarmStatus }) => (
                    <TableRow
                      key={id}
                      property={`${name}:`}
                      alarmStatus={alarmStatus}
                      value={(
                        currentValue
                          ? `${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`
                          : '-'
                      )}
                    />
                  ))}
                </tbody>
              </Table>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        )
      : (
        <Table>
          <tbody>
            {filtered.map(({ id, name, currentValue, unit, valueMap: vm, alarmStatus }) => (
              <TableRow
                key={id}
                property={`${name}:`}
                alarmStatus={alarmStatus}
                value={(
                  currentValue
                    ? `${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`
                    : '-'
                )}
              />
            ))}
          </tbody>
        </Table>
        )
    : null
}

function SystemSensors ({ sensors }: ApiData<typeof getAgentDetails>): ReactNode {
  const [showSystem, setShowSystem] = useState(false)
  const filtered = sensors.filter(({ systemOnly }) => systemOnly)

  return filtered.length > 0
    ? (
      <>
        <Center>
          <Button
            mt="lg"
            mb="sm"
            color="primary"
            variant="default"
            size="xs"
            onClick={() => setShowSystem(!showSystem)}
            leftIcon={<FontAwesomeIcon icon={['fas', 'microchip']} />}
          >
            {showSystem
              ? 'Hide system information'
              : 'Show system information'}
          </Button>
        </Center>
        <Collapse in={showSystem}>
          <Table>
            <tbody>
              {filtered.map(({ id, name, currentValue, unit, valueMap: vm }) => (
                <TableRow
                  key={id}
                  property={`${name}:`}
                  value={(
                    currentValue
                      ? `${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`
                      : '-'
                  )}
                />
              ))}
            </tbody>
          </Table>
        </Collapse>
      </>
      )
    : null
}

function StandardSensors ({ sensors }: ApiData<typeof getAgentDetails>): ReactNode {
  const filtered = sensors.filter(({ primaryFunction, systemOnly }) => !primaryFunction && !systemOnly)

  return filtered.length > 0
    ? (
      <Accordion
        multiple
        sx={{
          width: '100%'
        }}
      >
        <Accordion.Item value="other">
          <Accordion.Control>
            <Text size="xs" weight="bold">Other information</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Table>
              <tbody>
                {filtered.map(({ id, name, currentValue, unit, valueMap: vm, alarmStatus }) => (
                  <TableRow
                    key={id}
                    property={`${name}:`}
                    alarmStatus={alarmStatus}
                    value={(
                      currentValue
                        ? `${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`
                        : '-'
                    )}
                  />
                ))}
              </tbody>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      )
    : null
}

interface Props {
  agentId: ApiData<typeof getAgentDetails>['agentId']
  opened?: boolean
}

export default function ItemDetails ({
  agentId,
  opened = false
}: Props) {
  const { token } = useUser()
  const agent = useApi(getAgentDetails)
  const details = agent.data.mapOrDefault(agentDetails => agentDetails, null)

  const cancelLastFetch = () => {
    // cancel last poll
    void agent.clearAll()
    void agent.stopPolling()
  }

  useEffect(() => {
    if (opened) {
      void cancelLastFetch()
      void agent
        .fetch({ agentId }, token)
        .finally(() =>
          agent.startPolling({ agentId }, token, 10)
        )
    } else {
      void cancelLastFetch()
    }
  }, [opened, agentId])

  return (
    <Stack mt="md" spacing="md" style={{ position: 'relative' }}>
      {agent.loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0
          }}
        >
          <Center>
            <Loader color="primary" size="xs" variant="dots" />
          </Center>
        </Box>
      )}
      <ScrollArea type="hover" scrollbarSize={5}>
        {details && (
          <Stack spacing={0}>
            <AgentDetails {...details} />
            <PrimarySensors {...details} />
            <StandardSensors {...details} />
            {!details.hasImmobiliser && (
              <Text
                mt="sm"
                align="center"
                size="sm"
                color="gray"
                weight="lighter"
              >
                ** Immobiliser not installed **
              </Text>
            )}
            <SystemSensors {...details} />
          </Stack>
        )}
      </ScrollArea>
      {details?.assetType && details?.assetType.toUpperCase() !== 'VEHICLE' && (
        <Center>
          <Text size="sm">
            <Link
              to={(
                details?.assetType
                  ? `/assets/${details.assetType.toLowerCase().replaceAll(' ', '-')}/${agentId}`
                  : `/agent/${agentId}`
              )}
            >
              View asset details
            </Link>
          </Text>
        </Center>
      )}
    </Stack>
  )
}
