import { Container, Title } from '@mantine/core'
import { type CSSProperties } from 'react'
import { useSharedStyles } from '../shared'

export interface Props {
  id: string
  name: string
  title: string
  url: string
  customStyle?: CSSProperties | undefined
  iFrameHeight?: string
}
export default function IFrame ({ name, url, customStyle, iFrameHeight }: Props) {
  const { theme } = useSharedStyles()

  return (
    <Container style={customStyle} sx={{ background: theme.white, height: '100%' }}>
      {name && (
        <Title
          order={4}
          sx={{
            fontWeight: 'normal',
            padding: '10px 0 10px 0',
            textAlign: 'left'
          }}
        >
          {name}
        </Title>
      )}
      <iframe
        src={url}
        title={name}
        width="100%"
        height={iFrameHeight ?? '80%'}
        style={{ border: 0 }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </Container>
  )
}
