import { Paper, SimpleGrid, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type SensorDataLog } from '@venturi-io/api/src/collector/sensor'
import { parseEmpty } from 'src/utils/parseEmpty'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'

export default function Card ({
  agentName,
  eventLog,
  sensorId,
  sensorName,
  sensorInstanceId,
  sensorInstanceName,
  timestamp
}: SensorDataLog & ListRow) {
  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup label="Timestamp" value={parseEmpty(dayjs(timestamp).format(uiDateFormat))} maxLength={25} />
          <TextGroup label="Agent" value={parseEmpty(agentName)} />
          <TextGroup label="Sensor ID" value={parseEmpty(sensorId)} />
          <TextGroup label="Sensor" value={parseEmpty(sensorName)} />
          <TextGroup label="Sensor Instance ID" value={parseEmpty(sensorInstanceId)} />
          <TextGroup label="Sensor Instance" value={parseEmpty(sensorInstanceName)} />
          <TextGroup label="Value" value={parseEmpty(eventLog)} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
