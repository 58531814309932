import { type ReactNode } from 'react'
import { Box } from '@mantine/core'

interface Props {
  children: ReactNode
}

export default function PlaceholderContainer ({ children }: Props) {
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  )
}
