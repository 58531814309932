import { memo, useEffect, useMemo } from 'react'
import { Container, ScrollArea } from '@mantine/core'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Agent, type Sensor, getAgents } from '@venturi-io/api/src/config/agent'
import dayjs, { type ManipulateType } from 'dayjs'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { type ChosenBucket, type ItemKeys } from 'src/Input/TimePeriodSelector'
import { dateFormat, giveOffset } from 'src/utils/dates'
import NoData from '../shared/NoData'
import { useSharedStyles } from '../shared'
import Table from './Table'

type SensorWithExtra = Sensor & {
  key: string
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  agentAlarmStatus: Agent['alarmStatus']
}

interface SensorHistoryData extends SensorWithExtra {
  value: number
}

export interface SensorHistory {
  date: string
  data: SensorHistoryData[]
}

export interface Props {
  id: string
  name: string
  data: {
    sensorInstanceIds: Array<Sensor['sensorInstanceId']>
    bucket: ChosenBucket<ItemKeys>
  }
}

const MultiAgentSensorHistoryTable = ({
  name,
  data: {
    sensorInstanceIds,
    bucket
  }
}: Props) => {
  const { token, orgId } = useUser()
  const allAgents = useApi(getAgents)
  const allSensorsData = useApi(getSensorData)

  const { classes } = useSharedStyles()

  const agents = useMemo(() => (
    allAgents.data.mapOrDefault(({ items }) => items, [])
  ), [allAgents.data])

  const sensorsWithExtra: SensorWithExtra[] = useMemo(() => (
    agents.reduce((sensorsWithExtra: SensorWithExtra[], { agentId, agentName, alarmStatus, sensors }) => {
      const sensorInstancesWithExtra = sensors
        .filter(({ sensorInstanceId }) => sensorInstanceIds.includes(sensorInstanceId))
        .map(sensor => ({
          key: `${sensor.name} (${agentName})`,
          agentId,
          agentName,
          agentAlarmStatus: alarmStatus,
          ...sensor
        }))

      return [
        ...sensorsWithExtra,
        ...sensorInstancesWithExtra
      ]
    }, [])
  ), [agents, sensorInstanceIds])

  const sensorsHistory: SensorHistory[] = useMemo(() => {
    const history: Record<string, SensorHistoryData[]> = allSensorsData.data.mapOrDefault(({ result }) => (
      result.reduce((sensorsHistoryData: Record<string, SensorHistoryData[]>, { sensorInstanceId, data }) => {
        const sensor: SensorWithExtra | undefined =
              sensorsWithExtra.find(s => s.sensorInstanceId === sensorInstanceId)

        if (sensor) {
          data.forEach(({ x, y }) => {
            const date = x.toString()
            const value = typeof y !== 'undefined' ? y : 0
            const sensorData: SensorHistoryData = {
              ...sensor,
              value
            }

            if (typeof sensorsHistoryData[date] !== 'undefined') {
              sensorsHistoryData[date] = [
                ...sensorsHistoryData[date],
                sensorData
              ]
            } else {
              sensorsHistoryData[date] = [sensorData]
            }
          })
        }

        return sensorsHistoryData
      }, {})
    ), {})

    return Object.keys(history)
      .map(date => ({
        date,
        data: history[date]
      }))
      .sort((a, b) => (a.date < b.date) ? 1 : -1)
  }, [allSensorsData.data, sensorsWithExtra])

  const isLoading = useMemo(() => (
    allAgents.loading || allSensorsData.loading
  ), [allAgents.loading, allSensorsData.loading])

  useEffect(() => {
    const time = bucket.item.split(' ')
    const req = {
      sensorInstanceIds,
      startTime: dayjs().subtract(Number.parseInt(time[0]), time[1] as ManipulateType).format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: bucket.data.noOfRecords
    }

    void allSensorsData.fetch(req, token)
  }, [bucket, sensorInstanceIds])

  useEffect(() => {
    if (!sensorInstanceIds || sensorInstanceIds.length < 1) return

    void allAgents.fetch({
      orgId,
      page: 1,
      size: 99999
    }, token)
  }, [])

  return (
    <Container className={classes.itemContainer} fluid>
      {isLoading && <Loader variant="bars" />}
      {!isLoading && (
        sensorsHistory.length
          ? (
            <ScrollArea
              style={{
                height: '100%',
                width: '100%',
                background: 'white'
              }}
              type="hover"
              scrollbarSize={5}
            >
              <Table title={name} data={sensorsHistory} />
            </ScrollArea>
            )
          : <NoData />
      )}
    </Container>
  )
}

export default memo(MultiAgentSensorHistoryTable)
