import { Table as MantineTable, createStyles } from '@mantine/core'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { memo } from 'react'
import { truncateWithEllipsis } from 'src/utils/strings'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import { mq } from 'src/utils/style'
import NoData from '../shared/NoData'

const useStyle = createStyles((theme) => ({
  table: {
    width: 800,
    [mq(theme.breakpoints.xl)]: {
      width: '100%'
    }
  }
}))

export interface AgentsRowProps {
  agents: Agent[]
}

const countDays = (date: string | undefined) => {
  const lastSeen = dayjs(date)
  const days = lastSeen.diff(Date.now(), 'day')
  return Math.abs(days)
}

const messageLabel = (lastSeenTime: string) => {
  const messageTime = dayjs().diff(dayjs(lastSeenTime))
  const mins30 = 30 * 60 * 1000
  const mins90 = 5400000
  const hour1 = 60 * 60 * 1000
  let messageLabel
  let textColor = 'black'
  if (messageTime <= mins30) {
    messageLabel = 'Message Received'
  } else if (
    messageTime > mins30 &&
    messageTime <= hour1
  ) {
    messageLabel = 'Failed to check in 30 minutes'
    textColor = 'orange'
  } else if (
    messageTime > hour1 &&
    messageTime <= mins90
  ) {
    messageLabel = 'Failed to check in 60 minutes'
    textColor = 'orange'
  } else if (messageTime > mins90) {
    messageLabel = 'No messages over 90 minutes'
    textColor = 'red'
  }

  return {
    color: textColor,
    label: messageLabel
  }
}

function AgentsRow ({ agents }: AgentsRowProps) {
  return agents.length > 0
    ? (
      <>
        {agents.map(({
          agentName,
          lastSeenTime,
          lastLocation,
          agentStatus
        }) => {
          const { color, label } = messageLabel(
            lastSeenTime !== undefined && lastSeenTime !== ''
              ? lastSeenTime
              : ''
          )
          return (
            <tr key={agentName}>
              <td>{truncateWithEllipsis(agentName, 20)}</td>
              <td>{agentStatus}</td>
              <td>{dayjs(lastSeenTime).format(uiDateFormat)}</td>
              <td
                style={{
                  color
                }}
              >
                {label}
              </td>
              <td>{lastLocation?.fullAddress}</td>
              <td>{countDays(lastSeenTime)}</td>
            </tr>
          )
        })}
      </>
      )
    : <NoData message="No offline agents" />
}

export interface Props {
  agents: Agent[]
}

function Table ({ agents }: Props) {
  const { classes } = useStyle()
  return (
    <div>
      <MantineTable
        striped
        highlightOnHover
        className={classes.table}
      >
        <thead>
          <tr>
            <th>Asset</th>
            <th>Asset Status</th>
            <th>Last Messages</th>
            <th>Message Status</th>
            <th>Last Position</th>
            <th>Days</th>
          </tr>
        </thead>
        <tbody>
          <AgentsRow agents={agents} />
        </tbody>
      </MantineTable>
    </div>
  )
}

export default memo(Table)
