import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type AgentGroup, agentGroup } from '../config/agentGroup'
import { privilege, user } from './user'
import { type OrgIdRequest, type PaginatedRequest, type RoleIdRequest, paginated } from '../shared'

const orgUser = user
  .pick({
    firstName: true,
    lastName: true,
    jobTitle: true
  })
  .extend({
    id: z.number()
  })

type OrgUser = z.infer<typeof orgUser>

export const aclPermissions = z.enum([
  'READ',
  'WRITE',
  'CREATE',
  'DELETE'
])

const role = z.object({
  orgId: z.optional(z.number()),
  roleId: z.number(),
  name: z.string(),
  orgUsers: z.optional(z.array(orgUser)),
  agentGroups: z.optional(z.array(agentGroup)),
  privileges: z.optional(z.array(privilege)),
  aclPermissions: z.optional(z.array(aclPermissions))
})

export type Role = z.infer<typeof role>

interface RoleRequest extends OrgIdRequest {
  name: string
  orgUsers?: OrgUser[]
  agentGroups?: AgentGroup[]
  aclPermissions?: string[]
}
export const createRole = createStatefulApi<RoleRequest, typeof role>(
  'user',
  '/role',
  { method: 'POST' },
  role
)

const allRoles = paginated.extend({
  items: z.array(role)
})

interface AllRolesRequest extends OrgIdRequest, PaginatedRequest {
  search?: string
}
export const getRoles = createStatelessApi<AllRolesRequest, typeof allRoles>(
  'user',
  '/roles',
  { method: 'GET', type: 'path' },
  allRoles
)

export const getRole = createStatelessApi<RoleIdRequest, typeof role>(
  'user',
  '/role/:roleId',
  { method: 'GET', type: 'path' },
  role
)

export const updateRole = createStatefulApi<RoleRequest & RoleIdRequest, typeof role>(
  'user',
  '/role',
  { method: 'PUT' },
  role
)

export const deleteRole = createStatefulApi<RoleIdRequest, typeof role>(
  'user',
  '/role/:roleId',
  { method: 'DELETE', type: 'path' },
  role
)
