import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Grid,
  Group,
  Paper
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOrgs } from '@venturi-io/api/src/userManager/org'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import { useDebouncedValue } from '@mantine/hooks'
import TextInput from 'src/Input/TextInput'
import { Header, Row, Card } from './List'
import CreateOrg from './Create'

export default function Orgs () {
  const [showCreate, setShowCreate] = useState(false)
  const organisations = usePaginatedApi(getOrgs)
  const { token } = useUser()
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebouncedValue(search, 700)

  const loadOrganisations = async (page?: number, size?: number): Promise<void> => {
    void organisations.fetch({
      page: page ?? organisations.page,
      size: size ?? organisations.size,
      search: debouncedSearch
    }, token)
  }
  useEffect(() => {
    void loadOrganisations(1)
  }, [debouncedSearch])

  useEffect(() => {
    void loadOrganisations()
  }, [organisations.page])

  return (
    <>
      <Group
        position="right"
        spacing="xs"
        mb="lg"
      >
        <Button
          disabled={showCreate}
          title="Create Organisation"
          color="primary"
          leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
          onClick={() => setShowCreate(true)}
        >
          Add new organisation
        </Button>
      </Group>
      <Paper
        mt="lg"
        p="sm"
        shadow="sm"
        my={0}
      >
        <Grid grow>
          <Col span={12} md={2}>
            <TextInput
              radius="sm"
              placeholder="Search organisations here..."
              icon={<FontAwesomeIcon icon={['fas', 'search']} />}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Grid>
      </Paper>
      <ActionList
        isLoading={organisations.loading}
        data={organisations.data.mapOrDefault(({ items }) => items, [])}
        getId={({ orgId }) => orgId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={organisations.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={organisations.page}
        onPageChange={organisations.setPage}
      />
      <CreateOrg
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadOrganisations}
      />
    </>
  )
}
