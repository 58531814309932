import { useCallback, useState } from 'react'
import { createStyles, ActionIcon, Stack, Tooltip } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmModal from 'src/Layout/ConfirmModal'

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    padding: '5px',
    background: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 17.22447395324707px 0px',
    borderRadius: '5px',
    zIndex: 5
  }
}))

interface Props {
  editMode: boolean
  onSaveItems: () => void
  onClearItems: () => void
  onEditNode: () => void
  onDeleteNode: () => void
}

export default function Tools ({ editMode, onSaveItems, onClearItems, onEditNode, onDeleteNode }: Props) {
  const [showReset, setShowReset] = useState(false)
  const { classes } = useStyles()
  const iconSize = 'sm'

  const onReset = useCallback(() => {
    onClearItems()
    setShowReset(false)
  }, [showReset])

  return (
    <>
      <Stack className={classes.container} spacing={2}>
        <Tooltip
          label="Save All"
          color="primary"
          position="left"
          withArrow
        >
          <ActionIcon onClick={onSaveItems}>
            <FontAwesomeIcon
              icon={['fas', 'floppy-disk']}
              size={iconSize}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          label="Reset"
          color="primary"
          position="left"
          withArrow
        >
          <ActionIcon onClick={() => setShowReset(true)}>
            <FontAwesomeIcon
              icon={['fas', 'rotate-left']}
              size={iconSize}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          label={!editMode ? 'Edit' : 'Cancel Edit'}
          color="primary"
          position="left"
          withArrow
        >
          <ActionIcon onClick={onEditNode}>
            <FontAwesomeIcon
              icon={['fas', editMode ? 'cancel' : 'pencil']}
              size={iconSize}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          label="Delete"
          color="primary"
          position="left"
          withArrow
        >
          <ActionIcon onClick={onDeleteNode}>
            <FontAwesomeIcon
              icon={['fas', 'trash']}
              size={iconSize}
            />
          </ActionIcon>
        </Tooltip>
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showReset}
        title="Resetting your work"
        question="Are you sure you want to proceed? This action will clear all items in the Pane and cannot be undone."
        confirmLabel="Reset"
        onClose={() => setShowReset(false)}
        onCancel={() => setShowReset(false)}
        onConfirm={onReset}
      />
    </>
  )
}
